import { AllowanceTypeGroups } from "./enums";
export default {
    id: {
        presence: true,
    },
    name: {},
    mins: {},
    sms: {},
    mms: {},
    dataMB: {},
    type: {
        presence: true,
    },
    duration: {
        presence: true,
    },
    numBundles: {
        presence: true,
    },
    cost: {
        presence: true,
    },
    applyToRows: {
        presence: true,
    },
    color: {
        presence: true,
    },
    allowanceTypeGroup: {
        presence: true,
        enum: AllowanceTypeGroups,
    },
};
