import { DataSize } from "@Shared/Entities/Customer/Usage/enums";
import { roundToFormat } from "@Shared/Helpers/Numbers";
export const displayData = (amount, points = 2) => amount === undefined ? undefined : `${roundToFormat(amount, points)} GB`;
const base = 1024;
export const dataConvertFromTo = (value, from = DataSize.MB, to = DataSize.GB) => {
    return from >= to
        ? value * Math.pow(base, from - to)
        : value / Math.pow(base, to - from);
};
export const dataConvert = (value = 0, from = DataSize.MB) => {
    let to = from;
    while (value >= base && to < DataSize.TB) {
        // Assuming TB is the highest unit
        value /= base;
        to++;
    }
    return `${Math.round(value * 100) / 100} ${DataSize[to]}`;
};
