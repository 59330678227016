import React from "react";
import { useLocation } from "react-router-dom";
import AdminUsers from "@Components/Icons/AdminUsers";
import AwardedContract from "@Components/Icons/AwardedContract";
import Customer from "@Components/Icons/Customer";
import Dashboard from "@Components/Icons/Dashboard";
import LogoutIcon from "@Components/Icons/Logout";
import PhoneIcon from "@Components/Icons/Phone";
import ProfileIcon from "@Components/Icons/Profile";
import Supplier from "@Components/Icons/Supplier";
import UserIcon from "@Components/Icons/User";
import PrimaryNav from "@Components/PrimaryNav";
import GetPath, { GetReplacements } from "@Helpers/GetPath";
import IsPathMatch from "@Helpers/IsPathMatch";
import ProposalNavigation from "@SmartComponents/Proposal/Navigation";
import { AWARDED_CONTRACTS, CUSTOMERS, DEVICES, EDIT_SUPPLIER_RESPONSE, LOGOUT, MY_ACCOUNT, PARTNERS, ROOT, SAVE_SUPPLIER_RESPONSE, SUPPLIERS, USERS, } from "@Shared/Paths/Admin";
import styles from "./index.module.scss";
const topRoutes = [
    {
        path: ROOT,
        name: "Dashboard",
        icon: React.createElement(Dashboard, { className: styles.icon }),
        exact: true,
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: USERS,
        name: "Admin Users",
        icon: React.createElement(AdminUsers, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: CUSTOMERS,
        name: "Customers",
        icon: React.createElement(Customer, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: PARTNERS,
        name: "Partners",
        icon: React.createElement(UserIcon, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: SUPPLIERS,
        name: "Suppliers",
        icon: React.createElement(Supplier, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    // {
    //     path: TENDERS,
    //     name: "Tenders",
    //     icon: TenderIcon,
    // },
    {
        path: DEVICES,
        name: "Devices",
        icon: React.createElement(PhoneIcon, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: AWARDED_CONTRACTS,
        name: "Awarded Contracts",
        icon: React.createElement(AwardedContract, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    // {
    //     path: CONTRACTS,
    //     name: "Usage",
    //     icon: UsageIcon,
    // },
    {
        path: MY_ACCOUNT,
        name: "My Account",
        icon: React.createElement(ProfileIcon, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
];
const bottomRoutes = [
    {
        path: LOGOUT,
        name: "Logout",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
        filter: (url) => !IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
    {
        path: SAVE_SUPPLIER_RESPONSE,
        name: "Save & Exit",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
        filter: (url) => IsPathMatch(url, EDIT_SUPPLIER_RESPONSE),
    },
];
export default () => {
    const location = useLocation();
    const topRoutesFiltered = topRoutes
        .filter(({ filter = (path) => !!path }) => filter(location.pathname))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const bottomRoutesFiltered = bottomRoutes
        .filter(({ filter = (path) => !!path }) => filter(location.pathname))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    let extra = React.createElement(React.Fragment, null), className;
    if (IsPathMatch(location.pathname, EDIT_SUPPLIER_RESPONSE)) {
        extra = (React.createElement(ProposalNavigation, { responseId: location.pathname.replace(/.*?responses\/(.+?)\/edit/, `$1`) }));
        className = styles.setWidth;
    }
    return (React.createElement(PrimaryNav, { topLinks: topRoutesFiltered, bottomLinks: bottomRoutesFiltered, className: className }, extra));
};
