import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import Logout from "@Components/Pages/Logout";
import { CUSTOMERS, FEEDBACK, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, NOT_FOUND, PROPOSALS, ROOT, TENDERS, TENDER_RESPONSES, } from "@Shared/Paths/Supplier";
import MyAccount from "@SmartComponents/User/MyAccount";
import Customers from "./Customers";
import Dashboard from "./Dashboard";
import Feedback from "./Feedback";
import MyOrganisation from "./MyOrganisation";
import Proposals from "./Proposals";
import Responses from "./Responses";
import SearchTenders from "./SearchTenders";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ROOT, exact: true },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: TENDER_RESPONSES },
            React.createElement(Responses, null)),
        React.createElement(Route, { path: TENDERS },
            React.createElement(SearchTenders, null)),
        React.createElement(Route, { path: MY_ORGANISATION },
            React.createElement(MyOrganisation, null)),
        React.createElement(Route, { path: CUSTOMERS },
            React.createElement(Customers, null)),
        React.createElement(Route, { path: FEEDBACK },
            React.createElement(Feedback, null)),
        React.createElement(Route, { path: MY_ACCOUNT },
            React.createElement(MyAccount, null)),
        React.createElement(Route, { path: PROPOSALS },
            React.createElement(Proposals, null)),
        React.createElement(Route, { path: LOGOUT },
            React.createElement(Logout, null)),
        React.createElement(Route, { path: NOT_FOUND },
            React.createElement(NotFoundFallback, null))));
};
