import dayjs from "dayjs";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export default (original = {}) => {
    const converted = [version1].reduce((converted, f) => f(converted), original);
    let { version, name, addressLine1, addressLine2, city, region, country = "United Kingdom", postcode, status, sicCodes, updatedAt, } = converted;
    if (updatedAt) {
        updatedAt = dayjs.utc(updatedAt);
    }
    return {
        version,
        name,
        addressLine1,
        addressLine2,
        city,
        region,
        country,
        postcode,
        status,
        sicCodes,
        updatedAt,
    };
};
