import dayjs from "dayjs";
import React from "react";
import Button from "@Components/Button";
import RepeatGroup from "@Components/Form/RepeatGroup";
import SingleSlider from "@Components/SingleSlider";
import { displayDate } from "@Shared/Helpers/Date";
import styles from "./index.module.scss";
import MessageGroup from "./MessageGroup";
import Options from "./Options";
import TextInput from "./TextInput";
import Typing from "./Typing";
export var ChatWho;
(function (ChatWho) {
    ChatWho[ChatWho["PredictyBot"] = 0] = "PredictyBot";
    ChatWho[ChatWho["Staff"] = 1] = "Staff";
    ChatWho[ChatWho["Customer"] = 2] = "Customer";
    ChatWho[ChatWho["Supplier"] = 3] = "Supplier";
})(ChatWho || (ChatWho = {}));
export var SelectTypes;
(function (SelectTypes) {
    SelectTypes[SelectTypes["Options"] = 0] = "Options";
    SelectTypes[SelectTypes["MultiText"] = 1] = "MultiText";
    SelectTypes[SelectTypes["Slider"] = 2] = "Slider";
    SelectTypes[SelectTypes["DatePicker"] = 3] = "DatePicker";
    SelectTypes[SelectTypes["Multi"] = 4] = "Multi";
})(SelectTypes || (SelectTypes = {}));
export default class ChatBot extends React.Component {
    static defaultProps = {
        typing: false,
        select: {},
    };
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            selected: undefined,
        };
    }
    componentDidUpdate(previousProps) {
        if (this.props.select.type === SelectTypes.Multi) {
            const typeChanged = previousProps.select.type !== this.props.select.type;
            const typingChanged = previousProps.typing !== this.props.typing;
            if (typeChanged || typingChanged) {
                this.setState({
                    selected: this.props.select.data,
                    text: "[options selected]",
                });
            }
        }
    }
    inputChange = (name, value) => {
        this.setState({ text: value, selected: value });
    };
    submitChat = (event) => {
        event.preventDefault();
        if (!this.props.typing) {
            return this.sendValue();
        }
    };
    sendValue = () => {
        const { text, selected } = this.state;
        let value = text;
        if (selected !== undefined) {
            value = selected;
        }
        if (text === "") {
            return Promise.resolve();
        }
        let message = text;
        if (selected instanceof dayjs) {
            message = displayDate(selected);
        }
        this.setState({ text: "", selected: undefined });
        return this.props.output({ message, value });
    };
    select = ({ label, value }) => {
        if (!this.props.select.multi) {
            return this.props.output({ message: label, value });
        }
        this.setState({ text: label, selected: value });
    };
    multiTextInput = (name, tags) => {
        this.setState({ text: tags.join(", "), selected: tags });
    };
    sliderChange = (name, value) => {
        this.setState({ selected: value, text: String(value) });
    };
    multiChange = (data) => {
        this.setState({
            selected: data,
            text: "[options selected]",
        });
    };
    dropdownMultiChange = ({ option, }) => {
        const selected = [];
        let text = "";
        for (const element of option) {
            selected.push(element.value);
            text = `${text}${element.label}, `;
        }
        text = text.slice(0, Math.max(0, text.length - 2));
        this.setState({ selected, text });
    };
    dropdownChange = (options) => {
        const selected = options.map(({ value }) => value);
        this.setState({ selected, text: "[choices selected]" });
    };
    findComponent = () => {
        switch (this.props.select.type) {
            case SelectTypes.Slider:
                return (React.createElement(React.Fragment, null,
                    React.createElement(SingleSlider, { update: this.sliderChange, range: [0, 100], value: this.state.selected, name: "slider", ticks: 3, headers: this.props.select.headers(this.state.selected) }),
                    React.createElement(Button, { click: this.sendValue, className: styles.confirmButton }, "CONFIRM")));
            default:
                return (React.createElement(TextInput, { value: this.state.text, values: this.state.selected, valueChanged: this.inputChange, multiChanged: this.multiTextInput, submit: this.sendValue, type: this.props.select.type, validation: this.props.select.validation }));
        }
    };
    newGroup = (input1, input2) => {
        if (input1.who !== input2.who) {
            return true;
        }
        const { timestamp: timestamp1 } = input1;
        const { timestamp: timestamp2 } = input2;
        if (timestamp1.clone().add(2, "seconds").isBefore(timestamp2)) {
            return true;
        }
    };
    render() {
        const userInitials = this.props.user
            ? this.props.user.name
                .charAt(0)
                .toUpperCase()
            : "";
        const groups = this.props.chat
            .reduce((o, input, index, array) => {
            const newG = array[index - 1]
                ? this.newGroup(input, array[index - 1])
                : true;
            if (newG && o.group.messages.length > 0) {
                o.groups.push(o.group);
                o.group = { messages: [] };
            }
            o.group.messages.push(input);
            o.group.who = input.who;
            if (array[index + 1] === undefined) {
                o.groups.push(o.group);
            }
            return o;
        }, { groups: [], group: { messages: [] } })
            .groups.map((g, index, array) => {
            return { ...g, key: array.length - index };
        });
        const messages = groups.map((input, index) => {
            const avatar = input.who === ChatWho.PredictyBot ? "PM" : userInitials;
            const typing = 0 === index && input.who === ChatWho.PredictyBot
                ? this.props.typing
                : false;
            if (typing) {
                input.messages.unshift({
                    uid: "typing",
                    message: "...",
                    who: ChatWho.PredictyBot,
                    timestamp: dayjs.utc(),
                });
            }
            return React.createElement(MessageGroup, { ...input, avatar: avatar, typing: typing });
        });
        if (this.props.typing &&
            (!groups[0] || groups[0].who !== ChatWho.PredictyBot)) {
            messages.unshift(React.createElement(MessageGroup, { key: "typing", messages: [
                    {
                        uid: "typing",
                        message: "...",
                        who: ChatWho.PredictyBot,
                        timestamp: dayjs.utc(),
                    },
                ], who: ChatWho.PredictyBot, avatar: "PM", typing: true }));
        }
        return (React.createElement("div", { className: styles.chatFrame },
            React.createElement("div", { className: styles.chat }, messages),
            this.props.typing && React.createElement(Typing, null),
            !this.props.typing && (React.createElement("div", { className: styles.inputComponent },
                this.props.select.type === SelectTypes.Options && (React.createElement(Options, { options: this.props.select.options, select: this.select, multi: this.props.select.multi, selected: {
                        text: this.state.text,
                        value: this.state.selected,
                    }, change: this.dropdownChange })),
                this.props.select.type === SelectTypes.Multi && (React.createElement(RepeatGroup, { data: this.state.selected, change: this.multiChange, inputs: this.props.select.inputs, chat: true })))),
            React.createElement("form", { autoComplete: "off", onSubmit: this.submitChat.bind(this), className: styles.form }, this.findComponent())));
    }
}
