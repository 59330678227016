import React from "react";
import Button from "@Components/Button";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import Form, { InputType } from "@Components/Form";
import FormGroup from "@Components/Form/Group";
import HoverTooltip from "@Components/HoverTooltip";
import PredictyIconEyesOpen from "@Components/Icons/ChatBot/Predicty/PredictyEyesOpen";
import PredictMascotLeftIcon from "@Components/Icons/PredictMascotLeft";
import InfoPopup from "@Components/InfoPopup";
import buttonStyles from "@Components/Sass/buttons.module.scss";
import getBrokerName from "@Helpers/GetBrokerName";
import { validation } from "@Shared/Validate";
import constraints from "./constraints";
import styles from "./index.module.scss";
export default class CreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errors: {},
            companyFound: false,
            companyErrors: [],
        };
    }
    componentDidUpdate(previousProps, previousState) {
        if (this.state.data.companyNo !== previousState.data.companyNo) {
            this.setState({
                companyFound: false,
                data: {
                    ...this.state.data,
                    company: {},
                    canCreateTender: true,
                },
            });
        }
    }
    inputChange = (data) => {
        this.setState({ data });
    };
    submit = async () => {
        if (this.state.companyFound) {
            try {
                validation(this.state.data, constraints);
                this.props.create(this.state.data).catch(({ status }) => {
                    if (status === 405) {
                        //need a solution to tell them the email is already in use
                        this.setState({
                            errors: { email: true },
                        });
                    }
                });
            }
            catch (error) {
                this.setState({ errors: error.fails });
            }
            return;
        }
        await this.findCompany();
    };
    findCompany = async () => {
        this.setState({ errors: {}, companyErrors: [] });
        const companyErrors = [];
        const { companyNo } = constraints;
        try {
            validation(this.state.data, {
                companyNo,
            });
        }
        catch (error) {
            for (let index = 0; index < error.fails?.companyNo?.length || 0; index++) {
                companyErrors.push(error.fails.companyNo[index]);
            }
            this.setState({
                errors: { companyNo: true },
                companyErrors,
            });
            return;
        }
        return this.props
            .findCompany(this.state.data.companyNo)
            .then((companyData) => {
            if (companyData.status === "dissolved") {
                companyErrors.push("This company has been dissolved");
                const data = {
                    ...this.state.data,
                    company: {},
                };
                this.setState({
                    data,
                    companyFound: false,
                    companyErrors,
                });
                return;
            }
            const data = {
                ...this.state.data,
                company: { ...companyData },
            };
            this.setState({
                data,
                companyFound: true,
            });
        })
            .catch(({ error = "" }) => {
            const errorMessage = error.replace(/.*Exception: /, "");
            companyErrors.push(errorMessage);
            const data = {
                ...this.state.data,
                company: {},
            };
            this.setState({
                data,
                companyFound: false,
                companyErrors,
            });
        });
    };
    getRobotText = (sensabill) => {
        if (sensabill) {
            return (React.createElement(React.Fragment, null, "We do not provide your details to third parties. Information is only used to generate analysis and\u00A0predictions."));
        }
        return (React.createElement(React.Fragment, null, "We do not provide your details to third parties. Information is only shared through the platform to enable the best fit tariff and service\u00A0solution."));
    };
    render() {
        const { data, companyErrors, companyFound } = this.state;
        const { addressLine1, addressLine2, city, country, name, postcode } = data.company || {};
        const { sensabill = false } = this.props;
        const companyDetails = [
            name,
            addressLine1,
            addressLine2,
            city,
            country,
            postcode,
        ]
            .filter((element) => !!element)
            .map((element) => {
            return (React.createElement("span", { className: styles.addressSpan, key: element }, element));
        });
        const popup = () => {
            return (React.createElement("div", { className: styles.wrapper },
                React.createElement(ColouredBorderBox, { primary: true, title: React.createElement("div", { className: styles.header },
                        React.createElement("b", null,
                            "Please create your",
                            " ",
                            React.createElement("span", { className: styles.highlight }, sensabill
                                ? "Sensabill"
                                : getBrokerName()),
                            " ",
                            "account")) },
                    React.createElement("div", { className: styles.container },
                        React.createElement("div", { className: styles.formWrapper },
                            React.createElement("div", { className: styles.centered },
                                React.createElement(PredictyIconEyesOpen, { className: styles.logo })),
                            React.createElement("div", { className: styles.form },
                                React.createElement(Form, { change: this.inputChange, labelLeft: false, data: this.state.data, errors: this.state.errors, submit: this.submit, buttons: false, rightButton: true, input: [
                                        {
                                            label: "Name",
                                            name: "name",
                                            type: InputType.Text,
                                        },
                                        {
                                            label: "Email",
                                            name: "email",
                                            type: InputType.Email,
                                        },
                                        {
                                            label: (React.createElement(React.Fragment, null,
                                                "Create a Password",
                                                " ",
                                                React.createElement(HoverTooltip, null, "Must be at least 8 characters long and contain at least one special character"))),
                                            name: "password",
                                            type: InputType.Password,
                                            showPassword: true,
                                        },
                                        {
                                            label: "Company Number",
                                            name: "companyNo",
                                            type: InputType.Text,
                                        },
                                    ] },
                                    React.createElement(FormGroup, null,
                                        React.createElement("div", { className: buttonStyles.buttonGroupRight },
                                            React.createElement(Button, { cta: companyFound, type: "submit" }, companyFound
                                                ? "CREATE"
                                                : "FIND COMPANY")))))),
                        (companyErrors.length > 0 ||
                            companyDetails.length > 0) && (React.createElement("div", { className: styles.formWrapper },
                            React.createElement("div", { className: styles.companyTitle }, "Your company's details"),
                            React.createElement("div", { className: styles.form },
                                React.createElement("div", { className: styles.companyDetails }, companyDetails.length > 0
                                    ? companyDetails
                                    : (companyErrors.length > 0 && (React.createElement("div", { className: styles.companyDetailsErrors }, companyErrors.map((error) => (React.createElement("span", { className: styles.addressSpan, key: error },
                                        "Sorry,",
                                        " ",
                                        error,
                                        " ")))))) || React.createElement(React.Fragment, null, "No details found"))))))),
                React.createElement(ColouredBorderBox, { primary: true, title: React.createElement("div", { className: styles.title }, "How we manage your information") },
                    React.createElement("div", { className: styles.robotWrapper },
                        React.createElement("div", { className: styles.styleWrapper },
                            React.createElement("div", { className: styles.robotBox },
                                this.getRobotText(sensabill),
                                React.createElement(PredictMascotLeftIcon, { className: styles.robotIcon })))))));
        };
        return (React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose }));
    }
}
