import React from "react";
import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import styles from "@Components/SingleSlider/index.module.scss";
import { Handle, SliderRail, Tick, Track } from "./components";
const sliderStyle = {
    position: "relative",
    width: "100%",
    touchAction: "none",
};
export default class DoubleSlider extends React.Component {
    static defaultProps = {
        value: [10, 90],
        range: [0, 100],
    };
    constructor(props) {
        super(props);
        this.state = {
            values: [...this.props.value],
        };
    }
    componentDidUpdate() {
        if (this.state.values.join("-") !== this.props.value.join("-")) {
            this.setState({
                values: [...this.props.value],
            });
        }
    }
    onUpdate = (update) => {
        if (update.join("-") !== this.props.value.join("-")) {
            this.props.update(this.props.name, update);
        }
    };
    render() {
        const { values } = this.state;
        const { range } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement(Slider, { mode: 2, step: 1, domain: range, rootStyle: sliderStyle, onSlideEnd: this.onUpdate, values: values },
                    React.createElement(Rail, null, ({ getRailProps }) => (React.createElement(SliderRail, { getRailProps: getRailProps, disabled: this.props.disabled }))),
                    React.createElement(Handles, null, ({ handles, getHandleProps }) => (React.createElement("div", { className: "slider-handles" }, handles.map((handle) => (React.createElement(Handle, { key: handle.id, handle: handle, range: range, getHandleProps: getHandleProps, disabled: this.props.disabled, error: this.props.error })))))),
                    React.createElement(Tracks, { left: false, right: false }, ({ tracks, getTrackProps }) => (React.createElement("div", { className: "slider-tracks" }, tracks.map(({ id, source, target }) => (React.createElement(Track, { key: id, source: source, target: target, getTrackProps: getTrackProps, error: this.props.error })))))),
                    React.createElement(Ticks, { count: 3 }, ({ ticks }) => (React.createElement("div", { className: "slider-ticks" }, ticks.map((tick) => (React.createElement(Tick, { key: tick.id, tick: tick, count: ticks.length })))))))),
            React.createElement("div", { className: `${styles.errorWrapper} ${styles.ticks}` })));
    }
}
