import SVG from "@Components/Icons/SVG";
export default class LeftArrow extends SVG {
    static defaultProps = {
        size: `1.5vw`,
        className: "",
        rotate: 0,
        fillOpacity: 1,
        onClick: () => { },
        edit: false,
        primary: false,
        dark: false,
        light: false,
        create: false,
        delete: false,
        noFill: true,
    };
    strokeDetails = {
        stroke: "#58585A",
        strokeWidth: "4",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    path = ["M 75 100 L 25 50 L 75 0"];
}
