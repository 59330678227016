import { collection } from "@Api/helpers";
import api from "@Api/index";
import Factory from "@Shared/Factory";
export const get = (callback, customerId, tenderId, showAll = false) => {
    const url = `/customers/${customerId}/tenders/${tenderId}/responses/${showAll}`;
    return collection((data) => callback(Factory.Response(data)), url);
};
export const getForPartner = (callback, partnerId, tenderId, showAll = false) => {
    const url = `/partners/${partnerId}/tenders/${tenderId}/responses/${showAll}`;
    return collection((data) => callback(Factory.Response(data)), url);
};
export const getForScoring = (callback, customerId, tenderId) => {
    const url = `/customers/${customerId}/tenders/${tenderId}/responses-scores`;
    return collection((data) => callback(Factory.Response(data)), url);
};
export const find = (customerId, tenderId, responseId) => {
    const url = `/comparisons/${customerId}/tenders/${tenderId}/responses/${responseId}`;
    return api.get(url).then((result) => Factory.Response(result.data.item));
};
export const markFavouriteResponse = (customerId, tenderId, responseId) => {
    const url = `/comparisons/${customerId}/tenders/${tenderId}/responses/${responseId}`;
    return api.put(url).then((result) => Factory.Response(result.data.item));
};
