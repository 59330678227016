import SVG from "@Components/Icons/SVG";
export default class LogoutIcon extends SVG {
    path = [
        "M97.95 44.2",
        "C98.15 43.74 98.15 43.24 97.95 42.82",
        "C97.88 42.6 97.73 42.4 97.57 42.21",
        "L75.82 20.46",
        "C75.09 19.73 73.95 19.73 73.26 20.46",
        "C72.53 21.18 72.53 22.33 73.26 23.02",
        "L91.92 41.68",
        "H52.76",
        "C51.77 41.68 50.97 42.48 50.97 43.47",
        "S51.77 45.27 52.76 45.27",
        "H91.89",
        "L73.22 63.93",
        "C72.5 64.66 72.5 65.8 73.22 66.49",
        "C73.56 66.83 74.02 67.02 74.52 67.02",
        "S75.44 66.83 75.82 66.49",
        "L97.57 44.73",
        "C97.73 44.62 97.84 44.43 97.95 44.2",
        "Z",
        "M65.44 52.56",
        "C64.44 52.56 63.64 53.36 63.64 54.35",
        "V83.36",
        "H45.51",
        "V14.5",
        "C45.51 13.7 45.02 13.02 44.25 12.79",
        "L15.51 3.63",
        "H63.64",
        "V32.63",
        "C63.64 33.63 64.44 34.43 65.44 34.43",
        "S67.23 33.63 67.23 32.63",
        "V1.79",
        "C67.23.8 66.43 0 65.44 0",
        "H3.79",
        "C3.76 0 3.72.04 3.64.04",
        "C3.53.04 3.45.08 3.34.11",
        "C3.18.15 3.03.19 2.88.31",
        "C2.84.31 2.8.31 2.73.34",
        "C2.69.38 2.65.46 2.61.5",
        "C2.57.53 2.53.53 2.5.57",
        "C2.34.73 2.27.88 2.15 1.07",
        "C2.15 1.11 2.11 1.15 2.11 1.18",
        "C2.04 1.37 2 1.6 2 1.79",
        "V85.19",
        "C2 85.42 2.04 85.65 2.11 85.84",
        "C2.15 85.92 2.19 85.95 2.23 86.03",
        "C2.31 86.15 2.38 86.26 2.46 86.37",
        "C2.5 86.45 2.57 86.49 2.61 86.53",
        "C2.73 86.6 2.84 86.68 2.95 86.76",
        "C3.03 86.79 3.07 86.83 3.15 86.87",
        "C3.18 86.87 3.18 86.91 3.22 86.91",
        "L43.15 99.62",
        "C43.34 99.66 43.49 99.69 43.68 99.69",
        "C44.06 99.69 44.44 99.58 44.75 99.35",
        "C45.21 99.01 45.47 98.47 45.47 97.9",
        "V87.02",
        "H65.4",
        "C66.39 87.02 67.19 86.22 67.19 85.23",
        "V54.43",
        "C67.27 53.4 66.47 52.56 65.44 52.56",
        "Z",
        "M41.89 95.42 5.63 83.89",
        "V4.27",
        "L41.89 15.8",
        "V95.42",
        "Z",
    ];
}
