import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (customerId = null, inclDel) => {
    const url = `/customers/${customerId}`;
    return api
        .get(url, { params: { inclDel } })
        .then((result) => Factory.Customer(result.data.item));
};
export const get = (callback) => collection((data) => callback(Factory.Customer(data)), `/customers`);
export const post = (customer) => {
    return api
        .post(`/customers`, customer.data)
        .then((result) => {
        return Factory.Customer(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (customer) => {
    return api
        .put(`/customers/${customer.id}`, customer.data)
        .then((result) => {
        return Factory.Customer(result.data.item);
    });
};
export const del = (customer) => {
    return api.delete(`/customers/${customer.id}`).then((result) => {
        return result.data.item;
    });
};
