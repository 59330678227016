import SVG from "@Components/Icons/SVG";
export default class Phone extends SVG {
    path = [
        "M 13 0",
        "H 4",
        "C 2 0 0 2 0 4",
        "V 96",
        "C 0 98 2 100 4 100",
        "H 53",
        "C 55 100 57 98 57 96",
        "V 4",
        "C 57 2 55 0 53 0",
        "H 13",
        "M 13 4",
        "H 6 C 4 4 4 4 4 6",
        "V 94",
        "C 4 96 4 96 6 96",
        "H 51",
        "C 53 96 53 96 53 94",
        "V 6",
        "C 53 4 53 4 51 4",
        "H 13",
        "M 13 4",
        "L 15 9",
        "C 15 10 17 11 18 11",
        "H 39",
        "C 40 11 42 10 42 9",
        "L 44 4",
        "M 15 4",
        "L 17 8",
        "C 17 9 17 9 18 9",
        "H 39",
        "C 40 9 40 9 40 8",
        "L 42 4",
        "M 57 9",
        "H 91",
        "C 93 9 95 11 95 13",
        "V 87",
        "C 95 89 93 91 91 91",
        "H 57",
        "M 61 13",
        "H 89",
        "C 91 13 91 13 91 15",
        "V 85",
        "C 91 87 91 87 89 87",
        "H 61",
        "Z",
        "M 61 20",
        "H 91",
        "M 61 24",
        "H 91",
        "V 20",
        "H 61",
        "Z",
        "M 61 74",
        "H 91",
        "M 61 78",
        "H 91",
        "V 74",
        "H 61",
        "Z",
        "M 78 83",
        "C 78 81 75 81 75 83",
        "C 75 85 78 85 78 83",
    ];
    evenOdd = true;
}
