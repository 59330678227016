import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put } from "@Api/Partners/users";
export const DELETE_PARTNER_USER = "deletePartnerUser";
export const deleteUser = (dispatch, user) => del(user)
    .then((id) => {
    dispatch({
        type: DELETE_PARTNER_USER,
        data: id,
    });
})
    .then(notify200(dispatch, API_TYPE.DELETE, "Partner User Deleted", "The partner user(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_PARTNER_USER = "updatePartnerUser";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_PARTNER_USER,
        data,
    });
};
export const createUser = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Partner User Created", "The partner user was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateUser = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Partner User Updated", "The partner user was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchUser = (dispatch, partnerId, userId) => find(partnerId, userId).then(add(dispatch));
export const fetchUsers = (dispatch, partnerId, inclDel) => get(add(dispatch), partnerId, inclDel);
