// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--XRJI4{display:flex;flex-direction:column;width:100%;overflow:hidden}.container--XRJI4 .content--fPC7F{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container--XRJI4{flex-direction:row}}.wrapper--Ke7Iz{max-width:800px;margin:auto !important}.wrapper--Ke7Iz .content--fPC7F div{margin:.75rem 0}", "",{"version":3,"sources":["webpack://./src/gui/Components/SupplierSupport/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,YAAY,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,kCAAoB,YAAY,CAAC,SAAS,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,yBAAyB,kBAAW,kBAAkB,CAAC,CAAC,gBAAS,eAAe,CAAC,sBAAsB,CAAC,oCAAsB,eAAe","sourcesContent":[".container{display:flex;flex-direction:column;width:100%;overflow:hidden}.container .content{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container{flex-direction:row}}.wrapper{max-width:800px;margin:auto !important}.wrapper .content div{margin:.75rem 0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--XRJI4",
	"content": "content--fPC7F",
	"wrapper": "wrapper--Ke7Iz"
};
export default ___CSS_LOADER_EXPORT___;
