// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav--W4UQW{display:flex;justify-content:center;width:100%;height:100%}.nav--W4UQW .link--NljCq{display:flex;justify-content:center;width:2em;height:2em;line-height:2em;cursor:pointer}.nav--W4UQW .link--NljCq.selected--Z7QWr{color:var(--selected-input-color)}.nav--W4UQW .link--NljCq.unclickable--YvJIT{cursor:auto}.nav--W4UQW .link--NljCq:first-child{border-radius:5px 0 0 5px}.nav--W4UQW .link--NljCq:last-child{border-radius:0 5px 5px 0}.nav--W4UQW .link--NljCq.nextToSelected--FNJQr{border-right:none}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/TableNav/Pagination/index.module.scss"],"names":[],"mappings":"AAAA,YAAK,YAAY,CAAC,sBAAsB,CAAC,UAAU,CAAC,WAAW,CAAC,yBAAW,YAAY,CAAC,sBAAsB,CAAC,SAAS,CAAC,UAAU,CAAC,eAAe,CAAC,cAAc,CAAC,yCAAoB,iCAAiC,CAAC,4CAAuB,WAAW,CAAC,qCAAuB,yBAAyB,CAAC,oCAAsB,yBAAyB,CAAC,+CAA0B,iBAAiB","sourcesContent":[".nav{display:flex;justify-content:center;width:100%;height:100%}.nav .link{display:flex;justify-content:center;width:2em;height:2em;line-height:2em;cursor:pointer}.nav .link.selected{color:var(--selected-input-color)}.nav .link.unclickable{cursor:auto}.nav .link:first-child{border-radius:5px 0 0 5px}.nav .link:last-child{border-radius:0 5px 5px 0}.nav .link.nextToSelected{border-right:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "nav--W4UQW",
	"link": "link--NljCq",
	"selected": "selected--Z7QWr",
	"unclickable": "unclickable--YvJIT",
	"nextToSelected": "nextToSelected--FNJQr"
};
export default ___CSS_LOADER_EXPORT___;
