// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--nk0ob{gap:1vw;margin-bottom:1em;display:flex;align-items:center;justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Header/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,OAAO,CAAC,iBAAiB,CAAC,YAAY,CAAC,kBAAkB,CAAC,0BAA0B","sourcesContent":[".header{gap:1vw;margin-bottom:1em;display:flex;align-items:center;justify-content:flex-start}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--nk0ob"
};
export default ___CSS_LOADER_EXPORT___;
