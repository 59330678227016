import { ADD_NOTIFICATION, DELETE_NOTIFICATION, } from "../../Actions/Notifications";
export default (state = [], { type, data }) => {
    switch (type) {
        case ADD_NOTIFICATION:
            return [data, ...state];
        case DELETE_NOTIFICATION:
            return state.filter(({ id }) => id !== data.id);
    }
    return state;
};
