import { combineReducers } from "redux";
import { withReduxStateSync } from "redux-state-sync";
import actions from "./Actions";
import auth from "./Auth";
import awardedContracts from "./AwardedContracts";
import customers from "./Customers";
import hardware from "./Hardware";
import notifications from "./Notifications";
import partners from "./Partners";
import suppliers from "./Suppliers";
import users from "./Users";
export default withReduxStateSync(combineReducers({
    actions,
    auth,
    awardedContracts,
    customers,
    hardware,
    notifications,
    partners,
    suppliers,
    users,
}));
