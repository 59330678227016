import React from "react";
import Button from "@Components/Button";
import styles from "./index.module.scss";
export default class FileUploader extends React.Component {
    filesInput;
    handleChange = (event) => {
        const file = this.props.multi
            ? [...event.target.files]
            : event.target.files[0];
        return this.props.upload(file);
    };
    click = () => {
        this.filesInput.click();
    };
    render() {
        const className = this.props.cta ? styles.cta : "";
        const left = this.props.left !== undefined && this.props.left === true;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { click: this.click, left: left, className: className, disabled: this.props.disabled }, this.props.children || "UPLOAD"),
            React.createElement("input", { className: styles.input, type: "file", ref: (input) => {
                    this.filesInput = input;
                }, name: "file", placeholder: null, multiple: this.props.multi, onChange: this.handleChange })));
    }
}
