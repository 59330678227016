import React from "react";
import LeftArrow from "@Components/Icons/Pagination/LeftArrow";
import RightArrow from "@Components/Icons/Pagination/RightArrow";
import styles from "./index.module.scss";
export default class Pagination extends React.Component {
    render() {
        const { pages, current } = this.props;
        const links = [];
        for (let index = 1; index <= pages; index++) {
            if (index <= 3) {
                links.push(index);
            }
            else if (index >= pages - 2) {
                links.push(index);
            }
            else if (index >= current - 1 && index <= current + 1) {
                links.push(index);
            }
            else if (links.at(-1) !== false) {
                links.push(false);
            }
        }
        return (React.createElement("div", { className: styles.nav },
            React.createElement("div", { className: `
                            ${styles.link}
                            ${current === 1 ? styles.nextToSelected : ""}
                        `, onClick: () => {
                    if (current !== 1) {
                        this.props.changePage(current - 1);
                    }
                } },
                React.createElement(LeftArrow, { size: "1em" })),
            links.map((number_, index) => {
                if (typeof number_ !== "number") {
                    return (React.createElement("div", { className: `
                            ${styles.link}
                            ${styles.unclickable}
                            `, key: `gap-${index}` }, "..."));
                }
                return (React.createElement("div", { className: `
                                    ${styles.link} 
                                    ${number_ === current
                        ? styles.selected
                        : ""}
                                    ${number_ === current - 1
                        ? styles.nextToSelected
                        : ""}
                                `, onClick: () => this.props.changePage(number_), key: number_ }, number_));
            }),
            React.createElement("div", { className: styles.link, onClick: () => {
                    if (current !== pages) {
                        this.props.changePage(current + 1);
                    }
                } },
                React.createElement(RightArrow, { size: "1em" }))));
    }
}
