import { del, find, get, post, put } from "@Api/Customers/companies";
import { API_TYPE, notify200, notify4xx } from "../Notifications";
export const DELETE_CUSTOMER = "deleteCustomer";
export const deleteCompany = (dispatch, data) => del(data)
    .then(({ id }) => dispatch({
    type: DELETE_CUSTOMER,
    data: id,
}))
    .then(notify200(dispatch, API_TYPE.DELETE, "Customer Deleted", "The customer(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_CUSTOMER = "updateCustomer";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_CUSTOMER,
        data,
    });
};
export const createCompany = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Customer Created", "The customer was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateCompany = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Customer Updated", "The customer was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchCompany = (dispatch, customerId, inclDel) => find(customerId, inclDel).then(add(dispatch));
export const fetchCompanies = (dispatch) => get(add(dispatch));
