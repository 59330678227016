import React from "react";
import SVG from "@Components/Icons/SVG";
import styles from "./index.module.scss";
export default class KeepingAll extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.wrapper, style: {
                width: this.props.size,
                height: this.props.size,
            } },
            React.createElement(LeftDevice, { className: styles.item, ...this.props }),
            React.createElement(MiddleDevice, { className: styles.item, ...this.props }),
            React.createElement(RightDevice, { className: styles.item, ...this.props })));
    }
}
class LeftDevice extends SVG {
    path = [
        "M 26.8136 9.5901 H 6.5082 C 4.7821 9.5901 3.1267 10.2599 1.9063 11.4519",
        "C 0.6857 12.644 0 14.2609 0 15.9467 L 0 57.2645 C 0 58.9503 0.6857 60.5672 1.9063 61.7593",
        "C 3.1267 62.9513 4.7821 63.621 6.5082 63.621 H 26.8136",
        "C 28.5397 63.621 30.1951 62.9513 31.4155 61.7593",
        "C 32.6361 60.5672 33.3218 58.9503 33.3218 57.2645 V 15.9467",
        "C 33.3218 14.2609 32.6361 12.644 31.4155 11.4519",
        "C 30.1951 10.2599 28.5397 9.5901 26.8136 9.5901 V 9.5901 M 28.7985 57.2645",
        "C 28.7987 57.5176 28.747 57.7682 28.6469 58.0016 C 28.5467 58.2351 28.4 58.4467 28.2152 58.6242",
        "C 28.0304 58.8018 27.8113 58.9415 27.5707 59.0355 C 27.3301 59.1294 27.0727 59.1757 26.8136 59.1713",
        "H 6.5082 C 5.9903 59.1713 5.4937 58.9704 5.1276 58.6128",
        "C 4.7613 58.2552 4.5557 57.7701 4.5557 57.2645 V 15.9467",
        "C 4.5557 15.4409 4.7613 14.956 5.1276 14.5982 C 5.4937 14.2406 5.9903 14.0397 6.5082 14.0397",
        "H 7.224 L 10.1852 16.487 C 10.5864 16.8406 11.1093 17.0336 11.6496 17.0273",
        "H 21.7046 C 22.2428 17.0218 22.7616 16.8304 23.169 16.487 L 26.0325 14.0397",
        "H 26.7485 C 27.0076 14.0355 27.2651 14.0818 27.5057 14.1757",
        "C 27.7463 14.2696 27.9654 14.4094 28.1501 14.5869",
        "C 28.3349 14.7645 28.4816 14.9761 28.5818 15.2096",
        "C 28.6819 15.443 28.7336 15.6936 28.7334 15.9467 L 28.7985 57.2645 V 15.9467",
    ];
}
class MiddleDevice extends SVG {
    path = [
        "M 36.7839 0.139 H 63.181 C 65.3285 0.139 67.3864 0.9742 68.9025 2.4585",
        "C 70.4184 3.9425 71.2685 5.9536 71.2685 8.0488 V 61.8882",
        "C 71.2685 63.9836 70.4184 65.9946 68.9025 67.4787",
        "C 67.3864 68.9628 65.3285 69.7981 63.181 69.7981 H 36.7839",
        "C 34.6364 69.7981 32.5785 68.9628 31.0624 67.4787",
        "C 29.5464 65.9946 28.6964 63.9836 28.6964 61.8882 V 8.0488",
        "C 28.6964 5.9536 29.5464 3.9425 31.0624 2.4585 C 32.5785 0.9742 34.6364 0.139 36.7839 0.139",
        "M 66.1346 61.8882 V 61.8876 L 66.05 8.049 C 66.05 8.0487 66.05 8.0485 66.05 8.0482",
        "C 66.05 7.6679 65.9727 7.2918 65.8227 6.9415 L 65.4797 7.0882 L 65.8227 6.9415",
        "C 65.6727 6.591 65.4533 6.2737 65.1773 6.0082 L 64.9185 6.277 L 65.1773 6.0082",
        "C 64.9015 5.7425 64.5749 5.5337 64.2167 5.3936",
        "C 63.8594 5.2537 63.4776 5.1849 63.0934 5.1907 H 62.1657 H 62.0278",
        "L 61.923 5.2806 L 58.2022 8.4679 C 58.2019 8.4681 58.2016 8.4684 58.2013 8.4687",
        "C 57.7393 8.8587 57.15 9.0772 56.5375 9.0837 H 43.4678 V 9.0837",
        "L 43.4634 9.0839 C 42.8525 9.0909 42.2627 8.8722 41.8112 8.4733",
        "L 41.8113 8.4731 L 41.8021 8.4655 L 37.9525 5.2766 L 37.849 5.1907",
        "H 37.7145 H 36.7839 C 36.0142 5.1907 35.2746 5.49 34.7281 6.0251",
        "C 34.1812 6.5604 33.8725 7.2882 33.8725 8.0488 V 61.8882",
        "C 33.8725 62.6488 34.1812 63.3767 34.7281 63.9119",
        "C 35.2746 64.447 36.0142 64.7463 36.7839 64.7463 H 63.1782",
        "C 63.5624 64.7521 63.944 64.6833 64.3012 64.5436",
        "C 64.6596 64.4033 64.9861 64.1946 65.2619 63.929",
        "C 65.5379 63.6633 65.7573 63.3461 65.9073 62.9957",
        "C 66.0573 62.6452 66.1346 62.2687 66.1346 61.8882",
    ];
}
class RightDevice extends SVG {
    path = [
        "M 93.457 9.5901 H 73.1516 C 71.4255 9.5901 69.7701 10.2599 68.5497 11.4519",
        "C 67.3291 12.644 66.6434 14.2609 66.6434 15.9467 V 57.2645",
        "C 66.6434 58.9503 67.3291 60.5672 68.5497 61.7593",
        "C 69.7701 62.9513 71.4255 63.621 73.1516 63.621 H 93.457",
        "C 95.1831 63.621 96.8385 62.9513 98.059 61.7593",
        "C 99.2796 60.5672 99.9652 58.9503 99.9652 57.2645 V 15.9467",
        "C 99.9652 14.2609 99.2796 12.644 98.059 11.4519 C 96.8385 10.2599 95.1831 9.5901 93.457 9.5901",
        "M 95.4421 57.2645 C 95.4421 57.5176 95.3904 57.7682 95.2903 58.0016",
        "C 95.1901 58.2351 95.0434 58.4467 94.8587 58.6242",
        "C 94.674 58.8018 94.4549 58.9415 94.2142 59.0355",
        "C 93.9736 59.1294 93.7161 59.1757 93.457 59.1713 H 73.1516",
        "C 72.6337 59.1713 72.1372 58.9704 71.771 58.6128",
        "C 71.4049 58.2552 71.1991 57.7701 71.1991 57.2645 V 15.9467",
        "C 71.1991 15.4409 71.4049 14.956 71.771 14.5982",
        "C 72.1372 14.2406 72.6337 14.0397 73.1516 14.0397 H 73.8675 L 76.8287 16.487",
        "C 77.2299 16.8406 77.7527 17.0336 78.293 17.0273 H 88.3482",
        "C 88.8863 17.0218 89.4051 16.8304 89.8125 16.487 L 92.6761 14.0397",
        "H 93.3919 C 93.651 14.0355 93.9085 14.0818 94.1491 14.1757",
        "C 94.3899 14.2696 94.6088 14.4094 94.7936 14.5869",
        "C 94.9784 14.7645 95.1251 14.9761 95.2252 15.2096",
        "C 95.3254 15.443 95.377 15.6936 95.377 15.9467 L 95.4421 57.2645",
    ];
}
