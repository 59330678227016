import SVG from "@Components/Icons/SVG";
export default class ProfileIcon extends SVG {
    path = [
        "M 40 13",
        "H 5",
        "C 2 13 0 15 0 18",
        "V 80",
        "C 0 83 2 85 5 85",
        "H 95",
        "C 98 85 100 83 100 80",
        "V 18",
        "C 100 15 98 13 95 13",
        "H 58",
        "V 17",
        "H 93",
        "C 96 17 96 17 96 20",
        "V 78",
        "C 96 81 96 81 93 81",
        "H 7",
        "C 4 81 4 81 4 78",
        "V 20",
        "C 4 17 4 17 7 17",
        "H 40",
        "Z",
        "M 54 40",
        "H 69",
        "V 36",
        "H 54",
        "Z",
        "M 54 50",
        "H 86",
        "V 46",
        "H 54",
        "Z",
        "M 54 60",
        "H 84",
        "V 56",
        "H 54",
        "Z",
        "M 54 70",
        "H 59",
        "V 66",
        "H 54",
        "Z",
        "M 64 70",
        "H 65",
        "V 66",
        "H 64",
        "Z",
        "M 70 70",
        "H 74",
        "V 66",
        "H 70",
        "Z",
        "M 80 70",
        "H 79",
        "V 66",
        "H 80",
        "Z",
        "M 49 7",
        "C 51 7 52 8 52 10",
        "C 52 12 51 13 49 13",
        "C 47 13 46 12 46 10",
        "C 46 8 47 7 49 7",
        "Z",
        "M 13 68",
        "V 70",
        "C 13 71 13 71 13 71",
        "C 19 73 36 73 42 71",
        "C 42 71 43 71 43 70",
        "L 43 68",
        "C 43 67 42 67 42 66",
        "C 41 65 41 64 40 64",
        "L 33 61",
        "C 33 61 32 60 32 60",
        "C 32 60 32 59 32 59",
        "V 56",
        "C 32 56 32 56 32 56",
        "C 35 55 37 52 38 48",
        "C 38 48 38 48 38 48",
        "C 39 48 39 48 40 47",
        "C 41 46 41 44 40 42",
        "C 40 41 39 41 39 41",
        "C 39 41 39 41 38 41",
        "C 38 35 33 32 28 32",
        "C 25 32 23 32 20 31",
        "L 19 30",
        "C 19 30 19 30 19 31",
        "C 19 32 18 33 17 35",
        "C 16 37 16 39 16 41",
        "C 16 41 16 41 16 41",
        "C 16 42 15 42 15 42",
        "C 14 43 14 46 15 47",
        "C 15 48 17 48 17 48",
        "C 17 48 17 48 17 48",
        "C 17 52 20 55 23 56",
        "C 23 56 23 56 23 56",
        "V 59",
        "C 23 59 23 59 23 60",
        "C 22 60 22 60 22 61",
        "L 15 64",
        "C 14 64 14 65 13 66",
        "C 13 67 13 67 13 68",
        "Z",
        "M 58 5",
        "V 19",
        "C 58 22 55 24 53 24",
        "H 45",
        "C 42 24 40 22 40 19",
        "V 5",
        "C 40 2 42 0 45 0",
        "H 53",
        "C 55 0 58 2 58 5",
        "Z",
        "M 54 7",
        "V 17",
        "C 54 20 53 20 51 20",
        "H 47",
        "C 45 20 44 20 44 17",
        "V 7",
        "C 44 4 45 4 47 4",
        "H 51",
        "C 53 4 54 4 54 7",
        "M 49 8",
        "C 50 8 51 9 51 10",
        "C 51 11 50 12 49 12",
        "C 48 12 47 11 47 10",
        "C 47 9 48 8 49 8",
        "M 15 68",
        "V 69",
        "C 15 69 15 68 15 69",
        "C 19 70 36 70 40 69",
        "C 40 69 41 69 41 69",
        "L 41 69",
        "C 41 68 40 68 40 67",
        "C 39 66 39 66 38 66",
        "L 33 64",
        "C 33 64 32 63 32 63",
        "C 31 63 30 61 30 61",
        "V 55",
        "C 30 55 30 55 31 54",
        "C 31 54 35 51 36 47",
        "C 36 47 36 47 36 47",
        "C 38 47 38 47 38 46",
        "C 39 45 39 44 38 43",
        "C 38 43 38 42 37 42",
        "C 37 42 36 42 36 42",
        "C 36 38 33 35 28 35",
        "C 25 35 23 35 21 34",
        "L 20 33",
        "C 20 31 20 32 20 33",
        "C 20 35 20 33 20 36",
        "C 18 38 18 40 18 42",
        "C 18 42 18 42 18 42",
        "C 18 43 17 43 17 43",
        "C 16 44 16 45 17 46",
        "C 17 47 17 47 19 47",
        "C 19 47 19 47 19 47",
        "C 19 51 22 54 25 55",
        "V 61",
        "C 24 61 24 61 24 62",
        "L 17 65",
        "C 16 65 16 66 15 67",
        "Z",
    ];
    evenOdd = true;
}
