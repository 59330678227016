export var Status;
(function (Status) {
    Status[Status["Awaiting_Changed_Requirements"] = 0] = "Awaiting_Changed_Requirements";
    Status[Status["Awaiting_Supplier_Documents"] = 1] = "Awaiting_Supplier_Documents";
    Status[Status["Awaiting_Admin_Approval"] = 2] = "Awaiting_Admin_Approval";
    Status[Status["Awaiting_Customer_Documents"] = 3] = "Awaiting_Customer_Documents";
    Status[Status["Awaiting_Start_Date"] = 4] = "Awaiting_Start_Date";
    Status[Status["Awaiting_Billing_Details"] = 5] = "Awaiting_Billing_Details";
    Status[Status["Awaiting_Confirmation_From_Customer"] = 6] = "Awaiting_Confirmation_From_Customer";
    Status[Status["Complete"] = 7] = "Complete";
})(Status || (Status = {}));
