import React from "react";
import styles from "./index.module.scss";
export default class ExpiredPopup extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.popup },
            React.createElement("div", { className: styles.expired },
                React.createElement("div", { className: styles.expiredHeader }, "Responses Expired"),
                React.createElement("div", { className: styles.expiredContent }, "You have missed the deadline to select a supplier"))));
    }
}
