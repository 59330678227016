import React from "react";
import AnimateHeight from "react-animate-height";
import Arrow from "@Components/Icons/Arrow";
import styles from "./index.module.scss";
export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open === undefined ? true : props.open,
        };
    }
    onToggle() {
        const { open } = this.state;
        this.setState({
            open: !open,
        });
        if (this.props.onToggle) {
            this.props.onToggle(!open);
        }
    }
    componentDidUpdate(previousProps) {
        if (previousProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }
    render() {
        const { className = "" } = this.props;
        return (React.createElement("div", { className: `${styles.card} ${className}` },
            (this.props.title || this.props.collapsible) && (React.createElement("div", { className: `${styles.header} 
                        ${this.props.open === false ? styles.closedHeader : ""} 
                        ${this.props.children === undefined
                    ? styles.closedHeader
                    : ""} 
                        ${this.props.sticky === true ? styles.sticky : ""} ${this.props.collapsible === true
                    ? styles.collapsible
                    : ""}`, onClick: this.props.collapsible && this.onToggle.bind(this) },
                this.props.title && (React.createElement("div", null,
                    React.createElement("h2", { className: styles.title }, this.props.title),
                    this.props.subTitle && (React.createElement("div", { className: styles.subTitle }, this.props.subTitle)))),
                React.createElement("div", { className: styles.empty }),
                React.createElement("div", { className: styles.right },
                    this.props.rightSide,
                    this.props.collapsible && (React.createElement("div", { className: styles.arrow },
                        React.createElement(Arrow, { size: `1em`, rotate: this.state.open === false
                                ? -360
                                : -270 })))))),
            React.createElement(AnimateHeight, { height: this.state.open ? "auto" : 0 }, this.props.children || React.createElement(React.Fragment, null))));
    }
}
