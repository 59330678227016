import { fetchCompanies } from "@Store/Actions/Customers/companies";
import { fetchTendersForAdmin, unsubmitTender, } from "@Store/Actions/Customers/tenders";
import { addNotification } from "@Store/Actions/Notifications";
import React from "react";
import { connect } from "react-redux";
import { fetchUsagesForAdmin } from "@Store/Actions/Customers/usage";
import SensabillCustomers from "./SensabillCustomers";
import Tenders from "./Tenders";
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
        };
    }
    componentDidMount() {
        this.reload();
    }
    reload = () => {
        this.setState({ fetching: true });
        this.props
            .fetch()
            .catch((error) => {
            console.error(error.message);
        })
            .finally(() => {
            this.setState({ fetching: false });
        });
    };
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Tenders, { tenders: this.props.tenders, customers: this.props.companies, fetching: this.state.fetching, reload: this.reload, unsubmitTender: this.props.unsubmitTender }),
            React.createElement(SensabillCustomers, { tenders: this.props.tenders, customers: this.props.companies, usages: this.props.usage, fetching: this.state.fetching, reload: this.reload })));
    }
}
const mapStateToProps = ({ customers: { tenders = [], companies = [], usage = [] }, }) => ({
    tenders,
    companies,
    usage,
});
const mapDispatchToProps = (dispatch) => {
    return {
        notify: (data) => addNotification(dispatch, data),
        unsubmitTender: (tender) => unsubmitTender(dispatch, tender),
        fetch: () => Promise.all([
            fetchTendersForAdmin(dispatch),
            fetchCompanies(dispatch),
            fetchUsagesForAdmin(dispatch),
        ]),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
