import Factory from "@Shared/Factory";
import { ADD_PARTNER, DELETE_PARTNER } from "@Store/Actions/Partners/companies";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_PARTNER:
            return state.filter((object) => object.id !== data);
        case ADD_PARTNER:
            const partner = Factory.Partner(data);
            return [...state.filter(({ id }) => id !== partner.id), partner];
    }
    return state;
};
