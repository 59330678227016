import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [version1].reduce((converted, f) => f(converted), original);
    const { version, responseIds, tenderId } = converted;
    return {
        ...baseMigrated,
        version,
        responseIds,
        tenderId,
    };
};
