import React from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { ERROR } from "@Components/Toast";
import { logout } from "@Store/Actions/Auth";
import { addNotification, deleteNotification, } from "@Store/Actions/Notifications";
class IdleTimeout extends React.Component {
    static defaultProps = {
        timeout: 24 * 60 * 60 * 1000, // 1 day
    };
    idleTimer;
    constructor(props) {
        super(props);
        this.state = {
            notificationId: null,
        };
        this.idleTimer = null;
    }
    onAction() {
        this.idleTimer = null;
        if (this.state.notificationId !== null) {
            setTimeout(() => {
                this.props.deleteNotification({ id: this.state.notificationId });
                this.setState({ notificationId: null });
            }, 500);
        }
    }
    onIdle() {
        const result = this.props.notify({
            title: "Time out due to inactivity",
            description: "In 60 seconds you will be logged out due to inactivity",
            status: ERROR,
        });
        this.setState({ notificationId: result.data.id });
        this.idleTimer = setTimeout(async () => {
            await this.props.logout();
        }, 60000);
    }
    render() {
        return (React.createElement(IdleTimer, { element: document, onActive: this.onAction.bind(this), onIdle: this.onIdle.bind(this), onAction: this.onAction.bind(this), throttle: 10000, timeout: this.props.timeout }, this.props.children));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        logout: () => logout(dispatch),
        notify: (notification) => addNotification(dispatch, notification, 0),
        deleteNotification: (data) => deleteNotification(dispatch, data),
    };
};
export default connect(null, mapDispatchToProps)(IdleTimeout);
