import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "@Components/Icons/Logos/LogoText";
import styles from "./index.module.scss";
export default class PrimaryNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: true,
        };
    }
    toggle() {
        const { closed } = this.state;
        this.setState({ closed: !closed });
    }
    render() {
        const { closed } = this.state;
        const topLinks = this.props.topLinks.map(({ path, name, style, exact = false, icon = false }) => (React.createElement(NavLink, { to: path, key: path, className: styles.link, activeClassName: styles.selected, exact: exact },
            icon,
            React.createElement("div", { className: `${styles.text} ${style}` }, name))));
        const bottomLinks = (this.props.bottomLinks || []).map(({ path, name, style, exact = false, icon = false }) => (React.createElement(NavLink, { to: path, key: path, className: styles.link, activeClassName: styles.selected, exact: exact },
            icon,
            React.createElement("div", { className: `${styles.text} ${style}` }, name))));
        return (React.createElement("div", { className: `${styles.nav} ${closed ? `` : styles.expanded} ${this.props.className}` },
            React.createElement("div", { className: `${styles.header}` },
                React.createElement(Logo, null),
                React.createElement("div", { className: styles.iconContainer, onClick: this.toggle.bind(this) },
                    React.createElement("div", { className: styles.icon },
                        React.createElement("div", { className: styles.menu })))),
            React.createElement("div", { className: styles.navContainer },
                topLinks.length > 0 && (React.createElement("div", { className: `${styles.links}` }, topLinks)),
                this.props.children,
                bottomLinks.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.expander }),
                    React.createElement("div", { className: `${styles.links}` }, bottomLinks))))));
    }
}
