import SVG from "@Components/Icons/SVG";
export default class Organisation extends SVG {
    path = [
        "M 58 85",
        "V 30",
        "M 58 85",
        "H 98",
        "C 99 85 100 84 100 83",
        "V 40",
        "C 100 38 99 37 98 37",
        "L 58 30",
        "M 58 85",
        "H 40",
        "M 58 30",
        "V 11",
        "C 58 10 57 9 56 8",
        "L 3 0",
        "C 1 0 0 1 0 3",
        "V 83",
        "C 0 84 1 85 3 85",
        "H 18",
        "V 81",
        "H 7",
        "C 5 81 4 80 4 79",
        "V 7",
        "C 4 4 5 4 7 4",
        "L 54 12",
        "C 55 12 56 13 56 14",
        "V 81",
        "H 40",
        "V 85",
        "H 58",
        "Z",
        "M 13 23",
        "H 23",
        "V 20",
        "H 13",
        "Z",
        "M 35 20",
        "H 45",
        "V 23",
        "H 35",
        "Z",
        "M 13 36",
        "H 23",
        "V 33",
        "H 13",
        "Z",
        "M 35 33",
        "H 45",
        "V 36",
        "H 35",
        "Z",
        "M 13 45",
        "H 23",
        "V 48",
        "H 13",
        "Z",
        "M 35 45",
        "H 45",
        "V 48",
        "H 35",
        "Z",
        "M 70 45",
        "H 88",
        "V 49",
        "H 70",
        "Z",
        "M 70 57",
        "H 88",
        "V 61",
        "H 70",
        "Z",
        "M 70 69",
        "H 88",
        "V 73",
        "H 70",
        "Z",
        "M 40 85",
        "V 64",
        "C 40 62 39 61 38 61",
        "H 20",
        "C 19 61 18 62 18 64",
        "V 85",
        "Z",
        "M 40 85",
        "H 18",
        "M 36 81",
        "V 66",
        "C 36 65 36 65 35 65",
        "H 23",
        "C 22 65 22 65 22 66",
        "V 81",
        "H 36",
        "M 60 34 V 81",
        "H 94",
        "C 95 81 96 80 96 78",
        "V 44",
        "C 96 42 95 41 94 41",
        "Z",
    ];
    evenOdd = true;
}
