import React from "react";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import InfoPopup from "@Components/InfoPopup";
import UsageUpload from "@Components/UsageUpload";
import HowToGetBills from "@SmartComponents/TenderEdit/Display/Boxes/HowToGetBills";
import TreatData from "@SmartComponents/TenderEdit/Display/Boxes/TreatData";
import styles from "./index.module.scss";
export default class AddUsage extends React.Component {
    render() {
        const horizontal = window.innerWidth > 1410;
        const popup = (close) => {
            return (React.createElement("div", { className: styles.popupWrapper },
                horizontal && (React.createElement("div", { className: styles.outerBox },
                    React.createElement(TreatData, { className: styles.outerBoxContent }))),
                React.createElement("div", { className: styles.centreBox },
                    React.createElement(ColouredBorderBox, { primary: true, title: React.createElement("div", { className: styles.title },
                            React.createElement("b", { className: styles.primaryColor }, "Bill Report"),
                            " ",
                            "upload, recommended for best-fit Tender results") },
                        React.createElement("div", { className: styles.usageUpload },
                            React.createElement(UsageUpload, { submitPlatform: this.props.submitPlatform, submitFiles: this.props.submitFiles, grey: true, dataRequired: true, close: close }))),
                    !horizontal && (React.createElement("div", { className: styles.bottomBoxes },
                        React.createElement(TreatData, { className: styles.outerBoxContent }),
                        React.createElement(HowToGetBills, { className: styles.outerBoxContent })))),
                horizontal && (React.createElement("div", { className: styles.outerBox },
                    React.createElement(HowToGetBills, { className: styles.outerBoxContent })))));
        };
        let left = "unset";
        if (window.innerWidth >= 1715) {
            const middleOfScreen = window.innerWidth / 2;
            left = (this.props.middle || middleOfScreen) - middleOfScreen;
        }
        return (React.createElement(InfoPopup, { styles: {
                left,
            }, trigger: this.props.open === true, content: popup, onClose: this.props.onClose }));
    }
}
