// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vodafone--cIWCc{width:3rem}.vf1--I45mO,.vf2--KQK6p{fill-rule:nonzero;stroke:none;fill-opacity:1}.vf1--I45mO{fill:#e60000}.vf2--KQK6p{fill:#fff}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/VodafoneWithoutText/index.module.scss"],"names":[],"mappings":"AAAA,iBAAU,UAAU,CAAC,wBAAU,iBAAiB,CAAC,WAAW,CAAC,cAAc,CAAC,YAAK,YAAY,CAAC,YAAK,SAAS","sourcesContent":[".vodafone{width:3rem}.vf1,.vf2{fill-rule:nonzero;stroke:none;fill-opacity:1}.vf1{fill:#e60000}.vf2{fill:#fff}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vodafone": "vodafone--cIWCc",
	"vf1": "vf1--I45mO",
	"vf2": "vf2--KQK6p"
};
export default ___CSS_LOADER_EXPORT___;
