import auth from "./Auth";
import awardedContract from "./AwardedContract";
import notifications from "./Notifications";
import responses from "./Responses";
import syncState from "./syncState";
import tenders from "./Tenders";
export default [
    notifications,
    auth,
    tenders,
    responses,
    awardedContract,
    syncState,
];
