import React from "react";
import styles from "./index.module.scss";
import icon from "./protel-mini.png";
export default class Logo extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        return (React.createElement("img", { src: icon, alt: "Protel", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
    }
}
