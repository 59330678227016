// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--NfUqf{display:none}@media(min-width: 960px){.header--NfUqf{display:flex;align-items:center;height:3.5rem;min-height:52.5px;padding-left:1rem;color:var(--inverted-text-color);background:var(--standard-gradient);font-weight:var(--title-font-weight);font-size:1.6rem;font-family:\"NettoPro\",sans-serif;line-height:1.8rem;letter-spacing:.08rem}}", "",{"version":3,"sources":["webpack://./src/gui/Components/Header/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,YAAY,CAAC,yBAAyB,eAAQ,YAAY,CAAC,kBAAkB,CAAC,aAAa,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,gCAAgC,CAAC,mCAAmC,CAAC,oCAAoC,CAAC,gBAAgB,CAAC,iCAAiC,CAAC,kBAAkB,CAAC,qBAAqB,CAAC","sourcesContent":[".header{display:none}@media(min-width: 960px){.header{display:flex;align-items:center;height:3.5rem;min-height:52.5px;padding-left:1rem;color:var(--inverted-text-color);background:var(--standard-gradient);font-weight:var(--title-font-weight);font-size:1.6rem;font-family:\"NettoPro\",sans-serif;line-height:1.8rem;letter-spacing:.08rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--NfUqf"
};
export default ___CSS_LOADER_EXPORT___;
