import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class CostPerItem extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { ukIDDToEurope, ukIDDToROW, ukIDDToUSAandCanada, ukIDDToAPAC, ukToInternational, pictureMsg, chargeable08, UKUsageUkIDDToEurope, UKUsageUkIDDToROW, UKUsageUkIDDToUSAandCanada, UKUsageUkIDDToAPAC, UKUsageUkToInternational, UKUsagePictureMsg, UKUsageChargeable08, } = this._data;
        return {
            ukIDDToEurope,
            ukIDDToROW,
            ukIDDToUSAandCanada,
            ukIDDToAPAC,
            ukToInternational,
            pictureMsg,
            chargeable08,
            UKUsageUkIDDToEurope,
            UKUsageUkIDDToROW,
            UKUsageUkIDDToUSAandCanada,
            UKUsageUkIDDToAPAC,
            UKUsageUkToInternational,
            UKUsagePictureMsg,
            UKUsageChargeable08,
        };
    }
    get(k) {
        return this._data[k];
    }
}
