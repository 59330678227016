import React from "react";
import Close from "@Components/Icons/Close";
import PredictMascotIcon from "@Components/Icons/PredictMascot";
import InfoPopup from "@Components/InfoPopup";
import StepCircle from "./StepCircle";
import styles from "./index.module.scss";
export default class WelcomePopup extends React.Component {
    getWidth = (screenWidth) => {
        if (screenWidth >= 1200) {
            return "55%";
        }
        if (screenWidth >= 950) {
            return "70%";
        }
        return "80%";
    };
    render() {
        const popup = (close) => {
            return (React.createElement("div", { className: styles.popup },
                React.createElement(Close, { onClick: close, color: "#58585a", size: "0.8rem", className: styles.close }),
                React.createElement("div", { className: styles.header },
                    React.createElement(PredictMascotIcon, { className: styles.sideBox }),
                    React.createElement("div", { className: styles.title },
                        React.createElement("span", { className: styles.highlightPrimary }, "Hello"),
                        ", before we start, here's what to expect next...")),
                React.createElement("div", { className: styles.body },
                    React.createElement("div", { className: styles.dots }),
                    React.createElement("div", { className: styles.step },
                        React.createElement("div", { className: styles.stepTitle },
                            "Your",
                            " ",
                            React.createElement("span", { className: styles.highlightPrimary }, "Preferences")),
                        React.createElement(StepCircle, { number: 1 },
                            React.createElement("b", null, "You"),
                            " answer",
                            React.createElement("br", null),
                            "a few",
                            " ",
                            React.createElement("span", { className: styles.highlightSecondary }, "questions"))),
                    React.createElement("div", { className: styles.step },
                        React.createElement("div", { className: styles.stepTitle },
                            "Your",
                            " ",
                            React.createElement("span", { className: styles.highlightPrimary }, "Bill Reports")),
                        React.createElement(StepCircle, { number: 2 },
                            React.createElement("b", null, "We"),
                            " ",
                            React.createElement("span", { className: styles.highlightSecondary }, "analyse"),
                            React.createElement("br", null),
                            "your Bill Reports")),
                    React.createElement("div", { className: styles.step },
                        React.createElement("div", { className: styles.stepTitle },
                            "You're",
                            " ",
                            React.createElement("span", { className: styles.highlightPrimary }, "Done")),
                        React.createElement(StepCircle, { number: 3 },
                            React.createElement("b", null, "Suppliers"),
                            " submit",
                            React.createElement("br", null),
                            React.createElement("span", { className: styles.highlightSecondary }, "Proposals"))))));
        };
        const middleOfScreen = window.innerWidth / 2;
        const left = (this.props.middle || middleOfScreen) - middleOfScreen;
        return (React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose, styles: {
                left,
                transition: "all 0.5s",
                width: this.getWidth(window.innerWidth),
            } }));
    }
}
