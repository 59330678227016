import React from "react";
import { generate as uuidv4 } from "short-uuid";
import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
import styles from "./index.module.scss";
export default class SVG extends React.Component {
    static defaultProps = {
        size: `1.5vw`,
        className: "",
        rotate: 0,
        fillOpacity: 1,
        onClick: () => { },
    };
    constructor(props) {
        super(props);
        this.state = {
            light: props.light,
            dark: props.dark,
            error: props.error,
            success: props.success,
            primary: props.primary,
            create: props.create,
            delete: props.delete,
            edit: props.edit,
            noFill: props.noFill,
            gradId: uuidv4(),
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps !== this.props) {
            this.setState({
                light: this.props.light,
                dark: this.props.dark,
                error: this.props.error,
                success: this.props.success,
                primary: this.props.primary,
                create: this.props.create,
                delete: this.props.delete,
                edit: this.props.edit,
                noFill: this.props.noFill,
            });
        }
    }
    path = ["M0 0 100 0 100 100 0 100Z"];
    strokeDetails = {
        stroke: "none",
        strokeWidth: "0",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = false;
    viewbox = "0 0 100 100";
    gradient = () => `url('#${this.state.gradId}')`;
    gradientColours = () => {
        const broker = getBrokerDomain();
        switch (broker) {
            case BrokerDomain.ERA:
                return [`#749fcc`, `#0e4376`];
            case BrokerDomain.TechMahindra:
                return [`#e31837`, `#4d4d4f`];
            case BrokerDomain.SevenStepSolutions:
                return [`#cF5c5d`, `#4d4d4f`];
            case BrokerDomain.Bionic:
                return [`#142f73`, `#ff6841`];
            case BrokerDomain.BDO:
                return [`#e81a3b`, `#007aff`];
            case BrokerDomain.Protel:
                return [`#28538A`, `#141314`];
            default:
                return [`#72BE49`, `#0EABDB`];
        }
    };
    create = [
        "M80 80",
        "H95",
        "C100 80 100 80 100 75",
        "C100 70 100 70 95 70",
        "H80",
        "V55",
        "C80 50 80 50 75 50",
        "C70 50 70 50 70 55",
        "V70",
        "H55",
        "C50 70 50 70 50 75",
        "C50 80 50 80 55 80",
        "H70",
        "V95",
        "C70 100 70 100 75 100",
        "C80 100 80 100 80 95",
        "Z",
    ];
    delete = [
        "M 45 10",
        " C 50 10 50 10 50 5",
        " C 50 0 50 0 45 0",
        " H 5",
        " C 0 0 0 0 0 5",
        " C 0 10 0 10 5 10",
        " Z",
    ];
    edit = [
        "M50 100",
        "V90",
        "L86 54 96 64 60 100",
        "Z",
        "M100 60 98 62 88 52 90 50",
        "C96 50 100 54 100 60",
        "M52 98 52 92 58 98",
        "Z",
    ];
    fill = undefined;
    style() {
        const properties = {};
        const transform = [];
        if (this.props.rotate !== 0) {
            transform.push(`rotate(${this.props.rotate}deg)`);
        }
        if (transform.length > 0) {
            properties.transform = transform.join(` `);
        }
        return properties;
    }
    render() {
        const fill = this.state.primary
            ? `#0EABDB`
            : this.state.light
                ? `#FFFFFF`
                : this.state.dark
                    ? `#5D5E5E`
                    : this.state.error
                        ? `#DD4B39`
                        : this.state.success
                            ? `#5cb85c`
                            : this.fill === undefined
                                ? this.props.fill === undefined
                                    ? this.gradient()
                                    : this.props.fill
                                : this.fill;
        return (React.createElement("svg", { onClick: this.props.onClick, onMouseOver: this.props.onMouseOver, onMouseOut: this.props.onMouseOut, width: this.props.size, viewBox: this.viewbox, style: this.style(), className: `${styles.icon} ${this.props.className}` },
            React.createElement("g", null,
                React.createElement("linearGradient", { id: this.state.gradId, gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: "0", style: { stopColor: this.gradientColours()[0] } }),
                    React.createElement("stop", { offset: "1", style: { stopColor: this.gradientColours()[1] } })),
                React.createElement("path", { d: this.path.join(" "), fill: fill, fillRule: this.evenOdd ? "evenodd" : "inherit", fillOpacity: this.state.noFill ? 0 : this.props.fillOpacity, ...this.strokeDetails }),
                this.state.create && (React.createElement("path", { d: this.create.join(" "), fill: "#FFFFFF", stroke: "#5D5E5E", strokeWidth: "5", strokeLinecap: "round" })),
                this.state.delete && (React.createElement("path", { d: this.delete.join(" "), fill: "#FFFFFF", stroke: "#5D5E5E", strokeWidth: "5", strokeLinecap: "round" })),
                this.state.edit && (React.createElement("path", { d: this.edit.join(" "), fill: "#FFFFFF", stroke: "#5D5E5E", strokeWidth: "2", strokeLinecap: "round", fillRule: "evenodd" })))));
    }
}
