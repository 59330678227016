import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class AwardedContract extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/awarded-contracts/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { customerId, supplierId, tenderId, responseId, documents, usageId, status, note, requirementsChanged, startDate, partnerId, } = this;
        return {
            ...parent,
            version,
            customerId,
            supplierId,
            tenderId,
            responseId,
            documents,
            usageId,
            status,
            note,
            requirementsChanged,
            startDate,
            partnerId,
        };
    }
    get customerId() {
        return this._data.customerId;
    }
    get supplierId() {
        return this._data.supplierId;
    }
    get tenderId() {
        return this._data.tenderId;
    }
    get partnerId() {
        return this._data.partnerId;
    }
    get startDate() {
        return this._data.startDate;
    }
    get responseId() {
        return this._data.responseId;
    }
    get documents() {
        return this._data.documents;
    }
    get usageId() {
        return this._data.usageId;
    }
    get status() {
        return this._data.status;
    }
    get note() {
        return this._data.note;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get requirementsChanged() {
        return this._data.requirementsChanged;
    }
}
