import React from "react";
import Footer from "@Components/Footer";
import Notifications from "../../Notifications";
import Header from "./Header";
import styles from "./index.module.scss";
import Pages from "./Pages";
export default class extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement(Header, null),
                React.createElement("div", { className: styles.page },
                    React.createElement(Pages, null)),
                React.createElement(Footer, null)),
            React.createElement(Notifications, null)));
    }
}
