import React from "react";
import styles from "./index.module.scss";
export default class Badge extends React.Component {
    render() {
        const badgeStyle = this.props.width
            ? { width: `${this.props.width * 0.6}rem` }
            : {};
        return (React.createElement("div", { className: styles.badgeWrapper },
            React.createElement("span", { className: styles.text, style: badgeStyle }, this.props.children == 0 ? React.createElement(React.Fragment, null, "--") : this.props.children)));
    }
}
