// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--cBob_{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);width:35em;background-color:var(--card-color);border:1px solid var(--primary-button-color-press);border-radius:1.5rem;box-shadow:0px 1px 10px -5px #333}", "",{"version":3,"sources":["webpack://./src/gui/Components/Pages/Fallbacks/Uploading/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,2EAA2E,CAAC,UAAU,CAAC,kCAAkC,CAAC,kDAAkD,CAAC,oBAAoB,CAAC,iCAAiC","sourcesContent":[".popup{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);width:35em;background-color:var(--card-color);border:1px solid var(--primary-button-color-press);border-radius:1.5rem;box-shadow:0px 1px 10px -5px #333}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup--cBob_"
};
export default ___CSS_LOADER_EXPORT___;
