import SVG from "@Components/Icons/SVG";
export default class Supplier extends SVG {
    path = [
        "M 48 100",
        "V 55",
        "H 52",
        "V 100",
        "Z",
        "M 61 83",
        "C 81 75 85 59 84 50 ",
        "C 84 39 80 18 50 18",
        "C 20 18 16 39 16 50",
        "C 15 59 19 75 39 83",
        "V 87",
        "C 19 79 11 59 12 50",
        "C 12 39 20 14 50 14",
        "C 80 14 88 39 88 50",
        "C 89 59 81 79 61 87",
        "Z",
        "M 62 99",
        "C 77 97 100 80 100 50",
        "V 50",
        "C 100 23 78 0 50 0",
        "V 0",
        "C 22 0 0 23 0 50",
        "V 50",
        "C 0 80 23 97 38 99",
        "V 95",
        "C 23 93 4 80 4 51",
        "V 50",
        "C 4 23 22 4 50 4",
        "V 4",
        "C 78 4 96 23 96 50",
        "V 50",
        "C 96 80 77 93 62 95",
        "Z",
        "M 60 70",
        "C 70 65 76 55 75 50",
        "C 75 40 66 26 50 26",
        "C 34 26 26 40 26 50",
        "C 25 55 30 65 40 70",
        "V 66",
        "C 30 61 29 55 30 50",
        "C 30 40 34 30 50 30",
        "C 66 30 71 40 71 50",
        "C 72 55 70 61 60 66",
        "Z ",
        "M 50 54",
        "V 55",
        "C 55 55 57 52 57 48",
        "V 48",
        "C 57 44 54 41 50 41",
        "V 41",
        "C 46 41 43 44 43 48",
        "V 47",
        "C 43 52 45 55 50 55",
        "M 50 51",
        "V 51",
        "C 52 51 53 50 53 48",
        "V 48",
        "C 53 46 52 45 50 45",
        "V 45",
        "C 48 45 47 46 47 48",
        "V 48",
        "C 47 50 48 51 50 51",
    ];
    evenOdd = true;
}
