// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--lmdyx{transition:all 500ms}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/SVG/index.module.scss"],"names":[],"mappings":"AAAA,aAAM,oBAAoB","sourcesContent":[".icon{transition:all 500ms}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--lmdyx"
};
export default ___CSS_LOADER_EXPORT___;
