// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--gK98S{position:absolute;z-index:1;align-self:inherit;justify-content:center;width:100%;height:100%;background:rgba(255,255,255,.75);border-radius:.5vw}.popup--gK98S .expired--UQ4oG{display:flex;flex-wrap:wrap;justify-content:center;margin-top:3em;margin-right:1em;margin-left:1em;border:1px solid red}.popup--gK98S .expired--UQ4oG .expiredHeader--PtWDQ{display:flex;justify-content:center;width:100%;padding-top:1em;font-size:1.1rem;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.popup--gK98S .expired--UQ4oG .expiredContent--Iobro{display:flex;justify-content:center;width:100%;padding-bottom:1.2em;color:red;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Customer/Pages/Tenders/TenderCard/ExpiredPopup/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,iBAAiB,CAAC,SAAS,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,UAAU,CAAC,WAAW,CAAC,gCAAgC,CAAC,kBAAkB,CAAC,8BAAgB,YAAY,CAAC,cAAc,CAAC,sBAAsB,CAAC,cAAc,CAAC,gBAAgB,CAAC,eAAe,CAAC,oBAAoB,CAAC,oDAA+B,YAAY,CAAC,sBAAsB,CAAC,UAAU,CAAC,eAAe,CAAC,gBAAgB,CAAC,2EAA2E,CAAC,qDAAgC,YAAY,CAAC,sBAAsB,CAAC,UAAU,CAAC,oBAAoB,CAAC,SAAS,CAAC,2EAA2E","sourcesContent":[".popup{position:absolute;z-index:1;align-self:inherit;justify-content:center;width:100%;height:100%;background:rgba(255,255,255,.75);border-radius:.5vw}.popup .expired{display:flex;flex-wrap:wrap;justify-content:center;margin-top:3em;margin-right:1em;margin-left:1em;border:1px solid red}.popup .expired .expiredHeader{display:flex;justify-content:center;width:100%;padding-top:1em;font-size:1.1rem;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.popup .expired .expiredContent{display:flex;justify-content:center;width:100%;padding-bottom:1.2em;color:red;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup--gK98S",
	"expired": "expired--UQ4oG",
	"expiredHeader": "expiredHeader--PtWDQ",
	"expiredContent": "expiredContent--Iobro"
};
export default ___CSS_LOADER_EXPORT___;
