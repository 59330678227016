// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ZhiI_{position:relative;text-align:center}.wrapper--ZhiI_ .textBox--l8AxI{position:absolute;top:calc(55% - 2rem);left:calc(50% - 5rem);width:10rem;height:4rem;font-weight:var(--body-font-weight);font-size:.95rem;font-family:var(--body-font-family);line-height:1.25rem}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderEdit/Popups/Welcome/StepCircle/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,iBAAiB,CAAC,iBAAiB,CAAC,gCAAkB,iBAAiB,CAAC,oBAAoB,CAAC,qBAAqB,CAAC,WAAW,CAAC,WAAW,CAAC,mCAAmC,CAAC,gBAAgB,CAAC,mCAAmC,CAAC,mBAAmB","sourcesContent":[".wrapper{position:relative;text-align:center}.wrapper .textBox{position:absolute;top:calc(55% - 2rem);left:calc(50% - 5rem);width:10rem;height:4rem;font-weight:var(--body-font-weight);font-size:.95rem;font-family:var(--body-font-family);line-height:1.25rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ZhiI_",
	"textBox": "textBox--l8AxI"
};
export default ___CSS_LOADER_EXPORT___;
