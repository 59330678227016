import React from "react";
import { connect } from "react-redux";
import Toast from "@Components/Toast";
import { deleteNotification } from "@Store/Actions/Notifications";
class Notifications extends React.Component {
    deleteNotification = (id) => {
        this.props.delete({ id });
    };
    render() {
        return (React.createElement(Toast, { toastList: this.props.notifications, deleteNotification: this.deleteNotification }));
    }
}
const mapStateToProps = ({ notifications }) => ({
    notifications,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        delete: (data) => deleteNotification(dispatch, data),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
