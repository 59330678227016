// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badgeWrapper--Jt_B4{display:inline-flex;align-items:center;justify-content:center;padding:.3em .5em;text-align:center;background-color:var(--alt-highlighted-text-color);border-radius:.4rem}.badgeWrapper--Jt_B4 .text--yP41b{color:#fff;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/gui/Components/Badge/index.module.scss"],"names":[],"mappings":"AAAA,qBAAc,mBAAmB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,kDAAkD,CAAC,mBAAmB,CAAC,kCAAoB,UAAU,CAAC,gBAAgB","sourcesContent":[".badgeWrapper{display:inline-flex;align-items:center;justify-content:center;padding:.3em .5em;text-align:center;background-color:var(--alt-highlighted-text-color);border-radius:.4rem}.badgeWrapper .text{color:#fff;font-weight:bold}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "badgeWrapper--Jt_B4",
	"text": "text--yP41b"
};
export default ___CSS_LOADER_EXPORT___;
