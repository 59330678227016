import { Color, Make, OS } from "@Shared/Entities/Hardware/Device/enums";
export const deviceConstraints = {
    id: {
        type: "string",
    },
    make: {
        presence: true,
        enum: Make,
    },
    model: {
        presence: true,
        type: "string",
    },
    color: {
        presence: true,
        enum: Color,
    },
    size: {
        presence: true,
        type: "string",
    },
    os: {
        presence: true,
        enum: OS,
    },
    price: {
        presence: true,
        type: "number",
        numericality: {
            greaterThanOrEqualTo: 0,
        },
    },
    predecessorId: {
        type: "string",
    },
    replacementId: {
        type: "string",
    },
};
