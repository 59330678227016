import React from "react";
import styles from "./index.module.scss";
export default class Header extends React.Component {
    static defaultProps = {
        logout: true,
    };
    render() {
        return React.createElement("span", { className: styles.header }, this.props.children);
    }
}
