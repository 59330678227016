import SVG from "@Components/Icons/SVG";
export default class Tick extends SVG {
    path = [
        "m 50 5 c -24.8513 0 -45 20.1487 -45 45 s 20.1487 45 45 45 s 45 -20.1487 45 -45 s -20.1487 -45 -45 -45 z m -6.975 65.7647 l -20.7057 -20.7085 l 7.9538 -7.9538 l 12.749 12.7547 l 27.0478 -27.0478 l 7.9538 7.9538 l -34.9988 35.0015 z",
    ];
    fill = this.props.outlined ? "#ffffff" : this.props.color;
    strokeDetails = {
        stroke: this.props.outlined ? "#9a9a9a" : "none",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
