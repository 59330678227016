// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--g92wZ{font-weight:var(--title-font-weight);font-size:1.5rem;font-family:\"NettoPro\",sans-serif;line-height:1.7rem;letter-spacing:.08rem}.title--g92wZ .primaryColor--fgjMc{color:var(--primary-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/UsageUploadSensabill/title.module.scss"],"names":[],"mappings":"AAAA,cAAO,oCAAoC,CAAC,gBAAgB,CAAC,iCAAiC,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,mCAAqB,0BAA0B","sourcesContent":[".title{font-weight:var(--title-font-weight);font-size:1.5rem;font-family:\"NettoPro\",sans-serif;line-height:1.7rem;letter-spacing:.08rem}.title .primaryColor{color:var(--primary-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--g92wZ",
	"primaryColor": "primaryColor--fgjMc"
};
export default ___CSS_LOADER_EXPORT___;
