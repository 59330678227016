// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--QORII{display:flex;gap:calc(var(--standard-vertical-padding)*2);justify-content:center;width:100%;margin-top:calc(var(--standard-vertical-padding)*2)}.wrapper--QORII .section--f1KPM{display:flex;flex-direction:column;gap:calc(var(--standard-vertical-padding)*2);width:35rem}.wrapper--QORII .section--f1KPM .card--Xoa5G{margin:0}.wrapper--QORII .section--f1KPM .card--Xoa5G .link--jsnE2{font-weight:var(--title-font-weight);font-size:1.1rem;font-family:\"NettoPro\",sans-serif;line-height:1.3rem;letter-spacing:.08rem}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/User/MyAccount/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,YAAY,CAAC,4CAA4C,CAAC,sBAAsB,CAAC,UAAU,CAAC,mDAAmD,CAAC,gCAAkB,YAAY,CAAC,qBAAqB,CAAC,4CAA4C,CAAC,WAAW,CAAC,6CAAwB,QAAQ,CAAC,0DAA8B,oCAAoC,CAAC,gBAAgB,CAAC,iCAAiC,CAAC,kBAAkB,CAAC,qBAAqB","sourcesContent":[".wrapper{display:flex;gap:calc(var(--standard-vertical-padding)*2);justify-content:center;width:100%;margin-top:calc(var(--standard-vertical-padding)*2)}.wrapper .section{display:flex;flex-direction:column;gap:calc(var(--standard-vertical-padding)*2);width:35rem}.wrapper .section .card{margin:0}.wrapper .section .card .link{font-weight:var(--title-font-weight);font-size:1.1rem;font-family:\"NettoPro\",sans-serif;line-height:1.3rem;letter-spacing:.08rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--QORII",
	"section": "section--f1KPM",
	"card": "card--Xoa5G",
	"link": "link--jsnE2"
};
export default ___CSS_LOADER_EXPORT___;
