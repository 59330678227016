import React from "react";
import { Route, Switch } from "react-router-dom";
import HeaderComp from "@Components/Header";
import { CONTRACTS, CREATE_CUSTOMER, CREATE_CUSTOMER_USER, CREATE_USER, CUSTOMER, CUSTOMERS, CUSTOMER_TENDER, CUSTOMER_TENDERS, CUSTOMER_TENDER_RESPONSES, CUSTOMER_USAGES, CUSTOMER_USER, CUSTOMER_USERS, EDIT_CUSTOMER_TENDER, EDIT_CUSTOMER_USER, EDIT_MY_ACCOUNT, EDIT_USER, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, NOT_FOUND, ROOT, USER, USERS, } from "@Shared/Paths/Partner";
export default class Header extends React.Component {
    render() {
        return (React.createElement(HeaderComp, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: ROOT, exact: true }, "Dashboard"),
                React.createElement(Route, { path: MY_ORGANISATION },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: MY_ORGANISATION, exact: true }, "My Organisation Details"),
                        React.createElement(Route, { path: USERS },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: USERS, exact: true }, "Users"),
                                React.createElement(Route, { path: CREATE_USER }, "Create User"),
                                React.createElement(Route, { path: USER },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: USER, exact: true }, "User"),
                                        React.createElement(Route, { path: EDIT_USER }, "Edit User"))))))),
                React.createElement(Route, { path: CUSTOMERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: CUSTOMERS, exact: true }, "Customers"),
                        React.createElement(Route, { path: CREATE_CUSTOMER }, "Create Customer"),
                        React.createElement(Route, { path: CUSTOMER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: CUSTOMER, exact: true }, "Customer Details"),
                                React.createElement(Route, { path: CUSTOMER_USERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: CUSTOMER_USER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: CUSTOMER_USER, exact: true }, "Customer User"),
                                                React.createElement(Route, { path: EDIT_CUSTOMER_USER }, "Edit Customer User"))),
                                        React.createElement(Route, { path: CREATE_CUSTOMER_USER }, "Create Customer User"))),
                                React.createElement(Route, { path: CUSTOMER_USAGES }, "Sensabill"),
                                React.createElement(Route, { path: CUSTOMER_TENDERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: CUSTOMER_TENDERS, exact: true }, "Customer Tenders"),
                                        React.createElement(Route, { path: CUSTOMER_TENDER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: CUSTOMER_TENDER, exact: true }, "Customer Tender"),
                                                React.createElement(Route, { path: EDIT_CUSTOMER_TENDER }, "Edit Customer Tender"),
                                                React.createElement(Route, { path: CUSTOMER_TENDER_RESPONSES }, "Customer Tender Responses"))))))))),
                React.createElement(Route, { path: CONTRACTS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: CONTRACTS, exact: true }, "Contracts"))),
                React.createElement(Route, { path: MY_ACCOUNT },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: MY_ACCOUNT, exact: true }, "My Account"),
                        React.createElement(Route, { path: EDIT_MY_ACCOUNT }, "Edit My Account"))),
                React.createElement(Route, { path: LOGOUT }),
                React.createElement(Route, { path: NOT_FOUND }, "404"))));
    }
}
