// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--fz1eQ{display:none}.cta--D1mrS{background-color:var(--cta-button-color);box-shadow:0px 2px 2px var(--cta-button-color-hover)}.cta--D1mrS:hover{background-color:var(--cta-button-color-hover)}.cta--D1mrS:active{background-color:var(--cta-button-color-press)}", "",{"version":3,"sources":["webpack://./src/gui/Components/FileUploader/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,YAAY,CAAC,YAAK,wCAAwC,CAAC,oDAAoD,CAAC,kBAAW,8CAA8C,CAAC,mBAAY,8CAA8C","sourcesContent":[".input{display:none}.cta{background-color:var(--cta-button-color);box-shadow:0px 2px 2px var(--cta-button-color-hover)}.cta:hover{background-color:var(--cta-button-color-hover)}.cta:active{background-color:var(--cta-button-color-press)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--fz1eQ",
	"cta": "cta--D1mrS"
};
export default ___CSS_LOADER_EXPORT___;
