// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overall--lP0ZU{width:2em}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Scores/Overall/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,SAAS","sourcesContent":[".overall{width:2em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overall": "overall--lP0ZU"
};
export default ___CSS_LOADER_EXPORT___;
