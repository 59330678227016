import React from "react";
import { connect } from "react-redux";
import { checkMfa, turnOffMfa, turnOnMfa } from "@Api/Auth";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import Fingerprint from "@Components/Icons/Fingerprint";
import ProfileIcon from "@Components/Icons/Profile";
import { ERROR, ToastStatus } from "@Components/Toast";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import Factory from "@Shared/Factory";
import { updateUser as updateAdminUser } from "@Store/Actions/Admin/users";
import { boot, refresh } from "@Store/Actions/Auth";
import { updateUser as updateCustomerUser } from "@Store/Actions/Customers/users";
import { addNotification } from "@Store/Actions/Notifications";
import { updateUser as updatePartnerUser } from "@Store/Actions/Partners/users";
import { updateUser as updateSupplierUser } from "@Store/Actions/Suppliers/users";
import QRCode from "qrcode";
import styles from "./index.module.scss";
class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        const { user } = this.props;
        const { name = "", email = "", role = "", mobileNumber, activated = false, } = user || {};
        this.state = {
            disabled: false,
            data: {
                name,
                email,
                role,
                mobileNumber,
                activated,
            },
            passwords: {
                password: "",
                confirmPassword: "",
            },
            mfa: {
                mfaEnabled: user?.mfaEnabled || false,
                mfaVerify: "",
                png: "",
            },
            errors: {},
        };
    }
    componentDidMount() {
        const { user } = this.props;
        if (user.mfaEnabled) {
            this.mfaChange({
                mfaEnabled: user.mfaEnabled,
            });
        }
    }
    componentDidUpdate(previousProps) {
        const { user } = this.props;
        if (previousProps.user !== user) {
            const { name = "", email = "", mfaEnabled, role = "", mobileNumber, activated = false, } = user || {};
            this.setState({
                data: {
                    name,
                    email,
                    role,
                    mobileNumber,
                    activated,
                },
            });
            if (mfaEnabled) {
                this.mfaChange({
                    mfaEnabled,
                });
            }
        }
    }
    updateAccount = async () => {
        const updatedData = { ...this.props.user.data, ...this.state.data };
        try {
            this.setState({ errors: {} });
            const validated = Factory.User(updatedData);
            return this.props.update(validated).catch((error) => {
                console.log(error.message);
            });
        }
        catch (error) {
            Object.values(error.fails).map((error) => {
                this.props.notify({
                    title: "User Not Updated",
                    description: error,
                    status: ERROR,
                });
            });
            const inputErrors = this.state.errors;
            Object.keys(error.fails).map((name) => {
                inputErrors[name] = true;
            });
            this.setState({ errors: inputErrors });
        }
    };
    resetPassword = () => {
        const { password, confirmPassword } = this.state.passwords;
        if (password !== confirmPassword) {
            this.props.notify({
                title: "Passwords must match!",
                description: "'New Password' and 'Confirm New Password' must be the same...",
                status: ERROR,
            });
            this.setState({ errors: { confirmPassword: true } });
            return Promise.reject();
        }
        try {
            const validated = Factory.User({
                ...this.props.user.data,
                password,
                isPasswordHashed: false,
            });
            return this.props
                .update(validated)
                .then(() => {
                this.setState({
                    errors: {},
                    passwords: {
                        password: "",
                        confirmPassword: "",
                    },
                });
            })
                .catch((error) => {
                console.log(error);
                this.props.notify({
                    title: "Oops",
                    description: "Please contact support",
                    status: ERROR,
                });
            });
        }
        catch (error) {
            Object.values(error.fails).map((error) => {
                this.props.notify({
                    title: "Password Not Updated",
                    description: error,
                    status: ERROR,
                });
            });
            this.setState({ errors: error.fails });
            return Promise.reject();
        }
    };
    mfaUpdate = async () => {
        const { mfaEnabled, mfaVerify } = this.state.mfa;
        if (mfaVerify === "") {
            return;
        }
        if (mfaEnabled === true) {
            try {
                await checkMfa(this.props.user.id, mfaVerify);
                await this.props.refresh();
            }
            catch { }
        }
        if (mfaEnabled === false) {
            try {
                await turnOffMfa(this.props.user.id, mfaVerify);
                await this.props.refresh();
                this.setState({
                    mfa: { mfaEnabled: false, mfaVerify: "", png: "" },
                });
            }
            catch { }
        }
    };
    dataChange = (data) => {
        this.setState({ data });
    };
    passwordChange = (passwords) => {
        this.setState({ passwords });
    };
    mfaChange = async (mfa) => {
        const { mfaEnabled, mfaVerify } = mfa;
        const { assumed } = this.props.user;
        let png = this.state.mfa.png;
        if (mfaEnabled === true && png === "") {
            if (assumed) {
                this.props.notify({
                    title: "Oops",
                    description: "Can't turn on MFA while assuming role",
                    status: ToastStatus.Error,
                });
                return;
            }
            const { data } = await turnOnMfa(this.props.user.id);
            png = await QRCode.toDataURL(data);
        }
        this.setState({ mfa: { mfaEnabled, mfaVerify, png } });
    };
    render() {
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.section },
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement(ProfileIcon, { edit: true }),
                        " Update Profile"), className: styles.card },
                    React.createElement(Form, { submit: this.updateAccount, change: this.dataChange, disabled: this.state.disabled, data: this.state.data, errors: this.state.errors, buttonText: "UPDATE", input: [
                            {
                                label: "Name",
                                name: "name",
                                type: InputType.Text,
                            },
                            {
                                label: "Email",
                                name: "email",
                                type: InputType.Text,
                            },
                            {
                                label: "Mobile/Direct Dial Number",
                                name: "mobileNumber",
                                type: InputType.Text,
                            },
                            {
                                label: "Role",
                                name: "role",
                                type: InputType.Text,
                            },
                        ] })),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement(Fingerprint, { edit: true }),
                        " Reset Password"), className: styles.card },
                    React.createElement(Form, { submit: this.resetPassword, change: this.passwordChange, disabled: this.state.disabled, data: this.state.passwords, errors: this.state.errors, buttonText: "UPDATE", input: [
                            {
                                label: "New password",
                                name: "password",
                                type: InputType.Password,
                            },
                            {
                                label: "Repeat password",
                                name: "confirmPassword",
                                type: InputType.Password,
                            },
                        ] }))),
            React.createElement("div", { className: styles.section },
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement(Fingerprint, { create: true }),
                        " MFA"), rightSide: React.createElement("a", { className: styles.link, href: `https://docs.predictmobile.com/guides/MFA/`, target: "_blank" }, "View Guide"), className: styles.card },
                    React.createElement(Form, { submit: this.mfaUpdate, change: this.mfaChange, disabled: this.state.disabled, data: this.state.mfa, errors: this.state.errors, buttons: this.props.user?.mfaEnabled !==
                            this.state.mfa.mfaEnabled, buttonText: this.props.user?.mfaEnabled
                            ? "DISABLE"
                            : "ENABLE", input: [
                            {
                                label: "MFA Enabled",
                                name: "mfaEnabled",
                                type: InputType.SmallToggle,
                            },
                            {
                                label: "Verify",
                                name: "mfaVerify",
                                type: InputType.Text,
                                hidden: !this.props.user?.mfaEnabled ===
                                    !this.state.mfa.mfaEnabled,
                            },
                        ] }),
                    this.state.mfa.png && React.createElement("img", { src: this.state.mfa.png })))));
    }
}
const mapStateToProps = ({ auth: { user } }) => ({
    user,
});
const mapDispatchToProps = (dispatch) => {
    return {
        notify: (data) => addNotification(dispatch, data),
        update: (user) => {
            switch (user.type) {
                case UserType.Admin:
                    return updateAdminUser(dispatch, user);
                case UserType.Customer:
                    return updateCustomerUser(dispatch, user);
                case UserType.Partner:
                case UserType.White_Label:
                    return updatePartnerUser(dispatch, user);
                case UserType.Supplier:
                    return updateSupplierUser(dispatch, user);
                default:
                    throw new Error("User type not found");
            }
        },
        refresh: async () => {
            await refresh(dispatch);
            await boot(dispatch);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
