// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--OTamJ{position:relative}.wrapper--OTamJ .number--jaPAX{position:absolute;top:.1rem;left:.7rem;color:#9a9a9a;font-weight:600}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/NumberedTick/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,iBAAiB,CAAC,+BAAiB,iBAAiB,CAAC,SAAS,CAAC,UAAU,CAAC,aAAa,CAAC,eAAe","sourcesContent":[".wrapper{position:relative}.wrapper .number{position:absolute;top:.1rem;left:.7rem;color:#9a9a9a;font-weight:600}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--OTamJ",
	"number": "number--jaPAX"
};
export default ___CSS_LOADER_EXPORT___;
