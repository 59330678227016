import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const getForAdmin = (callback) => {
    const url = `/tenders`;
    return collection((data) => callback(Factory.Tender(data)), url);
};
export const find = (tenderId, inclDel) => {
    const url = `/tenders/${tenderId}`;
    return api
        .get(url, { params: { inclDel } })
        .then((result) => Factory.Tender(result.data.item));
};
export const findTenderForComparison = (customerId, tenderId) => {
    const url = `/comparisons/${customerId}/tenders/${tenderId}`;
    return api.get(url).then((result) => Factory.Tender(result.data.item));
};
export const getForCustomer = (callback, customerId) => {
    const url = `/customers/${customerId}/tenders`;
    return collection((data) => callback(Factory.Tender(data)), url);
};
export const findForCustomer = (customerId, tenderId) => {
    const url = `/customers/${customerId}/tenders/${tenderId}`;
    return api.get(url).then((result) => Factory.Tender(result.data.item));
};
export const getForSupplier = (callback, supplierId) => {
    const url = `/suppliers/${supplierId}/tenders`;
    return collection((data) => callback(Factory.Tender(data)), url);
};
export const getForPartner = (callback, partnerId) => {
    const url = `/partners/${partnerId}/tenders`;
    return collection((data) => callback(Factory.Tender(data)), url);
};
export const post = (tender) => {
    return api
        .post(`/customers/${tender.customerId}/tenders`, tender.data)
        .then((result) => {
        return Factory.Tender(result.data.item);
    });
};
export const put = (tender) => {
    return api
        .put(`/customers/${tender.customerId}/tenders/${tender.id}`, tender.data)
        .then((result) => {
        return Factory.Tender(result.data.item);
    });
};
export const invite = (customerId, tenderId, email) => {
    return api
        .put(`customers/${customerId}/tenders/${tenderId}/invite`, { email })
        .then((result) => {
        return Factory.Tender(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const delayPublishDate = (customerId, tenderId, publishDate) => {
    return api
        .put(`customers/${customerId}/tenders/${tenderId}/change-publish-date`, { publishDate })
        .then((result) => {
        return Factory.Tender(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const unsubmit = (tender) => {
    return api
        .put(`/customers/${tender.customerId}/tenders/${tender.id}/unsubmit`)
        .then((result) => {
        return Factory.Tender(result.data.item);
    });
};
export const publish = (tender) => {
    return api
        .put(`/customers/${tender.customerId}/tenders/${tender.id}/publish`, tender.data)
        .then((result) => {
        return Factory.Tender(result.data.item);
    });
};
export const del = (tender) => {
    return api
        .delete(`/customers/${tender.customerId}/tenders/${tender.id}`)
        .then((result) => {
        console.log(result.data.item.id);
        return result.data.item.id;
    });
};
export const fetchPostcodeSignal = (tender, postcode) => {
    return api
        .get(`/customers/${tender.customerId}/tenders/${tender.id}/postcode-check/${postcode}`)
        .then((result) => {
        return result.data;
    });
};
