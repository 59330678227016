import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import React from "react";
import styles from "./index.module.scss";
export default class FormCalendar extends React.Component {
    static defaultProps = {
        value: "",
        error: false,
        name: "",
        valueChanged: () => { },
        onChange: () => { },
    };
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    changeInput = (value) => {
        if (this.props.valueChanged) {
            this.props.valueChanged(this.props.name, value);
        }
    };
    setOpen = (open) => {
        this.setState({
            open,
        });
    };
    render() {
        const { time = false, min, max, disabled, value } = this.props;
        const input = (params) => (React.createElement(TextField, { ...params, className: `${styles.input} 
                ${this.props.error ? styles.error : ""}
                ${this.props.className}
                ${this.props.noBorder ? styles.noBorder : ""}
                `, onClick: () => this.setOpen(true), sx: {
                "& .MuiInputBase-input": {
                    padding: "0.2rem",
                },
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                "& .MuiOutlinedInput-notchedOutline": {
                    display: "none",
                },
            } }));
        if (time) {
            return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                React.createElement(DateTimePicker, { inputFormat: "DD/MM/YYYY HH:mm", value: value, onChange: this.changeInput, renderInput: input, open: this.state.open, onOpen: () => this.setOpen(true), onClose: () => this.setOpen(false), minDate: min, maxDate: max, disabled: disabled, ampm: false })));
        }
        return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
            React.createElement(DesktopDatePicker, { inputFormat: "DD/MM/YYYY", value: value, onChange: this.changeInput, renderInput: input, open: this.state.open, onOpen: () => this.setOpen(true), onClose: () => this.setOpen(false), minDate: min, maxDate: max, disabled: disabled, views: this.props.monthOnly ? ["month", "year"] : undefined })));
    }
}
