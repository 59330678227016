import { get, put } from "@Api/Hardware/priceRange";
export const UPDATE_RANGE = "updateRange";
export const updateRange = (dispatch, data) => put(data).then((range) => {
    return dispatch({
        type: UPDATE_RANGE,
        data: range,
    });
});
export const fetchRange = (dispatch) => {
    return get().then((data) => {
        return dispatch({
            type: UPDATE_RANGE,
            data,
        });
    });
};
