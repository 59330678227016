import React from "react";
import Circle from "@Components/Icons/Circle";
import Tick from "@Components/Icons/Tick";
import styles from "./index.module.scss";
export default class NumberedTick extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.wrapper }, this.props.outlined ? (React.createElement(React.Fragment, null,
            React.createElement(Circle, { fillOpacity: 0.6, size: "1.9rem" }),
            React.createElement("div", { className: styles.number }, this.props.outlined && this.props.number))) : (React.createElement(Tick, { outlined: this.props.outlined, fillOpacity: 0.6, size: "1.9rem" }))));
    }
}
