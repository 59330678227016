import React from "react";
import { Route, Switch } from "react-router-dom";
import { NOT_FOUND, PASSWORD_RESET, RESET } from "@Shared/Paths/Auth";
import Login from "./Login";
import PasswordReset from "./PasswordReset";
import Reset from "./Reset";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: RESET, exact: true, render: ({ history }) => React.createElement(Reset, { goBack: history.goBack }) }),
        React.createElement(Route, { path: PASSWORD_RESET, render: ({ match }) => (React.createElement(PasswordReset, { ...match.params })) }),
        React.createElement(Route, { path: NOT_FOUND, render: () => React.createElement(Login, null) })));
};
