import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
export default (qty, type) => {
    switch (type) {
        case UnitType.Data:
            return qty * 1000;
        case UnitType.Mins:
            return qty * 60;
        case UnitType.SMS:
        case UnitType.MMS:
            return qty;
    }
};
