import { LOGOUT, SET_TOKEN } from "@Store/Actions/Auth";
export default (state = "", { type, data }) => {
    switch (type) {
        case SET_TOKEN:
            localStorage.setItem("token", data);
            return data;
        case LOGOUT:
            localStorage.removeItem("token");
            return "";
    }
    return state;
};
