import React from "react";
import { connect } from "react-redux";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { logout } from "@Store/Actions/Auth";
class Logout extends React.Component {
    componentDidMount() {
        this.props.logout().then(() => {
            const url = window.location.pathname;
            if (url.includes("logout")) {
                window.location.assign(`/auth/login`);
            }
        });
    }
    render() {
        return React.createElement(LoadingFallback, null);
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        logout: () => logout(dispatch),
    };
};
export default connect(null, mapDispatchToProps)(Logout);
