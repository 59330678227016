import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (partnerId = null) => {
    const url = `/partners/${partnerId}`;
    return api.get(url).then((result) => Factory.Partner(result.data.item));
};
export const get = (callback) => collection((data) => callback(Factory.Partner(data)), `/partners`);
export const post = (partner) => {
    return api
        .post(`/partners`, partner.data)
        .then((result) => {
        return Factory.Partner(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (partner) => {
    return api.put(`/partners/${partner.id}`, partner.data).then((result) => {
        return Factory.Partner(result.data.item);
    });
};
export const del = (partner) => {
    return api.delete(`/partners/${partner.id}`).then((result) => {
        return result.data.item;
    });
};
