import { del, find, get, post, publish, put, unsubmit, } from "@Api/Suppliers/responses";
export const DELETE_RESPONSE = "deleteResponse";
export const deleteResponse = (dispatch, response) => del(response).then((id) => {
    return dispatch({
        type: DELETE_RESPONSE,
        data: id,
    });
});
export const ADD_RESPONSE = "updateResponse";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_RESPONSE,
        data,
    });
};
export const createResponse = (dispatch, data) => post(data).then((response) => {
    add(dispatch)(response);
    return response;
});
export const updateResponse = (dispatch, data) => put(data).then(add(dispatch));
export const unsubmitResponse = (dispatch, data) => unsubmit(data).then(add(dispatch));
export const autoSaveResponse = (data) => put(data);
export const publishResponse = (dispatch, data) => publish(data).then(add(dispatch));
export const fetchResponse = (dispatch, supplierId, responseId, editing, inclDel) => {
    return find(supplierId, responseId, editing, inclDel).then(add(dispatch));
};
export const fetchResponses = (dispatch, supplierId) => get(add(dispatch), supplierId);
export const EDIT_PROPOSAL = "editProposal";
export const editProposal = (dispatch, data) => dispatch({
    type: EDIT_PROPOSAL,
    data,
});
export const PROPOSAL_TAB = "ProposalTab";
export const proposalTab = (dispatch, data) => dispatch({
    type: PROPOSAL_TAB,
    data,
});
export const EDIT_BUNDLES = "editBundles";
export const editBundles = (dispatch, data) => dispatch({
    type: EDIT_BUNDLES,
    data,
});
