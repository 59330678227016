import React from "react";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import Document from "@Components/Icons/Document";
import Fingerprint from "@Components/Icons/Fingerprint";
import OpenInNew from "@Components/Icons/OpenInNew";
import Pound from "@Components/Icons/Pound";
import styles from "./index.module.scss";
export default class TreatData extends React.Component {
    render() {
        return (React.createElement(ColouredBorderBox, { title: React.createElement(React.Fragment, null,
                "How we treat your\u00A0",
                React.createElement("b", { className: styles.primaryColor }, "Data")), className: this.props.className, matchWidth: "uploadPopout" },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.content },
                    React.createElement("div", { className: styles.message },
                        React.createElement("div", { className: styles.messageTitle },
                            React.createElement("div", { className: styles.icon },
                                React.createElement(Pound, null)),
                            "Costs hidden from Suppliers"),
                        React.createElement("div", { className: styles.messageContent }, "Suppliers compete for your\u00A0business")),
                    React.createElement("div", { className: styles.message },
                        React.createElement("div", { className: styles.messageTitle },
                            React.createElement("div", { className: styles.icon },
                                React.createElement(Document, null)),
                            "Anonymised"),
                        React.createElement("div", { className: styles.messageContent }, "All personal identifiers removed")),
                    React.createElement("div", { className: styles.message },
                        React.createElement("div", { className: styles.messageTitle },
                            React.createElement("div", { className: styles.icon },
                                React.createElement(Fingerprint, null)),
                            "Safe and Secure"),
                        React.createElement("div", { className: styles.messageContent },
                            React.createElement("a", { href: "https://docs.predictmobile.com/policies/DataSecurity/", target: "_blank" },
                                "Data Security Policy",
                                " ",
                                React.createElement(OpenInNew, { size: "0.85rem" }))))))));
    }
}
