import React from "react";
import LogoText from "@Components/Icons/Logos/LogoText";
import { SupportEmail } from "@Components/Support/Email";
import SupportTel from "@Components/Support/Tel";
import { isPlatform } from "@Helpers/GetSubdomain";
import styles from "./index.module.scss";
export default class Header extends React.Component {
    static defaultProps = {
        logout: true,
    };
    render() {
        const view = isPlatform();
        return (React.createElement("div", null,
            React.createElement("span", { className: styles.greenBox }, view === true && (React.createElement("div", { className: styles.logoArea },
                React.createElement(SupportTel, null),
                " | ",
                React.createElement(SupportEmail, null)))),
            React.createElement("div", { className: styles.whiteBox },
                React.createElement("div", { className: styles.logoArea },
                    React.createElement(LogoText, { className: styles.logo }),
                    React.createElement("span", { className: styles.title }, this.props.title)))));
    }
}
