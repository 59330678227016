import SVG from "@Components/Icons/SVG";
export default class Android extends SVG {
    path = [
        "M 6.1893 32.3915 C 4.4663 32.3915 3.0044 32.9923 1.8027 34.194 C 0.601 35.3956 0 36.8377 0 38.5206 V 64.3619 C 0 66.0869 0.6009 67.5475 1.8027 68.7494 C 3.0044 69.9512 4.4663 70.5521 6.1893 70.5521 C 7.9111 70.5521 9.3641 69.9512 10.5472 68.7494 C 11.7279 67.5477 12.3199 66.0869 12.3199 64.3619 V 38.5206 C 12.3199 36.8375 11.719 35.3956 10.5171 34.194 C 9.3153 32.9923 7.8724 32.3915 6.1893 32.3915 Z",
        "M 55.9496 9.1939 L 60.2165 1.3226 C 60.4965 0.8015 60.3969 0.4019 59.9167 0.1209 C 59.3954 -0.1214 58.9948 0.0014 58.715 0.4806 L 54.3883 8.4148 C 50.5812 6.7316 46.5548 5.8884 42.3085 5.8884 C 38.0617 5.8884 34.0346 6.7317 30.229 8.4148 L 25.9023 0.4806 C 25.621 0.0014 25.2206 -0.1203 24.7006 0.1209 C 24.2192 0.4032 24.1196 0.8015 24.4008 1.3226 L 28.6679 9.1939 C 24.341 11.4004 20.8952 14.4734 18.3311 18.4195 C 15.7672 22.3677 14.4847 26.6825 14.4847 31.371 H 70.0721 C 70.0721 26.6835 68.7894 22.3687 66.2256 18.4195 C 63.6615 14.4734 60.2354 11.4004 55.9496 9.1939 Z",
        "M 31.2802 20.6428 C 30.8187 21.1054 30.2677 21.3354 29.6271 21.3354 C 28.9852 21.3354 28.4452 21.1054 28.0048 20.6428 C 27.5644 20.1824 27.3442 19.6335 27.3442 18.9907 C 27.3442 18.3501 27.5644 17.8 28.0048 17.3386 C 28.4452 16.8782 28.9865 16.648 29.6271 16.648 C 30.2677 16.648 30.8187 16.8782 31.2802 17.3386 C 31.7406 17.8011 31.9719 18.3501 31.9719 18.9907 C 31.9706 19.6324 31.7396 20.1824 31.2802 20.6428 Z",
        "M 56.61 20.6428 C 56.1687 21.1054 55.6275 21.3354 54.9879 21.3354 C 54.3458 21.3354 53.795 21.1054 53.3344 20.6428 C 52.8731 20.1824 52.6429 19.6335 52.6429 18.9907 C 52.6429 18.3501 52.8731 17.8 53.3344 17.3386 C 53.795 16.8782 54.3458 16.648 54.9879 16.648 C 55.6285 16.648 56.1685 16.8782 56.61 17.3386 C 57.0508 17.8011 57.2708 18.3501 57.2708 18.9907 C 57.2708 19.6324 57.0506 20.1824 56.61 20.6428 Z",
        "M 14.7232 73.5565 C 14.7232 75.4012 15.3638 76.9627 16.6453 78.2442 C 17.9278 79.5256 19.4893 80.1662 21.3327 80.1662 H 25.78 L 25.841 93.8096 C 25.841 95.5323 26.4419 96.9954 27.6435 98.1973 C 28.8452 99.3992 30.2883 100 31.9704 100 C 33.6923 100 35.155 99.3992 36.3569 98.1973 C 37.5587 96.9954 38.1596 95.5325 38.1596 93.8096 V 80.1675 H 46.4525 V 93.8096 C 46.4525 95.5323 47.0533 96.9954 48.2552 98.1973 C 49.4571 99.3992 50.9185 100 52.6417 100 C 54.3637 100 55.8265 99.3992 57.0283 98.1973 C 58.2302 96.9954 58.8308 95.5325 58.8308 93.8096 V 80.1675 H 63.3383 C 65.1408 80.1675 66.6823 79.5267 67.966 78.2452 C 69.2473 76.9637 69.8883 75.4023 69.8883 73.5577 V 33.5323 H 14.7232 V 73.5565 Z",
        "M 78.4254 32.3915 C 76.7421 32.3915 75.3004 32.9833 74.0985 34.1642 C 72.8967 35.3469 72.2958 36.8 72.2958 38.5206 V 64.3619 C 72.2958 66.0869 72.8965 67.5475 74.0985 68.7494 C 75.3004 69.9512 76.7433 70.5521 78.4254 70.5521 C 80.1473 70.5521 81.61 69.9512 82.8119 68.7494 C 84.0137 67.5475 84.6146 66.0869 84.6146 64.3619 V 38.5206 C 84.6146 36.7998 84.0137 35.3469 82.8119 34.1642 C 81.61 32.9833 80.1473 32.3915 78.4254 32.3915 Z",
    ];
    evenOdd = true;
}
