import { ScoreFieldList } from "./enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    for (const field of ScoreFieldList) {
        if (current[field] && current[field] <= 1) {
            current[field] = Math.round(current[field] * 100);
        }
        if (!current[`${field}Normalised`]) {
            current[`${field}Normalised`] = current[field];
        }
    }
    return { ...current, version: 2 };
};
export default (original = {}) => {
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    const { version, pmScore, priceScore, tariffScore, csScore, signalScore, convenienceScore, pmScoreRank, priceScoreRank, tariffScoreRank, csScoreRank, signalScoreRank, convenienceScoreRank, pmScoreNormalised, priceScoreNormalised, tariffScoreNormalised, csScoreNormalised, signalScoreNormalised, convenienceScoreNormalised, pmScoreColor, priceScoreColor, tariffScoreColor, csScoreColor, signalScoreColor, convenienceScoreColor, lowMarginWarning, } = converted;
    return {
        version,
        pmScore,
        priceScore,
        tariffScore,
        csScore,
        signalScore,
        convenienceScore,
        pmScoreRank,
        priceScoreRank,
        tariffScoreRank,
        csScoreRank,
        signalScoreRank,
        convenienceScoreRank,
        pmScoreNormalised,
        priceScoreNormalised,
        tariffScoreNormalised,
        csScoreNormalised,
        signalScoreNormalised,
        convenienceScoreNormalised,
        pmScoreColor,
        priceScoreColor,
        tariffScoreColor,
        csScoreColor,
        signalScoreColor,
        convenienceScoreColor,
        lowMarginWarning,
    };
};
