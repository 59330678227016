// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--a_DaF{display:flex;min-height:3.5em;padding:.3em;background-color:var(--card-color);border-radius:5em;box-shadow:0 0 8px 0 rgba(0,0,0,.3490196078)}.input--a_DaF.multi--Y1Cj8{overflow:hidden}.input--a_DaF .send--u2TzH{display:flex;justify-content:center;width:3em}@media(min-width: 960px){.input--a_DaF{width:80%;margin:auto}}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/TextInput/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,YAAY,CAAC,gBAAgB,CAAC,YAAY,CAAC,kCAAkC,CAAC,iBAAiB,CAAC,4CAA4C,CAAC,2BAAa,eAAe,CAAC,2BAAa,YAAY,CAAC,sBAAsB,CAAC,SAAS,CAAC,yBAAyB,cAAO,SAAS,CAAC,WAAW,CAAC","sourcesContent":[".input{display:flex;min-height:3.5em;padding:.3em;background-color:var(--card-color);border-radius:5em;box-shadow:0 0 8px 0 rgba(0,0,0,.3490196078)}.input.multi{overflow:hidden}.input .send{display:flex;justify-content:center;width:3em}@media(min-width: 960px){.input{width:80%;margin:auto}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--a_DaF",
	"multi": "multi--Y1Cj8",
	"send": "send--u2TzH"
};
export default ___CSS_LOADER_EXPORT___;
