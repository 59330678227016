import dayjs from "dayjs";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    const { charitySocialEnterprise } = current;
    return { ...current, VATRegistered: charitySocialEnterprise, version: 2 };
};
export default (original = {}) => {
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    let { version, voiceLines, voiceOnlyLines, dataLines, length, overallData, sameTime, leasingType, linesEnd, estimatedLines, estimatedDataLines, currentNetwork, ATC, ATCUnits, techFund, increaseUsage, zoom, dep, fiveG, onlineTeams, microsoft365, dataAcquisition, otherDataIncrease, highCost, iddCost, roamingCost, iddCostCountries, roamingCostCountries, MDM, ownMDM, MDMMSInTune, MDMInLine, MDMFee, MDMEnd, costSpread, hardwareType, OSSpread, priceRange, existingDevices, numberExistingDevices, changeNetwork, multipleNetwork, charity, VATRegistered, charitySocialEnterprise, extraDetails, likeHardwareFunded, newEndDate, questionIndex, handsets, pickedHandsets, postcodes, locked, highCostCountries, euCostCountries, simOnlyFunded, lines, complete = false, vastMajorityLinesRenewal, linesBeginning, linesWithinThreeMonths, linesWithinSixMonths, manualCurrentCost, } = converted;
    if (questionIndex === undefined) {
        questionIndex = 0;
    }
    if (voiceLines !== undefined) {
        voiceLines = Number(voiceLines);
    }
    if (voiceOnlyLines !== undefined) {
        voiceOnlyLines = Number(voiceOnlyLines);
    }
    if (dataLines !== undefined) {
        dataLines = Number(dataLines);
    }
    if (length !== undefined) {
        length = Number(length);
    }
    if (linesBeginning !== undefined) {
        linesBeginning = Number(linesBeginning);
    }
    if (overallData !== undefined) {
        overallData = Number(overallData);
    }
    if (estimatedLines !== undefined) {
        estimatedLines = Number(estimatedLines);
    }
    if (estimatedDataLines !== undefined) {
        estimatedDataLines = Number(estimatedDataLines);
    }
    if (ATC !== undefined) {
        ATC = Number(ATC);
    }
    if (costSpread !== undefined) {
        costSpread = Number(costSpread);
    }
    if (OSSpread !== undefined) {
        OSSpread = Number(OSSpread);
    }
    if (existingDevices !== undefined) {
        existingDevices = Number(existingDevices);
    }
    if (numberExistingDevices !== undefined) {
        numberExistingDevices = Number(numberExistingDevices);
    }
    if (techFund !== undefined) {
        techFund = Number(techFund);
    }
    if (MDMEnd !== undefined) {
        MDMEnd = dayjs(MDMEnd);
    }
    return {
        version,
        voiceLines,
        dataLines,
        voiceOnlyLines,
        length,
        overallData,
        leasingType,
        sameTime,
        linesEnd,
        estimatedLines,
        estimatedDataLines,
        ATC,
        ATCUnits,
        techFund,
        increaseUsage,
        zoom,
        VATRegistered,
        charitySocialEnterprise,
        dep,
        fiveG,
        onlineTeams,
        microsoft365,
        dataAcquisition,
        otherDataIncrease,
        highCost,
        iddCost,
        roamingCost,
        iddCostCountries,
        roamingCostCountries,
        MDM,
        ownMDM,
        MDMMSInTune,
        MDMInLine,
        MDMFee,
        MDMEnd,
        costSpread,
        hardwareType,
        OSSpread,
        priceRange,
        existingDevices,
        numberExistingDevices,
        changeNetwork,
        multipleNetwork,
        charity,
        extraDetails,
        likeHardwareFunded,
        currentNetwork,
        newEndDate,
        questionIndex,
        handsets,
        pickedHandsets,
        postcodes,
        locked,
        highCostCountries,
        euCostCountries,
        simOnlyFunded,
        lines,
        complete,
        vastMajorityLinesRenewal,
        linesBeginning,
        linesWithinThreeMonths,
        linesWithinSixMonths,
        manualCurrentCost,
    };
};
