import React from "react";
import AppSwitch from "@Components/AppSwitch";
import Button from "@Components/Button";
export default class AppSwitchButtonLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        };
    }
    click = () => {
        this.setState({ redirect: true });
    };
    render() {
        const { to, ...props } = this.props;
        return (React.createElement(React.Fragment, null,
            this.state.redirect && React.createElement(AppSwitch, { uri: to }),
            React.createElement(Button, { ...props, click: this.click }, this.props.children)));
    }
}
