import React from "react";
import Select from "react-select";
const cssPrimaryColor = "#0cabdb";
const cssPrimaryColorLight = "#33abda";
const cssBorderColour = "#d7dada";
const pageWrap = "960px";
export default class Multi extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const styles = {
            option: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isSelected
                        ? cssPrimaryColor
                        : state.isFocused
                            ? cssPrimaryColorLight
                            : provided.backgroundColor,
                };
            },
            control: (provided, state) => {
                return {
                    ...provided,
                    borderColor: state.isFocused
                        ? cssPrimaryColor
                        : cssBorderColour,
                    boxShadow: state.isFocused
                        ? `0 0 0 1px ${cssPrimaryColor}`
                        : "",
                    "&:hover": {
                        borderColor: cssPrimaryColor,
                    },
                    marginTop: "0.3em",
                    minHeight: "3.5em",
                    padding: "0.3em",
                    borderRadius: "5em",
                    [`@media (min-width: ${pageWrap})`]: {
                        width: "80%",
                        margin: "auto",
                    },
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    zIndex: 4,
                    position: "relative",
                    [`@media (min-width: ${pageWrap})`]: {
                        width: "80%",
                        margin: "auto",
                    },
                };
            },
            multiValue: (provided) => {
                return {
                    ...provided,
                    borderRadius: "5em",
                    padding: "0.1em",
                };
            },
            multiValueRemove: (provided) => {
                return {
                    ...provided,
                    borderRadius: "100%",
                };
            },
        };
        return (React.createElement(Select, { ...{
                ...this.props,
                styles,
                isMulti: true,
            } }));
    }
}
