import React from "react";
import styles from "./index.module.scss";
export default class VodafoneWithoutTextIcon extends React.Component {
    render() {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 150 150", version: "1.1", className: `${this.props.size ? "" : styles.vodafone} ${this.props.className || ""}`, style: { width: this.props.size } },
            React.createElement("title", null, "Vodafone Logo"),
            React.createElement("g", { id: "surface1" },
                React.createElement("path", { className: styles.vf1, d: "M 56.5304 28.784 C 69.0616 23.79 83.6608 24.1332 95.8576 29.9324 C 92.3816 29.4044 88.844 29.8048 85.4076 30.3856 C 76.062 32.15 67.1828 36.5456 60.244 43.0796 C 53.5868 49.618 48.9272 58.2992 47.6072 67.5832 C 46.736 74.1612 47.5456 81.0648 50.498 87.0532 C 53.5384 93.3364 58.8932 98.4888 65.348 101.1464 C 71.5696 103.782 78.7812 103.7512 85.082 101.4016 C 94.5332 97.9344 101.3268 88.5668 102.1056 78.5568 C 102.594 72.0008 100.9968 65.0224 96.5924 59.9932 C 92.386 55.0696 86.2304 52.3548 80.0748 50.8104 C 79.7448 44.7384 82.6048 38.7412 87.1412 34.7944 C 89.6624 32.5196 92.7864 31.072 96.0204 30.148 L 96.2668 30.06 C 105.52 34.4996 113.3872 41.8212 118.3988 50.806 C 122.6888 58.4576 124.9284 67.2708 124.7084 76.0532 C 124.6644 87.4096 120.4184 98.656 113.1452 107.3372 C 106.2636 115.618 96.6408 121.5756 86.1512 123.9604 C 75.6352 126.3892 64.3184 125.342 54.4756 120.8716 C 44.8484 116.5684 36.6864 109.0928 31.4988 99.8924 C 27.178 92.2496 24.8944 83.4364 25.0836 74.6452 C 25.1056 63.6892 28.9952 52.8212 35.7888 44.2544 C 41.214 37.4124 48.408 31.974 56.5304 28.784 Z M 56.5304 28.784" }),
                React.createElement("path", { className: styles.vf2, d: "M 85.4076 30.3856 C 88.844 29.8048 92.3816 29.4044 95.8576 29.9324 L 96.3548 30.0204 L 96.0204 30.148 C 92.7864 31.072 89.6624 32.5196 87.1412 34.7944 C 82.6048 38.7412 79.7448 44.7384 80.0748 50.8104 C 86.2304 52.3548 92.386 55.0696 96.5924 59.9932 C 100.9968 65.0224 102.594 72.0008 102.1056 78.5568 C 101.3268 88.5668 94.5332 97.9344 85.082 101.4016 C 78.7812 103.7512 71.5696 103.782 65.348 101.1464 C 58.8932 98.4888 53.5384 93.3364 50.498 87.0532 C 47.5456 81.0648 46.736 74.1612 47.6072 67.5832 C 48.9272 58.2992 53.5868 49.618 60.244 43.0796 C 67.1828 36.5456 76.062 32.15 85.4076 30.3856 Z M 85.4076 30.3856" }))));
    }
}
