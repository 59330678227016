import React from "react";
import Button from "@Components/Button";
import ButtonLink from "@Components/ButtonLink";
import DoubleSlider from "@Components/DoubleSlider";
import FormCalendar from "@Components/Form/Calendar";
import Dropdown from "@Components/Form/Dropdown";
import FormGroup from "@Components/Form/Group";
import FormHeader from "@Components/Form/Header";
import FormInput from "@Components/Form/Input";
import LargeFormInput from "@Components/Form/LargeInput";
import LargeToggle from "@Components/Form/LargeToggle";
import MultiTextAdd from "@Components/Form/MultiTextAdd";
import RadioGroup from "@Components/Form/RadioGroup";
import RepeatGroup from "@Components/Form/RepeatGroup";
import TwoWayToggleSmall from "@Components/Form/TwoWayToggleSmall";
import Loading from "@Components/Icons/Loading";
import buttonStyles from "@Components/Sass/buttons.module.scss";
import SingleSlider from "@Components/SingleSlider";
import styles from "./index.module.scss";
export var InputType;
(function (InputType) {
    InputType[InputType["Text"] = 0] = "Text";
    InputType[InputType["LargeText"] = 1] = "LargeText";
    InputType[InputType["Number"] = 2] = "Number";
    InputType[InputType["Password"] = 3] = "Password";
    InputType[InputType["Currency"] = 4] = "Currency";
    InputType[InputType["FakeCurrency"] = 5] = "FakeCurrency";
    InputType[InputType["Percentage"] = 6] = "Percentage";
    InputType[InputType["Lines"] = 7] = "Lines";
    InputType[InputType["GB"] = 8] = "GB";
    InputType[InputType["SmallToggle"] = 9] = "SmallToggle";
    InputType[InputType["LargeToggle"] = 10] = "LargeToggle";
    InputType[InputType["DropdownOptions"] = 11] = "DropdownOptions";
    InputType[InputType["DropdownEnum"] = 12] = "DropdownEnum";
    InputType[InputType["Slider"] = 13] = "Slider";
    InputType[InputType["DoubleSlider"] = 14] = "DoubleSlider";
    InputType[InputType["Email"] = 15] = "Email";
    InputType[InputType["MultiAddText"] = 16] = "MultiAddText";
    InputType[InputType["Calendar"] = 17] = "Calendar";
    InputType[InputType["Group"] = 18] = "Group";
    InputType[InputType["RepeatGroup"] = 19] = "RepeatGroup";
    InputType[InputType["RadioGroup"] = 20] = "RadioGroup";
    InputType[InputType["PlusMinus"] = 21] = "PlusMinus";
})(InputType || (InputType = {}));
export default class Form extends React.Component {
    static defaultProps = {
        className: "",
        autocomplete: true,
        cancel: "",
        buttonText: "SUBMIT",
        disabled: false,
        input: [],
        data: {},
        buttons: true,
        noSave: false,
        errors: {},
        submit: () => {
            console.log("Submit!");
        },
        rightButton: false,
    };
    constructor(props) {
        super(props);
        const formGroups = this.props.input.reduce((fg, input) => {
            const { type, name } = input;
            if (type !== InputType.RepeatGroup) {
                return fg;
            }
            fg[name] = this.props.data[name] ? this.props.data[name].length : 1;
            return fg;
        }, {});
        this.state = {
            loading: false,
            componentIsMounted: true,
            formGroups,
        };
    }
    componentWillUnmount() {
        this.setState({
            componentIsMounted: false,
        });
    }
    async submit(events) {
        events.preventDefault();
        this.setState({ loading: true });
        const callback = await this.props.submit();
        if (this.state.componentIsMounted) {
            this.setState({ loading: false });
            return typeof callback === "function" ? callback() : callback;
        }
    }
    reportChange = (field, value) => {
        const data = { ...this.props.data };
        if (this.props.change) {
            data[field] = value;
            this.props.change(data);
        }
        if (this.props.inputChange) {
            const input = this.props.input.find(({ name }) => name === field);
            if (input) {
                this.props.inputChange({ ...input, value });
            }
        }
    };
    change = (field, value) => {
        this.reportChange(field, value);
    };
    changeDropdown = ({ field, option }) => {
        this.reportChange(field, option.value);
    };
    changeRepeatedDropdown = (index, group, { field, option }) => {
        return this.changeRepeatedComponent(index, group, field, option.value);
    };
    changeRepeatedComponent = (index, group = "test", field, value) => {
        const { [group]: g = [] } = this.props.data;
        const object = g[index] || {};
        object[field] = value;
        g[index] = object;
        this.reportChange(group, g);
    };
    repeatGroupChange = (name) => (data) => {
        this.reportChange(name, data);
    };
    changeMultiDropdown = ({ field, option, }) => {
        const value = option.map(({ value }) => value);
        this.reportChange(field, value);
    };
    createComponent = ({ name, group, type, options, placeholder, multi, characters, range, headers, validText, separators, ticks, max, min, disabled, groupInputs, index, limit, relative, toggleOptions, repeatText, repeatButtonRound, small, noDecimal, green, roundEdges, blue, showPassword, filterOptions, addButton, long, reducedWidth, left, time, defaultObj, }) => {
        const getField = (data, name, group, index) => {
            try {
                if (group && index !== undefined) {
                    return data[group][index][name];
                }
                return data[name];
            }
            catch {
                return;
            }
        };
        const value = getField(this.props.data, name, group, index);
        const error = getField(this.props.errors, name, group, index);
        const change = index === undefined
            ? this.change
            : this.changeRepeatedComponent.bind(this, index, group);
        const dropdownChange = index === undefined
            ? multi === true
                ? this.changeMultiDropdown
                : this.changeDropdown
            : this.changeRepeatedDropdown.bind(this, index, group);
        switch (type) {
            case InputType.Number:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, type: "number", valueChanged: change, disabled: disabled, blue: blue, setErrors: this.props.setErrors, left: left }));
            case InputType.Password:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, type: "password", valueChanged: change, disabled: disabled, showPassword: showPassword, setErrors: this.props.setErrors }));
            case InputType.Lines:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, type: "lines", valueChanged: change, disabled: disabled, noDecimal: noDecimal, blue: blue, setErrors: this.props.setErrors }));
            case InputType.GB:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, type: "gb", valueChanged: change, disabled: disabled, noDecimal: noDecimal, blue: blue, setErrors: this.props.setErrors }));
            case InputType.Currency:
                return (React.createElement(FormInput, { error: error, value: value, name: name, type: "currency", valueChanged: change, disabled: disabled, noDecimal: noDecimal, placeholder: placeholder, blue: blue, setErrors: this.props.setErrors }));
            case InputType.FakeCurrency:
                return (React.createElement(FormInput, { error: error, value: value, name: name, type: "fakeCurrency", valueChanged: change, disabled: disabled, noDecimal: noDecimal, setErrors: this.props.setErrors }));
            case InputType.Percentage:
                return (React.createElement(FormInput, { error: error, value: value, name: name, type: "percentage", valueChanged: change, disabled: disabled, noDecimal: noDecimal, blue: blue, setErrors: this.props.setErrors }));
            case InputType.Text:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, maxlength: limit, type: "text", valueChanged: change, disabled: disabled, blue: blue, setErrors: this.props.setErrors }));
            case InputType.PlusMinus:
                return (React.createElement(FormInput, { error: error, value: value, name: name, type: "currency", plusMinus: true, valueChanged: change, disabled: disabled, noDecimal: noDecimal, setErrors: this.props.setErrors }));
            case InputType.Calendar:
                return (React.createElement(FormCalendar, { error: error, value: value, name: name, valueChanged: change, max: max, min: min, disabled: disabled, time: time }));
            case InputType.LargeText:
                return (React.createElement(LargeFormInput, { error: error, value: value, name: name, type: "text", valueChanged: change, characterLimit: characters, disabled: disabled }));
            case InputType.Email:
                return (React.createElement(FormInput, { error: error, value: value, placeholder: placeholder, name: name, type: "email", valueChanged: change, disabled: disabled, setErrors: this.props.setErrors }));
            case InputType.LargeToggle:
                return (React.createElement(LargeToggle, { optionSelected: change, options: toggleOptions, selected: value, name: name, error: error, disabled: disabled, small: small, green: green, setErrors: this.props.setErrors, long: long, reducedWidth: reducedWidth }));
            case InputType.RadioGroup:
                return (React.createElement(RadioGroup, { value: value, valueChange: change, name: name, options: options, error: error }));
            case InputType.DropdownEnum:
                return (React.createElement(Dropdown, { enum: options, error: error, onChange: dropdownChange, placeholder: placeholder, value: value, isMulti: multi, name: name, disabled: disabled, relative: relative, setErrors: this.props.setErrors, filterOptions: filterOptions }));
            case InputType.DropdownOptions:
                return (React.createElement(Dropdown, { options: options, error: error, onChange: dropdownChange, placeholder: placeholder, value: value, isMulti: multi, name: name, disabled: disabled, relative: relative, setErrors: this.props.setErrors, filterOptions: filterOptions }));
            case InputType.SmallToggle:
                return (React.createElement(TwoWayToggleSmall, { toggle: change, value: value, name: name, disabled: disabled, green: green }));
            case InputType.MultiAddText:
                return (React.createElement(MultiTextAdd, { name: name, error: error, values: value, onChange: change, format: validText, separators: separators, placeHolder: placeholder, disabled: disabled, limit: limit }));
            case InputType.Slider:
                return (React.createElement(SingleSlider, { value: value, update: change, name: name, range: range, ticks: ticks, headers: headers(value), disabled: disabled, error: error }));
            case InputType.DoubleSlider:
                return (React.createElement(DoubleSlider, { value: value, update: change, name: name, range: range, disabled: disabled, error: error }));
            case InputType.RepeatGroup:
                return (React.createElement(RepeatGroup, { data: value, error: error, change: this.repeatGroupChange(name), inputs: groupInputs, repeatText: repeatText, repeatButtonRound: repeatButtonRound, roundEdges: roundEdges, addButton: addButton, defaultObj: defaultObj }));
        }
    };
    render() {
        const horizontal = this.props.horizontal ? styles.horizontal : "";
        const notes = this.props.input.some(({ notes }) => notes !== undefined && notes !== "");
        const topNotes = this.props.input.some(({ topNotes }) => topNotes !== undefined && topNotes !== "");
        const inputs = this.props.input.map(({ extraComponent, ...input }, index) => (React.createElement(FormGroup, { label: input.label, key: index, hidden: input.hidden, notes: input.notes || (notes ? " " : undefined), topNotes: input.topNotes || (topNotes ? " " : undefined), badge: input.badge, width: input.badgeWidth, labelLeft: this.props.labelLeft, topRight: this.props.topRight, className: `${styles.formGroup} ${styles.topRight} ${horizontal} ${input.className}`, inputClassName: input.inputClassName, noPadding: input.noPadding, horizontal: this.props.horizontal, longerInput: this.props.longerInput || input.longerInput, narrowLabel: this.props.narrowLabel },
            this.createComponent(input),
            extraComponent)));
        const { loading } = this.state;
        return (React.createElement("div", { className: `${loading ? styles.loading : ""} ${this.props.className} ${styles.wrapper}` },
            React.createElement("form", { onSubmit: this.submit.bind(this), autoComplete: this.props.autocomplete ? `on` : `off`, className: `${horizontal}` },
                this.props.header !== undefined && (React.createElement(FormHeader, null, this.props.header)),
                this.props.topSlot,
                inputs,
                this.props.buttons && (React.createElement(FormGroup, { className: buttonStyles.splitGroups },
                    this.props.cancel == "" &&
                        this.props.noSave === false &&
                        this.props.rightButton === false && (React.createElement("div", { className: buttonStyles.buttonGroupLeft },
                        React.createElement(Button, { disabled: this.props.disabled, type: "submit" }, this.props.buttonText))),
                    this.props.cancel == "" &&
                        this.props.noSave === false &&
                        this.props.rightButton === true && (React.createElement("div", { className: buttonStyles.buttonGroupRight },
                        React.createElement(Button, { disabled: this.props.disabled, type: "submit" }, this.props.buttonText))),
                    this.props.cancel !== "" && (React.createElement("div", { className: buttonStyles.splitGroups },
                        React.createElement("div", { className: buttonStyles.buttonGroupLeft },
                            React.createElement(Button, { disabled: this.props.disabled, type: "submit", className: buttonStyles.buttons }, this.props.buttonText)),
                        React.createElement("div", { className: buttonStyles.buttonGroupRight },
                            React.createElement(ButtonLink, { dark: true, to: this.props.cancel, className: buttonStyles.buttons }, "CANCEL")))))),
                this.props.bottomSlot,
                this.props.children,
                loading && (React.createElement(Loading, { size: "5rem", className: styles.spinner })))));
    }
}
