import React from "react";
import { ChatWho } from "@Components/ChatBot";
import Info from "@Components/Icons/Info";
import Tooltip from "../Tooltip";
import styles from "./index.module.scss";
export default class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }
    showTooltip() {
        const visible = this.props.tooltip !== undefined;
        this.setState({ visible });
    }
    closeTooltip = () => {
        this.setState({ visible: false });
    };
    render() {
        let style = "";
        switch (this.props.who) {
            case ChatWho.PredictyBot:
                style = styles.predicty;
                break;
            case ChatWho.Customer:
                style = styles.customer;
                break;
        }
        return (React.createElement("div", { className: styles.chat },
            this.props.who !== ChatWho.PredictyBot && (React.createElement("div", { className: styles.empty })),
            React.createElement("div", { className: `${styles.message} ${style}` },
                this.props.message,
                this.props.tooltip !== undefined && (React.createElement(Info, { onClick: this.showTooltip.bind(this), size: "1em", light: true })),
                this.state.visible && (React.createElement(Tooltip, { close: this.closeTooltip }, this.props.tooltip))),
            this.props.who === ChatWho.PredictyBot ? (React.createElement("div", { className: styles.empty })) : null));
    }
}
