import React from "react";
import DoubleSlider from "@Components/DoubleSlider";
import Dropdown from "@Components/Form/Dropdown";
import LargeToggle from "@Components/Form/LargeToggle";
import styles from "./index.module.scss";
export default class FilterMenuField extends React.Component {
    updateOptions = ({ target }) => {
        const selected = new Set(this.props.selected);
        if (target.checked) {
            selected.add(target.value);
        }
        else {
            selected.delete(target.value);
        }
        this.props.updateSelected([...selected], this.props.field);
    };
    updateArray = ({ option }) => {
        const values = option.map(({ value }) => value);
        this.props.updateArray([...values], this.props.field);
    };
    updateRange = (name, value) => {
        this.setState({ selected: value });
        this.props.updateRange({
            min: value[0],
            max: value[1],
        }, this.props.field);
    };
    updateToggle = (name, value) => {
        if (this.props.selected[0] === value) {
            this.props.updateSelected([], name);
        }
        else {
            this.props.updateSelected([value], name);
        }
    };
    render() {
        const listItems = this.props.options ? (this.props.toggle ? (React.createElement(LargeToggle, { optionSelected: this.updateToggle, options: this.props.options.map((value) => ({
                value,
                label: value,
            })), selected: this.props.selected[0], name: this.props.field, small: true })) : (this.props.options.map((name) => {
            const checked = this.props.selected.includes(name);
            return (React.createElement("li", { key: name },
                React.createElement("label", null,
                    React.createElement("input", { type: "checkbox", value: name, checked: checked, onChange: this.updateOptions }),
                    name)));
        }))) : (React.createElement(React.Fragment, null));
        const dropdown = this.props.array ? (React.createElement(Dropdown, { options: this.props.array.map((value) => ({
                value,
                label: value,
            })), isMulti: true, isClearable: true, onChange: this.updateArray })) : (React.createElement(React.Fragment, null));
        const doubleSlider = this.props.range ? (React.createElement(DoubleSlider, { range: [this.props.range.min, this.props.range.max], value: this.props.rangeFilters[this.props.field]
                ? [
                    this.props.rangeFilters[this.props.field].min,
                    this.props.rangeFilters[this.props.field].max,
                ]
                : [this.props.range.min, this.props.range.max], update: this.updateRange, name: "filterSlider" })) : (React.createElement(React.Fragment, null));
        return (React.createElement("div", { className: `${styles.filterMenuItem} ${this.props.large ? styles.large : undefined}` },
            React.createElement("div", { className: styles.filterTitle }, this.props.header),
            React.createElement("ul", { className: styles.filterList }, listItems),
            dropdown,
            doubleSlider));
    }
}
