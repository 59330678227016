// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--XvVOw{width:100%;padding:1em 0}.wrapper--XvVOw .headers--qRo9R{width:100%;margin-bottom:1em}.wrapper--XvVOw .headers--qRo9R .headerRight--zT9yK{float:right}.errorWrapper--F1Nxb.ticks--Vuf0I{margin-top:1.5em}", "",{"version":3,"sources":["webpack://./src/gui/Components/SingleSlider/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,UAAU,CAAC,aAAa,CAAC,gCAAkB,UAAU,CAAC,iBAAiB,CAAC,oDAA+B,WAAW,CAAC,kCAAoB,gBAAgB","sourcesContent":[".wrapper{width:100%;padding:1em 0}.wrapper .headers{width:100%;margin-bottom:1em}.wrapper .headers .headerRight{float:right}.errorWrapper.ticks{margin-top:1.5em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--XvVOw",
	"headers": "headers--qRo9R",
	"headerRight": "headerRight--zT9yK",
	"errorWrapper": "errorWrapper--F1Nxb",
	"ticks": "ticks--Vuf0I"
};
export default ___CSS_LOADER_EXPORT___;
