// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plan--AH8fg{width:4rem}.st0--ej9NB{fill:#169bed}.st1--_htiw{fill:#fff}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/Plan.com/index.module.scss"],"names":[],"mappings":"AAAA,aAAM,UAAU,CAAC,YAAK,YAAY,CAAC,YAAK,SAAS","sourcesContent":[".plan{width:4rem}.st0{fill:#169bed}.st1{fill:#fff}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plan": "plan--AH8fg",
	"st0": "st0--ej9NB",
	"st1": "st1--_htiw"
};
export default ___CSS_LOADER_EXPORT___;
