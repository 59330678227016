import SVG from "@Components/Icons/SVG";
export default class Eye extends SVG {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            edit: false,
            primary: false,
            dark: false,
            light: false,
            create: false,
            delete: false,
            noFill: true,
        };
    }
    componentDidUpdate(previousProps) {
        const { primary } = this.props;
        if (previousProps.primary !== primary) {
            this.setState({ noFill: true });
        }
        this.strokeDetails = {
            stroke: this.props.primary ? "#0EABDB" : "#58585A",
            strokeWidth: "8",
            strokeLinecap: "round",
            strokeLinejoin: "round",
        };
    }
    path = [
        "M 93.4325 42.5695 C 95.6576 45.5145 95.6576 49.4902 93.4325 52.4305",
        "C 86.4237 61.6882 69.6088 80.75 49.9772 80.75",
        "C 30.3453 80.75 13.5301 61.6882 6.5215 52.4305",
        "C 5.439 51.0203 4.8513 49.2856 4.8513 47.5",
        "C 4.8513 45.7144 5.439 43.9797 6.5215 42.5695",
        "C 13.5301 33.3117 30.3453 14.25 49.9772 14.25",
        "C 69.6088 14.25 86.4237 33.3117 93.4325 42.5695 V 42.5695",
        "Z M 49.9767 61.75 C 57.7549 61.75 64.0598 55.3701 64.0598 47.5",
        "C 64.0598 39.6299 57.7549 33.25 49.9767 33.25",
        "C 42.199 33.25 35.8939 39.6299 35.8939 47.5",
        "C 35.8939 55.3701 42.199 61.75 49.9767 61.75 Z",
    ];
    strokeDetails = {
        stroke: "#58585A",
        strokeWidth: "8",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
}
