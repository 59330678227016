export const displayDate = (date, showTime = false) => {
    if (!date) {
        return;
    }
    let template = "DD-MM-YYYY";
    if (showTime) {
        template = template + " HH:mm";
    }
    return date.utc(true).format(template);
};
export const getDeadlines = (publishedAt) => {
    const supplierDeadline = getSupplierDeadline(publishedAt);
    const resultsViewable = getResultsViewable(supplierDeadline);
    const customerDeadline = getCustomerDeadline(resultsViewable);
    return {
        supplierDeadline,
        resultsViewable,
        customerDeadline,
    };
};
export const getSupplierDeadline = (publishedAt) => {
    return publishedAt.addBusinessDays(8).endOf("day");
};
export const getResultsViewable = (supplierDeadline) => {
    return supplierDeadline.addBusinessDays(2).endOf("day");
};
export const getCustomerDeadline = (resultsViewable) => {
    return resultsViewable.addBusinessDays(10);
};
export const utcToLocal = (utcDate) => {
    if (!utcDate || !utcDate.isValid()) {
        return;
    }
    return utcDate.local();
};
export const localToUTC = (localDate) => {
    if (!localDate) {
        return;
    }
    return localDate.utc();
};
