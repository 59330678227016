// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--CgFxC{display:flex;flex-direction:column;width:100%;overflow:hidden}.container--CgFxC .content--fv82c{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container--CgFxC{flex-direction:row}}.container--CgFxC{overflow:visible}.container--CgFxC .content--fv82c{overflow:visible}.container--CgFxC .content--fv82c .page--cJjP7{display:flex;align-items:center;justify-content:safe center;display:flex;flex:auto;flex-direction:column;overflow:auto;background:#fff}.link--NnVlH{color:var(--text-link-color)}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Auth/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,YAAY,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,kCAAoB,YAAY,CAAC,SAAS,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,yBAAyB,kBAAW,kBAAkB,CAAC,CAAC,kBAAW,gBAAgB,CAAC,kCAAoB,gBAAgB,CAAC,+CAA0B,YAAY,CAAC,kBAAkB,CAAC,2BAA2B,CAAC,YAAY,CAAC,SAAS,CAAC,qBAAqB,CAAC,aAAa,CAAC,eAAe,CAAC,aAAM,4BAA4B","sourcesContent":[".container{display:flex;flex-direction:column;width:100%;overflow:hidden}.container .content{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container{flex-direction:row}}.container{overflow:visible}.container .content{overflow:visible}.container .content .page{display:flex;align-items:center;justify-content:safe center;display:flex;flex:auto;flex-direction:column;overflow:auto;background:#fff}.link{color:var(--text-link-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--CgFxC",
	"content": "content--fv82c",
	"page": "page--cJjP7",
	"link": "link--NnVlH"
};
export default ___CSS_LOADER_EXPORT___;
