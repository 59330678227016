// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--YMpoF{width:3rem}.ee--VEAsl{fill:#1d9f9e;stroke:none;fill-opacity:1}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/EE/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,UAAU,CAAC,WAAI,YAAY,CAAC,WAAW,CAAC,cAAc","sourcesContent":[".wrapper{width:3rem}.ee{fill:#1d9f9e;stroke:none;fill-opacity:1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--YMpoF",
	"ee": "ee--VEAsl"
};
export default ___CSS_LOADER_EXPORT___;
