// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--uoYhm{text-align:left}.tableValue--aKD0K{color:var(--title-main-color)}.tableValue--aKD0K.cost--P_JiD{text-align:right}.tableValue--aKD0K.total--JyrQJ{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderResponses/PagePopup/Summary/Accordion/TariffTable/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,eAAe,CAAC,mBAAY,6BAA6B,CAAC,+BAAiB,gBAAgB,CAAC,gCAAkB,gBAAgB","sourcesContent":[".title{text-align:left}.tableValue{color:var(--title-main-color)}.tableValue.cost{text-align:right}.tableValue.total{font-weight:bold}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--uoYhm",
	"tableValue": "tableValue--aKD0K",
	"cost": "cost--P_JiD",
	"total": "total--JyrQJ"
};
export default ___CSS_LOADER_EXPORT___;
