export const roundToFormat = (amount, points = 2) => {
    return new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: points,
        maximumFractionDigits: points,
    }).format(Number(amount));
};
export const round = (amount, points = 2) => {
    const pow = Math.pow(10, points);
    return Number(`${(Math.round(amount * pow) / pow).toFixed(points > 0 ? points : 0)}`);
};
export const roundUp = (amount, points = 2) => {
    const pow = Math.pow(10, points);
    return Number(`${(Math.ceil(amount * pow) / pow).toFixed(points > 0 ? points : 0)}`);
};
export const roundDown = (amount, points = 2) => {
    const pow = Math.pow(10, points);
    return Number(`${(Math.floor(amount * pow) / pow).toFixed(points > 0 ? points : 0)}`);
};
export const ceil = (amount, points = 2) => {
    const pow = Math.pow(10, points);
    return `${(Math.ceil(amount * pow) / pow).toFixed(points > 0 ? points : 0)}`;
};
export const floor = (amount, points = 2) => {
    const pow = Math.pow(10, points);
    return `${(Math.floor(amount * pow) / pow).toFixed(points > 0 ? points : 0)}`;
};
export const roundTo = (amount, nearest = 1) => {
    return Number(`${(Math.round(amount / nearest) * nearest).toFixed(0)}`);
};
export const ceilTo = (amount, nearest = 1) => {
    return Number(`${(Math.ceil(amount / nearest) * nearest).toFixed(0)}`);
};
export const floorTo = (amount, nearest = 1) => {
    return Number(`${(Math.floor(amount / nearest) * nearest).toFixed(0)}`);
};
