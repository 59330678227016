import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, getDetails, getForAdmin, post, put, } from "@Api/Customers/usage";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import AdminUser from "@Shared/Entities/Admin/User";
import CustomerUser from "@Shared/Entities/Customer/User";
import Factory from "@Shared/Factory";
export const DELETE_USAGE = "deleteUsage";
export const deleteUsage = (dispatch, usage) => del(usage)
    .then((id) => {
    dispatch({
        type: DELETE_USAGE,
        data: id,
    });
})
    .then(notify200(dispatch, API_TYPE.DELETE, "Usage Deleted", "The usage(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_USAGE = "updateUsage";
const add = (dispatch) => (data) => {
    dispatch({
        type: ADD_USAGE,
        data,
    });
    return data;
};
export const createUsage = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Usage Created", "The usage was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateUsage = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Usage Updated", "The usage was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchUsage = (dispatch, customerId, usageId, inclDel) => find(customerId, usageId, inclDel).then(add(dispatch));
export const fetchUsages = (dispatch, customerId) => get(add(dispatch), customerId);
export const fetchUsagesForAdmin = (dispatch) => getForAdmin(add(dispatch));
export const fetchUsagesDetails = (dispatch, customerId) => getDetails(add(dispatch), customerId);
export const watchUsage = (dispatch, usage, user) => {
    let emailData;
    switch (true) {
        case user instanceof AdminUser:
            emailData = {
                id: user.id,
                type: UserType.Admin,
            };
            break;
        case user instanceof CustomerUser:
            emailData = {
                id: user.id,
                type: UserType.Admin,
                customerId: user.customerId,
            };
            break;
    }
    const data = Factory.Usage({
        ...usage.data,
        mailingList: [...usage.mailingList, emailData],
    });
    return put(data).then(add(dispatch));
};
export const unwatchUsage = (dispatch, usage, user) => {
    const data = Factory.Usage({
        ...usage.data,
        mailingList: usage.mailingList.filter(({ id }) => id !== user.id),
    });
    return put(data).then(add(dispatch));
};
