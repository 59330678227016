import React from "react";
import Select from "react-select";
import { isArray } from "validate.js";
import styles from "./index.module.scss";
const cssSelectedColor = "var(--alt-highlighted-text-color)";
const cssErrorColor = "#c54039";
const cssTagBackgroundColor = "#dae3e7";
const textColor = "#58585a";
export default class Dropdown extends React.Component {
    static defaultProps = {
        title: "",
    };
    constructor(props) {
        super(props);
        this.state = { options: this.getOptions() };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.options !== this.props.options) {
            this.setState({ options: this.getOptions() });
        }
    }
    handleErrors = () => {
        if (this.props.error && this.props.setErrors) {
            this.props.setErrors({});
        }
    };
    getOptions = () => {
        const { filterOptions = () => true } = this.props;
        return (this.props.options ||
            Object.keys(this.props.enum)
                .filter((k) => typeof this.props.enum[k] === "number")
                .map((k) => ({
                value: this.props.enum[k],
                label: k.replaceAll("_", ` `),
            }))
                .filter((element) => filterOptions(element)));
    };
    onChange = (option) => {
        this.props.onChange({
            field: this.props.name,
            option,
        });
    };
    findValue = (options) => {
        if (this.props.value === undefined) {
            return null;
        }
        if (this.props.isMulti) {
            return options.filter(({ value }) => isArray(this.props.value) &&
                this.props.value.includes(value));
        }
        return options.find(({ value }) => value === this.props.value) || null;
    };
    render() {
        const css = {
            container: (provided) => {
                return {
                    ...provided,
                    width: "100%",
                    borderRadius: "3px",
                    maxHeight: "auto",
                };
            },
            valueContainer: (provided) => {
                return {
                    ...provided,
                    maxHeight: "auto",
                    minHeight: "1vw",
                    padding: "2px",
                    position: "unset",
                };
            },
            indicatorsContainer: (provided) => {
                return {
                    ...provided,
                    maxHeight: "1.5vw",
                    minHeight: "1vw",
                };
            },
            singleValue: (provided) => {
                return {
                    ...provided,
                    top: "50%",
                    width: "100%",
                };
            },
            multiValue: (provided) => {
                return {
                    ...provided,
                    marginRight: "4px",
                    backgroundColor: cssTagBackgroundColor,
                    marginTop: "-0.3vw",
                    maxHeight: "1.5vw",
                    alignItems: "center",
                };
            },
            placeholder: (provided) => {
                return {
                    ...provided,
                    color: this.props.error ? cssErrorColor : provided.color,
                    top: "50%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                };
            },
            indicatorSeparator: (provided) => {
                return {
                    ...provided,
                    maxHeight: "2vw",
                    minHeight: "1vw",
                    display: "none",
                };
            },
            dropdownIndicator: (provided) => {
                return {
                    ...provided,
                    color: cssSelectedColor,
                    maxHeight: "2vw",
                    minHeight: "1vw",
                };
            },
            option: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isSelected
                        ? cssSelectedColor
                        : provided.backgroundColor,
                };
            },
            control: (provided) => {
                return {
                    ...provided,
                    maxHeight: "2vw",
                    minHeight: "1vw",
                    border: "none",
                    borderWidth: "1px",
                    boxShadow: "none",
                    "&:hover": {
                        border: "none",
                    },
                    borderRadius: this.props.title.length > 0 ? "0 2px 2px 0" : "4px",
                    padding: 0,
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    position: this.props.relative ? "relative" : "absolute",
                    zIndex: 999,
                    color: textColor,
                };
            },
        };
        const { options } = this.state;
        const value = this.findValue(options);
        return (React.createElement("div", { className: styles.wrapper },
            this.props.title.length > 0 ? (React.createElement("div", { className: styles.title }, this.props.title)) : (React.createElement(React.Fragment, null)),
            React.createElement(Select, { ...{
                    ...this.props,
                    styles: css,
                    options,
                    value,
                    onChange: this.onChange,
                    isDisabled: this.props.disabled,
                }, isClearable: false, onFocus: this.handleErrors })));
    }
}
