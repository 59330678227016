export default ({ daysTraveller = 0, voiceLines = 1, overallData = 0, iddUkMinsToRow = 0, iddUkMinsToApac = 0, iddUkMinsToEurope = 0, iddUkMinsToUsaCanada = 0, pictureMessages = 0, ukToInternationalTexts = 0, textsWhileRoaming = 0, }) => {
    let dataCostPerGb = 3, leaderCost = 0, sharerCost = 0;
    const ukVoiceAndSmsCost = 6, travellerCost = 0, iddUkMinsToEuropeAndUsaCanadaBundleCost = 25, iddUkMinsToEuropeAndUsaCanadaMin = 400, iddUkMinsToEuropeCost = 0.21, iddUkMinsToUsaCanadaCost = 0.1, iddUkMinsToRowCost = 0.65, iddUkMinsToApacCost = 0.3, pictureMessagesCost = 0.4255, ukToInternationalTextsCosts = 0.1, textsWhileRoamingCosts = 0.3;
    if (voiceLines > 150) {
        dataCostPerGb = 3;
        sharerCost = 3;
        leaderCost = 3;
    }
    const leader = leaderCost + ukVoiceAndSmsCost;
    const shares = (sharerCost + ukVoiceAndSmsCost) * (voiceLines - 1);
    const data = overallData * dataCostPerGb;
    const tariff = leader + shares + data;
    const traveller = daysTraveller * travellerCost;
    const iddRow = Math.ceil(iddUkMinsToRow) * iddUkMinsToRowCost;
    const iddApac = Math.ceil(iddUkMinsToApac) * iddUkMinsToApacCost;
    const iddEurope = Math.ceil(iddUkMinsToEurope) * iddUkMinsToEuropeCost;
    const iddUsaCanada = Math.ceil(iddUkMinsToUsaCanada) * iddUkMinsToUsaCanadaCost;
    const iddEuropeUsaCanada = Math.min(iddEurope + iddUsaCanada, iddUkMinsToEuropeAndUsaCanadaBundleCost *
        Math.ceil((iddUkMinsToEurope + iddUkMinsToUsaCanada) /
            iddUkMinsToEuropeAndUsaCanadaMin));
    const mms = Math.ceil(pictureMessages) * pictureMessagesCost;
    const internationalTexts = Math.ceil(ukToInternationalTexts) * ukToInternationalTextsCosts;
    const textsRoaming = Math.ceil(textsWhileRoaming) * textsWhileRoamingCosts;
    return [
        tariff,
        traveller,
        iddRow,
        iddApac,
        iddEuropeUsaCanada,
        mms,
        internationalTexts,
        textsRoaming,
    ].reduce((t, v) => t + v, 0);
};
