import React from "react";
import Button from "@Components/Button";
import NumberedTick from "@Components/Icons/NumberedTick";
import { Sections } from "@Shared/Entities/Customer/Tender/enums";
import { usagePercentageTarget } from "@SmartComponents/TenderEdit";
import styles from "./index.module.scss";
export default class SubHeader extends React.Component {
    getSectionName = (section) => {
        switch (section) {
            case Sections.LineRequirements:
                return "Line Requirements";
            case Sections.ContractEndDates:
                return "Contract End Dates";
            case Sections.CurrentCostsOrDiscountsAndBenefits:
                if (this.props.hasUsage) {
                    return "Discounts & Benefits";
                }
                return "Current Costs";
            case Sections.DataInternationalCallsAndRoaming:
                return "Data, IDD & Roaming";
            case Sections.NetworkProviderOptions:
                return "Network Provider Options";
            case Sections.HandsetsAndDevicesOptions:
                return "Handsets & Devices";
            case Sections.MDM:
                return "MDM";
            case Sections.AdditionalInfo:
                return "Additional Info";
            default:
                return "";
        }
    };
    displayPublishButton = (allComplete) => {
        const { hasUsage = false, published = false, usagePercentage = 0, } = this.props;
        if (hasUsage && usagePercentage < usagePercentageTarget) {
            return (React.createElement(Button, { click: this.props.save, key: `button-${allComplete}`, cta: true }, "PUBLISH"));
        }
        if (!published) {
            return (React.createElement(Button, { click: this.props.publish, key: `button-${allComplete}`, cta: true }, "PUBLISH"));
        }
        return (React.createElement(Button, { click: this.props.publish, key: `button-${allComplete}`, cta: true }, "UPDATE"));
    };
    displayStars = () => {
        let allComplete = true;
        let number = 0;
        const starArray = Object.keys(Sections)
            .filter((section) => !Number.isNaN(Number(section)))
            .filter((section) => section !== Sections.SubmitTender.toString())
            .map((section) => {
            const sectionValue = Number(section);
            const complete = this.props.completedSections[sectionValue];
            if (!complete) {
                allComplete = false;
            }
            number++;
            return (React.createElement("div", { className: styles.star, key: `${sectionValue}-${complete}`, onClick: () => this.props.goToSection(sectionValue) },
                React.createElement(NumberedTick, { outlined: !complete, number: number }),
                React.createElement("div", { className: styles.tooltip }, this.getSectionName(sectionValue))));
        });
        starArray.push(this.displayPublishButton(allComplete));
        return starArray;
    };
    render() {
        return React.createElement("div", { className: styles.wrapper }, this.displayStars());
    }
}
