import Factory from "@Shared/Factory";
import { ADD_CUSTOMER, DELETE_CUSTOMER, } from "@Store/Actions/Customers/companies";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_CUSTOMER:
            return state.filter((object) => object.id !== data);
        case ADD_CUSTOMER:
            const customer = Factory.Customer(data);
            return [...state.filter(({ id }) => id !== customer.id), customer];
    }
    return state;
};
