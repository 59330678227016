import { getForPartner } from "@Api/Customers/tenders";
import { invite } from "@Api/Partners/tenders";
import { ADD_TENDER } from "@Store/Actions/Customers/tenders";
const add = (dispatch) => (data) => {
    dispatch({
        type: ADD_TENDER,
        data,
    });
    return data;
};
export const fetchTendersForPartners = (dispatch, partnerId) => getForPartner(add(dispatch), partnerId);
export const inviteSupplierForPartner = (dispatch, partnerId, customerId, tenderId, email) => invite(partnerId, customerId, tenderId, email).then(add(dispatch));
