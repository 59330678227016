import React from "react";
import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
import ERA from "../../Partners/ERA/LogoWhite";
import sevenStepSolutions from "../../Partners/SevenStepSolutions/LogoWhite/logo.png";
import tm from "../../Partners/TechMahindra/LogoWhite/logo.png";
import styles from "./index.module.scss";
import icon from "./logo.svg";
export default class LogoWhite extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        const broker = getBrokerDomain();
        switch (broker) {
            case BrokerDomain.ERA:
                return React.createElement(ERA, { ...this.props });
            case BrokerDomain.TechMahindra:
                return (React.createElement("img", { src: tm, alt: "Tech-Mahindra", className: `${styles.logo} ${this.props.className}` }));
            case BrokerDomain.SevenStepSolutions:
                return (React.createElement("img", { src: sevenStepSolutions, alt: "7 Step Solutions", className: `${styles.logo} ${this.props.className}` }));
            default:
                return (React.createElement("img", { src: icon, alt: "Predict Mobile", className: `${styles.logo} ${this.props.className}` }));
        }
    }
}
