import { AllowanceTypes, ChargeMethod, ComponentTypes, Effected, QTYType, TariffType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { average, weightedAverage } from "@Shared/Helpers/Array";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import ConvertUnitsForDisplay from "@Shared/Helpers/CalculateUsageCovered/ConvertUnitsForDisplay";
import GetTotalLeasingCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalLeasingCost";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
export default class Meta {
    _coverage;
    _proposal;
    _tender;
    _devices;
    _leasing;
    _vat;
    constructor(props) {
        this._proposal = props.proposal;
        this._tender = props.tender;
        this._devices = props.devices || [];
        const { vat = false, leasing = false } = props.flags || {};
        this._vat = vat ? 1.2 : 1;
        this._leasing = leasing;
    }
    get hardwareAdjustmentRatio() {
        if (!this._proposal.devicePrices ||
            this._proposal.devicePrices.length === 0) {
            return 0.95;
        }
        const hardwareAdjustmentRatio = [];
        for (const dp of this._proposal.devicePrices) {
            if (!dp.deviceId) {
                continue;
            }
            const device = this._devices.find(({ id }) => id === dp.deviceId);
            if (!device) {
                console.log(`Can't find ${dp.deviceId}`);
                continue;
            }
            const ratio = Math.min(device.price / dp.price, 1);
            hardwareAdjustmentRatio.push(ratio);
        }
        if (hardwareAdjustmentRatio.length === 0) {
            return 0.95;
        }
        return average(hardwareAdjustmentRatio);
    }
    get dataAllowance() {
        const data = this._proposal?.tariffComponents || [];
        return data.reduce((total, component) => {
            return component[Effected.TariffComponentItems].reduce((t, allowance) => {
                if (allowance[Effected.TariffComponentAllowanceType] !==
                    AllowanceTypes["UK Data (GB)"] &&
                    allowance[Effected.TariffComponentAllowanceType] !==
                        AllowanceTypes["EU Data (GB)"]) {
                    return t;
                }
                const allowanceTotal = (allowance[Effected.TariffComponentAllowanceQtyType] ===
                    QTYType.Unlimited
                    ? 1000
                    : allowance[Effected.TariffComponentAllowanceQty]) *
                    (allowance[Effected.TariffComponentAllowanceQtyType] ===
                        QTYType.Shared
                        ? 1
                        : component[Effected.TariffComponentNumberLines]);
                return t + allowanceTotal;
            }, total);
        }, 0);
    }
    get coverage() {
        if (!this._coverage) {
            this._coverage = CalculateUsageCovered(this._tender, this._proposal);
        }
        return this._coverage;
    }
    get atc() {
        return this._proposal.get(Effected.Discount) || 0;
    }
    get monthlyCost() {
        return this.oobCost + this.tariffCost - this.atc;
    }
    get perLinePerMonth() {
        const { totalLines, length } = this._tender.details;
        return ((this.monthlyCost +
            this.terminationChargePerMonth +
            this.monthlyLeasingCost +
            (this.pacChargeAccount +
                this.pacChargePerLine * totalLines +
                this.upfrontCost -
                this.adjustedHardwareFund) /
                length) /
            totalLines);
    }
    get monthlyLeasingCost() {
        const { setupFee = 0, leasingLumpSum = 0, minimumLeaseTerm = 24, } = this._proposal;
        const { length } = this._tender.details;
        if (!this._leasing || this._proposal.offerLeasing === false) {
            return 0;
        }
        return ((this.leasingCost * minimumLeaseTerm + setupFee + leasingLumpSum) /
            length);
    }
    get totalCost() {
        return this.monthlyCost * (this._tender?.details?.length || 24);
    }
    get totalNetCostOfOwnership() {
        const { totalLines, length = 24 } = this._tender.details;
        return this.perLinePerMonth * length * totalLines;
    }
    get tariffComponents() {
        const data = this._proposal?.tariffComponents || [];
        return data.map((comp) => {
            let net = comp[Effected.TariffComponentNetCost];
            let total = comp[Effected.TariffComponentTotalCost];
            if (comp.type === ComponentTypes.Network_ATC) {
                net = -net;
                total = -total;
            }
            const netWithMargin = net * (1 + this._tender.margin);
            const totalWithMargin = total * (1 + this._tender.margin);
            return { ...comp, netWithMargin, totalWithMargin };
        });
    }
    get tariffCost() {
        const data = this.tariffComponents;
        return (data.reduce((total, { totalWithMargin: cost }) => {
            if (Number.isNaN(cost)) {
                return total;
            }
            return total + cost;
        }, 0) * this._vat);
    }
    get oobCost() {
        const covered = this.coverage;
        let totalCost = 0;
        for (const allowanceType in covered) {
            totalCost += covered[allowanceType].costOfBundles;
            totalCost +=
                ConvertUnitsForDisplay(covered[allowanceType].remaining, allowanceType) * (this._proposal?.rackRate?.[allowanceType] || 0);
        }
        return totalCost * (1 + this._tender.margin) * this._vat;
    }
    get mdmCost() {
        return this._proposal[Effected.OfferMDM] === false ||
            this._proposal[Effected.IsCloudFree]
            ? 0
            : this._proposal[Effected.MDMLineCost] * this._vat;
    }
    get dmCost() {
        return this._proposal[Effected.OfferDM] === false ||
            this._proposal[Effected.DMFree]
            ? 0
            : this._proposal[Effected.DMLineCost] * this._vat;
    }
    get leasingCost() {
        if (!this._leasing || this._proposal.offerLeasing === false) {
            return 0;
        }
        const totalLeasingCost = GetTotalLeasingCost(this._proposal.leasingData);
        return totalLeasingCost * this._vat;
    }
    get monthsToReachMinSpend() {
        return this._proposal[Effected.HasMinimumSpend]
            ? this._proposal[Effected.MinimumSpend] / this.tariffCost
            : 0;
    }
    get costOfSims() {
        return this._proposal.deductSims === false ||
            !this._proposal.deductedPerSim
            ? 0
            : Number(this._proposal.deductedPerSim) * this._vat;
    }
    get pacChargePerLine() {
        if (this._proposal.PACCodeCharge === true &&
            this._proposal.chargeMethod === ChargeMethod.Per_Device) {
            return this._proposal.PACCost * this._vat;
        }
        return 0;
    }
    get pacChargeAccount() {
        if (this._proposal.PACCodeCharge === true) {
            if (this._proposal.chargeMethod === ChargeMethod.Per_Account) {
                return Number(this._proposal.PACCost) * this._vat;
            }
            return (Number(this.pacChargePerLine) *
                this._tender.details.totalLines *
                this._vat);
        }
        return 0;
    }
    get hardwareFundPerNewLine() {
        return (this._proposal.hwFundPerDataSim +
            this._proposal.hwFundPerLine -
            this.costOfSims);
    }
    get groupedTariffSummary() {
        let tariffSummaries = groupedTariff(this.tariffComponents);
        tariffSummaries = tariffSummaries.map((gts) => {
            const { [gts.id]: lineData = {} } = this._proposal.additionalLines || {};
            const additionalLineCost = lineData.additionalLineCost || gts.costOfLine;
            gts = { ...gts, ...lineData, additionalLineCost };
            const { [gts.id]: data = {} } = this._proposal.additionalData || {};
            gts = { ...gts, ...data };
            let { additionalDataMinQty = 1, additionalDataCost = 0, additionalDataNoNewLine = false, additionalDataAuto = false, dataAllowance, } = gts;
            let costPerGB = additionalDataNoNewLine
                ? additionalLineCost / Number(dataAllowance)
                : additionalDataCost > 0
                    ? Number(additionalDataCost) / Number(additionalDataMinQty)
                    : gts.costPerGB || 0;
            additionalDataCost = additionalDataNoNewLine
                ? additionalLineCost
                : additionalDataAuto
                    ? additionalDataCost * (gts.lines + (gts.dataLines || 0))
                    : additionalDataCost;
            if (dataAllowance === "unlimited") {
                costPerGB = 0;
                additionalDataCost = 0;
            }
            return { ...gts, costPerGB, additionalDataCost };
        });
        return tariffSummaries;
    }
    get costPerGB() {
        const pooled = [];
        for (const comp of this.groupedTariffSummary) {
            let { costPerGB, additionalLineCost, costOfLine, dataAllowance, lines, } = comp;
            if (dataAllowance === "unlimited") {
                pooled.push([0, lines]);
                continue;
            }
            if (costPerGB) {
                pooled.push([costPerGB, lines]);
                continue;
            }
            costPerGB =
                (additionalLineCost || costOfLine) / dataAllowance;
            pooled.push([costPerGB, lines]);
        }
        if (pooled.length > 0) {
            return Math.max(0, weightedAverage(pooled));
        }
        return 0;
    }
    get hardwareCost() {
        if (this._tender.details.handsets === false) {
            return 0;
        }
        if (!this._leasing) {
            return 0;
        }
        if (this.leasingCost > 0) {
            return 0;
        }
        const { pickedHandsets = [] } = this._tender.details;
        const specificDevices = pickedHandsets.filter(({ pickedHandsets }) => typeof pickedHandsets === "string");
        const devicePrices = this._proposal.devicePrices || [];
        let localHardware = 0;
        for (const { pickedHandsets, num } of specificDevices) {
            const device = devicePrices.find(({ deviceID }) => deviceID === pickedHandsets);
            if (!device?.price) {
                continue;
            }
            localHardware += device.price * num;
        }
        if (localHardware > 0) {
            return localHardware;
        }
        if (this.hardwareAdjustmentRatio === 0) {
            return 0;
        }
        const allVoiceLines = this._tender.details.voiceOnlyLines +
            this._tender.details.voiceLines;
        const hardwareCosts = allVoiceLines * 523;
        return hardwareCosts / this.hardwareAdjustmentRatio;
    }
    get adjustedHardwareFund() {
        return this.adjustedHardwareCredit + this.hardwareFundCash;
    }
    get adjustedHardwareCredit() {
        const credit = this.hardwareFund - this.hardwareFundCash;
        return credit > 0 ? credit * this.hardwareAdjustmentRatio : 0;
    }
    get upfrontCost() {
        return (this.hardwareCost +
            this.costOfSims * this._tender.details.totalLines);
    }
    get savings() {
        return this.monthlySavings * this._tender.details.length;
    }
    get monthlySavings() {
        return this.unusedSaving + this.usedSaving;
    }
    get unusedSaving() {
        const unused = Math.max(0, this._tender.stats.totalLines - this._tender.details.totalLines);
        return unused * this._tender.currentCostPerLine;
    }
    get usedSaving() {
        return this.savingPerLine * this._tender.details.totalLines;
    }
    get savingPerLine() {
        return this._tender.currentCostPerLine - this.perLinePerMonth;
    }
    get terminationChargePerLine() {
        return this._proposal.terminationPenalty === false ||
            !this._proposal.penaltyPerLine
            ? 0
            : Number(this._proposal.penaltyPerLine || 0) * this._vat;
    }
    get terminationChargePerMonth() {
        return ((this.terminationChargePerLine * this._tender.details.totalLines) /
            this._tender.details.length);
    }
    get lineCost() {
        const components = this._proposal?.tariffComponents || [];
        const lineCost = weightedAverage([
            ...components
                .filter((component) => component[Effected.TariffComponentType] ===
                ComponentTypes["Unlimited Voice & Text"] ||
                component[Effected.TariffComponentType] ===
                    ComponentTypes["Sim Only Voice & Data"])
                .map((component) => [
                component[Effected.TariffComponentNetCost],
                component[Effected.TariffComponentNumberLines],
            ]),
        ]);
        const sharerCost = weightedAverage([
            ...components
                .filter((component) => component[Effected.TariffComponentType] ===
                ComponentTypes.Data_Sharers)
                .map((component) => [
                component[Effected.TariffComponentNetCost],
                component[Effected.TariffComponentNumberLines],
            ]),
        ]);
        return ((lineCost + sharerCost) * this._vat +
            (this.costOfSims +
                this.terminationChargePerLine +
                this.pacChargePerLine) /
                this._tender.details.length);
    }
    get futureLineCost() {
        return (average(this.groupedTariffSummary.map((gts) => gts.additionalLineCost)) -
            this.hardwareFundPerNewLine / this._tender.details.length);
    }
    get hardwareFund() {
        return this._proposal.totalFund || 0;
    }
    get hardwareFundCash() {
        return this._proposal.cashHW === true
            ? this._proposal.hwFundTaken || 0
            : 0;
    }
    get margin() {
        const monthlyCommission = (this.tariffCost / this._vat) * 0.6;
        const monthlyHW = this.hardwareFund / this._tender.details.length;
        const monthlyNet = monthlyCommission - monthlyHW;
        return monthlyNet / this._tender.details.totalLines;
    }
    get primaryPlatform() {
        const platformCounts = {};
        let highestPlatform = {
            platform: undefined,
            lines: 0,
        };
        for (const { platform, lines } of this.groupedTariffSummary) {
            platformCounts[platform] = (platformCounts[platform] || 0) + lines;
            if (platformCounts[platform] > highestPlatform.lines) {
                highestPlatform = {
                    platform,
                    lines: platformCounts[platform],
                };
            }
        }
        return highestPlatform.platform;
    }
    get tariffType() {
        const typeCounts = {};
        let highestTariffType = {
            type: undefined,
            lines: 0,
        };
        for (const { type, lines } of this.groupedTariffSummary) {
            typeCounts[type] = (typeCounts[type] || 0) + lines;
            if (typeCounts[type] > highestTariffType.lines) {
                highestTariffType = {
                    type,
                    lines: typeCounts[type],
                };
            }
        }
        if (highestTariffType.type === "Individual") {
            return TariffType.Individual;
        }
        return TariffType.Shared;
    }
    toJSON() {
        const { adjustedHardwareCredit, adjustedHardwareFund, atc, costOfSims, costPerGB, coverage, dataAllowance, hardwareCost, hardwareFundCash, hardwareFundPerNewLine, leasingCost, lineCost, margin, mdmCost, dmCost, monthlyCost, monthlyLeasingCost, monthlySavings, monthsToReachMinSpend, oobCost, pacChargeAccount, pacChargePerLine, perLinePerMonth, savingPerLine, savings, tariffCost, terminationChargePerLine, terminationChargePerMonth, totalCost, totalLines, totalNetCostOfOwnership, unusedSaving, upfrontCost, usedSaving, groupedTariffSummary, tariffComponents, primaryPlatform, tariffType, } = this;
        return {
            adjustedHardwareCredit,
            adjustedHardwareFund,
            atc,
            costOfSims,
            costPerGB,
            coverage,
            dataAllowance,
            hardwareCost,
            hardwareFundCash,
            hardwareFundPerNewLine,
            leasingCost,
            lineCost,
            margin,
            mdmCost,
            dmCost,
            monthlyCost,
            monthlyLeasingCost,
            monthlySavings,
            monthsToReachMinSpend,
            oobCost,
            pacChargeAccount,
            pacChargePerLine,
            perLinePerMonth,
            savingPerLine,
            savings,
            tariffCost,
            terminationChargePerLine,
            terminationChargePerMonth,
            totalCost,
            totalLines,
            totalNetCostOfOwnership,
            unusedSaving,
            upfrontCost,
            usedSaving,
            groupedTariffSummary,
            tariffComponents,
            dp: this._proposal.devicePrices,
            primaryPlatform,
            tariffType,
        };
    }
    get totalLines() {
        //add up components to total lines
        let total = 0;
        for (const group of this.groupedTariffSummary) {
            total += group.lines;
        }
        return total;
    }
}
