import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import { COMPARISON, NOT_FOUND } from "@Shared/Paths/Comparison";
import Comparison from "./Comparison";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: COMPARISON, component: ({ match }) => (React.createElement(Comparison, { ...match.params })) }),
        React.createElement(Route, { path: NOT_FOUND },
            React.createElement(NotFoundFallback, null))));
};
