import React from "react";
import Logo from "@Components/Icons/Logos/Logo";
import LogoWhite from "@Components/Icons/Logos/LogoWhite";
import styles from "./index.module.scss";
export default class Overall extends React.Component {
    render() {
        if (this.props.light) {
            return React.createElement(LogoWhite, { className: styles.overall });
        }
        return React.createElement(Logo, { className: styles.overall });
    }
}
