import { ADD_NOTIFICATION, deleteNotification, } from "../../Actions/Notifications";
export default (storeAPI) => (next) => (action) => {
    const state = next(action); // Store is updated
    const { type, data, ttl = 7000 } = action;
    if (type === ADD_NOTIFICATION && ttl > 0) {
        setTimeout(() => {
            deleteNotification(storeAPI.dispatch, data);
        }, ttl);
    }
    return state;
};
