import React, { useEffect, useState } from "react";
import ButtonLink from "@Components/ButtonLink";
import Card from "@Components/Card";
import buttons from "@Components/Sass/buttons.module.scss";
import GetPath from "@Helpers/GetPath";
import { ROOT } from "@Shared/Paths/Admin";
import FormGroup from "../../../Form/Group";
import LoadingFallback from "../Loading";
import styles from "./../../index.module.scss";
import logo from "./image.png";
export function NotFoundFallback() {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 2000);
        return () => {
            setShow(false);
        };
    }, []);
    if (!show) {
        return React.createElement(LoadingFallback, null);
    }
    return (React.createElement("div", { className: styles.page },
        React.createElement(Card, null,
            React.createElement("div", { className: styles.header },
                React.createElement("img", { src: logo, alt: "logo" }),
                React.createElement("span", { className: styles.headerText }, "404")),
            React.createElement(FormGroup, null,
                React.createElement("h1", { className: styles.h1 },
                    "It looks like the page you were trying to access does not exist! ",
                    React.createElement("br", null),
                    "Use the button below to navigate to the dashboard:")),
            React.createElement(FormGroup, { className: buttons.buttonGroupCenter },
                React.createElement(ButtonLink, { className: buttons.buttons, to: GetPath(ROOT) }, "GO HOME")))));
}
export default NotFoundFallback;
