import React from "react";
import { connect } from "react-redux";
import Footer from "@Components/Footer";
import IdleTimeout from "@Components/IdleTimeout";
import PageWrapper from "@Components/Pages/PageWrapper";
import { CURRENT_USAGE, CUSTOMER, CUSTOMERS, FEEDBACK, PREVIOUS_USAGE, PROPOSAL, PROPOSAL_EDIT, ROOT, TENDER, TENDER_RESPONSE, TENDER_RESPONSES, } from "@Shared/Paths/Supplier";
import Header from "./Header";
import styles from "./index.module.scss";
import Nav from "./Nav";
import Notifications from "./Notifications";
import Pages from "./Pages";
class Supplier extends React.Component {
    render() {
        return (React.createElement(IdleTimeout, null,
            React.createElement("div", { className: styles.container },
                React.createElement(Nav, { company: this.props.company }),
                React.createElement("div", { className: styles.content },
                    React.createElement(Header, null),
                    React.createElement(PageWrapper, { paths: [
                            TENDER_RESPONSES,
                            CUSTOMERS,
                            FEEDBACK,
                            TENDER,
                            ROOT,
                            TENDER_RESPONSE,
                            PROPOSAL,
                            PROPOSAL_EDIT,
                            CUSTOMER,
                            PREVIOUS_USAGE,
                            CURRENT_USAGE,
                        ] },
                        React.createElement(Pages, null)),
                    React.createElement(Footer, null)),
                React.createElement(Notifications, null))));
    }
}
const mapStateToProps = ({ auth: { user }, suppliers: { companies }, }) => ({
    company: companies.find((c) => c.id === user.supplierId),
});
export default connect(mapStateToProps)(Supplier);
