export const get = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ low: 150, high: 600 });
        }, 3000);
    });
};
export const put = (range) => {
    // let url = `/suppliers/${device.supplierId}/devices/device.id`
    // return axios.put(url).then(data => Factory.Device(data))
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(range);
        }, 1000);
    });
};
