import React from "react";
import { Route, Switch } from "react-router-dom";
import HeaderComp from "@Components/Header";
import { AWARDED_CONTRACTS, CREATE_CUSTOMER, CREATE_CUSTOMER_USAGE, CREATE_CUSTOMER_USER, CREATE_DEVICE, CREATE_PARTNER, CREATE_PARTNER_CUSTOMER, CREATE_PARTNER_USER, CREATE_SUPPLIER, CREATE_SUPPLIER_USER, CREATE_USER, CUSTOMER, CUSTOMERS, CUSTOMERS_USAGES, CUSTOMERS_USERS, CUSTOMER_TENDER, CUSTOMER_TENDERS, CUSTOMER_TENDER_RESPONSES, CUSTOMER_USAGE, CUSTOMER_USER, DEVICE, DEVICES, EDIT_CUSTOMER_TENDER, EDIT_CUSTOMER_USAGE, EDIT_CUSTOMER_USER, EDIT_DEVICE, EDIT_MY_ACCOUNT, EDIT_PARTNER_USER, EDIT_SUPPLIER_RESPONSE, EDIT_SUPPLIER_USER, EDIT_TENDER, EDIT_USER, MY_ACCOUNT, NOT_FOUND, PARTNER, PARTNERS, PARTNERS_USERS, PARTNER_CUSTOMER, PARTNER_CUSTOMERS, PARTNER_USER, ROOT, SUPPLIER, SUPPLIERS, SUPPLIER_RESPONSE, SUPPLIER_RESPONSES, SUPPLIER_USER, SUPPLIER_USERS, TENDER, TENDERS, UPLOAD_DEVICE_LIST, USER, USERS, } from "@Shared/Paths/Admin";
export default class Header extends React.Component {
    render() {
        return (React.createElement(HeaderComp, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: ROOT, exact: true }, "Dashboard"),
                React.createElement(Route, { path: USERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: USERS, exact: true }, "Admins"),
                        React.createElement(Route, { path: CREATE_USER }, "Create Admin"),
                        React.createElement(Route, { path: USER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: USER, exact: true }, "Admin"),
                                React.createElement(Route, { path: EDIT_USER }, "Edit Admin"))))),
                React.createElement(Route, { path: CUSTOMERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: CUSTOMERS, exact: true }, "Customers"),
                        React.createElement(Route, { path: CREATE_CUSTOMER }, "Create Customer"),
                        React.createElement(Route, { path: CUSTOMER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: CUSTOMER, exact: true }, "Customer Details"),
                                React.createElement(Route, { path: CUSTOMERS_USERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: CUSTOMER_USER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: CUSTOMER_USER, exact: true }, "Customer User"),
                                                React.createElement(Route, { path: EDIT_CUSTOMER_USER }, "Edit Customer User"))),
                                        React.createElement(Route, { path: CREATE_CUSTOMER_USER }, "Create Customer User"))),
                                React.createElement(Route, { path: CUSTOMERS_USAGES },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: CUSTOMERS_USAGES, exact: true }, "Customers Usage"),
                                        React.createElement(Route, { path: CREATE_CUSTOMER_USAGE }, "Create Customer Usage"),
                                        React.createElement(Route, { path: CUSTOMER_USAGE },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: CUSTOMER_USAGE, exact: true }, "Customer Usage"),
                                                React.createElement(Route, { path: EDIT_CUSTOMER_USAGE }, "Edit Customer Usage"))))),
                                React.createElement(Route, { path: CUSTOMER_TENDERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: CUSTOMER_TENDERS, exact: true }, "Customer Tenders"),
                                        React.createElement(Route, { path: CUSTOMER_TENDER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: CUSTOMER_TENDER, exact: true }, "Customer Tender"),
                                                React.createElement(Route, { path: EDIT_CUSTOMER_TENDER }, "Edit Customer Tender"),
                                                React.createElement(Route, { path: CUSTOMER_TENDER_RESPONSES }, "Customer Tender Responses"))))))))),
                React.createElement(Route, { path: SUPPLIERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: SUPPLIERS, exact: true }, "Suppliers"),
                        React.createElement(Route, { path: CREATE_SUPPLIER }, "Create Supplier"),
                        React.createElement(Route, { path: SUPPLIER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: SUPPLIER, exact: true }, "Supplier Details"),
                                React.createElement(Route, { path: SUPPLIER_USERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: SUPPLIER_USERS, exact: true }, "Supplier Users"),
                                        React.createElement(Route, { path: CREATE_SUPPLIER_USER }, "Create Supplier User"),
                                        React.createElement(Route, { path: SUPPLIER_USER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: SUPPLIER_USER, exact: true }, "Supplier User"),
                                                React.createElement(Route, { path: EDIT_SUPPLIER_USER }, "Edit Supplier User"))))),
                                React.createElement(Route, { path: SUPPLIER_RESPONSES },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: SUPPLIER_RESPONSE, exact: true }, "Supplier Response"),
                                        React.createElement(Route, { path: EDIT_SUPPLIER_RESPONSE }, "Edit Supplier Response"))))))),
                React.createElement(Route, { path: PARTNERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: PARTNERS, exact: true }, "Partners"),
                        React.createElement(Route, { path: CREATE_PARTNER }, "Create Partner"),
                        React.createElement(Route, { path: PARTNER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: PARTNER, exact: true }, "Partner"),
                                React.createElement(Route, { path: PARTNERS_USERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: PARTNERS_USERS, exact: true }, "Partner Users"),
                                        React.createElement(Route, { path: CREATE_PARTNER_USER }, "Create Partner User"),
                                        React.createElement(Route, { path: PARTNER_USER },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: PARTNER_USER, exact: true }, "Partner User"),
                                                React.createElement(Route, { path: EDIT_PARTNER_USER }, "Edit Partner User"))))),
                                React.createElement(Route, { path: PARTNER_CUSTOMERS },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: PARTNER_CUSTOMERS, exact: true }, "Partner Customers"),
                                        React.createElement(Route, { path: CREATE_PARTNER_CUSTOMER }, "Create Partner Customer"),
                                        React.createElement(Route, { path: PARTNER_CUSTOMER, exact: true }, "Partner Customer"))))))),
                React.createElement(Route, { path: DEVICES },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: DEVICES, exact: true }, "Devices"),
                        React.createElement(Route, { path: CREATE_DEVICE }, "Create Device"),
                        React.createElement(Route, { path: UPLOAD_DEVICE_LIST }, "Upload Device List"),
                        React.createElement(Route, { path: DEVICE },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: DEVICE, exact: true }, "Device"),
                                React.createElement(Route, { path: EDIT_DEVICE }, "Edit Device"))))),
                React.createElement(Route, { path: TENDERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: TENDERS, exact: true }, "Tenders"),
                        React.createElement(Route, { path: TENDER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: TENDER, exact: true }, "Tender"),
                                React.createElement(Route, { path: EDIT_TENDER }, "Edit Tender"))))),
                React.createElement(Route, { path: AWARDED_CONTRACTS }, "Awarded Contracts"),
                React.createElement(Route, { path: MY_ACCOUNT },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: MY_ACCOUNT, exact: true }, "My Account"),
                        React.createElement(Route, { path: EDIT_MY_ACCOUNT }, "Edit My Account"))),
                React.createElement(Route, { path: NOT_FOUND }))));
    }
}
