import dayjs from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(dayjsBusinessTime);
const holidays = [
    "2023-08-28",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-03-29",
    "2024-04-01",
    "2024-05-06",
    "2024-05-27",
    "2024-08-26",
    "2024-12-25",
    "2024-12-26",
];
// Add holidays to dayjs
dayjs.setHolidays(holidays);
