import React from "react";
import Card from "@Components/Card";
import { DisplayLargeText } from "@Components/DisplayLargeText";
import Close from "@Components/Icons/Close";
import InfoPopup from "@Components/InfoPopup";
import styles from "./index.module.scss";
export default class AdditionalInfo extends React.Component {
    render() {
        const popup = (close) => {
            const items = this.props.details.map(({ field, title }) => {
                return (React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, title),
                    DisplayLargeText(this.props.proposal.get(field))));
            });
            return (React.createElement(Card, { title: "Additional Details", className: styles.popupCard, rightSide: React.createElement(Close, { onClick: close, color: "#58585a", size: "0.75rem", className: styles.close }) },
                React.createElement("div", { className: styles.items }, items)));
        };
        return (React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose }));
    }
}
