import dayjs from "dayjs";
import { fix } from "postcode";
import { SelectTypes } from "@Components/ChatBot";
import { ATCUnits, ChangeNetwork, DEPChoices, DetailsFields, DeviceRange, DevicesLocked, EuCostCountries, ExistingDevices, FiveG, HighCostCountries, LeasingType, LikeHardwareFunded, ManagerPackageTypes, MDMInLine, Months, SimOnlyFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { ValidationException } from "@Shared/Exceptions";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import { getRemainingLines } from "@Shared/Helpers/TenderLines";
import { validatePostcode } from "@Shared/Validate";
import { validateHandsets } from "../../../validation";
import DevicesInput from "../../Devices/DevicesInput";
const positiveResponse = ["Great", "Brilliant", "Excellent", "Perfect"];
const checkPosNeg = (answer) => {
    const positives = ["yes", "yeah", "y", "true"];
    const negatives = ["no", "nope", "n", "false"];
    if (typeof answer === "boolean") {
        return answer;
    }
    const value = answer.toLowerCase().replaceAll(/[^a-z]/g, "");
    if (positives.includes(value)) {
        return true;
    }
    if (negatives.includes(value)) {
        return false;
    }
    throw new ValidationException("Unknown value");
};
const checkLength = (value) => {
    const number_ = extractNumber(value);
    if ([12, 24, 36].includes(number_)) {
        return number_;
    }
    throw new ValidationException("Unknown value");
};
const extractNumber = (text, allowDecimals = false) => {
    const number_ = String(text).replace(allowDecimals ? /[^\d.-]/g : /[^\d-]/g, "");
    const number = Number(number_);
    if (number_ === "" || Number.isNaN(number)) {
        throw new ValidationException("Unknown value");
    }
    if (number < 0) {
        throw new ValidationException("Negative value");
    }
    return number;
};
const extractATC = (value) => {
    const input = String(value);
    const number_ = extractNumber(value, true);
    if (!Number.isNaN(number_)) {
        if (input.includes("%") || input.includes("percent")) {
            return { input: number_, type: ATCUnits.Percentage };
        }
        return { input: number_, type: ATCUnits.Pounds };
    }
    throw new ValidationException("Unknown value");
};
const checkEnum = (enumObject) => (value) => {
    if (typeof value === "number" && enumObject[value] !== undefined) {
        return value;
    }
    throw new ValidationException("Unknown value");
};
const checkDate = (date) => {
    if (date !== undefined && date instanceof dayjs) {
        return date;
    }
    throw new ValidationException("Unknown value");
};
const checkExtraDetails = (value) => {
    const negative = ["no", "nope", "n"];
    const text = value.toLowerCase().replaceAll(/[^a-z]/g, "");
    if (negative.includes(text)) {
        return "";
    }
    return value;
};
const checkPickedHandsets = (handsets, handsetsRequired) => {
    return validateHandsets(handsets, handsetsRequired).length === 0;
};
export const requiredOSSpread = (array = []) => {
    return (array.length > 0 &&
        array.at(-1) !== undefined &&
        (array.at(-1).pickedHandsets === DeviceRange.Mid ||
            array.at(-1).pickedHandsets === DeviceRange.High));
};
const getPosWord = () => {
    return positiveResponse[Math.floor(Math.random() * positiveResponse.length)];
};
export default (tender, devicesOptions, months) => {
    const { details, stats, usageId } = tender;
    const noContract = usageId === undefined;
    const { linesLeftSix, linesLeftThree } = getRemainingLines(tender);
    const lineRequirements = [
        {
            effects: DetailsFields.VoiceLines,
            question: [
                {
                    text: noContract
                        ? "How many voice lines do you want?"
                        : `You currently pay for ${stats.totalLines} voice lines with data, & use ${stats.usedVoiceLines}. How many do you want?`,
                },
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.VoiceLines) === undefined ||
                    details.get(DetailsFields.VoiceLines) < 1);
            },
            validate: extractNumber,
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    return [
                        `${getPosWord()}, we'll set voice lines to ${value}`,
                    ];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.DataLines,
            question: [
                {
                    text: noContract
                        ? "How many data only lines would you like?"
                        : `You currently use ${stats.dataLines} data only lines. How many would you like?`,
                },
            ],
            validate: extractNumber,
            needsAnswering: () => {
                return details.get(DetailsFields.DataLines) === undefined;
            },
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    if (value !== 0) {
                        return [
                            `Ok, we'll add ${value} data only lines to the tender`,
                        ];
                    }
                    return [`No data only lines, got it!`];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.VoiceOnlyLines,
            question: [
                {
                    text: noContract
                        ? "How many voice only lines would you like?"
                        : `You currently use 0 voice only lines. How many would you like?`,
                },
            ],
            validate: extractNumber,
            needsAnswering: () => {
                return details.get(DetailsFields.VoiceOnlyLines) === undefined;
            },
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    if (value !== 0) {
                        return [
                            `Ok, we'll add ${value} voice only lines to the tender`,
                        ];
                    }
                    return [`No voice only lines, got it!`];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
    ];
    const contractDates = [
        {
            effects: DetailsFields.Length,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "12 months",
                        value: 12,
                    },
                    {
                        label: "24 months",
                        value: 24,
                    },
                    {
                        label: "36 months",
                        value: 36,
                    },
                ],
            },
            validate: checkLength,
            needsAnswering: () => {
                return details.get(DetailsFields.Length) === undefined;
            },
            question: [
                {
                    text: "We recommend a contract length of 24 months, but you can choose from 12, 24 or 36 months. What is your preferred mobile contract length?",
                },
            ],
        },
        {
            effects: DetailsFields.VastMajorityLinesRenewal,
            question: [
                {
                    text: `In which month will the vast majority of your current lines be up for renewal?`,
                    tooltip: "This information is not in your billing, if unknown your current supplier or network customer services can supply it.",
                },
            ],
            select: {
                type: SelectTypes.Options,
                options: months,
            },
            needsAnswering: () => {
                return (!noContract &&
                    details.get(DetailsFields.VastMajorityLinesRenewal) ===
                        undefined);
            },
            reply: (text) => {
                try {
                    extractNumber(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.LinesBeginning,
            question: [
                `How many lines will be up for renewal in ${Months[details.get(DetailsFields.VastMajorityLinesRenewal)]}?`,
            ],
            validate: extractNumber,
            needsAnswering: () => {
                return (!noContract &&
                    details.get(DetailsFields.LinesBeginning) === undefined);
            },
            reply: (text) => {
                try {
                    extractNumber(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.LinesWithinThreeMonths,
            question: [
                `How many lines will be up for renewal between ${Months[(details.vastMajorityLinesRenewal + 1) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 2) % 12]}?`,
            ],
            validate: extractNumber,
            needsAnswering: () => {
                return (!noContract &&
                    details.get(DetailsFields.LinesWithinThreeMonths) ===
                        undefined &&
                    linesLeftThree > 0);
            },
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    const linesLeft = linesLeftThree - value;
                    return [
                        `Ok, that means we will have ${linesLeft} lines renewing after the first 3 months of this new contract`,
                    ];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.LinesWithinSixMonths,
            question: [
                `Are your remaining ${linesLeftSix} lines in contract renewing between ${Months[(details.vastMajorityLinesRenewal + 3) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 5) % 12]}?`,
            ],
            validate: checkPosNeg,
            needsAnswering: () => {
                return (!noContract &&
                    details.get(DetailsFields.LinesWithinSixMonths) ===
                        undefined &&
                    linesLeftSix > 0);
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
        },
        {
            effects: DetailsFields.EstimatedLines,
            question: [
                {
                    text: `If you anticipate adding new lines between ${Months[(details.vastMajorityLinesRenewal + 3) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 5) % 12]} how many more voice lines with data will you need?`,
                },
                {
                    text: "This is for indicative purposes only.",
                },
            ],
            needsAnswering: () => {
                return details.get(DetailsFields.EstimatedLines) === undefined;
            },
            validation: extractNumber,
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    if (value === 0) {
                        return [`No extra voice lines, got it!`];
                    }
                    return [
                        `We'll let the suppliers know you will possibly be adding ${value} lines in the first 6 months.`,
                    ];
                }
                catch {
                    return ["Sorry, I don't understand that"];
                }
            },
        },
        {
            effects: DetailsFields.EstimatedDataLines,
            question: [
                {
                    text: "And, how many more data only lines will you need? Again for indicative purposes only.",
                },
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.EstimatedDataLines) === undefined);
            },
            validation: extractNumber,
            reply: (text) => {
                try {
                    const value = extractNumber(text);
                    if (value === 0) {
                        return [`No extra data lines, got it!`];
                    }
                    return [
                        `We'll let the suppliers know you will possibly be adding ${value} data lines in the first 6 months.`,
                    ];
                }
                catch {
                    return ["Sorry, I don't understand that"];
                }
            },
        },
    ];
    const discountsBenefits = [
        {
            effects: DetailsFields.ATC,
            question: [
                {
                    text: "Do you receive a monthly discount from your supplier?",
                },
                {
                    text: "If so, please enter the total monthly amount here",
                },
            ],
            needsAnswering: () => {
                return details.get(DetailsFields.ATC) === undefined;
            },
            validate: extractATC,
            reply: (amount) => {
                try {
                    const { input, type } = extractATC(amount);
                    if (input === 0) {
                        return [
                            "Ok, we won't make any deductions from your monthly cost",
                        ];
                    }
                    else {
                        const realCost = type === ATCUnits.Pounds
                            ? stats.totalCost - input
                            : stats.totalCost -
                                stats.totalCost * (input / 100);
                        return [
                            `We'll deduct ${type === ATCUnits.Pounds ? "£" : ""}${input}${type === ATCUnits.Percentage ? "%" : ""} from your monthly cost`,
                            `That means your real cost is ${format(realCost)}`,
                        ];
                    }
                }
                catch {
                    return ["Sorry, I don't understand that"];
                }
            },
        },
        {
            effects: DetailsFields.TechFund,
            question: [
                "Do you also receive additional benefits such as a hardware or tech fund in addition to the monthly line rental discounts?",
                "Please enter the total amount for the life of your contract",
            ],
            needsAnswering: () => {
                return details.get(DetailsFields.TechFund) === undefined;
            },
            validate: extractNumber,
            reply: (amount) => {
                try {
                    extractNumber(amount, false);
                    return ["Great, we'll adjust your net cost"];
                }
                catch {
                    return ["Sorry, I don't understand that"];
                }
            },
        },
    ];
    const currentCosts = [
        {
            effects: DetailsFields.ManualCurrentCost,
            question: [
                {
                    text: "If you already have a contract, what is your monthly net cost?",
                },
            ],
            needsAnswering: () => {
                return (noContract &&
                    details.get(DetailsFields.ManualCurrentCost) === undefined);
            },
            validate: extractNumber,
            reply: (amount) => {
                try {
                    extractNumber(amount, false);
                    return [];
                }
                catch {
                    return ["Sorry, I don't understand that"];
                }
            },
        },
    ];
    const dataIDDRoaming = [
        {
            effects: DetailsFields.OverallData,
            question: [
                {
                    text: noContract
                        ? "How many GB of overall data would you like?"
                        : `You are currently using ${stats.averageData.toFixed(2)} GB of data. To allow for cost-effective growth, we recommend a minimum of ${stats.recommendedData} GB. How many GB of overall data would you like?`,
                },
            ],
            validate: extractNumber,
            needsAnswering: () => {
                return details.get(DetailsFields.OverallData) === undefined;
            },
            reply: (text) => {
                try {
                    extractNumber(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.IncreaseUsage,
            needsAnswering: () => {
                return details.get(DetailsFields.IncreaseUsage) === undefined;
            },
            question: [
                {
                    text: "Do you anticipate a significant change in data usage during the contract term, such as moving to Office 365 or an acquisition?",
                    tooltip: "This might include actions such as a move to Office365, staff working remotely, an acquisition, etc.",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.Microsoft365,
            needsAnswering: () => {
                return (details.get(DetailsFields.Microsoft365) === undefined &&
                    details.increaseUsage === true);
            },
            question: [
                {
                    text: "Are you moving to Microsoft 365?",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.OnlineTeams,
            needsAnswering: () => {
                return (details.get(DetailsFields.OnlineTeams) === undefined &&
                    details.increaseUsage === true);
            },
            question: [
                {
                    text: "Do you use Teams?",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.Zoom,
            needsAnswering: () => {
                return (details.get(DetailsFields.Zoom) === undefined &&
                    details.increaseUsage === true);
            },
            question: [
                {
                    text: "Do you use Zoom?",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.OtherDataIncrease,
            question: [
                {
                    text: "Any other reason why you anticipate a significant change in data usage?",
                    tooltip: "Most businesses have a few heavy users of data & many more lighter users.  Our suppliers have visibility of this split & will meet these needs accordingly which may include a number of unlimited data sims as appropriate. If you require as a mandatory requirement all lines to be with unlimited data & understand the higher cost implications please detail this in the comments box",
                },
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.IncreaseUsage) === true &&
                    details.get(DetailsFields.OtherDataIncrease) === undefined);
            },
            reply: () => {
                return [];
            },
        },
        {
            effects: DetailsFields.IDDCost,
            needsAnswering: () => {
                return details.get(DetailsFields.IDDCost) === undefined;
            },
            question: [
                {
                    text: "Do you anticipate an increase of calls from the UK to other countries?",
                    tooltip: "These are called international direct dial (IDD) calls",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.IDDCostCountries,
            needsAnswering: () => {
                const c = details.get(DetailsFields.IDDCostCountries);
                return (details.get(DetailsFields.IDDCost) === true &&
                    (c === undefined || c.length === 0));
            },
            select: {
                type: SelectTypes.Options,
                options: EuCostCountries,
                multi: true,
            },
            question: ["If yes, which regions?"],
        },
        {
            effects: DetailsFields.RoamingCost,
            needsAnswering: () => {
                return details.get(DetailsFields.RoamingCost) === undefined;
            },
            question: [
                {
                    text: "Do you anticipate using your mobile in non UK countries?",
                    tooltip: "This is known as Roaming usage & covers calls, texts & data",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.RoamingCostCountries,
            needsAnswering: () => {
                const c = details.get(DetailsFields.RoamingCostCountries);
                return (details.get(DetailsFields.RoamingCost) === true &&
                    (c === undefined || c.length === 0));
            },
            select: {
                type: SelectTypes.Options,
                options: EuCostCountries,
                multi: true,
            },
            question: ["If yes, which regions?"],
        },
        {
            effects: DetailsFields.HighCost,
            needsAnswering: () => {
                return details.get(DetailsFields.HighCost) === undefined;
            },
            question: [
                {
                    text: "Do you anticipate a significant increase within the contract term in usage in countries that may not have access to a cost reducing bolt-on?",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.HighCostCountries,
            needsAnswering: () => {
                const c = details.get(DetailsFields.HighCostCountries);
                return (details.get(DetailsFields.HighCost) === true &&
                    (c === undefined || c.length === 0));
            },
            select: {
                type: SelectTypes.Options,
                options: HighCostCountries,
                multi: true,
            },
            question: ["If yes, which countries?"],
        },
    ];
    const networkProvider = [
        {
            effects: DetailsFields.CurrentNetwork,
            needsAnswering: () => {
                return details.get(DetailsFields.CurrentNetwork) === undefined;
            },
            select: {
                type: SelectTypes.Options,
                options: Network,
            },
            question: ["What is your current network?"],
        },
        {
            effects: DetailsFields.ChangeNetwork,
            needsAnswering: () => {
                return (details.get(DetailsFields.ChangeNetwork) === undefined &&
                    details.get(DetailsFields.CurrentNetwork) !== Network.None);
            },
            select: {
                type: SelectTypes.Options,
                options: ChangeNetwork,
            },
            question: [
                `Are you wanting to switch from ${displayEnum(Network, details.currentNetwork)} to another network?`,
            ],
        },
        {
            effects: DetailsFields.MultipleNetwork,
            needsAnswering: () => {
                return details.get(DetailsFields.MultipleNetwork) === undefined;
            },
            question: [
                {
                    text: "Do you currently use more than one network?",
                    tooltip: "Please detail how many lines & which network in the additional information box at the bottom of the tender",
                },
            ],
            validate: checkPosNeg,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.Postcodes,
            needsAnswering: () => {
                const c = details.get(DetailsFields.Postcodes);
                return c === undefined || c.length === 0;
            },
            question: [
                "Please enter postcodes that are significant to your business for signal checking.",
            ],
            select: {
                type: SelectTypes.MultiText,
                validation: (text) => {
                    if (validatePostcode(text) === true) {
                        return fix(text);
                    }
                },
            },
        },
    ];
    const handsetsDevices = [
        {
            effects: DetailsFields.ExistingDevices,
            question: ["Do you have any existing devices you want to keep?"],
            validate: checkEnum(ExistingDevices),
            needsAnswering: () => {
                return details.get(DetailsFields.ExistingDevices) === undefined;
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes, all of them",
                        value: ExistingDevices["Yes, all of them"],
                    },
                    {
                        label: "Yes, some",
                        value: ExistingDevices["Yes, some"],
                    },
                    {
                        label: "No",
                        value: ExistingDevices.None,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkEnum(ExistingDevices)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.Locked,
            question: ["Are your current devices locked to a network?"],
            select: {
                type: SelectTypes.Options,
                options: DevicesLocked,
            },
            needsAnswering: () => {
                return ((details.get(DetailsFields.ExistingDevices) ===
                    ExistingDevices["Yes, all of them"] ||
                    details.get(DetailsFields.ExistingDevices) ===
                        ExistingDevices["Yes, some"]) &&
                    details.get(DetailsFields.Locked) === undefined);
            },
            validate: checkEnum(DevicesLocked),
            reply: (text) => {
                try {
                    checkEnum(DevicesLocked)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.Handsets,
            question: ["Would you like more devices?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return details.get(DetailsFields.Handsets) === undefined;
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.SimOnlyFunded,
            select: {
                type: SelectTypes.Options,
                options: SimOnlyFunded,
            },
            question: [
                "Even if you are not getting handsets, you will sometimes qualify for additional support. This can be returned to you as a tech fund or as line rental credit.",
                "Which would you prefer?",
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === false &&
                    details.get(DetailsFields.SimOnlyFunded) === undefined);
            },
            validate: checkEnum(SimOnlyFunded),
            reply: (text) => {
                try {
                    checkEnum(SimOnlyFunded)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.FiveG,
            question: ["Are all devices required to be 5G compatible?"],
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    details.get(DetailsFields.FiveG) === undefined);
            },
            validate: checkEnum(FiveG),
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: FiveG.Yes,
                    },
                    {
                        label: "No",
                        value: FiveG.No,
                    },
                    {
                        label: "Don't mind",
                        value: FiveG["Don't mind"],
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkEnum(FiveG)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.PickedHandsets,
            question: ["Now, please pick some handsets... "],
            select: {
                type: SelectTypes.Multi,
                inputs: DevicesInput(devicesOptions),
                data: details.get(DetailsFields.PickedHandsets),
            },
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    (details.get(DetailsFields.PickedHandsets) === undefined ||
                        !checkPickedHandsets(details.get(DetailsFields.PickedHandsets), details.get(DetailsFields.Handsets))));
            },
            reply: (answer) => {
                if (checkPickedHandsets(answer, true)) {
                    return [];
                }
                return [`Please complete all of the fields`];
            },
        },
        {
            effects: DetailsFields.DEP,
            question: [
                "If you want a device enrolment solution for your selected handsets, please select.",
            ],
            validate: checkEnum(DEPChoices),
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    details.get(DetailsFields.DEP) === undefined);
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Not required",
                        value: DEPChoices["Not required"],
                    },
                    {
                        label: "Apple",
                        value: DEPChoices["Apple (DEP)"],
                    },
                    {
                        label: "Android",
                        value: DEPChoices["Android (AZT)"],
                    },
                    {
                        label: "Both",
                        value: DEPChoices["Both"],
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkEnum(DEPChoices)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.LikeHardwareFunded,
            select: {
                type: SelectTypes.Options,
                options: LikeHardwareFunded,
            },
            question: [
                {
                    text: "What method of funding would you like to use?",
                    tooltip: "If included in tariff is chosen we cannot guarantee there will be no upfront cost",
                },
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    details.get(DetailsFields.LikeHardwareFunded) === undefined);
            },
            validate: checkEnum(LikeHardwareFunded),
            reply: (text) => {
                try {
                    checkEnum(LikeHardwareFunded)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.LeasingType,
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "To get brand new handsets each time I renew the contract (Residual value)",
                        value: LeasingType.Residual_value,
                    },
                    {
                        label: "To retain my handsets until they stop working then swap out (Lease purchase)",
                        value: LeasingType.Lease_purchase,
                    },
                ],
            },
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    details.get(DetailsFields.LikeHardwareFunded) ===
                        LikeHardwareFunded.Leasing &&
                    details.get(DetailsFields.LeasingType) === undefined);
            },
            validate: checkEnum(LeasingType),
            reply: (text) => {
                try {
                    checkEnum(LeasingType)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
            question: [
                "If leasing handsets, which of the following to you prefer?",
            ],
        },
        {
            effects: DetailsFields.SimOnlyFunded,
            select: {
                type: SelectTypes.Options,
                options: SimOnlyFunded,
            },
            question: [
                "By choosing leasing you separate the hardware from the airtime contract, this is often known as a sim only solution.",
                "However you may qualify for additional financial support due to the airtime tariff commissions. If applicable, how you would like to receive these funds from the supplier.",
            ],
            needsAnswering: () => {
                return (details.get(DetailsFields.Handsets) === true &&
                    details.get(DetailsFields.LikeHardwareFunded) ===
                        LikeHardwareFunded.Leasing &&
                    details.get(DetailsFields.SimOnlyFunded) === undefined);
            },
            validate: checkEnum(SimOnlyFunded),
            reply: (text) => {
                try {
                    checkEnum(SimOnlyFunded)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
    ];
    const mdm = [
        {
            effects: DetailsFields.OwnMDM,
            question: ["Do you currently have Mobile Device Management (MDM)?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return details.get(DetailsFields.OwnMDM) === undefined;
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.MDMMSInTune,
            question: ["Do you use Microsoft InTune?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return (details.get(DetailsFields.OwnMDM) === true &&
                    details.get(DetailsFields.MDMMSInTune) === undefined);
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.MDMInLine,
            question: [
                "Does the contract for MDM run in line with your mobile phone contract?",
            ],
            validate: checkEnum(MDMInLine),
            needsAnswering: () => {
                return (details.get(DetailsFields.OwnMDM) === true &&
                    details.get(DetailsFields.MDMMSInTune) === false &&
                    details.get(DetailsFields.MDMInLine) === undefined);
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: MDMInLine.Yes,
                    },
                    {
                        label: "No",
                        value: MDMInLine.No,
                    },
                    {
                        label: "No fixed contract",
                        value: MDMInLine.No_Fixed_Contract,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkEnum(MDMInLine)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.MDMEnd,
            question: ["When does your MDM contract end?"],
            needsAnswering: () => {
                return (details.get(DetailsFields.OwnMDM) === true &&
                    details.get(DetailsFields.MDMMSInTune) === false &&
                    details.get(DetailsFields.MDMInLine) === MDMInLine.No &&
                    details.get(DetailsFields.MDMEnd) === undefined);
            },
            validate: checkDate,
            select: {
                type: SelectTypes.DatePicker,
            },
            reply: (value) => {
                try {
                    checkDate(value);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.MDMFee,
            question: ["Do you pay a monthly fee for the MDM?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return (details.get(DetailsFields.OwnMDM) === true &&
                    details.get(DetailsFields.MDMMSInTune) === false &&
                    details.get(DetailsFields.MDMFee));
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.MDM,
            question: [
                {
                    text: "What level of Mobile Device Management (MDM) would you like?",
                    tooltip: "tooltip here",
                },
            ],
            select: {
                type: SelectTypes.Options,
                options: ManagerPackageTypes,
            },
            needsAnswering: () => {
                return details.get(DetailsFields.MDM) === undefined;
            },
            validate: checkEnum(ManagerPackageTypes),
            reply: (text) => {
                try {
                    checkEnum(ManagerPackageTypes)(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
    ];
    const additionalInfo = [
        {
            effects: DetailsFields.VATRegistered,
            question: ["Are you VAT registered?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return details.get(DetailsFields.VATRegistered) === undefined;
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.CharitySocialEnterprise,
            question: ["Are you charity or social enterprise?"],
            validate: checkPosNeg,
            needsAnswering: () => {
                return (details.get(DetailsFields.CharitySocialEnterprise) ===
                    undefined && details.get(DetailsFields.VATRegistered));
            },
            select: {
                type: SelectTypes.Options,
                options: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            reply: (text) => {
                try {
                    checkPosNeg(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
        {
            effects: DetailsFields.ExtraDetails,
            question: [
                "Is there any additional information you want to provide?",
            ],
            validate: checkExtraDetails,
            needsAnswering: () => {
                return details.get(DetailsFields.ExtraDetails) === undefined;
            },
            reply: (text) => {
                try {
                    checkExtraDetails(text);
                    return [];
                }
                catch {
                    return [`Sorry, I don't understand that`];
                }
            },
        },
    ];
    return [
        ...lineRequirements,
        ...contractDates,
        ...discountsBenefits.filter(() => !noContract),
        ...currentCosts.filter(() => noContract),
        ...dataIDDRoaming,
        ...networkProvider.filter(() => !noContract),
        ...handsetsDevices,
        ...mdm,
        ...additionalInfo,
    ].find(({ needsAnswering = () => false }) => needsAnswering());
};
