// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge--wDAlU{display:flex;align-items:center;justify-content:center;width:2.8rem;min-height:1rem;margin-left:.2rem;color:var(--inverted-text-color);font-weight:bold;font-size:.75rem;line-height:1.5rem;background-color:#fff;border:var(--text-color) 1px solid;border-radius:1rem;opacity:75%}.badge--wDAlU.small--Yp6pm{width:2.2rem;line-height:1.2rem;border:none}.badge--wDAlU.veryGood--pyF0l{background-color:var(--very-good-scale-color)}.badge--wDAlU.good--XAXIq{background-color:var(--good-scale-color)}.badge--wDAlU.medium--ynSgk{color:var(--text-color);background-color:var(--medium-scale-color)}.badge--wDAlU.bad--LIaPx{background-color:var(--bad-scale-color)}.badge--wDAlU.veryBad--FWsZX{background-color:var(--very-bad-scale-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/HealthScore/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,YAAY,CAAC,eAAe,CAAC,iBAAiB,CAAC,gCAAgC,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,kCAAkC,CAAC,kBAAkB,CAAC,WAAW,CAAC,2BAAa,YAAY,CAAC,kBAAkB,CAAC,WAAW,CAAC,8BAAgB,6CAA6C,CAAC,0BAAY,wCAAwC,CAAC,4BAAc,uBAAuB,CAAC,0CAA0C,CAAC,yBAAW,uCAAuC,CAAC,6BAAe,4CAA4C","sourcesContent":[".badge{display:flex;align-items:center;justify-content:center;width:2.8rem;min-height:1rem;margin-left:.2rem;color:var(--inverted-text-color);font-weight:bold;font-size:.75rem;line-height:1.5rem;background-color:#fff;border:var(--text-color) 1px solid;border-radius:1rem;opacity:75%}.badge.small{width:2.2rem;line-height:1.2rem;border:none}.badge.veryGood{background-color:var(--very-good-scale-color)}.badge.good{background-color:var(--good-scale-color)}.badge.medium{color:var(--text-color);background-color:var(--medium-scale-color)}.badge.bad{background-color:var(--bad-scale-color)}.badge.veryBad{background-color:var(--very-bad-scale-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "badge--wDAlU",
	"small": "small--Yp6pm",
	"veryGood": "veryGood--pyF0l",
	"good": "good--XAXIq",
	"medium": "medium--ynSgk",
	"bad": "bad--LIaPx",
	"veryBad": "veryBad--FWsZX"
};
export default ___CSS_LOADER_EXPORT___;
