import React, { Component } from "react";
import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import { Handle, SliderRail, Tick, Track, } from "@Components/DoubleSlider/components";
import styles from "./index.module.scss";
const sliderStyle = {
    position: "relative",
    width: "100%",
    touchAction: "none",
};
export default class SingleSlider extends Component {
    static defaultProps = {
        value: 0,
        range: [0, 100],
        ticks: 0,
        className: "",
    };
    constructor(props) {
        super(props);
        this.state = {
            values: [props.value],
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.value !== this.props.value &&
            this.props.value !== this.state.values[0]) {
            this.setState({ values: [this.props.value] });
        }
    }
    onUpdate = (update) => {
        if (Math.round(update[0]) !== Math.round(this.props.value)) {
            const value = update[0];
            this.props.update(this.props.name, value);
        }
    };
    onChange = (values) => {
        this.setState({ values: [...values] });
    };
    render() {
        const { values } = this.state;
        const headers = this.props.headers === undefined
            ? undefined
            : Array.isArray(this.props.headers)
                ? this.props.headers
                : this.props.headers(this.state.values[0]);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${styles.wrapper} ${this.props.className}` },
                this.props.headers && (React.createElement("div", { className: styles.headers },
                    React.createElement("span", null, headers[0]),
                    React.createElement("span", { className: styles.headerRight }, headers[1]))),
                React.createElement(Slider, { mode: 1, step: this.props.increment || 1, domain: this.props.range, rootStyle: sliderStyle, onUpdate: this.onUpdate, onChange: this.onChange, values: values, disabled: this.props.disabled },
                    React.createElement(Rail, null, ({ getRailProps }) => (React.createElement(SliderRail, { getRailProps: getRailProps, disabled: this.props.disabled }))),
                    React.createElement(Handles, null, ({ handles, getHandleProps }) => (React.createElement("div", { className: "slider-handles" }, handles.map((handle) => (React.createElement(Handle, { key: handle.id, handle: handle, range: this.props.range, getHandleProps: getHandleProps, disabled: this.props.disabled, error: this.props.error, resultsPage: this.props.resultsPage })))))),
                    React.createElement(Tracks, { right: false }, ({ tracks, getTrackProps }) => (React.createElement("div", { className: "slider-tracks" }, tracks.map(({ id, source, target }) => (React.createElement(Track, { key: id, source: source, target: target, getTrackProps: getTrackProps, error: this.props.error })))))),
                    this.props.ticks > 0 && (React.createElement(Ticks, { count: this.props.ticks }, ({ ticks }) => (React.createElement("div", { className: "slider-ticks" }, ticks.map((tick) => (React.createElement(Tick, { key: tick.id, tick: tick, count: ticks.length }))))))))),
            React.createElement("div", { className: `${styles.errorWrapper} ${this.props.ticks > 0 ? styles.ticks : ""}` })));
    }
}
