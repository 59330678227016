export default (function_, delay) => {
    let timerId;
    return (...arguments_) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            function_(...arguments_);
            timerId = null;
        }, delay);
    };
};
export const settable = (function_) => {
    let timerId;
    return (delay, ...arguments_) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            function_(...arguments_);
            timerId = null;
        }, delay);
    };
};
