import React from "react";
import { isPlatform } from "@Helpers/GetSubdomain";
import CurrentCosts from "@SmartComponents/TenderEdit/Display/Boxes/CurrentCosts";
import NeedHelp from "@SmartComponents/TenderEdit/Display/Boxes/NeedHelp";
import Testimonials from "@SmartComponents/TenderEdit/Display/Boxes/Testimonials";
import styles from "./index.module.scss";
export default class RightGutter extends React.Component {
    render() {
        const { tender, isAdmin, customerName } = this.props;
        const view = isPlatform();
        return (React.createElement("div", { className: styles.wrapper },
            isAdmin === true && customerName && (React.createElement("div", null,
                React.createElement("b", null, "Customer:"),
                " ",
                customerName)),
            React.createElement(CurrentCosts, { tender: tender }),
            view === true && (React.createElement(React.Fragment, null,
                React.createElement(Testimonials, null),
                React.createElement(NeedHelp, null)))));
    }
}
