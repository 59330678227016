import dayjs from "dayjs";
const userVersion1 = (current = {}) => {
    const { userVersion = 0 } = current;
    if (userVersion >= 1) {
        return current;
    }
    return { ...current, userVersion: 1 };
};
const userVersion2 = (current = {}) => {
    const { userVersion = 0 } = current;
    if (userVersion >= 2) {
        return current;
    }
    const { activated = true } = current;
    return { ...current, activated, userVersion: 2 };
};
export default (original = {}) => {
    const converted = [userVersion1, userVersion2].reduce((converted, f) => f(converted), { ...original });
    let { userVersion, email, password, isPasswordHashed = false, name, mobileNumber, lastLogin, singleUsePassword, resetId, type, activated, mfaEnabled, mfaSecret, assumed, } = converted;
    if (lastLogin) {
        lastLogin = dayjs.utc(lastLogin);
    }
    if (email) {
        email = email.toLowerCase();
    }
    return {
        userVersion,
        email,
        password,
        isPasswordHashed,
        name,
        mobileNumber,
        lastLogin,
        singleUsePassword,
        resetId,
        type,
        activated,
        mfaEnabled,
        mfaSecret,
        assumed,
    };
};
