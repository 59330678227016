import Button from "@Components/Button";
import Card from "@Components/Card";
import InfoPopup from "@Components/InfoPopup";
import buttons from "@Components/Sass/buttons.module.scss";
import React from "react";
export default class YesNoConfirm extends React.Component {
    click = async (sendEmail, close) => {
        await this.props.onSelection(sendEmail);
        close();
    };
    render() {
        const popup = (close) => {
            return (React.createElement(Card, null,
                "Do you want to send an email to the",
                " ",
                this.props.emailSubject,
                "?",
                React.createElement("div", { className: buttons.splitGroups },
                    React.createElement(Button, { click: () => this.click(true, close) }, "Yes"),
                    React.createElement(Button, { click: () => this.click(false, close), dark: true }, "No"))));
        };
        return (React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose }));
    }
}
