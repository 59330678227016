import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import { isEmpty } from "@Shared/Helpers/IsEmpty";
import constraints from "./constraints";
export default class Company extends BaseEntity {
    constructor(data = {}, extraConstraints = {}) {
        super(data, { ...constraints, ...extraConstraints });
    }
    get data() {
        const parent = super.data;
        const { companyNo, details, tradingAs } = this;
        const { companyVersion, viewAll } = this._data;
        return {
            ...parent,
            companyVersion,
            companyNo,
            tradingAs,
            details,
            viewAll,
        };
    }
    get companyNo() {
        return this._data.companyNo;
    }
    get tradingAs() {
        return this._data.tradingAs;
    }
    get name() {
        if (!isEmpty(this.tradingAs)) {
            return this.tradingAs;
        }
        return this.details.name;
    }
    get details() {
        return this._data.details;
    }
    get viewAll() {
        return this._data.viewAll === true;
    }
}
