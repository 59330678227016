export var BundleType;
(function (BundleType) {
    BundleType[BundleType["At_Account_Level"] = 0] = "At_Account_Level";
    BundleType[BundleType["Individual"] = 1] = "Individual";
    BundleType[BundleType["Shared_Across_Account"] = 2] = "Shared_Across_Account";
})(BundleType || (BundleType = {}));
export var Duration;
(function (Duration) {
    Duration[Duration["Per_Day"] = 0] = "Per_Day";
    Duration[Duration["Per_Month"] = 1] = "Per_Month";
    Duration[Duration["Annual"] = 2] = "Annual";
})(Duration || (Duration = {}));
export var Effected;
(function (Effected) {
    Effected["Name"] = "name";
    Effected["Mins"] = "mins";
    Effected["SMS"] = "sms";
    Effected["MMS"] = "mms";
    Effected["DataMB"] = "dataMB";
    Effected["Type"] = "type";
    Effected["Duration"] = "duration";
    Effected["NumberOfBundles"] = "numBundles";
    Effected["Cost"] = "cost";
    Effected["ApplyRows"] = "applyToRows";
    Effected["Color"] = "color";
})(Effected || (Effected = {}));
export var UnitType;
(function (UnitType) {
    UnitType[UnitType["Mins"] = 0] = "Mins";
    // Texts,
    UnitType[UnitType["Data"] = 2] = "Data";
    UnitType[UnitType["SMS"] = 3] = "SMS";
    UnitType[UnitType["MMS"] = 4] = "MMS";
})(UnitType || (UnitType = {}));
export var AllowanceTypeGroups;
(function (AllowanceTypeGroups) {
    AllowanceTypeGroups[AllowanceTypeGroups["UK"] = 0] = "UK";
    AllowanceTypeGroups[AllowanceTypeGroups["IDD"] = 1] = "IDD";
    AllowanceTypeGroups[AllowanceTypeGroups["EU_Roaming"] = 2] = "EU_Roaming";
    AllowanceTypeGroups[AllowanceTypeGroups["Non_EU_Roaming"] = 3] = "Non_EU_Roaming";
})(AllowanceTypeGroups || (AllowanceTypeGroups = {}));
