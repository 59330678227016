import React from "react";
import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
import bdo from "../../Partners/BDO/bdo.png";
import bionic from "../../Partners/Bionic/Logo/bionic.svg";
import ERA from "../../Partners/ERA/Logo";
import Protel from "../../Partners/Protel/Logo";
import sevenStepSolutions from "../../Partners/SevenStepSolutions/Logo/logo.png";
import tm from "../../Partners/TechMahindra/Logo/logo.png";
import styles from "./index.module.scss";
import icon from "./logo.svg";
export default class Logo extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        const broker = getBrokerDomain();
        switch (broker) {
            case BrokerDomain.ERA:
                return React.createElement(ERA, { ...this.props });
            case BrokerDomain.TechMahindra:
                return (React.createElement("img", { src: tm, alt: "Tech-Mahindra", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
            case BrokerDomain.SevenStepSolutions:
                return (React.createElement("img", { src: sevenStepSolutions, alt: "7 Step Solutions", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
            case BrokerDomain.Bionic:
                return (React.createElement("img", { src: bionic, alt: "bionic", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
            case BrokerDomain.BDO:
                return (React.createElement("img", { src: bdo, alt: "BDO", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
            case BrokerDomain.Protel:
                return React.createElement(Protel, { ...this.props });
            default:
                return (React.createElement("img", { src: icon, alt: "Predict Mobile", className: `${styles.logo} ${this.props.className}`, onClick: this.props.onClick }));
        }
    }
}
