import SVG from "@Components/Icons/SVG";
export default class OpenInNew extends SVG {
    path = [
        "M 78 78",
        "H 22",
        "V 22",
        "H 50",
        "V 14",
        "H 22",
        "C 17.56 14 14 17.6 14 22",
        "V 78",
        "C 14 82.4 17.56 86 22 86",
        "H 78",
        "C 82.4 86 86 82.4 86 78",
        "V 50",
        "H 78",
        "V 78",
        "Z",
        "M 58 14",
        "V 22",
        "H 72.36",
        "L 33.04 61.32",
        "L 38.68 66.96",
        "L 78 27.64",
        "V 42",
        "H 86",
        "V 14",
        "H 58",
        "Z",
    ];
    noFill = true;
}
