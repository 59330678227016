import dayjs from "dayjs";
import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import userMigrate from "@Shared/Entities/AbstractEntities/User/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const userMigrated = userMigrate(original);
    const converted = [version1].reduce((converted, f) => f(converted), original);
    let { version, customerId, supplierId, tenderId, partnerId, responseId, documents, usageId, status, note, requirementsChanged, startDate, } = converted;
    if (startDate !== undefined) {
        startDate = dayjs.utc(startDate);
    }
    return {
        ...baseMigrated,
        ...userMigrated,
        version,
        customerId,
        supplierId,
        tenderId,
        partnerId,
        responseId,
        documents,
        usageId,
        status,
        note,
        requirementsChanged,
        startDate,
    };
};
