import SVG from "@Components/Icons/SVG";
export default class Filter extends SVG {
    path = [
        "M18.15 23.33",
        "C24.04 23.33 28.89 18.98 29.69 13.33",
        "H91.48",
        "C92.39 13.33 93.13 12.6 93.13 11.68",
        "S92.39 10.04 91.48 10.04",
        "H29.69",
        "C28.89 4.35 24.04 0 18.15 0",
        "C11.73 0 6.5 5.23 6.5 11.65",
        "S11.73 23.33 18.15 23.33",
        "Z",
        "M18.15 3.33",
        "C22.75 3.33 26.5 7.09 26.5 11.65",
        "S22.75 20 18.15 20",
        "S9.83 16.25 9.83 11.65",
        "S13.59 3.33 18.15 3.33",
        "Z",
        "M81.48 76.67",
        "C75.62 76.67 70.78 81.02 69.94 86.67",
        "H8.15",
        "C7.24 86.67 6.5 87.4 6.5 88.32",
        "C6.5 89.23 7.24 89.96 8.15 89.96",
        "H69.94",
        "C70.75 95.61 75.62 99.96 81.48 99.96",
        "C87.9 99.96 93.13 94.74 93.13 88.32",
        "S87.94 76.67 81.48 76.67",
        "Z",
        "M81.48 96.67",
        "C76.89 96.67 73.17 92.91 73.17 88.35",
        "C73.17 83.75 76.92 80.04 81.48 80.04",
        "S89.8 83.79 89.8 88.35",
        "C89.83 92.91 86.08 96.67 81.48 96.67",
        "Z",
        "M91.48 48.32",
        "H62.11",
        "C61.45 42.32 56.36 37.61 50.18 37.61",
        "S38.92 42.32 38.25 48.32",
        "H8.15",
        "C7.24 48.32 6.5 49.05 6.5 49.96",
        "C6.5 50.88 7.24 51.61 8.15 51.61",
        "H38.32",
        "C39.27 57.26 44.22 61.61 50.15 61.61",
        "S61.03 57.3 61.97 51.61",
        "H91.45",
        "C92.36 51.61 93.1 50.88 93.1 49.96",
        "C93.17 49.09 92.43 48.32 91.48 48.32",
        "Z",
        "M50.18 58.32",
        "C45.41 58.32 41.52 54.42 41.52 49.65",
        "S45.41 40.98 50.18 40.98",
        "S58.85 44.88 58.85 49.65",
        "S54.96 58.32 50.18 58.32",
        "Z",
    ];
}
