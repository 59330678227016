// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box--Ufnw3{display:flex;align-items:center;justify-content:center;width:1.8rem;height:1.8rem;border:2px solid var(--border-color);border-radius:15%;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/gui/Components/Tickbox/index.module.scss"],"names":[],"mappings":"AAAA,YAAK,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,YAAY,CAAC,aAAa,CAAC,oCAAoC,CAAC,iBAAiB,CAAC,cAAc","sourcesContent":[".box{display:flex;align-items:center;justify-content:center;width:1.8rem;height:1.8rem;border:2px solid var(--border-color);border-radius:15%;cursor:pointer}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "box--Ufnw3"
};
export default ___CSS_LOADER_EXPORT___;
