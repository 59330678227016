import SVG from "@Components/Icons/SVG";
export default class Info extends SVG {
    path = [
        "M 0 50 A 25 25 0 1 1 100 50 A 25 25 0 1 1 0 50",
        "M 8 50 A 25 25 0 1 1 92 50 A 25 25 0 1 1 8 50",
        "M 45 78 A 1 1 0 1 0 55 78 V 40 A 1 1 0 0 0 45 40 Z",
        "M 43 22 A 1 1 0 1 0 57 22 A 1 1 0 0 0 43 22 Z",
    ];
    evenOdd = true;
}
