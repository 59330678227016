import { combineReducers } from "redux";
import chat from "./chat";
import companies from "./companies";
import comparisons from "./comparisons";
import tenders from "./tenders";
import usage from "./usage";
export default combineReducers({
    chat,
    companies,
    comparisons,
    tenders,
    usage,
});
