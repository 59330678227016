// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading--ibhpz{width:7rem;height:7rem;border:3px solid var(--border-color);border-top-color:#000;border-radius:50%;-webkit-animation:spin--_Rjca 2s linear infinite;animation:spin--_Rjca 2s linear infinite}@-webkit-keyframes spin--_Rjca{0%{-webkit-transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg)}}@keyframes spin--_Rjca{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Loading/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,UAAU,CAAC,WAAW,CAAC,oCAAoC,CAAC,qBAAqB,CAAC,iBAAiB,CAAC,gDAAyC,CAAC,wCAAiC,CAAC,+BAAwB,GAAG,8BAA8B,CAAC,KAAK,gCAAgC,CAAC,CAAC,uBAAgB,GAAG,sBAAsB,CAAC,KAAK,wBAAwB,CAAC","sourcesContent":[".loading{width:7rem;height:7rem;border:3px solid var(--border-color);border-top-color:#000;border-radius:50%;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg)}}@keyframes spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "loading--ibhpz",
	"spin": "spin--_Rjca"
};
export default ___CSS_LOADER_EXPORT___;
