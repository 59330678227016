// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--rQhQv{flex:1;width:100%;height:100%;padding-bottom:1em;overflow-x:hidden;overflow-y:auto;background-color:var(--main-background-color)}.container--rQhQv .loading--uC97V{max-width:800px;margin:auto}.predictySpeech--22n83{position:absolute;top:23rem;left:1rem;visibility:hidden}.predictySpeech--22n83 .bubble--XCAhb{position:absolute;bottom:8rem;left:5rem}.predictySpeech--22n83 .iconStyle--dBAZk{height:13vh}.displayRight--p7bpb{position:absolute;right:1rem;bottom:4rem}@media(min-width: 1200px){.predictySpeech--22n83{visibility:visible}.predictySpeech--22n83 .bubble--XCAhb{width:13rem}}@media(min-width: 1500px){.predictySpeech--22n83 .bubble--XCAhb{width:20rem}}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Register/Supplier/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,MAAM,CAAC,UAAU,CAAC,WAAW,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,eAAe,CAAC,6CAA6C,CAAC,kCAAoB,eAAe,CAAC,WAAW,CAAC,uBAAgB,iBAAiB,CAAC,SAAS,CAAC,SAAS,CAAC,iBAAiB,CAAC,sCAAwB,iBAAiB,CAAC,WAAW,CAAC,SAAS,CAAC,yCAA2B,WAAW,CAAC,qBAAc,iBAAiB,CAAC,UAAU,CAAC,WAAW,CAAC,0BAA0B,uBAAgB,kBAAkB,CAAC,sCAAwB,WAAW,CAAC,CAAC,0BAA0B,sCAAwB,WAAW,CAAC","sourcesContent":[".container{flex:1;width:100%;height:100%;padding-bottom:1em;overflow-x:hidden;overflow-y:auto;background-color:var(--main-background-color)}.container .loading{max-width:800px;margin:auto}.predictySpeech{position:absolute;top:23rem;left:1rem;visibility:hidden}.predictySpeech .bubble{position:absolute;bottom:8rem;left:5rem}.predictySpeech .iconStyle{height:13vh}.displayRight{position:absolute;right:1rem;bottom:4rem}@media(min-width: 1200px){.predictySpeech{visibility:visible}.predictySpeech .bubble{width:13rem}}@media(min-width: 1500px){.predictySpeech .bubble{width:20rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--rQhQv",
	"loading": "loading--uC97V",
	"predictySpeech": "predictySpeech--22n83",
	"bubble": "bubble--XCAhb",
	"iconStyle": "iconStyle--dBAZk",
	"displayRight": "displayRight--p7bpb"
};
export default ___CSS_LOADER_EXPORT___;
