import React from "react";
import Badge from "@Components/Badge";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import styles from "./index.module.scss";
export default class FormGroup extends React.Component {
    render() {
        const { className = "", hidden = false, labelLeft, topRight, width, } = this.props;
        if (hidden) {
            return React.createElement(React.Fragment, null);
        }
        const topRightStyle = topRight ? styles.topRight : "";
        const horizontal = this.props.horizontal ? styles.horizontal : "";
        const longerInput = this.props.longerInput ? styles.longerInput : "";
        const narrowLabel = this.props.narrowLabel ? styles.narrowLabel : "";
        const content = labelLeft ? (React.createElement(React.Fragment, null,
            (this.props.label !== undefined ||
                this.props.notes !== undefined ||
                this.props.topNotes !== undefined) && (React.createElement("div", { className: `${styles.label} ${longerInput} ${narrowLabel}` },
                this.props.topNotes === undefined ? (React.createElement("h4", { className: styles.text }, this.props.label)) : (React.createElement("div", { className: styles.topNoteWrapper },
                    this.props.badge !== undefined && (React.createElement("div", { className: styles.badge },
                        React.createElement(Badge, { width: width }, roundToFormat(this.props.badge, 0)))),
                    React.createElement("div", { className: styles.noteLabel },
                        React.createElement("div", null, this.props.topNotes),
                        React.createElement("h4", { className: styles.text }, this.props.label)))),
                this.props.notes && (React.createElement("div", { className: styles.notes }, this.props.notes)))),
            React.createElement("div", { className: `${styles.input} ${topRightStyle} ${longerInput} ${this.props.inputClassName}` }, this.props.children))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${styles.input} ${longerInput} ${this.props.inputClassName}` },
                React.createElement("h4", { className: styles.text }, this.props.label),
                this.props.children,
                this.props.notes && (React.createElement("div", { className: styles.notes }, this.props.notes)))));
        return (React.createElement("div", { className: `${styles.group} ${className} ${horizontal} ${this.props.noPadding ? styles.noPadding : ""}` }, content));
    }
}
