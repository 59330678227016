export const ROOT = "/supplier";
export const TENDERS = ROOT + "/tenders";
export const TENDER = TENDERS + "/:tenderId";
export const TENDER_RESPONSES = ROOT + "/responses";
export const TENDER_RESPONSE = TENDER_RESPONSES + "/:responseId";
export const VIEW_SCORES = TENDER_RESPONSE + "/scores";
export const CUSTOMERS = ROOT + "/customers";
export const CUSTOMER = CUSTOMERS + "/:awardedContractId";
export const DOCUMENTS = CUSTOMER + "/documents";
export const MY_TENDER = CUSTOMER + "/tender";
export const RECOMMENDATIONS = CUSTOMER + "/recommendations";
export const MY_CONTRACT = CUSTOMER + "/contract";
export const BILLS = CUSTOMER + "/bills";
export const PREVIOUS_USAGE = CUSTOMER + "/previous-sensabill";
export const CURRENT_USAGE = CUSTOMER + "/sensabill";
export const FEEDBACK = ROOT + "/feedback";
export const MY_ORGANISATION = ROOT + "/my-organisation";
export const USERS = MY_ORGANISATION + "/users";
export const USER = USERS + "/:userId";
export const EDIT_USER = USER + "/edit";
export const CREATE_USER = USERS + "/:supplierId/create";
export const MY_ACCOUNT = ROOT + "/my-account";
export const LOGOUT = ROOT + "/logout";
export const PROPOSALS = ROOT + "/proposals";
export const PROPOSAL = PROPOSALS + "/:responseId";
export const PROPOSAL_EDIT = PROPOSAL + "/edit";
export const PROPOSAL_SAVE = PROPOSAL + "/save";
export const NOT_FOUND = ROOT + "/*";
