import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import Benchmark from "@Shared/Helpers/Benchmark";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Usage extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/customers/${this.customerId}/contracts/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { customerId, supplierId, breakdown, endsAt, network, platform, username, password, passwordEncrypted = false, twoFA = false, userNameEncrypted = false, mailingList, stats, status, canViewSensabill, hasTender, canCreateTenderOverride = false, hideStartTenderOverride = false, totalRows, } = this;
        const { version } = this._data;
        return {
            ...parent,
            version,
            customerId,
            supplierId,
            breakdown,
            endsAt,
            network,
            platform,
            username,
            password,
            passwordEncrypted,
            twoFA,
            userNameEncrypted,
            mailingList,
            stats,
            status,
            canViewSensabill,
            hasTender,
            canCreateTenderOverride,
            hideStartTenderOverride,
            totalRows,
        };
    }
    get toSave() {
        const { seqNo, primaryTerm, stats, breakdown, username, password, twoFA, ...data } = this.data;
        return data;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
            monthlyUsage: `${this.baseUrl}/usage/monthly`,
            dailyUsage: `${this.baseUrl}/usage/daily`,
        };
    }
    get customerId() {
        return this._data.customerId;
    }
    get supplierId() {
        return this._data.supplierId;
    }
    get endsAt() {
        return this._data.endsAt;
    }
    get network() {
        return this._data.network;
    }
    get platform() {
        return this._data.platform;
    }
    get username() {
        return this._data.username;
    }
    get password() {
        return this._data.password;
    }
    get twoFA() {
        return this._data.twoFA;
    }
    get userNameEncrypted() {
        return this._data.userNameEncrypted;
    }
    get passwordEncrypted() {
        return this._data.passwordEncrypted;
    }
    get stats() {
        return this._data.stats;
    }
    get breakdown() {
        return this._data.breakdown;
    }
    get status() {
        return this._data.status;
    }
    get canViewSensabill() {
        return this._data.canViewSensabill === true;
    }
    get hasTender() {
        return this._data.hasTender === true;
    }
    get canCreateTenderOverride() {
        return this._data.canCreateTenderOverride;
    }
    get hideStartTenderOverride() {
        return this._data.hideStartTenderOverride;
    }
    get totalRows() {
        return this._data.totalRows;
    }
    get mailingList() {
        return this._data.mailingList;
    }
    get benchmark() {
        return Benchmark({
            daysTraveller: this.stats.daysTraveller,
            voiceLines: this.stats.usedVoiceLines,
            overallData: this.stats.recommendedData,
            iddUkMinsToRow: this.stats.iddRow,
            iddUkMinsToApac: this.stats.iddApac,
            iddUkMinsToEurope: this.stats.iddEurope,
            iddUkMinsToUsaCanada: this.stats.iddUsa,
            pictureMessages: this.stats.pictureMessages,
            ukToInternationalTexts: this.stats.ukToIntTexts,
            textsWhileRoaming: this.stats.roamingTexts,
        });
    }
}
