import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put } from "@Api/Partners/companies";
export const DELETE_PARTNER = "deletePartner";
export const deleteCompany = (dispatch, data) => del(data)
    .then((id) => dispatch({
    type: DELETE_PARTNER,
    data: id,
}))
    .then(notify200(dispatch, API_TYPE.DELETE, "Partner Deleted", "The partner(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_PARTNER = "updatePartner";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_PARTNER,
        data,
    });
};
export const createCompany = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Partner Created", "The partner was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateCompany = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Partner Updated", "The partner was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchCompany = (dispatch, partnerId) => find(partnerId).then(add(dispatch));
export const fetchCompanies = (dispatch) => {
    return get(add(dispatch));
};
