export const ROOT = "/customer";
export const MY_ORGANISATION = ROOT + "/my-organisation";
export const USERS = MY_ORGANISATION + "/users";
export const USER = USERS + "/:userId";
export const EDIT_USER = USER + "/edit";
export const CREATE_USER = USERS + "/:customerId/create";
export const TENDERS = ROOT + "/tenders";
export const TENDER = TENDERS + "/:tenderId";
export const CREATE_TENDER = TENDERS + "/create";
export const CREATE_TENDER_WITH_USAGE = CREATE_TENDER + "/usage/:usageId";
export const EDIT_TENDER = TENDER + "/edit";
export const VIEW_RESPONSES = TENDER + "/responses";
export const COMPARISON = VIEW_RESPONSES + "/comparison";
export const USAGES = ROOT + "/sensabill";
export const USAGE = USAGES + "/:usageId";
export const MY_ACCOUNT = ROOT + "/my-account";
export const LOGOUT = ROOT + "/logout";
export const NOT_FOUND = ROOT + "/*";
