import Factory from "@Shared/Factory";
import { ADD_SUPPLIER, DELETE_SUPPLIER, } from "@Store/Actions/Suppliers/companies";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_SUPPLIER:
            return state.filter((object) => object.id !== data);
        case ADD_SUPPLIER:
            const supplier = Factory.Supplier(data);
            return [...state.filter(({ id }) => id !== supplier.id), supplier];
    }
    return state;
};
