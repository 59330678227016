// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--v8l8W{display:flex;flex-direction:column;border-right:1px solid var(--main-background-color)}.wrapper--v8l8W .header--pHRlh .logo--xDlar{height:10vh;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.wrapper--v8l8W .nav--gGa8j{position:relative;display:flex;flex:1;flex-direction:column;height:10vh;margin-top:35%}.wrapper--v8l8W .nav--gGa8j .navItem--Czxte{z-index:2;display:flex;flex-direction:column;align-items:center;justify-content:center;width:7em;height:3em;margin:1em auto;font-weight:bold;font-size:.85rem;text-align:center;background-color:var(--card-color);border-radius:100%}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Register/Page/LeftBar/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,YAAY,CAAC,qBAAqB,CAAC,mDAAmD,CAAC,4CAAuB,WAAW,CAAC,2EAA2E,CAAC,4BAAc,iBAAiB,CAAC,YAAY,CAAC,MAAM,CAAC,qBAAqB,CAAC,WAAW,CAAC,cAAc,CAAC,4CAAuB,SAAS,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,SAAS,CAAC,UAAU,CAAC,eAAe,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,kCAAkC,CAAC,kBAAkB","sourcesContent":[".wrapper{display:flex;flex-direction:column;border-right:1px solid var(--main-background-color)}.wrapper .header .logo{height:10vh;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.wrapper .nav{position:relative;display:flex;flex:1;flex-direction:column;height:10vh;margin-top:35%}.wrapper .nav .navItem{z-index:2;display:flex;flex-direction:column;align-items:center;justify-content:center;width:7em;height:3em;margin:1em auto;font-weight:bold;font-size:.85rem;text-align:center;background-color:var(--card-color);border-radius:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--v8l8W",
	"header": "header--pHRlh",
	"logo": "logo--xDlar",
	"nav": "nav--gGa8j",
	"navItem": "navItem--Czxte"
};
export default ___CSS_LOADER_EXPORT___;
