import React from "react";
import { connect } from "react-redux";
import Button from "@Components/Button";
import Card from "@Components/Card";
import InfoPopup from "@Components/InfoPopup";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import buttons from "@Components/Sass/buttons.module.scss";
import TenderBox from "@Components/TenderBoxes";
import { fetchTender } from "@Store/Actions/Customers/tenders";
import { addNotification } from "@Store/Actions/Notifications";
import { deleteResponse, fetchResponses, unsubmitResponse, } from "@Store/Actions/Suppliers/responses";
import styles from "../index.module.scss";
class MyResponses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            popup: false,
            deleteResponse: undefined,
        };
    }
    componentDidMount() {
        this.props
            .fetchResponses(this.props.user.supplierId)
            .then(() => {
            const uniqueTenderIds = new Set(this.props.responses.map((response) => response.tenderId));
            return Promise.all([...uniqueTenderIds].map((tenderId) => {
                return this.props.fetchTender(tenderId);
            }));
        })
            .then(() => {
            this.setState({
                fetching: false,
            });
        });
    }
    closePopup = () => {
        this.setState({ popup: false });
    };
    deleteResponse = () => {
        this.props.delete(this.state.deleteResponse).then(this.closePopup);
    };
    delete = (response) => {
        this.setState({
            popup: true,
            deleteResponse: response,
        });
    };
    render() {
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        const popup = () => {
            return (React.createElement(Card, { title: "Are you sure?" },
                React.createElement("div", { className: styles.popup },
                    React.createElement("div", { className: buttons.splitGroups },
                        React.createElement(Button, { cta: true, click: this.deleteResponse, className: buttons.buttons }, "DELETE"),
                        React.createElement(Button, { dark: true, click: this.closePopup, className: buttons.buttons }, "CANCEL")))));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(InfoPopup, { trigger: this.state.popup, content: popup }),
            React.createElement(TenderBox, { dashboard: true, responses: this.props.responses, unsubmitResponse: this.props.unsubmitResponse, tenders: this.props.tenders, delete: this.delete })));
    }
}
const mapStateToProps = ({ auth: { user }, customers: { tenders = [] }, suppliers: { responses = [] }, }) => ({
    user,
    tenders,
    responses,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetchResponses: (supplierId) => fetchResponses(dispatch, supplierId),
        fetchTender: (tenderId) => fetchTender(dispatch, tenderId),
        unsubmitResponse: (response) => unsubmitResponse(dispatch, response),
        delete: (response) => deleteResponse(dispatch, response),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyResponses);
