import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import { deviceConstraints } from "@Shared/Entities/Hardware/Device/constraints";
import { Color, Make } from "@Shared/Entities/Hardware/Device/enums";
import { migrate } from "@Shared/Entities/Hardware/Device/migrate";
export default class Device extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), deviceConstraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/devices/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { name, make, model, color, size, os, price, predecessorId, replacementId, priceListMonth, } = this;
        return {
            ...parent,
            version,
            name,
            make,
            model,
            color,
            size,
            os,
            price,
            predecessorId,
            replacementId,
            priceListMonth,
        };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get name() {
        return `${Make[this.make]} ${this.model} ${this.size} ${Color[this.color]}`;
    }
    get make() {
        return this._data.make;
    }
    get model() {
        return this._data.model;
    }
    get color() {
        return this._data.color;
    }
    get size() {
        return this._data.size;
    }
    get os() {
        return this._data.os;
    }
    get price() {
        return this._data.price;
    }
    get predecessorId() {
        return this._data.predecessorId;
    }
    get replacementId() {
        return this._data.replacementId;
    }
    get priceListMonth() {
        return this._data.priceListMonth;
    }
}
