// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--iXdiv{min-width:2em;max-width:2em}.setWidth--pmoew{width:12rem}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Admin/Nav/index.module.scss"],"names":[],"mappings":"AAAA,aAAM,aAAa,CAAC,aAAa,CAAC,iBAAU,WAAW","sourcesContent":[".icon{min-width:2em;max-width:2em}.setWidth{width:12rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--iXdiv",
	"setWidth": "setWidth--pmoew"
};
export default ___CSS_LOADER_EXPORT___;
