import React from "react";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@Components/Icons/Logout";
import CustomerIcon from "@Components/Icons/Organisation";
import ProfileIcon from "@Components/Icons/Profile";
import Tender from "@Components/Icons/Tender";
import Usage from "@Components/Icons/Usage";
import PrimaryNav from "@Components/PrimaryNav";
import GetPath, { GetReplacements } from "@Helpers/GetPath";
import IsPathMatch from "@Helpers/IsPathMatch";
import { LOGOUT, MY_ACCOUNT, MY_ORGANISATION, TENDERS, USAGES, VIEW_RESPONSES, } from "@Shared/Paths/Customer";
import styles from "./index.module.scss";
const topRoutes = [
    /*{
        path: ROOT,
        name: "Dashboard",
        icon: <Dashboard className={styles.icon} />,
        exact: true,
        filter: (path: string, company: Customer) => company.viewAll,
    },*/
    {
        path: TENDERS,
        name: "Tenders",
        icon: React.createElement(Tender, { className: styles.icon }),
        filter: (path, company) => company?.canCreateTender,
    },
    {
        path: USAGES,
        name: "Sensabill",
        icon: React.createElement(Usage, { className: styles.icon }),
        filter: (path, company) => company?.canViewSensabill,
    },
    {
        path: MY_ORGANISATION,
        name: "My Organisation",
        icon: React.createElement(CustomerIcon, { className: styles.icon }),
    },
    {
        path: MY_ACCOUNT,
        name: "My Account",
        icon: React.createElement(ProfileIcon, { className: styles.icon }),
        filter: (path, company) => company?.viewAll,
    },
];
const bottomRoutes = [
    {
        path: LOGOUT,
        name: "Logout",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
    },
];
export default (props) => {
    const location = useLocation();
    // Hides left nav when user clicks View Responses
    if (IsPathMatch(location.pathname, VIEW_RESPONSES)) {
        return React.createElement(React.Fragment, null);
    }
    const topRoutesFiltered = topRoutes
        .filter(({ filter = (path, company) => !!path && !!company, }) => filter(location.pathname, props.company))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const bottomRoutesFiltered = bottomRoutes
        .filter(({ filter = (path, company) => !!path && !!company, }) => filter(location.pathname, props.company))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const extra = React.createElement(React.Fragment, null);
    return (React.createElement(PrimaryNav, { topLinks: topRoutesFiltered, bottomLinks: bottomRoutesFiltered }, extra));
};
