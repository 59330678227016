import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import { priceRangeConstraints } from "@Shared/Entities/Hardware/PriceRange/constraints";
import { migrate } from "@Shared/Entities/Hardware/PriceRange/migrate";
export default class PriceRange extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), priceRangeConstraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/price-range/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { high, low, userId } = this;
        return {
            ...parent,
            version,
            high,
            low,
            userId,
        };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get high() {
        return this._data.high;
    }
    get low() {
        return this._data.low;
    }
    get userId() {
        return this._data.userId;
    }
}
