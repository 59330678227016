import api from "@Api/index";
export const collection = (callback, url, params = {}) => {
    return api
        .get(url, { params })
        .then(async ({ data }) => {
        await Promise.all(data.items.map((datum) => callback(datum.item)));
        return data;
    })
        .then(({ links }) => links.next ? collection(callback, links.next) : "done");
};
export const collectionV2 = (callback, url, params = {}) => {
    return api
        .get(url, { params })
        .then(async ({ data }) => {
        await callback(data.items);
        return data;
    })
        .then(({ links }) => links.next ? collectionV2(callback, links.next) : "done");
};
