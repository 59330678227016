import Factory from "@Shared/Factory";
import { ADD_AWARDED_CONTRACT } from "@Store/Actions/AwardedContract";
export default (state = [], { type, data }) => {
    switch (type) {
        case ADD_AWARDED_CONTRACT:
            const awardedContract = Factory.AwardedContract(data);
            return [
                ...state.filter(({ id }) => id !== awardedContract.id),
                awardedContract,
            ];
    }
    return state;
};
