import SVG from "@Components/Icons/SVG";
export default class Circle extends SVG {
    path = [
        "m 50 5 c -24.8513 0 -45 20.1487 -45 45 s 20.1487 45 45 45 s 45 -20.1487 45 -45 s -20.1487 -45 -45 -45 z z",
    ];
    fill = "#ffffff";
    strokeDetails = {
        stroke: "#9a9a9a",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
