import { ATCUnits, ChangeNetwork, DEPChoices, DetailsFields, DeviceRange, DevicesLocked, EuCostCountries, ExistingDevices, FiveG, HighCostCountries, LeasingType, LikeHardwareFunded, ManagerPackageTypes, MDMInLine, Months, SimOnlyFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { validation } from "@Shared/Validate";
export const lineRequirementConstraints = {
    [DetailsFields.VoiceLines]: {
        presence: true,
        type: "number",
    },
    [DetailsFields.DataLines]: {
        type: "number",
    },
    [DetailsFields.VoiceOnlyLines]: {
        type: "number",
    },
};
export const contractDatesConstraints = {
    [DetailsFields.Length]: {
        presence: true,
        inclusion: [12, 24, 36],
    },
    [DetailsFields.VastMajorityLinesRenewal]: {
        presence: true,
        enum: Months,
    },
    [DetailsFields.LinesBeginning]: {
        presence: true,
        type: "number",
    },
    [DetailsFields.LinesWithinThreeMonths]: {
        conditionalPresenceMatchAll: [
            {
                field: "linesLeftThree",
                value: 0,
                inverted: true,
            },
        ],
        type: "number",
    },
    [DetailsFields.LinesWithinSixMonths]: {
        conditionalPresenceMatchAll: [
            {
                field: "linesLeftSix",
                value: 0,
                inverted: true,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.EstimatedLines]: {
        type: "number",
    },
    [DetailsFields.EstimatedDataLines]: {
        type: "number",
    },
};
export const discountsConstraints = {
    [DetailsFields.ATC]: {
        conditionalPresenceMatchAll: [
            {
                field: "hasUsage",
                value: true,
            },
        ],
        type: "number",
    },
    [DetailsFields.ATCUnits]: {
        conditionalPresenceMatchAll: [
            {
                field: "hasUsage",
                value: true,
            },
            {
                field: DetailsFields.ATC,
                value: 0,
                inverted: true,
            },
        ],
        enum: ATCUnits,
    },
    [DetailsFields.TechFund]: {
        type: "number",
    },
};
export const currentCostsConstraints = {
    [DetailsFields.ManualCurrentCost]: {
        presence: true,
        type: "number",
    },
};
export const dataIDDRoamingConstraints = {
    [DetailsFields.OverallData]: {
        presence: true,
        type: "number",
    },
    [DetailsFields.IncreaseUsage]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.Microsoft365]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.IncreaseUsage,
                value: true,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.OnlineTeams]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.IncreaseUsage,
                value: true,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.Zoom]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.IncreaseUsage,
                value: true,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.OtherDataIncrease]: {
        type: "string",
    },
    [DetailsFields.IDDCost]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.IDDCostCountries]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.IDDCost,
                value: true,
            },
        ],
        enumArray: EuCostCountries,
    },
    [DetailsFields.RoamingCost]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.RoamingCostCountries]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.RoamingCost,
                value: true,
            },
        ],
        enumArray: EuCostCountries,
    },
    [DetailsFields.HighCost]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.HighCostCountries]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.HighCost,
                value: true,
            },
        ],
        enumArray: HighCostCountries,
    },
};
export const networkProviderConstraints = {
    [DetailsFields.CurrentNetwork]: {
        presence: true,
        enum: Network,
    },
    [DetailsFields.ChangeNetwork]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.CurrentNetwork,
                value: Network.None,
                inverted: true,
            },
        ],
        enum: ChangeNetwork,
    },
    [DetailsFields.MultipleNetwork]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.Postcodes]: {
        presence: true,
        type: "array",
        length: {
            minimum: 1,
        },
    },
};
export const handsetDevicesConstraints = {
    [DetailsFields.ExistingDevices]: {
        presence: true,
        enum: ExistingDevices,
    },
    [DetailsFields.Locked]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.ExistingDevices,
                value: ExistingDevices.None,
                inverted: true,
            },
        ],
        enum: DevicesLocked,
    },
    [DetailsFields.Handsets]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.SimOnlyFunded]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: false,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
        ],
        enum: SimOnlyFunded,
    },
    [DetailsFields.FiveG]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: true,
            },
        ],
        enum: FiveG,
    },
    [DetailsFields.PickedHandsets]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: true,
            },
        ],
        type: "array",
        length: {
            minimum: 1,
        },
    },
    [DetailsFields.DEP]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: true,
            },
        ],
        enum: DEPChoices,
    },
    [DetailsFields.LikeHardwareFunded]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: true,
            },
        ],
        enum: LikeHardwareFunded,
    },
    [DetailsFields.LeasingType]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.Handsets,
                value: true,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
        ],
        enum: LeasingType,
    },
};
export const mdmConstraints = {
    [DetailsFields.OwnMDM]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.MDMMSInTune]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.OwnMDM,
                value: true,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.MDMInLine]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.OwnMDM,
                value: true,
            },
            {
                field: DetailsFields.MDMMSInTune,
                value: false,
            },
        ],
        enum: MDMInLine,
    },
    [DetailsFields.MDMEnd]: {
        type: "Dayjs",
    },
    [DetailsFields.MDMFee]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.OwnMDM,
                value: true,
            },
            {
                field: DetailsFields.MDMMSInTune,
                value: false,
            },
        ],
        type: "boolean",
    },
    [DetailsFields.MDM]: {
        presence: true,
        enum: ManagerPackageTypes,
    },
};
export const additionalInfoConstraints = {
    [DetailsFields.VATRegistered]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.CharitySocialEnterprise]: {
        presence: true,
        type: "boolean",
    },
    [DetailsFields.ExtraDetails]: {
        type: "string",
    },
};
export const tenderConstraints = {
    ...lineRequirementConstraints,
    ...contractDatesConstraints,
    ...discountsConstraints,
    ...currentCostsConstraints,
    ...dataIDDRoamingConstraints,
    ...networkProviderConstraints,
    ...handsetDevicesConstraints,
    ...mdmConstraints,
    ...additionalInfoConstraints,
};
export const handsetConstraints = {
    [DetailsFields.PickedHandsets]: {
        presence: true,
    },
    [DetailsFields.NumberHandsets]: {
        presence: true,
        type: "number",
    },
    [DetailsFields.OSSpreadHandsets]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.PickedHandsets,
                value: DeviceRange.Mid,
            },
            {
                field: DetailsFields.PickedHandsets,
                value: DeviceRange.High,
            },
        ],
        type: "number",
    },
};
export const validateHandsets = (handsets = [], handsetsRequired) => {
    const errorsArray = [];
    let data = handsets;
    if (handsetsRequired && data.length === 0) {
        data = [{}];
    }
    let index = 0;
    for (const handset of data) {
        try {
            validation(handset, handsetConstraints);
        }
        catch (error) {
            errorsArray[index] = error.fails;
        }
        index++;
    }
    return errorsArray;
};
