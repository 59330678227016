import React from "react";
import { ChatWho } from "@Components/ChatBot";
import PredictyIcon from "@Components/Icons/ChatBot/Predicty";
import PredictyIconTyping from "@Components/Icons/ChatBot/Predicty/PredictyTyping";
import styles from "./index.module.scss";
export default class Avatar extends React.Component {
    findWho = (who) => {
        switch (who) {
            case ChatWho.PredictyBot:
                return (React.createElement("div", { className: `${styles.avatar} ${styles.predicty}` },
                    this.props.typing === true && (React.createElement(PredictyIconTyping, { className: styles.logo })),
                    this.props.typing !== true && (React.createElement(PredictyIcon, { className: styles.logo }))));
            case ChatWho.Staff:
            case ChatWho.Customer:
            case ChatWho.Supplier:
                return (React.createElement("div", { className: `${styles.avatar} ${styles.customer}` }, this.props.avatar));
        }
    };
    render() {
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.avatarFiller }),
            this.findWho(this.props.who)));
    }
}
