// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subHeaderText--HYY0P{font-weight:var(--title-font-weight);font-size:1.1rem;font-family:\"NettoPro\",sans-serif;line-height:1.3rem;letter-spacing:.08rem;font-weight:600}.subHeaderText--HYY0P .highlighted--Q58ZV{color:var(--primary-color)}.content--FlAXF{height:100%;overflow-y:auto;background-color:var(--main-background-color);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Register/Sensabill/index.module.scss"],"names":[],"mappings":"AAAA,sBAAe,oCAAoC,CAAC,gBAAgB,CAAC,iCAAiC,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,eAAe,CAAC,0CAA4B,0BAA0B,CAAC,gBAAS,WAAW,CAAC,eAAe,CAAC,6CAA6C,CAAC,2EAA2E","sourcesContent":[".subHeaderText{font-weight:var(--title-font-weight);font-size:1.1rem;font-family:\"NettoPro\",sans-serif;line-height:1.3rem;letter-spacing:.08rem;font-weight:600}.subHeaderText .highlighted{color:var(--primary-color)}.content{height:100%;overflow-y:auto;background-color:var(--main-background-color);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeaderText": "subHeaderText--HYY0P",
	"highlighted": "highlighted--Q58ZV",
	"content": "content--FlAXF"
};
export default ___CSS_LOADER_EXPORT___;
