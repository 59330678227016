const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export default (original = {}) => {
    const converted = [version1].reduce((converted, f) => f(converted), original);
    const { ukIDDToEurope, ukIDDToROW, ukIDDToUSAandCanada, ukIDDToAPAC, ukToInternational, pictureMsg, chargeable08, UKUsageUkIDDToEurope, UKUsageUkIDDToROW, UKUsageUkIDDToUSAandCanada, UKUsageUkIDDToAPAC, UKUsageUkToInternational, UKUsagePictureMsg, UKUsageChargeable08, } = converted;
    return {
        ukIDDToEurope,
        ukIDDToROW,
        ukIDDToUSAandCanada,
        ukIDDToAPAC,
        ukToInternational,
        pictureMsg,
        chargeable08,
        UKUsageUkIDDToEurope,
        UKUsageUkIDDToROW,
        UKUsageUkIDDToUSAandCanada,
        UKUsageUkIDDToAPAC,
        UKUsageUkToInternational,
        UKUsagePictureMsg,
        UKUsageChargeable08,
    };
};
