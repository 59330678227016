import React from "react";
import Icon from "@Components/Icons/Tooltip";
import styles from "./index.module.scss";
export default class Tooltip extends React.Component {
    border;
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }
    handleClick = () => {
        this.setState({ visible: !this.state.visible });
    };
    closeClick = () => {
        this.setState({ visible: false });
    };
    render() {
        if (this.state.visible === true) {
            setTimeout((this.closeClick = () => {
                this.setState({ visible: false });
            }), 15000);
        }
        const border = this.props.border === false ? "" : styles.border;
        return (React.createElement(React.Fragment, null,
            React.createElement(Icon, { onClick: this.handleClick, size: "0.8em" }),
            this.state.visible && (React.createElement("div", { className: styles.tooltip },
                React.createElement("div", { className: border }, this.props.children)))));
    }
}
