import React from "react";
import { register, upload } from "@Api/Registration";
import AppSwitch from "@Components/AppSwitch";
import Header from "@Components/Header";
import getSubdomain from "@Helpers/GetSubdomain";
import { ValidationException } from "@Shared/Exceptions";
import TenderEditLocalStorage from "@SmartComponents/TenderEdit/LocalStorage";
export var Stage;
(function (Stage) {
    Stage[Stage["Usage"] = 0] = "Usage";
    Stage[Stage["Details"] = 1] = "Details";
})(Stage || (Stage = {}));
class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            haveUsage: false,
            details: {},
            redirectTo: "",
            stage: Stage.Usage,
            subdomain: undefined,
        };
    }
    componentDidMount() {
        const subdomain = getSubdomain();
        switch (subdomain) {
            case "era":
            case "7ss":
                this.setState({
                    redirectTo: "auth/login",
                });
                break;
            //add white labels here
            case "chambercompare":
                this.setState({
                    subdomain,
                });
        }
    }
    usageSubmit({ platform, files, haveUsage = true }) {
        this.setState({
            haveUsage,
            network: platform,
            files,
        }, () => this.updateStage(Stage.Details));
    }
    nextPage() {
        this.updateStage(Stage.Details);
    }
    detailsSubmit(details) {
        const { network, files = [], haveUsage, subdomain } = this.state;
        return register({
            network,
            details,
            haveUsage,
            numFiles: files.length,
            subdomain,
        })
            .then(async ({ customer, usage, tender }) => {
            if (usage !== null) {
                await upload(customer.id, usage.id, files);
            }
            return `/customer/tenders/${tender.id}`;
        })
            .then((redirectTo) => {
            // "RegisterDetails" uses a Form component we need to return callback
            // to update state
            return () => {
                this.setState({
                    redirectTo,
                });
            };
        })
            .catch((error) => {
            if (!(error instanceof ValidationException)) {
                throw error;
            }
        });
    }
    updateStage = (stage) => {
        this.setState({
            stage,
        });
    };
    backToDetails = () => {
        this.updateStage(Stage.Details);
    };
    render() {
        if (this.state.redirectTo !== "") {
            return React.createElement(AppSwitch, { uri: this.state.redirectTo });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, null, "Welcome to Predict, the Comparison Platform for Business Mobile"),
            React.createElement(TenderEditLocalStorage, { loaded: this.props.loaded })));
    }
}
export default RegisterForm;
