import AbstractCustomer from "@Shared/Entities/AbstractEntities/Customer";
import { CustomerType } from "@Shared/Entities/AbstractEntities/Customer/enums";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Customer extends AbstractCustomer {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get type() {
        return CustomerType.Predict;
    }
    get baseUrl() {
        return `${super.baseUrl}/customers/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        return { ...parent, version };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
            users: `${this.baseUrl}/users`,
            contracts: `${this.baseUrl}/contracts`,
            tenders: `${this.baseUrl}/tenders`,
        };
    }
}
