import { ATCUnits, DetailsFields, DeviceRange, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Sections } from "@Shared/Entities/Customer/Tender/enums";
import { getRemainingLines } from "@Shared/Helpers/TenderLines";
export const getData = (tender, ignoreAdditionalInfo = true, section) => {
    const { details } = tender || {};
    const { linesLeftThree, linesLeftSix } = getRemainingLines(tender);
    const hasUsage = tender?.usageId !== undefined;
    const pickedHandsets = getPickedHandsets(details);
    const highCostCountries = details.data[DetailsFields.HighCostCountries] || [];
    const postcodes = details.data[DetailsFields.Postcodes] || [];
    const defaults = {};
    let sectionArray = [];
    sectionArray =
        section === Sections.SubmitTender
            ? Object.values(Sections).filter((s) => !Number.isNaN(s))
            : [section];
    for (const sec of sectionArray) {
        switch (Number(sec)) {
            default:
            case Sections.LineRequirements:
                defaults[DetailsFields.VoiceLines] =
                    details.data[DetailsFields.VoiceLines];
                defaults[DetailsFields.DataLines] =
                    details.data[DetailsFields.DataLines];
                break;
            case Sections.ContractEndDates:
                defaults[DetailsFields.Length] =
                    details.data[DetailsFields.Length];
                defaults[DetailsFields.VastMajorityLinesRenewal] =
                    details.data[DetailsFields.VastMajorityLinesRenewal];
                defaults[DetailsFields.LinesBeginning] =
                    details.data[DetailsFields.LinesBeginning];
                defaults[DetailsFields.LinesWithinThreeMonths] =
                    linesLeftThree > 0
                        ? details.data[DetailsFields.LinesWithinThreeMonths]
                        : 0;
                defaults[DetailsFields.LinesWithinSixMonths] =
                    linesLeftSix > 0
                        ? details.data[DetailsFields.LinesWithinSixMonths] ||
                            false
                        : undefined;
                defaults[DetailsFields.EstimatedLines] =
                    details.data[DetailsFields.EstimatedLines];
                break;
            case Sections.CurrentCostsOrDiscountsAndBenefits:
                defaults[DetailsFields.ManualCurrentCost] =
                    details.data[DetailsFields.ManualCurrentCost] || 0;
                defaults[DetailsFields.ATC] =
                    details.data[DetailsFields.ATC] || 0;
                defaults[DetailsFields.ATCUnits] = getATCUnits(details.data[DetailsFields.ATC], details.data[DetailsFields.ATCUnits]);
                break;
            case Sections.DataInternationalCallsAndRoaming:
                defaults[DetailsFields.OverallData] =
                    details.data[DetailsFields.OverallData];
                defaults[DetailsFields.IncreaseUsage] =
                    details.data[DetailsFields.IncreaseUsage] || false;
                defaults[DetailsFields.Microsoft365] = details.data[DetailsFields.IncreaseUsage]
                    ? details.data[DetailsFields.Microsoft365] || false
                    : undefined;
                defaults[DetailsFields.OnlineTeams] = details.data[DetailsFields.IncreaseUsage]
                    ? details.data[DetailsFields.OnlineTeams] || false
                    : undefined;
                defaults[DetailsFields.Zoom] = details.data[DetailsFields.IncreaseUsage]
                    ? details.data[DetailsFields.Zoom] || false
                    : undefined;
                defaults[DetailsFields.IDDCost] =
                    details.data[DetailsFields.IDDCost] || false;
                defaults[DetailsFields.RoamingCost] =
                    details.data[DetailsFields.RoamingCost] || false;
                defaults[DetailsFields.HighCost] =
                    details.data[DetailsFields.HighCost] || false;
                defaults[DetailsFields.HighCostCountries] =
                    highCostCountries.length === 0
                        ? undefined
                        : highCostCountries;
                break;
            case Sections.NetworkProviderOptions:
                defaults[DetailsFields.CurrentNetwork] =
                    details.data[DetailsFields.CurrentNetwork];
                defaults[DetailsFields.ChangeNetwork] =
                    details.data[DetailsFields.ChangeNetwork];
                defaults[DetailsFields.Postcodes] =
                    postcodes.length === 0 ? undefined : postcodes;
                break;
            case Sections.HandsetsAndDevicesOptions:
                defaults[DetailsFields.ExistingDevices] =
                    details.data[DetailsFields.ExistingDevices];
                defaults[DetailsFields.Locked] =
                    details.data[DetailsFields.Locked];
                defaults[DetailsFields.Handsets] =
                    details.data[DetailsFields.Handsets];
                defaults[DetailsFields.SimOnlyFunded] =
                    details.data[DetailsFields.SimOnlyFunded];
                defaults[DetailsFields.PickedHandsets] =
                    pickedHandsets.length === 0 ? undefined : pickedHandsets;
                defaults[DetailsFields.LikeHardwareFunded] =
                    details.data[DetailsFields.LikeHardwareFunded];
                defaults[DetailsFields.LeasingType] =
                    details.data[DetailsFields.LeasingType];
                break;
            case Sections.MDM:
                defaults[DetailsFields.MDM] = details.data[DetailsFields.MDM];
                break;
            case Sections.AdditionalInfo:
                defaults[DetailsFields.VATRegistered] =
                    details.data[DetailsFields.VATRegistered] || false;
                defaults[DetailsFields.CharitySocialEnterprise] =
                    ignoreAdditionalInfo
                        ? details.data[DetailsFields.CharitySocialEnterprise]
                        : details.data[DetailsFields.CharitySocialEnterprise] ||
                            false;
                defaults[DetailsFields.ExtraDetails] =
                    details.data[DetailsFields.ExtraDetails];
                break;
        }
    }
    return {
        ...details.data,
        ...defaults,
        hasUsage,
        linesLeftThree,
        linesLeftSix,
    };
};
export const getPickedHandsets = (details) => {
    return (details.data[DetailsFields.PickedHandsets] || []).map((handset) => {
        if (handset[DetailsFields.PickedHandsets] === DeviceRange.Mid ||
            handset[DetailsFields.PickedHandsets] === DeviceRange.High) {
            const osSpread = handset[DetailsFields.OSSpreadHandsets];
            handset[DetailsFields.OSSpreadHandsets] = osSpread || 0;
        }
        return handset;
    });
};
const getATCUnits = (atc, atcUnits) => {
    if (atc !== undefined && atc >= 100) {
        return ATCUnits.Pounds;
    }
    return atcUnits;
};
export const usagePercentageTarget = 99;
