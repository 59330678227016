import SVG from "@Components/Icons/SVG";
export default class AdminUsers extends SVG {
    path = [
        "M 62.58 74.79 ",
        "L 48.09 67.39",
        "V 63.74",
        "L 49.04 63.21",
        "C 54.62 60.16 58.58 55.08 60.79 48.13",
        "L 61.04 47.43",
        "L 61.71 47.08",
        "C 64.72 45.46 66.62 42.38 66.62 38.97",
        "V 35.29",
        "C 66.62 32.45 65.32 29.82 63.07 28.06",
        "L 62.51 27.61",
        "L 62.41 26.91",
        "C 60.23 13.75 51.32 7.37 35.19 7.37",
        "C 34.45 7.37 33.71 7.4 32.98 7.44",
        "C 29.47 7.72 24.35 7.4 19.86 4.38",
        "C 18.17 3.26 16.87 2.14 16.03 1.05",
        "C 15.51 0.35 14.77 0 14 0",
        "C 13.68 0 13.33 0.07 13.01 0.21",
        "C 11.93 0.67 11.3 1.75 11.44 2.91",
        "C 11.58 4.24 11.82 5.82 12.14 7.51",
        "C 12.91 11.37 12.94 11.79 11.72 14.42",
        "C 11.26 15.4 10.84 16.31 10.35 17.5",
        "C 9.19 20.35 8.35 23.5 7.89 26.84",
        "L 7.79 27.57",
        "L 7.23 28.03",
        "C 5.02 29.78 3.72 32.41 3.72 35.22",
        "V 38.94",
        "V 18.72",
        "V 42.34",
        "V 20.58",
        "V 45.46",
        "V 23.59",
        "V 47.08",
        "L 9.26 47.43",
        "L 9.51 48.13",
        "C 11.72 55.11 15.68 60.16 21.26 63.21",
        "L 22.24 63.74",
        "V 67.35",
        "L 7.26 74.72 ",
        "C 2.81 77.18 0 81.88 0 87",
        "V 91.91",
        "C 0 97.28 11.82 99.98 35.15 99.98 S 70.3 97.28 70.3 91.91",
        "V 87.31",
        "C 70.3 81.98 67.35 77.18 62.58 74.79",
        "Z",
        "M 66.62 92.08",
        "L 65.74 92.61",
        "C 63 94.3 53.32 96.22 35.19 96.22",
        "C 17.01 96.22 7.37 94.26 4.63 92.61",
        "L 3.75 92.08",
        "V 87",
        "C 3.75 83.28 5.79 79.77 8.98 78.05",
        "L 23.33 71",
        "C 24.94 70.23 25.96 68.55 25.96 66.79",
        "V 61.32",
        "L 24.84 60.83",
        "C 18.66 58.2 14.66 53.18 12.63 45.5",
        "L 12.35 44.52",
        "L 11.37 44.2",
        "C 9.05 43.5 7.44 41.32 7.44 38.94",
        "V 35.26",
        "C 7.44 33.22 8.56 31.36 10.42 30.38",
        "L 11.3 29.92",
        "L 11.4 28.94",
        "C 11.79 25.29 12.59 21.93 13.82 18.91",
        "C 14.31 17.68 14.77 16.73 15.12 16",
        "C 16.31 13.4 16.59 12.38 16.35 10.03",
        "L 15.96 6.42",
        "L 19.12 8.24",
        "C 23.12 10.52 28.03 11.54 33.33 11.12",
        "C 33.92 11.09 34.59 11.05 35.22 11.05",
        "C 49.99 11.05 57.57 16.73 59 28.98",
        "L 59 41",
        "C 57 44 50 48 40 50",
        "L 40 48 C 36 46 34 46 30 48",
        "C 29 51 29 52 30 55",
        "C 34 57 36 57 40 55",
        "L 40 53",
        "C 48 51 51 50 58 46",
        "C 55.74 53.15 51.74 58.16 45.57 60.83",
        "L 44.45 61.32",
        "V 66.79",
        "C 44.45 68.55 45.43 70.16 47.01 70.97",
        "L 60.93 78.09",
        "C 64.44 79.84 66.62 83.39 66.62 87.31",
        "V 92.08 ",
        "Z",
    ];
}
