import { Effected, TermLength, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const additionalLinesPresenceConstraints = {
    [Effected.AdditionalLinesCoTerminus]: {
        presence: true,
    },
    [Effected.TimeLeftTerm]: {
        conditionalPresenceMatchAll: [
            { field: Effected.AdditionalLinesCoTerminus, value: true },
        ],
    },
    [Effected.HWFundPerLine]: {
        presence: true,
    },
    [Effected.HWFundPerDataSim]: {
        presence: true,
    },
    [Effected.HWProRata]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalLinesCoTerminus,
                value: true,
            },
        ],
    },
};
export const additionalLinesValidConstraints = {
    [Effected.AdditionalLinesCoTerminus]: {
        presence: true,
        type: "boolean",
    },
    [Effected.TimeLeftTerm]: {
        conditionalPresenceMatchAll: [
            { field: Effected.AdditionalLinesCoTerminus, value: true },
        ],
        enum: TermLength,
    },
    [Effected.HWFundPerLine]: {
        presence: true,
        type: "number",
    },
    [Effected.HWFundPerDataSim]: {
        presence: true,
        type: "number",
    },
    [Effected.HWProRata]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalLinesCoTerminus,
                value: true,
            },
        ],
        type: "boolean",
    },
    [Effected.AdditionalLinesInfo]: {
        type: "string",
    },
};
export const additionalLinesCostValidConstraints = {
    [Effected.AdditionalLineCost]: {
        type: "number",
        conditionalPresenceMatchAll: [
            {
                field: "lines",
                value: 0,
                inverted: true,
            },
            {
                field: "lines",
                value: undefined,
                inverted: true,
            },
        ],
        numericality: {
            greaterThan: 0,
        },
    },
    [Effected.AdditionalDataLineCost]: {
        type: "number",
        conditionalPresenceMatchAll: [
            {
                field: "dataLines",
                value: 0,
                inverted: true,
            },
            {
                field: "dataLines",
                value: undefined,
                inverted: true,
            },
        ],
        numericality: {
            greaterThan: 0,
        },
    },
};
