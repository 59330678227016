import React from "react";
import bionic from "@Components/Icons/Partners/Bionic/Logo/bionic.svg";
import TextLogo from "@Components/Icons/Partners/ERA/Logo/logo.png";
import Protel from "@Components/Icons/Partners/Protel/TextLogo/protel.png";
import SevenStepSolutions from "@Components/Icons/Partners/SevenStepSolutions/TextLogo/logo.png";
import TMLogo from "@Components/Icons/Partners/TechMahindra/TextLogo/logo.png";
import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
import bdo from "../../Partners/BDO/bdo.png";
import styles from "./index.module.scss";
import logo from "./logo.svg";
export default class LogoText extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        const icon = () => {
            const broker = getBrokerDomain();
            switch (broker) {
                case BrokerDomain.ERA:
                    return TextLogo;
                case BrokerDomain.TechMahindra:
                    return TMLogo;
                case BrokerDomain.SevenStepSolutions:
                    return SevenStepSolutions;
                case BrokerDomain.Bionic:
                    return bionic;
                case BrokerDomain.BDO:
                    return bdo;
                case BrokerDomain.Protel:
                    return Protel;
                default:
                    return logo;
            }
        };
        return (React.createElement("img", { src: icon(), alt: "Predict Mobile", className: `${styles.logo} ${this.props.className}`, onLoad: this.props.onLoad }));
    }
}
