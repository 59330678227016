import axios from "axios";
import { uploaded } from "@Api/Customers/usage";
import api from "@Api/index";
import Factory from "@Shared/Factory";
export const register = (register) => {
    return api.post(`register/customer`, register).then((result) => {
        const { data: { token, user, customer, usage, tender }, } = result;
        localStorage.setItem("token", token);
        let u = null;
        try {
            u = Factory.Usage(usage.item);
        }
        catch { }
        return {
            user: Factory.CustomerUser(user.item),
            customer: Factory.Customer(customer.item),
            tender: Factory.Tender(tender.item),
            usage: u,
        };
    });
};
export const sensabillRegister = (registerDate) => {
    return api
        .post(`register/sensabill-customer`, registerDate)
        .then((result) => {
        const { data: { token, user, customer, usage }, } = result;
        localStorage.setItem("token", token);
        return {
            user: Factory.CustomerUser(user.item),
            customer: Factory.Customer(customer.item),
            usage: Factory.Usage(usage.item),
        };
    });
};
const fileReader = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const typedArray = new Uint8Array(arrayBuffer);
    return typedArray;
};
export const uploadFilesWithSignedUrl = async (filesSignedData, files) => {
    return await Promise.all(filesSignedData.map(async (file) => {
        const toUpload = files.find(({ name }) => name === file.filename);
        if (!toUpload) {
            return;
        }
        const result = await fileReader(toUpload);
        await axios.put(file.url, result, {
            headers: {
                "Content-Type": file.mime,
            },
        });
        return result;
    }));
};
export const upload = async (customerId, usageId, orgFiles) => {
    if (orgFiles.length === 0)
        return;
    const files = orgFiles.map((file) => {
        return {
            filename: file.name,
            mime: file.type,
            date: file.date,
        };
    });
    let fileDataArray = [];
    try {
        const { data } = await api.post(`upload/${customerId}/usages/${usageId}`, files);
        fileDataArray = await uploadFilesWithSignedUrl(data, orgFiles);
    }
    catch (error) {
        // keep files in predict S3 temporarily and upload with admin tools when service is restored
        const { message = "", stack, name } = error;
        const { data } = await api.post(`upload/${customerId}/usages/${usageId}/failed`, { error: { name, message, stack }, files: files });
        fileDataArray = await uploadFilesWithSignedUrl(data, orgFiles);
    }
    await saveUploadedRows(customerId, usageId, fileDataArray);
};
const saveUploadedRows = (customerId, usageId, fileDataArray) => {
    const utf8decoder = new TextDecoder();
    let totalRowCount = 0;
    for (const fileData of fileDataArray) {
        const csvString = utf8decoder.decode(fileData);
        const rowCount = csvString.split("\n").length - 1;
        totalRowCount += rowCount;
    }
    return uploaded(customerId, usageId, totalRowCount);
};
export const findCompany = (companyNo) => {
    return api
        .get(`register/company-house/${companyNo}`)
        .then(({ data }) => data)
        .catch(({ response }) => {
        throw response.data;
    });
};
export const findTenderDetails = (tenderId) => {
    return api
        .get(`register/tender-details/${tenderId}`)
        .then(({ data }) => data)
        .catch(({ response }) => {
        throw response.data;
    });
};
export const registerIncumbent = (details) => {
    return api
        .post("/register/incumbent-supplier", details)
        .then((result) => {
        const { data: { token, tender }, } = result;
        localStorage.setItem("token", token);
        return Factory.Tender(tender.item);
    })
        .catch(({ response }) => {
        throw response.data;
    });
};
export const findIncumbentEmail = (tenderId, email) => {
    return api
        .get(`register/incumbent-supplier/${tenderId}/${email}`)
        .then(({ data }) => data)
        .catch(({ response }) => {
        throw response.data;
    });
};
