import React from "react";
import { Link } from "react-router-dom";
import styles from "@Components/Table/Display/index.module.scss";
export function TBody(props) {
    const { fields, data, selected, link, showSelect } = props;
    const alternate = props.alternate === true ? styles.alternate : "";
    const totalCost = props.totalCost === true ? styles.totalCost : "";
    const { light } = props;
    return (React.createElement("tbody", null, data.map((datum) => {
        return (React.createElement("tr", { key: datum.tableRowKey, className: `${alternate} ${datum.className || ""}`, style: datum.style },
            showSelect && (React.createElement("td", { className: light ? styles.light : "" },
                React.createElement("input", { type: "checkbox", value: datum.tableRowKey, onChange: props.toggleSelectRow, checked: selected.includes("" + datum.tableRowKey) }))),
            fields.map(({ field, leftAlign, rightAlign, className = "", noLink, display = (data) => {
                let value = data[field];
                if (value === undefined) {
                    value = "";
                }
                return value;
            }, }) => {
                const displayed = (() => {
                    const value = display(datum);
                    if (link !== null && !noLink) {
                        return (React.createElement(Link, { to: link(datum) }, value));
                    }
                    return value;
                })();
                if (typeof className === "function") {
                    className = className(datum);
                }
                return (React.createElement("td", { key: `${datum.tableRowKey}-${field}`, className: `${totalCost} ${leftAlign
                        ? styles.leftAlign
                        : rightAlign
                            ? styles.rightAlign
                            : styles.center} ${props.noPadding
                        ? styles.noPadding
                        : ""} ${light ? styles.light : ""} ${className}`, style: {
                        padding: props.padding,
                    } }, displayed));
            })));
    })));
}
export default TBody;
