import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import CompanyDetails from "@Shared/Entities/AbstractEntities/Company/Details";
import { CustomerType } from "@Shared/Entities/AbstractEntities/Customer/enums";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import Action from "@Shared/Entities/Action";
import AdminUser from "@Shared/Entities/Admin/User";
import AwardedContract from "@Shared/Entities/AwardedContract";
import Customer from "@Shared/Entities/Customer";
import Comparison from "@Shared/Entities/Customer/Comparison";
import Tender from "@Shared/Entities/Customer/Tender";
import TenderDetails from "@Shared/Entities/Customer/Tender/Details";
import Usage from "@Shared/Entities/Customer/Usage";
import UsageBreakdown from "@Shared/Entities/Customer/Usage/Breakdown";
import UsageStats from "@Shared/Entities/Customer/Usage/Stats";
import CustomerUser from "@Shared/Entities/Customer/User";
import Device from "@Shared/Entities/Hardware/Device";
import PriceRange from "@Shared/Entities/Hardware/PriceRange";
import Partner from "@Shared/Entities/Partner";
import PartnerCustomer from "@Shared/Entities/Partner/Customer";
import PartnerUser from "@Shared/Entities/Partner/User";
import SensabillClient from "@Shared/Entities/SensabillClient";
import Supplier from "@Shared/Entities/Supplier";
import CostPerItem from "@Shared/Entities/Supplier/CostPerItem";
import Response from "@Shared/Entities/Supplier/Response";
import Meta from "@Shared/Entities/Supplier/Response/Meta";
import Proposal from "@Shared/Entities/Supplier/Response/Proposal";
import Bundle from "@Shared/Entities/Supplier/Response/Proposal/Bundle";
import Scores from "@Shared/Entities/Supplier/Response/Scores";
import SupplierUser from "@Shared/Entities/Supplier/User";
import { NotFoundException } from "@Shared/Exceptions";
const build = (data, entity) => {
    if (data instanceof entity) {
        return data;
    }
    if (data?.item) {
        return new entity(data.item);
    }
    return new entity(data);
};
const _data = (data) => {
    if (data instanceof BaseEntity) {
        return data.data;
    }
    if (data?.item) {
        return data.item;
    }
    return data;
};
export default {
    Action(data) {
        return build(data, Action);
    },
    AdminUser(data) {
        return build(data, AdminUser);
    },
    AwardedContract(data) {
        return build(data, AwardedContract);
    },
    Bundle(data) {
        return build(data, Bundle);
    },
    Partner(data) {
        return build(data, Partner);
    },
    PartnerUser(data) {
        return build(data, PartnerUser);
    },
    Comparison(data) {
        return build(data, Comparison);
    },
    CompanyDetails(data) {
        return build(data, CompanyDetails);
    },
    Usage(data) {
        return build(data, Usage);
    },
    UsageStats(data) {
        return build(data, UsageStats);
    },
    UsageBreakdown(data) {
        return build(data, UsageBreakdown);
    },
    Costs(data) {
        return build(data, Meta);
    },
    CustomerUser(data) {
        return build(data, CustomerUser);
    },
    SensabillClient(data) {
        return build(data, SensabillClient);
    },
    Device(data) {
        return build(data, Device);
    },
    Scores(data) {
        return build(data, Scores);
    },
    SupplierUser(data) {
        return build(data, SupplierUser);
    },
    Supplier(data) {
        return build(data, Supplier);
    },
    Tender(data) {
        return build(data, Tender);
    },
    TenderDetails(data) {
        return build(data, TenderDetails);
    },
    Response(data) {
        return build(data, Response);
    },
    PriceRange(data) {
        return build(data, PriceRange);
    },
    Proposal(data) {
        return build(data, Proposal);
    },
    CostPerItem(data) {
        return build(data, CostPerItem);
    },
    User(data) {
        data = _data(data);
        const { type, } = data;
        switch (type) {
            case UserType.Admin:
                return this.AdminUser(data);
            case UserType.Supplier:
                return this.SupplierUser(data);
            case UserType.Customer:
                return this.CustomerUser(data);
            case UserType.Partner:
            case UserType.White_Label:
                return this.PartnerUser(data);
            default:
                console.log(data);
                throw new NotFoundException(`Unknown User type`);
        }
    },
    PredictCustomer(data) {
        return build(data, Customer);
    },
    PartnerCustomer(data) {
        return build(data, PartnerCustomer);
    },
    WhiteLabelCustomer(data) {
        return build(data, Customer);
    },
    Customer(data) {
        data = _data(data);
        const { type, } = data;
        switch (type) {
            case CustomerType.Broker:
                return this.PartnerCustomer(data);
            case CustomerType.WhiteLabel:
                return this.WhiteLabelCustomer(data);
            // case CustomerType.Predict:
            default:
                return this.PredictCustomer(data);
            // throw new NotFoundException(`Can't find customer`)
        }
    },
};
