// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip--LhP29{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);margin-top:var(--standard-vertical-padding);color:var(--text-color);font-size:.85rem;background:#fff}.tooltip--LhP29 .border--jt0ci{border-radius:.2em;box-shadow:0 1px 5px 2px rgba(0,0,0,.2);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Components/Tooltip/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,2EAA2E,CAAC,2CAA2C,CAAC,uBAAuB,CAAC,gBAAgB,CAAC,eAAe,CAAC,+BAAiB,kBAAkB,CAAC,uCAAuC,CAAC,2EAA2E","sourcesContent":[".tooltip{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);margin-top:var(--standard-vertical-padding);color:var(--text-color);font-size:.85rem;background:#fff}.tooltip .border{border-radius:.2em;box-shadow:0 1px 5px 2px rgba(0,0,0,.2);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip--LhP29",
	"border": "border--jt0ci"
};
export default ___CSS_LOADER_EXPORT___;
