import React from "react";
import styles from "./index.module.scss";
export default class O2Icon extends React.Component {
    render() {
        return (React.createElement("svg", { viewBox: "0 0 150 150", xmlns: "http://www.w3.org/2000/svg", className: `${this.props.size ? "" : styles.wrapper} ${this.props.className || ""}`, style: { width: this.props.size } },
            React.createElement("title", null, "O2 Logo"),
            React.createElement("path", { className: styles.o2, d: "M 59.336 15.88 C 70.464 15.28 82 18.008 91.016 24.736 C 99.64 31.04 105.52 40.688 107.928 51.032 A 56.84 56.84 90 0 1 107.864 77.344 C 104.76 90.008 96.344 101.416 84.736 107.528 C 76.752 111.816 67.488 113.536 58.472 112.824 A 45.864 45.864 90 0 1 31.304 101.712 C 22.192 93.784 16.592 82.216 15.312 70.288 C 14.24 59.728 15.904 48.76 20.912 39.36 A 43.28 43.28 90 0 1 41.76 19.936 A 50.312 50.312 90 0 1 59.336 15.88 Z M 58.512 29.912 A 26.552 26.552 90 0 0 41.536 39.584 C 35.568 46.896 32.912 56.552 33.248 65.896 C 33.52 74.264 35.992 82.792 41.28 89.384 A 25.712 25.712 90 0 0 57.232 98.704 C 63.688 99.752 70.616 98.888 76.264 95.456 C 82.28 91.88 86.456 85.808 88.736 79.304 C 92.232 69.264 92.136 57.904 88 48.064 C 85.36 41.752 80.856 36.04 74.776 32.744 C 69.856 30.04 64.056 29.184 58.512 29.912 Z" }),
            React.createElement("path", { className: styles.o2, d: "M 108.496 94.888 A 27.584 27.584 90 0 1 122.312 92.016 C 125.568 92.256 128.976 93.384 131.112 95.984 C 133.64 99.096 134.056 103.456 133.224 107.264 C 131.744 112.896 127.816 117.424 123.904 121.56 C 122.016 123.52 120.088 125.408 118.12 127.272 C 123.704 127.312 129.288 127.272 134.88 127.296 C 134.848 129.424 134.88 131.56 134.88 133.696 Q 121.232 133.696 107.6 133.696 V 126.856 C 112.8 121.984 118.248 117.256 122.352 111.376 C 123.784 109.24 125.16 106.912 125.352 104.288 A 5.048 5.048 90 0 0 123.16 99.728 C 121.128 98.312 118.496 98.32 116.144 98.704 C 113.632 99.208 111.328 100.392 109.184 101.736 C 108.952 99.456 108.752 97.168 108.496 94.888 Z" })));
    }
}
