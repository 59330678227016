import { getBillingEngines } from "@Api/BillingEngine";
import Button from "@Components/Button";
import Form, { InputType } from "@Components/Form";
import DragAndDrop from "@Components/Icons/DragAndDrop";
import { validation } from "@Shared/Validate";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
export function Auto(props) {
    const [billingEngines, setBillingEngines] = useState([]);
    const init = async () => {
        let engines = await getBillingEngines();
        engines = engines
            .filter((platform) => platform.engine !== 0)
            .map((platform) => {
            return {
                value: platform.engine,
                label: (React.createElement("div", null,
                    React.createElement("img", { src: platform.icon, className: styles.billingEngineIcon }),
                    platform.name)),
            };
        });
        setBillingEngines(engines);
    };
    useEffect(() => {
        init();
    }, []);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const submitPlatform = () => {
        try {
            validation(data, {
                username: {
                    presence: true,
                    type: "string",
                    length: {
                        minimum: 1,
                    },
                },
                password: {
                    presence: true,
                    type: "string",
                    length: {
                        minimum: 1,
                    },
                },
                platform: {
                    presence: true,
                },
            });
            props.submit(data);
        }
        catch (error) {
            setErrors(error.fails);
        }
    };
    return (React.createElement("div", { className: `${styles.card}` },
        React.createElement("div", { className: styles.titleContainer },
            React.createElement("div", { className: styles.icon },
                React.createElement(DragAndDrop, null)),
            React.createElement("h4", { className: styles.title }, "Auto retrieve your Bill Reports")),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.middle },
                React.createElement(Form, { change: setData, className: styles.body, data: data, errors: errors, buttons: false, input: [
                        {
                            label: "Who is your current network provider?",
                            name: "platform",
                            type: InputType.DropdownOptions,
                            options: billingEngines,
                        },
                        {
                            label: "Username",
                            name: "username",
                            type: InputType.Text,
                        },
                        {
                            label: "Password",
                            name: "password",
                            type: InputType.Password,
                            showPassword: true,
                        },
                        {
                            label: "Does your login have 2FA?",
                            name: "twoFA",
                            type: InputType.SmallToggle,
                        },
                    ] })),
            React.createElement("div", { className: styles.bottom },
                React.createElement(Button, { arrow: true, cta: true, click: submitPlatform }, "CONNECT TO REPORTS")))));
}
export default Auto;
