// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o2plan--Bk1cb{width:3rem}.cls0--rk5s2{fill:#0112aa}.st0--s5397{fill:#169bed}.st1--gP37F{fill:#fff}.st2--Aya54{fill:#000}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/O2Plan.com/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,UAAU,CAAC,aAAM,YAAY,CAAC,YAAK,YAAY,CAAC,YAAK,SAAS,CAAC,YAAK,SAAS","sourcesContent":[".o2plan{width:3rem}.cls0{fill:#0112aa}.st0{fill:#169bed}.st1{fill:#fff}.st2{fill:#000}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"o2plan": "o2plan--Bk1cb",
	"cls0": "cls0--rk5s2",
	"st0": "st0--s5397",
	"st1": "st1--gP37F",
	"st2": "st2--Aya54"
};
export default ___CSS_LOADER_EXPORT___;
