// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--t4d19{position:relative;align-self:center}.wrapper--t4d19 .item--fh3Xa{position:absolute;top:0;left:0}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Hardware/KeepingAll/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,iBAAiB,CAAC,iBAAiB,CAAC,6BAAe,iBAAiB,CAAC,KAAK,CAAC,MAAM","sourcesContent":[".wrapper{position:relative;align-self:center}.wrapper .item{position:absolute;top:0;left:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--t4d19",
	"item": "item--fh3Xa"
};
export default ___CSS_LOADER_EXPORT___;
