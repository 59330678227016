import React from "react";
import { requestPasswordReset } from "@Api/Auth";
import Button from "@Components/Button";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import PredictyIconEyesOpen from "@Components/Icons/ChatBot/Predicty/PredictyEyesOpen";
import buttons from "@Components/Sass/buttons.module.scss";
import styles from "./../Login/index.module.scss";
export default class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { username: "" },
            requestSent: false,
            errors: {},
        };
    }
    inputChange = (data) => {
        this.setState({ data });
    };
    resetPassword = () => {
        return requestPasswordReset(this.state.data.username).then(() => {
            this.setState({
                requestSent: true,
            });
        });
    };
    goBack = () => {
        return this.props.goBack();
    };
    render() {
        if (this.state.requestSent) {
            return (React.createElement(Card, { className: styles.mainContent },
                React.createElement("div", { className: styles.centered },
                    React.createElement(PredictyIconEyesOpen, { className: styles.logo })),
                React.createElement("div", { className: styles.form }, "We have processed your request, if you are registered on our system you will receive instructions in your inbox shortly")));
        }
        return (React.createElement("div", { className: styles.mainContent },
            React.createElement(Card, null,
                React.createElement("div", { className: styles.centered },
                    React.createElement(PredictyIconEyesOpen, { className: styles.logo })),
                React.createElement(Form, { className: styles.form, submit: this.resetPassword, labelLeft: false, input: [
                        {
                            label: "Email",
                            name: "username",
                            type: InputType.Text,
                        },
                    ], buttons: false, change: this.inputChange, data: this.state.data, errors: this.state.errors },
                    React.createElement("div", { className: styles.login },
                        React.createElement("div", { className: buttons.buttonGroupCenter },
                            React.createElement(Button, { cta: true, type: "submit" }, "RESET PASSWORD")),
                        React.createElement("div", { className: styles.centered },
                            React.createElement("h2", { className: styles.forgottenPassword, onClick: this.goBack }, "Remembered your password?")))))));
    }
}
