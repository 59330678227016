export default {
    email: {
        presence: true,
        type: "string",
        format: {
            pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        },
    },
    name: {
        length: { minimum: 2 },
        presence: true,
        type: "string",
    },
    password: {
        conditionalPresenceMatchAll: [
            { field: "singleUsePassword", value: true, inverted: true },
        ],
        type: "string",
        checkPassword: {
            pattern: /^.{8,}$/,
            message: "must be at least 8 characters long",
        },
    },
    lastLogin: {
        type: "Dayjs",
    },
    mobileNumber: {
        type: "string",
    },
    singleUsePassword: {
        type: "boolean",
    },
    resetId: {
        type: "string",
    },
};
