// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bubble--IIOYn{position:relative;z-index:3;width:23rem;font-size:1rem;text-align:center;background:var(--card-color);border-radius:1rem;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.bubble--IIOYn:before,.bubble--IIOYn:after{position:absolute;bottom:-28px;left:33px;z-index:2;width:0;height:0;border-top:12px solid #fff;border-right:12px solid rgba(0,0,0,0);border-bottom:20px solid rgba(0,0,0,0);border-left:24px solid #fff;content:\"\"}.bubble--IIOYn:after{bottom:-32px;left:31px;z-index:1}.bubble--IIOYn.border--cXOYA{border:2px solid var(--border-color)}.bubble--IIOYn.border--cXOYA:after{border-top-color:var(--border-color);border-left-color:var(--border-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/SpeechBubble/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,iBAAiB,CAAC,SAAS,CAAC,WAAW,CAAC,cAAc,CAAC,iBAAiB,CAAC,4BAA4B,CAAC,kBAAkB,CAAC,2EAA2E,CAAC,2CAA6B,iBAAiB,CAAC,YAAY,CAAC,SAAS,CAAC,SAAS,CAAC,OAAO,CAAC,QAAQ,CAAC,0BAA0B,CAAC,qCAAqC,CAAC,sCAAsC,CAAC,2BAA2B,CAAC,UAAU,CAAC,qBAAc,YAAY,CAAC,SAAS,CAAC,SAAS,CAAC,6BAAe,oCAAoC,CAAC,mCAAqB,oCAAoC,CAAC,qCAAqC","sourcesContent":[".bubble{position:relative;z-index:3;width:23rem;font-size:1rem;text-align:center;background:var(--card-color);border-radius:1rem;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.bubble:before,.bubble:after{position:absolute;bottom:-28px;left:33px;z-index:2;width:0;height:0;border-top:12px solid #fff;border-right:12px solid rgba(0,0,0,0);border-bottom:20px solid rgba(0,0,0,0);border-left:24px solid #fff;content:\"\"}.bubble:after{bottom:-32px;left:31px;z-index:1}.bubble.border{border:2px solid var(--border-color)}.bubble.border:after{border-top-color:var(--border-color);border-left-color:var(--border-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubble": "bubble--IIOYn",
	"border": "border--cXOYA"
};
export default ___CSS_LOADER_EXPORT___;
