import React from "react";
import { connect } from "react-redux";
import Footer from "@Components/Footer";
import IdleTimeout from "@Components/IdleTimeout";
import PageWrapper from "@Components/Pages/PageWrapper";
import { COMPARISON, CREATE_TENDER, EDIT_TENDER, TENDER, TENDERS, USAGE, USAGES, VIEW_RESPONSES, } from "@Shared/Paths/Customer";
import Header from "./Header";
import styles from "./index.module.scss";
import Nav from "./Nav";
import Notifications from "./Notifications";
import Pages from "./Pages";
class Customer extends React.Component {
    render() {
        return (React.createElement(IdleTimeout, null,
            React.createElement("div", { className: styles.container },
                React.createElement(Nav, { company: this.props.company }),
                React.createElement("div", { className: styles.content },
                    React.createElement(Header, { customer: this.props.company }),
                    React.createElement(PageWrapper, { paths: [
                            TENDERS,
                            EDIT_TENDER,
                            VIEW_RESPONSES,
                            COMPARISON,
                            CREATE_TENDER,
                            USAGE,
                            TENDER,
                            USAGES,
                        ] },
                        React.createElement(Pages, null)),
                    React.createElement(Footer, null)),
                React.createElement(Notifications, null))));
    }
}
const mapStateToProps = ({ auth: { user }, customers: { companies }, }) => ({
    company: companies.find((c) => c.id === user.customerId),
});
export default connect(mapStateToProps)(Customer);
