import React from "react";
import ErrorIcon from "@Components/Icons/Cross";
import CheckIcon from "@Components/Icons/Tick";
import styles from "./index.module.scss";
export var ToastStatus;
(function (ToastStatus) {
    ToastStatus[ToastStatus["Success"] = 0] = "Success";
    ToastStatus[ToastStatus["Error"] = 1] = "Error";
})(ToastStatus || (ToastStatus = {}));
export const SUCCESS = ToastStatus.Success;
export const ERROR = ToastStatus.Error;
const config = {
    [ToastStatus.Success]: {
        backgroundColor: "#5cb85c",
        icon: React.createElement(CheckIcon, { success: true, color: "#ffffff" }),
    },
    [ToastStatus.Error]: {
        backgroundColor: "#d11a2a",
        icon: React.createElement(ErrorIcon, { error: true, color: "#ffffff" }),
    },
};
export default class Toast extends React.Component {
    render() {
        const deleteToast = (id) => {
            this.props.deleteNotification(id);
        };
        return (React.createElement("div", { className: styles.notificationContainer }, this.props.toastList.map((toast, index) => (React.createElement("div", { key: index, className: styles.notification, style: {
                backgroundColor: config[toast.status].backgroundColor,
            } },
            React.createElement("div", { className: styles.notificationImage }, config[toast.status].icon),
            React.createElement("div", null,
                React.createElement("p", { className: styles.notificationTitle }, toast.title),
                React.createElement("p", { className: styles.notificationMessage }, toast.description)),
            React.createElement("button", { onClick: () => deleteToast(toast.id) }, "X"))))));
    }
}
