import { Effected, MDMNames, MDMOS, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const mdmPresenceConstraints = {
    [Effected.OfferMDM]: {
        presence: true,
    },
    [Effected.MDMName]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
    },
    [Effected.OtherName]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MDMName, value: MDMNames.Other },
        ],
    },
    [Effected.MDMOS]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
    },
    [Effected.MinCommitmentPeriod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
    },
    [Effected.MinCommitmentPeriodOther]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MinCommitmentPeriod, value: TimePeriods.Other },
        ],
    },
    [Effected.IsCloudFree]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
    },
    [Effected.MDMLineCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.IsCloudFree, value: false },
        ],
    },
    [Effected.MDMRange]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
    },
    [Effected.EnterpriseVersions]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MDMRange, value: true },
        ],
    },
};
export const mdmValidConstraints = {
    [Effected.OfferMDM]: {
        presence: true,
        type: "boolean",
    },
    [Effected.MDMName]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
        enum: MDMNames,
    },
    [Effected.OtherName]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MDMName, value: MDMNames.Other },
        ],
        type: "string",
    },
    [Effected.MDMOS]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
        enum: MDMOS,
    },
    [Effected.MinCommitmentPeriod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
        enum: TimePeriods,
    },
    [Effected.MinCommitmentPeriodOther]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MinCommitmentPeriod, value: TimePeriods.Other },
        ],
        type: "number",
    },
    [Effected.IsCloudFree]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
        type: "boolean",
    },
    [Effected.MDMLineCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.IsCloudFree, value: false },
        ],
        type: "number",
    },
    [Effected.MDMRange]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
        ],
        type: "boolean",
    },
    [Effected.EnterpriseVersions]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferMDM, value: true },
            { field: Effected.MDMRange, value: true },
        ],
        type: "boolean",
    },
    [Effected.AlternativeSolution]: {
        type: "string",
    },
    [Effected.FurtherMDM]: {
        type: "string",
    },
};
