import SVG from "@Components/Icons/SVG";
export default class Dashboard extends SVG {
    path = [
        "M 4 0",
        "L 96 0",
        "S 100 0 100 4",
        "L 100 96",
        "S 100 100 96 100",
        "L 4 100",
        "S 0 100 0 96",
        "L 0 4",
        "S 0 0 4 0",
        "M 6 4",
        "H 48",
        "V 48",
        "H 4",
        "V 6",
        "S 4 4 6 4",
        "M 52 4",
        "L 94 4",
        "S 96 4 96 6",
        "V 63",
        "H 52",
        "Z",
        "M 4 52",
        "H 48",
        "V 96",
        "H 6",
        "S 4 96 4 94",
        "Z",
        "M 52 67",
        "H 96",
        "V 94",
        "S 96 96 94 96",
        "H 52",
        "Z",
    ];
    evenOdd = true;
}
