import React from "react";
import PageWrapper from "@Components/Pages/PageWrapper";
import { COMPARISON } from "@Shared/Paths/Comparison";
import styles from "./index.module.scss";
import Pages from "./Pages";
export default class Comparison extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement(PageWrapper, { paths: [COMPARISON] },
                    React.createElement(Pages, null)))));
    }
}
