import SVG from "@Components/Icons/SVG";
export default class Remove extends SVG {
    path = [
        "M 0 50 a 45 45 90 1 0 90 0 a 45 45 90 1 0 -90 0",
        "M 24.75 50 L 65.25 50",
    ];
    strokeDetails = {
        stroke: "white",
        strokeWidth: "6",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
