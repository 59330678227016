import React from "react";
import SVG from "@Components/Icons/SVG";
import styles from "./index.module.scss";
export default class KeepingSome extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.wrapper, style: {
                width: this.props.size,
                height: this.props.size,
            } },
            React.createElement(LeftDevice, { className: styles.item, ...this.props }),
            React.createElement(MiddleDevice, { className: styles.item, ...this.props })));
    }
}
class LeftDevice extends SVG {
    path = [
        "M 40.8595 24.4312 H 20.5193 C 18.7903 24.4312 17.1321 25.1021 15.9094 26.2961",
        "C 14.6869 27.4902 14 29.1098 14 30.7986 L 14 72.1864",
        "C 14 73.8752 14.6869 75.4948 15.9094 76.689",
        "C 17.1321 77.8831 18.7903 78.5539 20.5193 78.5539 H 40.8595",
        "C 42.5886 78.5539 44.2468 77.8831 45.4693 76.689",
        "C 46.692 75.4948 47.3789 73.8752 47.3789 72.1864 V 30.7986",
        "C 47.3789 29.1098 46.692 27.4902 45.4693 26.2961",
        "C 44.2468 25.1021 42.5886 24.4312 40.8595 24.4312 V 24.4312",
        "M 42.8478 72.1864 C 42.848 72.44 42.7963 72.6911 42.6959 72.9248",
        "C 42.5957 73.1587 42.4487 73.3708 42.2636 73.5487",
        "C 42.0784 73.7264 41.859 73.8664 41.618 73.9605",
        "C 41.3769 74.0546 41.1191 74.101 40.8595 74.0967 H 20.5193",
        "C 20.0006 74.0967 19.5031 73.8953 19.1363 73.5371",
        "C 18.7696 73.1789 18.5635 72.6932 18.5635 72.1864 V 30.7986",
        "C 18.5635 30.2918 18.7696 29.8061 19.1363 29.4478",
        "C 19.5031 29.0896 20.0006 28.8883 20.5193 28.8883 H 21.2364 L 24.2026 31.3397",
        "C 24.6046 31.6939 25.1283 31.8873 25.6696 31.8809 H 35.7418",
        "C 36.2809 31.8754 36.8007 31.6838 37.2087 31.3397 L 40.0771 28.8883",
        "H 40.7944 C 41.0539 28.8841 41.3118 28.9303 41.5528 29.0244",
        "C 41.7938 29.1186 42.0132 29.2585 42.1984 29.4365",
        "C 42.3834 29.6143 42.5304 29.8262 42.6307 30.0601",
        "C 42.7311 30.2939 42.7829 30.5449 42.7827 30.7986 L 42.8478 72.1864",
    ];
}
class MiddleDevice extends SVG {
    path = [
        "M 50.8469 14.9638 H 77.2893 C 79.4404 14.9638 81.5019 15.8005 83.0206 17.2873",
        "C 84.5391 18.7738 85.3907 20.7883 85.3907 22.8871 V 76.8183",
        "C 85.3907 78.9171 84.5391 80.9316 83.0206 82.4182",
        "C 81.5019 83.9048 79.4404 84.7415 77.2893 84.7415 H 50.8469",
        "C 48.6958 84.7415 46.6344 83.9048 45.1156 82.4182",
        "C 43.597 80.9316 42.7456 78.9171 42.7456 76.8183 V 22.8871",
        "C 42.7456 20.7883 43.597 18.7738 45.1156 17.2873",
        "C 46.6344 15.8005 48.6958 14.9638 50.8469 14.9638 Z M 80.2479 76.8181",
        "V 76.8177 L 80.1631 22.8871 C 80.1631 22.8869 80.1631 22.8867 80.1631 22.8865",
        "C 80.1631 22.5055 80.0857 22.1287 79.9355 21.7778",
        "C 79.7851 21.4268 79.5655 21.109 79.289 20.843 L 79.0297 21.1122",
        "L 79.289 20.843 C 79.0127 20.5769 78.6855 20.3677 78.3267 20.2273",
        "C 77.9688 20.0874 77.5864 20.0183 77.2015 20.0242 H 76.2721 H 76.134",
        "L 76.0291 20.1141 L 72.3019 23.3068 C 72.3016 23.3071 72.3012 23.3074 72.3009 23.3077",
        "C 71.8381 23.6983 71.2478 23.9172 70.6343 23.9239 H 57.5422 V 23.9239",
        "L 57.5379 23.9239 C 56.926 23.931 56.3351 23.7118 55.8829 23.3123",
        "L 55.883 23.3121 L 55.8736 23.3045 L 52.0175 20.1101 L 51.9138 20.0242",
        "H 51.7791 H 50.8469 C 50.0758 20.0242 49.3351 20.324 48.7875 20.8599",
        "C 48.2397 21.3962 47.9305 22.1252 47.9305 22.8871 V 76.8183",
        "C 47.9305 77.5802 48.2397 78.3092 48.7875 78.8455",
        "C 49.3351 79.3815 50.0758 79.6811 50.8469 79.6811 H 77.2864",
        "C 77.6713 79.687 78.0536 79.618 78.4114 79.478",
        "C 78.7704 79.3377 79.0974 79.1285 79.3737 78.8623",
        "C 79.6502 78.5963 79.8699 78.2786 80.0202 77.9274",
        "C 80.1705 77.5764 80.2479 77.1994 80.2479 76.8181 Z Z Z",
    ];
}
