import React from "react";
import styles from "./index.module.scss";
export default class TwoWayToggleSmall extends React.Component {
    static defaultProps = {
        onText: "Yes",
        offText: "No",
    };
    toggle = () => {
        this.props.toggle(this.props.name, !this.props.value);
    };
    render() {
        return (React.createElement("div", null,
            React.createElement("button", { name: this.props.name, onClick: this.toggle, type: "button", className: `${styles.toggle} ${this.props.value === true ? styles.yes : styles.no} ${this.props.green === true ? styles.green : ""}`, disabled: this.props.disabled },
                React.createElement("div", { className: styles.yesText }, this.props.onText),
                React.createElement("div", { className: styles.circle }),
                React.createElement("div", { className: styles.noText }, this.props.offText))));
    }
}
