import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import Logout from "@Components/Pages/Logout";
import { CONTRACTS, CUSTOMERS, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, NOT_FOUND, ROOT, } from "@Shared/Paths/Partner";
import MyAccount from "@SmartComponents/User/MyAccount";
import Contracts from "./Contracts";
import Customers from "./Customers";
import Dashboard from "./Dashboard";
import MyOrganisation from "./MyOrganisation";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ROOT, exact: true },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: CUSTOMERS },
            React.createElement(Customers, null)),
        React.createElement(Route, { path: CONTRACTS },
            React.createElement(Contracts, null)),
        React.createElement(Route, { path: MY_ORGANISATION },
            React.createElement(MyOrganisation, null)),
        React.createElement(Route, { path: MY_ACCOUNT },
            React.createElement(MyAccount, null)),
        React.createElement(Route, { path: LOGOUT },
            React.createElement(Logout, null)),
        React.createElement(Route, { path: NOT_FOUND },
            React.createElement(NotFoundFallback, null))));
};
