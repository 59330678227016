import { fetchCompany as fetchCustomer } from "@Store/Actions/Customers/companies";
import { fetchTender } from "@Store/Actions/Customers/tenders";
import { fetchUsage } from "@Store/Actions/Customers/usage";
import { fetchUsers as fetchCustomerUsers } from "@Store/Actions/Customers/users";
import { fetchUsers as fetchPartnerUsers } from "@Store/Actions/Partners/users";
import { fetchCompany as fetchSupplier } from "@Store/Actions/Suppliers/companies";
import { fetchResponse } from "@Store/Actions/Suppliers/responses";
import { fetchUsers as fetchSupplierUsers } from "@Store/Actions/Suppliers/users";
import { ADD_AWARDED_CONTRACT } from "../../Actions/AwardedContract";
const findInSet = (set, findFunction) => {
    for (const item of set) {
        if (findFunction(item)) {
            return item;
        }
    }
};
const fetchAwardedContractEntities = async (awardedContract, state, dispatch) => {
    const customers = new Set(state.customers.companies);
    const suppliers = new Set(state.suppliers.companies);
    const responses = new Set(state.suppliers.responses);
    const tenders = new Set(state.customers.tenders);
    const usages = new Set(state.customers.usage);
    if (!findInSet(customers, ({ id }) => id === awardedContract.customerId)) {
        await fetchCustomer(dispatch, awardedContract.customerId, true);
    }
    if (!findInSet(suppliers, ({ id }) => id === awardedContract.supplierId)) {
        await fetchSupplier(dispatch, awardedContract.supplierId, true);
    }
    if (!findInSet(responses, ({ id }) => id === awardedContract.responseId)) {
        await fetchResponse(dispatch, awardedContract.supplierId, awardedContract.responseId, undefined, true);
    }
    if (!findInSet(tenders, ({ id }) => id === awardedContract.tenderId)) {
        await fetchTender(dispatch, awardedContract.tenderId, true);
    }
    if (awardedContract.usageId &&
        !findInSet(usages, ({ id }) => id === awardedContract.usageId)) {
        await fetchUsage(dispatch, awardedContract.customerId, awardedContract.usageId, true);
    }
    //fetch all users
    await fetchSupplierUsers(dispatch, awardedContract.supplierId, true);
    await fetchCustomerUsers(dispatch, awardedContract.customerId, true);
    if (awardedContract.partnerId !== undefined) {
        await fetchPartnerUsers(dispatch, awardedContract.partnerId, true);
    }
};
export default (store) => (next) => async (action) => {
    const state = next(action); // Store is updated
    const { type, data } = action;
    if (type === ADD_AWARDED_CONTRACT && data !== undefined) {
        await fetchAwardedContractEntities(data, store.getState(), store.dispatch);
    }
    return state;
};
