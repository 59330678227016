import { dataConvertFromTo } from "@Shared/Helpers/Data";
import { DataSize } from "../enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    let { version = 0, averageData, unitType } = current;
    if (version >= 2) {
        return current;
    }
    if (unitType !== undefined && averageData !== undefined) {
        averageData = dataConvertFromTo(averageData, unitType, DataSize.GB);
    }
    return { ...current, averageData, version: 2 };
};
export default (original = {}) => {
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    const { version, averageData, usedVoiceLines, totalCost, tariffCosts, dataLines, totalLines, pictureMessages, daysTraveller, iddEurope, iddApac, iddRow, iddUsa, ukToIntTexts, roamingTexts, oobCosts, dataAggregates, iddTotalAggregates, iddEuropeAggregates, iddRowAggregates, iddApacAggregates, iddUsaAndCanadaAggregates, percentage, allRecords, categorisedRecords, nonCategorisedRecords, invalidCredentials, unusedLineCost, voiceOnlyLines, } = converted;
    return {
        version,
        averageData,
        usedVoiceLines,
        totalCost,
        tariffCosts,
        dataLines,
        totalLines,
        pictureMessages,
        daysTraveller,
        iddEurope,
        iddApac,
        iddRow,
        iddUsa,
        ukToIntTexts,
        roamingTexts,
        oobCosts,
        dataAggregates,
        iddTotalAggregates,
        iddEuropeAggregates,
        iddRowAggregates,
        iddApacAggregates,
        iddUsaAndCanadaAggregates,
        percentage,
        allRecords,
        categorisedRecords,
        nonCategorisedRecords,
        invalidCredentials,
        unusedLineCost,
        voiceOnlyLines,
    };
};
