import getSubdomain from "@Helpers/GetSubdomain";
export var BrokerDomain;
(function (BrokerDomain) {
    BrokerDomain[BrokerDomain["Predict"] = 0] = "Predict";
    BrokerDomain[BrokerDomain["ERA"] = 1] = "ERA";
    BrokerDomain[BrokerDomain["TechMahindra"] = 2] = "TechMahindra";
    BrokerDomain[BrokerDomain["SevenStepSolutions"] = 3] = "SevenStepSolutions";
    BrokerDomain[BrokerDomain["Bionic"] = 4] = "Bionic";
    BrokerDomain[BrokerDomain["BDO"] = 5] = "BDO";
    BrokerDomain[BrokerDomain["Protel"] = 6] = "Protel";
})(BrokerDomain || (BrokerDomain = {}));
export const getBrokerDomain = () => {
    const subDomain = getSubdomain();
    switch (subDomain) {
        case "era":
            return BrokerDomain.ERA;
        case "techmahindra":
            return BrokerDomain.TechMahindra;
        case "7ss":
            return BrokerDomain.SevenStepSolutions;
        case "bionic":
            return BrokerDomain.Bionic;
        case "bdo":
            return BrokerDomain.BDO;
        case "protel":
            return BrokerDomain.Protel;
        default:
            return BrokerDomain.Predict;
    }
};
export default getBrokerDomain;
