import React from "react";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import EEIcon from "@Components/Icons/Networks/EE";
import O2Icon from "@Components/Icons/Networks/O2";
import VodafoneWithoutTextIcon from "@Components/Icons/Networks/VodafoneWithoutText";
import styles from "./index.module.scss";
export function HowToGetBills(props) {
    const domain = props.sensabill ? "sensabill.co.uk" : "predictmobile.com";
    return (React.createElement(ColouredBorderBox, { title: React.createElement("div", { className: styles.boxTitle },
            "How to get",
            " ",
            React.createElement("b", { className: styles.primaryColor }, "Bill\u00A0Reports")), className: props.className, matchWidth: "uploadPopout" },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.message },
                    React.createElement("div", { className: `${styles.messageTitle} ${styles.centered}` }, "Step-by-step guides"),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement(EEIcon, { className: styles.eeIcon }),
                        React.createElement("a", { className: styles.link, href: `https://docs.${domain}/instructions/EE/`, target: "_blank" }, "EE Mobile Manager")),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement(O2Icon, { className: styles.o2Icon }),
                        React.createElement("a", { className: styles.link, href: `https://docs.${domain}/instructions/O2/`, target: "_blank" }, "My O2")),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement(VodafoneWithoutTextIcon, { className: styles.vodafoneIcon }),
                        React.createElement("a", { className: styles.link, href: `https://docs.${domain}/instructions/VodafoneVCO/`, target: "_blank" }, "Vodafone VCO")),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement(VodafoneWithoutTextIcon, { className: styles.vodafoneIcon }),
                        React.createElement("a", { className: styles.link, href: `https://docs.${domain}/instructions/Vodafone/`, target: "_blank" }, "My Vodafone")),
                    React.createElement("div", { className: styles.messageText },
                        React.createElement("a", { className: styles.link, href: `https://docs.${domain}/`, target: "_blank" }, "Not listed above? Click\u00A0here")))))));
}
export default HowToGetBills;
