import dayjs from "dayjs";
import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import { BillingEngine } from "@Shared/Entities/Customer/Usage/enums";
import Factory from "@Shared/Factory";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    let { platform } = current;
    if (platform === 1) {
        platform = BillingEngine.EE_Mobile_Manager;
    }
    return { ...current, platform, version: 2 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    let { version, customerId, supplierId, network, platform, username, password, endsAt, passwordEncrypted = false, twoFA = false, userNameEncrypted = false, stats = {}, breakdown = {}, mailingList = [], status, canViewSensabill = false, hasTender = false, canCreateTenderOverride = false, hideStartTenderOverride = false, totalRows, } = converted;
    if (endsAt) {
        endsAt = dayjs.utc(endsAt);
    }
    stats = Factory.UsageStats(stats);
    breakdown = Factory.UsageBreakdown(breakdown);
    return {
        ...baseMigrated,
        version,
        customerId,
        supplierId,
        network,
        platform,
        username,
        password,
        passwordEncrypted,
        twoFA,
        userNameEncrypted,
        endsAt,
        stats,
        breakdown,
        mailingList,
        status,
        canViewSensabill,
        hasTender,
        canCreateTenderOverride,
        hideStartTenderOverride,
        totalRows,
    };
};
