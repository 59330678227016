import SVG from "@Components/Icons/SVG";
export default class UiError extends SVG {
    path = [
        "M 0 50 a 50 50 90 1 0 100 0 a 50 50 90 1 0 -100 0",
        "M 54.6875 59.3585 H 45.4209 L 43.4841 22.2547 H 56.6245 L 54.6875 59.3585 Z",
        "M 43.3322 72.347 C 43.3322 70.2205 43.9019 68.6125 45.0412 67.524 C 46.1805 66.435 47.8388 65.891 50.016 65.891 C 52.1175 65.891 53.738 66.448 54.8775 67.562 C 56.042 68.676 56.6245 70.271 56.6245 72.347 C 56.6245 74.347 56.042 75.9295 54.8775 77.094 C 53.7125 78.2335 52.0925 78.803 50.016 78.803 C 47.8895 78.803 46.2438 78.246 45.0792 77.132 C 43.9145 75.993 43.3322 74.398 43.3322 72.347 Z",
    ];
    strokeDetails = {
        stroke: "white",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
