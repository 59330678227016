import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import dayjs from "dayjs";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 1 } = current;
    if (version >= 2) {
        return current;
    }
    let price = current.price;
    if (price === undefined) {
        price = current.simFreePrice;
    }
    return { ...current, price, version: 2 };
};
export const migrate = (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    let { make, model, color, size, os, price, predecessorId, replacementId, priceListMonth, } = converted;
    make = Number(make);
    color = Number(color);
    price = Number(price);
    os = Number(os);
    if (model !== undefined) {
        model = model.trim();
    }
    if (priceListMonth !== undefined) {
        priceListMonth = dayjs(priceListMonth);
    }
    return {
        ...baseMigrated,
        make,
        model,
        color,
        size,
        os,
        price,
        predecessorId,
        replacementId,
        priceListMonth,
    };
};
