import dayjs from "dayjs";
export default () => {
    const getNumber = () => {
        return Math.floor(Math.random() * 10);
    };
    const getLetter = () => {
        const number = Math.floor(Math.random() * 26) + 10;
        return number.toString(36);
    };
    const randomCharacters = () => {
        let characters = "";
        for (let index = 0; index < 4; index++) {
            //50/50 chance
            characters += Math.random() < 0.5 ? getNumber() : getLetter();
        }
        return characters;
    };
    const now = dayjs();
    const year = now.format("YYYY");
    const month = now.format("MMM");
    return `${year}-${month}-${randomCharacters()}-${randomCharacters()}`.toUpperCase();
};
