import User from "@Shared/Entities/AbstractEntities/User";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import constraints from "./constraints";
import migrate from "./migrate";
export default class CustomerUser extends User {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get type() {
        return UserType.Customer;
    }
    get customerId() {
        return this._data.customerId;
    }
    get role() {
        return this._data.role;
    }
    get baseUrl() {
        return `${super.baseUrl}/customers/${this.customerId}/users/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { customerId, role, activated } = this;
        const { version } = this._data;
        return { ...parent, version, customerId, role, activated };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
}
