import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    const { texts = 0, applyToRows = [], } = current;
    let { sms, mms } = current;
    if (texts > 0 || texts === "Unlimited") {
        const smsTypes = new Set([
            AllowanceTypes["Std SMS"],
            AllowanceTypes["Prem SMS"],
            AllowanceTypes["Int SMS"],
            AllowanceTypes["EU SMS"],
            AllowanceTypes["Non-EU SMS"],
        ]);
        if (applyToRows.some((allowanceType) => smsTypes.has(allowanceType))) {
            sms = texts;
        }
        const mmsTypes = new Set([
            AllowanceTypes["Std MMS"],
            AllowanceTypes["Int MMS"],
            AllowanceTypes["EU MMS"],
            AllowanceTypes["Non-EU MMS"],
        ]);
        if (applyToRows.some((allowanceType) => mmsTypes.has(allowanceType))) {
            mms = texts;
        }
    }
    return { ...current, sms, mms, texts: undefined, version: 2 };
};
export default (original = {}) => {
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    const { version, id, roamLikeHome, name, mins, sms, mms, dataMB, type, duration, numBundles, cost, applyToRows, color, allowanceTypeGroup, } = converted;
    return {
        version,
        id,
        roamLikeHome,
        name,
        mins,
        sms,
        mms,
        dataMB,
        type,
        duration,
        numBundles,
        cost,
        applyToRows,
        color,
        allowanceTypeGroup,
    };
};
