export default {
    responseIds: {
        presence: true,
        type: "array",
        length: {
            maximum: 3,
            minimum: 2,
            message: "must be 2 or 3 responses long",
        },
    },
};
