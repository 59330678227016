import Factory from "@Shared/Factory";
import { DELETE_CUSTOMER } from "@Store/Actions/Customers/companies";
import { ADD_USAGE, DELETE_USAGE } from "@Store/Actions/Customers/usage";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_USAGE:
            return state.filter(({ id }) => id !== data);
        case ADD_USAGE:
            const usage = Factory.Usage(data);
            return [...state.filter(({ id }) => id !== usage.id), usage];
        case DELETE_CUSTOMER:
            return state.filter(({ customerId }) => customerId !== data);
    }
    return state;
};
