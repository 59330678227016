export class PredictError extends Error {
    statusCode = 500;
    _context = [];
    constructor(message, ...context) {
        super(message);
        this.name = "PredictError";
        this._context = context;
    }
    get context() {
        return this._context;
    }
    toJSON() {
        const { name, message, statusCode, context } = this;
        return { name, message, statusCode, context };
    }
}
export class ValidationException extends PredictError {
    statusCode = 400;
    fails;
    constructor(fails, ...params) {
        super("", ...params);
        this.name = "ValidationException";
        this.fails = fails;
    }
    toJSON() {
        return { ...super.toJSON(), fails: this.fails };
    }
}
export class AccessDeniedException extends PredictError {
    statusCode = 403;
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "AccessDeniedException";
    }
}
export class NotFoundException extends PredictError {
    statusCode = 404;
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "NotFoundException";
    }
}
export class DuplicateException extends PredictError {
    statusCode = 405;
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "DuplicateException";
    }
}
export class NotAllowedException extends PredictError {
    statusCode = 403;
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "NotAllowedException";
    }
}
export class ConflictException extends PredictError {
    statusCode = 409;
    state;
    constructor(message, state) {
        super(message);
        this.name = "ConflictException";
        this.state = state;
    }
    toJSON() {
        return { ...super.toJSON(), state: this.state };
    }
}
export class TooEarlyException extends PredictError {
    statusCode = 425;
    state;
    constructor(message, state) {
        super(message);
        this.name = "TooEarlyException";
        this.state = state;
    }
    toJSON() {
        return { ...super.toJSON(), state: this.state };
    }
}
export class FatalException extends PredictError {
    statusCode = 500;
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "FatalException";
    }
}
export class DBConnectException extends FatalException {
    constructor(message, ...context) {
        super(message, ...context);
        this.name = "DBConnectException";
    }
}
export const ExceptionFactory = (exception) => {
    switch (exception.statusCode) {
        case 400:
            return createException(exception, ValidationException);
        case 403:
            return createException(exception, AccessDeniedException);
        case 404:
            return createException(exception, NotFoundException);
        case 405:
            return createException(exception, DuplicateException);
        case 409:
            return createException(exception, NotAllowedException);
        case 500:
            return createException(exception, FatalException);
    }
};
const createException = (exception, entity) => {
    const { statusCode, message, ...data } = exception;
    return new entity(message, { ...data });
};
