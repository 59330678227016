import { getBillingEngines } from "@Api/BillingEngine";
import Button from "@Components/Button";
import Form, { InputType } from "@Components/Form";
import DragAndDrop from "@Components/Icons/DragAndDrop";
import { validation } from "@Shared/Validate";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
export function Auto(props) {
    const [billingEngines, setBillingEngines] = useState([]);
    const init = async () => {
        let engines = await getBillingEngines();
        engines = engines
            .filter((platform) => platform.engine !== 0)
            .map((platform) => {
            return {
                value: platform.engine,
                label: (React.createElement("div", null,
                    React.createElement("img", { src: platform.icon, className: styles.billingEngineIcon }),
                    platform.name)),
            };
        });
        setBillingEngines(engines);
    };
    useEffect(() => {
        init();
    }, []);
    const { platform } = props.usage || {};
    const [data, setData] = useState({ platform });
    const [errors, setErrors] = useState({});
    const submitPlatform = () => {
        try {
            validation(data, {
                username: {
                    presence: true,
                    type: "string",
                    length: {
                        minimum: 1,
                    },
                },
                password: {
                    presence: true,
                    type: "string",
                    length: {
                        minimum: 1,
                    },
                },
                platform: {
                    presence: true,
                },
                twoFA: {
                    type: "boolean",
                },
            });
            props.submit(data);
        }
        catch (error) {
            setErrors(error.fails);
        }
    };
    const getTitle = () => {
        if (props.title !== undefined) {
            return props.title;
        }
        if (props.usage !== undefined) {
            return "Update your Billing Engine details";
        }
        return "Login to Sensabill's Bill Report Analysis";
    };
    const getInstructions = () => {
        if (props.usage !== undefined) {
            return (React.createElement(React.Fragment, null, "Your password may be renewed at regular intervals, you can update our records\u00A0here:"));
        }
        return React.createElement(React.Fragment, null);
    };
    const placeholder = "encrypted";
    return (React.createElement("div", { className: `${styles.card}` },
        React.createElement("div", { className: styles.titleContainer },
            React.createElement("div", { className: styles.icon },
                React.createElement(DragAndDrop, null)),
            React.createElement("h4", { className: styles.title }, getTitle())),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.middle },
                React.createElement(Form, { change: setData, className: styles.body, data: data, errors: errors, buttons: false, topSlot: React.createElement("div", { className: styles.titleWrapper },
                        React.createElement("div", { className: styles.title }, getInstructions())), input: [
                        {
                            label: "Select your new billing engine",
                            name: "platform",
                            type: InputType.DropdownOptions,
                            options: billingEngines,
                            disabled: !props.admin &&
                                props?.usage?.platform !== undefined,
                        },
                        {
                            label: "Username",
                            name: "username",
                            type: InputType.Text,
                            placeholder: props.usage?.username
                                ? placeholder
                                : undefined,
                        },
                        {
                            label: "Password",
                            name: "password",
                            type: InputType.Password,
                            showPassword: true,
                            placeholder: props.usage?.password
                                ? placeholder
                                : undefined,
                        },
                        {
                            label: "Does your login have 2FA?",
                            name: "twoFA",
                            type: InputType.SmallToggle,
                            notes: data.twoFA
                                ? "We may have to contact you in order for reports to be downloaded."
                                : undefined,
                        },
                    ], bottomSlot: React.createElement("div", { className: styles.buttons },
                        props.switchToManual && (React.createElement(Button, { click: props.switchToManual }, "SWITCH TO MANUAL UPLOAD")),
                        React.createElement(Button, { arrow: true, cta: true, click: submitPlatform }, "CONNECT TO REPORTS")) })))));
}
export default Auto;
