import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Comparison extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/customers/comparisons/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { responseIds, tenderId } = this;
        return { ...parent, version, responseIds, tenderId };
    }
    get responseIds() {
        return this._data.responseIds;
    }
    get tenderId() {
        return this._data.tenderId;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
}
