import React from "react";
import styles from "./index.module.scss";
export default class PlanIcon extends React.Component {
    render() {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", version: "1.1", id: "Layer_1", x: "0px", y: "0px", viewBox: "0 0 150 34.5", className: `${this.props.size ? "" : styles.plan} ${this.props.className || ""}`, style: { width: this.props.size, backgroundColor: "black" } },
            React.createElement("title", null, "Plan.com Logo"),
            React.createElement("path", { className: styles.st0, d: "M 71.98 23.12 C 71.98 22.72 72.05 22.31 72.2 21.95 C 72.34 21.58 72.56 21.25 72.82 20.99 C 73.08 20.74 73.41 20.52 73.78 20.33 C 74.14 20.18 74.55 20.07 74.95 20.07 C 75.35 20.07 75.76 20.15 76.12 20.33 C 76.49 20.48 76.82 20.7 77.08 20.99 C 77.34 21.25 77.56 21.58 77.74 21.95 C 77.89 22.31 77.96 22.72 77.96 23.12 C 77.96 23.52 77.89 23.93 77.74 24.3 C 77.59 24.66 77.37 24.99 77.08 25.25 C 76.82 25.51 76.49 25.73 76.12 25.87 C 75.76 26.02 75.35 26.09 74.95 26.09 C 74.55 26.09 74.14 26.02 73.78 25.87 C 73.41 25.73 73.08 25.51 72.82 25.25 C 72.56 24.99 72.34 24.66 72.2 24.3 C 72.05 23.93 71.98 23.52 71.98 23.12" }),
            React.createElement("path", { className: styles.st1, d: "M 96.79 23.71 C 96.02 24.55 95.03 25.25 93.78 25.87 C 92.53 26.46 91.06 26.79 89.37 26.79 C 87.87 26.79 86.55 26.53 85.3 26.06 C 84.09 25.58 83.06 24.88 82.18 24 C 81.34 23.12 80.64 22.09 80.16 20.88 C 79.68 19.67 79.43 18.35 79.43 16.92 C 79.43 15.56 79.68 14.28 80.16 13.1 C 80.64 11.93 81.34 10.86 82.22 9.98 C 83.1 9.1 84.13 8.4 85.34 7.89 C 86.55 7.38 87.87 7.12 89.3 7.12 C 90.95 7.12 92.42 7.38 93.63 7.93 C 94.84 8.48 95.91 9.21 96.71 10.13 L 93.3 13.8 C 92.9 13.32 92.38 12.92 91.76 12.55 C 91.17 12.18 90.36 12 89.37 12 C 88.68 12 88.09 12.15 87.54 12.4 C 86.99 12.7 86.55 13.03 86.18 13.47 C 85.81 13.91 85.52 14.42 85.34 15.01 C 85.15 15.6 85.04 16.22 85.04 16.88 C 85.04 17.47 85.15 18.09 85.34 18.68 C 85.52 19.27 85.81 19.78 86.18 20.26 C 86.55 20.7 87.02 21.1 87.54 21.36 C 88.09 21.65 88.68 21.8 89.37 21.8 C 90.33 21.8 91.14 21.62 91.8 21.25 C 92.46 20.88 92.97 20.44 93.37 20 L 96.79 23.71 Z" }),
            React.createElement("path", { className: styles.st1, d: "M 1 7.74 H 6.39 C 6.43 8.07 6.47 8.4 6.5 8.73 C 6.54 9.06 6.54 9.4 6.58 9.73 C 7.17 8.95 7.94 8.4 8.89 7.96 C 9.84 7.56 10.8 7.38 11.83 7.38 C 13.15 7.38 14.32 7.63 15.35 8.15 C 16.38 8.66 17.22 9.36 17.88 10.24 C 18.54 11.12 19.06 12.11 19.42 13.25 C 19.75 14.39 19.94 15.56 19.94 16.77 C 19.94 18.46 19.64 19.96 19.06 21.21 C 18.47 22.46 17.74 23.49 16.82 24.3 C 15.9 25.1 14.87 25.69 13.73 26.09 C 12.6 26.5 11.46 26.68 10.32 26.68 C 9.44 26.68 8.71 26.61 8.12 26.46 C 7.53 26.31 7.02 26.13 6.54 25.98 V 33.95 H 1 V 7.74 Z M 6.62 21.29 C 7.35 21.8 8.41 22.02 9.88 22.02 C 11.28 22.02 12.34 21.54 13.15 20.63 C 13.96 19.71 14.36 18.46 14.36 16.92 C 14.36 16.33 14.29 15.74 14.14 15.16 C 13.99 14.57 13.77 14.09 13.48 13.65 C 13.18 13.21 12.78 12.88 12.3 12.62 C 11.83 12.37 11.24 12.22 10.51 12.22 C 9.88 12.22 9.33 12.33 8.85 12.59 C 8.38 12.81 7.97 13.14 7.64 13.54 C 7.31 13.95 7.06 14.39 6.91 14.86 C 6.73 15.38 6.65 15.85 6.65 16.4 V 21.29 Z" }),
            React.createElement("rect", { x: "21.8", className: styles.st1, width: "5.6", height: "26.1" }),
            React.createElement("path", { className: styles.st1, d: "M 42.98 24.41 C 42.36 25.14 41.55 25.69 40.6 26.09 C 39.65 26.5 38.58 26.68 37.41 26.68 C 36.75 26.68 36.09 26.61 35.35 26.42 C 34.62 26.24 33.96 25.95 33.37 25.51 C 32.75 25.07 32.23 24.48 31.83 23.78 C 31.42 23.05 31.2 22.13 31.2 21.03 C 31.2 19.96 31.42 19.01 31.86 18.17 C 32.31 17.32 32.89 16.63 33.66 16.07 C 34.43 15.52 35.31 15.08 36.34 14.79 C 37.37 14.5 38.51 14.35 39.68 14.35 C 40.34 14.35 41 14.35 41.66 14.39 C 42.32 14.42 42.76 14.46 42.98 14.5 V 14.09 C 42.98 13.62 42.87 13.21 42.69 12.88 C 42.51 12.55 42.25 12.29 41.92 12.07 C 41.59 11.85 41.22 11.71 40.82 11.6 C 40.42 11.52 39.98 11.45 39.5 11.45 C 38.25 11.45 37.22 11.6 36.38 11.93 C 35.57 12.26 34.87 12.59 34.29 12.99 L 31.94 9.54 C 32.34 9.25 32.78 8.95 33.26 8.7 C 33.77 8.4 34.36 8.15 35.02 7.93 C 35.68 7.71 36.45 7.49 37.3 7.38 C 38.14 7.23 39.13 7.16 40.2 7.16 C 42.98 7.16 45.04 7.89 46.4 9.4 C 47.72 10.9 48.38 12.92 48.38 15.45 V 26.13 H 43.09 L 42.98 24.41 Z M 42.98 18.02 C 42.84 17.98 42.62 17.95 42.25 17.91 C 41.88 17.87 41.48 17.84 41 17.84 C 39.42 17.84 38.32 18.06 37.66 18.5 C 37 18.94 36.67 19.56 36.67 20.37 C 36.67 21.87 37.59 22.64 39.42 22.64 C 39.87 22.64 40.31 22.57 40.75 22.42 C 41.19 22.28 41.55 22.09 41.88 21.8 C 42.21 21.54 42.47 21.21 42.69 20.81 C 42.91 20.41 43.02 19.96 43.02 19.45 V 18.02 Z" }),
            React.createElement("path", { className: styles.st1, d: "M 50.28 7.74 H 55.67 L 55.82 9.73 C 56.41 8.99 57.22 8.4 58.21 7.96 C 59.2 7.52 60.22 7.3 61.29 7.3 C 63.42 7.3 65.07 7.93 66.32 9.18 C 67.56 10.42 68.19 12.29 68.19 14.75 V 26.13 H 62.54 V 15.56 C 62.54 14.53 62.28 13.73 61.77 13.14 C 61.25 12.55 60.55 12.22 59.64 12.22 C 59.01 12.22 58.46 12.33 57.99 12.55 C 57.51 12.77 57.14 13.07 56.81 13.43 C 56.48 13.8 56.26 14.24 56.11 14.72 C 55.97 15.19 55.89 15.71 55.89 16.26 V 26.13 H 50.28 V 7.74 Z" }),
            React.createElement("path", { className: styles.st1, d: "M 98.22 16.92 C 98.22 15.56 98.48 14.28 98.95 13.1 C 99.43 11.93 100.13 10.86 100.97 9.98 C 101.85 9.1 102.88 8.4 104.09 7.89 C 105.3 7.38 106.66 7.12 108.09 7.12 C 109.56 7.12 110.88 7.38 112.09 7.89 C 113.3 8.4 114.33 9.1 115.17 9.98 C 116.02 10.86 116.72 11.93 117.19 13.1 C 117.67 14.28 117.93 15.56 117.93 16.92 C 117.93 18.28 117.67 19.56 117.19 20.74 C 116.72 21.91 116.05 22.97 115.17 23.86 C 114.29 24.74 113.27 25.43 112.09 25.98 C 110.88 26.5 109.56 26.75 108.09 26.75 C 106.62 26.75 105.3 26.5 104.09 25.98 C 102.88 25.47 101.85 24.77 100.97 23.86 C 100.13 22.97 99.43 21.95 98.95 20.74 C 98.48 19.56 98.22 18.28 98.22 16.92 M 108.09 21.87 C 108.79 21.87 109.41 21.73 109.96 21.47 C 110.51 21.21 110.99 20.81 111.36 20.37 C 111.72 19.89 112.02 19.38 112.2 18.79 C 112.38 18.2 112.46 17.58 112.46 16.96 C 112.46 16.33 112.38 15.71 112.2 15.12 C 112.02 14.53 111.72 14.02 111.36 13.54 C 110.99 13.1 110.51 12.73 109.96 12.44 C 109.41 12.18 108.79 12.04 108.09 12.04 C 107.39 12.04 106.73 12.18 106.18 12.44 C 105.63 12.73 105.15 13.07 104.79 13.54 C 104.42 13.98 104.13 14.53 103.94 15.12 C 103.76 15.71 103.65 16.33 103.65 16.96 C 103.65 17.58 103.76 18.2 103.94 18.79 C 104.13 19.38 104.42 19.89 104.79 20.37 C 105.15 20.85 105.63 21.21 106.18 21.47 C 106.77 21.73 107.39 21.87 108.09 21.87" }),
            React.createElement("path", { className: styles.st1, d: "M 120.75 7.74 H 126.15 L 126.33 9.8 C 126.92 9.1 127.65 8.51 128.53 8.04 C 129.41 7.56 130.48 7.34 131.65 7.34 C 132.39 7.34 133.05 7.41 133.6 7.56 C 134.18 7.71 134.66 7.93 135.1 8.18 C 135.54 8.44 135.91 8.7 136.24 8.99 C 136.57 9.29 136.83 9.58 137.05 9.87 C 137.82 9.06 138.74 8.44 139.8 8 C 140.9 7.56 142 7.34 143.18 7.34 C 145.52 7.34 147.32 8 148.57 9.32 C 149.82 10.64 150.44 12.48 150.44 14.75 V 26.13 H 144.86 V 15.67 C 144.86 14.57 144.61 13.73 144.13 13.14 C 143.65 12.55 142.92 12.22 141.96 12.22 C 140.86 12.22 139.98 12.59 139.32 13.36 C 138.66 14.13 138.33 15.08 138.33 16.26 V 26.13 H 132.9 V 15.67 C 132.9 14.57 132.64 13.73 132.17 13.14 C 131.69 12.55 130.95 12.22 130 12.22 C 128.94 12.22 128.06 12.59 127.39 13.36 C 126.73 14.13 126.4 15.08 126.4 16.26 V 26.13 H 120.75 V 7.74 Z" })));
    }
}
