import SVG from "@Components/Icons/SVG";
export default class CSVIcon extends SVG {
    path = [
        "M 72.2015 51.8665",
        "a 1.666 1.666 90 0 1 3.332 0",
        "a 1.617 1.617 90 0 0 3.234 0",
        "a 1.666 1.666 90 0 1 3.332 0",
        "a 4.9 4.9 90 0 1 -9.8 0 Z m 26.95 4.2875 v 6.762",
        "a 5.7085 5.7085 90 0 1 -3.9445 5.439 l -6.5905 2.107",
        "a 1.519 1.519 90 0 0 -0.7595 0.6125",
        "a 31.654 31.654 90 0 1 -12.838 11.9315 l 0 0 l -2.0335 10.7555",
        "a 3.773 3.773 90 0 1 -3.6995 3.0625 h -6.86 A 3.7975 3.7975 90 0 1 58.8 93.835 l -1.0045 -5.39 l -0.294 0",
        "a 64.239 64.239 90 0 1 -14.3815 0.2695 h -0.2695 L 41.65 93.835",
        "a 3.7485 3.7485 90 0 1 -3.6995 3.0625 H 31.213",
        "a 3.773 3.773 90 0 1 -3.6995 -3.0625 l -1.96 -10.143 l 0 0 A 29.4 29.4 90 0 1 14.4305 73.5",
        "a 29.9635 29.9635 90 0 1 -4.312 -16.0965 c 0 -0.637 0 -1.274 0 -1.911 v -0.294 l -0.7105 0 l 0 0.1715",
        "a 9.065 9.065 90 0 1 -0.931 1.4455 C 5.7575 60.025 3.969 60.2455 2.2295 60.1475 A 1.3965 1.3965 90 0 1 0.8575 58.8 c 0 -0.833 0.2695 -1.862 1.029 -2.058 l 0.392 0",
        "a 4.704 4.704 90 0 0 3.283 -1.7395 l 0.3675 -0.4165 l -0.539 0 A 6.4925 6.4925 90 0 1 0.245 50.8865",
        "a 3.9935 3.9935 90 0 1 0.882 -4.116",
        "a 4.508 4.508 90 0 1 3.969 -1.764 A 6.2475 6.2475 90 0 1 9.8 49",
        "a 6.517 6.517 90 0 1 0.392 1.3475 l 0.3675 1.715 l 0.245 -1.7395",
        "a 25.431 25.431 90 0 1 15.092 -19.8205",
        "a 1.568 1.568 90 0 1 1.2005 0",
        "a 1.5925 1.5925 90 0 1 1.0045 0.7595",
        "a 1.666 1.666 90 0 1 -0.588 2.2785",
        "a 26.3865 26.3865 90 0 0 -10.4615 9.8",
        "a 27.636 27.636 90 0 0 -3.479 14.063 A 25.4065 25.4065 90 0 0 27.4155 80.85",
        "a 2.45 2.45 90 0 1 1.323 1.764 L 30.772 93.1",
        "a 0.441 0.441 90 0 0 0.441 0.3675 h 6.8355",
        "a 0.441 0.441 90 0 0 0.441 -0.343 l 1.1025 -5.8555",
        "a 2.45 2.45 90 0 1 2.744 -2.0335",
        "a 59.731 59.731 90 0 0 15.631 -0.294",
        "a 2.45 2.45 90 0 1 2.8175 2.009 l 1.176 6.174",
        "a 0.441 0.441 90 0 0 0.4165 0.343 h 6.86",
        "a 0.4165 0.4165 90 0 0 0.4165 -0.343 l 2.107 -11.172",
        "a 2.597 2.597 90 0 1 1.3475 -1.764",
        "a 28.5425 28.5425 90 0 0 11.8825 -10.7555",
        "a 4.9 4.9 90 0 1 2.695 -2.254 l 6.566 -2.107",
        "a 2.45 2.45 90 0 0 1.6415 -2.254 V 56.154",
        "a 2.45 2.45 90 0 0 -1.6415 -2.254 l -4.067 -1.2985",
        "a 4.753 4.753 90 0 1 -3.1115 -3.038",
        "a 22.05 22.05 90 0 0 -3.43 -6.8355 l -0.6125 -0.8085 L 82.712 41.65 L 80.85 39.739",
        "a 1.715 1.715 90 0 1 -0.5145 -1.8375",
        "a 36.2845 36.2845 90 0 0 1.813 -7.35",
        "a 15.1165 15.1165 90 0 0 -15.7535 1.4945",
        "a 1.715 1.715 90 0 1 -2.45 -2.45 c 4.165 -4.116 13.818 -5.7085 20.482 -1.666 c 0.8575 0.5145 2.254 1.372 -0.5145 9.996",
        "a 28.6405 28.6405 90 0 1 2.45 2.842",
        "a 26.4845 26.4845 90 0 1 3.8955 7.693",
        "a 1.47 1.47 90 0 0 0.9555 0.98 l 4.0915 1.2985 A 5.684 5.684 90 0 1 99.225 56.154 Z m -92.365 -4.9 l 0 -0.441",
        "a 4.753 4.753 90 0 0 -0.1715 -0.539 c -0.5635 -1.372 -1.078 -2.009 -1.6905 -2.107",
        "a 1.6905 1.6905 90 0 0 -1.4945 0.5635",
        "a 1.127 1.127 90 0 0 0 1.2005 c 0.5145 0.98 1.568 1.1025 3.0135 1.2495 Z m -92.365 -4.9 l 0 -0.441",
        "a 4.753 4.753 90 0 0 -0.1715 -0.539 c -0.5635 -1.372 -1.078 -2.009 -1.6905 -2.107",
        "a 1.6905 1.6905 90 0 0 -1.4945 0.5635",
        "a 1.127 1.127 90 0 0 0 1.2005 c 0.5145 0.98 1.568 1.1025 3.0135 1.2495 Z M 29.106 17.15",
        "a 17.15 17.15 90 1 1 25.921 14.504 c 1.0045 0.196 2.0335 0.392 3.0625 0.637",
        "a 1.7395 1.7395 90 0 1 1.274 2.058",
        "a 1.715 1.715 90 0 1 -2.058 1.274",
        "a 45.2025 45.2025 90 0 0 -22.246 0 l -0.4655 0",
        "a 1.715 1.715 90 0 1 -0.441 -3.381 c 1.029 -0.2695 2.058 -0.49 3.087 -0.686 A 17.15 17.15 90 0 1 29.106 17.15 Z m 3.381 -1.47 H 34.3 A 1.666 1.666 90 1 1 34.3 18.865 h -1.7395",
        "a 13.769 13.769 90 0 0 11.76 11.7845 v -1.764",
        "a 1.666 1.666 90 0 1 3.3075 0 v 1.813",
        "a 13.7935 13.7935 90 0 0 12.25 -11.809 h -1.7395 A 1.666 1.666 90 0 1 56.35 17.15",
        "a 1.6905 1.6905 90 0 1 1.666 -1.666 h 1.813",
        "a 13.818 13.818 90 0 0 -12.25 -12.25 v 1.8865",
        "a 1.666 1.666 90 0 1 -3.3075 0 V 3.43 A 13.7445 13.7445 90 0 0 32.487 15.5575 Z m 8.9915 2.2785 l 4.067 1.0045 l 0.4165 0",
        "a 1.274 1.274 90 0 0 0.5145 0",
        "a 0.8085 0.8085 90 0 0 0.3185 -0.1715 l 0.147 0 h 0 l 7.35 -5.635",
        "a 1.6415 1.6415 90 0 0 -2.009 -2.597 L 45.57 15.4595 L 42.2625 14.7",
        "a 1.617 1.617 90 0 0 -1.9845 1.176 A 1.666 1.666 90 0 0 41.4785 17.836 Z",
    ];
    evenOdd = true;
}
