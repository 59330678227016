// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baseStatus--LmJJS{display:inline-flex;align-items:center;height:20px;padding:4px 10px;font-weight:800;font-size:.85rem;text-align:center;background-color:#fff;border:1px solid rgba(0,0,0,0);border-radius:1em}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/index.module.scss"],"names":[],"mappings":"AAAA,mBAAY,mBAAmB,CAAC,kBAAkB,CAAC,WAAW,CAAC,gBAAgB,CAAC,eAAe,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,qBAAqB,CAAC,8BAA8B,CAAC,iBAAiB","sourcesContent":[".baseStatus{display:inline-flex;align-items:center;height:20px;padding:4px 10px;font-weight:800;font-size:.85rem;text-align:center;background-color:#fff;border:1px solid rgba(0,0,0,0);border-radius:1em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseStatus": "baseStatus--LmJJS"
};
export default ___CSS_LOADER_EXPORT___;
