import React from "react";
import Multi from "./Multi";
import Single from "./Single";
export default class Options extends React.Component {
    render() {
        const { multi, select, selected, options } = this.props;
        const processedOptions = Array.isArray(options)
            ? options
            : Object.keys(options)
                .filter((k) => typeof options[k] === "number")
                .map((k) => ({
                value: options[k],
                label: k.replaceAll("_", ` `),
            }));
        if (multi === true) {
            return (React.createElement(Multi, { selected: selected, options: processedOptions, onChange: this.props.change }));
        }
        return (React.createElement(Single, { options: processedOptions, select: select, selected: selected }));
    }
}
