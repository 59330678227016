import { validation } from "@Shared/Validate";
export default class ChildEntity {
    _data = {};
    constructor(data = {}, constraints = {}) {
        this._data = validation(data, constraints);
    }
    toJSON() {
        return this.data;
    }
}
