import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import { actionConstraints } from "./constraints";
import { migrate } from "./migrate";
export default class Action extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), actionConstraints);
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { link, type } = this;
        return {
            ...parent,
            version,
            link,
            type,
        };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get link() {
        return this._data.link;
    }
    get type() {
        return this._data.type;
    }
}
