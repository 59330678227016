import React from "react";
import Badge from "@Components/Badge";
import Button from "@Components/Button";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import Upload from "@Components/Icons/Upload";
import styles from "./index.module.scss";
export default function UploadBills(props) {
    return (React.createElement(ColouredBorderBox, { icon: React.createElement(Upload, null), title: React.createElement(React.Fragment, null,
            "Upload your",
            React.createElement("b", { className: styles.primaryColor }, " Bill\u00A0Reports")), primary: true },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.title }, "While not mandatory, this will give the ideal results."),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.message },
                    React.createElement("div", { className: styles.messageTitle }, "Our analysis identifies:"),
                    React.createElement("div", { className: styles.messageContentGroup },
                        React.createElement("div", { className: styles.messageContent }, "Number of lines on your account"),
                        React.createElement("div", { className: styles.messageContent }, "Number of these lines you use"),
                        React.createElement("div", { className: styles.messageContent }, "Data usage and future needs"),
                        React.createElement("div", { className: styles.messageContent }, "Predicted new contract\u00A0saving")),
                    React.createElement("div", { className: styles.messageText },
                        React.createElement("span", null, "Results shown in\u00A0boxes"),
                        React.createElement(Badge, null, "99"))),
                React.createElement("div", { className: styles.uploadButton },
                    React.createElement(Button, { click: props.upload, className: styles.squareButton }, "UPLOAD BILL REPORTS"))))));
}
