import { Effected, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
export const displayAllowance = (allowance) => {
    const allowanceType = allowance[Effected.TariffComponentAllowanceType];
    const allowanceQtyType = allowance[Effected.TariffComponentAllowanceQtyType];
    const allowanceQty = allowance[Effected.TariffComponentAllowanceQty];
    if (allowanceQtyType === QTYType.Unlimited) {
        return `${allowanceType} Unlimited`;
    }
    return `${allowanceType} ${allowanceQty} ${displayEnum(QTYType, allowanceQtyType)}`;
};
