import AbstractCustomer from "@Shared/Entities/AbstractEntities/Customer";
import { CustomerType } from "@Shared/Entities/AbstractEntities/Customer/enums";
import constraints from "./constraints";
import migrate from "./migrate";
export default class PartnerCustomer extends AbstractCustomer {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get type() {
        return CustomerType.Broker;
    }
    get partnerId() {
        return this._data.partnerId;
    }
    get baseUrl() {
        return `${super.baseUrl}/partners/${this.partnerId}/customers/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { partnerId } = this;
        const { version } = this._data;
        return { ...parent, version, partnerId };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
            contracts: `${this.baseUrl}/contracts`,
            tenders: `${this.baseUrl}/tenders`,
        };
    }
}
