export var Network;
(function (Network) {
    Network[Network["EE"] = 0] = "EE";
    Network[Network["Vodafone"] = 1] = "Vodafone";
    Network[Network["O2"] = 2] = "O2";
    Network[Network["plan.com"] = 3] = "plan.com";
    Network[Network["Other"] = 4] = "Other";
    Network[Network["None"] = 5] = "None";
    Network[Network["Three"] = 6] = "Three";
})(Network || (Network = {}));
export var BillingEngine;
(function (BillingEngine) {
    BillingEngine[BillingEngine["Other"] = 0] = "Other";
    BillingEngine[BillingEngine["EE_Mobile_Manager"] = 2] = "EE_Mobile_Manager";
    BillingEngine[BillingEngine["Vodafone_VCO"] = 3] = "Vodafone_VCO";
    BillingEngine[BillingEngine["Vodafone"] = 4] = "Vodafone";
    BillingEngine[BillingEngine["O2"] = 5] = "O2";
    BillingEngine[BillingEngine["DAISY"] = 6] = "DAISY";
    BillingEngine[BillingEngine["O2 - Plan.com"] = 7] = "O2 - Plan.com";
    BillingEngine[BillingEngine["EE - Plan.com"] = 8] = "EE - Plan.com";
    BillingEngine[BillingEngine["MVNO_Three"] = 9] = "MVNO_Three";
    BillingEngine[BillingEngine["EE_Teams"] = 10] = "EE_Teams";
})(BillingEngine || (BillingEngine = {}));
export const billingEngineToNetwork = (engine) => {
    switch (engine) {
        case BillingEngine.EE_Mobile_Manager:
        case BillingEngine["EE - Plan.com"]:
            return Network.EE;
        case BillingEngine.O2:
        case BillingEngine["O2 - Plan.com"]:
            return Network.O2;
        case BillingEngine.Vodafone:
        case BillingEngine.Vodafone_VCO:
            return Network.Vodafone;
        case BillingEngine.MVNO_Three:
            return Network.Three;
        case BillingEngine.Other:
            return Network.Other;
    }
    return undefined;
};
export var UsageType;
(function (UsageType) {
    UsageType["Unknown"] = "Unknown";
    UsageType["Free_Calls"] = "Free_Calls";
    UsageType["Calls_From_Europe"] = "Calls_From_Europe";
    UsageType["Calls_From_Traveller"] = "Calls_From_Traveller";
    UsageType["Calls_From_ROW"] = "Calls_From_ROW";
    UsageType["Texts_While_Roaming"] = "Texts_While_Roaming";
    UsageType["UK_Calls"] = "UK_Calls";
    UsageType["UK_Texts"] = "UK_Texts";
    UsageType["Idd_UK_Mins_To_Europe"] = "Idd_UK_Mins_To_Europe";
    UsageType["Idd_UK_Mins_To_ROW"] = "Idd_UK_Mins_To_ROW";
    UsageType["Idd_UK_Mins_To_USA_And_Canada"] = "Idd_UK_Mins_To_USA_And_Canada";
    UsageType["Idd_UK_Mins_To_Apac"] = "Idd_UK_Mins_To_Apac";
    UsageType["Idd_Mins_Total"] = "Idd_Mins_Total";
    UsageType["UK_To_International_Texts"] = "UK_To_International_Texts";
    UsageType["Data_UK"] = "Data_UK";
    UsageType["Data_Europe"] = "Data_Europe";
    UsageType["Data_Traveller"] = "Data_Traveller";
    UsageType["Data_ROW"] = "Data_ROW";
    UsageType["Directory"] = "Directory";
    UsageType["Picture_Messages"] = "Picture_Messages";
    UsageType["Other"] = "Other";
    UsageType["Chargeable_Calls"] = "Chargeable_Calls";
    UsageType["Ignore"] = "Ignore";
    UsageType["Data_Only_Lines"] = "Data_Only_Lines";
    UsageType["Roaming_Days_Europe"] = "Roaming_Days_Europe";
    UsageType["Roaming_Days_ROW"] = "Roaming_Days_ROW";
    UsageType["Roaming_Days_Traveller"] = "Roaming_Days_Traveller";
    UsageType["Total_Lines"] = "Total_Lines";
    UsageType["High_UK_Data_Users"] = "High_UK_Data_Users";
    UsageType["Oob_Cost"] = "Oob_Cost";
})(UsageType || (UsageType = {}));
export var UsageEnding;
(function (UsageEnding) {
    UsageEnding[UsageEnding["In_the_next_3_months"] = 0] = "In_the_next_3_months";
    UsageEnding[UsageEnding["In_3_to_6_months"] = 1] = "In_3_to_6_months";
    UsageEnding[UsageEnding["Over_6_months"] = 2] = "Over_6_months";
})(UsageEnding || (UsageEnding = {}));
export var Status;
(function (Status) {
    Status[Status["GrabbingFiles"] = 0] = "GrabbingFiles";
    Status[Status["ProcessingFiles"] = 1] = "ProcessingFiles";
    Status[Status["CategorisingData"] = 2] = "CategorisingData";
    Status[Status["FinishedCategorising"] = 3] = "FinishedCategorising";
})(Status || (Status = {}));
export var DataSize;
(function (DataSize) {
    DataSize[DataSize["Bytes"] = 0] = "Bytes";
    DataSize[DataSize["KB"] = 1] = "KB";
    DataSize[DataSize["MB"] = 2] = "MB";
    DataSize[DataSize["GB"] = 3] = "GB";
    DataSize[DataSize["TB"] = 4] = "TB";
    DataSize[DataSize["PB"] = 5] = "PB";
})(DataSize || (DataSize = {}));
