import * as countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
countries.registerLocale(en);
export const allCountries = Object.keys(countries.getAlpha2Codes());
export const eu = [
    "Austria",
    "Azores",
    "Belgium",
    "Bulgaria",
    "Canary Islands",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Faroe Islands",
    "Finland",
    "France",
    "French Guiana",
    "Germany",
    "Gibraltar",
    "Greece",
    "Guadeloupe",
    "Guernsey",
    "Holy See (Vatican City State)",
    "Hungary",
    "Iceland",
    "Ireland",
    "Isle of Man",
    "Italy",
    "Jersey",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madeira",
    "Malta",
    "Martinique",
    "Mayotte",
    "Monaco",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Reunion",
    "Romania",
    "Saint Barthelemy",
    "Saint Martin (French part)",
    "San Marino",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
]
    .map((name) => countries.getAlpha2Code(name, "en"))
    .filter((a2) => a2 !== undefined);
eu.push("EU");
export const usaAndCanada = [
    "United States of America",
    "Canada",
    "Cocos Islands",
    "Northern Mariana Islands",
]
    .map((name) => countries.getAlpha2Code(name, "en"))
    .filter((a2) => a2 !== undefined);
usaAndCanada.push("USC");
export const apac = [
    "Australia",
    "Christmas Island",
    "Hong Kong",
    "Japan",
    "Malaysia",
    "New Zealand",
    "Singapore",
    "South Africa",
]
    .map((name) => countries.getAlpha2Code(name, "en"))
    .filter((a2) => a2 !== undefined);
apac.push("APAC");
export const row = allCountries.filter((c) => {
    return !(eu.includes(c) || usaAndCanada.includes(c) || apac.includes(c));
});
