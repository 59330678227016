import dayjs from "dayjs";
import { fix } from "postcode";
import React from "react";
import Badge from "@Components/Badge";
import Button from "@Components/Button";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import HoverTooltip from "@Components/HoverTooltip";
import Filter from "@Components/Icons/Filter";
import Gears from "@Components/Icons/Gears";
import KeepingAll from "@Components/Icons/Hardware/KeepingAll";
import KeepingSome from "@Components/Icons/Hardware/KeepingSome";
import Locked from "@Components/Icons/Hardware/Locked";
import Unlocked from "@Components/Icons/Hardware/Unlocked";
import Basic from "@Components/Icons/Management/Basic";
import Enterprise from "@Components/Icons/Management/Enterprise";
import SME from "@Components/Icons/Management/SME";
import No from "@Components/Icons/No";
import Popcorn from "@Components/Icons/Popcorn";
import PredictMascotLeftIcon from "@Components/Icons/PredictMascotLeft";
import Overall from "@Components/Icons/Scores/Overall";
import Tick from "@Components/Icons/Tick";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import buttons from "@Components/Sass/buttons.module.scss";
import Tooltip from "@Components/Tooltip";
import getBrokerName from "@Helpers/GetBrokerName";
import { ATCUnits, ChangeNetwork, DEPChoices, DetailsFields, DevicesLocked, EuCostCountries, ExistingDevices, FiveG, HighCostCountries, LeasingType, LikeHardwareFunded, ManagerPackageTypes, MDMInLine, Months, SimOnlyFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Sections } from "@Shared/Entities/Customer/Tender/enums";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import Factory from "@Shared/Factory";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import { getRemainingLines } from "@Shared/Helpers/TenderLines";
import { validatePostcode } from "@Shared/Validate";
import { usagePercentageTarget } from "@SmartComponents/TenderEdit";
import DevicesInput from "../Devices/DevicesInput";
import styles from "./index.module.scss";
export default class TenderForm extends React.Component {
    constructor(props) {
        super(props);
        const { details } = this.props.tender || {};
        let data = {};
        if (details !== undefined) {
            data = details.data;
        }
        this.state = {
            currentEndDate: dayjs.utc(),
            data,
            completedSections: {},
        };
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousState.data.sameTime !== this.state.data.sameTime) {
            const data = {
                ...this.state.data,
                dates: [],
                noLines: [],
                repeatedDateQuestions: 1,
            };
            this.setState({
                data,
            });
        }
        if (previousState.data.repeatedDateQuestions >
            this.state.data.repeatedDateQuestions) {
            const { repeatedDateQuestions, dates, noLines } = this.state.data;
            while (dates.length > repeatedDateQuestions) {
                dates.pop();
            }
            while (noLines.length > repeatedDateQuestions) {
                noLines.pop();
            }
            this.setState({ data: { ...this.state.data, dates, noLines } });
        }
        if (previousState.data.priceRange !== this.state.data.priceRange) {
            const { data } = this.state;
            this.setState({
                data: {
                    ...data,
                    model: [],
                    noDevices: [],
                    pickedDevices: 1,
                },
            });
        }
        if (previousProps.tender !== this.props.tender) {
            this.setState({ data: this.props.tender.details.data });
        }
        if (previousProps.scrollTo !== this.props.scrollTo &&
            this.props.scrollTo !== undefined) {
            const sectionElement = document.querySelector(`#section-${this.props.scrollTo}`);
            sectionElement?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            this.props.unsetScrollTo();
        }
        if (previousProps.errors !== this.props.errors &&
            this.props.errors.postcodes) {
            this.updateOpenSections(Sections.NetworkProviderOptions)(true);
        }
    }
    ATCUnits = (name, value) => {
        return this.inputChange({ name, value });
    };
    postcodeCheck = (text) => {
        if (validatePostcode(text) === true) {
            return fix(text);
        }
    };
    inputChange = async (input) => {
        this.props.update(input);
    };
    getTitle = () => {
        let title, text, icon, titleGap;
        if (this.props.tender.usageId === undefined) {
            titleGap = styles.altTitleGap;
            title = "Build your Tender - it's quick and easy";
            text = (React.createElement(React.Fragment, null, "Questions can be completed in any order and are saved as you go. Just a few minutes and you\u2019re finished!"));
            icon = React.createElement(Filter, null);
        }
        else if (this.props.usagePercentage < usagePercentageTarget) {
            titleGap = styles.altTitleGap;
            title = "Your savings prediction is on its way";
            text = (React.createElement(React.Fragment, null, "Information will start to populate the coloured boxes. The questions below only take a few minutes to complete."));
            icon = React.createElement(Gears, null);
        }
        else if (this.props.usagePercentage >= usagePercentageTarget) {
            titleGap = styles.normalTitleGap;
            title = "Almost finished";
            text = (React.createElement(React.Fragment, null, "Please complete the questions and collect the remaining ticks, submit and publish your Tender to our expert panel of the UK\u2019s best independent suppliers. Your current supplier can be invited into the process after you have published."));
            icon = React.createElement(Popcorn, null);
        }
        return (React.createElement("div", { className: `${styles.titleWrapper} ${titleGap}` },
            React.createElement("div", { className: styles.titleIcon }, icon),
            React.createElement("div", { className: styles.textWrapper },
                React.createElement("div", { className: styles.mainTitle }, title),
                React.createElement("div", { className: styles.subtext }, text))));
    };
    updateSection = (section) => {
        try {
            const tender = Factory.Tender({
                ...this.props.tender.data,
                section: section,
            });
            return this.props.saveTender(tender);
        }
        catch {
            return Promise.resolve();
        }
    };
    nextSection = (section, ignoreAdditionalInfo = true) => () => {
        const { openSections = {} } = this.props;
        const previousCompletedSections = {
            ...this.props.completedSections,
        };
        const completedSections = this.props.completedSection(ignoreAdditionalInfo, section);
        const completed = completedSections[section];
        const recentlyChangedSections = Object.keys(completedSections)
            .filter((key) => {
            const section = Number(key);
            return (completedSections[section] !==
                previousCompletedSections[section]);
        })
            .reduce((object, key) => {
            const section = Number(key);
            object[section] = completedSections[section];
            return object;
        }, {});
        const time = completed && recentlyChangedSections[section] ? 3500 : 0;
        this.setState({
            completedSections: {
                ...this.state.completedSections,
                ...recentlyChangedSections,
            },
        });
        setTimeout(() => {
            openSections[section] = false;
            openSections[section + 1] = true;
            this.props.updateOpenSections(openSections);
        }, time);
        this.updateSection(section);
    };
    updateOpenSections = (section) => (value) => {
        const { openSections = {} } = this.props;
        openSections[section] = value;
        this.props.updateOpenSections(openSections);
    };
    displayStar = (section) => {
        let position = styles.completedPosition;
        let awarded = "";
        if (!this.props.completedSections[section]) {
            position = "";
            if (!this.props.openSections[section]) {
                return React.createElement(React.Fragment, null);
            }
        }
        if (this.state.completedSections[section]) {
            awarded = styles.awarded;
        }
        return React.createElement(Tick, { className: `${styles.star} ${position} ${awarded}` });
    };
    calculateWidth = (list) => {
        const maxLength = Math.max(...list.map((lines) => {
            return lines.toString().length;
        }), 1);
        return maxLength;
    };
    displayPublishButton = () => {
        const { tender, usagePercentage } = this.props;
        if (tender.usageId !== undefined &&
            usagePercentage < usagePercentageTarget) {
            return (React.createElement(Button, { click: this.props.save, className: styles.button, cta: true }, "PUBLISH"));
        }
        if (tender.publishedAt === undefined) {
            return (React.createElement(Button, { click: this.props.publish, className: styles.button, cta: true }, "PUBLISH"));
        }
        return (React.createElement(Button, { click: this.props.publish, className: styles.button, cta: true }, "UPDATE"));
    };
    render() {
        const { tender } = this.props;
        if (tender === undefined) {
            return React.createElement(LoadingFallback, null);
        }
        const noContract = tender.usageId === undefined;
        const { stats } = tender;
        const recommendedData = stats.recommendedData;
        const data = {
            ...this.state.data,
            recommendedData,
        };
        const type = data.ATC >= 100 ? InputType.Currency : InputType.Number;
        if (data.atc >= 100) {
            data.atcUnits = ATCUnits.Pounds;
        }
        data[DetailsFields.RealMonthlyCosts] = roundToFormat(tender.netCost, 0);
        const { linesLeftSix, linesLeftThree } = getRemainingLines(this.props.tender);
        const buildNewTender = [
            {
                label: "How many voice lines with data do you want?",
                name: DetailsFields.VoiceLines,
                type: InputType.Lines,
                notes: (React.createElement("div", null,
                    React.createElement(Badge, null, roundToFormat(stats.usedVoiceLines, 0)),
                    " ",
                    "voice lines with data are currently used")),
            },
            {
                label: "How many data only lines do you want?",
                name: DetailsFields.DataLines,
                type: InputType.Lines,
                placeholder: "Leave blank if N/A",
                notes: (React.createElement("div", null,
                    React.createElement(Badge, null, roundToFormat(stats.dataLines, 0)),
                    " data only lines are currently used",
                    React.createElement(HoverTooltip, null, "This is an estimate, we only measure lines that have exclusively used data. On occasion, this may be a voice with data\u00A0line."))),
            },
            {
                label: "How many voice only lines do you want?",
                name: DetailsFields.VoiceOnlyLines,
                type: InputType.Lines,
                placeholder: "Leave blank if N/A",
                notes: (React.createElement("div", null,
                    React.createElement(Badge, null, roundToFormat(stats.voiceOnlyLines, 0)),
                    " ",
                    "voice only lines are currently used",
                    React.createElement(HoverTooltip, null, "This is an estimate, we only measure lines that have exclusively used voice and sms. On occasion, this may be a voice with data\u00A0line."))),
            },
        ];
        const contractDates = [
            {
                label: "What is your preferred mobile contract length?",
                name: DetailsFields.Length,
                type: InputType.DropdownOptions,
                options: [
                    {
                        value: 12,
                        label: "12 months",
                    },
                    {
                        value: 24,
                        label: "24 months",
                    },
                    {
                        value: 36,
                        label: "36 months",
                    },
                ],
                notes: (React.createElement("div", null,
                    "We recommend ",
                    React.createElement("b", { className: styles.blue }, "24"),
                    " months, as the optimum value contract length in terms of cost vs benefit")),
            },
            {
                label: (React.createElement(React.Fragment, null,
                    "When in the next twelve months will the majority of your current lines be up for renewal?",
                    React.createElement(Tooltip, null, "This information is not in your billing, if unknown your current supplier or network customer services can supply it. If you do not have a contract select the current month."))),
                notes: "This is when the vast majority of your current lines will be out of contract",
                name: DetailsFields.VastMajorityLinesRenewal,
                type: InputType.DropdownOptions,
                options: this.props.months,
            },
            {
                label: (React.createElement("div", null,
                    "How many lines will be up for renewal in",
                    " ",
                    React.createElement("b", { className: styles.blue }, Months[data.vastMajorityLinesRenewal]),
                    "?")),
                name: DetailsFields.LinesBeginning,
                type: InputType.Lines,
                blue: true,
                hidden: data.vastMajorityLinesRenewal === undefined,
            },
            {
                label: (React.createElement("div", null,
                    "How many lines will be up for renewal between",
                    " ",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 1) % 12]),
                    " ",
                    "&",
                    " ",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 2) % 12]),
                    "?")),
                name: DetailsFields.LinesWithinThreeMonths,
                type: InputType.Lines,
                blue: true,
                hidden: data[DetailsFields.LinesBeginning] === undefined ||
                    linesLeftThree <= 0,
            },
            // Add statement of how many line renewing after 3+ months
            {
                label: (React.createElement("div", null,
                    "Are your remaining",
                    " ",
                    React.createElement("b", { className: styles.blue }, linesLeftSix),
                    " lines in contract renewing between",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 3) % 12]),
                    " ",
                    "&",
                    " ",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 5) % 12]),
                    "?")),
                hidden: data[DetailsFields.LinesBeginning] === undefined ||
                    data[DetailsFields.LinesWithinThreeMonths] === undefined ||
                    linesLeftSix <= 0,
                name: DetailsFields.LinesWithinSixMonths,
                type: InputType.SmallToggle,
                green: true,
            },
            {
                label: (React.createElement("div", null,
                    "If you anticipate adding new lines between",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 3) % 12]),
                    " ",
                    "&",
                    " ",
                    React.createElement("b", { className: styles.blue },
                        " ",
                        Months[(data.vastMajorityLinesRenewal + 5) % 12]),
                    " ",
                    "how many more",
                    React.createElement("b", { className: styles.blue }, " voice lines with data "),
                    "will you need? This is for indicative purposes only.")),
                name: DetailsFields.EstimatedLines,
                type: InputType.Lines,
                blue: true,
                placeholder: "Leave blank if N/A",
            },
            {
                label: (React.createElement("div", null,
                    "And, how many more",
                    " ",
                    React.createElement("b", { className: styles.blue }, " data only "),
                    "lines will you need? Again for indicative purposes only.")),
                name: DetailsFields.EstimatedDataLines,
                type: InputType.Lines,
                blue: true,
                placeholder: "Leave blank if N/A",
            },
        ];
        const discountsBenefits = [
            {
                label: "Do you receive a monthly discount from your supplier?",
                name: DetailsFields.ATC,
                type: type,
                blue: true,
                placeholder: "Leave blank if N/A",
                notes: "If you receive any monthly discount not detailed on your network bill, sometimes called a rebate, cashback or Air Time Credit (ATC), please enter the total monthly amount here. This allows for an accurate comparison of costs & savings",
                hidden: noContract,
            },
            {
                label: "Is this in pounds or a percentage?",
                name: DetailsFields.ATCUnits,
                type: InputType.LargeToggle,
                long: true,
                toggleOptions: [
                    {
                        value: ATCUnits.Pounds,
                        label: "Pounds (£)",
                    },
                    {
                        value: ATCUnits.Percentage,
                        label: "Percentage (%)",
                    },
                ],
                hidden: !(data.ATC !== undefined &&
                    data.ATC > 0 &&
                    data.ATC < 100) || noContract,
            },
            {
                label: (React.createElement(React.Fragment, null, "Do you also receive additional benefits such as a hardware or tech fund in addition to the monthly line rental discounts?")),
                name: DetailsFields.TechFund,
                type: InputType.Currency,
                blue: true,
                noDecimal: true,
                notes: (React.createElement(React.Fragment, null,
                    "If yes please enter the",
                    " ",
                    React.createElement("b", { className: styles.blue }, "total"),
                    " amount for the life of your contract here, if not leave blank")),
                hidden: noContract,
                placeholder: "Leave blank if N/A",
            },
            {
                label: "Your total net monthly cost is therefore",
                notes: "Total of your current contract fixed monthly tariff & avg. charges outside your allowance minus your discounts & benefits",
                name: DetailsFields.RealMonthlyCosts,
                disabled: true,
                type: InputType.FakeCurrency,
                hidden: noContract,
            },
        ];
        const currentCosts = [
            {
                label: "If you already have a contract, what is your net monthly cost?",
                notes: (React.createElement(React.Fragment, null,
                    "Or upload your Bill Reports for best results:",
                    React.createElement(Button, { click: this.props.upload, className: styles.uploadReminder }, "UPLOAD BILL REPORTS"))),
                name: DetailsFields.ManualCurrentCost,
                placeholder: "Leave blank if N/A",
                type: InputType.Currency,
                hidden: !noContract,
            },
        ];
        const dataIDDRoaming = [
            {
                label: "Data, how many GB of data do you want?",
                name: DetailsFields.OverallData,
                type: InputType.GB,
                blue: true,
                noDecimal: true,
                notes: (React.createElement("div", null,
                    "You are currently using",
                    " ",
                    React.createElement(Badge, null, roundToFormat(stats.averageData, 0)),
                    " GB of data. To allow for cost-effective growth, we recommend a minimum of",
                    " ",
                    React.createElement(Badge, null, roundToFormat(recommendedData, 0)),
                    " GB.")),
            },
            {
                label: "Do you anticipate a significant increase in data usage within the first six months of the contract term?",
                name: DetailsFields.IncreaseUsage,
                type: InputType.SmallToggle,
                notes: "This might include changes such as a move to Microsoft 365, staff working remotely on video apps etc.",
                green: true,
            },
            {
                label: "Are you moving to Microsoft 365?",
                name: DetailsFields.Microsoft365,
                type: InputType.SmallToggle,
                hidden: data[DetailsFields.IncreaseUsage] !== true,
            },
            {
                label: "Do you use Teams?",
                name: DetailsFields.OnlineTeams,
                type: InputType.SmallToggle,
                hidden: data[DetailsFields.IncreaseUsage] !== true,
            },
            {
                label: "Do you use Zoom?",
                name: DetailsFields.Zoom,
                type: InputType.SmallToggle,
                hidden: data[DetailsFields.IncreaseUsage] !== true,
            },
            {
                label: "Is there an acquisition?",
                name: DetailsFields.DataAcquisition,
                type: InputType.SmallToggle,
                hidden: true,
            },
            {
                label: "Any other reason why you anticipate a significant change in data usage?",
                notes: "Most businesses have a few heavy users of data & many more lighter users.  Our suppliers have visibility of this split & will meet these needs accordingly which may include a number of unlimited data sims as appropriate. If you require as a mandatory requirement all lines to be with unlimited data & understand the higher cost implications please detail this in the comments box",
                name: DetailsFields.OtherDataIncrease,
                type: InputType.LargeText,
                placeholder: "Leave blank if N/A",
                hidden: data[DetailsFields.IncreaseUsage] !== true,
            },
            {
                label: (React.createElement(React.Fragment, null,
                    "Do you anticipate an increase of calls from the",
                    " ",
                    React.createElement("b", { className: styles.blue }, "UK to other countries"),
                    "?")),
                notes: "These are called international direct dial (IDD) calls",
                name: DetailsFields.IDDCost,
                type: InputType.SmallToggle,
                green: true,
            },
            {
                label: "Which regions?",
                notes: "Generally, mobile networks have cost saving bolt-ons related to regions rather than specific countries. Suppliers will add these bolt-ons based on your current usage",
                name: DetailsFields.IDDCostCountries,
                hidden: data[DetailsFields.IDDCost] !== true,
                type: InputType.DropdownEnum,
                multi: true,
                options: EuCostCountries,
            },
            {
                label: (React.createElement(React.Fragment, null,
                    "Do you anticipate using your mobile in",
                    React.createElement("b", { className: styles.blue }, " non UK countries"),
                    "?")),
                notes: "This is known as Roaming usage & covers calls, texts & data",
                name: DetailsFields.RoamingCost,
                type: InputType.SmallToggle,
                green: true,
            },
            {
                label: "Which regions?",
                notes: "Generally, mobile networks have cost saving bolt-ons related to regions rather than specific countries. Suppliers will add these bolt-ons based on your current usage",
                name: DetailsFields.RoamingCostCountries,
                hidden: data[DetailsFields.RoamingCost] !== true,
                type: InputType.DropdownEnum,
                multi: true,
                options: EuCostCountries,
            },
            {
                label: (React.createElement(React.Fragment, null,
                    "Do you anticipate a significant increase within the contract term in usage in",
                    React.createElement("b", { className: styles.blue }, " countries "),
                    " that may not have access to a cost-reducing bolt-on?")),
                notes: (React.createElement(React.Fragment, null,
                    "If unsure click ",
                    React.createElement("b", { className: styles.blue }, "yes"),
                    " & check the list of countries")),
                name: DetailsFields.HighCost,
                type: InputType.SmallToggle,
                green: true,
            },
            {
                label: "If yes, which countries?",
                name: DetailsFields.HighCostCountries,
                hidden: data[DetailsFields.HighCost] !== true,
                type: InputType.DropdownEnum,
                multi: true,
                options: HighCostCountries,
            },
        ];
        const networkProvider = [
            {
                label: "What is your current network?",
                notes: "When using manual uploads sometimes it is not possible for us to determine your current network provider",
                name: DetailsFields.CurrentNetwork,
                type: InputType.DropdownEnum,
                options: Network,
            },
            {
                label: (React.createElement(React.Fragment, null,
                    "Are you wanting to switch from ",
                    React.createElement("br", null),
                    React.createElement("b", { className: styles.blue },
                        displayEnum(Network, data.currentNetwork),
                        " "),
                    "to another network?")),
                name: DetailsFields.ChangeNetwork,
                type: InputType.RadioGroup,
                options: [
                    {
                        label: "Yes, I want to change network",
                        value: ChangeNetwork["Yes, I want to change"],
                    },
                    {
                        label: "No, I'm happy where I am",
                        value: ChangeNetwork["No, I am happy where I am"],
                    },
                    {
                        label: "I don't mind",
                        value: ChangeNetwork["I don't mind"],
                    },
                ],
                hidden: data.currentNetwork === Network.None,
            },
            {
                name: DetailsFields.MultipleNetwork,
                type: InputType.SmallToggle,
                label: "Do you currently use more than one network?",
                notes: "Please detail how many lines & which network in the additional information box at the bottom of the Tender",
                green: true,
            },
            {
                label: "If there are postcodes you want suppliers to check the signal at please enter them here?",
                name: DetailsFields.Postcodes,
                type: InputType.MultiAddText,
                placeholder: "Please enter at least 1 postcode",
                validText: (text) => this.postcodeCheck(text),
                separators: [",", " "],
                limit: 30,
                notes: (React.createElement(React.Fragment, null,
                    "Supplier will check the network signal at these locations & report back, however ",
                    getBrokerName(),
                    " also measures whether suppliers have actually checked & shown the appropriate level of due diligence required from a quality supplier.")),
            },
        ];
        const handsetsDevices = [
            // Existing
            {
                label: "Are you keeping your existing devices?",
                name: DetailsFields.ExistingDevices,
                type: InputType.LargeToggle,
                toggleOptions: [
                    {
                        label: "Yes, all of them",
                        value: ExistingDevices["Yes, all of them"],
                        icon: KeepingAll,
                    },
                    {
                        label: "Yes, some",
                        value: ExistingDevices["Yes, some"],
                        icon: KeepingSome,
                    },
                    {
                        label: "None",
                        value: ExistingDevices.None,
                        icon: No,
                        size: "2em",
                    },
                ],
            },
            {
                label: "Are these existing devices locked or unlocked?",
                name: DetailsFields.Locked,
                type: InputType.LargeToggle,
                hidden: data[DetailsFields.ExistingDevices] === undefined ||
                    data[DetailsFields.ExistingDevices] ===
                        ExistingDevices.None,
                toggleOptions: [
                    {
                        label: "Locked",
                        value: DevicesLocked.Yes,
                        icon: Locked,
                    },
                    {
                        label: "Unlocked",
                        value: DevicesLocked.No,
                        icon: Unlocked,
                    },
                    {
                        label: "Don't know / mixed",
                        value: DevicesLocked["Don't know/mixed"],
                        icon: No,
                        size: "2em",
                    },
                ],
            },
            // New devices
            {
                label: "Do you require any new handsets?",
                name: DetailsFields.Handsets,
                type: InputType.LargeToggle,
                long: true,
                toggleOptions: [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ],
            },
            {
                label: (React.createElement("div", null,
                    "You have selected a",
                    React.createElement("b", { className: styles.blue }, " sim only "),
                    "option")),
                name: DetailsFields.SimOnlyFunded,
                type: InputType.LargeToggle,
                long: true,
                small: true,
                hidden: data[DetailsFields.Handsets] !== false,
                toggleOptions: [
                    {
                        label: "To lower my monthly payments",
                        value: SimOnlyFunded.To_lower_my_monthly_payments,
                    },
                    {
                        label: "To receive a tech fund to spend as I wish",
                        value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                    },
                ],
                notes: "However you may qualify for additional financial support. If applicable, let us know how you would like to receive it from the supplier.",
            },
            {
                label: "Are all devices required to be 5G compatible?",
                type: InputType.LargeToggle,
                name: DetailsFields.FiveG,
                hidden: data[DetailsFields.Handsets] !== true,
                toggleOptions: [
                    {
                        label: "Yes",
                        value: FiveG.Yes,
                    },
                    {
                        label: "No",
                        value: FiveG.No,
                    },
                    {
                        label: "Don't mind",
                        value: FiveG["Don't mind"],
                    },
                ],
            },
            {
                label: "",
                type: InputType.RepeatGroup,
                name: DetailsFields.PickedHandsets,
                hidden: !(data[DetailsFields.Handsets] === true),
                groupInputs: DevicesInput(this.props.deviceOptions),
                defaultObj: {},
            },
            {
                label: "If you want a device enrolment solution for your selected handsets, please select.",
                notes: (React.createElement(React.Fragment, null,
                    "A device enrolment solution helps easily set up, deploy & configure devices remotely from a single admin user.",
                    data[DetailsFields.DEP] ===
                        DEPChoices["Apple (DEP)"] && (React.createElement(React.Fragment, null, "For Apple devices this is called Device Enrolment Programme (DEP)")),
                    data[DetailsFields.DEP] ===
                        DEPChoices["Android (AZT)"] && (React.createElement(React.Fragment, null, "For Android devices this is called Android Zero Touch (AZT)")))),
                type: InputType.LargeToggle,
                toggleOptions: [
                    {
                        label: "Not required",
                        value: DEPChoices["Not required"],
                    },
                    {
                        label: "Apple",
                        value: DEPChoices["Apple (DEP)"],
                    },
                    {
                        label: "Android",
                        value: DEPChoices["Android (AZT)"],
                    },
                    {
                        label: "Both",
                        value: DEPChoices["Both"],
                    },
                ],
                name: DetailsFields.DEP,
                hidden: data[DetailsFields.Handsets] !== true,
            },
            {
                label: "You can fund your devices in a number of different ways",
                notes: (React.createElement(React.Fragment, null,
                    this.props.tender.details.likeHardwareFunded ===
                        LikeHardwareFunded.Included_in_tariff && (React.createElement("div", null,
                        "This is a",
                        " ",
                        React.createElement("b", { className: styles.blue }, "device fund"),
                        ", the value of the fund depends upon the tariff that is offered. Depending on the handsets you have chosen we can not guarantee there will be no upfront cost but suppliers will do their best to minimise it.")),
                    this.props.tender.details.likeHardwareFunded ===
                        LikeHardwareFunded.Outright_Purchase && (React.createElement("div", null, "Depending on the tariff, the device cost can sometimes be less than the normal market price as suppliers can subsidise the cost.")),
                    this.props.tender.details.likeHardwareFunded ===
                        LikeHardwareFunded.Leasing && (React.createElement("div", null, "Finance agreement that sits alongside but is separate to your mobile contract. You will be signing two agreements, one for the handsets & the other for airtime.")))),
                name: DetailsFields.LikeHardwareFunded,
                type: InputType.LargeToggle,
                hidden: data[DetailsFields.Handsets] !== true,
                toggleOptions: [
                    {
                        label: "Included in tariff",
                        value: LikeHardwareFunded.Included_in_tariff,
                    },
                    {
                        label: (React.createElement("div", null,
                            "Outright",
                            React.createElement("br", null),
                            " purchase")),
                        value: LikeHardwareFunded.Outright_Purchase,
                    },
                    {
                        label: "Leasing",
                        value: LikeHardwareFunded.Leasing,
                    },
                ],
            },
            {
                label: (React.createElement("div", null,
                    "There are two types of handset leasing.",
                    " ",
                    React.createElement("b", { className: styles.blue }, "Residual value"),
                    " &",
                    " ",
                    React.createElement("b", { className: styles.blue }, "lease purchase"),
                    ". Which of the following statements best describes your normal preference?")),
                name: DetailsFields.LeasingType,
                type: InputType.LargeToggle,
                long: true,
                small: true,
                hidden: data[DetailsFields.Handsets] !== true ||
                    data[DetailsFields.LikeHardwareFunded] !==
                        LikeHardwareFunded.Leasing,
                toggleOptions: [
                    {
                        label: "I like new handsets every time I start a new contract",
                        value: LeasingType.Residual_value,
                    },
                    {
                        label: "I retain existing handsets until they stop working then replace them",
                        value: LeasingType.Lease_purchase,
                    },
                ],
            },
            {
                label: (React.createElement("div", null,
                    "By choosing leasing you separate the hardware from the airtime contract, this is often known as a",
                    " ",
                    React.createElement("b", { className: styles.blue }, " sim only "),
                    "solution.")),
                name: DetailsFields.SimOnlyFunded,
                type: InputType.LargeToggle,
                long: true,
                small: true,
                hidden: data[DetailsFields.Handsets] !== true ||
                    data[DetailsFields.LikeHardwareFunded] !==
                        LikeHardwareFunded.Leasing,
                toggleOptions: [
                    {
                        label: "To lower my monthly payments",
                        value: SimOnlyFunded.To_lower_my_monthly_payments,
                    },
                    {
                        label: "To receive a tech fund to spend as I wish",
                        value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                    },
                ],
                notes: "However you may qualify for additional financial support due to the airtime tariff commissions. If applicable, how you would like to receive these funds from the supplier.",
            },
        ];
        const mdm = [
            {
                label: "Do you currently have Mobile Device Management (MDM)?",
                name: DetailsFields.OwnMDM,
                type: InputType.SmallToggle,
                green: true,
            },
            {
                label: "Do you use Microsoft InTune?",
                name: DetailsFields.MDMMSInTune,
                type: InputType.SmallToggle,
                hidden: data[DetailsFields.OwnMDM] !== true,
                green: true,
            },
            {
                label: "Does the contract for MDM run in line with your mobile phone contract?",
                hidden: data[DetailsFields.OwnMDM] !== true ||
                    data[DetailsFields.MDMMSInTune] === true,
                name: DetailsFields.MDMInLine,
                type: InputType.LargeToggle,
                toggleOptions: [
                    {
                        label: "Yes",
                        value: MDMInLine.Yes,
                    },
                    {
                        label: "No",
                        value: MDMInLine.No,
                    },
                    {
                        label: (React.createElement("div", null,
                            "No fixed",
                            React.createElement("br", null),
                            " contract")),
                        value: MDMInLine.No_Fixed_Contract,
                    },
                ],
            },
            {
                label: "When does your MDM contract end?",
                hidden: data[DetailsFields.OwnMDM] !== true ||
                    data[DetailsFields.MDMMSInTune] === true ||
                    data[DetailsFields.MDMInLine] !== MDMInLine.No,
                name: DetailsFields.MDMEnd,
                type: InputType.Calendar,
                notes: "If unknown leave blank",
            },
            {
                label: "Do you pay a monthly fee for the MDM?",
                hidden: data[DetailsFields.OwnMDM] !== true ||
                    data[DetailsFields.MDMMSInTune] === true,
                name: DetailsFields.MDMFee,
                type: InputType.SmallToggle,
            },
            {
                label: (this.props.tender.details.ownMDM === true &&
                    data[DetailsFields.MDMMSInTune] === true && (React.createElement(React.Fragment, null, "Do you require more functionality using an alternative MDM?"))) ||
                    (this.props.tender.details.ownMDM === true && (React.createElement(React.Fragment, null, "What level of Mobile Device Management (MDM) do you require?"))) ||
                    (this.props.tender.details.ownMDM !== true && (React.createElement(React.Fragment, null, "If required, what level of Mobile Device Management (MDM) do you want?"))),
                name: DetailsFields.MDM,
                type: InputType.LargeToggle,
                toggleOptions: [
                    {
                        value: ManagerPackageTypes.Bronze,
                        label: "Bronze",
                        icon: Basic,
                    },
                    {
                        value: ManagerPackageTypes.Silver,
                        label: "Silver",
                        icon: SME,
                    },
                    {
                        value: ManagerPackageTypes.Gold,
                        label: "Gold",
                        icon: Enterprise,
                    },
                    {
                        value: ManagerPackageTypes.None,
                        label: "None",
                        icon: No,
                        size: "2em",
                    },
                ],
                notes: (React.createElement(React.Fragment, null,
                    this.props.tender.details.MDM ===
                        ManagerPackageTypes.Bronze && (React.createElement("div", null, "Sometimes MDM is included in your plan. Features such as remote wipe, lock device & password configuration are included")),
                    this.props.tender.details.MDM ===
                        ManagerPackageTypes.Silver && (React.createElement("div", null, "Generally a chargeable cloud based solution, features including app whitelist/blacklist, disable handset features & app installation, etc.")),
                    this.props.tender.details.MDM ===
                        ManagerPackageTypes.Gold && (React.createElement("div", null, "Full feature suit, dedicated server, features include remote view, geofencing, data limits & url blacklists, etc.")))),
            },
        ];
        const additionalInfo = [
            {
                label: "Are you VAT registered?",
                green: true,
                notes: (React.createElement(React.Fragment, null,
                    data[DetailsFields.VATRegistered] !== true && (React.createElement("div", null, "We will automatically show you the costs including VAT")),
                    data[DetailsFields.VATRegistered] === true && React.createElement("div", null))),
                name: DetailsFields.VATRegistered,
                type: InputType.SmallToggle,
            },
            {
                label: "Are you a charity or social enterprise?",
                green: true,
                name: DetailsFields.CharitySocialEnterprise,
                type: InputType.SmallToggle,
            },
            {
                label: "Additional information",
                name: DetailsFields.ExtraDetails,
                type: InputType.LargeText,
                placeholder: "Leave blank if N/A",
                characters: 2000,
                notes: (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        "Please add any further information you think would be useful for the suppliers to know, so they can fine tune their Proposal to meet your needs. For example,",
                        React.createElement("li", null, "if you have a few users currently on a different network"),
                        React.createElement("li", null, "if you require e-sims"),
                        React.createElement("li", null, "if you need all lines to have unlimited data"),
                        React.createElement("li", null, "if you anticipate any event where a significant increase in users will occur"),
                        "Not all requirements will be possible to accommodate however suppliers will - within the possibilities of the mobile market - try to facilitate your requirements in a cost effective manner"))),
            },
        ];
        const { openSections = {} } = this.props;
        return (React.createElement("div", { className: styles.formWrapper },
            React.createElement("div", null,
                React.createElement(Card, { title: this.getTitle(), className: styles.card })),
            React.createElement("div", { className: styles.section, id: `section-${Sections.LineRequirements}` },
                this.displayStar(Sections.LineRequirements),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Line Requirements"),
                        React.createElement("div", { className: styles.subtitle },
                            React.createElement("div", null,
                                React.createElement(Badge, null, stats.totalLines),
                                " lines on your account"),
                            noContract && (React.createElement(React.Fragment, null,
                                React.createElement(Button, { click: this.props.upload, className: styles.uploadSubtitleReminder }, "UPLOAD BILL REPORTS"))))), collapsible: true, sticky: true, open: openSections[Sections.LineRequirements], onToggle: this.updateOpenSections(Sections.LineRequirements), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: buildNewTender },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.LineRequirements) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.ContractEndDates}` },
                this.displayStar(Sections.ContractEndDates),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Contract end dates")), className: styles.card, collapsible: true, sticky: true, open: openSections[Sections.ContractEndDates], onToggle: this.updateOpenSections(Sections.ContractEndDates) },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: contractDates },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.ContractEndDates), className: styles.nextButton }, "NEXT"))))),
            noContract === false && (React.createElement("div", { className: styles.section, id: `section-${Sections.CurrentCostsOrDiscountsAndBenefits}` },
                this.displayStar(Sections.CurrentCostsOrDiscountsAndBenefits),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Discounts & benefits")), collapsible: true, sticky: true, open: openSections[Sections.CurrentCostsOrDiscountsAndBenefits], onToggle: this.updateOpenSections(Sections.CurrentCostsOrDiscountsAndBenefits), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: discountsBenefits },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.CurrentCostsOrDiscountsAndBenefits) }, "NEXT")))))),
            noContract && (React.createElement("div", { className: styles.section, id: `section-${Sections.CurrentCostsOrDiscountsAndBenefits}` },
                this.displayStar(Sections.CurrentCostsOrDiscountsAndBenefits),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Current costs")), collapsible: true, sticky: true, open: openSections[Sections.CurrentCostsOrDiscountsAndBenefits], onToggle: this.updateOpenSections(Sections.CurrentCostsOrDiscountsAndBenefits), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: currentCosts },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.CurrentCostsOrDiscountsAndBenefits) }, "NEXT")))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.DataInternationalCallsAndRoaming}` },
                this.displayStar(Sections.DataInternationalCallsAndRoaming),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Data, international calls & roaming")), collapsible: true, sticky: true, open: openSections[Sections.DataInternationalCallsAndRoaming], onToggle: this.updateOpenSections(Sections.DataInternationalCallsAndRoaming), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: dataIDDRoaming },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.DataInternationalCallsAndRoaming) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.NetworkProviderOptions}` },
                this.displayStar(Sections.NetworkProviderOptions),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Network provider options")), collapsible: true, sticky: true, open: openSections[Sections.NetworkProviderOptions], onToggle: this.updateOpenSections(Sections.NetworkProviderOptions), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: networkProvider },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.NetworkProviderOptions) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.HandsetsAndDevicesOptions}` },
                this.displayStar(Sections.HandsetsAndDevicesOptions),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Handsets & devices options")), collapsible: true, sticky: true, open: openSections[Sections.HandsetsAndDevicesOptions], onToggle: this.updateOpenSections(Sections.HandsetsAndDevicesOptions), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: handsetsDevices },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.HandsetsAndDevicesOptions) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.MDM}` },
                this.displayStar(Sections.MDM),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Mobile device management")), collapsible: true, sticky: true, open: openSections[Sections.MDM], onToggle: this.updateOpenSections(Sections.MDM), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: mdm },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.MDM) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.AdditionalInfo}` },
                this.displayStar(Sections.AdditionalInfo),
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Additional information")), collapsible: true, sticky: true, open: openSections[Sections.AdditionalInfo], onToggle: this.updateOpenSections(Sections.AdditionalInfo), className: styles.card },
                    React.createElement(Form, { data: data, errors: this.props.errors, inputChange: this.inputChange, buttons: false, labelLeft: true, input: additionalInfo },
                        React.createElement("div", { className: buttons.buttonGroupRight },
                            React.createElement(Button, { cta: true, arrow: true, click: this.nextSection(Sections.AdditionalInfo, false) }, "NEXT"))))),
            React.createElement("div", { className: styles.section, id: `section-${Sections.SubmitTender}` },
                React.createElement(Card, { title: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Overall, null)),
                        React.createElement("div", { className: styles.title }, "Submit Tender")), collapsible: true, sticky: true, open: openSections[Sections.SubmitTender], onToggle: this.updateOpenSections(Sections.SubmitTender), className: styles.card },
                    React.createElement("div", { className: styles.saveButton },
                        React.createElement("div", { className: styles.saveCollection },
                            this.displayPublishButton(),
                            React.createElement(PredictMascotLeftIcon, { className: styles.mascotIcon })))))));
    }
}
