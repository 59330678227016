// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainTitle--OfNCa{color:var(--alt-highlighted-text-color);font-size:1.2rem}.tenderWrapper--UhicR{display:flex;justify-content:center}.tenderWrapper--UhicR .addTender--e0NFr{display:flex;width:25rem;height:16rem;margin:2vw;background-color:#fff;border-radius:.5em;box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)}.tenderWrapper--UhicR .addTender--e0NFr .content--xaLq2{width:100%}.tenderWrapper--UhicR .addTender--e0NFr .content--xaLq2 .createText--jmE86{display:flex;align-items:center;justify-content:center;margin-top:4rem;color:var(--text-color);font-size:1.6rem}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Broker/Pages/Dashboard/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,uCAAuC,CAAC,gBAAgB,CAAC,sBAAe,YAAY,CAAC,sBAAsB,CAAC,wCAA0B,YAAY,CAAC,WAAW,CAAC,YAAY,CAAC,UAAU,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,kEAAkE,CAAC,wDAAmC,UAAU,CAAC,2EAA+C,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,eAAe,CAAC,uBAAuB,CAAC,gBAAgB","sourcesContent":[".mainTitle{color:var(--alt-highlighted-text-color);font-size:1.2rem}.tenderWrapper{display:flex;justify-content:center}.tenderWrapper .addTender{display:flex;width:25rem;height:16rem;margin:2vw;background-color:#fff;border-radius:.5em;box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)}.tenderWrapper .addTender .content{width:100%}.tenderWrapper .addTender .content .createText{display:flex;align-items:center;justify-content:center;margin-top:4rem;color:var(--text-color);font-size:1.6rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": "mainTitle--OfNCa",
	"tenderWrapper": "tenderWrapper--UhicR",
	"addTender": "addTender--e0NFr",
	"content": "content--xaLq2",
	"createText": "createText--jmE86"
};
export default ___CSS_LOADER_EXPORT___;
