import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import companyMigrate from "@Shared/Entities/AbstractEntities/Company/migrate";
import { PartnerType } from "@Shared/Entities/Partner/enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version3 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 3) {
        return current;
    }
    return { type: PartnerType.Broker, ...current, version: 3 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const companyMigrated = companyMigrate(original);
    const converted = [version1, version3].reduce((converted, f) => f(converted), original);
    let { version, subDomain = "", apiKey = "", type, showFullBreakdown = false, hideWinnersFee = false, margin, allowedUsage = false, } = converted;
    subDomain = subDomain.toLowerCase();
    return {
        ...baseMigrated,
        ...companyMigrated,
        version,
        subDomain,
        apiKey,
        type,
        showFullBreakdown,
        hideWinnersFee,
        margin,
        allowedUsage,
    };
};
