import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put, restore } from "@Api/Customers/users";
export const DELETE_CUSTOMER_USER = "deleteCustomerUser";
export const deleteUser = (dispatch, user) => del(user)
    .then(({ id }) => {
    dispatch({
        type: DELETE_CUSTOMER_USER,
        data: id,
    });
})
    .then(notify200(dispatch, API_TYPE.DELETE, "Customer User(s) deleted", "The customer user(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_CUSTOMER_USER = "updateCustomerUser";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_CUSTOMER_USER,
        data,
    });
};
export const createUser = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Customer User Created", "The customer user was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateUser = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Customer User Updated", "The customer user was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchUser = (dispatch, customerId, userId) => find(customerId, userId).then(add(dispatch));
export const fetchUsers = (dispatch, customerId, inclDel) => get(add(dispatch), customerId, inclDel);
export const restoreUser = (dispatch, user) => restore(user).then(add(dispatch));
