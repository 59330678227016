import { collectionV2 } from "@Api/helpers";
import Device from "@Shared/Entities/Hardware/Device";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (deviceId = null, inclDel = false) => {
    const url = `/devices/${deviceId}`;
    return api
        .get(url, { params: { inclDel } })
        .then((result) => Factory.Device(result.data.item));
};
export const get = (callback, size = 1000) => collectionV2((data) => callback(data.map((d) => Factory.Device(d))), `/devices?size=${size}`);
export const post = (device) => {
    return api.post(`/devices`, device.data).then((result) => {
        return Factory.Device(result.data.item);
    });
};
export const put = (device) => {
    return api.put(`/devices/${device.id}`, device.data).then((result) => {
        return Factory.Device(result.data.item);
    });
};
export const del = (device) => {
    return api.delete(`/devices/${device.id}`).then((result) => {
        return result.data.item;
    });
};
export const priceListPost = (devices) => {
    return api
        .post(`/devices/price-list`, devices.map(({ data }) => data))
        .then((result) => result.data.map(({ item }) => new Device(item)));
};
export const parsePriceListPost = (pricelist) => {
    return api
        .post("/devices/price-list/parse", { pricelist })
        .then((result) => {
        return result.data;
    });
};
export const discontinueDevicesPost = (month) => {
    return api
        .post("/devices/price-list/discontinue", { month })
        .then((result) => {
        return result.data;
    });
};
