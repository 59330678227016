import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (partnerId, userId) => {
    const url = `/partners/${partnerId}/users/${userId}`;
    return api.get(url).then((result) => Factory.User(result.data.item));
};
export const get = (callback, partnerId, inclDel) => {
    const url = `/partners/${partnerId}/users`;
    return collection((data) => callback(Factory.User(data)), url, { inclDel });
};
export const post = (user) => {
    return api
        .post(`/partners/${user.partnerId}/users`, user.data)
        .then((result) => {
        return Factory.User(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (user) => {
    return api
        .put(`/partners/${user.partnerId}/users/${user.id}`, user.data)
        .then((result) => {
        return Factory.User(result.data.item);
    });
};
export const del = (user) => {
    return api
        .delete(`/partners/${user.partnerId}/users/${user.id}`)
        .then((result) => {
        return result.data.item;
    });
};
