import "@Sass/app.scss";
import { boot } from "@Store/Actions/Auth";
import React from "react";
import { connect } from "react-redux";
import { ROOT as ADMIN_ROOT } from "@Shared/Paths/Admin";
import { ROOT as COMPARISON_ROOT } from "@Shared/Paths/Comparison";
import { ROOT as CUSTOMER_ROOT } from "@Shared/Paths/Customer";
import { ROOT as PARTNER_ROOT } from "@Shared/Paths/Partner";
import { ROOT as REGISTER_ROOT } from "@Shared/Paths/Register";
import { ROOT as SUPPLIER_ROOT } from "@Shared/Paths/Supplier";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Admin from "./Admin/Admin";
import Auth from "./Auth/Auth";
import Broker from "./Broker/Broker";
import Comparison from "./Comparison/Comparison";
import Customer from "./Customer/Customer";
import Register from "./Register/Register";
import Supplier from "./Supplier/Supplier";
class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            booting: true,
        };
    }
    componentDidMount() {
        this.props.boot().finally(() => {
            this.setState({ booting: false });
        });
    }
    render() {
        if (this.state.booting) {
            return React.createElement(React.Fragment, null);
        }
        const unsecuredRoutes = [
            React.createElement(Route, { path: REGISTER_ROOT },
                React.createElement(Register, null)),
            React.createElement(Route, { path: COMPARISON_ROOT },
                React.createElement(Comparison, null)),
            React.createElement(Route, { path: "*" },
                React.createElement(Auth, null)),
        ];
        if (!this.props.token ||
            (this.props.user.mfaEnabled &&
                !this.props.mfa &&
                !this.props.user.assumed)) {
            return (React.createElement(Router, null,
                React.createElement(Switch, null, ...unsecuredRoutes)));
        }
        return (React.createElement(Router, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: ADMIN_ROOT },
                    React.createElement(Admin, null)),
                React.createElement(Route, { path: PARTNER_ROOT },
                    React.createElement(Broker, null)),
                React.createElement(Route, { path: CUSTOMER_ROOT },
                    React.createElement(Customer, null)),
                React.createElement(Route, { path: SUPPLIER_ROOT },
                    React.createElement(Supplier, null)),
                ...unsecuredRoutes)));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        boot: () => boot(dispatch),
    };
};
const mapStateToProps = ({ auth: { token, user, mfa } }) => ({
    mfa,
    token,
    user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Pages);
