import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import { ExistingDevices, LikeHardwareFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import constraints from "./constraints";
import migrate from "./migrate";
export default class TenderDetails extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { ATC, ATCUnits, changeNetwork, charity, charitySocialEnterprise, complete, costSpread, currentNetwork, currentSpend, dataAcquisition, dataLines, dataLinesUsed, dataUsed, dep, estimatedDataLines, estimatedLines, euCostCountries, existingDevices, extraDetails, fiveG, handsets, hardwareType, highCost, highCostCountries, iddCost, iddCostCountries, increaseUsage, leasingType, length, likeHardwareFunded, lines, linesBeginning, linesEnd, linesWithinSixMonths, linesWithinThreeMonths, locked, manualCurrentCost, MDM, MDMEnd, MDMFee, MDMInLine, MDMMSInTune, microsoft365, multipleNetwork, newEndDate, numberExistingDevices, onlineTeams, OSSpread, otherDataIncrease, overallData, ownMDM, pickedHandsets, postcodes, priceRange, roamingCost, roamingCostCountries, sameTime, simOnlyFunded, techFund, vastMajorityLinesRenewal, VATRegistered, voiceLines, voiceLinesUsed, voiceOnlyLines, zoom, } = this;
        const { version } = this._data;
        return {
            version,
            ATC,
            ATCUnits,
            changeNetwork,
            charity,
            charitySocialEnterprise,
            complete,
            costSpread,
            currentNetwork,
            currentSpend,
            dataAcquisition,
            dataLines,
            dataLinesUsed,
            dataUsed,
            dep,
            estimatedDataLines,
            estimatedLines,
            euCostCountries,
            existingDevices,
            extraDetails,
            fiveG,
            handsets,
            hardwareType,
            highCost,
            highCostCountries,
            iddCost,
            iddCostCountries,
            increaseUsage,
            leasingType,
            length,
            likeHardwareFunded,
            lines,
            linesBeginning,
            linesEnd,
            linesWithinSixMonths,
            linesWithinThreeMonths,
            locked,
            manualCurrentCost,
            MDM,
            MDMEnd,
            MDMFee,
            MDMInLine,
            MDMMSInTune,
            microsoft365,
            multipleNetwork,
            newEndDate,
            numberExistingDevices,
            onlineTeams,
            OSSpread,
            otherDataIncrease,
            overallData,
            ownMDM,
            pickedHandsets,
            postcodes,
            priceRange,
            roamingCost,
            roamingCostCountries,
            sameTime,
            simOnlyFunded,
            techFund,
            vastMajorityLinesRenewal,
            VATRegistered,
            voiceLines,
            voiceLinesUsed,
            voiceOnlyLines,
            zoom,
        };
    }
    get handsets() {
        return this._data.handsets;
    }
    get voiceLines() {
        return this._data.voiceLines;
    }
    get dataLines() {
        return this._data.dataLines;
    }
    get length() {
        return this._data.length;
    }
    get vastMajorityLinesRenewal() {
        return this._data.vastMajorityLinesRenewal;
    }
    get linesBeginning() {
        return this._data.linesBeginning;
    }
    get leasingType() {
        if (this.likeHardwareFunded !== LikeHardwareFunded.Leasing) {
            return undefined;
        }
        return this._data.leasingType;
    }
    get overallData() {
        return this._data.overallData;
    }
    get sameTime() {
        return this._data.sameTime;
    }
    get estimatedLines() {
        return this._data.estimatedLines;
    }
    get estimatedDataLines() {
        return this._data.estimatedDataLines;
    }
    get linesEnd() {
        return this._data.linesEnd;
    }
    get currentSpend() {
        return this._data.currentSpend || 0;
    }
    get ATC() {
        return this._data.ATC;
    }
    get ATCUnits() {
        return this._data.ATCUnits;
    }
    get increaseUsage() {
        return this._data.increaseUsage;
    }
    get iddCost() {
        return this._data.iddCost;
    }
    get roamingCost() {
        return this._data.roamingCost;
    }
    get highCost() {
        return this._data.highCost;
    }
    get MDM() {
        return this._data.MDM;
    }
    get ownMDM() {
        return this._data.ownMDM;
    }
    get MDMInLine() {
        return this._data.MDMInLine;
    }
    get costSpread() {
        return this._data.costSpread;
    }
    get OSSpread() {
        return this._data.OSSpread;
    }
    get existingDevices() {
        return this._data.existingDevices;
    }
    get numberExistingDevices() {
        return this._data.numberExistingDevices;
    }
    get changeNetwork() {
        return this._data.changeNetwork;
    }
    get charity() {
        return this._data.charity;
    }
    get VATRegistered() {
        const VATRegistered = this._data.VATRegistered;
        if (VATRegistered === undefined) {
            return true;
        }
        return VATRegistered;
    }
    get charitySocialEnterprise() {
        return this._data.charitySocialEnterprise;
    }
    get extraDetails() {
        return this._data.extraDetails;
    }
    get likeHardwareFunded() {
        if (this.handsets !== true) {
            return undefined;
        }
        return this._data.likeHardwareFunded;
    }
    get newEndDate() {
        return this._data.newEndDate;
    }
    get pickedHandsets() {
        if (this.handsets !== true) {
            return undefined;
        }
        return this._data.pickedHandsets;
    }
    get postcodes() {
        return this._data.postcodes;
    }
    get locked() {
        if (this.existingDevices === undefined ||
            this.existingDevices === ExistingDevices.None) {
            return undefined;
        }
        return this._data.locked;
    }
    get highCostCountries() {
        return this._data.highCostCountries;
    }
    get simOnlyFunded() {
        if (this.handsets === false ||
            this.likeHardwareFunded === LikeHardwareFunded.Leasing) {
            return this._data.simOnlyFunded;
        }
        return undefined;
    }
    get(k) {
        return this._data[k];
    }
    get techFund() {
        return this._data.techFund;
    }
    get complete() {
        if (this._data.voiceLines !== undefined &&
            this._data.length !== undefined &&
            this._data.overallData !== undefined &&
            this._data.existingDevices !== undefined &&
            this._data.handsets !== undefined &&
            this._data.MDM !== undefined) {
            return true;
        }
        return false;
    }
    get voiceOnlyLines() {
        return this._data.voiceOnlyLines;
    }
    get zoom() {
        return this._data.zoom;
    }
    get onlineTeams() {
        return this._data.onlineTeams;
    }
    get microsoft365() {
        return this._data.microsoft365;
    }
    get dataAcquisition() {
        return this._data.dataAcquisition;
    }
    get otherDataIncrease() {
        return this._data.otherDataIncrease;
    }
    get iddCostCountries() {
        return this._data.iddCostCountries;
    }
    get roamingCostCountries() {
        return this._data.roamingCostCountries;
    }
    get euCostCountries() {
        return this._data.euCostCountries;
    }
    get dep() {
        if (this.handsets !== true) {
            return;
        }
        return this._data.dep;
    }
    get fiveG() {
        if (this.handsets !== true) {
            return;
        }
        return this._data.fiveG;
    }
    get lines() {
        return this._data.lines;
    }
    get linesWithinThreeMonths() {
        return this._data.linesWithinThreeMonths;
    }
    get linesWithinSixMonths() {
        return this._data.linesWithinSixMonths;
    }
    get voiceLinesUsed() {
        return this._data.voiceLinesUsed;
    }
    get dataLinesUsed() {
        return this._data.dataLinesUsed;
    }
    get dataUsed() {
        return this._data.dataUsed;
    }
    get currentNetwork() {
        return this._data.currentNetwork;
    }
    get multipleNetwork() {
        return this._data.multipleNetwork;
    }
    get priceRange() {
        return this._data.priceRange;
    }
    get hardwareType() {
        return this._data.hardwareType;
    }
    get MDMFee() {
        return this._data.MDMFee;
    }
    get MDMEnd() {
        return this._data.MDMEnd;
    }
    get MDMMSInTune() {
        return this._data.MDMMSInTune;
    }
    get manualCurrentCost() {
        return this._data.manualCurrentCost;
    }
    get totalLines() {
        const { voiceLines = 0, dataLines = 0, voiceOnlyLines = 0 } = this;
        return voiceLines + dataLines + voiceOnlyLines;
    }
}
