import React from "react";
import FilterMenuField from "./FilterMenuField";
import styles from "./index.module.scss";
export default class FilterMenu extends React.Component {
    updateFilterField(updates = [], field) {
        this.props.updateFilter(updates, field);
    }
    render() {
        const filters = this.props.availableFilters.map(({ field, header, options, selected, range, array, arraySelected, toggle, }) => {
            return (React.createElement(FilterMenuField, { key: field, field: field, header: header, options: options, toggle: toggle, selected: selected, arraySelected: arraySelected, range: range, array: array, updateSelected: this.updateFilterField.bind(this), updateArray: this.props.updateArray, updateRange: this.props.updateRange, rangeFilters: this.props.rangeFilters, large: this.props.large }));
        });
        return (React.createElement("div", { className: styles.filterMenu },
            React.createElement("div", { className: styles.filterTitle }, "Filters"),
            filters));
    }
}
