import { find, get, getForPartner, getForScoring, markFavouriteResponse, } from "@Api/Customers/responses";
import { ADD_RESPONSE } from "@Store/Actions/Suppliers/responses";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_RESPONSE,
        data,
    });
};
export const fetchResponsesForCustomer = (dispatch, customerId, tenderId, showAll) => get(add(dispatch), customerId, tenderId, showAll);
export const fetchResponsesForPartner = (dispatch, partnerId, tenderId, showAll) => getForPartner(add(dispatch), partnerId, tenderId, showAll);
export const fetchResponsesForScoring = (dispatch, customerId, tenderId) => getForScoring(add(dispatch), customerId, tenderId);
export const fetchResponseForComparison = (dispatch, customerId, tenderId, responseId) => find(customerId, tenderId, responseId).then(add(dispatch));
export const markFavourite = (dispatch, customerId, tenderId, responseId) => markFavouriteResponse(customerId, tenderId, responseId).then(add(dispatch));
