export const average = (array) => {
    if (array.length > 0) {
        return total(array) / array.length;
    }
    return 0;
};
export const weightedAverage = (array) => {
    if (array.length === 0) {
        return 0;
    }
    const { total, weight } = array.reduce(({ total, weight }, [n, w]) => {
        return { total: total + n * w, weight: weight + w };
    }, { total: 0, weight: 0 });
    return total / weight;
};
export const median = (array) => {
    if (array.length === 0) {
        return 0;
    }
    array.sort((a, b) => {
        return a - b;
    });
    const half = Math.floor(array.length / 2);
    if (array.length % 2)
        return array[half];
    return (array[half - 1] + array[half]) / 2;
};
export const total = (array) => array.reduce((total, number_) => {
    if (Number.isNaN(number_)) {
        return total;
    }
    return total + number_;
}, 0);
