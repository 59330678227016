import React from "react";
import Logo from "@Components/Icons/Logos/LogoText";
import styles from "./index.module.scss";
export default class LeftBar extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.header },
                React.createElement(Logo, { className: styles.logo, onLoad: this.props.onLoad }))));
    }
}
