import User from "@Shared/Entities/AbstractEntities/User";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import constraints from "./constraints";
import migrate from "./migrate";
export default class AdminUser extends User {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get type() {
        return UserType.Admin;
    }
    get baseUrl() {
        return `${super.baseUrl}/users/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        return { ...parent, version };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
}
