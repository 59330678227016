export default {
    username: {
        notEmpty: true,
        format: {
            pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        },
    },
    password: {
        notEmpty: true,
    },
};
