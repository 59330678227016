import { Status } from "@Shared/Entities/Customer/Usage/enums";
export default {
    customerId: {
        presence: true,
        type: "string",
    },
    supplierId: {
        type: "string",
    },
    endsAt: {
        type: "Dayjs",
    },
    // network: {
    //     type: "integer",
    //     enum: Network,
    // },
    // platform: {
    //     type: "integer",
    //     enum: Platform,
    // },
    username: {
        type: "string",
    },
    password: {
        type: "string",
    },
    encrypted: {
        type: "boolean",
    },
    mailingList: {
        type: "array",
    },
    status: {
        presence: true,
        enum: Status,
    },
    canCreateTenderOverride: {
        type: "boolean",
    },
};
