import { AnalysisMethod, ChargeMethod, Effected, FreeNextDay, Frequency, SmallTimeFrame, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const customerServicePresenceConstraints = {
    [Effected.AccountManaged]: {
        presence: true,
    },
    [Effected.AccountManagerVisit]: {
        conditionalPresenceMatchAll: [
            { field: Effected.AccountManaged, value: true },
        ],
    },
    [Effected.AccountManagerVisitFrequency]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AccountManaged,
                value: true,
            },
            {
                field: Effected.AccountManagerVisit,
                value: true,
            },
        ],
    },
    [Effected.ComplaintProcess]: {
        presence: true,
    },
    [Effected.TicketSystem]: {
        presence: true,
    },
    [Effected.VIPService]: {
        presence: true,
    },
    [Effected.VIPServiceResponseTime]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.VIPService,
                value: true,
            },
        ],
    },
    [Effected.ResponseTime]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.VIPService,
                value: false,
            },
        ],
    },
    [Effected.ResolutionTime]: {
        presence: true,
    },
    [Effected.FreeNextDay]: {
        presence: true,
    },
    [Effected.OngoingAnalysis]: {
        presence: true,
    },
    [Effected.AnalysisFrequency]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.OngoingAnalysis,
                value: true,
            },
        ],
    },
    [Effected.OngoingAnalysisMethod]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.OngoingAnalysis,
                value: true,
            },
        ],
    },
    [Effected.DisposeHandsets]: {
        presence: true,
    },
    [Effected.WEEECertificates]: {
        conditionalPresenceMatchAll: [
            { field: Effected.DisposeHandsets, value: true },
        ],
    },
    [Effected.FullRecycleValue]: {
        conditionalPresenceMatchAll: [
            { field: Effected.DisposeHandsets, value: true },
        ],
    },
    [Effected.CustomTerms]: {
        presence: true,
    },
    [Effected.PACCodeCharge]: {
        presence: true,
    },
    [Effected.ChargeMethod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PACCodeCharge, value: true },
        ],
    },
    [Effected.PACCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PACCodeCharge, value: true },
        ],
    },
    [Effected.TerminationPenalty]: {
        presence: true,
    },
    [Effected.PenaltyPerLine]: {
        conditionalPresenceMatchAll: [
            { field: Effected.TerminationPenalty, value: true },
        ],
    },
    [Effected.PaperStatements]: {
        presence: true,
    },
    [Effected.CostForStatements]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PaperStatements, value: true },
        ],
    },
    [Effected.NonDirectDebitCharges]: {
        presence: true,
    },
    [Effected.NonDirectDebitCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.NonDirectDebitCharges, value: true },
        ],
    },
    [Effected.ExtraChargesInTenders]: {
        presence: true,
    },
    [Effected.AdditionalChargeDetails]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ExtraChargesInTenders, value: true },
        ],
    },
    [Effected.TotalMonthlyCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ExtraChargesInTenders, value: true },
        ],
    },
};
export const customerServiceValidConstraints = {
    [Effected.AccountManaged]: {
        presence: true,
        type: "boolean",
    },
    [Effected.AccountManagerVisit]: {
        conditionalPresenceMatchAll: [
            { field: Effected.AccountManaged, value: true },
        ],
        type: "boolean",
    },
    [Effected.AccountManagerVisitFrequency]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AccountManaged,
                value: true,
            },
            {
                field: Effected.AccountManagerVisit,
                value: true,
            },
        ],
        enum: Frequency,
    },
    [Effected.ComplaintProcess]: {
        presence: true,
        type: "boolean",
    },
    [Effected.TicketSystem]: {
        presence: true,
        type: "boolean",
    },
    [Effected.VIPService]: {
        presence: true,
        type: "boolean",
    },
    [Effected.VIPServiceResponseTime]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.VIPService,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.ResponseTime]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.VIPService,
                value: false,
            },
        ],
        enum: SmallTimeFrame,
    },
    [Effected.ResolutionTime]: {
        presence: true,
        enum: SmallTimeFrame,
    },
    [Effected.FreeNextDay]: {
        presence: true,
        enum: FreeNextDay,
    },
    [Effected.OngoingAnalysis]: {
        presence: true,
        type: "boolean",
    },
    [Effected.AnalysisFrequency]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.OngoingAnalysis,
                value: true,
            },
        ],
        enum: Frequency,
    },
    [Effected.OngoingAnalysisMethod]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.OngoingAnalysis,
                value: true,
            },
        ],
        enum: AnalysisMethod,
    },
    [Effected.DisposeHandsets]: {
        presence: true,
        type: "boolean",
    },
    [Effected.WEEECertificates]: {
        conditionalPresenceMatchAll: [
            { field: Effected.DisposeHandsets, value: true },
        ],
        type: "boolean",
    },
    [Effected.FullRecycleValue]: {
        conditionalPresenceMatchAll: [
            { field: Effected.DisposeHandsets, value: true },
        ],
        type: "boolean",
    },
    [Effected.CustomTerms]: {
        presence: true,
        type: "boolean",
    },
    [Effected.PACCodeCharge]: {
        presence: true,
        type: "boolean",
    },
    [Effected.ChargeMethod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PACCodeCharge, value: true },
        ],
        enum: ChargeMethod,
    },
    [Effected.PACCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PACCodeCharge, value: true },
        ],
        type: "number",
    },
    [Effected.TerminationPenalty]: {
        presence: true,
        type: "boolean",
    },
    [Effected.PenaltyPerLine]: {
        conditionalPresenceMatchAll: [
            { field: Effected.TerminationPenalty, value: true },
        ],
        type: "number",
    },
    [Effected.PaperStatements]: {
        presence: true,
        type: "boolean",
    },
    [Effected.CostForStatements]: {
        conditionalPresenceMatchAll: [
            { field: Effected.PaperStatements, value: true },
        ],
        type: "number",
    },
    [Effected.NonDirectDebitCharges]: {
        presence: true,
        type: "boolean",
    },
    [Effected.NonDirectDebitCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.NonDirectDebitCharges, value: true },
        ],
        type: "number",
    },
    [Effected.ExtraChargesInTenders]: {
        presence: true,
        type: "boolean",
    },
    [Effected.AdditionalChargeDetails]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ExtraChargesInTenders, value: true },
        ],
        type: "string",
    },
    [Effected.TotalMonthlyCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ExtraChargesInTenders, value: true },
        ],
        type: "number",
    },
};
