export const ROOT = "/admin";
export const USERS = ROOT + "/users";
export const USER = USERS + "/:userId";
export const EDIT_USER = USER + "/edit";
export const CREATE_USER = USERS + "/create";
export const CUSTOMERS = ROOT + "/customers";
export const CREATE_CUSTOMER = CUSTOMERS + "/create";
export const CUSTOMER = CUSTOMERS + "/:customerId";
export const CUSTOMER_TENDERS = CUSTOMER + "/tender";
export const CREATE_CUSTOMER_TENDER = CUSTOMER_TENDERS + "/create";
export const CUSTOMER_TENDER = CUSTOMER_TENDERS + "/:tenderId";
export const EDIT_CUSTOMER_TENDER = CUSTOMER_TENDER + "/edit";
export const CUSTOMER_TENDER_RESPONSES = CUSTOMER_TENDER + "/responses";
export const CUSTOMER_TENDER_COMPARISON = CUSTOMER_TENDER_RESPONSES + "/comparison";
export const CUSTOMERS_USERS = CUSTOMER + "/users";
export const CREATE_CUSTOMER_USER = CUSTOMERS_USERS + "/create";
export const CUSTOMER_USER = CUSTOMERS_USERS + "/:userId";
export const EDIT_CUSTOMER_USER = CUSTOMER_USER + "/edit";
export const CUSTOMERS_USAGES = CUSTOMER + "/usage";
export const CREATE_CUSTOMER_USAGE = CUSTOMERS_USAGES + "/create";
export const CUSTOMER_USAGE = CUSTOMERS_USAGES + "/:usageId";
export const EDIT_CUSTOMER_USAGE = CUSTOMER_USAGE + "/edit";
export const SUPPLIERS = ROOT + "/suppliers";
export const CREATE_SUPPLIER = SUPPLIERS + "/create";
export const SUPPLIER = SUPPLIERS + "/:supplierId";
export const SUPPLIER_RESPONSES = SUPPLIER + "/responses";
export const SUPPLIER_RESPONSE = SUPPLIER_RESPONSES + "/:responseId";
export const EDIT_SUPPLIER_RESPONSE = SUPPLIER_RESPONSE + "/edit";
export const SAVE_SUPPLIER_RESPONSE = SUPPLIER_RESPONSE + "/save";
export const SUPPLIER_USERS = SUPPLIER + "/users";
export const CREATE_SUPPLIER_USER = SUPPLIER_USERS + "/create";
export const SUPPLIER_USER = SUPPLIER_USERS + "/:userId";
export const EDIT_SUPPLIER_USER = SUPPLIER_USER + "/edit";
export const PARTNERS = ROOT + "/partners";
export const CREATE_PARTNER = PARTNERS + "/create";
export const PARTNER = PARTNERS + "/:partnerId";
export const PARTNERS_USERS = PARTNER + "/users";
export const CREATE_PARTNER_USER = PARTNERS_USERS + "/create";
export const PARTNER_USER = PARTNERS_USERS + "/:userId";
export const EDIT_PARTNER_USER = PARTNER_USER + "/edit";
export const PARTNER_CUSTOMERS = PARTNER + "/customers";
export const CREATE_PARTNER_CUSTOMER = PARTNER_CUSTOMERS + "/create";
export const PARTNER_CUSTOMER = PARTNER_CUSTOMERS + "/:customerId";
export const PARTNER_CUSTOMER_TENDERS = PARTNER_CUSTOMER + "/tender";
export const PARTNER_CUSTOMERS_USAGES = PARTNER_CUSTOMER + "/usage";
export const AWARDED_CONTRACTS = ROOT + "/awarded-contracts";
export const TENDERS = ROOT + "/tenders";
export const TENDER = TENDERS + "/:tenderId";
export const EDIT_TENDER = TENDER + "/edit";
export const DEVICES = ROOT + "/devices";
export const DEVICE = DEVICES + "/:deviceId";
export const EDIT_DEVICE = DEVICE + "/edit";
export const CREATE_DEVICE = DEVICES + "/create";
export const UPLOAD_DEVICE_LIST = DEVICES + "/upload";
export const MY_ACCOUNT = ROOT + "/my-account";
export const EDIT_MY_ACCOUNT = MY_ACCOUNT + "/edit";
export const LOGOUT = ROOT + "/logout";
export const NOT_FOUND = ROOT + "/*";
