import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put } from "@Api/Partners/customers";
export const DELETE_PARTNER_CUSTOMER = "deletePartnerCustomer";
export const deleteCustomer = (dispatch, data) => del(data)
    .then(({ id }) => dispatch({
    type: DELETE_PARTNER_CUSTOMER,
    data: id,
}))
    .then(notify200(dispatch, API_TYPE.DELETE, "Partner Customer Deleted", "The customer(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_PARTNER_CUSTOMER = "updatePartnerCustomer";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_PARTNER_CUSTOMER,
        data,
    });
};
export const createCustomer = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Partner Customer Created", "The customer was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateCustomer = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Partner Customer Updated", "The customer was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchCustomer = (dispatch, partnerId, customerId) => find(partnerId, customerId).then(add(dispatch));
export const fetchCustomers = (dispatch, partnerId) => get(add(dispatch), partnerId);
