import { PartnerType } from "@Shared/Entities/Partner/enums";
export default {
    type: {
        presence: true,
        enum: PartnerType,
    },
    subDomain: {
        presence: true,
        length: {
            minimum: 1,
        },
        type: "string",
        format: {
            pattern: "[a-z0-9-_]+",
            message: "can only contain a-z, 0-9, dash, and underscore",
        },
    },
};
