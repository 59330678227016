import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import Logout from "@Components/Pages/Logout";
import { AWARDED_CONTRACTS, CUSTOMERS, DEVICES, LOGOUT, MY_ACCOUNT, NOT_FOUND, PARTNERS, ROOT, SUPPLIERS, USERS, } from "@Shared/Paths/Admin";
import MyAccount from "@SmartComponents/User/MyAccount";
import AdminUsers from "./AdminUsers";
import AwardedContracts from "./AwardedContracts";
import Customers from "./Customers";
import Dashboard from "./Dashboard";
import Devices from "./Devices";
import Partners from "./Partners";
import Suppliers from "./Suppliers";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ROOT, exact: true },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: USERS },
            React.createElement(AdminUsers, null)),
        React.createElement(Route, { path: CUSTOMERS },
            React.createElement(Customers, null)),
        React.createElement(Route, { path: PARTNERS },
            React.createElement(Partners, null)),
        React.createElement(Route, { path: SUPPLIERS },
            React.createElement(Suppliers, null)),
        React.createElement(Route, { path: DEVICES },
            React.createElement(Devices, null)),
        React.createElement(Route, { path: AWARDED_CONTRACTS },
            React.createElement(AwardedContracts, null)),
        React.createElement(Route, { path: MY_ACCOUNT },
            React.createElement(MyAccount, null)),
        React.createElement(Route, { path: LOGOUT },
            React.createElement(Logout, null)),
        React.createElement(Route, { path: NOT_FOUND },
            React.createElement(NotFoundFallback, null))));
};
