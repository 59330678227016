import * as React from "react";
import styles from "./index.module.scss";
export const SliderRail = ({ getRailProps }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.railOuter, ...getRailProps() }),
        React.createElement("div", { className: styles.railInner })));
};
export const Handle = ({ handle: { id, percent }, getHandleProps, resultsPage = false, }) => {
    const left = resultsPage ? (percent / 100) * 90 + 5 : percent;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                left: `${left}%`,
            }, className: styles.handle, ...getHandleProps(id) })));
};
export const Track = ({ source, target, getTrackProps, }) => {
    return (React.createElement("div", { style: {
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`,
        }, className: styles.track, ...getTrackProps() }));
};
export const Tick = ({ tick, count, format = (d) => d, }) => {
    return (React.createElement("div", null,
        React.createElement("div", { style: {
                left: `${tick.percent}%`,
            }, className: styles.tick }),
        React.createElement("div", { style: {
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`,
            }, className: styles.tickValue }, format(tick.value))));
};
