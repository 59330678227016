import React from "react";
import Dropdown from "@Components/Form/Dropdown";
import FormInput from "@Components/Form/Input";
import AndroidIcon from "@Components/Icons/Android";
import AppleIcon from "@Components/Icons/Apple";
import Slider from "@Components/SingleSlider";
import { DetailsFields, DeviceRange, } from "@Shared/Entities/Customer/Tender/Details/enums";
import styles from "./index.module.scss";
export default (devicesOptions) => (update, data = [], errors = [], index) => {
    const error = errors[index] || {};
    const values = data[index] || {};
    const dropdownChange = ({ option: { value }, field, }) => {
        update({ value, name: field });
    };
    const inputChange = (name, value) => {
        update({ name, value });
    };
    if (values[DetailsFields.OSSpreadHandsets] === undefined) {
        values[DetailsFields.OSSpreadHandsets] = 50;
    }
    const components = [
        React.createElement("div", { className: styles.subRow },
            React.createElement("div", { className: styles.subColumn },
                React.createElement("div", { className: styles.upperInputs },
                    React.createElement("div", { className: styles.item },
                        React.createElement(Dropdown, { title: "Model", options: devicesOptions, onChange: dropdownChange, value: values[DetailsFields.PickedHandsets], name: DetailsFields.PickedHandsets, error: error[DetailsFields.PickedHandsets] })),
                    React.createElement("div", { className: styles.item },
                        React.createElement(FormInput, { title: "QTY", type: "number", blue: true, value: values[DetailsFields.NumberHandsets], error: error[DetailsFields.NumberHandsets], name: DetailsFields.NumberHandsets, valueChanged: inputChange, small: true, noDecimal: true }))))),
        React.createElement("div", { className: styles.slider },
            React.createElement("span", { className: styles.title }, "Operating system preference?"),
            React.createElement(Slider, { className: styles.noSelect, update: inputChange, headers: (value) => [
                    React.createElement(React.Fragment, null,
                        React.createElement(AndroidIcon, null),
                        value !== undefined && `${value}%`),
                    React.createElement(React.Fragment, null,
                        React.createElement(AppleIcon, { className: styles.apple }),
                        React.createElement("div", { className: styles.value }, value !== undefined && `${100 - value}%`)),
                ], value: values[DetailsFields.OSSpreadHandsets], error: error[DetailsFields.OSSpreadHandsets], name: DetailsFields.OSSpreadHandsets })),
    ];
    if (!(values[DetailsFields.PickedHandsets] === DeviceRange.Mid ||
        values[DetailsFields.PickedHandsets] === DeviceRange.High)) {
        components.pop();
    }
    return components;
};
