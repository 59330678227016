import React from "react";
import { Route, Switch } from "react-router-dom";
import HeaderComp from "@Components/Header";
import { BILLS, CREATE_USER, CUSTOMER, CUSTOMERS, DOCUMENTS, EDIT_USER, FEEDBACK, LOGOUT, MY_ACCOUNT, MY_CONTRACT, MY_ORGANISATION, MY_TENDER, NOT_FOUND, PROPOSAL, PROPOSALS, RECOMMENDATIONS, ROOT, TENDER, TENDERS, TENDER_RESPONSE, TENDER_RESPONSES, USER, USERS, VIEW_SCORES, } from "@Shared/Paths/Supplier";
export default class Header extends React.Component {
    render() {
        return (React.createElement(HeaderComp, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: ROOT, exact: true }, "Dashboard"),
                React.createElement(Route, { path: MY_ORGANISATION },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: MY_ORGANISATION, exact: true }, "My Organisation Details"),
                        React.createElement(Route, { path: USERS },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: USERS, exact: true }, "Users"),
                                React.createElement(Route, { path: CREATE_USER }, "Create User"),
                                React.createElement(Route, { path: USER },
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { path: USER, exact: true }, "User"),
                                        React.createElement(Route, { path: EDIT_USER }, "Edit User"))))))),
                React.createElement(Route, { path: CUSTOMERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: CUSTOMERS, exact: true }, "Customer Records"),
                        React.createElement(Route, { path: CUSTOMER },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: CUSTOMER, exact: true }, "Customer Details"),
                                React.createElement(Route, { path: DOCUMENTS }, "Documents"),
                                React.createElement(Route, { path: MY_TENDER }, "My Tender"),
                                React.createElement(Route, { path: RECOMMENDATIONS }, "Recommendations"),
                                React.createElement(Route, { path: MY_CONTRACT }, "My Contract"),
                                React.createElement(Route, { path: BILLS }, "Bills"))))),
                React.createElement(Route, { path: TENDERS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: TENDERS, exact: true }, "Search Tenders"),
                        React.createElement(Route, { path: TENDER }, "Tender"))),
                React.createElement(Route, { path: TENDER_RESPONSES },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: TENDER_RESPONSES, exact: true }, "Responses"),
                        React.createElement(Route, { path: TENDER_RESPONSE },
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: TENDER_RESPONSE, exact: true }, "Response"),
                                React.createElement(Route, { path: VIEW_SCORES }, "View Scores"))))),
                React.createElement(Route, { path: PROPOSALS },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: PROPOSALS, exact: true }, "Tender Responses"),
                        React.createElement(Route, { path: PROPOSAL }, "Tender Response"))),
                React.createElement(Route, { path: FEEDBACK }, "Feedback"),
                React.createElement(Route, { path: MY_ACCOUNT }, "My Personal Account Profile"),
                React.createElement(Route, { path: LOGOUT }),
                React.createElement(Route, { path: NOT_FOUND }, "404"))));
    }
}
