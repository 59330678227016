import dayjs from "dayjs";
import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import { Sections } from "@Shared/Entities/Customer/Tender/enums";
import Factory from "@Shared/Factory";
import { getCustomerDeadline, getSupplierDeadline } from "@Shared/Helpers/Date";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    let { priceCheckDevices } = current;
    if (priceCheckDevices) {
        priceCheckDevices = priceCheckDevices.map((datum) => {
            if (typeof datum === "string") {
                return datum;
            }
            const d = Factory.Device(datum);
            return d.id;
        });
    }
    return { ...current, priceCheckDevices, version: 2 };
};
const version3 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 3) {
        return current;
    }
    let { supplierInvited } = current;
    if (typeof supplierInvited === "string") {
        supplierInvited = [supplierInvited];
    }
    return { ...current, supplierInvited, version: 3 };
};
const version4 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 4) {
        return current;
    }
    let { deadline, supplierDeadline } = current;
    if (supplierDeadline === undefined) {
        supplierDeadline = deadline;
    }
    return { ...current, supplierDeadline, version: 4 };
};
const version5 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 5) {
        return current;
    }
    let { section } = current;
    if (section === undefined) {
        section = Sections.LineRequirements;
    }
    return { ...current, section, version: 5 };
};
const version6 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 6) {
        return current;
    }
    let { publishedAt, customerDeadline, supplierDeadline } = current;
    if (publishedAt !== undefined) {
        if (supplierDeadline === undefined) {
            supplierDeadline = getSupplierDeadline(dayjs(publishedAt));
        }
        if (customerDeadline === undefined) {
            customerDeadline = getCustomerDeadline(dayjs(supplierDeadline));
        }
    }
    return {
        ...current,
        publishedAt,
        supplierDeadline,
        customerDeadline,
        version: 6,
    };
};
const version7 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 7) {
        return current;
    }
    const { supplierDeadline, publishedAt } = current;
    let resultsViewable;
    if (publishedAt) {
        resultsViewable = dayjs(supplierDeadline).addBusinessDays(2);
    }
    return {
        ...current,
        resultsViewable,
        version: 7,
    };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [
        version1,
        version2,
        version3,
        version4,
        version5,
        version6,
        version7,
    ].reduce((converted, f) => f(converted), original);
    let { version, id, customerId, usageId, publishedAt, supplierDeadline, customerDeadline, resultsAvailable, details = {}, stats = {}, priceCheckDevices, breakdown = {}, categories, awarded, postcode, supplierInvited, userId, partnerId, withholdResults = false, customerType = 0, usageAddedLater, convenienceScoreVersion, customerServiceScoreVersion, valueScoreVersion, signalScoreVersion, tariffScoreVersion, pointsScoreVersion, margin, showSupplierNames = false, whitelist, changedDefaultPublishDate = false, section, resultsViewable, ofcom, } = converted;
    if (publishedAt) {
        publishedAt = dayjs.utc(publishedAt);
    }
    if (supplierDeadline) {
        supplierDeadline = dayjs.utc(supplierDeadline);
    }
    if (customerDeadline) {
        customerDeadline = dayjs.utc(customerDeadline);
    }
    if (resultsViewable) {
        resultsViewable = dayjs.utc(resultsViewable);
    }
    details = Factory.TenderDetails(details);
    stats = Factory.UsageStats(stats);
    breakdown = Factory.UsageBreakdown(breakdown);
    return {
        ...baseMigrated,
        version,
        id,
        customerId,
        usageId,
        publishedAt,
        supplierDeadline,
        customerDeadline,
        resultsAvailable,
        details,
        stats,
        priceCheckDevices,
        breakdown,
        categories,
        awarded,
        postcode,
        supplierInvited,
        userId,
        partnerId,
        withholdResults,
        customerType,
        usageAddedLater,
        convenienceScoreVersion,
        customerServiceScoreVersion,
        valueScoreVersion,
        signalScoreVersion,
        tariffScoreVersion,
        pointsScoreVersion,
        margin,
        showSupplierNames,
        whitelist,
        changedDefaultPublishDate,
        section,
        resultsViewable,
        ofcom,
    };
};
