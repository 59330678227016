// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popupCard--CwFNz{width:80%;min-width:15rem;max-width:70rem;margin:auto}.popupCard--CwFNz .close--kqAJ1{cursor:pointer}.popupCard--CwFNz .items--VZbBC{max-height:10rem;overflow-y:auto}.popupCard--CwFNz .items--VZbBC .item--gpHXf{margin-bottom:.5rem}.popupCard--CwFNz .items--VZbBC .item--gpHXf .title--QaOY1{font-weight:bold}@media(min-height: 300px){.items--VZbBC{max-height:18rem !important}}@media(min-height: 550px){.items--VZbBC{max-height:30rem !important}}@media(min-height: 700px){.items--VZbBC{max-height:35rem !important}}@media(min-width: 960px){.popupCard--CwFNz{min-width:40rem !important}}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderResponses/PagePopup/Summary/AdditionalInfo/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,SAAS,CAAC,eAAe,CAAC,eAAe,CAAC,WAAW,CAAC,gCAAkB,cAAc,CAAC,gCAAkB,gBAAgB,CAAC,eAAe,CAAC,6CAAwB,mBAAmB,CAAC,2DAA+B,gBAAgB,CAAC,0BAA0B,cAAO,2BAA2B,CAAC,CAAC,0BAA0B,cAAO,2BAA2B,CAAC,CAAC,0BAA0B,cAAO,2BAA2B,CAAC,CAAC,yBAAyB,kBAAW,0BAA0B,CAAC","sourcesContent":[".popupCard{width:80%;min-width:15rem;max-width:70rem;margin:auto}.popupCard .close{cursor:pointer}.popupCard .items{max-height:10rem;overflow-y:auto}.popupCard .items .item{margin-bottom:.5rem}.popupCard .items .item .title{font-weight:bold}@media(min-height: 300px){.items{max-height:18rem !important}}@media(min-height: 550px){.items{max-height:30rem !important}}@media(min-height: 700px){.items{max-height:35rem !important}}@media(min-width: 960px){.popupCard{min-width:40rem !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupCard": "popupCard--CwFNz",
	"close": "close--kqAJ1",
	"items": "items--VZbBC",
	"item": "item--gpHXf",
	"title": "title--QaOY1"
};
export default ___CSS_LOADER_EXPORT___;
