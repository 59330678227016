import dayjs from "dayjs";
import React from "react";
import PredictMascotIcon from "@Components/Icons/PredictMascot";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import getSubdomain from "@Helpers/GetSubdomain";
import { DeviceRange } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Sections } from "@Shared/Entities/Customer/Tender/enums";
import { validation } from "@Shared/Validate";
import { getData } from "@SmartComponents/TenderEdit";
import TenderChat from "@SmartComponents/TenderEdit/Display/Chat";
import { additionalInfoConstraints, contractDatesConstraints, currentCostsConstraints, dataIDDRoamingConstraints, discountsConstraints, handsetDevicesConstraints, lineRequirementConstraints, mdmConstraints, networkProviderConstraints, } from "@SmartComponents/TenderEdit/validation";
import TenderForm from "./Form";
import styles from "./index.module.scss";
import LeftGutter from "./LeftGutter";
import RightGutter from "./RightGutter";
import SubHeader from "./SubHeader";
export default class TenderEditDisplay extends React.Component {
    container;
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            pageWidth: 0,
            chat: false,
            completedSections: {},
            openSections: {
                [Sections.LineRequirements]: true,
                [Sections.ContractEndDates]: false,
                [Sections.CurrentCostsOrDiscountsAndBenefits]: false,
                [Sections.DataInternationalCallsAndRoaming]: false,
                [Sections.NetworkProviderOptions]: false,
                [Sections.HandsetsAndDevicesOptions]: false,
                [Sections.MDM]: false,
                [Sections.AdditionalInfo]: false,
                [Sections.SubmitTender]: false,
            },
            scrollTo: undefined,
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.loaded !== this.props.loaded && this.props.loaded) {
            this.updateWidth();
            this.fillOutCompletedSections();
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateWidth);
        this.updateWidth();
        this.fillOutCompletedSections();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }
    updateWidth = () => {
        const width = this.container?.current?.clientWidth || 0;
        this.setState({
            pageWidth: width,
        });
        const clientRect = this.container?.current?.getBoundingClientRect();
        if (clientRect && this.props.updatePositions) {
            const middle = clientRect.left + clientRect.width / 2;
            this.props.updatePositions({ middle });
        }
    };
    goToSection = (section) => {
        this.setState({
            openSections: {
                [section]: true,
            },
            scrollTo: section,
        });
    };
    unsetScrollTo = () => {
        this.setState({
            scrollTo: undefined,
        });
    };
    updateOpenSections = (value) => {
        this.setState({
            openSections: value,
        });
    };
    fillOutCompletedSections = (ignoreAdditionalInfo = true, section) => {
        const { completedSections = {} } = this.state;
        const data = getData(this.props.tender, completedSections[Sections.AdditionalInfo]
            ? false
            : ignoreAdditionalInfo, section);
        for (const item in Sections) {
            const sectionValue = Number(item);
            if (!Number.isNaN(sectionValue)) {
                try {
                    let constraints = {};
                    switch (sectionValue) {
                        case Sections.LineRequirements:
                            constraints = lineRequirementConstraints;
                            break;
                        case Sections.ContractEndDates:
                            constraints = contractDatesConstraints;
                            break;
                        case Sections.CurrentCostsOrDiscountsAndBenefits:
                            if (this.props.tender.usageId === undefined) {
                                constraints = currentCostsConstraints;
                                break;
                            }
                            constraints = discountsConstraints;
                            break;
                        case Sections.DataInternationalCallsAndRoaming:
                            constraints = dataIDDRoamingConstraints;
                            break;
                        case Sections.NetworkProviderOptions:
                            constraints = networkProviderConstraints;
                            break;
                        case Sections.HandsetsAndDevicesOptions:
                            constraints = handsetDevicesConstraints;
                            break;
                        case Sections.MDM:
                            constraints = mdmConstraints;
                            break;
                        case Sections.AdditionalInfo:
                            constraints = additionalInfoConstraints;
                            break;
                    }
                    validation(data, constraints);
                    completedSections[sectionValue] = true;
                }
                catch {
                    completedSections[sectionValue] = false;
                }
            }
        }
        if (this.props.removeErrorsIfFixed) {
            this.props.removeErrorsIfFixed();
        }
        this.setState({
            completedSections,
        });
        return completedSections;
    };
    render() {
        if (this.props.tender === undefined) {
            return React.createElement(NotFoundFallback, null);
        }
        const deviceOptions = [
            {
                value: DeviceRange.Low,
                label: "Budget",
            },
            {
                value: DeviceRange.Mid,
                label: "Mid range",
            },
            {
                value: DeviceRange.High,
                label: "Top of the range",
            },
            ...this.props.devices.map((device) => ({
                value: device.id,
                label: device.name,
            })),
            // .filter(({ value }) =>
            //     this.state.data.model !== undefined
            //         ? !this.state.data.model.includes(value)
            //         : true
            // ),
        ];
        const months = [...Array.from({ length: 12 }).keys()].map((index) => {
            const m = dayjs().add(index, "months");
            return {
                value: m.month(),
                label: m.format("MMM YYYY"),
            };
        });
        const subdomain = getSubdomain();
        const pageContent = this.state.chat ? (React.createElement(TenderChat, { ...this.props, deviceOptions: deviceOptions, months: months })) : (React.createElement(TenderForm, { admin: this.props.admin, tender: this.props.tender, update: this.props.update, upload: this.props.upload, deviceOptions: deviceOptions, save: this.props.submit, errors: this.props.errors, completeTenderQuestions: this.props.completeTenderQuestions, usagePercentage: this.props.tender?.stats?.percentage || 0, months: months, completedSection: this.fillOutCompletedSections, completedSections: this.state.completedSections, openSections: this.state.openSections, updateOpenSections: this.updateOpenSections, scrollTo: this.state.scrollTo, unsetScrollTo: this.unsetScrollTo, publish: this.props.publish, saveTender: this.props.saveTender }));
        return (React.createElement("div", { className: styles.mainContainer },
            React.createElement("div", { className: styles.header, style: { width: `${this.state.pageWidth}px` } },
                React.createElement("div", { className: styles.subHeader },
                    React.createElement(SubHeader, { completedSections: this.state.completedSections, save: this.props.submit, goToSection: this.goToSection, hasUsage: this.props.tender.usageId !== undefined, usagePercentage: this.props.tender?.stats?.percentage || 0, publish: this.props.publish, published: this.props.tender?.publishedAt !== undefined }))),
            React.createElement("div", { className: styles.container, ref: this.container },
                subdomain === "platform" && this.props.admin !== true ? (React.createElement("div", { className: styles.avatarFiller },
                    React.createElement("div", { className: styles.avatar },
                        React.createElement(PredictMascotIcon, null)))) : (React.createElement(React.Fragment, null)),
                React.createElement("div", { className: styles.left },
                    React.createElement(LeftGutter, { tender: this.props.tender, upload: this.props.upload, welcomePopupOpen: this.props.welcomePopupOpen, fetchingUsage: this.props.fetchingUsage, usage: this.props.usage })),
                React.createElement("div", { className: styles.middle }, pageContent),
                React.createElement("div", { className: styles.right },
                    React.createElement(RightGutter, { tender: this.props.tender, isAdmin: this.props.admin, customerName: this.props.customer?.name })))));
    }
}
