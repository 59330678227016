import validate from "validate.js";
export default {
    name: {
        length: { minimum: 2 },
        presence: true,
        type: "string",
    },
    email: {
        presence: true,
        type: "string",
        format: {
            pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        },
    },
    password: {
        presence: true,
        type: "string",
        checkPassword: {
            pattern: /^.{8,}$/,
            message: "must be at least 8 characters long",
        },
        format: {
            pattern: /.*[ !"#$%&'()*+,./:;<=>?@\\^_{|}£-].*/,
            message: "must contain at least one special character",
        },
    },
    companyNo: {
        length: { minimum: 2 },
        presence: true,
        type: "string",
        format: {
            pattern: /^([\da-z]{2})\d{6}$/i,
            message: (value) => validate.format("^%{num} is not a valid company number", {
                num: value,
            }),
        },
    },
};
