import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put, restore } from "@Api/Suppliers/users";
export const DELETE_SUPPLIER_USER = "deleteSupplierUser";
export const deleteUser = (dispatch, user) => del(user)
    .then(({ id }) => {
    dispatch({
        type: DELETE_SUPPLIER_USER,
        data: id,
    });
})
    .then(notify200(dispatch, API_TYPE.DELETE, "Supplier User Deleted", "The supplier user(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_SUPPLIER_USER = "updateSupplierUser";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_SUPPLIER_USER,
        data,
    });
};
export const createUser = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Supplier User Created", "The supplier user was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateUser = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Supplier User Updated", "The supplier user was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchUser = (dispatch, supplierId, userId) => find(supplierId, userId).then(add(dispatch));
export const fetchUsers = (dispatch, supplierId, inclDel) => get(add(dispatch), supplierId, inclDel);
export const restoreUser = (dispatch, user) => restore(user).then(add(dispatch));
