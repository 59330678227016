// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ATG0J{display:flex;justify-content:center;width:100%}.wrapper--ATG0J .typing--jmAxD{display:flex;width:4em}.wrapper--ATG0J .typing--jmAxD .dot--kGOLy{margin:auto;padding:.4em;background-color:#000;border-radius:100%}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/Typing/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,YAAY,CAAC,sBAAsB,CAAC,UAAU,CAAC,+BAAiB,YAAY,CAAC,SAAS,CAAC,2CAAsB,WAAW,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB","sourcesContent":[".wrapper{display:flex;justify-content:center;width:100%}.wrapper .typing{display:flex;width:4em}.wrapper .typing .dot{margin:auto;padding:.4em;background-color:#000;border-radius:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ATG0J",
	"typing": "typing--jmAxD",
	"dot": "dot--kGOLy"
};
export default ___CSS_LOADER_EXPORT___;
