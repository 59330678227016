import React from "react";
import { connect } from "react-redux";
import { findCompany, findTenderDetails, registerIncumbent, } from "@Api/Registration";
import AppSwitch from "@Components/AppSwitch";
import Header from "@Components/Header";
import PredictMascotIcon from "@Components/Icons/PredictMascot";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import SpeechBubble from "@Components/SpeechBubble";
import SupplierSupport from "@Components/SupplierSupport";
import { isPlatform } from "@Helpers/GetSubdomain";
import { addNotification } from "@Store/Actions/Notifications";
import Form from "./Form";
import styles from "./index.module.scss";
class Supplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: "",
            length: undefined,
            totalLines: undefined,
            network: undefined,
            showFee: undefined,
            fetching: true,
        };
    }
    componentDidMount() {
        this.props
            .findTenderDetails(this.props.tenderId)
            .then(({ length, totalLines, network, showFee }) => {
            this.setState({
                length,
                totalLines,
                network,
                showFee,
                fetching: false,
            });
        });
    }
    submit = (details) => {
        return registerIncumbent({ ...details, email: this.props.email }).then((tender) => {
            this.setState({
                redirectTo: `/supplier/tenders/${tender.id}`,
            });
        });
    };
    displayForm = () => {
        if (this.state.fetching) {
            return (React.createElement("div", { className: styles.loading },
                React.createElement(LoadingFallback, null)));
        }
        return (React.createElement(Form, { notify: this.props.notify, find: this.props.findCompanyDetails, onSubmit: this.submit, tenderId: this.props.tenderId, length: this.state.length, totalLines: this.state.totalLines, network: this.state.network, showFee: this.state.showFee }));
    };
    render() {
        if (this.state.redirectTo !== "") {
            return React.createElement(AppSwitch, { uri: this.state.redirectTo });
        }
        const header = "Guest Supplier Registration - Welcome";
        const view = isPlatform();
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, null, header),
            React.createElement("div", { className: styles.container },
                this.displayForm(),
                React.createElement("div", { className: styles.displayRight },
                    React.createElement(SupplierSupport, null))),
            view === true && (React.createElement("div", { className: styles.predictySpeech },
                React.createElement(SpeechBubble, { border: true, className: styles.bubble },
                    "Welcome to the Proposal Platform.",
                    React.createElement("br", null),
                    "Before you continue, please read and accept the terms & conditions associated with using the platform."),
                React.createElement(PredictMascotIcon, { className: styles.iconStyle })))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        findCompanyDetails: (companyNo) => findCompany(companyNo),
        findTenderDetails: (tenderId) => findTenderDetails(tenderId),
    };
};
export default connect(null, mapDispatchToProps)(Supplier);
