export const download = (file, filename) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.append(link);
    link.click();
};
export const navigateExternalUrl = (url) => {
    window.open(url, "_blank").focus();
};
