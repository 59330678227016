import React, { useEffect, useState } from "react";
import Badge from "@Components/Badge";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import Customer from "@Components/Icons/Customer";
import Gears from "@Components/Icons/Gears";
import Supplier from "@Components/Icons/Supplier";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import Progress from "@Components/ProgressBar";
import { round, roundUp } from "@Shared/Helpers/Numbers";
import styles from "./index.module.scss";
export function AnalysingBillReports(props) {
    const { tender, usage } = props;
    const { stats } = tender;
    const [minPercent, setMinPercent] = useState(0);
    const updateMin = () => {
        if (minPercent > 75) {
            return;
        }
        setMinPercent((c) => {
            if (c > 75) {
                return c;
            }
            setTimeout(() => updateMin(), round(Math.random() * 1000, 0) + 1000);
            return roundUp(c + Math.random() * 7, 1);
        });
    };
    useEffect(() => {
        setTimeout(() => updateMin(), 1500);
    }, []);
    if (tender === undefined) {
        return React.createElement(LoadingFallback, null);
    }
    const getPercentage = () => {
        if (usage === undefined) {
            return minPercent;
        }
        let percentage = stats.percentage;
        const { totalRows = 0 } = usage;
        if (stats.allRecords < 0.95 * totalRows) {
            percentage = round((stats.categorisedRecords / totalRows) * 100, 1);
        }
        return Math.max(minPercent, percentage);
    };
    const percentage = getPercentage();
    return (React.createElement(ColouredBorderBox, { icon: React.createElement(Gears, null), title: React.createElement(React.Fragment, null,
            "Analysing",
            " ",
            React.createElement("b", { className: styles.primaryColor }, "Bill Reports")), primary: true },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.title },
                React.createElement("span", null,
                    "We're",
                    " ",
                    React.createElement("span", { className: styles.primaryColor },
                        " ",
                        percentage,
                        "%",
                        " "),
                    " ",
                    "Complete")),
            React.createElement("div", { className: styles.content },
                React.createElement(Progress, { completeValue: 100, index: percentage, className: styles.analysisProgress }),
                React.createElement("div", { className: styles.title }, "How does this help..."),
                React.createElement("div", { className: styles.message },
                    React.createElement("div", { className: styles.messageTitle },
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Customer, null)),
                        "Customers"),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement("div", { className: styles.boxesText },
                            React.createElement("span", null, "Results shown in\u00A0boxes"),
                            React.createElement(Badge, null, "99")),
                        "These numbers help you to accurately complete the Tender\u00A0questions.")),
                React.createElement("div", { className: styles.message },
                    React.createElement("div", { className: styles.messageTitle },
                        React.createElement("div", { className: styles.icon },
                            React.createElement(Supplier, null)),
                        "Suppliers"),
                    React.createElement("div", { className: styles.messageContent }, "Predict provides a full breakdown of your Bill Report usage and translates it into the Supplier's language."))))));
}
export default AnalysingBillReports;
