// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--zOreT{width:100%;height:10em;padding:.5em;overflow-y:scroll;color:var(--text-color);border-color:var(--border-color);border-style:solid;border-width:.1em;border-radius:.2em}.input--zOreT.error--Cb23E{color:var(--error-color);border-color:var(--error-color)}.input--zOreT:hover{border-color:var(--primary-button-color)}.input--zOreT:focus{outline:none}.text--M4B3j{font-weight:lighter !important}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/LargeInput/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,iBAAiB,CAAC,uBAAuB,CAAC,gCAAgC,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,2BAAa,wBAAwB,CAAC,+BAA+B,CAAC,oBAAa,wCAAwC,CAAC,oBAAa,YAAY,CAAC,aAAM,8BAA8B","sourcesContent":[".input{width:100%;height:10em;padding:.5em;overflow-y:scroll;color:var(--text-color);border-color:var(--border-color);border-style:solid;border-width:.1em;border-radius:.2em}.input.error{color:var(--error-color);border-color:var(--error-color)}.input:hover{border-color:var(--primary-button-color)}.input:focus{outline:none}.text{font-weight:lighter !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--zOreT",
	"error": "error--Cb23E",
	"text": "text--M4B3j"
};
export default ___CSS_LOADER_EXPORT___;
