import Button from "@Components/Button";
import Card from "@Components/Card";
import CustomerIcon from "@Components/Icons/Customer";
import buttons from "@Components/Sass/buttons.module.scss";
import Table from "@Components/Table";
import displayPublishDate from "@Helpers/DisplayPublishDate";
import GetPath from "@Helpers/GetPath";
import { displayDate } from "@Shared/Helpers/Date";
import { displayBool } from "@Shared/Helpers/DisplayBool";
import { CUSTOMER, CUSTOMER_TENDER, CUSTOMER_TENDER_RESPONSES, } from "@Shared/Paths/Admin";
import dayjs from "dayjs";
import React from "react";
import { NavLink } from "react-router-dom";
export default class Tenders extends React.Component {
    render() {
        const fields = [
            {
                field: "id",
            },
            {
                field: "customerId",
                hidden: true,
            },
            {
                field: "customer",
                header: "Customer",
                noLink: true,
                sortable: true,
                searchable: true,
                alias: (tender) => {
                    const customer = this.props.customers.find((company) => company.id === tender.customerId);
                    if (customer) {
                        return customer.name;
                    }
                    return "";
                },
                display: (tender) => {
                    if (tender.customer) {
                        return (React.createElement(NavLink, { to: GetPath(CUSTOMER, tender.customerId) }, tender.customer));
                    }
                    return "";
                },
            },
            {
                field: "publishedAt",
                header: "Published At",
                sortable: true,
                searchable: true,
                display: (tender) => displayPublishDate(tender),
            },
            {
                field: "supplierDeadline",
                header: "Closes",
                sortable: true,
                searchable: true,
                display: (tender) => displayDate(tender.supplierDeadline, true),
            },
            {
                field: "resultsViewable",
                header: "Customer Can View",
                sortable: true,
                searchable: true,
                display: (tender) => {
                    return tender.resultsViewable
                        ? displayDate(tender.resultsViewable, true)
                        : "";
                },
            },
            {
                field: "customerDeadline",
                header: "Customer Selects By",
                sortable: true,
                searchable: true,
                display: (tender) => displayDate(tender.customerDeadline, true),
            },
            {
                field: "resultsAvailable",
                header: "Results Available",
                sortable: true,
                searchable: true,
                display: (tender) => {
                    return (React.createElement(NavLink, { to: GetPath(CUSTOMER_TENDER_RESPONSES, tender.customerId, tender.id) }, displayBool(tender.resultsAvailable)));
                },
            },
            {
                field: "view",
                noLink: true,
                display: (tender) => {
                    const unsubmit = (tender) => async () => {
                        await this.props.unsubmitTender(tender);
                    };
                    return (React.createElement("div", { className: buttons.buttonGroup }, tender.publishedAt !== undefined && (React.createElement(Button, { dark: true, click: unsubmit(tender) }, "UNSUBMIT"))));
                },
            },
        ];
        const sortedData = this.props.tenders
            .map((tender) => {
            const customer = this.props.customers.find(({ id }) => id === tender.id);
            return {
                tender,
                customer,
            };
        })
            .sort((a, b) => dayjs(b.customer?.createdAt).diff(dayjs(a.customer?.createdAt)) ||
            dayjs(b.tender.publishedAt).diff(dayjs(a.tender.publishedAt)))
            .map(({ tender }) => tender);
        return (React.createElement(Card, { title: React.createElement(React.Fragment, null,
                React.createElement(CustomerIcon, null),
                " Tenders") },
            React.createElement(Table, { link: (data) => GetPath(CUSTOMER_TENDER, data.customerId, data.id), data: sortedData, fields: fields, fetching: this.props.fetching, reload: this.props.reload })));
    }
}
