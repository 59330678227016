import Company from "@Shared/Entities/AbstractEntities/Company";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Partner extends Company {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/partners/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { subDomain, apiKey, type, showFullBreakdown, hideWinnersFee, margin, allowedUsage, } = this;
        const { version } = this._data;
        return {
            ...parent,
            version,
            subDomain,
            apiKey,
            type,
            showFullBreakdown,
            hideWinnersFee,
            margin,
            allowedUsage,
        };
    }
    get allowedUsage() {
        return this._data.allowedUsage;
    }
    get subDomain() {
        return this._data.subDomain;
    }
    get type() {
        return this._data.type;
    }
    get apiKey() {
        return this._data.apiKey;
    }
    get showFullBreakdown() {
        return this._data.showFullBreakdown;
    }
    get hideWinnersFee() {
        return this._data.hideWinnersFee;
    }
    get margin() {
        return this._data.margin;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
            users: `${this.baseUrl}/users`,
            customers: `${this.baseUrl}/customers`,
        };
    }
}
