import { fetchPostcodeSignal } from "@Api/Customers/tenders";
import Card from "@Components/Card";
import MultiTextAdd from "@Components/Form/MultiTextAdd";
import HoverTooltip from "@Components/HoverTooltip";
import Close from "@Components/Icons/Close";
import OfcomLogo from "@Components/Icons/Ofcom";
import InfoPopup from "@Components/InfoPopup";
import { ToastStatus } from "@Components/Toast";
import { Quality, } from "@Shared/Entities/Customer/Tender/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { getIconFromNetwork, getNetworkFromPlatform, } from "@Shared/Helpers/Network";
import { validatePostcode } from "@Shared/Validate";
import { fix } from "postcode";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
const signalDataKey = "postcodes_signal_data";
const getSignalData = () => {
    const postcodeSignalData = localStorage.getItem(signalDataKey);
    if (postcodeSignalData) {
        const signalDataObject = JSON.parse(postcodeSignalData);
        return signalDataObject;
    }
    return {};
};
export default (props) => {
    const postcodesListKey = `signal_postcodes_${props.tender.id}`;
    const getSignalPostcodes = (importantPostcodes) => {
        const postcodeList = localStorage.getItem(postcodesListKey);
        if (postcodeList) {
            const postcodeArray = JSON.parse(postcodeList);
            return [...new Set([...importantPostcodes, ...postcodeArray])];
        }
        return [...new Set(importantPostcodes)];
    };
    const [signalData, setSignalData] = useState(() => getSignalData());
    const [postcodesList, setPostcodes] = useState(() => getSignalPostcodes(props.tender.details.postcodes));
    useEffect(() => {
        fetchSignalRatings(postcodesList);
        localStorage.setItem(postcodesListKey, JSON.stringify(postcodesList));
    }, [postcodesList]);
    useEffect(() => {
        setPostcodes(getSignalPostcodes(props.tender.details.postcodes));
    }, [props.open]);
    const fetchSignalRatings = async (postcodes) => {
        if (Object.keys(props.tender.ofcom).length === 0) {
            return;
        }
        const signalDataObject = { ...signalData };
        const fetchNeeded = [];
        for (const postcode of postcodes) {
            if (!signalDataObject[postcode]) {
                fetchNeeded.push(postcode);
            }
        }
        const fetchedData = await Promise.all(fetchNeeded.map((postcode) => fetchPostcodeSignal(props.tender, postcode).catch((error) => {
            if (error.response?.status === 404) {
                props.notify({
                    title: "Oops",
                    description: error.response.data.message,
                    status: ToastStatus.Error,
                });
                return null;
            }
            props.notify({
                title: "Oops",
                description: `Couldn't get signal data for: ${postcode}`,
                status: ToastStatus.Error,
            });
            console.log(error);
            return null;
        })));
        for (const [index, data] of fetchedData.entries()) {
            if (data === null) {
                setPostcodes(postcodesList.filter((postcode) => postcode !== fetchNeeded[index]));
            }
            if (data) {
                signalDataObject[fetchNeeded[index]] = data;
            }
        }
        setSignalData(signalDataObject);
        localStorage.setItem(signalDataKey, JSON.stringify(signalDataObject));
    };
    const networkValues = props.tariffComponents.map((tc) => tc.networkComponent);
    const networkKeysSet = new Set(networkValues.map((value) => getNetworkFromPlatform(value)));
    const showQualityKey = () => {
        const qualities = [
            Quality.Clear,
            Quality.Red,
            Quality.Amber,
            Quality.Green,
            Quality.Enhanced,
        ];
        return (React.createElement("div", { className: styles.scaleWrapper },
            React.createElement("div", { className: styles.scaleEnd }, "Worst Signal -"),
            React.createElement("div", { className: styles.scale }, qualities.map((quality) => (React.createElement("div", { className: `${styles.quality} ${getQualityClass(quality)}`, key: quality }, displayEnum(Quality, quality))))),
            React.createElement("div", { className: styles.scaleEnd }, "- Best Signal")));
    };
    const getQualityClass = (quality) => {
        switch (quality) {
            case Quality.Enhanced:
                return styles.veryGood;
            case Quality.Green:
                return styles.good;
            case Quality.Amber:
                return styles.medium;
            case Quality.Red:
                return styles.bad;
            case Quality.Clear:
                return styles.veryBad;
        }
    };
    const displaySignalRatings = () => {
        const getSignalRatings = (ofcom) => Object.entries(ofcom).reduce((object, [networkKey, qualityData = {}]) => {
            const network = Number(networkKey);
            if (!networkKeysSet.has(network)) {
                return object;
            }
            object[network] = {
                voice: qualityData.VoiceIndoor,
                data: qualityData.DataIndoor,
            };
            return object;
        }, {});
        const postcodeSignalRatings = {
            ...Object.entries(props.tender.ofcom).reduce((object, [postcode, ofcomRating]) => {
                object[postcode] = getSignalRatings(ofcomRating);
                return object;
            }, {}),
            ...Object.entries(signalData).reduce((object, [postcode, networkQuality]) => {
                object[fix(postcode)] = getSignalRatings(networkQuality);
                return object;
            }, {}),
        };
        const convertedSignalRatings = Object.entries(postcodeSignalRatings).reduce((object, [postcode, signalRatings]) => {
            for (const [networkKey, networkQuality] of Object.entries(signalRatings)) {
                const network = Number(networkKey);
                object[network] = {
                    ...object[network],
                    [postcode]: networkQuality,
                };
            }
            return object;
        }, {});
        const displayQuality = (name, value) => {
            return (React.createElement("div", { className: styles.qualities },
                React.createElement("div", { className: styles.type }, name),
                React.createElement("div", { className: `${styles.quality} ${getQualityClass(value)}` }, displayEnum(Quality, value))));
        };
        return Object.entries(convertedSignalRatings).map(([network, postcodesObject]) => {
            const postcodes = postcodesList.map((postcode) => {
                const { voice, data } = postcodesObject[postcode] || {};
                return (React.createElement("div", { className: styles.postcodeQualities, key: `${postcode}-${voice}-${data}` },
                    React.createElement("div", { className: styles.postcode }, fix(postcode)),
                    displayQuality("Voice", voice),
                    displayQuality("Data", data)));
            });
            return (React.createElement("div", { className: styles.network },
                React.createElement("h2", { className: styles.networkHeader }, getIconFromNetwork(Number(network))),
                postcodes));
        });
    };
    const changePostcodes = (name, tags) => {
        const postcodeArray = [...new Set(tags)];
        setPostcodes(postcodeArray);
    };
    const formatPostcode = (text) => {
        if (validatePostcode(text) === true) {
            return fix(text);
        }
    };
    const popup = () => {
        return (React.createElement(Card, { className: styles.card },
            React.createElement("div", { className: styles.header },
                React.createElement("div", { className: styles.mainTitle }, "Ofcom's Rating"),
                React.createElement("div", { className: styles.exit },
                    React.createElement(Close, { onClick: props.onClose, color: "#58585a", size: "0.75rem", className: styles.button }))),
            React.createElement("div", { className: styles.qualityKey }, showQualityKey()),
            React.createElement("div", { className: styles.networkCoverage }, displaySignalRatings()),
            React.createElement("div", { className: styles.footer },
                React.createElement("div", { className: styles.ofcom },
                    React.createElement(OfcomLogo, null),
                    React.createElement(HoverTooltip, { style: { maxWidth: "40rem" } }, "The data displayed on mobile coverage availability uses source data made available by Ofcom which is based on data from the mobile operators about how strong they think signal levels are at every location in the UK. Each mobile operator has a slightly different approach to displaying coverage on its own map, including assumptions on the handsets used, levels of call reliability and the expected signal loss when indoors or in car. Because Ofcom brings all mobile operator data together in a single place and holds it to a single, independent standard, the mobile coverage availability information above may display different levels of coverage than those seen on the operators' websites. We would therefore recommend you also check the mobile provider's coverage checker. Mobile coverage data is created by coverage modelling, and since this is based on computer predictions is not error free. Ofcom update the source data regularly (every month) and the mobile network operators update theirs, but there may be times when the maps are based on slightly different data and therefore show different coverage.")),
                !props.sharedComparison && (React.createElement("div", { className: styles.addPostcodes },
                    React.createElement("div", { className: styles.label }, "Add Postcodes"),
                    React.createElement(MultiTextAdd, { onChange: changePostcodes, values: postcodesList, separators: [",", " "], format: formatPostcode }))))));
    };
    return (React.createElement(InfoPopup, { trigger: props.open, content: popup, onClose: props.onClose }));
};
