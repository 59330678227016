import React from "react";
import { connect } from "react-redux";
import { checkMfa } from "@Api/Auth";
import Button from "@Components/Button";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import PredictyIconEyesOpen from "@Components/Icons/ChatBot/Predicty/PredictyEyesOpen";
import buttons from "@Components/Sass/buttons.module.scss";
import { ERROR } from "@Components/Toast";
import { addNotification } from "@Store/Actions/Notifications";
import { boot } from "@Store/Actions/Auth";
import styles from "./../index.module.scss";
class MFA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { code: "" },
            errors: {},
        };
    }
    inputChange = (data) => {
        this.setState({ data });
    };
    submitMfa = async () => {
        try {
            const { code } = this.state.data;
            await checkMfa(this.props.user.id, code);
            this.props.boot();
        }
        catch {
            this.props.notify({
                title: "Failed to log in",
                description: "MFA code incorrect",
                status: ERROR,
            });
            this.setState({
                errors: { code: true },
            });
        }
    };
    render() {
        return (React.createElement("div", { className: styles.mainContent },
            React.createElement(Card, { className: styles.green },
                React.createElement("div", { className: styles.centered },
                    React.createElement(PredictyIconEyesOpen, { className: styles.logo })),
                React.createElement(Form, { className: styles.form, submit: this.submitMfa, labelLeft: false, input: [
                        {
                            label: "MFA Code",
                            name: "code",
                            type: InputType.Text,
                        },
                    ], buttons: false, change: this.inputChange, data: this.state.data, errors: this.state.errors },
                    React.createElement("div", { className: styles.login },
                        React.createElement("div", { className: `${buttons.buttonGroupCenter} ${styles.noMargin}` },
                            React.createElement(Button, { cta: true, type: "submit" }, "VERIFY")))))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        boot: () => boot(dispatch),
        notify: (data) => addNotification(dispatch, data),
    };
};
const mapStateToProps = ({ auth: { user } }) => ({
    user,
});
export default connect(mapStateToProps, mapDispatchToProps)(MFA);
