import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import userMigrate from "@Shared/Entities/AbstractEntities/User/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    let { role } = current;
    if (role) {
        role = role.replaceAll("_", " ");
    }
    return { ...current, role, version: 2 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const userMigrated = userMigrate(original);
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    const { version, customerId, role } = converted;
    return {
        ...baseMigrated,
        ...userMigrated,
        version,
        customerId,
        role,
    };
};
