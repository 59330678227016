// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterMenuItem--ieg5R{width:15em;padding:1em 2em;color:var(--text-color);font-size:.85rem;background-color:var(--card-color);-webkit-box-shadow:0 .1em 1em rgba(93,94,94,.1);box-shadow:0 .1em 1em rgba(93,94,94,.1)}.filterMenuItem--ieg5R.large--kYEJP{width:25em}.filterMenuItem--ieg5R .filterTitle--HIrE3{padding-bottom:var(--standard-vertical-padding);font-weight:bold;font-size:.85rem}.filterMenuItem--ieg5R .filterList--WuU8y{margin:0;padding-bottom:var(--standard-vertical-padding);text-align:left;list-style:none}.filterMenuItem--ieg5R .filterList--WuU8y li{padding-bottom:var(--standard-vertical-padding)}.filterMenuItem--ieg5R .filterList--WuU8y li label input{margin-right:var(--standard-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/FilterMenu/FilterMenuField/index.module.scss"],"names":[],"mappings":"AAAA,uBAAgB,UAAU,CAAC,eAAe,CAAC,uBAAuB,CAAC,gBAAgB,CAAC,kCAAkC,CAAC,+CAA+C,CAAC,uCAAuC,CAAC,oCAAsB,UAAU,CAAC,2CAA6B,+CAA+C,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,0CAA4B,QAAQ,CAAC,+CAA+C,CAAC,eAAe,CAAC,eAAe,CAAC,6CAA+B,+CAA+C,CAAC,yDAA2C,+CAA+C","sourcesContent":[".filterMenuItem{width:15em;padding:1em 2em;color:var(--text-color);font-size:.85rem;background-color:var(--card-color);-webkit-box-shadow:0 .1em 1em rgba(93,94,94,.1);box-shadow:0 .1em 1em rgba(93,94,94,.1)}.filterMenuItem.large{width:25em}.filterMenuItem .filterTitle{padding-bottom:var(--standard-vertical-padding);font-weight:bold;font-size:.85rem}.filterMenuItem .filterList{margin:0;padding-bottom:var(--standard-vertical-padding);text-align:left;list-style:none}.filterMenuItem .filterList li{padding-bottom:var(--standard-vertical-padding)}.filterMenuItem .filterList li label input{margin-right:var(--standard-horizontal-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenuItem": "filterMenuItem--ieg5R",
	"large": "large--kYEJP",
	"filterTitle": "filterTitle--HIrE3",
	"filterList": "filterList--WuU8y"
};
export default ___CSS_LOADER_EXPORT___;
