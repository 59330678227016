import Card from "@Components/Card";
import HoverTooltip from "@Components/HoverTooltip";
import Close from "@Components/Icons/Close";
import InfoPopup from "@Components/InfoPopup";
import Table from "@Components/Table";
import { displayAllowanceTooltip } from "@Helpers/DisplayAllowanceTooltip";
import { DataSize } from "@Shared/Entities/Customer/Usage/enums";
import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import ConvertUnitsForDisplay from "@Shared/Helpers/CalculateUsageCovered/ConvertUnitsForDisplay";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
import { dataConvert } from "@Shared/Helpers/Data";
import { format } from "@Shared/Helpers/Money";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import { createList } from "@SmartComponents/TenderResponses/PagePopup/Summary/Accordion";
import React from "react";
import parentStyles from "../Accordion/index.module.scss";
import styles from "./index.module.scss";
export default (props) => {
    const { tender, proposal, oobCost } = props;
    const { breakdown } = tender;
    if (breakdown === undefined) {
        const display = (key) => {
            let subtitle = key;
            switch (key) {
                case "Int SMS":
                    subtitle = "UK to Int SMS";
                    break;
                case "Int MMS":
                    subtitle = "UK to Int MMS";
            }
            return {
                subtitle: (React.createElement(React.Fragment, null,
                    subtitle,
                    displayAllowanceTooltip(key))),
                value: format(proposal.rackRate[AllowanceTypes[key]]),
            };
        };
        const ukKeys = [
            AllowanceTypes["Biz rate Calls (mins)"],
            AllowanceTypes["Prem Calls"],
            AllowanceTypes["Prem SMS"],
            AllowanceTypes["Std MMS"],
            AllowanceTypes["Dir Enq"],
        ];
        const iddKeys = [
            AllowanceTypes["UK IDD to EU (mins)"],
            AllowanceTypes["UK IDD to USA & Canada (mins)"],
            AllowanceTypes["UK IDD to APAC (mins)"],
            AllowanceTypes["UK IDD to ROW (mins)"],
            AllowanceTypes["Int SMS"],
            AllowanceTypes["Int MMS"],
        ];
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, "These are the categories most commonly charged outside of any tariff or bundle."),
            createList([
                //header
                {
                    subtitle: (React.createElement("div", { className: parentStyles.header }, "UK Chargeable Usage")),
                    value: (React.createElement("div", { className: parentStyles.header }, "Cost per item")),
                },
                ...ukKeys.map((key) => display(key)),
                {
                    subtitle: (React.createElement("div", { className: parentStyles.header }, "UK to International Numbers")),
                    value: (React.createElement("div", { className: parentStyles.header }, "Cost per item")),
                },
                ...iddKeys.map((key) => display(key)),
            ]),
            React.createElement("div", null,
                React.createElement("b", null, "EU Roaming"),
                React.createElement("br", null),
                "Refer to Supplier's suggested bundles",
                React.createElement("br", null),
                React.createElement("b", null, "Non-EU Roaming"),
                React.createElement("br", null),
                "Refer to Supplier's suggested bundles")));
    }
    const covered = CalculateUsageCovered(tender, proposal);
    const reduceData = (filterZeroCost = false) => (array, key) => {
        const { costOfBundles, remaining, used, coveredByBundles, coveredByTender, } = covered[key];
        const rackRateCost = proposal.rackRate[key] || 0;
        // if (!(costOfBundles > 0 || used > 0 )) {
        //     return arr
        // }
        let filterOut = used <= 0;
        if (filterOut && coveredByBundles > 0) {
            filterOut = false;
        }
        if (!filterOut && coveredByTender > used && costOfBundles <= 0) {
            filterOut = true;
        }
        const uncoveredCost = ConvertUnitsForDisplay(remaining, key) * rackRateCost;
        const totalCost = uncoveredCost + costOfBundles;
        if (!filterOut && totalCost <= 0 && filterZeroCost) {
            filterOut = true;
        }
        if (filterOut) {
            return array;
        }
        array.push({
            key,
            costOfBundles,
            remaining,
            rackRateCost,
            used,
            uncoveredCost,
            totalCost,
            covered: coveredByBundles + coveredByTender,
        });
        return array;
    };
    const ukOOBCosts = [
        AllowanceTypes["UK Data (GB)"],
        AllowanceTypes["Std Calls (mins)"],
        AllowanceTypes["Std SMS"],
        AllowanceTypes["Biz rate Calls (mins)"],
        AllowanceTypes["Prem Calls"],
        AllowanceTypes["Prem SMS"],
        AllowanceTypes["Std MMS"],
        AllowanceTypes["Dir Enq"],
        AllowanceTypes["Free Calls (mins)"],
    ].reduce(reduceData(true), []);
    const iddOOBCosts = [
        AllowanceTypes["UK IDD to EU (mins)"],
        AllowanceTypes["UK IDD to USA & Canada (mins)"],
        AllowanceTypes["UK IDD to APAC (mins)"],
        AllowanceTypes["UK IDD to ROW (mins)"],
        AllowanceTypes["Int SMS"],
        AllowanceTypes["Int MMS"],
    ].reduce(reduceData(), []);
    const euRoamingCosts = [
        AllowanceTypes["EU Data (GB)"],
        AllowanceTypes["EU Calls (mins)"],
        AllowanceTypes["EU SMS"],
        AllowanceTypes["EU MMS"],
    ].reduce(reduceData(), []);
    const nonEURoamingCosts = [
        AllowanceTypes["Non-EU Data (GB)"],
        AllowanceTypes["Non-EU Calls (mins)"],
        AllowanceTypes["Non-EU SMS"],
        AllowanceTypes["Non-EU MMS"],
    ].reduce(reduceData(), []);
    const createTitleRow = (title, costsArray) => {
        if (costsArray.length === 0) {
            return;
        }
        return {
            key: title,
            className: styles.titleRow,
        };
    };
    const totalBundleCost = [
        ...ukOOBCosts,
        ...iddOOBCosts,
        ...euRoamingCosts,
        ...nonEURoamingCosts,
    ].reduce((totalCost, object) => {
        return totalCost + object.costOfBundles;
    }, 0);
    const totalUncoveredCost = [
        ...ukOOBCosts,
        ...iddOOBCosts,
        ...euRoamingCosts,
        ...nonEURoamingCosts,
    ].reduce((totalCost, object) => {
        return totalCost + object.uncoveredCost;
    }, 0);
    const oobCosts = [
        createTitleRow("UK Chargeable Usage", ukOOBCosts),
        ...ukOOBCosts,
        createTitleRow("UK to International Usage", iddOOBCosts),
        ...iddOOBCosts,
        createTitleRow("EU Roaming Usage", euRoamingCosts),
        ...euRoamingCosts,
        createTitleRow("Non-EU Roaming Usage", nonEURoamingCosts),
        ...nonEURoamingCosts,
        {
            key: "Estimated Variable Cost",
            className: `${styles.titleRow} ${styles.totalRow}`,
            uncoveredCost: totalUncoveredCost,
            costOfBundles: totalBundleCost,
            totalCost: oobCost,
        },
    ].filter((object) => object !== undefined);
    const isRowTitle = (key) => {
        return !(key in AllowanceTypes);
    };
    const fields = [
        {
            field: "key",
            header: "",
            leftAlign: true,
        },
        {
            field: "used",
            // className: styles.cell,
            header: (React.createElement(React.Fragment, null,
                "Avg. Usage",
                React.createElement(HoverTooltip, { iconClassName: styles.hoverTooltipClass }, "This is an estimate of your average monthly use based on your Bill\u00A0Reports"))),
            // rightAlign: true,
            alias: ({ used, key }) => {
                if (isRowTitle(key)) {
                    return;
                }
                const type = GetUnits(key);
                if (type === UnitType.Data) {
                    return dataConvert(used, DataSize.MB);
                }
                return roundToFormat(ConvertUnitsForDisplay(used, key), 0);
            },
        },
        {
            field: "covered",
            // className: styles.cell,
            header: (React.createElement(React.Fragment, null,
                "Covered",
                React.createElement(HoverTooltip, { iconClassName: styles.hoverTooltipClass }, "The qty that this proposal's tariff allowance and bundles would\u00A0cover"))),
            // rightAlign: true,
            alias: ({ covered, key, }) => {
                if (isRowTitle(key)) {
                    return;
                }
                const type = GetUnits(key);
                if (type === UnitType.Data) {
                    return dataConvert(covered, DataSize.MB);
                }
                return roundToFormat(ConvertUnitsForDisplay(covered, key), 0);
            },
        },
        {
            field: "remaining",
            // className: styles.cell,
            header: (React.createElement(React.Fragment, null,
                "Remaining",
                React.createElement(HoverTooltip, { iconClassName: styles.hoverTooltipClass }, "The min, text or data remaining that would be chargeable at the cost per unit price"))),
            // rightAlign: true,
            alias: ({ remaining, key, }) => {
                if (isRowTitle(key)) {
                    return;
                }
                const type = GetUnits(key);
                if (type === UnitType.Data) {
                    return dataConvert(remaining, DataSize.MB);
                }
                return roundToFormat(ConvertUnitsForDisplay(remaining, key), 0);
            },
        },
        {
            field: "rackRateCost",
            // className: styles.cell,
            header: "Cost Per Unit",
            // rightAlign: true,
            alias: ({ rackRateCost, key, }) => {
                if (isRowTitle(key)) {
                    return;
                }
                return format(rackRateCost);
            },
        },
        {
            field: "uncoveredCost",
            className: styles.cell,
            header: (React.createElement(React.Fragment, null,
                "Uncovered Cost",
                React.createElement(HoverTooltip, { iconClassName: styles.hoverTooltipClass }, "Cost of usage not covered by bundles or your tariff\u00A0allowance"))),
            rightAlign: true,
            display: ({ uncoveredCost, key, }) => {
                if (!isRowTitle(key)) {
                    return format(uncoveredCost);
                }
                if (uncoveredCost !== undefined) {
                    return (React.createElement("div", { className: styles.totalVal }, format(uncoveredCost, 2) || 0));
                }
                return;
            },
        },
        {
            field: "costOfBundles",
            className: ({ costOfBundles }) => `${styles.cell} ${costOfBundles > 0 ? styles.boldVal : ""}`.trim(),
            header: "Bundle Cost",
            rightAlign: true,
            display: ({ costOfBundles, key, }) => {
                if (!isRowTitle(key)) {
                    return format(costOfBundles);
                }
                if (costOfBundles !== undefined) {
                    return (React.createElement("div", { className: [styles.totalVal, styles.link].join(" "), onClick: props.openBundles }, format(costOfBundles, 2) || 0));
                }
                return;
            },
        },
        {
            field: "totalCost",
            className: `${styles.cell} ${styles.boldVal}`,
            header: "Total Cost",
            rightAlign: true,
            display: ({ key, totalCost, }) => {
                if (!isRowTitle(key)) {
                    return format(totalCost);
                }
                if (totalCost !== undefined) {
                    return (React.createElement("div", { className: `${styles.totalBox} ${styles.totalOverall}` }, format(totalCost, 2) || 0));
                }
                return;
            },
        },
        { field: "className", hidden: true },
    ];
    const popup = () => {
        return (React.createElement(Card, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.exit },
                    React.createElement(Close, { onClick: props.onClose, color: "#58585a", size: "0.75rem", className: styles.button })),
                React.createElement("div", { className: styles.headerText }, "Estimated Variable Cost outside your tariff allowance, based on your current average mobile use"),
                React.createElement(Table, { data: oobCosts, fields: fields, noNav: true, padding: "0.5rem", light: true, className: styles.table }))));
    };
    const shownWithSummary = !props.comparison && window.innerWidth > 950;
    return (React.createElement(InfoPopup, { trigger: props.open, content: popup, onClose: props.onClose, overlayStyles: shownWithSummary
            ? {
                background: "none",
                pointerEvents: "none",
            }
            : {}, styles: shownWithSummary
            ? {
                marginRight: "470px",
            }
            : {}, closeOnDocumentClick: !shownWithSummary }));
};
