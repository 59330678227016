export default {
    name: {
        presence: true,
        type: "string",
    },
    ref: {
        presence: true,
        type: "string",
    },
};
