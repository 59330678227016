import { DeliveryChargeMethod, DetailsFields, LikeHardwareFunded, SimOnlyFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Effected, Months, TimeFrame, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const hardwareFundPresenceConstraints = {
    //check device prices is present
    [Effected.DevicePrices]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
                inverted: true,
            },
        ],
        length: {
            minimum: 1,
        },
    },
    //questions shown if likeHardwareFunded === Included_in_tariff
    [Effected.TotalFund]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
        ],
    },
    [Effected.CashHW]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
    },
    [Effected.HWFundTaken]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
            {
                field: Effected.CashHW,
                value: true,
            },
        ],
    },
    [Effected.DrawDown]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: undefined,
                    inverted: true,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
    },
    [Effected.DeductSims]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
    },
    [Effected.DeductedPerSim]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
            {
                field: Effected.DeductSims,
                value: true,
            },
        ],
    },
    [Effected.SimCost]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
    },
    [Effected.ChargePerSim]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.SimCost,
                value: true,
            },
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
    },
    [Effected.OfferLeasing]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
        ],
    },
    [Effected.SetupFee]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
    },
    [Effected.MinimumLeaseTerm]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
    },
    //leasing prices
    [Effected.LeasingRequiresInsurance]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
    },
    [Effected.DirectDebit]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
    },
    [Effected.DirectDebitCost]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
            {
                field: Effected.DirectDebit,
                value: true,
            },
        ],
    },
    [Effected.LeasingLumpSum]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
    },
    //made leasingNotes not required
    [Effected.HandsetProfitMargin]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
    },
    [Effected.AppleDEP]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
    },
    [Effected.DeviceInsurance]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
    },
    [Effected.ChargeForDelivery]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
    },
    [Effected.DeliveryChargeMethod]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Leasing,
                },
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
            ],
            {
                field: Effected.ChargeForDelivery,
                value: true,
            },
        ],
    },
    [Effected.DeliveryCharge]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Leasing,
                },
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
            ],
            {
                field: Effected.ChargeForDelivery,
                value: true,
            },
        ],
    },
    //made extra details not required
};
export const hardwareFundValidConstraints = {
    //questions shown if likeHardwareFunded === Included_in_tariff
    [Effected.TotalFund]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
        ],
        type: "number",
    },
    [Effected.CashHW]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
        type: "boolean",
    },
    [Effected.HWFundTaken]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
            {
                field: Effected.CashHW,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.DrawDown]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
        enum: TimeFrame,
    },
    [Effected.DeductSims]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
        type: "boolean",
    },
    [Effected.DeductedPerSim]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
            {
                field: Effected.DeductSims,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.SimCost]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
        type: "boolean",
    },
    [Effected.ChargePerSim]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
                {
                    field: DetailsFields.SimOnlyFunded,
                    value: SimOnlyFunded.To_receive_a_fund_to_spend_as_I_wish,
                },
            ],
            {
                field: Effected.SimCost,
                value: true,
            },
            {
                field: Effected.TotalFund,
                value: 0,
                inverted: true,
            },
        ],
        type: "number",
    },
    [Effected.OfferLeasing]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
        ],
        type: "boolean",
    },
    [Effected.SetupFee]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.MinimumLeaseTerm]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
        enum: Months,
    },
    //leasing prices
    [Effected.LeasingRequiresInsurance]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
        type: "boolean",
    },
    [Effected.DirectDebit]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
        type: "boolean",
    },
    [Effected.DirectDebitCost]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
            {
                field: Effected.DirectDebit,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.LeasingLumpSum]: {
        conditionalPresenceMatchAll: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: Effected.OfferLeasing,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.LeasingNotes]: {
        type: "string",
    },
    [Effected.HandsetProfitMargin]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
        type: "boolean",
    },
    [Effected.AppleDEP]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
        type: "boolean",
    },
    [Effected.DeviceInsurance]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
        type: "boolean",
    },
    [Effected.ChargeForDelivery]: {
        conditionalPresenceMatchOne: [
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Leasing,
            },
            {
                field: DetailsFields.LikeHardwareFunded,
                value: LikeHardwareFunded.Included_in_tariff,
            },
        ],
        type: "boolean",
    },
    [Effected.DeliveryChargeMethod]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Leasing,
                },
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
            ],
            {
                field: Effected.ChargeForDelivery,
                value: true,
            },
        ],
        enum: DeliveryChargeMethod,
    },
    [Effected.DeliveryCharge]: {
        conditionalPresenceMatchAll: [
            [
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Leasing,
                },
                {
                    field: DetailsFields.LikeHardwareFunded,
                    value: LikeHardwareFunded.Included_in_tariff,
                },
            ],
            {
                field: Effected.ChargeForDelivery,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.ExtraDeviceDetails]: {
        type: "string",
    },
};
