import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import { UnitType } from "./enums";
import migrate from "./migrate";
export default class Bundle extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { id, roamLikeHome, name, mins, sms, mms, dataMB, type, duration, numBundles, cost, applyToRows, color, allowanceTypeGroup, } = this;
        return {
            id,
            roamLikeHome,
            name,
            mins,
            sms,
            mms,
            dataMB,
            type,
            duration,
            numBundles,
            cost,
            applyToRows,
            color,
            allowanceTypeGroup,
        };
    }
    get id() {
        return this._data.id;
    }
    get roamLikeHome() {
        return this._data.roamLikeHome === true;
    }
    get name() {
        return this._data.name;
    }
    get mins() {
        return this._data.mins;
    }
    get sms() {
        return this._data.sms;
    }
    get mms() {
        return this._data.mms;
    }
    get dataMB() {
        return this._data.dataMB;
    }
    qtyFor(type) {
        switch (type) {
            case UnitType.Data:
                return this.dataMB;
            case UnitType.Mins:
                return this.mins;
            case UnitType.SMS:
                return this.sms;
            case UnitType.MMS:
                return this.mms;
            default:
                return 0;
        }
    }
    get type() {
        return this._data.type;
    }
    get duration() {
        return this._data.duration;
    }
    get numBundles() {
        return this._data.numBundles;
    }
    get cost() {
        return this._data.cost;
    }
    get applyToRows() {
        return this._data.applyToRows;
    }
    get color() {
        return this._data.color;
    }
    get allowanceTypeGroup() {
        return this._data.allowanceTypeGroup;
    }
}
