import React from "react";
import { connect } from "react-redux";
import Button from "@Components/Button";
import FormInput from "@Components/Form/Input";
import TwoWayToggleSmall from "@Components/Form/TwoWayToggleSmall";
import Close from "@Components/Icons/Close";
import Exit from "@Components/Icons/Exit";
import InfoPopup from "@Components/InfoPopup";
import SubHeader from "@Components/SubHeader";
import { LikeHardwareFunded } from "@Shared/Entities/Customer/Tender/Details/enums";
import BeginContracting from "@SmartComponents/TenderResponses/BeginContracting";
import OldContractDetails from "@SmartComponents/TenderResponses/OldContractDetails";
import Summary from "@SmartComponents/TenderResponses/PagePopup/Summary";
import { deleteComparison } from "@Store/Actions/Customers/comparisons";
import { markFavourite } from "@Store/Actions/Customers/responses";
import { addNotification } from "@Store/Actions/Notifications";
import { fetchDevice } from "@Store/Actions/Hardware/devices";
import styles from "./index.module.scss";
class ComparisonPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            contractingId: undefined,
            leasing: false,
            vat: false,
            linkCopied: false,
        };
    }
    toggleLeasing = () => {
        this.setState({
            leasing: !this.state.leasing,
        });
    };
    toggleVat = () => {
        this.setState({
            vat: !this.state.vat,
        });
    };
    close = () => {
        window.close();
    };
    openPopup = () => {
        this.setState({
            popup: true,
        });
    };
    closePopup = () => {
        this.setState({
            popup: false,
            linkCopied: false,
        });
    };
    openContractingPopup = (responseId) => {
        this.setState({
            contractingId: responseId,
        });
    };
    closeContractingPopup = () => {
        this.setState({
            contractingId: undefined,
        });
    };
    beginContracting = (responseId) => {
        if (!this.props.shared) {
            return this.props.createAwardedContract(this.props.tender.customerId, this.props.tender.id, responseId);
        }
    };
    markFavourite = (responseId) => {
        this.props.markFavourite(this.props.tender.customerId, this.props.tender.id, responseId);
    };
    componentDidMount() {
        this.fetchDevices();
    }
    componentDidUpdate(previousProps) {
        if (previousProps.responses.length !== this.props.responses.length) {
            this.fetchDevices();
        }
    }
    fetchDevices = () => {
        const deviceIds = this.props.responses.reduce((set, response) => {
            const { leasingData = [] } = response.proposal;
            for (const { latestId } of leasingData) {
                set.add(latestId);
            }
            const { devicePrices = [] } = response.proposal;
            for (const { latestId } of devicePrices) {
                set.add(latestId);
            }
            return set;
        }, new Set());
        for (const id of deviceIds) {
            this.props.fetchDevice(id);
        }
    };
    render() {
        const popup = (close) => {
            const responses = this.props.responses.reduce((text, response, index) => {
                //response1=[responseId]response2=[responseId] etc
                return `${text}response${index + 1}=${response.id}`;
            }, "");
            const currentURL = window.location.href;
            const currentPathname = window.location.pathname;
            const baseURL = currentURL.replace(currentPathname, "");
            const url = `${baseURL}/comparison/${this.props.tender.customerId}/${this.props.tender.id}/${responses}`;
            const copyLink = () => {
                navigator.clipboard.writeText(url);
                this.setState({ linkCopied: true });
            };
            return (React.createElement("div", { className: styles.popup },
                React.createElement("h1", { className: styles.popupTitle }, "Send to others in your organisation"),
                React.createElement("div", { className: styles.linkWrapper },
                    React.createElement(FormInput, { disabled: true, value: url, className: styles.link }),
                    React.createElement(Button, { dark: true, click: copyLink }, this.state.linkCopied ? "COPIED" : "COPY LINK")),
                React.createElement("div", { className: styles.buttonWrapper },
                    React.createElement("div", { className: styles.filler }),
                    React.createElement(Button, { click: close }, "DONE"))));
        };
        const { shared } = this.props;
        const viewResponses = this.props.responses
            .sort((a, b) => b.scores.pmScore - a.scores.pmScore)
            .map((response) => (React.createElement("div", { className: styles.summaryWrapper, key: response.id },
            React.createElement(Summary, { response: response, key: response.id, comparison: true, shared: shared, tender: this.props.tender, devices: this.props.devices, openPopup: this.openContractingPopup, leasing: this.state.leasing, vat: this.state.vat, adminUser: this.props.adminUser, markFavourite: this.markFavourite, notify: this.props.notify }),
            !shared && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.circle }),
                React.createElement(Exit, { dark: true, size: "1.5em", className: styles.exit, onClick: () => this.props.deleteComparison(response.id) }))))));
        for (let index = viewResponses.length; index < 3; index++) {
            viewResponses.push(React.createElement("div", { className: styles.filler, key: index },
                React.createElement("div", { className: styles.fillerText }, "Add another side-by-side by returning to the previous screen")));
        }
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.left },
                React.createElement("div", { className: styles.content },
                    React.createElement(OldContractDetails, { tender: this.props.tender, vat: this.state.vat }))),
            React.createElement("div", { className: styles.right },
                React.createElement(SubHeader, { flex: true, className: styles.subHeader },
                    React.createElement("div", { className: styles.title }, "Compare Responses"),
                    React.createElement("div", { className: styles.buttons },
                        React.createElement("div", { className: styles.leaseToggle },
                            React.createElement("span", { className: styles.leaseText }, "With Vat"),
                            " ",
                            React.createElement(TwoWayToggleSmall, { value: this.state.vat, toggle: this.toggleVat })),
                        this.props.tender.details.likeHardwareFunded ===
                            LikeHardwareFunded.Leasing && (React.createElement("div", { className: styles.leaseToggle },
                            React.createElement("span", { className: styles.leaseText }, "With Devices"),
                            " ",
                            React.createElement(TwoWayToggleSmall, { value: this.state.leasing, toggle: this.toggleLeasing }))),
                        !this.props.shared && (React.createElement(Button, { click: this.openPopup }, "SHARE")),
                        !this.props.isSharedLink && (React.createElement(Button, { dark: true, click: this.close },
                            React.createElement(Close, { size: "0.75em" }),
                            " CLOSE")))),
                React.createElement("div", { className: styles.responses }, viewResponses),
                !shared && (React.createElement(React.Fragment, null,
                    React.createElement(InfoPopup, { trigger: this.state.popup, content: popup, onClose: this.closePopup }),
                    React.createElement(BeginContracting, { response: this.props.responses.find(({ id }) => id === this.state.contractingId), tender: this.props.tender, onClose: this.closeContractingPopup, notify: this.props.notify, beginContracting: this.beginContracting, userType: this.props.user.type }))))));
    }
}
const mapStateToProps = ({ hardware: { devices }, auth: { user } }) => {
    return {
        user,
        devices,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        deleteComparison: (responseId) => deleteComparison(dispatch, responseId),
        notify: (data) => addNotification(dispatch, data),
        markFavourite: (customerId, tenderId, responseId) => markFavourite(dispatch, customerId, tenderId, responseId),
        fetchDevice: (deviceId) => fetchDevice(dispatch, deviceId, true),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparisonPage);
