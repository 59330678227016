import React from "react";
export default class AppSwitch extends React.Component {
    componentDidMount() {
        if (window.location === parent.location) {
            window.location.assign(`${this.props.uri}`);
        }
        else {
            console.log("sending msg");
            try {
                parent.postMessage(`${this.props.uri}`, "*");
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    render() {
        return React.createElement(React.Fragment, null);
    }
}
