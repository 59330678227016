import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const oobPresenceConstraints = (hasUsage, covered) => Object.keys(AllowanceTypes).reduce((constraints, key) => {
    if (hasUsage) {
        const { used } = covered[key];
        if (used === 0) {
            return constraints;
        }
    }
    constraints[key] = {
        presence: true,
    };
    return constraints;
}, {});
export const oobValidConstraints = (hasUsage, covered) => Object.keys(AllowanceTypes).reduce((constraints, key) => {
    if (hasUsage) {
        const { used } = covered[key];
        if (used === 0) {
            return constraints;
        }
    }
    constraints[key] = {
        presence: true,
        type: "number",
    };
    return constraints;
}, {});
