// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip--FzbYZ{margin-top:var(--standard-vertical-padding);background:var(--tag-background-color);border:1px solid var(--border-color);border-radius:2px}.tooltip--FzbYZ .exit--G_ijL{float:right}.tooltip--FzbYZ .text--hfYkE{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);color:var(--text-color);font-size:.85rem}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/Tooltip/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,2CAA2C,CAAC,sCAAsC,CAAC,oCAAoC,CAAC,iBAAiB,CAAC,6BAAe,WAAW,CAAC,6BAAe,2EAA2E,CAAC,uBAAuB,CAAC,gBAAgB","sourcesContent":[".tooltip{margin-top:var(--standard-vertical-padding);background:var(--tag-background-color);border:1px solid var(--border-color);border-radius:2px}.tooltip .exit{float:right}.tooltip .text{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);color:var(--text-color);font-size:.85rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip--FzbYZ",
	"exit": "exit--G_ijL",
	"text": "text--hfYkE"
};
export default ___CSS_LOADER_EXPORT___;
