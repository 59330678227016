// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterMenu--sMgrY{margin-right:2em;color:var(--inverted-text-color);font-size:.85rem;background-color:var(--card-color);-webkit-box-shadow:0 0 2.4em rgba(93,94,94,.1);box-shadow:0 0 2.4em rgba(93,94,94,.1)}.filterMenu--sMgrY .filterTitle--sG0gN{padding:calc(var(--standard-vertical-padding) * 0.5) calc(var(--standard-horizontal-padding) * 1);font-weight:bold;text-align:center;background-color:var(--header-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/FilterMenu/index.module.scss"],"names":[],"mappings":"AAAA,mBAAY,gBAAgB,CAAC,gCAAgC,CAAC,gBAAgB,CAAC,kCAAkC,CAAC,8CAA8C,CAAC,sCAAsC,CAAC,uCAAyB,iGAAiG,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,oCAAoC","sourcesContent":[".filterMenu{margin-right:2em;color:var(--inverted-text-color);font-size:.85rem;background-color:var(--card-color);-webkit-box-shadow:0 0 2.4em rgba(93,94,94,.1);box-shadow:0 0 2.4em rgba(93,94,94,.1)}.filterMenu .filterTitle{padding:calc(var(--standard-vertical-padding) * 0.5) calc(var(--standard-horizontal-padding) * 1);font-weight:bold;text-align:center;background-color:var(--header-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenu": "filterMenu--sMgrY",
	"filterTitle": "filterTitle--sG0gN"
};
export default ___CSS_LOADER_EXPORT___;
