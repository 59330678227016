import debounce from "@Shared/Helpers/Util/Debounce";
import tenderReducer from "@Store/Reducers/Customers/tenders";
import { put } from "@Api/Customers/tenders";
import { ChatWho } from "@Components/ChatBot";
import sleep from "@Helpers/Sleep";
import Factory from "@Shared/Factory";
import { ADD_FORM_DATA, ADD_TENDER, ADD_TENDER_CHAT, } from "@Store/Actions/Customers/tenders";
import { store } from "./../../";
const updateTender = (tender) => put(tender);
class TenderStack {
    id;
    stack = [];
    dispatch;
    constructor(id, dispatch) {
        this.id = id;
        this.dispatch = dispatch;
    }
    addToStack(action) {
        this.stack.push(action);
        if (this.stack.length === 1) {
            this.save();
        }
    }
    save = debounce(async (updatedTender) => {
        const state = store.getState();
        const ids = this.stack.map((a) => a.$uuid);
        const tender = updatedTender ||
            state.customers.tenders.find((data) => data.id === this.id);
        await this._save(tender, 5, ids);
    }, 2500);
    async _save(tender, tries = 5, ids = []) {
        try {
            const updatedTender = await updateTender(tender);
            this.stack = this.stack.filter((a) => !ids.includes(a.$uuid));
            if (this.stack.length > 0) {
                this.replayStack(updatedTender);
            }
            else {
                this.dispatch({
                    type: ADD_TENDER,
                    data: updatedTender,
                });
            }
        }
        catch (error) {
            if (tries < 1) {
                throw error;
            }
            if (error.response.status === 409 ||
                error.response.status === 425) {
                await sleep(250);
                await this.replayStack(error.response.data.state, tries);
            }
        }
    }
    replayStack(serverState, tries = 6) {
        let responses = [Factory.Tender(serverState)];
        const stack = [...this.stack];
        const ids = [];
        for (const action of stack) {
            responses = tenderReducer(responses, action);
            ids.push(action.$uuid);
        }
        this._save(responses[0], tries, ids);
    }
}
const stacks = {};
export default (store) => (next) => (action) => {
    const state = next(action); // Store is updated
    const { type, data } = action;
    if (((type === ADD_TENDER_CHAT && data.who === ChatWho.Customer) ||
        type === ADD_FORM_DATA) &&
        data.effects !== undefined) {
        if (!stacks[data.id]) {
            stacks[data.id] = new TenderStack(data.id, store.dispatch);
        }
        stacks[data.id].addToStack(action);
    }
    return state;
};
