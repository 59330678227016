export const ROOT = "/broker";
export const CUSTOMERS = ROOT + "/customers";
export const CREATE_CUSTOMER = CUSTOMERS + "/create";
export const CUSTOMER = CUSTOMERS + "/:customerId";
export const CUSTOMER_TENDERS = CUSTOMER + "/tender";
export const CUSTOMER_TENDER = CUSTOMER_TENDERS + "/:tenderId";
export const EDIT_CUSTOMER_TENDER = CUSTOMER_TENDER + "/edit";
export const CREATE_CUSTOMER_TENDER = CUSTOMER_TENDERS + "/create";
export const CREATE_TENDER_WITH_USAGE = CREATE_CUSTOMER_TENDER + "/usage/:usageId";
export const CUSTOMER_TENDER_RESPONSES = CUSTOMER_TENDER + "/responses";
export const CUSTOMER_TENDER_COMPARISON = CUSTOMER_TENDER_RESPONSES + "/comparison";
export const CUSTOMER_USERS = CUSTOMER + "/users";
export const CREATE_CUSTOMER_USER = CUSTOMER_USERS + "/create";
export const CUSTOMER_USER = CUSTOMER_USERS + "/:userId";
export const EDIT_CUSTOMER_USER = CUSTOMER_USER + "/edit";
export const CUSTOMER_USAGES = CUSTOMER + "/usage";
export const CREATE_CUSTOMER_USAGE = CUSTOMER_USAGES + "/create";
export const CUSTOMER_USAGE = CUSTOMER_USAGES + "/:usageId";
export const SUPPLIERS = ROOT + "/suppliers";
export const CREATE_SUPPLIER = SUPPLIERS + "/create";
export const SUPPLIER = SUPPLIERS + "/:supplierId";
export const SUPPLIER_RESPONSES = SUPPLIER + "/responses";
export const SUPPLIER_RESPONSE = SUPPLIER_RESPONSES + "/:responseId";
export const EDIT_SUPPLIER_RESPONSE = SUPPLIER_RESPONSE + "/edit";
export const SUPPLIER_USERS = SUPPLIER + "/users";
export const CREATE_SUPPLIER_USER = SUPPLIER_USERS + "/create";
export const SUPPLIER_USER = SUPPLIER_USERS + "/:userId";
export const EDIT_SUPPLIER_USER = SUPPLIER_USER + "/edit";
export const AWARDED_CONTRACTS = ROOT + "/awarded-contracts";
export const DEVICES = ROOT + "/devices";
export const DEVICE = DEVICES + "/:deviceId";
export const EDIT_DEVICE = DEVICE + "/edit";
export const CREATE_DEVICE = DEVICES + "/create";
export const UPLOAD_DEVICE_LIST = DEVICES + "/upload";
export const CONTRACTS = ROOT + "/contracts";
export const CONTRACT = CONTRACTS + "/:contractId";
export const CONTRACT_NOTE = CONTRACT + "/note";
export const MY_ORGANISATION = ROOT + "/my-organisation";
export const USERS = MY_ORGANISATION + "/users";
export const USER = USERS + "/:userId";
export const EDIT_USER = USER + "/edit";
export const CREATE_USER = USERS + "/:partnerId/create";
export const MY_ACCOUNT = ROOT + "/my-account";
export const EDIT_MY_ACCOUNT = MY_ACCOUNT + "/edit";
export const LOGOUT = ROOT + "/logout";
export const NOT_FOUND = ROOT + "/*";
