import React from "react";
import { ChatWho } from "@Components/ChatBot";
import Message from "@Components/ChatBot/Message";
import Avatar from "@Components/ChatBot/MessageGroup/Avatars";
import styles from "./index.module.scss";
export default class MessageGroup extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.group },
            this.props.who === ChatWho.PredictyBot && (React.createElement(Avatar, { who: ChatWho.PredictyBot, typing: this.props.typing })),
            React.createElement("div", { className: styles.messages }, this.props.messages.map((input) => {
                return React.createElement(Message, { key: input.uid, ...input });
            })),
            this.props.who === ChatWho.Customer && (React.createElement(Avatar, { who: ChatWho.Customer, avatar: this.props.avatar }))));
    }
}
