import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put } from "@Api/Admin/users";
export const DELETE_ADMIN_USER = "deleteAdminUser";
export const deleteUser = (dispatch, data) => del(data)
    .then((user) => {
    dispatch({
        type: DELETE_ADMIN_USER,
        data: user.id,
    });
})
    .then(notify200(dispatch, API_TYPE.DELETE, "Admin Deleted", "The admin(s) were deleted successfully "))
    .catch(notify4xx(dispatch));
export const ADD_ADMIN_USER = "updateAdminUser";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_ADMIN_USER,
        data,
    });
};
export const createUser = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Admin Created", "A new admin has been created"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateUser = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Admin Updated", "The admin was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchUser = (dispatch, userId) => find(userId).then(add(dispatch));
export const fetchUsers = (dispatch) => get(add(dispatch));
