import HoverTooltip from "@Components/HoverTooltip";
import React from "react";
export const displayAllowanceTooltip = (name) => {
    const contains = (test) => {
        return new RegExp(test, "i").test(name);
    };
    if (contains("Free")) {
        return (React.createElement(HoverTooltip, null, "Free - includes 0800/0808 numbers, 101, 105, 111, 112, 116, 999"));
    }
    if (contains("Std.")) {
        return (React.createElement(HoverTooltip, null, "Standard - 01, 02, 03, 0345, 030, 07, 070 numbers"));
    }
    if (contains("Prem.")) {
        return React.createElement(HoverTooltip, null, "Premium - 09 numbers");
    }
    if (contains("Biz.")) {
        return (React.createElement(HoverTooltip, null, "Business - includes 0843, 0844, 0845, 0870, 0871, 0872, 0873 numbers"));
    }
    if (contains("Dir.")) {
        return React.createElement(HoverTooltip, null, "Directory Enquiries - 118 numbers");
    }
};
