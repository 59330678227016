// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--jrv7J{width:100%;border:1px solid var(--border-color) !important;border-radius:4px}.input--jrv7J.noBorder--IeHc_{border-width:0 !important}.input--jrv7J:hover{border-color:var(--primary-button-color) !important}.input--jrv7J.error--aAKB1{color:var(--error-color) !important;border-color:var(--error-color) !important}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Calendar/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,UAAU,CAAC,+CAA+C,CAAC,iBAAiB,CAAC,8BAAgB,yBAAyB,CAAC,oBAAa,mDAAmD,CAAC,2BAAa,mCAAmC,CAAC,0CAA0C","sourcesContent":[".input{width:100%;border:1px solid var(--border-color) !important;border-radius:4px}.input.noBorder{border-width:0 !important}.input:hover{border-color:var(--primary-button-color) !important}.input.error{color:var(--error-color) !important;border-color:var(--error-color) !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--jrv7J",
	"noBorder": "noBorder--IeHc_",
	"error": "error--aAKB1"
};
export default ___CSS_LOADER_EXPORT___;
