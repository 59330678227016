import React from "react";
import Button from "@Components/Button";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import { Auto as SensabillAuto } from "./AutoSensabill";
import styles from "./index.module.scss";
import { Manual as SensabillManual } from "./ManualSensabill";
import HowToGetBills from "./ManualSensabill/HowToGetBills";
import titleStyles from "./title.module.scss";
export default class UsageUploadSensabill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            manualUpload: false,
        };
    }
    render() {
        if (this.props.showOnlyOne) {
            const switchToManual = () => {
                this.setState({
                    manualUpload: true,
                });
            };
            const switchToAuto = () => {
                this.setState({
                    manualUpload: false,
                });
            };
            if (this.state.manualUpload) {
                return (React.createElement(SensabillManual, { submit: this.props.submitFiles, bottomSlot: React.createElement("div", { className: styles.manualBottomSlot },
                        React.createElement(HowToGetBills, null),
                        React.createElement(Button, { click: switchToAuto }, "SWITCH TO AUTO UPLOAD")) }));
            }
            return (React.createElement(SensabillAuto, { submit: this.props.submitPlatform, usage: this.props.usage, switchToManual: switchToManual, admin: this.props.admin }));
        }
        return (React.createElement("div", { className: styles.page, key: this.props.key },
            React.createElement(ColouredBorderBox, { none: true, title: this.props.title ? this.props.title(titleStyles) : React.createElement(React.Fragment, null) },
                React.createElement("div", { className: styles.wrapper },
                    React.createElement("div", { className: styles.optionAuto },
                        React.createElement(SensabillAuto, { submit: this.props.submitPlatform, title: "Automatically retrieve Bill Reports", usage: this.props.usage, admin: this.props.admin })),
                    this.props.beside !== true && (React.createElement("div", { className: styles.lineSeparator },
                        React.createElement("div", { className: `${styles.line}` },
                            React.createElement("div", { className: styles.orCircle },
                                React.createElement("div", { className: styles.text }, "OR"))))),
                    React.createElement("div", { className: styles.optionManual },
                        React.createElement(SensabillManual, { submit: this.props.submitFiles })))),
            React.createElement(HowToGetBills, null)));
    }
}
