import SVG from "@Components/Icons/SVG";
export default class AwardedContract extends SVG {
    path = [
        "M 68 18 V 4 C 68 2 66 0 64 0 H 4 C 2 0 0 2 0 4 V 86 C 0 88 2 90 4 90 H 10 V 86 H 6 C 5 86 4 85 4 84 V 6 C 4 5 5 4 6 4 H 62 C 63 4 64 5 64 6 V 14 L 68 18",
        "M 78 28 L 60 10",
        "M 78 28 V 96 C 78 98 76 100 74 100 H 14 C 12 100 10 98 10 96 V 14 C 10 12 12 10 14 10 H 60",
        "M 78 28 H 64 C 62 28 60 26 60 24 V 10",
        "M 40 32 H 23 V 36 H 40 Z",
        "M 23 48 H 65 V 44 H 23 Z",
        "M 23 55 H 65 V 59 H 23 Z",
        "M 23 67 H 65 V 71 H 23 Z",
        "M 24 86 C 23 85 26 79 29 80 C 30 81 28 86 29 86 C 30 86 34 83 35 83 C 36 83 36 85 38 86 C 38 87 40 84 44 85 V 86 C 40 85 38 88 38 87 C 36 86 36 84 35 84 C 34 84 29 87 28 87 C 27 87 29 82 28 81 C 26 80 24 85 25 86 Z",
        "M 15 14 H 56 V 24 C 56 27 59 32 62 32 H 74 V 94 C 74 95 73 96 72 96 H 16 C 15 96 14 95 14 94 V 15 C 14 14 14 14 15 14",
        "M 60 14 L 74 28 H 78 L 60 10 Z",
        "M 65 80 C 68 80 70 82 70 85 C 70 88 68 90 65 90 C 62 90 60 88 60 85 C 60 82 62 80 65 80",
        "M 61 85 L 64 88 L 69 83 L 67 81 L 64 85 L 62 83 Z",
        "M 65 77 C 70 77 73 81 73 85 C 73 90 69 93 65 93 C 60 93 57 89 57 85 C 57 80 61 77 65 77",
    ];
    evenOdd = true;
}
