import React from "react";
import Reload from "@Components/Icons/Reload";
import FilterMenu from "./../FilterMenu";
import TableNav from "./../TableNav";
import TBody from "./body";
import THead from "./head";
import styles from "./index.module.scss";
export default class Display extends React.Component {
    static defaultProps = {
        fields: [],
        data: [],
        filterFields: [],
        selected: [],
        dataSize: 0,
        page: 1,
        rowsShown: 25,
        link: null,
        alternate: false,
        totalCost: false,
    };
    toggleSelectRow = ({ target }) => {
        const selected = new Set(this.props.selected);
        if (target.checked) {
            selected.add(target.value);
        }
        else {
            selected.delete(target.value);
        }
        this.props.updateSelected([...selected]);
    };
    toggleSelectAll = ({ target }) => {
        if (target.checked) {
            const selected = this.props.data.reduce((selected, datum) => {
                selected.add("" + datum.tableRowKey);
                return selected;
            }, new Set(this.props.selected));
            this.props.updateSelected([...selected]);
        }
        else {
            this.props.updateSelected([]);
        }
    };
    arrowColour = (field) => {
        if (this.props.sortBy === field ||
            this.props.sortBy === "-".concat(field)) {
            return "light";
        }
    };
    reload = () => {
        if (!this.props.fetching && this.props.reload) {
            this.props.reload();
        }
    };
    render() {
        const { fields, filterFields, dataSize, page, rowsShown, showFilters, activeRangeFilters, } = this.props;
        const head = (React.createElement(THead, { ...this.props, toggleSelectAll: this.toggleSelectAll }));
        const body = (React.createElement(TBody, { ...this.props, toggleSelectRow: this.toggleSelectRow }));
        const searchable = fields.some(({ searchable }) => searchable);
        const noMargin = this.props.noMargin ? styles.noMargin : "";
        return (React.createElement("div", { className: `${styles.tableContainer} ${noMargin} ${this.props.className}` },
            (searchable || this.props.fetching || this.props.reload) && (React.createElement("div", { className: styles.overHead },
                searchable ? (React.createElement("input", { type: "text", placeholder: "Search...", onInput: this.props.search, className: `${styles.search} ${styles.shown}` })) : (React.createElement("div", { className: styles.fill })),
                (this.props.fetching || this.props.reload) && (React.createElement("div", { className: styles.loadingIcon, onClick: this.reload },
                    React.createElement(Reload, { size: "1x", spin: this.props.fetching }))))),
            React.createElement("div", { className: styles.mainBody },
                showFilters && (React.createElement(FilterMenu, { availableFilters: filterFields, rangeFilters: activeRangeFilters, updateFilter: this.props.updateFilter, updateArray: this.props.updateArray, updateRange: this.props.updateRange, large: this.props.largeFilter })),
                React.createElement("div", { className: styles.tableWrapper },
                    React.createElement("table", { className: styles.table },
                        head,
                        body),
                    !this.props.noNav && (React.createElement(TableNav, { size: dataSize, changePage: this.props.changePage, changeRows: this.props.changeRows, page: page, rows: rowsShown }))))));
    }
}
