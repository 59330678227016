import React from "react";
import HoverTooltip from "@Components/HoverTooltip";
import Logo from "@Components/Icons/Logos/LogoText";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default class OldContractDetails extends React.Component {
    render() {
        const { tender, vat = false } = this.props;
        const { stats } = tender;
        const vatRate = vat ? 1.2 : 1;
        const tariffCosts = stats.tariffCosts * vatRate;
        const oobCosts = stats.oobCosts * vatRate;
        const currentCost = tender.currentCost * vatRate;
        const discounts = tender.discounts;
        const netCost = currentCost - discounts;
        const currentCostPerLine = netCost /
            (tender.stats?.totalLines || tender.details.totalLines || 1);
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.header },
                React.createElement(Logo, { className: styles.logo })),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.benchmarkTitle },
                    "What You",
                    " ",
                    React.createElement("span", { className: styles.highlighted }, "Currently"),
                    " ",
                    "Pay Monthly"),
                React.createElement("div", { className: styles.benchmarkWrapper },
                    React.createElement("div", { className: styles.benchmark },
                        tender.stats?.totalLines && (React.createElement("div", { className: `${styles.item}` },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text }, "Total Number of Lines:")),
                            React.createElement("div", { className: styles.highlighted }, tender.stats.totalLines))),
                        React.createElement("div", { className: `${styles.item}` },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text }, "Fixed Tariff Cost:")),
                            React.createElement("div", { className: styles.highlighted }, format(tariffCosts, 0))),
                        React.createElement("div", { className: `${styles.item}` },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text },
                                    "Variable Cost:",
                                    React.createElement(HoverTooltip, null, "Charges outside your tariff allowance"))),
                            React.createElement("div", { className: styles.highlighted }, format(oobCosts, 0))),
                        React.createElement("div", { className: `${styles.item}` },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text },
                                    "Total Current Cost:",
                                    React.createElement(HoverTooltip, null, "Total of fixed contract cost & charges outside your tariff allowance"))),
                            React.createElement("div", { className: styles.highlighted }, format(currentCost, 0))),
                        React.createElement("div", { className: `${styles.item}`, hidden: discounts <= 0 },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text }, "Discounts & Benefits:")),
                            React.createElement("div", { className: styles.highlighted },
                                "-",
                                format(discounts, 0))),
                        React.createElement("div", { className: `${styles.item}`, hidden: discounts <= 0 },
                            React.createElement("div", { className: styles.title },
                                React.createElement("div", { className: styles.text },
                                    "Net Current Cost:",
                                    React.createElement(HoverTooltip, null, "Total of fixed contract tariff & charges outside your tariff allowance, minus discounts & benefits."))),
                            React.createElement("div", { className: styles.highlighted }, format(netCost, 0)))),
                    React.createElement("div", { className: `${styles.highlightedRow}` },
                        React.createElement("div", { className: `${styles.left} ${styles.highlightedValue}` },
                            React.createElement("div", { className: styles.text },
                                "Per Line Per Month Cost",
                                React.createElement(HoverTooltip, { light: true }, "This is the most important number to compare supplier offers on a direct apples-for-apples comparison. It includes all costs and benefits, some visible, some hidden. This is the figure per line per month for your current\u00A0contract."))),
                        React.createElement("div", { className: `${styles.right} ${styles.rightValue} ${styles.highlightedValue}` }, format(currentCostPerLine, 2)))))));
    }
}
