import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import companyMigrate from "@Shared/Entities/AbstractEntities/Company/migrate";
import customerMigrate from "@Shared/Entities/AbstractEntities/Customer/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    let { partnerId } = current;
    if (current.brokerId) {
        partnerId = current.brokerId;
    }
    return { ...current, partnerId, version: 2 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const companyMigrated = companyMigrate(original);
    const customerMigrated = customerMigrate(original);
    const converted = [version1, version2].reduce((converted, f) => f(converted), original);
    const { version, partnerId } = converted;
    return {
        ...baseMigrated,
        ...companyMigrated,
        ...customerMigrated,
        version,
        partnerId,
    };
};
