import React from "react";
import { connect } from "react-redux";
import ButtonLink from "@Components/ButtonLink";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import buttons from "@Components/Sass/buttons.module.scss";
import SubHeader from "@Components/SubHeader";
import { ERROR } from "@Components/Toast";
import GetPath from "@Helpers/GetPath";
import { fetchAwardedContractsForPartner } from "@Store/Actions/AwardedContract";
import { fetchResponsesForPartner } from "@Store/Actions/Customers/responses";
import { fetchDevices } from "@Store/Actions/Hardware/devices";
import { addNotification } from "@Store/Actions/Notifications";
import { fetchTendersForPartners } from "@Store/Actions/Partners/tenders";
import { CREATE_CUSTOMER } from "@Shared/Paths/Partner";
import TenderCard from "../../../Customer/Pages/Tenders/TenderCard";
import styles from "./index.module.scss";
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leasing: false,
            vat: false,
            fetching: true,
            supplierData: {},
            supplierErrors: {},
            showPopup: false,
            popupId: undefined,
        };
    }
    reload = () => {
        this.props
            .fetch(this.props.user.partnerId)
            .catch(() => {
            this.props.notify({
                title: "Oops",
                description: "Please contact support",
                status: ERROR,
            });
        })
            .then(() => Promise.all(this.props.tenders.map(({ id }) => this.props.fetchResponses(this.props.user.partnerId, id))))
            .finally(() => {
            this.setState({ fetching: false });
        });
    };
    componentDidMount() {
        Promise.all([this.props.fetchAwardedContracts()]).then(() => this.reload());
    }
    render() {
        if (this.state.fetching === true) {
            return React.createElement(LoadingFallback, null);
        }
        const tenders = this.props.tenders.map((tender) => {
            const responses = this.props.responses.filter(({ tenderId, submittedAt }) => tenderId === tender.id && submittedAt !== undefined).length;
            const awardedContract = this.props.awardedContracts.find(({ tenderId }) => tenderId === tender.id);
            return (React.createElement(React.Fragment, null,
                React.createElement(TenderCard, { key: tender.id, tender: tender, responses: responses, awardedContract: awardedContract, partner: true })));
        });
        return (React.createElement(React.Fragment, null,
            React.createElement(SubHeader, { marginIncrease: true },
                React.createElement("div", { className: styles.mainTitle }, "Tenders")),
            React.createElement("div", null, tenders !== undefined && tenders.length > 0 ? (tenders) : (React.createElement("div", { className: styles.tenderWrapper },
                React.createElement("div", { className: styles.addTender },
                    React.createElement("div", { className: styles.content },
                        React.createElement("div", { className: styles.createText }, "Create new customer"),
                        React.createElement("div", { className: buttons.buttonGroupCenter },
                            React.createElement(ButtonLink, { to: GetPath(CREATE_CUSTOMER), className: buttons.buttons }, "+")))))))));
    }
}
const mapStateToProps = ({ customers: { tenders, usage }, auth: { user }, suppliers: { responses }, hardware: { devices = [] }, awardedContracts, }) => ({
    tenders: tenders.filter((t) => t.partnerId === user.data.partnerId),
    usage: usage.filter((u) => u.customerId === user.data.customerId),
    awardedContracts,
    user,
    responses,
    devices,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetch: (partnerId) => Promise.all([
            fetchTendersForPartners(dispatch, partnerId),
            // fetchUsagesDetails(dispatch, partnerId),
            fetchDevices(dispatch),
        ]),
        fetchResponses: (partnerId, tenderId) => fetchResponsesForPartner(dispatch, partnerId, tenderId, true),
        fetchAwardedContracts: (partnerId) => fetchAwardedContractsForPartner(dispatch, partnerId),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
