import dayjs from "dayjs";
import { ChatWho } from "@Components/ChatBot";
import { ATCUnits, DetailsFields, ManagerPackageTypes, } from "@Shared/Entities/Customer/Tender/Details/enums";
import Factory from "@Shared/Factory";
import { DELETE_CUSTOMER } from "@Store/Actions/Customers/companies";
import { ADD_FORM_DATA, ADD_TENDER, ADD_TENDER_CHAT, DELETE_TENDER, EDIT_ANSWER, } from "@Store/Actions/Customers/tenders";
import { ADD_USAGE } from "@Store/Actions/Customers/usage";
export const updateTender = (current, data) => {
    const details = { ...current.details.data };
    let { value, effects } = data;
    let invalid = false;
    if (data.validate) {
        try {
            value = data.validate(value);
        }
        catch {
            invalid = true;
        }
    }
    switch (effects) {
        case DetailsFields.ATCRaw:
            details[DetailsFields.ATCUnits] = value.type;
            effects = DetailsFields.ATC;
            value = value.input;
        // break omitted
        case DetailsFields.VoiceLines:
        case DetailsFields.VoiceOnlyLines:
        case DetailsFields.Length:
        case DetailsFields.DataLines:
        case DetailsFields.OverallData:
        case DetailsFields.ManualCurrentCost:
        case DetailsFields.EstimatedLines:
        case DetailsFields.EstimatedDataLines:
        case DetailsFields.CurrentSpend:
        case DetailsFields.CostSpread:
        case DetailsFields.OsSpread:
        case DetailsFields.LinesBeginning:
        case DetailsFields.NumberHandsets:
        case DetailsFields.LinesWithinThreeMonths:
            if (value === undefined) {
                break;
            }
            value = Number(value);
            if (Number.isNaN(value)) {
                invalid = true;
            }
            break;
        case DetailsFields.ATC:
            if (value === undefined) {
                break;
            }
            if (typeof value === "object") {
                const { input, type } = value;
                details[DetailsFields.ATCUnits] = type;
                value = input;
            }
            else {
                value = Number(value);
            }
            if (Number.isNaN(value)) {
                invalid = true;
            }
            break;
        case DetailsFields.LinesEnd:
            break;
        case DetailsFields.ExtraDetails:
            if (value === false) {
                value = "";
            }
            break;
        case DetailsFields.Postcodes:
            value.splice(30);
            break;
        case DetailsFields.PickedHandsets:
            value = value.map((h) => {
                h[DetailsFields.NumberHandsets] = h[DetailsFields.NumberHandsets]
                    ? Number(h[DetailsFields.NumberHandsets])
                    : undefined;
                return h;
            });
    }
    if (invalid !== true) {
        details[effects] = value;
        switch (effects) {
            case DetailsFields.ExtraDetails:
                details[DetailsFields.CharitySocialEnterprise] =
                    details[DetailsFields.CharitySocialEnterprise] === undefined
                        ? false
                        : details[DetailsFields.CharitySocialEnterprise];
            // break omitted
            case DetailsFields.CharitySocialEnterprise:
                details[DetailsFields.VATRegistered] =
                    details[DetailsFields.VATRegistered] === undefined
                        ? false
                        : details[DetailsFields.VATRegistered];
            // break omitted
            case DetailsFields.VATRegistered:
                details[DetailsFields.MDM] =
                    details[DetailsFields.MDM] === undefined
                        ? ManagerPackageTypes.None
                        : details[DetailsFields.MDM];
            // break omitted
            case DetailsFields.MDM:
                details[DetailsFields.MDMFee] =
                    details[DetailsFields.MDMFee] === undefined
                        ? false
                        : details[DetailsFields.MDMFee];
            // break omitted
            case DetailsFields.MDMFee:
            case DetailsFields.MDMEnd:
            case DetailsFields.MDMInLine:
                details[DetailsFields.MDMMSInTune] =
                    details[DetailsFields.MDMMSInTune] === undefined
                        ? false
                        : details[DetailsFields.MDMMSInTune];
            // break omitted
            case DetailsFields.MDMMSInTune:
                details[DetailsFields.OwnMDM] =
                    details[DetailsFields.OwnMDM] === undefined
                        ? false
                        : details[DetailsFields.OwnMDM];
            // break omitted
            case DetailsFields.OwnMDM:
            case DetailsFields.LeasingType:
            case DetailsFields.LikeHardwareFunded:
            case DetailsFields.DEP:
            case DetailsFields.NumberHandsets:
            case DetailsFields.PickedHandsets:
            case DetailsFields.FiveG:
            case DetailsFields.SimOnlyFunded:
            case DetailsFields.Handsets:
            case DetailsFields.Locked:
            case DetailsFields.ExistingDevices:
            case DetailsFields.Postcodes:
            case DetailsFields.ChosenNetwork:
            case DetailsFields.LeastNetwork:
                details[DetailsFields.MultipleNetwork] =
                    details[DetailsFields.MultipleNetwork] === undefined
                        ? false
                        : details[DetailsFields.MultipleNetwork];
            // break omitted
            case DetailsFields.MultipleNetwork:
            case DetailsFields.ChangeNetwork:
            case DetailsFields.CurrentNetwork:
            case DetailsFields.HighCostCountries:
                details[DetailsFields.HighCost] =
                    details[DetailsFields.HighCost] === undefined
                        ? false
                        : details[DetailsFields.HighCost];
            // break omitted
            case DetailsFields.HighCost:
            case DetailsFields.RoamingCostCountries:
                details[DetailsFields.RoamingCost] =
                    details[DetailsFields.RoamingCost] === undefined
                        ? false
                        : details[DetailsFields.RoamingCost];
            // break omitted
            case DetailsFields.RoamingCost:
            case DetailsFields.IDDCostCountries:
                details[DetailsFields.IDDCost] =
                    details[DetailsFields.IDDCost] === undefined
                        ? false
                        : details[DetailsFields.IDDCost];
            // break omitted
            case DetailsFields.IDDCost:
            case DetailsFields.OtherDataIncrease:
            case DetailsFields.DataAcquisition:
                details[DetailsFields.Zoom] =
                    details[DetailsFields.Zoom] === undefined
                        ? false
                        : details[DetailsFields.Zoom];
            // break omitted
            case DetailsFields.Zoom:
                details[DetailsFields.OnlineTeams] =
                    details[DetailsFields.OnlineTeams] === undefined
                        ? false
                        : details[DetailsFields.OnlineTeams];
            // break omitted
            case DetailsFields.OnlineTeams:
                details[DetailsFields.Microsoft365] =
                    details[DetailsFields.Microsoft365] === undefined
                        ? false
                        : details[DetailsFields.Microsoft365];
            // break omitted
            case DetailsFields.Microsoft365:
                details[DetailsFields.IncreaseUsage] =
                    details[DetailsFields.IncreaseUsage] === undefined
                        ? false
                        : details[DetailsFields.IncreaseUsage];
            // break omitted
            case DetailsFields.IncreaseUsage:
            case DetailsFields.OverallData:
            case DetailsFields.ManualCurrentCost:
            case DetailsFields.RealMonthlyCosts:
                details[DetailsFields.TechFund] =
                    details[DetailsFields.TechFund] === undefined
                        ? 0
                        : details[DetailsFields.TechFund];
            // break omitted
            case DetailsFields.TechFund:
            case DetailsFields.ATCUnits:
                details[DetailsFields.ATC] =
                    details[DetailsFields.ATC] === undefined
                        ? 0
                        : details[DetailsFields.ATC];
            // break omitted
            case DetailsFields.ATC:
                const atc = details[DetailsFields.ATC];
                if (atc >= 100) {
                    details[DetailsFields.ATCUnits] = ATCUnits.Pounds;
                }
                details[DetailsFields.EstimatedDataLines] =
                    details[DetailsFields.EstimatedDataLines] === undefined
                        ? 0
                        : details[DetailsFields.EstimatedDataLines];
            // break omitted
            case DetailsFields.EstimatedDataLines:
                details[DetailsFields.EstimatedLines] =
                    details[DetailsFields.EstimatedLines] === undefined
                        ? 0
                        : details[DetailsFields.EstimatedLines];
            // break omitted
            case DetailsFields.EstimatedLines:
                details[DetailsFields.LinesWithinSixMonths] =
                    details[DetailsFields.LinesWithinSixMonths] === undefined
                        ? false
                        : details[DetailsFields.LinesWithinSixMonths];
            // break omitted
            case DetailsFields.LinesWithinSixMonths:
            case DetailsFields.LinesWithinThreeMonths:
            case DetailsFields.LinesBeginning:
            case DetailsFields.VastMajorityLinesRenewal:
            case DetailsFields.Length:
                details[DetailsFields.VoiceOnlyLines] =
                    details[DetailsFields.VoiceOnlyLines] === undefined
                        ? 0
                        : details[DetailsFields.VoiceOnlyLines];
            // break omitted
            case DetailsFields.VoiceOnlyLines:
                details[DetailsFields.DataLines] =
                    details[DetailsFields.DataLines] === undefined
                        ? 0
                        : details[DetailsFields.DataLines];
            // break omitted
            case DetailsFields.DataLines:
            case DetailsFields.VoiceLines:
        }
    }
    return Factory.Tender({ ...current.data, details, updatedAt: dayjs.utc() });
};
export default (state = [], { type, data }) => {
    let current;
    switch (type) {
        case ADD_TENDER_CHAT:
        case ADD_FORM_DATA:
        case EDIT_ANSWER:
        case ADD_TENDER:
            current = state.find(({ id }) => id === data.id);
    }
    switch (type) {
        case DELETE_TENDER:
            return state.filter(({ id }) => id !== data);
        case ADD_TENDER:
            const tender = Factory.Tender(data);
            return [...state.filter(({ id }) => id !== tender.id), tender];
        case ADD_TENDER_CHAT:
            if (data.who !== ChatWho.Customer) {
                return state;
            }
        // break omitted
        case ADD_FORM_DATA:
            if (!current || data.effects === undefined) {
                return state;
            }
            const updated = updateTender(current, data);
            return [...state.filter(({ id }) => id !== current.id), updated];
        case ADD_USAGE:
            const usage = Factory.Usage(data);
            const { stats, breakdown } = usage;
            return state.map((tender) => {
                if ((tender.publishedAt === undefined ||
                    dayjs().isBefore(tender.publishedAt)) &&
                    tender.usageId === usage.id) {
                    return Factory.Tender({ ...tender.data, stats, breakdown });
                }
                return tender;
            });
        case DELETE_CUSTOMER:
            return state.filter(({ customerId }) => customerId !== data);
    }
    return state;
};
