// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".type--B6EvO{flex:1;padding-left:1.5em;background-color:rgba(0,0,0,0);border:none}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/TextInput/Single/index.module.scss"],"names":[],"mappings":"AAAA,aAAM,MAAM,CAAC,kBAAkB,CAAC,8BAA8B,CAAC,WAAW","sourcesContent":[".type{flex:1;padding-left:1.5em;background-color:rgba(0,0,0,0);border:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"type": "type--B6EvO"
};
export default ___CSS_LOADER_EXPORT___;
