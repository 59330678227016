import { LOGOUT, REMOVE_MFA, SET_MFA } from "@Store/Actions/Auth";
export default (state = "", { type, data }) => {
    switch (type) {
        case SET_MFA:
            localStorage.setItem("mfa", data);
            return data;
        case REMOVE_MFA:
            localStorage.removeItem("mfa");
            return "";
        case LOGOUT:
            return "";
    }
    return state;
};
