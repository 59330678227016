import loadable from "@loadable/component";
import React from "react";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
const LoadableComponent = loadable(() => import("./Router"), {
    fallback: React.createElement(LoadingFallback, null),
});
export default class Tenders extends React.Component {
    render() {
        return React.createElement(LoadableComponent, null);
    }
}
