import React from "react";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import HoverTooltip from "@Components/HoverTooltip";
import Pound from "@Components/Icons/Pound";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default function CurrentCosts(props) {
    const createListItem = (name, value) => {
        return (React.createElement("div", { className: styles.listItem },
            React.createElement("div", null, name),
            React.createElement("div", { className: styles.altHighlightColor }, format(value, 0))));
    };
    const { tender } = props;
    return (React.createElement(ColouredBorderBox, { icon: React.createElement(Pound, null), title: React.createElement(React.Fragment, null,
            "Your ",
            React.createElement("b", { className: styles.primaryColor }, " Current Costs")), primary: true, className: styles.card },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: `${styles.title} ${styles.centered}` }, "What you currently pay per month"),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.list },
                    createListItem("Fixed Tariff Cost:", tender.stats.tariffCosts),
                    createListItem(React.createElement(React.Fragment, null,
                        "Charges Outside your Allowance:",
                        React.createElement(HoverTooltip, null, "Additional charges incurred from exceeding your monthly allowance of minutes, texts or data")), tender.stats.oobCosts),
                    createListItem(React.createElement(React.Fragment, null,
                        "Total Current Cost:",
                        React.createElement(HoverTooltip, null, "Total of fixed contract cost & charges outside your allowance")), tender.currentCost),
                    createListItem("Discounts & Benefits:", tender.discounts),
                    createListItem(React.createElement(React.Fragment, null,
                        "Net Current Cost:",
                        React.createElement(HoverTooltip, null, "Total of fixed contract tariff & charges outside your allowance minus discounts & benefits.")), tender.netCost))))));
}
