import dayjs from "dayjs";
import { isValid } from "postcode";
import validate from "validate.js";
import { ValidationException } from "../Exceptions";
validate.validators.type.types.Dayjs = (value) => value instanceof dayjs && value.isValid();
const enumCheck = (value, E) => {
    let validValues = Object.keys(E)
        .filter((k) => typeof E[k] === "number")
        .map((k) => E[k]);
    if (validValues.length === 0) {
        validValues = Object.keys(E);
    }
    if (value && !validValues.includes(value)) {
        return `must be one of ${validValues
            .map((value) => E[value].replaceAll("_", ` `))
            .join(", ")}`;
    }
    return null;
};
validate.validators.enum = (value, E) => {
    return enumCheck(value, E);
};
validate.validators.enumArray = (value = [], E) => {
    if (value === undefined) {
        return null;
    }
    if (!Array.isArray(value)) {
        return "must be an array";
    }
    for (let index = 0; index < value.length; index++) {
        const member = value[index];
        if (enumCheck(member, E) !== null) {
            const validValues = Object.keys(E)
                .filter((k) => typeof E[k] === "number")
                .map((k) => E[k]);
            return `must be an array of ${validValues
                .map((value) => E[value].replaceAll("_", ` `))
                .join(", ")}`;
        }
    }
    return null;
};
validate.validators.notEmpty = (value) => {
    if (validate.isEmpty(value)) {
        return "cannot be empty";
    }
    return null;
};
validate.validators.lessThan = (value, options, key, attributes) => {
    if (value > attributes[options]) {
        return `must be less than ${options}`;
    }
    return null;
};
validate.validators.greaterThan = (value, options, key, attributes) => {
    if (value < attributes[options]) {
        return `must be greater than ${options}`;
    }
    return null;
};
validate.validators.entity = (value, E) => {
    if (!(value instanceof E)) {
        return `must be an instance of ${E.constructor.name}`;
    }
    return null;
};
validate.validators.checkPassword = (password, params = {}, field, fullData = {}) => {
    const { pattern = /(.*?)/, message = "must match requirements" } = params;
    const { isPasswordHashed = false } = fullData;
    if (isPasswordHashed === false && !pattern.test(password)) {
        return message;
    }
    return null;
};
validate.validators.conditionalPresenceMatchAll = (value, params = [], field, fullData = {}) => {
    if (value === undefined) {
        const count = params.reduce((count, params) => {
            if (Array.isArray(params)) {
                const some = params.some(({ field, value, inverted }) => (fullData[field] === value) === !inverted);
                if (some) {
                    count++;
                }
            }
            else {
                const { field, value, inverted } = params;
                if ((fullData[field] === value) === !inverted) {
                    count++;
                }
            }
            return count;
        }, 0);
        if (count === params.length) {
            return "must be present";
        }
    }
    return null;
};
validate.validators.conditionalPresenceMatchOne = (value, params = [], field, fullData = {}) => {
    if (value === undefined) {
        const count = params.reduce((count, { field, value, inverted }) => {
            if ((fullData[field] === value) === !inverted) {
                count++;
            }
            return count;
        }, 0);
        if (count > 0) {
            return "must be present";
        }
    }
    return null;
};
export const validation = (data, constraints) => {
    const error = validate.validate(data, constraints);
    if (error) {
        throw new ValidationException(error, { data, constraints });
    }
    return data;
};
export const validatePostcode = (text) => {
    return isValid(text.replace(" ", ""));
};
