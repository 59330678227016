import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import constraints from "@Shared/Entities/SensabillClient/constraints";
import migrate from "@Shared/Entities/SensabillClient/migrate";
export default class SensabillClient extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/clients/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { name, ref, username, password, platform } = this;
        const { version } = this._data;
        return {
            ...parent,
            version,
            name,
            ref,
            username,
            password,
            platform,
        };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get name() {
        return this._data.name;
    }
    get ref() {
        return this._data.ref;
    }
    get username() {
        return this._data.username;
    }
    get password() {
        return this._data.password;
    }
    get platform() {
        return this._data.platform;
    }
}
