import generateId from "@Lib/generateId";
import React, { useEffect, useRef, useState } from "react";
export function DragAndDropUploader(props) {
    const { content, submit, validate = (file) => !!file, transformer = (files) => files, } = props;
    const [files, setFiles] = useState([]);
    const [wait, setWait] = useState(false);
    const remove = (id) => {
        setFiles(files.filter((file) => file.id !== id));
    };
    const upload = async (uploaded) => {
        setWait(true);
        const newFiles = [];
        for (const f of uploaded) {
            const file = f;
            if (!validate(file)) {
                continue; // TODO error msg
            }
            file.id = file.id || generateId();
            newFiles.push(file);
        }
        const updatedFiles = await transformer([...files, ...newFiles].filter((value, index, self) => index ===
            self.findIndex((t) => t.size === value.size && t.name === value.name)));
        setFiles(updatedFiles);
        setWait(false);
        if (props.onUpload) {
            props.onUpload(updatedFiles);
        }
    };
    const updateFile = (file) => {
        const updatedFiles = files.map((f) => (f.id === file.id ? file : f));
        setFiles(updatedFiles);
    };
    const uploadBox = useRef(null);
    const input = useRef(null);
    const click = () => input.current.click();
    const fileUploadInputChange = (events) => {
        // best way to convert File list to real array
        const filesArray = Array.prototype.slice.call(events.target.files);
        upload(filesArray);
    };
    useEffect(() => {
        uploadBox.current.addEventListener("dragenter", handleDragIn);
        uploadBox.current.addEventListener("dragleave", handleDragOut);
        uploadBox.current.addEventListener("dragover", handleDrag);
        uploadBox.current.addEventListener("drop", handleDrop);
        return () => {
            uploadBox.current.removeEventListener("dragenter", handleDragIn);
            uploadBox.current.removeEventListener("dragleave", handleDragOut);
            uploadBox.current.removeEventListener("dragover", handleDrag);
            uploadBox.current.removeEventListener("drop", handleDrop);
        };
    }, []);
    const handleDrag = (events) => {
        events.preventDefault();
        events.stopPropagation();
    };
    const handleDragIn = (events) => {
        events.preventDefault();
        events.stopPropagation();
    };
    const handleDragOut = (events) => {
        events.preventDefault();
        events.stopPropagation();
    };
    const handleDrop = (events) => {
        events.preventDefault();
        events.stopPropagation();
        const uploaded = Object.values(events.dataTransfer.files);
        upload(uploaded);
    };
    return (React.createElement("div", { ref: uploadBox, className: props.className || "" },
        content({
            files,
            remove,
            upload,
            click,
            wait,
            updateFile,
            submit: () => submit(files),
        }),
        React.createElement("input", { type: "file", hidden: true, multiple: true, ref: input, onChange: fileUploadInputChange, 
            // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
            onClick: (event) => {
                const element = event.target;
                element.value = "";
            } })));
}
