import SVG from "@Components/Icons/SVG";
export default class CSVIcon extends SVG {
    path = [
        "M 7.152 0.096",
        "C 7.008 0.144 6.552 0.24 6.144 0.288",
        "C 5.76 0.36 5.352 0.456 5.232 0.528",
        "C 5.136 0.6 4.992 0.672 4.92 0.672",
        "C 4.752 0.672 4.416 0.816 3.984 1.104",
        "C 3.768 1.248 3.456 1.416 3.288 1.488",
        "C 3.144 1.56 2.976 1.68 2.952 1.776",
        "C 2.904 1.848 2.856 1.92 2.784 1.92",
        "C 2.712 1.92 1.824 2.76 1.824 2.856",
        "C 1.824 2.88 1.728 3.024 1.608 3.192",
        "C 1.488 3.36 1.344 3.576 1.296 3.672",
        "C 1.248 3.744 1.08 4.032 0.936 4.272",
        "C 0.528 4.992 0.192 6.048 0.096 6.984",
        "C -0.072 8.448 -0.024 93 0.144 94.032",
        "C 0.336 95.328 0.696 96.144 1.68 97.584",
        "C 1.968 98.016 2.688 98.712 3.216 99.072",
        "C 3.48 99.264 3.72 99.432 3.744 99.48",
        "C 3.768 99.504 3.912 99.576 4.08 99.6",
        "C 4.248 99.648 4.464 99.768 4.608 99.864",
        "C 4.728 99.96 4.92 100.032 5.04 100.032",
        "C 5.136 100.032 5.352 100.104 5.52 100.176",
        "C 6.096 100.488 8.136 100.512 43.92 100.512",
        "C 79.704 100.512 81.744 100.488 82.32 100.176",
        "C 82.488 100.104 82.68 100.032 82.776 100.032",
        "C 82.872 100.032 83.136 99.936 83.376 99.792",
        "C 83.64 99.672 83.904 99.552 83.976 99.552",
        "C 84.048 99.552 84.096 99.504 84.096 99.48",
        "C 84.096 99.432 84.216 99.336 84.36 99.264",
        "C 84.504 99.216 84.696 99.096 84.768 99.024",
        "C 85.32 98.52 85.992 97.848 86.064 97.728",
        "C 86.496 97.032 86.616 96.864 86.688 96.864",
        "C 86.736 96.864 86.808 96.744 86.832 96.576",
        "C 86.856 96.432 86.952 96.264 87.024 96.192",
        "C 87.096 96.144 87.192 95.952 87.216 95.784",
        "C 87.264 95.616 87.336 95.4 87.408 95.328",
        "C 87.48 95.256 87.552 94.92 87.6 94.608",
        "C 87.624 94.296 87.72 93.816 87.792 93.552",
        "C 87.912 93.168 88.032 38.832 87.936 32.376",
        "C 87.936 32.28 85.608 32.256 77.544 32.232",
        "C 65.568 32.184 65.304 32.16 63.6 31.392",
        "C 63.384 31.296 63.096 31.176 62.976 31.104",
        "C 62.328 30.816 61.2 29.976 60.744 29.448",
        "C 60.144 28.776 59.808 28.296 59.808 28.152",
        "C 59.808 28.08 59.76 28.032 59.712 28.032",
        "C 59.664 28.032 59.568 27.912 59.496 27.768",
        "C 59.424 27.624 59.304 27.408 59.208 27.264",
        "C 59.112 27.144 59.04 26.952 59.04 26.832",
        "C 59.04 26.712 58.968 26.568 58.896 26.496",
        "C 58.824 26.448 58.704 26.16 58.656 25.896",
        "C 58.584 25.608 58.488 25.296 58.416 25.2",
        "C 58.128 24.744 58.08 22.848 58.08 11.664",
        "C 58.08 1.656 58.056 0.408 57.936 0.216 L 57.792 0 L 32.616 0",
        "C 18.672 0.024 7.32 0.048 7.152 0.096 M 27.528 65.304",
        "C 27.672 65.376 27.984 65.472 28.2 65.52",
        "C 28.44 65.544 28.776 65.664 28.968 65.76",
        "C 29.16 65.856 29.376 65.952 29.448 65.952",
        "C 29.52 65.952 29.688 66.048 29.832 66.144",
        "C 29.952 66.24 30.144 66.336 30.216 66.336",
        "C 30.288 66.336 30.336 66.384 30.336 66.432",
        "C 30.336 66.48 30.456 66.6 30.624 66.672",
        "C 30.96 66.84 31.68 67.512 32.016 67.968",
        "C 33 69.312 31.2 70.848 30.024 69.696",
        "C 29.328 69.024 29.088 68.832 28.992 68.832",
        "C 28.944 68.832 28.896 68.76 28.896 68.688",
        "C 28.896 68.616 28.824 68.544 28.728 68.544",
        "C 28.608 68.544 28.44 68.472 28.32 68.376",
        "C 28.176 68.28 27.96 68.16 27.792 68.112",
        "C 27.624 68.088 27.432 67.992 27.36 67.92",
        "C 26.928 67.536 23.856 67.584 23.088 67.968",
        "C 22.872 68.064 22.656 68.16 22.584 68.16",
        "C 22.512 68.16 22.344 68.256 22.2 68.352",
        "C 22.08 68.448 21.888 68.544 21.792 68.544",
        "C 21.72 68.544 21.6 68.64 21.528 68.736",
        "C 21.456 68.832 21.36 68.928 21.312 68.928",
        "C 21.216 68.928 20.328 69.816 19.8 70.416",
        "C 19.656 70.584 19.392 71.064 19.104 71.712",
        "C 19.056 71.856 18.96 72.024 18.936 72.096",
        "C 18.528 72.792 18.264 76.176 18.552 76.776",
        "C 18.648 76.92 18.744 77.256 18.768 77.52",
        "C 18.816 77.784 18.912 78.096 19.032 78.24",
        "C 19.128 78.36 19.2 78.528 19.2 78.624",
        "C 19.2 78.72 19.296 78.912 19.392 79.032",
        "C 19.488 79.176 19.584 79.32 19.584 79.368",
        "C 19.584 79.44 19.704 79.608 20.208 80.184",
        "C 20.28 80.28 20.352 80.4 20.352 80.448",
        "C 20.352 80.496 20.4 80.544 20.448 80.544",
        "C 20.472 80.544 20.688 80.712 20.904 80.928",
        "C 21.12 81.12 21.384 81.312 21.48 81.36",
        "C 21.576 81.384 21.744 81.504 21.84 81.6",
        "C 21.936 81.696 22.08 81.792 22.152 81.792",
        "C 22.248 81.792 22.392 81.864 22.488 81.936",
        "C 22.56 82.032 22.848 82.152 23.088 82.176",
        "C 23.328 82.224 23.592 82.344 23.664 82.416",
        "C 23.88 82.632 26.808 82.632 26.976 82.416",
        "C 27.048 82.344 27.24 82.248 27.432 82.224",
        "C 27.624 82.176 27.912 82.08 28.056 81.984",
        "C 28.2 81.864 28.344 81.792 28.392 81.792",
        "C 28.512 81.792 29.232 81.264 29.736 80.76",
        "C 31.416 79.152 33.24 80.88 31.68 82.584",
        "C 31.344 82.944 31.032 83.232 30.96 83.232",
        "C 30.888 83.232 30.792 83.28 30.744 83.352",
        "C 30.648 83.52 29.928 84 29.784 84",
        "C 29.736 84 29.64 84.072 29.568 84.144",
        "C 29.52 84.216 29.328 84.312 29.16 84.336",
        "C 28.992 84.384 28.704 84.48 28.512 84.576",
        "C 28.176 84.744 27.624 84.864 26.184 85.08",
        "C 25.224 85.2 23.088 84.936 22.176 84.576",
        "C 21.936 84.48 21.6 84.36 21.432 84.336",
        "C 21.264 84.312 21.072 84.216 21.024 84.144",
        "C 20.952 84.072 20.832 84 20.736 84",
        "C 20.616 84 20.472 83.928 20.376 83.832",
        "C 20.28 83.736 20.088 83.616 19.968 83.544",
        "C 19.488 83.304 19.296 83.16 19.08 82.968",
        "C 18.96 82.848 18.792 82.752 18.744 82.752",
        "C 18.672 82.752 18.624 82.728 18.624 82.68",
        "C 18.624 82.608 18.168 82.08 17.616 81.504",
        "C 17.424 81.288 16.992 80.64 16.992 80.544",
        "C 16.992 80.52 16.896 80.4 16.8 80.304",
        "C 16.704 80.208 16.608 80.04 16.608 79.944",
        "C 16.608 79.848 16.512 79.656 16.416 79.512",
        "C 16.32 79.392 16.224 79.176 16.224 79.056",
        "C 16.224 78.96 16.152 78.72 16.032 78.576",
        "C 15.936 78.408 15.84 78 15.792 77.664",
        "C 15.744 77.328 15.672 76.968 15.624 76.896",
        "C 15.432 76.68 15.408 73.896 15.576 73.416",
        "C 15.672 73.176 15.768 72.744 15.792 72.432",
        "C 15.84 72.096 15.936 71.76 16.056 71.616",
        "C 16.152 71.496 16.224 71.28 16.224 71.136",
        "C 16.224 70.992 16.296 70.8 16.368 70.728",
        "C 16.44 70.632 16.536 70.44 16.584 70.272",
        "C 16.608 70.104 16.704 69.936 16.824 69.888",
        "C 16.92 69.84 16.992 69.696 16.992 69.6",
        "C 16.992 69.528 17.088 69.312 17.208 69.192",
        "C 17.328 69.048 17.52 68.808 17.64 68.664",
        "C 17.76 68.544 17.856 68.376 17.856 68.328",
        "C 17.856 68.304 17.928 68.256 18 68.256",
        "C 18.072 68.256 18.144 68.208 18.144 68.136",
        "C 18.144 68.016 19.08 67.104 19.2 67.104",
        "C 19.248 67.104 19.32 67.056 19.368 66.984",
        "C 19.416 66.912 19.56 66.768 19.728 66.672",
        "C 19.896 66.6 20.232 66.408 20.496 66.24",
        "C 20.76 66.096 21.072 65.952 21.168 65.952",
        "C 21.264 65.952 21.528 65.856 21.72 65.76",
        "C 21.936 65.64 22.296 65.544 22.536 65.496",
        "C 22.8 65.472 23.088 65.4 23.184 65.352",
        "C 23.736 65.064 26.976 65.04 27.528 65.304 M 44.496 65.304",
        "C 44.712 65.4 45.072 65.496 45.336 65.52",
        "C 45.576 65.544 45.816 65.64 45.888 65.712",
        "C 45.936 65.784 46.152 65.856 46.344 65.904",
        "C 46.536 65.928 46.848 66.048 47.016 66.144",
        "C 47.208 66.264 47.424 66.336 47.496 66.336",
        "C 47.568 66.336 47.664 66.432 47.712 66.528",
        "C 47.76 66.624 47.88 66.72 47.952 66.72",
        "C 48.024 66.72 48.264 66.912 48.504 67.128",
        "C 49.176 67.752 49.128 68.76 48.432 69.24",
        "C 48.12 69.456 46.848 69.36 46.776 69.12",
        "C 46.752 69.072 46.608 68.952 46.44 68.88",
        "C 46.128 68.736 45.936 68.64 45.504 68.352",
        "C 45.384 68.256 45.096 68.16 44.904 68.112",
        "C 44.712 68.088 44.496 67.992 44.424 67.92",
        "C 44.064 67.584 40.632 67.56 40.224 67.92",
        "C 40.152 67.992 39.984 68.064 39.84 68.112",
        "C 39.696 68.16 39.552 68.256 39.48 68.376",
        "C 39.408 68.472 39.288 68.544 39.216 68.544",
        "C 39.144 68.544 39.072 68.592 39.072 68.664",
        "C 39.072 68.736 39 68.88 38.88 68.976",
        "C 38.376 69.432 38.448 71.112 39 71.616",
        "C 39.144 71.736 39.36 71.928 39.456 72.024",
        "C 39.552 72.096 39.72 72.192 39.864 72.24",
        "C 39.984 72.288 40.224 72.384 40.368 72.48",
        "C 40.536 72.6 40.752 72.672 40.848 72.672",
        "C 40.968 72.672 41.208 72.768 41.424 72.864",
        "C 41.64 72.96 41.928 73.056 42.096 73.056",
        "C 42.264 73.056 42.552 73.152 42.744 73.248",
        "C 42.936 73.344 43.224 73.44 43.368 73.44",
        "C 43.512 73.44 43.8 73.512 44.016 73.632",
        "C 44.232 73.728 44.568 73.824 44.784 73.848",
        "C 44.976 73.896 45.216 73.968 45.312 74.04",
        "C 45.384 74.136 45.624 74.232 45.816 74.256",
        "C 46.032 74.304 46.224 74.376 46.248 74.472",
        "C 46.272 74.544 46.392 74.592 46.512 74.592",
        "C 46.656 74.592 46.824 74.664 46.944 74.784",
        "C 47.04 74.88 47.208 74.976 47.328 75.024",
        "C 47.64 75.12 48.72 76.224 48.96 76.68",
        "C 49.056 76.896 49.2 77.112 49.248 77.136",
        "C 49.296 77.16 49.344 77.328 49.344 77.472",
        "C 49.344 77.64 49.416 77.952 49.512 78.168",
        "C 49.632 78.432 49.68 78.84 49.68 79.344",
        "C 49.68 79.848 49.632 80.256 49.512 80.52",
        "C 49.416 80.736 49.344 81.048 49.344 81.216",
        "C 49.344 81.36 49.272 81.528 49.2 81.6",
        "C 49.128 81.648 49.008 81.864 48.96 82.032",
        "C 48.912 82.2 48.792 82.368 48.72 82.416",
        "C 48.648 82.44 48.576 82.512 48.576 82.56",
        "C 48.576 82.704 48.072 83.232 47.928 83.232",
        "C 47.856 83.232 47.808 83.28 47.808 83.352",
        "C 47.808 83.496 47.352 83.808 46.656 84.144",
        "C 46.368 84.288 45.96 84.48 45.768 84.576",
        "C 45.576 84.696 45.264 84.768 45.072 84.768",
        "C 44.904 84.768 44.544 84.84 44.28 84.936",
        "C 43.656 85.152 41.472 85.152 40.656 84.936",
        "C 40.344 84.84 39.96 84.768 39.792 84.768",
        "C 39.624 84.768 39.36 84.696 39.168 84.6",
        "C 38.976 84.504 38.664 84.384 38.472 84.336",
        "C 38.28 84.312 38.088 84.216 38.016 84.144",
        "C 37.944 84.072 37.824 84 37.728 84",
        "C 37.608 84 37.44 83.904 37.344 83.808",
        "C 37.248 83.712 37.104 83.616 37.032 83.616",
        "C 36.96 83.616 36.816 83.52 36.72 83.424",
        "C 36.624 83.328 36.504 83.232 36.432 83.232",
        "C 36.288 83.232 35.592 82.584 35.376 82.224",
        "C 35.208 81.96 35.232 81.192 35.424 81.072",
        "C 35.496 81.024 35.568 80.904 35.616 80.784",
        "C 35.664 80.64 35.76 80.544 35.832 80.544",
        "C 35.928 80.544 36 80.472 36.024 80.4",
        "C 36.072 80.304 36.216 80.256 36.504 80.256",
        "C 36.768 80.256 36.984 80.304 37.056 80.4",
        "C 37.128 80.472 37.224 80.544 37.272 80.544",
        "C 37.32 80.544 37.512 80.664 37.68 80.808",
        "C 38.208 81.264 38.424 81.408 38.544 81.408",
        "C 38.616 81.408 38.736 81.504 38.832 81.6",
        "C 38.928 81.696 39.072 81.792 39.168 81.792",
        "C 39.24 81.792 39.432 81.888 39.6 81.984",
        "C 39.768 82.08 40.008 82.176 40.128 82.176",
        "C 40.272 82.176 40.536 82.272 40.728 82.368",
        "C 41.256 82.656 43.992 82.656 44.52 82.368",
        "C 44.712 82.272 44.976 82.152 45.12 82.08",
        "C 45.72 81.816 46.032 81.552 46.512 80.808",
        "C 46.848 80.28 46.872 78.672 46.536 78.312",
        "C 46.416 78.192 46.224 77.976 46.104 77.856",
        "C 45.744 77.448 45.528 77.28 45.264 77.232",
        "C 45.144 77.184 44.976 77.112 44.928 77.04",
        "C 44.856 76.968 44.664 76.872 44.472 76.848",
        "C 44.28 76.8 44.04 76.728 43.968 76.656",
        "C 43.872 76.584 43.608 76.488 43.344 76.44",
        "C 43.08 76.416 42.744 76.296 42.576 76.2",
        "C 42.408 76.104 42.12 76.032 41.928 76.032",
        "C 41.736 76.032 41.4 75.936 41.208 75.84",
        "C 41.016 75.744 40.728 75.648 40.584 75.648",
        "C 40.416 75.648 40.152 75.576 39.984 75.456",
        "C 39.84 75.36 39.528 75.264 39.336 75.216",
        "C 39.144 75.192 38.952 75.096 38.88 75.024",
        "C 38.808 74.952 38.664 74.88 38.544 74.88",
        "C 38.4 74.88 38.208 74.784 38.112 74.688",
        "C 38.016 74.592 37.872 74.496 37.8 74.496",
        "C 37.44 74.496 36.168 73.08 36.024 72.504",
        "C 36 72.36 35.904 72.144 35.808 72",
        "C 35.592 71.64 35.592 69.312 35.832 69.048",
        "C 35.928 68.952 36 68.784 36 68.664",
        "C 36 68.568 36.096 68.328 36.24 68.16",
        "C 36.384 67.992 36.48 67.8 36.48 67.752",
        "C 36.48 67.632 36.648 67.44 37.104 67.032",
        "C 37.248 66.888 37.464 66.672 37.584 66.552",
        "C 37.704 66.432 37.848 66.336 37.896 66.336",
        "C 37.944 66.336 38.04 66.264 38.112 66.192",
        "C 38.184 66.12 38.4 66 38.616 65.952",
        "C 38.856 65.88 39.096 65.784 39.192 65.688",
        "C 39.264 65.616 39.528 65.52 39.768 65.496",
        "C 39.984 65.472 40.224 65.4 40.296 65.352",
        "C 40.656 65.064 43.776 65.04 44.496 65.304 M 54.12 65.424",
        "C 54.432 65.592 54.912 66.144 54.912 66.336",
        "C 54.912 66.408 55.008 66.648 55.104 66.84",
        "C 55.2 67.032 55.296 67.272 55.296 67.368",
        "C 55.296 67.44 55.368 67.656 55.488 67.824",
        "C 55.584 67.968 55.68 68.256 55.728 68.448",
        "C 55.776 68.64 55.848 68.856 55.92 68.928",
        "C 55.992 69 56.064 69.216 56.112 69.384",
        "C 56.136 69.552 56.256 69.84 56.352 70.032",
        "C 56.472 70.248 56.544 70.464 56.544 70.512",
        "C 56.544 70.56 56.64 70.776 56.736 70.992",
        "C 56.832 71.208 56.928 71.472 56.928 71.568",
        "C 56.928 71.688 57 71.832 57.072 71.904",
        "C 57.144 71.976 57.24 72.168 57.288 72.36",
        "C 57.312 72.552 57.432 72.84 57.528 73.008",
        "C 57.624 73.176 57.696 73.392 57.696 73.512",
        "C 57.696 73.656 57.792 73.848 57.888 73.992",
        "C 57.984 74.136 58.08 74.304 58.08 74.4",
        "C 58.08 74.472 58.176 74.712 58.272 74.904",
        "C 58.368 75.096 58.464 75.336 58.464 75.432",
        "C 58.464 75.504 58.536 75.72 58.656 75.888",
        "C 58.752 76.032 58.848 76.32 58.896 76.512",
        "C 58.944 76.704 59.016 76.92 59.088 76.992",
        "C 59.16 77.064 59.232 77.304 59.28 77.496",
        "C 59.304 77.688 59.4 77.88 59.472 77.952",
        "C 59.544 78.024 59.64 78.264 59.664 78.48",
        "C 59.712 78.72 59.784 78.96 59.856 79.008",
        "C 59.928 79.08 60.024 79.272 60.048 79.464",
        "C 60.096 79.656 60.192 79.896 60.264 79.992",
        "C 60.336 80.064 60.432 80.328 60.456 80.544",
        "C 60.528 81.168 61.056 81.312 61.056 80.688",
        "C 61.056 80.568 61.128 80.4 61.2 80.304",
        "C 61.272 80.184 61.392 79.896 61.44 79.656",
        "C 61.512 79.416 61.608 79.152 61.68 79.104",
        "C 61.776 79.032 61.824 78.888 61.824 78.768",
        "C 61.824 78.672 61.92 78.408 62.016 78.216",
        "C 62.112 78.024 62.208 77.784 62.208 77.688",
        "C 62.208 77.616 62.28 77.4 62.4 77.232",
        "C 62.496 77.088 62.592 76.8 62.64 76.608",
        "C 62.688 76.416 62.76 76.2 62.832 76.128",
        "C 62.904 76.056 62.976 75.816 63.024 75.624",
        "C 63.048 75.432 63.144 75.24 63.216 75.168",
        "C 63.288 75.12 63.36 74.904 63.408 74.712",
        "C 63.432 74.52 63.552 74.208 63.648 74.04",
        "C 63.768 73.848 63.84 73.632 63.84 73.56",
        "C 63.84 73.488 63.912 73.296 63.984 73.176",
        "C 64.104 72.936 64.176 72.744 64.512 71.808",
        "C 64.584 71.616 64.704 71.304 64.8 71.112",
        "C 64.92 70.92 64.992 70.704 64.992 70.632",
        "C 64.992 70.56 65.064 70.344 65.184 70.152",
        "C 65.28 69.96 65.376 69.648 65.424 69.456",
        "C 65.448 69.288 65.544 69.096 65.616 69.024",
        "C 65.688 68.976 65.76 68.76 65.808 68.568",
        "C 65.832 68.376 65.952 68.064 66.048 67.896",
        "C 66.168 67.704 66.24 67.488 66.24 67.416",
        "C 66.24 67.344 66.336 67.128 66.432 66.936",
        "C 66.528 66.744 66.624 66.504 66.624 66.432",
        "C 66.624 66.336 66.744 66.12 66.864 65.952",
        "C 67.992 64.416 70.32 65.808 69.408 67.464",
        "C 69.312 67.656 69.168 68.016 69.12 68.232",
        "C 69.072 68.448 68.976 68.664 68.928 68.688",
        "C 68.88 68.712 68.832 68.832 68.832 68.952",
        "C 68.832 69.072 68.736 69.384 68.592 69.648",
        "C 68.472 69.912 68.352 70.2 68.352 70.296",
        "C 68.352 70.368 68.304 70.488 68.256 70.512",
        "C 68.208 70.536 68.112 70.728 68.064 70.92",
        "C 67.944 71.256 67.872 71.472 67.416 72.48",
        "C 67.32 72.72 67.2 73.056 67.152 73.224",
        "C 67.128 73.392 67.032 73.584 66.96 73.632",
        "C 66.888 73.704 66.816 73.824 66.816 73.896",
        "C 66.816 73.968 66.72 74.232 66.624 74.496",
        "C 66.504 74.736 66.336 75.096 66.24 75.312",
        "C 66.144 75.528 66.024 75.864 65.952 76.056",
        "C 65.904 76.248 65.808 76.44 65.76 76.464",
        "C 65.712 76.488 65.664 76.608 65.664 76.68",
        "C 65.664 76.776 65.544 77.064 65.424 77.328",
        "C 65.304 77.592 65.184 77.856 65.184 77.928",
        "C 65.184 78 65.088 78.216 64.992 78.408",
        "C 64.896 78.6 64.8 78.84 64.8 78.912",
        "C 64.8 79.008 64.752 79.128 64.656 79.2",
        "C 64.584 79.248 64.488 79.512 64.416 79.752",
        "C 64.368 79.992 64.248 80.28 64.176 80.4",
        "C 64.104 80.496 64.032 80.664 64.032 80.76",
        "C 64.032 80.856 63.936 81.048 63.84 81.192",
        "C 63.744 81.336 63.648 81.528 63.648 81.624",
        "C 63.648 81.72 63.576 81.96 63.456 82.152",
        "C 63.36 82.344 63.264 82.632 63.216 82.776",
        "C 63.192 82.92 63.12 83.088 63.024 83.16",
        "C 62.952 83.232 62.88 83.424 62.832 83.616",
        "C 62.808 83.784 62.712 83.952 62.64 83.976",
        "C 62.568 84 62.496 84.072 62.496 84.168",
        "C 62.496 84.24 62.424 84.312 62.328 84.36",
        "C 62.232 84.384 62.112 84.504 62.04 84.576",
        "C 61.8 84.936 60.024 84.936 59.64 84.576",
        "C 59.544 84.48 59.376 84.36 59.256 84.312",
        "C 59.088 84.216 58.272 82.704 58.272 82.44",
        "C 58.272 82.368 58.152 82.104 58.032 81.84",
        "C 57.912 81.576 57.792 81.288 57.792 81.216",
        "C 57.792 81.144 57.72 81 57.648 80.904",
        "C 57.576 80.808 57.48 80.544 57.456 80.352",
        "C 57.432 80.16 57.336 79.968 57.264 79.944",
        "C 57.192 79.896 57.096 79.728 57.072 79.512",
        "C 57.024 79.32 56.904 79.008 56.808 78.816",
        "C 56.712 78.624 56.64 78.408 56.64 78.312",
        "C 56.64 78.216 56.544 78.024 56.448 77.88",
        "C 56.352 77.736 56.256 77.568 56.256 77.448",
        "C 56.256 77.352 56.16 77.112 56.064 76.92",
        "C 55.968 76.728 55.872 76.488 55.872 76.392",
        "C 55.872 76.296 55.776 76.104 55.68 75.96",
        "C 55.584 75.816 55.488 75.624 55.488 75.504",
        "C 55.488 75.408 55.392 75.168 55.296 75",
        "C 55.2 74.832 55.104 74.616 55.104 74.52",
        "C 55.104 74.424 55.008 74.232 54.912 74.064",
        "C 54.816 73.896 54.72 73.704 54.72 73.632",
        "C 54.72 73.536 54.624 73.32 54.528 73.104",
        "C 54.432 72.888 54.312 72.576 54.288 72.408",
        "C 54.24 72.24 54.192 72.096 54.144 72.096",
        "C 54.096 72.096 54 71.904 53.952 71.688",
        "C 53.88 71.472 53.76 71.112 53.64 70.896",
        "C 53.544 70.68 53.472 70.44 53.472 70.344",
        "C 53.472 70.272 53.4 70.152 53.328 70.08",
        "C 53.256 70.008 53.16 69.816 53.136 69.624",
        "C 53.088 69.432 52.992 69.144 52.896 68.976",
        "C 52.8 68.808 52.704 68.544 52.656 68.376",
        "C 52.632 68.208 52.56 68.064 52.512 68.064",
        "C 52.464 68.064 52.392 67.872 52.32 67.632",
        "C 52.272 67.392 52.176 67.176 52.128 67.152",
        "C 51.96 67.056 52.032 66.456 52.272 66.048",
        "C 52.656 65.352 53.448 65.088 54.12 65.424",
    ];
    evenOdd = true;
}
