import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (supplierId = null, inclDel) => {
    const url = `/suppliers/${supplierId}`;
    return api
        .get(url, { params: { inclDel } })
        .then((result) => Factory.Supplier(result.data.item));
};
export const get = (callback) => collection((data) => callback(Factory.Supplier(data)), `/suppliers`);
export const post = (supplier) => {
    return api
        .post(`/suppliers`, supplier.data)
        .then((result) => {
        return Factory.Supplier(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (supplier) => {
    return api
        .put(`/suppliers/${supplier.id}`, supplier.data)
        .then((result) => {
        return Factory.Supplier(result.data.item);
    });
};
export const del = (supplier) => {
    return api.delete(`/suppliers/${supplier.id}`).then((result) => {
        return result.data.item;
    });
};
