import React from "react";
import styles from "@Components/Icons/ChatBot/Predicty/index.module.scss";
import PredictMascot from "./PredictMascot.svg";
export default class PredictMascotIcon extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        return (React.createElement("img", { src: PredictMascot, alt: "Predict Mobile", className: `${styles.predicty} ${this.props.className}` }));
    }
}
