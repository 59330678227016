// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag--BwVfc{display:inline-flex;align-items:center;justify-content:center;margin:.1em;padding-left:.5em;color:#0eabdb;background:var(--tag-background-color);border-radius:.2em}.tag--BwVfc.chat--sjjnm{padding:4px 4px 4px 8px;border-radius:1em}.tag--BwVfc .button--INcg5{display:block;float:right;padding:0 .5em;background:none;border:0;border-radius:50%;cursor:pointer}.tag--BwVfc .button--INcg5:hover{color:var(--delete-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/MultiTextAdd/Tag/index.module.scss"],"names":[],"mappings":"AAAA,YAAK,mBAAmB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,WAAW,CAAC,iBAAiB,CAAC,aAAa,CAAC,sCAAsC,CAAC,kBAAkB,CAAC,wBAAU,uBAAuB,CAAC,iBAAiB,CAAC,2BAAa,aAAa,CAAC,WAAW,CAAC,cAAc,CAAC,eAAe,CAAC,QAAQ,CAAC,iBAAiB,CAAC,cAAc,CAAC,iCAAmB,yBAAyB","sourcesContent":[".tag{display:inline-flex;align-items:center;justify-content:center;margin:.1em;padding-left:.5em;color:#0eabdb;background:var(--tag-background-color);border-radius:.2em}.tag.chat{padding:4px 4px 4px 8px;border-radius:1em}.tag .button{display:block;float:right;padding:0 .5em;background:none;border:0;border-radius:50%;cursor:pointer}.tag .button:hover{color:var(--delete-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "tag--BwVfc",
	"chat": "chat--sjjnm",
	"button": "button--INcg5"
};
export default ___CSS_LOADER_EXPORT___;
