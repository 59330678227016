import { store } from "@Store/index";
const getCustomerUser = (state, awardedContract, tender) => {
    const tenderLinkedUser = state.users.find(({ id }) => id === tender?.userId);
    if (tenderLinkedUser) {
        return tenderLinkedUser;
    }
    if (awardedContract.partnerId) {
        return state.users.find(({ partnerId }) => partnerId === awardedContract.partnerId);
    }
    return state.users.find(({ customerId }) => customerId === awardedContract.customerId);
};
export default (awardedContractId) => {
    const state = store.getState();
    const awardedContract = state.awardedContracts.find(({ id }) => id === awardedContractId);
    if (awardedContract === undefined) {
        return {
            fetching: true,
        };
    }
    const customer = state.customers.companies.find(({ id }) => id === awardedContract.customerId);
    const supplier = state.suppliers.companies.find(({ id }) => id === awardedContract.supplierId);
    const response = state.suppliers.responses.find(({ id }) => id === awardedContract.responseId);
    const tender = state.customers.tenders.find(({ id }) => id === awardedContract.tenderId);
    const customerUser = getCustomerUser(state, awardedContract, tender);
    const supplierUser = state.users.find(({ id }) => id === response?.userId) ||
        state.users.find(({ supplierId }) => supplierId === awardedContract?.supplierId);
    const usage = state.customers.usage.find(({ id }) => id === awardedContract.usageId);
    const fetching = customer === undefined ||
        supplier === undefined ||
        response === undefined ||
        tender === undefined ||
        customerUser === undefined ||
        supplierUser === undefined ||
        (awardedContract.usageId !== undefined && usage === undefined);
    return {
        fetching,
        awardedContract,
        customer,
        supplier,
        response,
        tender,
        customerUser,
        supplierUser,
        usage,
    };
};
