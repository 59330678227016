// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--qQZx8{display:flex;flex-direction:column;flex-wrap:wrap}.wrapper--qQZx8 .item--stRAF{display:flex;margin-top:2px}.wrapper--qQZx8 .item--stRAF .dotWrapper--ONCAl{display:flex;justify-content:center}.wrapper--qQZx8 .item--stRAF .dotWrapper--ONCAl .dot--Xd8qT{box-sizing:border-box;width:1em;height:1em;margin:auto;background-color:var(--card-color);border:1px solid var(--text-color);border-radius:100%;cursor:pointer}.wrapper--qQZx8 .item--stRAF .dotWrapper--ONCAl .dot--Xd8qT.error--TuFwC{border-color:var(--error-color)}.wrapper--qQZx8 .item--stRAF .dotWrapper--ONCAl .dot--Xd8qT.selected--uWtpz{border:4px solid var(--selected-input-color)}.wrapper--qQZx8 .item--stRAF .label--mw1B4{margin-left:.75em;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/RadioGroup/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,YAAY,CAAC,qBAAqB,CAAC,cAAc,CAAC,6BAAe,YAAY,CAAC,cAAc,CAAC,gDAA2B,YAAY,CAAC,sBAAsB,CAAC,4DAAgC,qBAAqB,CAAC,SAAS,CAAC,UAAU,CAAC,WAAW,CAAC,kCAAkC,CAAC,kCAAkC,CAAC,kBAAkB,CAAC,cAAc,CAAC,yEAAsC,+BAA+B,CAAC,4EAAyC,4CAA4C,CAAC,2CAAsB,iBAAiB,CAAC,cAAc","sourcesContent":[".wrapper{display:flex;flex-direction:column;flex-wrap:wrap}.wrapper .item{display:flex;margin-top:2px}.wrapper .item .dotWrapper{display:flex;justify-content:center}.wrapper .item .dotWrapper .dot{box-sizing:border-box;width:1em;height:1em;margin:auto;background-color:var(--card-color);border:1px solid var(--text-color);border-radius:100%;cursor:pointer}.wrapper .item .dotWrapper .dot.error{border-color:var(--error-color)}.wrapper .item .dotWrapper .dot.selected{border:4px solid var(--selected-input-color)}.wrapper .item .label{margin-left:.75em;cursor:pointer}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--qQZx8",
	"item": "item--stRAF",
	"dotWrapper": "dotWrapper--ONCAl",
	"dot": "dot--Xd8qT",
	"error": "error--TuFwC",
	"selected": "selected--uWtpz",
	"label": "label--mw1B4"
};
export default ___CSS_LOADER_EXPORT___;
