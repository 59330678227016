import React from "react";
import Tick from "@Components/Icons/Tick";
import styles from "./index.module.scss";
export default class Tickbox extends React.Component {
    toggle = () => {
        this.props.toggle(!this.props.value);
    };
    render() {
        return (React.createElement("span", { className: styles.box, onClick: this.toggle }, this.props.value && React.createElement(Tick, { success: true, size: "1.6rem" })));
    }
}
