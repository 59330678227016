import React from "react";
import FormCalendar from "@Components/Form/Calendar";
import styles from "./index.module.scss";
export default class Date extends React.Component {
    change = (name, value) => {
        this.props.change("text", value);
    };
    render() {
        return (React.createElement(FormCalendar, { value: this.props.value, valueChanged: this.change, className: styles.input, noBorder: true }));
    }
}
