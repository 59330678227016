// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--tm5NJ{display:flex !important;justify-content:center;padding-left:1.5em !important}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/TextInput/Date/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,uBAAuB,CAAC,sBAAsB,CAAC,6BAA6B","sourcesContent":[".input{display:flex !important;justify-content:center;padding-left:1.5em !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--tm5NJ"
};
export default ___CSS_LOADER_EXPORT___;
