export const leasingPricesConstraints = {
    deviceId: {
        presence: true,
        type: "string",
    },
    quantity: {
        presence: true,
        type: "number",
    },
    monthlyCost: {
        presence: true,
        type: "number",
    },
    totalMonthlyCost: {
        presence: true,
        type: "number",
    },
};
