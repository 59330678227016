import { validation } from "@Shared/Validate";
import constraints from "./constraints";
export default class BaseEntity {
    _data = {};
    constructor(data = {}, extraConstraints = {}) {
        this._data = validation(data, { ...constraints, ...extraConstraints });
    }
    get baseUrl() {
        return "";
        // if (process.env.IS_OFFLINE) {
        //     return "http://127.0.0.1:3000"
        // }
        // return process.env.DOMAIN
    }
    get data() {
        const { id, createdAt, updatedAt, deletedAt, isFrozen, seqNo, primaryTerm, } = this;
        const { baseVersion } = this._data;
        return {
            id,
            baseVersion,
            createdAt,
            updatedAt,
            deletedAt,
            isFrozen,
            seqNo,
            primaryTerm,
        };
    }
    get toSave() {
        const { seqNo, primaryTerm, ...data } = this.data;
        return data;
    }
    get id() {
        return this._data.id;
    }
    get createdAt() {
        return this._data.createdAt;
    }
    get updatedAt() {
        return this._data.updatedAt;
    }
    get deletedAt() {
        return this._data.deletedAt;
    }
    get seqNo() {
        return this._data.seqNo;
    }
    get primaryTerm() {
        return this._data.primaryTerm;
    }
    get isFrozen() {
        return this._data.isFrozen || false;
    }
    toJSON() {
        return {
            item: this.data,
            links: this.links,
        };
    }
}
