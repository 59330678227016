import { collection } from "@Api/helpers";
import api from "@Api/index";
import Factory from "@Shared/Factory";
export const getForAdmin = (callback) => {
    const url = `/customers/usages`;
    return collection((data) => callback(Factory.Usage(data)), url);
};
export const find = (customerId, usageId, inclDel) => {
    const url = `/customers/${customerId}/usages/${usageId}`;
    return api.get(url, { params: { inclDel } }).then((result) => {
        return Factory.Usage(result.data.item);
    });
};
export const getUsageIframe = (customerId, usageId) => {
    const url = `/customers/${customerId}/usages/${usageId}/iframe`;
    return api.get(url).then((result) => {
        let { url, usage } = result.data;
        usage = Factory.Usage(usage);
        return { url, usage };
    });
};
export const get = (callback, customerId) => {
    const url = `/customers/${customerId}/usages`;
    return collection((data) => callback(Factory.Usage(data)), url);
};
export const getDetails = (callback, customerId) => {
    const url = `/customers/${customerId}/usages/details`;
    return collection((data) => callback(Factory.Usage(data)), url);
};
export const post = (usage) => {
    return api
        .post(`/customers/${usage.customerId}/usages`, usage.data)
        .then((result) => {
        return Factory.Usage(result.data.item);
    });
};
export const put = (usage) => {
    return api
        .put(`/customers/${usage.customerId}/usages/${usage.id}`, usage.data)
        .then((result) => {
        return Factory.Usage(result.data.item);
    });
};
export const del = (usage) => {
    return api
        .delete(`/customers/${usage.customerId}/usages/${usage.id}`)
        .then((result) => {
        return result.data.item;
    });
};
export const uploaded = (customerId, usageId, totalRows) => {
    return api
        .put(`customers/${customerId}/usages/${usageId}/uploaded`, {
        totalRows,
    })
        .then((result) => {
        return result.data.item;
    });
};
