export const priceRangeConstraints = {
    low: {
        type: "number",
        notEmpty: true,
        numericality: {
            onlyInteger: true,
        },
        lessThan: "high",
    },
    high: {
        type: "number",
        notEmpty: true,
        numericality: {
            onlyInteger: true,
        },
        greaterThan: "low",
    },
    userId: {
        type: "string",
    },
    updatedAt: {
        type: "Dayjs",
    },
};
