import Factory from "@Shared/Factory";
import { ADD_ADMIN_USER, DELETE_ADMIN_USER } from "@Store/Actions/Admin/users";
import { DELETE_CUSTOMER } from "@Store/Actions/Customers/companies";
import { ADD_CUSTOMER_USER, DELETE_CUSTOMER_USER, } from "@Store/Actions/Customers/users";
import { DELETE_PARTNER } from "@Store/Actions/Partners/companies";
import { ADD_PARTNER_USER, DELETE_PARTNER_USER, } from "@Store/Actions/Partners/users";
import { DELETE_SUPPLIER } from "@Store/Actions/Suppliers/companies";
import { ADD_SUPPLIER_USER, DELETE_SUPPLIER_USER, } from "@Store/Actions/Suppliers/users";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_ADMIN_USER:
        case DELETE_SUPPLIER_USER:
        case DELETE_CUSTOMER_USER:
        case DELETE_PARTNER_USER:
            return state.filter(({ id }) => id !== data);
        case ADD_ADMIN_USER:
        case ADD_CUSTOMER_USER:
        case ADD_SUPPLIER_USER:
        case ADD_PARTNER_USER:
            const user = Factory.User(data);
            return [...state.filter(({ id }) => id !== user.id), user];
        case DELETE_PARTNER:
            return state.filter(({ partnerId }) => partnerId !== data);
        case DELETE_CUSTOMER:
            return state.filter(({ customerId }) => customerId !== data);
        case DELETE_SUPPLIER:
            return state.filter(({ supplierId }) => supplierId !== data);
    }
    return state;
};
