import React from "react";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import { SupportEmail } from "@Components/Support/Email";
import { SupportOfficeHours } from "@Components/Support/OfficeHours";
import SupportTel from "@Components/Support/Tel";
import styles from "./index.module.scss";
export default class NeedHelp extends React.Component {
    render() {
        return (React.createElement(ColouredBorderBox, { title: "Need Help?" },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.content },
                    React.createElement("div", null,
                        "Office hours: ",
                        React.createElement(SupportOfficeHours, null)),
                    React.createElement("div", null,
                        "Phone: ",
                        React.createElement(SupportTel, null)),
                    React.createElement("div", null,
                        "Email: ",
                        React.createElement(SupportEmail, null))))));
    }
}
