import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export const migrate = (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [version1].reduce((converted, f) => f(converted), original);
    let { low, high, userId } = converted;
    low = 150;
    high = 700;
    return {
        ...baseMigrated,
        low,
        high,
        userId,
    };
};
