import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./index.module.scss";
export default class Button extends React.Component {
    static defaultProps = {
        className: "",
        cta: false,
        dark: false,
        red: false,
        disabled: false,
        square: false,
        left: false,
        right: false,
        ctaCenter: false,
        blueCenter: false,
        ctaRight: false,
        arrow: false,
        type: "button",
        click: () => { },
    };
    click = () => {
        this.props.click();
    };
    getStyle = () => {
        const { props } = this;
        switch (true) {
            case props.cta:
                return styles.cta;
            case props.dark:
                return styles.dark;
            case props.red:
                return styles.red;
            case props.light:
                return styles.light;
            case props.square:
                return styles.square;
            case props.left:
                return styles.left;
            case props.right:
                return styles.right;
            case props.ctaCenter:
                return styles.ctaCenter;
            case props.blueCenter:
                return styles.blueCenter;
            case props.ctaRight:
                return styles.ctaRight;
        }
    };
    render() {
        const { className } = this.props;
        const style = this.getStyle();
        return (React.createElement("button", { disabled: this.props.disabled, type: this.props.type, onClick: this.click, className: `${className} ${styles.button} ${style}` },
            this.props.children,
            this.props.arrow && (React.createElement(FontAwesomeIcon, { icon: faArrowRight, className: "arrow" }))));
    }
}
