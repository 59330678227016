import dayjs from "dayjs";
import { decodeToken } from "@Api/Auth";
import { settable } from "@Shared/Helpers/Util/Debounce";
import { refresh, SET_TOKEN } from "@Store/Actions/Auth";
const setRefresh = settable((dispatch) => {
    refresh(dispatch);
});
export default (storeAPI) => (next) => (action) => {
    const state = next(action); // Store is updated
    if (action.type === SET_TOKEN) {
        const { exp } = decodeToken(action.data);
        setRefresh((exp - 30 - dayjs.utc().unix()) * 1000, storeAPI.dispatch);
    }
    return state;
};
