import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { total as arrayTotal } from "@Shared/Helpers/Array";
import { apac, eu, row, usaAndCanada, } from "@SmartComponents/Proposal/Oob/DisplayTable/countries";
export default (usageBreakdown) => {
    const { stats } = usageBreakdown;
    const iddData = getIDDData(usageBreakdown);
    const roamingData = getRoamingData(usageBreakdown);
    return {
        //Uk Table
        [AllowanceTypes["UK Data (GB)"]]: stats ? getTotal(stats.UK.Data) : 0,
        [AllowanceTypes["Std Calls (mins)"]]: stats
            ? getTotal(stats.UK["Standard Calls"])
            : 0,
        [AllowanceTypes["Std SMS"]]: stats
            ? getTotal(stats.UK["Standard SMS"])
            : 0,
        [AllowanceTypes["Biz rate Calls (mins)"]]: stats
            ? getTotal(stats.UK["Business Rate Calls"])
            : 0,
        [AllowanceTypes["Prem Calls"]]: stats
            ? getTotal(stats.UK["Premium Calls"])
            : 0,
        [AllowanceTypes["Prem SMS"]]: stats
            ? getTotal(stats.UK["Premium SMS"])
            : 0,
        [AllowanceTypes["Std MMS"]]: stats
            ? getTotal(stats.UK["Standard MMS"])
            : 0,
        [AllowanceTypes["Dir Enq"]]: stats
            ? getTotal(stats.UK["Directory Calls"])
            : 0,
        [AllowanceTypes["Free Calls (mins)"]]: stats
            ? getTotal(stats.UK["Free Calls"])
            : 0,
        //idd table
        [AllowanceTypes["UK IDD to EU (mins)"]]: Object.keys(iddData).length > 0 ? iddData.EU : 0,
        [AllowanceTypes["UK IDD to USA & Canada (mins)"]]: iddData["USA & Canada"],
        [AllowanceTypes["UK IDD to APAC (mins)"]]: Object.keys(iddData).length > 0 ? iddData.APAC : 0,
        [AllowanceTypes["UK IDD to ROW (mins)"]]: Object.keys(iddData).length > 0 ? iddData.ROW : 0,
        [AllowanceTypes["Int SMS"]]: stats ? getTotal(stats.IDD.SMS.total) : 0,
        [AllowanceTypes["Int MMS"]]: stats ? getTotal(stats.IDD.MMS.total) : 0,
        //eu roaming table
        [AllowanceTypes["EU Data (GB)"]]: (roamingData.Data || {}).eu || 0,
        [AllowanceTypes["EU Calls (mins)"]]: (roamingData.Call || {}).eu || 0,
        [AllowanceTypes["EU SMS"]]: (roamingData.SMS || {}).eu || 0,
        [AllowanceTypes["EU MMS"]]: (roamingData.MMS || {}).eu || 0,
        //non eu roaming table
        [AllowanceTypes["Non-EU Data (GB)"]]: (roamingData.Data || {}).nonEU || 0,
        [AllowanceTypes["Non-EU Calls (mins)"]]: (roamingData.Call || {}).nonEU || 0,
        [AllowanceTypes["Non-EU SMS"]]: (roamingData.SMS || {}).nonEU || 0,
        [AllowanceTypes["Non-EU MMS"]]: (roamingData.MMS || {}).nonEU || 0,
    };
};
const getIDDData = (breakdown) => {
    const { stats } = breakdown;
    const euList = eu;
    const usaList = usaAndCanada;
    const apacList = apac;
    const rowList = row;
    const iddDestinations = stats?.IDD?.Call?.destination || {};
    return Object.keys(iddDestinations).reduce((object, key) => {
        if (euList.includes(key)) {
            object.EU += getTotal(iddDestinations[key]);
        }
        if (usaList.includes(key)) {
            object["USA & Canada"] += getTotal(iddDestinations[key]);
        }
        if (apacList.includes(key)) {
            object.APAC += getTotal(iddDestinations[key]);
        }
        if (rowList.includes(key)) {
            object.ROW += getTotal(iddDestinations[key]);
        }
        return object;
    }, {
        EU: 0,
        ["USA & Canada"]: 0,
        APAC: 0,
        ROW: 0,
    });
};
const getRoamingData = (breakdown) => {
    const { stats } = breakdown;
    const euList = eu;
    return stats
        ? Object.keys(stats.Roaming).reduce((object, type) => {
            if (stats.Roaming[type].origin !== undefined && type !== "Days") {
                object[type] = Object.keys(stats.Roaming[type].origin).reduce((object_, country) => {
                    let numberMonths = 3;
                    const countryTotal = Object.values(stats.Roaming[type].origin[country]).reduce((total, months) => {
                        numberMonths = Object.keys(months).length;
                        return (arrayTotal(Object.values(months)) + total);
                    }, 0) / numberMonths;
                    if (euList.includes(country)) {
                        object_.eu = object_.eu + countryTotal;
                    }
                    else {
                        object_.nonEU = object_.nonEU + countryTotal;
                    }
                    return object_;
                }, { eu: 0, nonEU: 0 });
            }
            return object;
        }, {})
        : {};
};
const getTotal = (object = {}) => {
    return arrayTotal(Object.values(object)) / 3;
};
