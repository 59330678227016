import { addNote, addUsage as addUsageApi, approve, changeRequirementsPut, findForSupplier, finish, get, getForPartner, getForSupplier, updateStartDate, uploadCustomerDocument, uploadSupplierDocument, } from "@Api/AwardedContracts";
export const ADD_AWARDED_CONTRACT = "addAwardedContract";
const addAwardedContract = (dispatch) => (data) => {
    dispatch({
        type: ADD_AWARDED_CONTRACT,
        data,
    });
    return data;
};
export const fetchAwardedContracts = (dispatch) => get(addAwardedContract(dispatch));
export const fetchAwardedContractsForSupplier = (dispatch, supplierId) => getForSupplier(addAwardedContract(dispatch), supplierId);
export const fetchAwardedContractForSupplier = (dispatch, supplierId, awardedContractId) => findForSupplier(supplierId, awardedContractId).then(addAwardedContract(dispatch));
export const setStartDate = (dispatch, supplierId, awardedContractId, startDate) => updateStartDate(supplierId, awardedContractId, startDate).then(addAwardedContract(dispatch));
export const addUsage = (dispatch, awardedContractId, data) => addUsageApi(awardedContractId, data).then(addAwardedContract(dispatch));
export const finishContracting = (dispatch, customerId, awardedContractId) => finish(customerId, awardedContractId).then(addAwardedContract(dispatch));
export const approveAwardedContract = (dispatch, awardedContractId, noEmail) => approve(awardedContractId, noEmail).then(addAwardedContract(dispatch));
export const addNoteToAwardedContract = (dispatch, awardedContractId, note) => addNote(awardedContractId, note).then(addAwardedContract(dispatch));
export const changeRequirements = (dispatch, supplierId, awardedContractId, requirements) => changeRequirementsPut(supplierId, awardedContractId, requirements).then(addAwardedContract(dispatch));
export const uploadDocumentSupplier = (dispatch, supplierId, awardedContractId, files, reupload) => uploadSupplierDocument(supplierId, awardedContractId, files, reupload).then(addAwardedContract(dispatch));
export const uploadDocumentCustomer = (dispatch, customerId, awardedContractId, files, noEmail = false) => uploadCustomerDocument(customerId, awardedContractId, files, noEmail).then(addAwardedContract(dispatch));
export const fetchAwardedContractsForPartner = (dispatch, partnerId) => getForPartner(addAwardedContract(dispatch), partnerId);
