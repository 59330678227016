import EEIcon from "@Components/Icons/Networks/EE";
import EEPlanIcon from "@Components/Icons/Networks/EEPlan.com";
import None from "@Components/Icons/Networks/None";
import O2Icon from "@Components/Icons/Networks/O2";
import O2PlanIcon from "@Components/Icons/Networks/O2Plan.com";
import PlanIcon from "@Components/Icons/Networks/Plan.com";
import ThreeIcon from "@Components/Icons/Networks/Three";
import VodafoneWithoutTextIcon from "@Components/Icons/Networks/VodafoneWithoutText";
import { BillingEngine, Network } from "@Shared/Entities/Customer/Usage/enums";
import { Platform } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import React from "react";
export const getIconFromNetwork = (network) => {
    switch (network) {
        case Network.Vodafone:
            return React.createElement(VodafoneWithoutTextIcon, null);
        case Network.EE:
            return React.createElement(EEIcon, { size: "2.5rem" });
        case Network.O2:
            return React.createElement(O2Icon, null);
        case Network["plan.com"]:
            return React.createElement(PlanIcon, null);
        case Network.Three:
            return React.createElement(ThreeIcon, null);
        case Network.None:
            return React.createElement(None, null);
        default:
            return React.createElement(React.Fragment, null, "---");
    }
};
export const getIconFromPlatform = (platform) => {
    switch (platform) {
        case Platform.O2:
            return O2Icon;
        case Platform["O2 - Plan.com"]:
            return O2PlanIcon;
        case Platform.EE_Small_Business:
        case Platform.EE_Corporate:
        case Platform.EE_Teams:
            return EEIcon;
        case Platform["EE - Plan.com"]:
            return EEPlanIcon;
        case Platform.Vodafone_VCO:
        case Platform.Vodafone:
            return VodafoneWithoutTextIcon;
        case Platform.MVNO_Three:
            return ThreeIcon;
    }
};
export const getNetworkFromPlatform = (platform) => {
    switch (platform) {
        case Platform.O2:
        case Platform["O2 - Plan.com"]:
            return Network.O2;
        case Platform.EE_Small_Business:
        case Platform.EE_Corporate:
        case Platform.EE_Teams:
        case Platform["EE - Plan.com"]:
            return Network.EE;
        case Platform.Vodafone_VCO:
        case Platform.Vodafone:
            return Network.Vodafone;
        case Platform.MVNO_Three:
            return Network.Three;
    }
};
export const getIconFromBillingEngine = (engine) => {
    switch (engine) {
        case BillingEngine.O2:
            return O2Icon;
        case BillingEngine["O2 - Plan.com"]:
            return O2PlanIcon;
        case BillingEngine.EE_Mobile_Manager:
        case BillingEngine.EE_Teams:
            return EEIcon;
        case BillingEngine["EE - Plan.com"]:
            return EEPlanIcon;
        case BillingEngine.Vodafone_VCO:
        case BillingEngine.Vodafone:
            return VodafoneWithoutTextIcon;
        case BillingEngine.MVNO_Three:
            return ThreeIcon;
    }
};
