export default (template, ...replacements) => {
    return replacements.reduce((path, replacement) => path.replace(/:[^/]*/, replacement), template);
};
export const GetReplacements = (template, path) => {
    if (!template.includes(":")) {
        return [];
    }
    const regString = template
        .replaceAll("/", "\\/")
        .replaceAll(/:[^/]+/g, "([^/]+)")
        .replaceAll(/\w+/g, "[^/]+");
    const regex = new RegExp(regString, "");
    let m;
    if ((m = regex.exec(path)) !== null) {
        return m.slice(1);
    }
    return [];
};
