import React from "react";
import Exit from "@Components/Icons/Exit";
import styles from "./index.module.scss";
export default class Tag extends React.Component {
    remove = (events) => {
        events.stopPropagation();
        this.props.remove(this.props.text);
    };
    render() {
        return (React.createElement("span", { className: `
            ${styles.tag} 
            ${this.props.chat ? styles.chat : ""}
            
            ` },
            React.createElement("span", null, this.props.text),
            React.createElement("button", { type: "button", onClick: this.remove, className: styles.button },
                React.createElement(Exit, { dark: true, size: "0.8em" }))));
    }
}
