import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import HeaderComp from "@Components/Header";
import IsPathMatch from "@Helpers/IsPathMatch";
import { CREATE_TENDER, CREATE_TENDER_WITH_USAGE, CREATE_USER, EDIT_TENDER, EDIT_USER, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, NOT_FOUND, ROOT, TENDER, TENDERS, USAGE, USAGES, USER, USERS, VIEW_RESPONSES, } from "@Shared/Paths/Customer";
const Header = ({ tenders }) => {
    const location = useLocation();
    // Hide header when user clicks View Responses
    if (IsPathMatch(location.pathname, VIEW_RESPONSES)) {
        return React.createElement(React.Fragment, null);
    }
    const getHeader = () => {
        const { pathname } = useLocation();
        const regex = /\/tenders\/(?<tenderId>[^ $]*)\/edit/;
        const { groups } = regex.exec(pathname) || {};
        const { tenderId } = groups || {};
        const tender = tenders.find(({ id }) => id === tenderId);
        if (tender?.usageId === undefined) {
            return "Welcome to Predict, the Comparison Platform for Business Mobile";
        }
        const percentage = tender.stats?.percentage || 0;
        if (percentage < 95) {
            return "Customers save up to 50% on contract cost";
        }
        return "Congratulations on using Predict, the UK’s favourite B2B Mobile Comparison Platform";
    };
    return (React.createElement(HeaderComp, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: ROOT, exact: true }, "Dashboard"),
            React.createElement(Route, { path: USAGES },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: USAGES, exact: true }, "Sensabill"),
                    React.createElement(Route, { path: USAGE },
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: USAGE, exact: true }, "Sensabill - Analysis, Prediction, Control"))))),
            React.createElement(Route, { path: MY_ACCOUNT }, "My Account"),
            React.createElement(Route, { path: MY_ORGANISATION },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: MY_ORGANISATION, exact: true }, "My Organisation"),
                    React.createElement(Route, { path: USERS },
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: USERS, exact: true }, "Users"),
                            React.createElement(Route, { path: CREATE_USER }, "Create User"),
                            React.createElement(Route, { path: USER },
                                React.createElement(Switch, null,
                                    React.createElement(Route, { path: USER, exact: true }, "User"),
                                    React.createElement(Route, { path: EDIT_USER }, "Edit User"))))))),
            React.createElement(Route, { path: TENDERS },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: TENDERS, exact: true }, "Tenders"),
                    React.createElement(Route, { path: CREATE_TENDER },
                        React.createElement(Route, { path: CREATE_TENDER, exact: true }, "Create Tender"),
                        React.createElement(Route, { path: CREATE_TENDER_WITH_USAGE }, "Create Tender with Usage")),
                    React.createElement(Route, { path: TENDER },
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: TENDER, exact: true }, "Your Tender Details"),
                            React.createElement(Route, { path: EDIT_TENDER, exact: true }, getHeader()),
                            React.createElement(Route, { path: VIEW_RESPONSES, exact: true }, "Tender Responses"))))),
            React.createElement(Route, { path: LOGOUT }),
            React.createElement(Route, { path: NOT_FOUND }, "404"))));
};
const mapStateToProps = ({ customers: { tenders = [] } }) => {
    return {
        tenders,
    };
};
export default connect(mapStateToProps)(Header);
