import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
export const getBrokerName = (asOwner = false) => {
    const broker = getBrokerDomain();
    let name = "";
    switch (broker) {
        case BrokerDomain.ERA:
            name = "ERA";
            break;
        case BrokerDomain.TechMahindra:
            name = "Tech Mahindra";
            break;
        case BrokerDomain.SevenStepSolutions:
            name = "Seven Step Solutions";
            break;
        case BrokerDomain.Bionic:
            name = "Bionic";
            break;
        case BrokerDomain.BDO:
            name = "BDO";
            break;
        case BrokerDomain.Protel:
            name = "Protel";
            break;
        default:
            name = "Predict";
            break;
    }
    if (asOwner) {
        name = `${name}'${name.endsWith("s") ? "" : "s"}`;
    }
    //change spaces to non-breaking spaces
    return name.replaceAll(" ", "\u00A0");
};
export default getBrokerName;
