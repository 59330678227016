import SVG from "@Components/Icons/SVG";
export default class Fingerprint extends SVG {
    path = [
        "M48.89 44.18",
        "C48 44.43 47.46 45.39 47.75 46.29",
        "C47.79 46.43 52.32 62.57 51.07 98.36",
        "C51.04 99.29 51.79 100.07 52.71 100.11",
        "C52.75 100.11 52.75 100.11 52.79 100.11",
        "C53.68 100.11 54.43 99.39 54.46 98.46",
        "C55.71 62.14 51.21 46 51 45.36",
        "C50.75 44.43 49.82 43.93 48.89 44.18",
        "Z",
        "M91.57 27.96",
        "C84.36 10.96 67.82 0 49.39 0",
        "C43.32 0 37.36 1.18 31.75 3.54",
        "C28.64 4.82 25.71 6.39 23 8.11",
        "C22.21 8.61 22 9.68 22.5 10.46",
        "C23 11.25 24.07 11.46 24.86 10.96",
        "C27.36 9.32 30.14 7.89 33.04 6.68",
        "C38.25 4.5 43.71 3.39 49.36 3.39",
        "C66.43 3.39 81.75 13.54 88.43 29.29",
        "C91.18 35.82 92.68 47.86 93.32 54.46",
        "C93.39 55.36 94.14 56 95 56",
        "C95.07 56 95.11 56 95.18 56",
        "C96.11 55.89 96.79 55.07 96.71 54.14",
        "C95.89 45.82 94.36 34.57 91.57 27.96",
        "Z",
        "M17.96 16.46",
        "C18.64 15.82 18.64 14.75 17.96 14.07",
        "C17.32 13.39 16.25 13.39 15.57 14.07",
        "C6.46 23.07-2.64 39.79 5.86 68.36",
        "C6.07 69.11 6.75 69.57 7.5 69.57",
        "C7.64 69.57 7.82 69.54 8 69.5",
        "C8.89 69.25 9.39 68.29 9.14 67.39",
        "C2.79 46.04 5.86 28.43 17.96 16.46",
        "Z",
        "M50.68 10.21",
        "C45.5 10 40.43 10.96 35.68 12.93",
        "C19.04 19.86 10.21 38.14 15.11 55.46",
        "C15.61 57.18 16.11 59.04 16.36 60.86",
        "C16.96 65 17 72.64 17 84.79",
        "C17 85.71 17.75 86.46 18.68 86.46",
        "S20.36 85.71 20.36 84.79",
        "C20.36 72.14 20.32 64.75 19.68 60.36",
        "C19.39 58.32 18.86 56.36 18.32 54.5",
        "C13.93 38.89 21.93 22.32 36.96 16.07",
        "C41.29 14.29 45.89 13.46 50.57 13.61",
        "C62.64 14.04 76.32 24.68 80.43 36.82",
        "C84 47.32 84.04 65.25 83.57 81.39",
        "C83.54 82.32 84.29 83.11 85.21 83.14",
        "C86.14 83.14 86.93 82.43 86.96 81.5",
        "C87.43 65.07 87.36 46.79 83.64 35.75",
        "C79.04 22.14 64.25 10.68 50.68 10.21",
        "Z",
        "M49.39 20.36",
        "C47.54 20.36 45.71 20.57 43.93 20.96",
        "C43 21.18 42.43 22.07 42.64 23",
        "C42.86 23.93 43.75 24.46 44.68 24.29",
        "C46.21 23.96 47.82 23.79 49.39 23.79",
        "C58.29 23.79 66.25 29.07 69.71 37.25",
        "C73.82 47 74.11 62.61 73.14 89.89",
        "C73.11 90.82 73.86 91.61 74.79 91.64",
        "C74.82 91.64 74.82 91.64 74.86 91.64",
        "C75.75 91.64 76.5 90.93 76.54 90",
        "C77.54 61.64 77.21 46.32 72.82 35.93",
        "C68.82 26.46 59.61 20.36 49.39 20.36",
        "Z",
        "M36.79 27.71",
        "C37.54 27.18 37.75 26.11 37.21 25.36",
        "C36.68 24.61 35.61 24.39 34.86 24.93",
        "C26.11 31.07 22.29 42.86 25.39 54.29",
        "C25.43 54.39 28.86 66.93 28.86 86.54",
        "C28.86 87.46 29.61 88.21 30.54 88.21",
        "S32.21 87.46 32.21 86.54",
        "C32.21 66.5 28.75 53.93 28.61 53.39",
        "C25.93 43.36 29.21 33.04 36.79 27.71",
        "Z",
        "M66.18 81.43",
        "C66.18 80.5 65.43 79.71 64.5 79.71",
        "C64.5 79.71 64.5 79.71 64.5 79.71",
        "C63.57 79.71 62.82 80.46 62.82 81.39",
        "C62.82 82.68 62.86 84.54 62.89 86.54",
        "C62.96 89.46 63.04 92.79 62.96 94.89",
        "C62.93 95.82 63.68 96.61 64.61 96.64",
        "C64.64 96.64 64.64 96.64 64.68 96.64",
        "C65.57 96.64 66.32 95.93 66.36 95",
        "C66.43 92.79 66.36 89.39 66.29 86.43",
        "C66.21 84.5 66.18 82.68 66.18 81.43",
        "Z",
        "M49.39 32.21",
        "C47.61 32.21 45.82 32.57 44.18 33.25",
        "C37.57 36 34.36 43.46 36.71 50.57",
        "C38 54.46 39.07 74.79 39.07 94.96",
        "C39.07 95.89 39.82 96.64 40.75 96.64",
        "S42.43 95.89 42.43 94.96",
        "C42.43 76.79 41.46 54.25 39.89 49.5",
        "C38.11 44.07 40.5 38.43 45.43 36.36",
        "C46.68 35.82 48 35.57 49.36 35.57",
        "C53.46 35.57 57.14 38 58.75 41.79",
        "C61.93 49.29 62.86 62.86 63.07 72.93",
        "C63.11 73.86 63.86 74.57 64.75 74.57",
        "C64.75 74.57 64.79 74.57 64.79 74.57",
        "C65.71 74.54 66.46 73.79 66.43 72.82",
        "C66.18 62.46 65.21 48.43 61.82 40.43",
        "C59.75 35.46 54.86 32.21 49.39 32.21",
        "Z",
    ];
}
