import { collection } from "@Api/helpers";
import api from "@Api/index";
import { uploadFilesWithSignedUrl } from "@Api/Registration";
import Factory from "@Shared/Factory";
export const get = (callback) => {
    const url = `/awarded-contracts`;
    return collection((data) => {
        return callback(Factory.AwardedContract(data));
    }, url);
};
// Customer Actions
export const createAwardedContract = (customerId, tenderId, responseId) => {
    const url = `/awarded-contracts`;
    return api
        .post(url, { customerId, tenderId, responseId })
        .then((result) => {
        return result.data.item;
    });
};
export const getForCustomer = (callback, customerId) => {
    const url = `/customer/${customerId}/awarded-contracts`;
    return collection((data) => {
        return callback(Factory.AwardedContract(data));
    }, url);
};
export const finish = (customerId, awardedContractId) => {
    const url = `customers/${customerId}/awarded-contracts/${awardedContractId}/finish`;
    return api.put(url).then(({ data }) => {
        return Factory.AwardedContract(data);
    });
};
// Supplier Actions
export const getForSupplier = (callback, supplierId) => {
    const url = `/suppliers/${supplierId}/awarded-contracts`;
    return collection((data) => {
        return callback(Factory.AwardedContract(data));
    }, url);
};
export const findForSupplier = (supplierId, awardedContractId) => {
    const url = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}`;
    return api.get(url).then(({ data }) => {
        return Factory.AwardedContract(data);
    });
};
export const changeRequirementsPut = (supplierId, awardedContractId, requirements) => {
    const url = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}/requirements`;
    return api.put(url, { requirements }).then(({ data }) => {
        return Factory.AwardedContract(data);
    });
};
export const uploadSupplierDocument = async (supplierId, awardedContractId, files, reupload) => {
    const url = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}/upload`;
    const fileDetails = files.map((file) => ({
        filename: file.name,
        s3Filename: `supplierContracts/${awardedContractId}-${supplierId}-${file.name}`,
        mime: file.type,
    }));
    const { data } = await api.post(url, { fileDetails });
    const { filesWithUrl } = data;
    await uploadFilesWithSignedUrl(filesWithUrl, files);
    let urlUploaded = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}/uploaded`;
    if (reupload) {
        urlUploaded = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}/reupload`;
    }
    const uploadedResponse = await api.post(urlUploaded, { fileDetails });
    return Factory.AwardedContract(uploadedResponse.data);
};
export const uploadCustomerDocument = async (customerId, awardedContractId, files, noEmail) => {
    const url = `/customers/${customerId}/awarded-contracts/${awardedContractId}/upload`;
    const fileDetails = files.map((file) => ({
        filename: file.name,
        s3Filename: `signedContracts/${awardedContractId}-${customerId}-${file.name}`,
        mime: file.type,
    }));
    const { data } = await api.post(url, { fileDetails });
    const { filesWithUrl } = data;
    await uploadFilesWithSignedUrl(filesWithUrl, files);
    const urlUploaded = `/customers/${customerId}/awarded-contracts/${awardedContractId}/uploaded`;
    const uploadedResponse = await api.post(urlUploaded, {
        fileDetails,
        noEmail,
    });
    return Factory.AwardedContract(uploadedResponse.data);
};
export const updateStartDate = (supplierId, awardedContractId, startDate) => {
    const url = `/suppliers/${supplierId}/awarded-contracts/${awardedContractId}/start-date`;
    return api.put(url, { startDate }).then(({ data }) => {
        return Factory.AwardedContract(data);
    });
};
// Admin actions
export const approve = (awardedContractId, noEmail) => {
    const url = `/awarded-contracts/${awardedContractId}/approve`;
    return api.put(url, { noEmail }).then(({ data }) => {
        return Factory.AwardedContract(data);
    });
};
export const addNote = (awardedContractId, note) => {
    const url = `/awarded-contracts/${awardedContractId}/note`;
    return api
        .put(url, { note })
        .then(({ data }) => Factory.AwardedContract(data));
};
export const addUsage = (awardedContractId, usage) => {
    const url = `/awarded-contracts/${awardedContractId}/usage`;
    return api.post(url, usage).then(({ data }) => data.item);
};
export const downloadCustomerDocuments = (awardedContractId, customerId) => {
    const url = `customers/${customerId}/awarded-contracts/${awardedContractId}/download`;
    return api.post(url).then((result) => {
        return result.data;
    });
};
export const downloadSupplierDocuments = (awardedContractId, supplierId) => {
    const url = `suppliers/${supplierId}/awarded-contracts/${awardedContractId}/download`;
    return api.post(url).then((result) => {
        return result.data;
    });
};
export const getForPartner = (callback, partnerId) => {
    const url = `/partners/${partnerId}/awarded-contracts`;
    return collection((data) => {
        return callback(Factory.AwardedContract(data));
    }, url);
};
export const getPreviousUsageIframe = (awardedContractId) => {
    const url = `/awarded-contracts/${awardedContractId}/previous-usage/iframe`;
    return api.get(url).then((result) => result.data);
};
export const getUsageIframe = (awardedContractId) => {
    const url = `/awarded-contracts/${awardedContractId}/usage/iframe`;
    return api.get(url).then((result) => result.data);
};
