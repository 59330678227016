import { DMFeatures, Effected, MDMOS, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const dmPresenceConstraints = {
    [Effected.OfferDM]: {
        presence: true,
    },
    [Effected.DMTool]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        length: { minimum: 1 },
    },
    [Effected.DMOS]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
    },
    [Effected.DMFeatures]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        length: {
            minimum: 1,
        },
    },
    [Effected.DMFree]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
    },
    [Effected.DMLineCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMFree, value: true, inverted: true },
        ],
    },
    [Effected.DMMinCommitmentPeriod]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
    },
    [Effected.DMMinCommitmentPeriodOther]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMMinCommitmentPeriod, value: TimePeriods.Other },
        ],
    },
    [Effected.DMRange]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
    },
    [Effected.DMEnterprise]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMRange, value: true },
        ],
    },
};
export const dmValidConstraints = {
    [Effected.OfferDM]: {
        presence: true,
        type: "boolean",
    },
    [Effected.DMTool]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        type: "string",
        length: { minimum: 1 },
    },
    [Effected.DMOS]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        enum: MDMOS,
    },
    [Effected.DMFeatures]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        enumArray: DMFeatures,
        length: {
            minimum: 1,
        },
    },
    [Effected.DMFree]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        type: "boolean",
    },
    [Effected.DMLineCost]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMFree, value: true, inverted: true },
        ],
        type: "number",
    },
    [Effected.DMMinCommitmentPeriod]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        enum: TimePeriods,
    },
    [Effected.DMMinCommitmentPeriodOther]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMMinCommitmentPeriod, value: TimePeriods.Other },
        ],
        type: "number",
    },
    [Effected.DMRange]: {
        conditionalPresenceMatchAll: [{ field: Effected.OfferDM, value: true }],
        type: "boolean",
    },
    [Effected.DMEnterprise]: {
        conditionalPresenceMatchAll: [
            { field: Effected.OfferDM, value: true },
            { field: Effected.DMRange, value: true },
        ],
        type: "boolean",
    },
    [Effected.DMAlternative]: {
        type: "string",
    },
};
