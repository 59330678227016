import React from "react";
import { connect } from "react-redux";
import Loading from "@Components/Icons/Loading";
import { Status } from "@Shared/Entities/Supplier/Response/enums";
import { fetchTendersForSupplier } from "@Store/Actions/Customers/tenders";
import { addNotification } from "@Store/Actions/Notifications";
import { deleteResponse, fetchResponses, } from "@Store/Actions/Suppliers/responses";
import styles from "../index.module.scss";
class DashboardStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        this.props
            .fetchResponses(this.props.user.supplierId)
            .then(() => {
            this.props.fetchTenders(this.props.user.supplierId);
        })
            .then(() => {
            this.setState({ fetching: false });
        });
    }
    render() {
        if (this.state.fetching) {
            return (React.createElement("div", { className: styles.loadingWrapper },
                React.createElement(Loading, { size: "3rem", className: styles.loading })));
        }
        const unfinishedResponses = this.props.responses.filter(({ status }) => status === Status.Draft).length;
        const newTenders = this.props.tenders.filter(({ id }) => !this.props.responses.some(({ tenderId }) => tenderId === id)).length;
        const wonTenders = this.props.responses.filter(({ awarded }) => awarded === true).length;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.list },
                "Responses in progress",
                React.createElement("div", { className: styles.text }, unfinishedResponses)),
            React.createElement("div", { className: styles.list },
                "New suitable tenders",
                React.createElement("div", { className: styles.text }, newTenders)),
            React.createElement("div", { className: styles.list },
                "Tenders won",
                React.createElement("div", { className: styles.text }, wonTenders))));
    }
}
const mapStateToProps = ({ auth: { user }, suppliers: { responses }, customers: { tenders }, }) => ({
    user,
    responses,
    tenders,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetchResponses: (supplierId) => fetchResponses(dispatch, supplierId),
        fetchTenders: (supplierId) => fetchTendersForSupplier(dispatch, supplierId),
        delete: (response) => deleteResponse(dispatch, response),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardStats);
