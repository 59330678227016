import React from "react";
import styles from "./index.module.scss";
export default class HealthScore extends React.Component {
    getHealth = (score) => {
        switch (score) {
            case "veryGood":
                return "Best";
            case "good":
                return "Good";
            case "medium":
                return "Med.";
            case "bad":
                return "Poor";
            // case "veryBad":
            default:
                return "Worst";
        }
    };
    getHealthStyle = (score) => {
        switch (score) {
            case "veryGood":
                return styles.veryGood;
            case "good":
                return styles.good;
            case "medium":
                return styles.medium;
            case "bad":
                return styles.bad;
            // case "veryBad":
            default:
                return styles.veryBad;
        }
    };
    render() {
        const { score, place, small = false } = this.props;
        const text = score !== undefined && score > 0 ? `${score}%` : React.createElement(React.Fragment, null);
        return (React.createElement("div", { className: `
                ${styles.badge} 
                ${this.getHealthStyle(place)}
                ${small ? styles.small : ""}
                ` }, text));
    }
}
