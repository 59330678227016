import React from "react";
import Button from "@Components/Button";
import NoDocument from "@Components/Icons/NoDocument";
import Auto from "./Auto";
import styles from "./index.module.scss";
import Manual from "./Manual";
export default class UsageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
    }
    render() {
        const auto = React.createElement(Auto, { submit: this.props.submitPlatform });
        const manual = (React.createElement(Manual, { submit: this.props.submitFiles, bottomSlot: React.createElement(Button, { arrow: true, cta: true, click: this.props.close }, "CONTINUE WITHOUT BILL REPORTS") }));
        const noData = (React.createElement("div", { className: `${styles.card} ${styles.mainCard}` },
            React.createElement("div", { className: styles.titleContainer },
                React.createElement("div", { className: styles.icon },
                    React.createElement(NoDocument, null)),
                React.createElement("div", { className: styles.title }, "No mobile bill reports")),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.middle },
                    React.createElement("div", { className: styles.body },
                        React.createElement("div", { className: styles.title }, "No data, no problem"),
                        React.createElement("div", { className: styles.subtitle }, "You can start now and upload data later"),
                        React.createElement("div", { className: styles.button },
                            React.createElement(Button, { click: this.props.submitNoUsage, arrow: true }, "CREATE YOUR TENDER")))))));
        const grey = this.props.grey ? styles.grey : "";
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.option }, auto),
            this.props.beside !== true && (React.createElement("div", { className: styles.lineSeparator },
                React.createElement("div", { className: `${styles.line} ${grey}` },
                    React.createElement("div", { className: styles.orCircle },
                        React.createElement("div", { className: styles.text }, "OR"))))),
            React.createElement("div", { className: styles.option }, manual),
            !this.props.dataRequired && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.lineSeparator },
                    React.createElement("div", { className: `${styles.line} ${grey}` },
                        React.createElement("div", { className: styles.orCircle },
                            React.createElement("div", { className: styles.text }, "OR")))),
                React.createElement("div", { className: styles.option }, noData)))));
    }
}
