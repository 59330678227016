export const ADD_COMPARISON = "addComparison";
export const addComparison = (dispatch, responseId) => {
    dispatch({
        type: ADD_COMPARISON,
        data: responseId,
    });
};
export const DELETE_COMPARISON = "deleteComparison";
export const deleteComparison = (dispatch, responseId) => {
    dispatch({
        type: DELETE_COMPARISON,
        data: responseId,
    });
};
