import React from "react";
import { generate as uuidv4 } from "short-uuid";
import Button from "@Components/Button";
import Add from "@Components/Icons/Add";
import Remove from "@Components/Icons/Remove";
import styles from "./index.module.scss";
export default class RepeatGroup extends React.Component {
    static defaultProps = {
        chat: false,
        repeatText: "Add new item",
        data: [],
        defaultObj: {},
    };
    constructor(props) {
        super(props);
        const { data = [] } = props;
        const rows = this.giveRowIDs(data);
        this.state = {
            rows,
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.data.length !== this.props.data.length) {
            const rows = this.giveRowIDs(this.props.data);
            this.setState({ rows });
        }
    }
    giveRowIDs = (data = []) => {
        const numberRows = Math.max(data.length, 1);
        const rows = [];
        for (let index = 0; index < numberRows; index++) {
            rows.push(uuidv4());
        }
        return rows;
    };
    addItem = () => {
        const { rows } = this.state;
        rows.push(uuidv4());
        this.setState({
            rows,
        });
    };
    updateData = ({ value, name }, index) => {
        const { data = [], defaultObj: defaultObject = {} } = this.props;
        const object = data[index] || defaultObject;
        object[name] = value;
        data[index] = object;
        this.props.change(data);
    };
    removeItem = (index) => () => {
        const { data = [] } = this.props;
        data.splice(index, 1);
        this.props.change(data);
        const { rows } = this.state;
        rows.splice(index, 1);
        this.setState({
            rows,
        });
    };
    removeAllItems = () => {
        const { data = [] } = this.props;
        data.splice(0, 100);
        this.props.change(data);
        const { rows } = this.state;
        rows.splice(0, 100);
        this.setState({
            rows,
        });
    };
    getItems = () => {
        return this.state.rows.map((key, index) => {
            const { data = [], error = [], defaultObj: defaultObject = {}, } = this.props;
            const inputs = this.props.inputs((object) => this.updateData(object, index), data, error, index, defaultObject);
            return (React.createElement("div", { className: styles.group, key: key },
                inputs.map((component, index_) => (React.createElement("div", { className: styles.input, key: `${key}-${index_}` }, component))),
                this.props.addButton === undefined &&
                    this.state.rows.length > 1 && (React.createElement(Remove, { className: styles.remove, onClick: this.removeItem(index), error: true })),
                this.props.addButton !== undefined && (React.createElement(Remove, { className: styles.remove, onClick: this.removeItem(index), error: true }))));
        });
    };
    render() {
        const items = this.getItems();
        const { chat, repeatButtonRound } = this.props;
        const roundEdges = this.props.roundEdges ? styles.roundEdges : "";
        return (React.createElement("div", { className: `${styles.wrapper} ${chat ? styles.chat : ""}` },
            React.createElement("div", { className: `${styles.items} ${roundEdges} ${this.props.subGroup ? styles.subGroup : ""} ${chat ? styles.chat : ""}` }, items),
            React.createElement("div", { className: styles.addWrapper },
                React.createElement("div", { className: styles.filler }),
                React.createElement("div", { className: `${styles.button} ${this.props.subGroup ? styles.subGroup : ""} ${chat ? styles.chat : ""} ${repeatButtonRound ? styles.round : ""}`, onClick: this.addItem },
                    React.createElement(Add, { className: styles.addIcon, success: true }))),
            this.props.addButton && (React.createElement("div", { className: styles.extraButton },
                React.createElement(Button, { click: this.addItem, square: true }, this.props.addButton)))));
    }
}
