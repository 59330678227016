import React from "react";
import Footer from "@Components/Footer";
import IdleTimeout from "@Components/IdleTimeout";
import PageWrapper from "@Components/Pages/PageWrapper";
import { CONTRACT, CONTRACTS, CREATE_CUSTOMER_TENDER, CUSTOMER_TENDER_COMPARISON, CUSTOMER_TENDER_RESPONSES, CUSTOMER_USAGE, EDIT_CUSTOMER_TENDER, EDIT_SUPPLIER_RESPONSE, } from "@Shared/Paths/Partner";
import Notifications from "../../Notifications";
import Header from "./Header";
import styles from "./index.module.scss";
import Nav from "./Nav";
import Pages from "./Pages";
class Broker extends React.Component {
    render() {
        return (React.createElement(IdleTimeout, null,
            React.createElement("div", { className: styles.container },
                React.createElement(Nav, null),
                React.createElement("div", { className: styles.content },
                    React.createElement(Header, null),
                    React.createElement(PageWrapper, { paths: [
                            EDIT_CUSTOMER_TENDER,
                            CUSTOMER_TENDER_RESPONSES,
                            EDIT_SUPPLIER_RESPONSE,
                            CUSTOMER_TENDER_COMPARISON,
                            CONTRACTS,
                            CUSTOMER_USAGE,
                            CREATE_CUSTOMER_TENDER,
                            CONTRACT,
                        ] },
                        React.createElement(Pages, null)),
                    React.createElement(Footer, null)),
                React.createElement(Notifications, null))));
    }
}
export default Broker;
