export var ScoreType;
(function (ScoreType) {
    ScoreType[ScoreType["Overall"] = 0] = "Overall";
    ScoreType[ScoreType["Price"] = 1] = "Price";
    ScoreType[ScoreType["Tariff"] = 2] = "Tariff";
    ScoreType[ScoreType["Service"] = 3] = "Service";
    ScoreType[ScoreType["Signal"] = 4] = "Signal";
    ScoreType[ScoreType["Convenience"] = 5] = "Convenience";
    ScoreType[ScoreType["Custom"] = 6] = "Custom";
})(ScoreType || (ScoreType = {}));
export const ScoreFieldList = [
    "pmScore",
    "priceScore",
    "tariffScore",
    "csScore",
    "signalScore",
    "convenienceScore",
];
