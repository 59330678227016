import React, { useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./../index.module.scss";
export const DimensionsContext = React.createContext({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
});
export default ({ children, paths = [], }) => {
    const { pathname } = useLocation();
    const pathSections = pathname.split("/");
    let required = paths.length === 0;
    for (const path of paths) {
        required = false;
        const targetSections = path.split("/");
        if (pathSections.length === targetSections.length) {
            for (const [index, pathSection] of pathSections.entries()) {
                if (pathSection !== targetSections[index] &&
                    targetSections[index][0] !== ":") {
                    required = true;
                }
            }
        }
        else {
            required = true;
        }
        if (required === false) {
            break;
        }
    }
    const reference = useRef(null);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
        left: 0,
        top: 0,
    });
    const getDimensions = () => {
        const height = reference?.current?.offsetHeight || 0;
        const width = reference?.current?.offsetWidth || 0;
        const left = reference?.current?.offsetLeft || 0;
        const top = reference?.current?.offsetTop || 0;
        setDimensions({ height, left, top, width });
    };
    useLayoutEffect(() => {
        window.addEventListener("resize", getDimensions);
        getDimensions();
        return () => window.removeEventListener("resize", getDimensions);
    }, []);
    return (React.createElement(DimensionsContext.Provider, { value: dimensions },
        React.createElement("div", { className: `${styles.pageWrapper} ${required ? "" : styles.noPadding}`, ref: reference }, children)));
};
