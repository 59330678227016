import React from "react";
import Pagination from "@Components/Table/TableNav/Pagination";
import Dropdown from "../../Form/Dropdown";
import styles from "./index.module.scss";
const options = [
    { value: 10, label: "10 Rows per Page" },
    { value: 25, label: "25 Rows per Page" },
    { value: 50, label: "50 Rows per Page" },
    { value: 100, label: "100 Rows per Page" },
];
export default class TableNav extends React.Component {
    changePage = (page) => {
        this.props.changePage(page);
    };
    changeRows = ({ option }) => {
        this.props.changeRows(option.value);
    };
    render() {
        const { page = 1, size = 0, rows = 10 } = this.props;
        const pages = Math.ceil(size / rows);
        return (React.createElement("div", { className: styles.navigation },
            React.createElement("div", { className: styles.navBoxes },
                React.createElement(Dropdown, { options: options, value: rows, onChange: this.changeRows })),
            React.createElement("div", { className: styles.navBoxes },
                React.createElement("div", { className: styles.text },
                    "Showing page ",
                    React.createElement("b", null, page),
                    " to ",
                    React.createElement("b", null, pages),
                    " of",
                    " ",
                    React.createElement("b", null, size),
                    " results")),
            React.createElement("div", { className: styles.navBoxes },
                React.createElement(Pagination, { pages: pages, current: page, changePage: this.changePage }))));
    }
}
