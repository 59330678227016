import Factory from "@Shared/Factory";
import { ADD_DEVICE, BULK_ADD_DEVICES, DELETE_DEVICE, } from "@Store/Actions/Hardware/devices";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_DEVICE:
            return state.filter((object) => object.id !== data);
        case ADD_DEVICE:
            const device = Factory.Device(data);
            return [...state.filter(({ id }) => id !== device.id), device];
        case BULK_ADD_DEVICES:
            const devices = data.map((element) => Factory.Device(element));
            const ids = new Set(devices.map(({ id }) => id));
            return [...state.filter(({ id }) => !ids.has(id)), ...devices];
    }
    return state;
};
