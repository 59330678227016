import React from "react";
import { connect } from "react-redux";
import { downloadCustomerDocuments, downloadSupplierDocuments, } from "@Api/AwardedContracts";
import api from "@Api/index";
import Button from "@Components/Button";
import Card from "@Components/Card";
import FileUploader from "@Components/FileUploader";
import Form, { InputType } from "@Components/Form";
import AwardedUsageIcon from "@Components/Icons/AwardedContract";
import Document from "@Components/Icons/Document";
import InfoPopup from "@Components/InfoPopup";
import Table from "@Components/Table";
import { ERROR, ToastStatus } from "@Components/Toast";
import { Status } from "@Shared/Entities/AwardedContract/enums";
import { navigateExternalUrl } from "@Shared/Helpers/Download";
import { addNoteToAwardedContract, addUsage, approveAwardedContract, fetchAwardedContracts, uploadDocumentCustomer, uploadDocumentSupplier, } from "@Store/Actions/AwardedContract";
import { addNotification } from "@Store/Actions/Notifications";
import getAwardedContractEntities from "@Helpers/GetAwardedContractEntities";
import GetPath from "@Helpers/GetPath";
import { CUSTOMER, CUSTOMER_TENDER, SUPPLIER } from "@Shared/Paths/Admin";
import { updateUsage } from "@Store/Actions/Customers/usage";
import { NavLink } from "react-router-dom";
import AddUsage from "./AddUsage/index";
import FileName from "./FileName";
import styles from "./index.module.scss";
import YesNoConfirm from "./YesNoConfirm";
class AwardedContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            customerDocuments: [],
            supplierDocuments: [],
            currentContractId: undefined,
            openSupplierNote: false,
            customerId: undefined,
            openUsagePopup: false,
            showPopup: false,
            data: {},
            errors: {},
            open: false,
            visibleUploading: false,
            openEmailPopup: false,
            emailSubject: undefined,
            onSelection: undefined,
        };
    }
    componentDidMount() {
        this.reload();
    }
    reload = () => {
        this.setState({ fetching: true });
        this.props
            .fetch()
            .catch(() => {
            this.props.notify({
                title: "Oops",
                description: "Please contact support",
                status: ERROR,
            });
        })
            .finally(() => {
            this.setState({ fetching: false });
        });
    };
    closeEmailPopup = () => {
        this.setState({
            openEmailPopup: false,
            emailSubject: undefined,
        });
    };
    openPopup = () => {
        this.setState({ open: true });
    };
    closePopup = () => {
        this.setState({ open: false });
    };
    openUsagePopup = (awardedContractId) => () => {
        const { customer } = getAwardedContractEntities(awardedContractId);
        this.setState({
            currentContractId: awardedContractId,
            customerId: customer.id,
            openUsagePopup: true,
        });
    };
    closeUsagePopup = () => {
        this.setState({
            currentContractId: undefined,
            openUsagePopup: false,
        });
    };
    approveDocument = (awardedContractId) => async () => {
        this.setState({
            currentContractId: undefined,
        });
        this.setState({
            openEmailPopup: true,
            emailSubject: "Customer",
            onSelection: (sendEmail) => this.props.approve(awardedContractId, !sendEmail),
        });
    };
    regenFeedback = (awardedContractId) => async () => {
        const confirmed = confirm("Are you sure you want to regenerate feedback?");
        if (confirmed) {
            await api.post(`/awarded-contracts/${awardedContractId}/regenerate-feedback`, {});
        }
    };
    openNotePopup = (awardedContractId, openSupplierNote) => () => {
        const contract = this.props.awardedContracts.find((c) => c.awardedContract.id === awardedContractId);
        const { note, requirementsChanged } = contract.awardedContract;
        this.setState({
            currentContractId: awardedContractId,
            openSupplierNote: openSupplierNote,
            data: {
                ...this.state.data,
                note: note,
                supplierNote: requirementsChanged,
            },
            showPopup: true,
        });
    };
    downloadDocuments = (awardedContractId, supplierId, customerId) => async () => {
        const supplierDocuments = await downloadSupplierDocuments(awardedContractId, supplierId);
        const customerDocuments = await downloadCustomerDocuments(awardedContractId, customerId);
        // set documents state to display links
        this.setState({
            customerDocuments: customerDocuments,
            supplierDocuments: supplierDocuments,
            currentContractId: awardedContractId,
        });
    };
    viewDocHandler = (url) => () => {
        navigateExternalUrl(url);
    };
    popup = (close) => {
        const addNote = async () => {
            const { note } = this.state.data;
            await this.props.addNote(this.state.currentContractId, note);
            this.setState({
                showPopup: false,
                openSupplierNote: false,
                currentContractId: undefined,
            });
            return close;
        };
        const inputChange = (data) => {
            this.setState({
                data,
            });
        };
        const closeNote = () => {
            this.setState({
                showPopup: false,
                openSupplierNote: false,
                currentContractId: undefined,
            });
            return Promise.resolve(close);
        };
        if (!this.state.currentContractId) {
            return;
        }
        const contract = this.props.awardedContracts.find((c) => c.awardedContract.id === this.state.currentContractId);
        const { status } = contract.awardedContract;
        const canEditAddNote = status === Status.Awaiting_Admin_Approval;
        return (React.createElement(Card, null,
            !this.state.openSupplierNote && (React.createElement(Form, { data: this.state.data, errors: this.state.errors, change: inputChange, input: [
                    {
                        label: "Note",
                        type: InputType.LargeText,
                        name: "note",
                        disabled: !canEditAddNote,
                    },
                ], submit: canEditAddNote ? addNote : closeNote, buttonText: canEditAddNote ? "ADD NOTE" : "CLOSE" })),
            this.state.openSupplierNote && (React.createElement(Form, { data: this.state.data, errors: this.state.errors, change: inputChange, input: [
                    {
                        label: "Supplier Note",
                        type: InputType.LargeText,
                        name: "supplierNote",
                        disabled: true,
                    },
                ], submit: closeNote, buttonText: "CLOSE" }))));
    };
    uploadSupplierFile = (supplierId, awardedContractId) => (data) => {
        this.props
            .uploadSupplierContract(supplierId, awardedContractId, data)
            .then(() => {
            this.props.notify({
                title: "Contract Uploaded!",
                description: "",
                status: ToastStatus.Success,
            });
        });
    };
    reUploadSupplierFile = (supplierId, awardedContractId) => (data) => {
        this.props
            .uploadSupplierContract(supplierId, awardedContractId, data, true)
            .then(() => {
            this.props.notify({
                title: "Contract Uploaded!",
                description: "",
                status: ToastStatus.Success,
            });
            this.setState({
                customerDocuments: [],
                supplierDocuments: [],
                currentContractId: undefined,
                open: false,
            });
        });
    };
    uploadCustomerFile = (customerId, awardedContractId) => (data) => {
        this.setState({
            openEmailPopup: true,
            emailSubject: "Supplier",
            onSelection: (sendEmail) => this.props
                .uploadCustomerContract(customerId, awardedContractId, data, !sendEmail)
                .then(() => {
                this.props.notify({
                    title: "Contract Uploaded!",
                    description: "",
                    status: ToastStatus.Success,
                });
            }),
        });
    };
    createUsage = async (usage) => {
        return this.props.addUsage(this.state.currentContractId, usage);
    };
    render() {
        const fields = [
            {
                field: "awardedContract",
                hidden: true,
            },
            {
                field: "customer",
                hidden: true,
            },
            {
                field: "supplier",
                hidden: true,
            },
            {
                field: "customerName",
                header: "Customer Name",
                sortable: true,
                searchable: true,
                filterable: {
                    options: true,
                },
                alias: (awardedContract) => {
                    const { customer } = awardedContract;
                    return customer?.name;
                },
                display: ({ customerName, awardedContract }) => (React.createElement(NavLink, { to: GetPath(CUSTOMER, awardedContract.customerId) }, customerName)),
            },
            {
                field: "tenderId",
                header: "Tender ID",
                sortable: true,
                searchable: true,
                filterable: {
                    options: false,
                },
                alias: ({ awardedContract }) => {
                    return awardedContract.tenderId;
                },
                display: ({ tenderId, awardedContract }) => (React.createElement(NavLink, { to: GetPath(CUSTOMER_TENDER, awardedContract.customerId, tenderId) }, tenderId)),
            },
            {
                field: "supplierName",
                header: "Supplier Name",
                sortable: true,
                searchable: true,
                filterable: {
                    options: true,
                },
                alias: (awardedContract) => {
                    const { supplier } = awardedContract;
                    return supplier?.name;
                },
                display: ({ supplierName, awardedContract }) => (React.createElement(NavLink, { to: GetPath(SUPPLIER, awardedContract.supplierId) }, supplierName)),
            },
            {
                field: "statusDisplay",
                header: "Status",
                sortable: true,
                filterable: {
                    options: true,
                },
                alias: ({ awardedContract }) => {
                    if (awardedContract.status ===
                        Status.Awaiting_Changed_Requirements) {
                        return "Awaits supplier";
                    }
                    if (awardedContract.status ===
                        Status.Awaiting_Supplier_Documents) {
                        return "Awaits supplier";
                    }
                    if (awardedContract.status ===
                        Status.Awaiting_Admin_Approval) {
                        return "Awaits approval";
                    }
                    if (awardedContract.status ===
                        Status.Awaiting_Customer_Documents) {
                        return "Approved";
                    }
                    if (awardedContract.status === Status.Awaiting_Start_Date) {
                        return "Contracted";
                    }
                    if (awardedContract.status ===
                        Status.Awaiting_Billing_Details) {
                        return "Contracted";
                    }
                    if (awardedContract.status >=
                        Status.Awaiting_Confirmation_From_Customer) {
                        return "Contracted";
                    }
                },
                display: ({ statusDisplay, }) => {
                    let cssClass;
                    switch (statusDisplay) {
                        case "Awaits supplier":
                            cssClass = styles.waiting;
                            break;
                        case "Awaits approval":
                            cssClass = styles.actionRequired;
                            break;
                        case "Approved":
                            cssClass = styles.approved;
                            break;
                        case "Contracted":
                            cssClass = styles.approved;
                            break;
                    }
                    return (React.createElement("div", { className: `${styles.status} ${cssClass}` }, statusDisplay));
                },
            },
            {
                field: "actions",
                header: "Actions",
                display: ({ awardedContract: { id, supplierId, customerId, requirementsChanged, status, note, usageId, }, }) => {
                    switch (status) {
                        case Status.Awaiting_Changed_Requirements:
                        case Status.Awaiting_Supplier_Documents:
                            return (React.createElement("div", { className: styles.buttonGroup },
                                React.createElement(FileUploader, { cta: true, upload: this.uploadSupplierFile(supplierId, id), multi: true }, "UPLOAD SUPPLIER'S DOCUMENTS"),
                                React.createElement(Button, { red: true, click: this.regenFeedback(id) }, "REGEN FEEDBACK")));
                        case Status.Awaiting_Admin_Approval:
                            return (React.createElement("div", { className: styles.buttonGroup },
                                React.createElement(Button, { click: this.downloadDocuments(id, supplierId, customerId) }, "VIEW DOCUMENTS"),
                                React.createElement(Button, { red: true, click: this.regenFeedback(id) }, "REGEN FEEDBACK"),
                                requirementsChanged && (React.createElement(Button, { click: this.openNotePopup(id, true) }, "SUPPLIER NOTE")),
                                React.createElement(Button, { cta: true, click: this.openNotePopup(id) }, note
                                    ? "Edit Note".toUpperCase()
                                    : "Add Note".toUpperCase()),
                                React.createElement(Button, { cta: true, click: this.approveDocument(id) }, "APPROVE")));
                        case Status.Awaiting_Customer_Documents:
                            return (React.createElement("div", { className: styles.buttonGroup },
                                React.createElement(FileUploader, { cta: true, upload: this.uploadCustomerFile(customerId, id), multi: true }, "UPLOAD CUSTOMER'S DOCUMENTS"),
                                React.createElement(Button, { click: this.downloadDocuments(id, supplierId, customerId) }, "VIEW DOCUMENTS"),
                                React.createElement(Button, { red: true, click: this.regenFeedback(id) }, "REGEN FEEDBACK"),
                                requirementsChanged && (React.createElement(Button, { click: this.openNotePopup(id, true) }, "SUPPLIER NOTE")),
                                React.createElement(Button, { click: this.openNotePopup(id), disabled: !note, dark: !note }, "VIEW NOTE")));
                        case Status.Awaiting_Start_Date:
                        case Status.Awaiting_Billing_Details:
                        case Status.Awaiting_Confirmation_From_Customer:
                        case Status.Complete:
                            return (React.createElement("div", { className: styles.buttonGroup },
                                React.createElement(Button, { click: this.downloadDocuments(id, supplierId, customerId) }, "VIEW DOCUMENTS"),
                                React.createElement(Button, { red: true, click: this.regenFeedback(id) }, "REGEN FEEDBACK"),
                                requirementsChanged && (React.createElement(Button, { click: this.openNotePopup(id, true) }, "SUPPLIER NOTE")),
                                React.createElement(Button, { click: this.openNotePopup(id), disabled: !note, dark: !note }, "VIEW NOTE"),
                                usageId === undefined && (React.createElement(Button, { click: this.openUsagePopup(id) }, "ACTIVATE SENSABILL"))));
                    }
                },
            },
        ];
        const { awardedContract } = this.props.awardedContracts.find(({ awardedContract }) => awardedContract.id === this.state.currentContractId) || {};
        const popupReUpload = () => {
            return (React.createElement(Card, null,
                React.createElement("h1", null, "Are you sure you want to re-upload files?"),
                React.createElement("div", { className: styles.reuploadPopup },
                    React.createElement(FileUploader, { upload: this.reUploadSupplierFile(awardedContract?.supplierId, awardedContract?.id), multi: true }, "Re-Upload"),
                    React.createElement(Button, { red: true, click: this.closePopup }, "Cancel"))));
        };
        const documentsAvailable = this.state.supplierDocuments.length > 0 ||
            this.state.customerDocuments.length > 0;
        return (React.createElement(React.Fragment, null,
            documentsAvailable && (React.createElement(Card, { className: styles.downloads },
                React.createElement("div", { className: styles.title }, "Documents"),
                this.state.supplierDocuments.length > 0 && (React.createElement("ul", { className: styles.list },
                    React.createElement("li", { className: styles.header },
                        React.createElement("h1", null, "Supplier Documents"),
                        React.createElement(Button, { click: this.openPopup, className: styles.reuploadButton, red: true }, "RE-UPLOAD SUPPLIER DOCUMENTS")),
                    this.state.supplierDocuments.map((d, index) => {
                        return (React.createElement("li", { key: index + d.fileName },
                            React.createElement("div", { className: styles.file },
                                React.createElement(Document, null),
                                React.createElement(FileName, null, d.fileName),
                                React.createElement("div", { className: styles.button },
                                    React.createElement(Button, { click: this.viewDocHandler(d.tempURL) }, "VIEW")))));
                    }))),
                React.createElement(InfoPopup, { trigger: this.state.open, content: popupReUpload, onClose: this.closePopup }),
                this.state.customerDocuments.length > 0 && (React.createElement("ul", { className: styles.list },
                    React.createElement("li", null,
                        React.createElement("h1", null, "Customer Documents")),
                    this.state.customerDocuments.map((d, index) => {
                        return (React.createElement("li", { key: index + d.fileName },
                            React.createElement("div", { className: styles.file },
                                React.createElement(Document, null),
                                React.createElement(FileName, null, d.fileName),
                                React.createElement("div", { className: styles.button },
                                    React.createElement(Button, { click: this.viewDocHandler(d.tempURL) }, "VIEW")))));
                    }))))),
            React.createElement(Card, { title: React.createElement(React.Fragment, null,
                    React.createElement(AwardedUsageIcon, null),
                    " Awarded Contracts") },
                React.createElement(Table, { data: this.props.awardedContracts, fields: fields, fetching: this.state.fetching, reload: this.reload })),
            React.createElement(InfoPopup, { trigger: this.state.showPopup, content: this.popup, onClose: () => {
                    this.setState({
                        showPopup: false,
                        openSupplierNote: false,
                        currentContractId: undefined,
                    });
                } }),
            React.createElement(AddUsage, { open: this.state.openUsagePopup, onClose: this.closeUsagePopup, createUsage: this.createUsage, updateUsage: this.props.updateUsage, customerId: this.state.customerId }),
            React.createElement(YesNoConfirm, { open: this.state.openEmailPopup, onClose: this.closeEmailPopup, onSelection: this.state.onSelection, emailSubject: this.state.emailSubject })));
    }
}
const mapStateToProps = (store) => {
    const awardedContracts = store.awardedContracts
        .map(({ id }) => getAwardedContractEntities(id))
        .sort((a, b) => b.awardedContract.createdAt.diff(a.awardedContract.createdAt));
    return { awardedContracts };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetch: () => fetchAwardedContracts(dispatch),
        addUsage: (awardContractId, usage) => addUsage(dispatch, awardContractId, usage).then((ac) => ac.usageId),
        updateUsage: (usage) => updateUsage(dispatch, usage),
        notify: (data) => addNotification(dispatch, data),
        approve: (awardContractId, noEmail) => approveAwardedContract(dispatch, awardContractId, noEmail),
        addNote: (awardContractId, note) => addNoteToAwardedContract(dispatch, awardContractId, note),
        uploadSupplierContract: (supplierId, awardedContractId, files, reupload) => uploadDocumentSupplier(dispatch, supplierId, awardedContractId, files, reupload),
        uploadCustomerContract: (customerId, awardedContractId, files, noEmail) => uploadDocumentCustomer(dispatch, customerId, awardedContractId, files, noEmail),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AwardedContracts);
