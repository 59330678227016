import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (supplierId, userId) => {
    const url = `/suppliers/${supplierId}/users/${userId}`;
    return api.get(url).then((result) => Factory.User(result.data.item));
};
export const get = (callback, supplierId, inclDel) => {
    const url = `/suppliers/${supplierId}/users`;
    return collection((data) => callback(Factory.User(data)), url, { inclDel });
};
export const post = (user) => {
    return api
        .post(`/suppliers/${user.supplierId}/users`, user.data)
        .then((result) => {
        return Factory.User(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (user) => {
    return api
        .put(`/suppliers/${user.supplierId}/users/${user.id}`, user.data)
        .then((result) => {
        return Factory.User(result.data.item);
    });
};
export const del = (user) => {
    return api
        .delete(`/suppliers/${user.supplierId}/users/${user.id}`)
        .then((result) => {
        return result.data.item;
    });
};
export const restore = (user) => {
    return api
        .put(`/suppliers/${user.supplierId}/users/${user.id}/restore`)
        .then((result) => {
        return Factory.User(result.data.item);
    });
};
