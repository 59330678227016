import React from "react";
import styles from "./index.module.scss";
export default class SubHeader extends React.Component {
    static defaultProps = {
        marginIncrease: false,
        flex: false,
        seperated: false,
        noPadding: false,
        zIndexAdd: 0,
    };
    render() {
        const marginIncrease = this.props.marginIncrease === true ? styles.marginIncrease : "";
        const flex = this.props.flex === true ? styles.flex : "";
        const seperated = this.props.seperated === true ? styles.seperated : "";
        const noPadding = this.props.noPadding === true ? styles.noPadding : "";
        return (React.createElement("div", { className: `${styles.subHeader} ${marginIncrease} ${flex} ${seperated} ${noPadding} ${this.props.className}`, style: {
                zIndex: 100 + this.props.zIndexAdd,
            } }, this.props.children));
    }
}
