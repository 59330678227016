import validate from "validate.js";
export default {
    companyNo: {
        length: { minimum: 2 },
        presence: true,
        type: "string",
        format: {
            pattern: /^([\dA-Z]{2})\d{6}$/,
            message: (value) => validate.format("^%{num} is not a valid company number", {
                num: value,
            }),
        },
    },
};
