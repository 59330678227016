import React from "react";
import Exit from "@Components/Icons/Exit";
import styles from "./index.module.scss";
export default class Message extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.tooltip },
            React.createElement("div", { className: styles.exit },
                React.createElement(Exit, { onClick: this.props.close, dark: true, size: "1em" })),
            React.createElement("div", { className: styles.text }, this.props.children)));
    }
}
