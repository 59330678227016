// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status--bq3bm{color:orange;border-color:orange}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/WaitingClient/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,YAAY,CAAC,mBAAmB","sourcesContent":[".status{color:orange;border-color:orange}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "status--bq3bm"
};
export default ___CSS_LOADER_EXPORT___;
