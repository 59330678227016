import React from "react";
import { useLocation } from "react-router-dom";
import Customer from "@Components/Icons/Customer";
import Dashboard from "@Components/Icons/Dashboard";
import LogoutIcon from "@Components/Icons/Logout";
import OrganisationIcon from "@Components/Icons/Organisation";
import ProfileIcon from "@Components/Icons/Profile";
import TenderResponseIcon from "@Components/Icons/TenderResponse";
import PrimaryNav from "@Components/PrimaryNav";
import GetPath, { GetReplacements } from "@Helpers/GetPath";
import { CONTRACTS, CUSTOMERS, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, ROOT, } from "@Shared/Paths/Partner";
import styles from "./index.module.scss";
const topRoutes = [
    {
        path: ROOT,
        name: "Dashboard",
        exact: true,
        icon: React.createElement(Dashboard, { className: styles.icon }),
    },
    {
        path: CUSTOMERS,
        name: "Customers",
        icon: React.createElement(Customer, { className: styles.icon }),
    },
    {
        path: CONTRACTS,
        name: "Contracts",
        icon: React.createElement(TenderResponseIcon, { className: styles.icon }),
    },
    {
        path: MY_ACCOUNT,
        name: "My Account",
        icon: React.createElement(ProfileIcon, { className: styles.icon }),
    },
    {
        path: MY_ORGANISATION,
        name: "My Organisation",
        icon: React.createElement(OrganisationIcon, { className: styles.icon }),
    },
];
const bottomRoutes = [
    {
        path: LOGOUT,
        name: "Logout",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
    },
];
export default () => {
    const location = useLocation();
    const topRoutesFiltered = topRoutes
        .filter(({ filter = (path) => !!path }) => filter(location.pathname))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const bottomRoutesFiltered = bottomRoutes
        .filter(({ filter = (path) => !!path }) => filter(location.pathname))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const extra = React.createElement(React.Fragment, null);
    return (React.createElement(PrimaryNav, { topLinks: topRoutesFiltered, bottomLinks: bottomRoutesFiltered }, extra));
};
