import Cookies from "js-cookie";
import api from "@Api/index";
import getSubdomain, { getDomain } from "@Helpers/GetSubdomain";
import sleep from "@Helpers/Sleep";
export const decodeToken = (token) => {
    const [, payload] = token.split(".");
    const base64Payload = (payload || "")
        .replaceAll("-", "+")
        .replaceAll("_", "/");
    const json = atob(base64Payload);
    const { data: { user, company, subdomain = "platform", id, hash }, exp, } = JSON.parse(json);
    return { user, company, subdomain, exp, token, id, hash };
};
export const login = async (username, password) => {
    const { data: { token }, } = await api.post("/auth/login", { username, password });
    const domain = getDomain();
    const notLocal = domain !== "";
    const decoded = decodeToken(token);
    if (getSubdomain() !== decoded.subdomain && notLocal) {
        Cookies.set("token", token, { domain });
        await sleep(500);
    }
    localStorage.setItem("token", token);
    return decoded;
};
export const logout = async () => api.get("/auth/logout");
export const refreshToken = (force = false) => api.get("/auth/refresh", { params: { force } }).then((result) => {
    const { data: { token }, } = result;
    return decodeToken(token);
});
export const assumeId = (id, companyId) => api.post("/auth/assume", { id, companyId }).then((result) => {
    const { data: { token }, } = result;
    return token;
});
export const requestPasswordReset = (username) => api.post("/auth/reset", { username });
export const passwordReset = (id, password) => api.post(`/auth/reset/${id}`, { password });
export const turnOnMfa = (id) => api.post(`/auth/mfa-on`, { id });
export const turnOffMfa = (id, code) => api.post(`/auth/mfa-off`, { id, code });
export const checkMfa = async (id, code) => {
    const { data: { token }, } = await api.post(`/auth/mfa-check`, { id, code });
    localStorage.setItem("mfa", token);
};
