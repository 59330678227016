import { BrokerDomain, getBrokerDomain } from "@Helpers/GetBrokerDomain";
import { lighten } from "@mui/material";
import React from "react";
const platform = {
    textColor: "#58585a",
    invertedTextColor: "#ffffff",
    titleColor: "#58585a",
    labelColor: "#000000",
    textLinkColor: "#0c80a4",
    primaryColor: "#72c04b",
    primaryButtonColor: "#72c04b",
    primaryButtonColorHover: "#008946",
    primaryButtonColorPress: "#00a555",
    secondaryButtonColor: "#717173",
    secondaryButtonColorHover: "#58585a",
    secondaryButtonColorPress: "#58585a",
    tertiaryButtonColor: "#34abdc",
    tertiaryButtonColorShadow: "#2580a3",
    tertiaryButtonColorHover: "#2580a3",
    tertiaryButtonTextColorHover: "#ffffff",
    tertiaryButtonColorPress: "#2580a3",
    ctaButtonColor: "#72c04b",
    ctaButtonTextColor: "#ffffff",
    ctaButtonColorHover: "#008946",
    ctaButtonColorPress: "#008946",
    orangeButtonColor: "#e9b079",
    orangeButtonColorHover: "#d9a67a",
    orangeButtonColorPress: "#d9a67a",
    standOutColor: "#c54039",
    deleteButtonColor: "#DD4B39",
    deleteButtonColorHover: "#c54039",
    deleteButtonColorPress: "#c54039",
    selectedInputColor: "#34abdc",
    unselectedInputColor: "#dae3e7",
    disabledInputColor: "#dae3e7",
    disabledBackgroundColor: "#fcfcfc",
    tagBackgroundColor: "#dae3e7",
    errorColor: "#DD4B39",
    deleteColor: "#DD4B39",
    borderColor: "#dae3e7",
    mainBackgroundColor: "#e7efef",
    responseGradientColor1: "#4f9f88",
    responseGradientColor2: "#61a467",
    responseGradientColor3: "#64BB75",
    responseGradientColor4: "#32ACC4",
    //figure out function to create this
    mainBackgroundColorRGB: "231, 239, 239",
    cardColor: "#ffffff",
    headerColor: "#5d5e5e",
    footerColor: "#ffffff",
    badgeColor: "#36a554",
    badgeTextColor: "#5e5e5e",
    badgeBorderColor: "#5e5e5e",
    highlightedTextColor: "#04a65a",
    altHighlightedTextColor: "#00abd0",
    lightButtonTextColor: "#00abd0",
    darkendHighlightColor: "#0a7595",
    titleMainColor: "#0eabdb",
    veryBadScaleColor: "#e40909",
    badScaleColor: "#eaaa14",
    mediumScaleColor: "#ffe827",
    goodScaleColor: "#74bf43",
    veryGoodScaleColor: "#1a9b78",
};
const ERA = {
    ...platform,
    primaryColor: "#0e4376",
    primaryButtonColor: "#003d4c",
    primaryButtonColorHover: "#00252e",
    primaryButtonColorPress: "#003d4c",
    secondaryButtonColor: "#717173",
    secondaryButtonColorHover: "#58585a",
    secondaryButtonColorPress: "#58585a",
    tertiaryButtonColor: "#003d4c",
    tertiaryButtonColorShadow: lighten("#003d4c", 0.25),
    tertiaryButtonColorHover: "#3abddc",
    selectedInputColor: "#dd8930",
    tertiaryButtonTextColorHover: "#003d4c",
    tertiaryButtonColorPress: "#3abddc",
    ctaButtonColor: "#ffa01e",
    ctaButtonTextColor: "#003d4c",
    ctaButtonColorHover: "#cc8523",
    ctaButtonColorPress: "#f8a843",
    badgeColor: "#0e4376",
    highlightedTextColor: "#0e4376",
    altHighlightedTextColor: "#00abd0",
    responseGradientColor1: "#00abd0",
    responseGradientColor2: "#0e4376",
    responseGradientColor3: "#c77e17",
    responseGradientColor4: "#ffa01e",
};
const BDO = {
    ...platform,
    primaryColor: "#e81a3b",
    primaryButtonColor: "#e81a3b",
    primaryButtonColorHover: "#d01735",
    primaryButtonColorPress: "#e81a3b",
    secondaryButtonColor: "#007aff",
    secondaryButtonColorHover: "#006be0",
    secondaryButtonColorPress: "#006be0",
    tertiaryButtonColor: "#007aff",
    tertiaryButtonColorShadow: lighten("#007aff", 0.25),
    tertiaryButtonColorHover: "#0062cc",
    selectedInputColor: "#007aff",
    tertiaryButtonTextColorHover: "#f2f2f2",
    tertiaryButtonColorPress: "#007aff",
    ctaButtonColor: "#e81a3b",
    ctaButtonTextColor: "#f2f2f2",
    ctaButtonColorHover: "#d01735",
    ctaButtonColorPress: "#e81a3b",
    badgeColor: "#e81a3b",
    highlightedTextColor: "#e81a3b",
    altHighlightedTextColor: "#007aff",
    responseGradientColor1: "#007aff",
    responseGradientColor2: "#e81a3b",
    responseGradientColor3: "#d01735",
    responseGradientColor4: "#b9142f",
    darkendHighlightColor: "#003d80",
    titleMainColor: "#007aff",
};
const TechMahindra = {
    ...platform,
    primaryColor: "#e31837",
    primaryButtonColor: "#e31837",
    primaryButtonColorHover: "#cc1631",
    primaryButtonColorPress: "#e31837",
    secondaryButtonColor: "#4d4d4f",
    secondaryButtonColorHover: "#e6e7e8",
    secondaryButtonColorPress: "#e6e7e8",
    tertiaryButtonColor: "#e31837",
    tertiaryButtonColorShadow: lighten("#e31837", 0.25),
    tertiaryButtonColorHover: "#cc1631",
    tertiaryButtonTextColorHover: "white",
    tertiaryButtonColorPress: "#cc1631",
    selectedInputColor: "#231f20",
    ctaButtonColor: "#231f20",
    ctaButtonTextColor: "#e6e7e8",
    ctaButtonColorHover: "#4d4d4f",
    ctaButtonColorPress: "#4d4d4f",
    badgeColor: "#e31837",
    highlightedTextColor: "#e31837",
    altHighlightedTextColor: "#e31837",
    responseGradientColor1: "#881123",
    responseGradientColor2: "#670817",
    responseGradientColor3: "#939393",
    responseGradientColor4: "#4d4d4f",
};
const SevenStepSolutions = {
    ...platform,
    primaryColor: "#cF5c5d",
    primaryButtonColor: "#cF5c5d",
    primaryButtonColorHover: "#6f2527",
    primaryButtonColorPress: "#cF5c5d",
    secondaryButtonColor: "#4d4d4f",
    secondaryButtonColorHover: "#404048",
    secondaryButtonColorPress: "#4d4d4f",
    ctaButtonColor: "#beced5",
    ctaButtonColorHover: "#a3b0b6",
    ctaButtonColorPress: "#beced5",
    ctaButtonTextColor: "#58585a",
    tertiaryButtonColor: "#6f2527",
    tertiaryButtonColorHover: "#52181a",
    tertiaryButtonColorPress: "#6f2527",
    tertiaryButtonColorShadow: "#9b4548",
    selectedInputColor: "#231f20",
    badgeColor: "#cF5c5d",
    highlightedTextColor: "#cF5c5d",
    altHighlightedTextColor: "#cF5c5d",
    responseGradientColor1: "#6f2527",
    responseGradientColor2: "#cF5c5d",
    responseGradientColor3: "#beced5",
    responseGradientColor4: "#58585a",
};
const Bionic = {
    ...platform,
    primaryColor: "#142f73",
    primaryButtonColor: "#142f73",
    primaryButtonColorHover: "#007bff",
    primaryButtonColorPress: "#142f73",
    secondaryButtonColor: "#ff6841",
    secondaryButtonColorHover: "#ff3f0e",
    secondaryButtonColorPress: "#ff6841",
    ctaButtonColor: "#007bff",
    ctaButtonColorHover: "#142f73",
    ctaButtonColorPress: "#007bff",
    ctaButtonTextColor: "#ffffff",
    tertiaryButtonColor: "#ff6841",
    tertiaryButtonColorHover: "#ff3f0e",
    tertiaryButtonColorPress: "#ff6841",
    tertiaryButtonColorShadow: "#ff3f0e",
    selectedInputColor: "#142f73",
    badgeColor: "#ff6841",
    highlightedTextColor: "#ff6841",
    altHighlightedTextColor: "#ff6841",
    responseGradientColor1: "#142f73",
    responseGradientColor2: "#ff6841",
    responseGradientColor3: "#beced5",
    responseGradientColor4: "#58585a",
};
const Protel = {
    ...platform,
    textColor: "#58585a",
    invertedTextColor: "#ffffff",
    titleColor: "#58585a",
    labelColor: "#000000",
    textLinkColor: "#28538A",
    primaryColor: "#28538A",
    primaryButtonColor: "#28538A",
    primaryButtonColorHover: "#1d3b62",
    primaryButtonColorPress: "#28538A",
    secondaryButtonColor: "#717173",
    secondaryButtonColorHover: "#58585a",
    secondaryButtonColorPress: "#58585a",
    tertiaryButtonColor: "#141314",
    tertiaryButtonColorShadow: "#070707",
    tertiaryButtonColorHover: "#3b383b",
    tertiaryButtonTextColorHover: "#ffffff",
    tertiaryButtonColorPress: "#2580a3",
    ctaButtonColor: "#28538A",
    ctaButtonTextColor: "#ffffff",
    ctaButtonColorHover: "#1d3b62",
    ctaButtonColorPress: "#1d3b62",
    orangeButtonColor: "#e9b079",
    orangeButtonColorHover: "#d9a67a",
    orangeButtonColorPress: "#d9a67a",
    standOutColor: "#c54039",
    deleteButtonColor: "#DD4B39",
    deleteButtonColorHover: "#c54039",
    deleteButtonColorPress: "#c54039",
    selectedInputColor: "#141314",
    unselectedInputColor: "#dae3e7",
    disabledInputColor: "#dae3e7",
    disabledBackgroundColor: "#fcfcfc",
    tagBackgroundColor: "#dae3e7",
    errorColor: "#DD4B39",
    deleteColor: "#DD4B39",
    borderColor: "#dae3e7",
    mainBackgroundColor: "#e7efef",
    altHighlightedTextColor: "#28538A",
    responseGradientColor1: "#172f4f",
    responseGradientColor2: "#1d3b62",
    responseGradientColor3: "#28538A",
    responseGradientColor4: "#336bb2",
};
class Theme extends React.Component {
    render() {
        const colors = (() => {
            const broker = getBrokerDomain();
            switch (broker) {
                case BrokerDomain.ERA:
                    return ERA;
                case BrokerDomain.TechMahindra:
                    return TechMahindra;
                case BrokerDomain.SevenStepSolutions:
                    return SevenStepSolutions;
                case BrokerDomain.Bionic:
                    return Bionic;
                case BrokerDomain.BDO:
                    return BDO;
                case BrokerDomain.Protel:
                    return Protel;
                default:
                    return platform;
            }
        })();
        return (React.createElement("style", null, `
            :root {
    --text-color: ${colors.textColor};
    --inverted-text-color: ${colors.invertedTextColor};
    --title-color: ${colors.titleColor};
    --label-color: ${colors.labelColor};
    --text-link-color: ${colors.textLinkColor};
    --primary-color: ${colors.primaryColor};
    
    --primary-button-color: ${colors.primaryButtonColor};
    --primary-button-color-hover: ${colors.primaryButtonColorHover};
    --primary-button-color-press: ${colors.primaryButtonColorPress};

    --secondary-button-color: ${colors.secondaryButtonColor};
    --secondary-button-color-hover: ${colors.secondaryButtonColorHover};
    --secondary-button-color-press: ${colors.secondaryButtonColorPress};

    --tertiary-button-color: ${colors.tertiaryButtonColor};
    --tertiary-button-color-shadow: ${colors.tertiaryButtonColorShadow};
    --tertiary-button-color-hover: ${colors.tertiaryButtonColorHover};
    --tertiary-button-text-color-hover: ${colors.tertiaryButtonTextColorHover};
    --tertiary-button-color-press: ${colors.tertiaryButtonColorPress};
    
    --cta-button-color: ${colors.ctaButtonColor};
    --cta-button-text-color: ${colors.ctaButtonTextColor};
    --cta-button-color-hover: ${colors.ctaButtonColorHover};
    --cta-button-color-press: ${colors.ctaButtonColorPress};

    --delete-button-color: ${colors.deleteButtonColor};
    --delete-button-color-hover: ${colors.deleteButtonColorHover};
    --delete-button-color-press: ${colors.deleteButtonColorPress};

    --selected-input-color: ${colors.selectedInputColor};
    --unselected-input-color: ${colors.unselectedInputColor};

    --disabled-input-color: ${colors.disabledInputColor};
    --disabled-background-color: ${colors.disabledBackgroundColor};

    --tag-background-color: ${colors.tagBackgroundColor};
    --error-color: ${colors.errorColor};
    --delete-color: ${colors.deleteColor};
    --border-color: ${colors.borderColor};
    
    --response-gradient-color-1: ${colors.responseGradientColor1};
    --response-gradient-color-2: ${colors.responseGradientColor2};
    --response-gradient-color-3: ${colors.responseGradientColor3};
    --response-gradient-color-4: ${colors.responseGradientColor4};
    
    --main-background-color: ${colors.mainBackgroundColor};
    --main-background-color-rgb: ${colors.mainBackgroundColorRGB};
    --card-color: ${colors.cardColor};
    --header-color: ${colors.headerColor};
    --footer-color: ${colors.footerColor};
    
    --badge-color: ${colors.badgeColor};
    --badge-text-color: ${colors.badgeTextColor};
    --badge-border-color: ${colors.badgeBorderColor};
    --stand-out-color: ${colors.standOutColor};
    --orange-button-color: ${colors.orangeButtonColor};
    --orange-button-color-hover: ${colors.orangeButtonColorHover};
    --orange-button-color-press: ${colors.orangeButtonColorPress};
    --highlighted-text-color: ${colors.highlightedTextColor};
    --alt-highlighted-text-color: ${colors.altHighlightedTextColor};
    --light-button-text-color: ${colors.lightButtonTextColor};
    --darkend-highlight-color: ${colors.darkendHighlightColor};
    --title-main-color: ${colors.titleMainColor};
    
    --very-bad-scale-color: ${colors.veryBadScaleColor};
    --bad-scale-color: ${colors.badScaleColor};
    --medium-scale-color: ${colors.mediumScaleColor};
    --good-scale-color: ${colors.goodScaleColor};
    --very-good-scale-color: ${colors.veryGoodScaleColor};
    
    --standard-gradient: linear-gradient(23deg, ${colors.primaryColor} 40%, ${colors.selectedInputColor} 60%);
            }`));
    }
}
export default Theme;
