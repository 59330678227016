import React from "react";
import styles from "./index.module.scss";
import predicty from "./predicty.svg";
export default class PredictyIcon extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        return (React.createElement("img", { src: predicty, alt: "Predict Mobile", className: `${styles.predicty} ${this.props.className}` }));
    }
}
