import debounce from "@Shared/Helpers/Util/Debounce";
import { ADD_RESPONSE, EDIT_BUNDLES, EDIT_PROPOSAL, PROPOSAL_TAB, } from "@Store/Actions/Suppliers/responses";
import responseReducer from "@Store/Reducers/Suppliers/responses";
import { put } from "@Api/Suppliers/responses";
import sleep from "@Helpers/Sleep";
import Factory from "@Shared/Factory";
import { store } from "./../../";
const updateResponse = (response) => put(response);
class ProposalStack {
    id;
    stack = [];
    dispatch;
    constructor(id, dispatch) {
        this.id = id;
        this.dispatch = dispatch;
    }
    addToStack(action) {
        this.stack.push(action);
        if (this.stack.length === 1) {
            this.save();
        }
    }
    save = debounce(async (updatedResponse) => {
        const state = store.getState();
        const ids = this.stack.map((a) => a.$uuid);
        const response = updatedResponse ||
            state.suppliers.responses.find((data) => data.id === this.id);
        await this._save(response, 5, ids);
    }, 2500);
    async _save(response, tries = 5, ids = []) {
        try {
            const updatedResponse = await updateResponse(response);
            this.stack = this.stack.filter((a) => !ids.includes(a.$uuid));
            if (this.stack.length > 0) {
                this.replayStack(updatedResponse);
            }
            else {
                this.dispatch({
                    type: ADD_RESPONSE,
                    data: updatedResponse,
                });
            }
        }
        catch (error) {
            if (tries < 1) {
                throw error;
            }
            if (error.response.status === 409 ||
                error.response.status === 425) {
                await sleep(250);
                await this.replayStack(error.response.data.state, tries);
            }
        }
    }
    replayStack(serverState, tries = 6) {
        let responses = [Factory.Response(serverState)];
        const stack = [...this.stack];
        const ids = [];
        for (const action of stack) {
            responses = responseReducer(responses, action);
            ids.push(action.$uuid);
        }
        this._save(responses[0], tries, ids);
    }
}
const stacks = {};
export default (store) => (next) => (action) => {
    const state = next(action); // Store is updated
    const { type, data } = action;
    if ((type === EDIT_PROPOSAL && data.effects !== undefined) ||
        type === PROPOSAL_TAB ||
        type === EDIT_BUNDLES) {
        if (!stacks[data.id]) {
            stacks[data.id] = new ProposalStack(data.id, store.dispatch);
        }
        stacks[data.id].addToStack(action);
    }
    return state;
};
