import dayjs from "dayjs";
import React from "react";
import styles from "./index.module.scss";
export default class Footer extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.footer },
            React.createElement(React.Fragment, null,
                "Copyright \u00A9 ",
                dayjs().year()),
            React.createElement("div", { className: styles.whiteBox },
                React.createElement("a", { href: "https://docs.predictmobile.com/policies/TermsAndConditions/", target: "_blank" }, "T&Cs"),
                React.createElement("a", { href: "https://docs.predictmobile.com/policies/PrivacyPolicy/", target: "_blank", className: styles.privacy }, "Privacy Policy"))));
    }
}
