import React from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "@Components/Footer";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import { NOT_FOUND, ROOT, SENSABILL, SUPPLIER } from "@Shared/Paths/Register";
import Notifications from "../../../Notifications";
import Customer from "../Customer";
import Sensabill from "../Sensabill";
import Supplier from "../Supplier";
import styles from "./index.module.scss";
import LeftBar from "./LeftBar";
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }
    onLoad = () => {
        this.setState({
            loaded: true,
        });
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement(LeftBar, { onLoad: this.onLoad }),
            React.createElement("div", { className: styles.content },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: ROOT, exact: true },
                        React.createElement(Customer, { loaded: this.state.loaded })),
                    React.createElement(Route, { path: SUPPLIER, render: ({ match }) => (React.createElement(Supplier, { ...match.params })) }),
                    React.createElement(Route, { path: SENSABILL, render: ({ match }) => (React.createElement(Sensabill, { ...match.params })) }),
                    React.createElement(Route, { path: NOT_FOUND },
                        React.createElement(NotFoundFallback, null))),
                React.createElement(Footer, null)),
            React.createElement(Notifications, null)));
    }
}
