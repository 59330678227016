import React from "react";
import InfoPopup from "@Components/InfoPopup";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import styles from "./index.module.scss";
export default class Uploading extends React.Component {
    render() {
        const popup = () => {
            return (React.createElement("div", { className: styles.popup },
                React.createElement(LoadingFallback, { text: "Uploading..." })));
        };
        return (React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose }));
    }
}
