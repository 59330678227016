import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const additionalDataPresenceConstraints = {
    [Effected.AdditionalData]: {
        presence: true,
    },
};
export const additionalDataValidConstraints = {};
export const additionalDataCostValidConstraints = {
    [Effected.AdditionalDataNoNewLine]: {
        presence: true,
        type: "boolean",
    },
    [Effected.AdditionalDataMinQty]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalDataNoNewLine,
                value: true,
                inverted: true,
            },
        ],
        type: "number",
    },
    [Effected.AdditionalDataType]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalDataNoNewLine,
                value: true,
                inverted: true,
            },
        ],
        type: "number",
    },
    [Effected.AdditionalDataCost]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalDataNoNewLine,
                value: true,
                inverted: true,
            },
        ],
        type: "number",
    },
    [Effected.AdditionalDataAuto]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.AdditionalDataNoNewLine,
                value: true,
                inverted: true,
            },
        ],
        type: "boolean",
    },
};
