import SVG from "@Components/Icons/SVG";
export default class TenderResponseIcon extends SVG {
    path = [
        "M 68 18",
        "V 4",
        "C 68 2 66 0 64 0",
        "H 4",
        "C 2 0 0 2 0 4",
        "V 86",
        "C 0 88 2 90 4 90",
        "H 10",
        "V 86",
        "H 5",
        "C 4 86 4 85 4 84",
        "V 6",
        "C 4 4 4 4 5 4",
        "H 62",
        "C 64 4 64 5 64 6",
        "V 14",
        "Z",
        "M 78 28",
        "L 60 10",
        "V 14",
        "L 74 28",
        "Z",
        "M 78 28",
        "V 96",
        "C 78 98 76 100 74 100",
        "H 14",
        "C 12 100 10 98 10 96",
        "V 14",
        "C 10 12 12 10 14 10",
        "H 60",
        "M 78 28",
        "H 64",
        "C 62 28 60 26 60 24",
        "V 10",
        "M 40 46",
        "H 63",
        "V 42",
        "H 40",
        "Z",
        "M 40 63",
        "H 63",
        "V 59",
        "H 40",
        "Z",
        "M 40 81",
        "H 63",
        "V 77",
        "H 40",
        "Z",
        "M 23 43",
        "L 25 46",
        "L 30 40",
        "L 32 42",
        "L 25 49",
        "L 21 45",
        "Z",
        "M 23 60",
        "L 25 64",
        "L 30 58",
        "L 32 60",
        "L 25 67",
        "L 21 62",
        "Z",
        "M 23 78",
        "L 25 81",
        "L 30 75",
        "L 32 77",
        "L 25 84",
        "L 21 79",
        "Z",
        "M 16 14",
        "H 56",
        "V 24",
        "C 57 28 60 31 64 32",
        "H 74",
        "V 93",
        "C 74 96 73 96 71 96",
        "H 16",
        "C 14 96 14 95 14 94",
        "V 16",
        "C 14 14 15 14 16 14",
    ];
    evenOdd = true;
}
