import React from "react";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import AnalysingBillReports from "@SmartComponents/TenderEdit/Display/Boxes/AnalysingBillReports";
import HowToGetBills from "@SmartComponents/TenderEdit/Display/Boxes/HowToGetBills";
import Savings from "@SmartComponents/TenderEdit/Display/Boxes/Savings";
import TreatData from "@SmartComponents/TenderEdit/Display/Boxes/TreatData";
import UploadBills from "@SmartComponents/TenderEdit/Display/Boxes/UploadBills";
import { usagePercentageTarget } from "@SmartComponents/TenderEdit";
import styles from "./index.module.scss";
export default class LeftGutter extends React.Component {
    newTariffRef = React.createRef();
    unusedLinesRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            savingsWidth: 0,
            timeOut: false,
            showProgress: true,
        };
    }
    navigateToContactUs = () => {
        window.open("mailto:hello@predictmobile.com?subject=Predict Mobile Enquiry", "_blank");
    };
    componentDidMount() {
        if (!this.props.welcomePopupOpen) {
            this.startTimeout();
        }
        setTimeout(() => {
            this.setState({ showProgress: false });
        }, 10000);
    }
    componentDidUpdate(previousProps, previousState) {
        if (this.state.savingsWidth === previousState.savingsWidth) {
            const savingsWidth = Math.max(this.newTariffRef?.current?.offsetWidth || 0, this.unusedLinesRef?.current?.offsetWidth || 0, 0);
            if (savingsWidth !== this.state.savingsWidth) {
                this.setState({ savingsWidth });
            }
        }
        if (!this.props.welcomePopupOpen &&
            previousProps.welcomePopupOpen !== this.props.welcomePopupOpen) {
            this.startTimeout();
        }
    }
    startTimeout = () => {
        setTimeout(() => {
            this.setState({ timeOut: true });
        }, 40000);
    };
    render() {
        const { tender } = this.props;
        if (tender === undefined) {
            return React.createElement(LoadingFallback, null);
        }
        let content;
        const { stats } = tender;
        if (tender.usageId === undefined) {
            let bottomBox = React.createElement(TreatData, null);
            if (this.state.timeOut) {
                bottomBox = React.createElement(HowToGetBills, null);
            }
            content = (React.createElement(React.Fragment, null,
                React.createElement(UploadBills, { upload: this.props.upload }),
                bottomBox));
        }
        else if ((this.props.fetchingUsage ||
            this.state.showProgress ||
            stats?.percentage < usagePercentageTarget) &&
            !tender.publishedAt) {
            content = (React.createElement(AnalysingBillReports, { tender: this.props.tender, usage: this.props.usage }));
        }
        else {
            content = React.createElement(Savings, { tender: this.props.tender });
        }
        return React.createElement("div", { className: styles.wrapper }, content);
    }
}
