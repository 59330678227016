import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (partnerId, customerId) => {
    const url = `/partners/${partnerId}/customers/${customerId}`;
    return api
        .get(url)
        .then((result) => Factory.PartnerCustomer(result.data.item));
};
export const get = (callback, partnerId) => {
    const url = `/partners/${partnerId}/customers`;
    return collection((data) => callback(Factory.PartnerCustomer(data)), url);
};
export const post = (customer) => {
    return api
        .post(`/partners/${customer.partnerId}/customers`, customer.data)
        .then((result) => {
        return Factory.PartnerCustomer(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (customer) => {
    return api
        .put(`/partners/${customer.partnerId}/customers/${customer.id}`, customer.data)
        .then((result) => {
        return Factory.PartnerCustomer(result.data.item);
    });
};
export const del = (customer) => {
    return api
        .delete(`/partners/${customer.partnerId}/customers/${customer.id}`)
        .then((result) => {
        return result.data.item;
    });
};
export const getUsageIframeForPartner = (partnerId, customerId, usageId) => {
    const url = `partners/${partnerId}/customers/${customerId}/usages/${usageId}/iframe`;
    return api.get(url).then((result) => {
        let { url, usage } = result.data;
        usage = Factory.Usage(usage);
        return { url, usage };
    });
};
