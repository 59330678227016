// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer--Z_wrK{display:flex;align-items:center;justify-content:flex-end;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);gap:1rem;color:var(--text-color);font-weight:bold;background-color:var(--footer-color);border-top:.1em solid var(--main-background-color)}.footer--Z_wrK .whiteBox--ZgojE{z-index:1000;margin:-0.4rem;padding:.4rem .4rem;background-color:var(--footer-color);border-radius:.75rem}.footer--Z_wrK .whiteBox--ZgojE .privacy--iHBnv{margin-left:.75rem}", "",{"version":3,"sources":["webpack://./src/gui/Components/Footer/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,YAAY,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,2EAA2E,CAAC,QAAQ,CAAC,uBAAuB,CAAC,gBAAgB,CAAC,oCAAoC,CAAC,kDAAkD,CAAC,gCAAkB,YAAY,CAAC,cAAc,CAAC,mBAAmB,CAAC,oCAAoC,CAAC,oBAAoB,CAAC,gDAA2B,kBAAkB","sourcesContent":[".footer{display:flex;align-items:center;justify-content:flex-end;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);gap:1rem;color:var(--text-color);font-weight:bold;background-color:var(--footer-color);border-top:.1em solid var(--main-background-color)}.footer .whiteBox{z-index:1000;margin:-0.4rem;padding:.4rem .4rem;background-color:var(--footer-color);border-radius:.75rem}.footer .whiteBox .privacy{margin-left:.75rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer--Z_wrK",
	"whiteBox": "whiteBox--ZgojE",
	"privacy": "privacy--iHBnv"
};
export default ___CSS_LOADER_EXPORT___;
