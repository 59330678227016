import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import styles from "./index.module.scss";
export default class Progress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index,
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.index !== this.props.index) {
            this.setState({ index: this.props.index });
        }
    }
    render() {
        return (React.createElement("div", { className: this.props.className },
            React.createElement(ProgressBar, { completed: this.state.index, maxCompleted: this.props.completeValue, customLabel: " ", bgColor: "var(--primary-color)", baseBgColor: "#f0f6f6", barContainerClassName: styles.progressRail, height: "8px" })));
    }
}
