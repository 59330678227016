import React from "react";
import { useLocation } from "react-router-dom";
import Customer from "@Components/Icons/Customer";
import Dashboard from "@Components/Icons/Dashboard";
import GuidanceNotesIcon from "@Components/Icons/GuidanceNotes";
import LogoutIcon from "@Components/Icons/Logout";
import OrganisationIcon from "@Components/Icons/Organisation";
import ProfileIcon from "@Components/Icons/Profile";
import TenderIcon from "@Components/Icons/Tender";
import TenderResponseIcon from "@Components/Icons/TenderResponse";
import PrimaryNav from "@Components/PrimaryNav";
import GetPath, { GetReplacements } from "@Helpers/GetPath";
import IsPathMatch from "@Helpers/IsPathMatch";
import ProposalNavigation from "@SmartComponents/Proposal/Navigation";
import { CUSTOMERS, FEEDBACK, LOGOUT, MY_ACCOUNT, MY_ORGANISATION, PROPOSAL_EDIT, PROPOSAL_SAVE, ROOT, TENDERS, TENDER_RESPONSES, } from "@Shared/Paths/Supplier";
import styles from "./index.module.scss";
const topRoutes = [
    {
        path: ROOT,
        name: "Dashboard",
        icon: React.createElement(Dashboard, { className: styles.icon }),
        exact: true,
        filter: (url) => !IsPathMatch(url, PROPOSAL_EDIT),
    },
    {
        path: TENDERS,
        name: "Search Tenders",
        icon: React.createElement(TenderIcon, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, PROPOSAL_EDIT),
    },
    {
        path: TENDER_RESPONSES,
        name: "My Responses",
        icon: React.createElement(TenderResponseIcon, { className: styles.icon }),
        filter: (url, company) => !IsPathMatch(url, PROPOSAL_EDIT) &&
            company?.incumbentCanView === undefined,
    },
    {
        path: CUSTOMERS,
        name: "Customers",
        icon: React.createElement(Customer, { className: styles.icon }),
        filter: (url, company) => !IsPathMatch(url, PROPOSAL_EDIT) &&
            company?.incumbentCanView === undefined,
    },
    {
        path: FEEDBACK,
        name: "Feedback",
        icon: React.createElement(GuidanceNotesIcon, { className: styles.icon }),
        filter: (url) => !IsPathMatch(url, PROPOSAL_EDIT),
    },
    {
        path: MY_ORGANISATION,
        name: "My Organisation",
        icon: React.createElement(OrganisationIcon, { className: styles.icon }),
        filter: (url, company) => !IsPathMatch(url, PROPOSAL_EDIT) &&
            company?.incumbentCanView === undefined,
    },
    {
        path: MY_ACCOUNT,
        name: "My Account",
        icon: React.createElement(ProfileIcon, { className: styles.icon }),
        filter: (url, company) => !IsPathMatch(url, PROPOSAL_EDIT) &&
            company?.incumbentCanView === undefined,
    },
];
const bottomRoutes = [
    {
        path: LOGOUT,
        name: "Logout",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
        filter: (url) => !IsPathMatch(url, PROPOSAL_EDIT),
    },
    {
        path: PROPOSAL_SAVE,
        name: "Save & Exit",
        icon: React.createElement(LogoutIcon, { className: styles.icon }),
        exact: true,
        filter: (url) => IsPathMatch(url, PROPOSAL_EDIT),
    },
];
export default (props) => {
    const location = useLocation();
    const topRoutesFiltered = topRoutes
        .filter(({ filter = (path, company) => !!path && !!company, }) => filter(location.pathname, props.company))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    const bottomRoutesFiltered = bottomRoutes
        .filter(({ filter = (path) => !!path }) => filter(location.pathname))
        .map((route) => {
        const replacements = GetReplacements(route.path, location.pathname);
        return { ...route, path: GetPath(route.path, ...replacements) };
    });
    let extra = React.createElement(React.Fragment, null), className;
    if (IsPathMatch(location.pathname, PROPOSAL_EDIT)) {
        extra = (React.createElement(ProposalNavigation, { responseId: location.pathname.replace(/.*?proposals\/(.+?)\/edit/, `$1`) }));
        className = styles.setWidth;
    }
    return (React.createElement(PrimaryNav, { topLinks: topRoutesFiltered, bottomLinks: bottomRoutesFiltered, className: className }, extra));
};
