import { ERROR } from "@Components/Toast";
import { addNotification } from "@Store/Actions/Notifications";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { passwordReset } from "@Api/Auth";
import Button from "@Components/Button";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import FormGroup from "@Components/Form/Group";
import PredictyIconEyesOpen from "@Components/Icons/ChatBot/Predicty/PredictyEyesOpen";
import buttons from "@Components/Sass/buttons.module.scss";
import GetPath from "@Helpers/GetPath";
import { LOGIN } from "@Shared/Paths/Auth";
import styles from "./../Login/index.module.scss";
class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { password: "", confirmPassword: "" },
            errors: {},
            redirect: false,
        };
    }
    inputChange = (data) => {
        this.setState({ data });
    };
    resetPassword = async () => {
        const { password, confirmPassword } = this.state.data;
        this.setState({ errors: {} });
        if (password !== confirmPassword) {
            this.setState({
                errors: { confirmPassword: "Passwords do not match" },
            });
            this.props.notify({
                title: "Failed to Reset",
                description: "Passwords don't match",
                status: ERROR,
            });
            return;
        }
        try {
            await passwordReset(this.props.resetId, password);
            this.setState({ redirect: true });
        }
        catch (error) {
            this.props.notify({
                title: "Failed to Reset Password",
                description: error.message || "Failed to reset password.",
                status: ERROR,
            });
            this.setState({
                errors: { apiError: true },
            });
        }
    };
    render() {
        if (this.state.redirect) {
            return React.createElement(Redirect, { push: true, to: GetPath(LOGIN) });
        }
        return (React.createElement("div", { className: styles.mainContent },
            React.createElement(Card, { className: styles.green },
                React.createElement("div", { className: styles.centered },
                    React.createElement(PredictyIconEyesOpen, { className: styles.logo })),
                React.createElement(Form, { className: styles.form, submit: this.resetPassword, labelLeft: false, input: [
                        {
                            label: "New Password",
                            name: "password",
                            type: InputType.Password,
                        },
                        {
                            label: "Confirm Password",
                            name: "confirmPassword",
                            type: InputType.Password,
                        },
                    ], buttons: false, change: this.inputChange, data: this.state.data, errors: this.state.errors },
                    React.createElement(FormGroup, null,
                        React.createElement("div", { className: `${buttons.buttonGroupCenter} ${styles.noMargin}` },
                            React.createElement(Button, { cta: true, type: "submit" }, "RESET PASSWORD")))))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
    };
};
export default connect(null, mapDispatchToProps)(PasswordReset);
