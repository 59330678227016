import { AllowanceTypes, ComponentTypes, Effected, ManagementFeeChargeMethod, Platform, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const tariffPresenceConstraints = {
    [Effected.PlanName]: {
        presence: true,
    },
    [Effected.Discount]: {
        presence: true,
    },
    [Effected.ProposalDependant]: {
        presence: true,
    },
    [Effected.SpecificChangesOrRequirements]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ProposalDependant, value: true },
        ],
    },
    [Effected.ESims]: {
        presence: true,
    },
    [Effected.IsManagementFee]: {
        presence: true,
    },
    [Effected.ManagementFeeChargeMethod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.IsManagementFee, value: true },
        ],
    },
    [Effected.ManagementFee]: {
        conditionalPresenceMatchAll: [
            { field: Effected.IsManagementFee, value: true },
        ],
    },
    [Effected.HasMinimumSpend]: {
        presence: true,
    },
    [Effected.MinimumSpend]: {
        conditionalPresenceMatchAll: [
            { field: Effected.HasMinimumSpend, value: true },
        ],
    },
    [Effected.ReducedHWFund]: {
        conditionalPresenceMatchAll: [
            { field: Effected.HasMinimumSpend, value: true },
        ],
    },
};
export const tariffValidConstraints = {
    [Effected.PlanName]: {
        presence: true,
        type: "string",
        length: {
            minimum: 1,
            maximum: 50,
        },
    },
    [Effected.TariffComponents]: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 50,
        },
    },
    [Effected.Discount]: {
        presence: true,
        type: "number",
    },
    [Effected.ProposalDependant]: {
        presence: true,
        type: "boolean",
    },
    [Effected.SpecificChangesOrRequirements]: {
        conditionalPresenceMatchAll: [
            { field: Effected.ProposalDependant, value: true },
        ],
        type: "string",
    },
    [Effected.ESims]: {
        presence: true,
        type: "boolean",
    },
    [Effected.IsManagementFee]: {
        presence: true,
        type: "boolean",
    },
    [Effected.ManagementFeeChargeMethod]: {
        conditionalPresenceMatchAll: [
            { field: Effected.IsManagementFee, value: true },
        ],
        enum: ManagementFeeChargeMethod,
    },
    [Effected.ManagementFee]: {
        conditionalPresenceMatchAll: [
            { field: Effected.IsManagementFee, value: true },
        ],
        type: "number",
    },
    [Effected.HasMinimumSpend]: {
        presence: true,
        type: "boolean",
    },
    [Effected.MinimumSpend]: {
        conditionalPresenceMatchAll: [
            { field: Effected.HasMinimumSpend, value: true },
        ],
        type: "number",
    },
    [Effected.ReducedHWFund]: {
        conditionalPresenceMatchAll: [
            { field: Effected.HasMinimumSpend, value: true },
        ],
        type: "boolean",
    },
};
export const tariffComponentConstraints = {
    [Effected.TariffComponentNetwork]: {
        presence: true,
        enum: Platform,
    },
    [Effected.TariffComponentType]: {
        presence: true,
        enum: ComponentTypes,
    },
    [Effected.TariffComponentTitle]: {
        presence: true,
        type: "string",
    },
    [Effected.TariffComponentNumberLines]: {
        presence: true,
        type: "number",
    },
    [Effected.TariffComponentStandardCost]: {
        presence: true,
        type: "number",
    },
    [Effected.TariffComponentDiscount]: {
        presence: true,
        type: "number",
    },
    [Effected.TariffComponentNetCost]: {
        presence: true,
        type: "number",
    },
    [Effected.TariffComponentTotalCost]: {
        presence: true,
        type: "number",
    },
};
export const allowanceConstraints = {
    [Effected.TariffComponentAllowanceType]: {
        presence: true,
        enum: AllowanceTypes,
    },
    [Effected.TariffComponentAllowanceQtyType]: {
        presence: true,
        enum: QTYType,
    },
    [Effected.TariffComponentAllowanceQty]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.TariffComponentAllowanceQtyType,
                value: QTYType.Unlimited,
                inverted: true,
            },
        ],
    },
};
