import SVG from "@Components/Icons/SVG";
export default class User extends SVG {
    path = [
        "M 17 51 ",
        "L 41 76",
        "L 37 80",
        "L 13 55",
        "Z",
        "M 36 51",
        "L 11 76",
        "L 15 80",
        "L 40 55",
        "Z",
        "M 65 12",
        "H 84",
        "C 85 12 88 11 88 16",
        "V 36",
        "C 88 41 89 41 84 41",
        "H 64 C 60 41 59 41 59 37",
        "V 16",
        "C 59 13 59 12 62 12",
        "Z",
        "M 58 76",
        "L 70 54",
        "C 73 51 75 51 78 54",
        "L 90 76",
        "C 93 82 88 81 84 81",
        "H 63",
        "C 59 81 58 81 57 79",
        "Z",
        "M 26 39",
        "V 41",
        "C 34 42 42 33 41 26",
        "V 25",
        "C 40 18 36 12 26 12",
        "V 12",
        "C 17 12 12 19 12 26",
        "V 27 C 11 32 17 41 26 41",
        "Z",
        "M 3 90",
        "H 98",
        "C 99 90 100 89 100 88",
        "V 3",
        "C 100 1 99 0 98 0",
        "H 3",
        "C 1 0 0 1 0 3",
        "V 88",
        "C 0 89 1 90 3 90",
        "Z",
        "M 6 86",
        "H 94",
        "C 97 87 96 84 96 85",
        "V 6",
        "C 96 4 96 4 95 4",
        "H 6",
        "C 4 4 4 4 4 6",
        "V 84",
        "C 4 86 4 86 6 86",
        "M 26 16",
        "C 20 16 16 20 16 26",
        "C 16 32 20 37 26 37",
        "C 32 37 37 32 37 26",
        "C 37 20 32 16 26 16",
        "M 74 56",
        "L 86 77",
        "H 62",
        "Z",
        "M 63 37",
        "H 84",
        "V 16",
        "H 63",
        "Z",
    ];
    evenOdd = true;
}
