// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navigation--f_MLK{display:flex;align-items:center;padding:var(--base-size) 0}.navigation--f_MLK .navBoxes--EtQ2X{flex:1}.navigation--f_MLK .navBoxes--EtQ2X .text--ilAzs{font-size:.75rem;white-space:nowrap;text-align:center}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/TableNav/index.module.scss"],"names":[],"mappings":"AAAA,mBAAY,YAAY,CAAC,kBAAkB,CAAC,0BAA0B,CAAC,oCAAsB,MAAM,CAAC,iDAA4B,gBAAgB,CAAC,kBAAkB,CAAC,iBAAiB","sourcesContent":[".navigation{display:flex;align-items:center;padding:var(--base-size) 0}.navigation .navBoxes{flex:1}.navigation .navBoxes .text{font-size:.75rem;white-space:nowrap;text-align:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "navigation--f_MLK",
	"navBoxes": "navBoxes--EtQ2X",
	"text": "text--ilAzs"
};
export default ___CSS_LOADER_EXPORT___;
