import { DetailsFields } from "@Shared/Entities/Customer/Tender/Details/enums";
export const getRemainingLines = (tender) => {
    const { details, stats } = tender || {};
    let linesLeftThree = Number.parseFloat(details.data[DetailsFields.VoiceLines] || 0) +
        Number.parseFloat(details.data[DetailsFields.VoiceOnlyLines] || 0) +
        Number.parseFloat(details.data[DetailsFields.DataLines] || 0);
    // No usage processed (stats) yet
    linesLeftThree =
        stats.totalLines === 0
            ? linesLeftThree - details.linesBeginning
            : Math.min(linesLeftThree, stats.totalLines) -
                details.linesBeginning;
    if (Number.isNaN(linesLeftThree) || linesLeftThree < 0) {
        linesLeftThree = 0;
    }
    let linesLeftSix = linesLeftThree - details.linesWithinThreeMonths;
    if (Number.isNaN(linesLeftSix) || linesLeftSix < 0) {
        linesLeftSix = 0;
    }
    return { linesLeftSix, linesLeftThree };
};
