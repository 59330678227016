import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, find, get, post, put } from "@Api/Suppliers/companies";
export const DELETE_SUPPLIER = "deleteSupplier";
export const deleteCompany = (dispatch, data) => del(data)
    .then(({ id }) => dispatch({
    type: DELETE_SUPPLIER,
    data: id,
}))
    .then(notify200(dispatch, API_TYPE.DELETE, "Supplier Deleted", "The supplier(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_SUPPLIER = "updateSupplier";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_SUPPLIER,
        data,
    });
};
export const createCompany = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Supplier Created", "The supplier was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateCompany = (dispatch, data) => put(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.PUT, "Supplier Updated", "The supplier was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchCompany = (dispatch, supplierId, inclDel) => find(supplierId, inclDel).then(add(dispatch));
export const fetchCompanies = (dispatch) => {
    return get(add(dispatch));
};
