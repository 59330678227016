import dayjs from "dayjs";
const baseVersion1 = (current = {}) => {
    const { baseVersion = 0 } = current;
    if (baseVersion >= 1) {
        return current;
    }
    return { ...current, baseVersion: 1 };
};
export default (original = {}) => {
    const converted = [baseVersion1].reduce((converted, f) => f(converted), original);
    let { baseVersion, id, createdAt, updatedAt, deletedAt, isFrozen, seqNo, primaryTerm, _seq_no, _primary_term, } = converted;
    createdAt = dayjs.utc(createdAt);
    updatedAt = updatedAt ? dayjs.utc(updatedAt) : createdAt.clone();
    if (deletedAt) {
        deletedAt = dayjs.utc(deletedAt);
    }
    if (_seq_no) {
        seqNo = _seq_no;
    }
    if (_primary_term) {
        primaryTerm = _primary_term;
    }
    return {
        baseVersion,
        id,
        createdAt,
        updatedAt,
        deletedAt,
        isFrozen,
        seqNo,
        primaryTerm,
    };
};
