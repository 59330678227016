import dayjs from "dayjs";
import { del, delayPublishDate, find, findForCustomer, findTenderForComparison, getForAdmin, getForCustomer, getForSupplier, invite, post, publish, put, unsubmit, } from "@Api/Customers/tenders";
export const ADD_TENDER = "updateTender";
const add = (dispatch) => (data) => {
    dispatch({
        type: ADD_TENDER,
        data,
    });
    return data;
};
export const createTender = (dispatch, data) => post(data).then(add(dispatch));
export const updateTender = (dispatch, data) => put(data).then(add(dispatch));
export const unsubmitTender = (dispatch, data) => unsubmit(data).then(add(dispatch));
export const autoSaveTender = (data) => put(data);
export const publishTender = (dispatch, data) => publish(data).then(add(dispatch));
export const fetchTender = (dispatch, tenderId, inclDel) => find(tenderId, inclDel).then(add(dispatch));
export const fetchTendersForCustomer = (dispatch, customerId) => getForCustomer(add(dispatch), customerId);
export const fetchTenderForCustomer = (dispatch, customerId, tenderId) => findForCustomer(customerId, tenderId).then(add(dispatch));
export const fetchTendersForSupplier = (dispatch, supplierId) => getForSupplier(add(dispatch), supplierId);
export const fetchTendersForAdmin = (dispatch) => getForAdmin(add(dispatch));
export const fetchTenderForComparison = (dispatch, customerId, tenderId) => findTenderForComparison(customerId, tenderId).then(add(dispatch));
export const inviteSupplier = (dispatch, customerId, tenderId, email) => invite(customerId, tenderId, email).then(add(dispatch));
export const changePublishDate = (dispatch, customerId, tenderId, publishDate) => delayPublishDate(customerId, tenderId, publishDate).then(add(dispatch));
export const DELETE_TENDER = "deleteTender";
export const deleteTender = (dispatch, tender) => del(tender).then((id) => {
    return dispatch({
        type: DELETE_TENDER,
        data: id,
    });
});
export const EDIT_ANSWER = "editAnswer";
export const editAnswer = (dispatch, data) => {
    dispatch({
        type: EDIT_ANSWER,
        data,
    });
    return Promise.resolve();
};
export const ADD_TENDER_CHAT = "addTenderChat";
export const addChat = (dispatch, data) => {
    dispatch({
        type: ADD_TENDER_CHAT,
        data: { ...data, timestamp: dayjs() },
    });
    return Promise.resolve();
};
export const ADD_FORM_DATA = "addFormData";
export const addFormData = (dispatch, data) => {
    dispatch({
        type: ADD_FORM_DATA,
        data,
    });
    return Promise.resolve();
};
