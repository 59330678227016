import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import Logout from "@Components/Pages/Logout";
import { LOGOUT, MY_ACCOUNT, MY_ORGANISATION, NOT_FOUND, ROOT, TENDERS, USAGES, } from "@Shared/Paths/Customer";
import MyAccount from "@SmartComponents/User/MyAccount";
import Dashboard from "./Dashboard";
import Organisation from "./MyOrganisation";
import Tenders from "./Tenders";
import Usage from "./Usage";
export default () => {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ROOT, exact: true },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: TENDERS },
            React.createElement(Tenders, null)),
        React.createElement(Route, { path: USAGES },
            React.createElement(Usage, null)),
        React.createElement(Route, { path: MY_ACCOUNT },
            React.createElement(MyAccount, null)),
        React.createElement(Route, { path: MY_ORGANISATION },
            React.createElement(Organisation, null)),
        React.createElement(Route, { path: LOGOUT },
            React.createElement(Logout, null)),
        React.createElement(Route, { path: NOT_FOUND },
            React.createElement(NotFoundFallback, null))));
};
