import { dataConvertFromTo } from "@Shared/Helpers/Data";
import { DataSize } from "../enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    const { analysis } = current;
    for (const k in current) {
        for (const kk in current[k]) {
            if (kk.startsWith("lte") && typeof analysis[k][kk] === "number") {
                analysis[k][kk] = { lines: analysis[k][kk], total: 0 };
            }
        }
    }
    return { ...current, analysis, version: 2 };
};
const version3 = (current = {}) => {
    let { version = 0, stats, analysis, superUsers, unitType } = current;
    if (version >= 3) {
        return current;
    }
    if (unitType !== undefined) {
        if (stats !== undefined) {
            stats = convertStats(stats, unitType);
        }
        if (analysis !== undefined) {
            analysis = convertAnalysis(analysis, unitType);
        }
        if (superUsers !== undefined) {
            superUsers = convertSuperUsers(superUsers, unitType);
        }
    }
    return { ...current, stats, analysis, superUsers, version: 3 };
};
export default (original = {}) => {
    const converted = [version1, version2, version3].reduce((converted, f) => f(converted), original);
    const { version, stats, analysis, superUsers, rackRate } = converted;
    return {
        version,
        stats,
        analysis,
        superUsers,
        rackRate,
    };
};
//convert to MB
const getConvertFunction = (unitType) => {
    return (value) => dataConvertFromTo(value, unitType, DataSize.MB);
};
const convertMonths = (months, convert) => {
    return Object.keys(months).reduce((months, monthKey) => {
        months[monthKey] = convert(months[monthKey]);
        return months;
    }, months);
};
const convertStats = (stats, unitType) => {
    const convert = getConvertFunction(unitType);
    stats.UK.Data = convertMonths(stats.UK.Data, convert);
    stats.Roaming.Data.total = convertMonths(stats.Roaming.Data.total, convert);
    if (stats.Roaming.Data.origin !== undefined) {
        stats.Roaming.Data.origin = Object.keys(stats.Roaming.Data.origin).reduce((originObject, origin) => {
            originObject[origin] = Object.keys(originObject[origin]).reduce((destinationObject, destination) => {
                destinationObject[destination] = convertMonths(destinationObject[destination], convert);
                return destinationObject;
            }, originObject[origin]);
            return originObject;
        }, stats.Roaming.Data.origin);
    }
    return stats;
};
const convertAnalysis = (analysis, unitType) => {
    const convert = getConvertFunction(unitType);
    return Object.keys(analysis).reduce((object, key) => {
        if (!key.endsWith("Data")) {
            return object;
        }
        let { total, highUsers, ...lteKeys } = object[key];
        highUsers = highUsers.map((user) => {
            user.usage = Object.keys(user.usage).reduce((originObject, origin) => {
                originObject[origin] = Object.keys(originObject[origin]).reduce((destinationObject, destination) => {
                    destinationObject[destination] = convertMonths(destinationObject[destination], convert);
                    return destinationObject;
                }, originObject[origin]);
                return originObject;
            }, user.usage);
            return user;
        });
        lteKeys = Object.keys(lteKeys).reduce((object_, key) => {
            const newKey = `lte${convert(Number(key.replace("lte", "")))}`;
            object_[newKey] = {
                lines: lteKeys[key].lines,
                total: convert(lteKeys[key].total),
            };
            return object_;
        }, {});
        object[key] = { total, highUsers, ...lteKeys };
        return object;
    }, analysis);
};
const convertSuperUsers = (superUsers, unitType) => {
    const convert = getConvertFunction(unitType);
    return superUsers.map((user) => {
        user.usage = Object.keys(user.usage).reduce((usageObject, key) => {
            if (!key.endsWith("Data")) {
                return usageObject;
            }
            usageObject[key] = convertMonths(usageObject[key], convert);
            return usageObject;
        }, user.usage);
        return user;
    });
};
