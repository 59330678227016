import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../Button/index.module.scss";
export default class ButtonLink extends React.Component {
    static defaultProps = {
        className: "",
        cta: false,
        dark: false,
        red: false,
        disabled: false,
        square: false,
        left: false,
        right: false,
        ctaCenter: false,
        blueCenter: false,
        ctaRight: false,
        arrow: false,
        type: "button",
        big: false,
        click: () => { },
    };
    getStyle = () => {
        const { props } = this;
        switch (true) {
            case props.cta:
                return styles.cta;
            case props.dark:
                return styles.dark;
            case props.red:
                return styles.red;
            case props.light:
                return styles.light;
            case props.square:
                return styles.square;
            case props.left:
                return styles.left;
            case props.right:
                return styles.right;
            case props.ctaCenter:
                return styles.ctaCenter;
            case props.blueCenter:
                return styles.blueCenter;
            case props.ctaRight:
                return styles.ctaRight;
        }
    };
    render() {
        const { className } = this.props;
        const style = this.getStyle();
        return (React.createElement(NavLink, { to: this.props.to, className: `${className} ${styles.button} ${style}`, target: this.props.target || "_self" },
            this.props.children,
            this.props.arrow && (React.createElement(FontAwesomeIcon, { icon: faArrowRight, className: "arrow" }))));
    }
}
