import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Scores extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { version } = this._data;
        const { pmScore, priceScore, tariffScore, csScore, signalScore, convenienceScore, pmScoreRank, priceScoreRank, tariffScoreRank, csScoreRank, signalScoreRank, convenienceScoreRank, pmScoreNormalised, priceScoreNormalised, tariffScoreNormalised, csScoreNormalised, signalScoreNormalised, convenienceScoreNormalised, pmScoreColor, priceScoreColor, tariffScoreColor, csScoreColor, signalScoreColor, convenienceScoreColor, lowMarginWarning, } = this;
        return {
            version,
            pmScore,
            priceScore,
            tariffScore,
            csScore,
            signalScore,
            convenienceScore,
            pmScoreRank,
            priceScoreRank,
            tariffScoreRank,
            csScoreRank,
            signalScoreRank,
            convenienceScoreRank,
            pmScoreNormalised,
            priceScoreNormalised,
            tariffScoreNormalised,
            csScoreNormalised,
            signalScoreNormalised,
            convenienceScoreNormalised,
            pmScoreColor,
            priceScoreColor,
            tariffScoreColor,
            csScoreColor,
            signalScoreColor,
            convenienceScoreColor,
            lowMarginWarning,
        };
    }
    //scores
    get pmScore() {
        return this._data.pmScore;
    }
    get priceScore() {
        return this._data.priceScore;
    }
    get tariffScore() {
        return this._data.tariffScore;
    }
    get csScore() {
        return this._data.csScore;
    }
    get signalScore() {
        return this._data.signalScore;
    }
    get convenienceScore() {
        return this._data.convenienceScore;
    }
    //ranks
    get pmScoreRank() {
        return this._data.pmScoreRank;
    }
    get priceScoreRank() {
        return this._data.priceScoreRank;
    }
    get tariffScoreRank() {
        return this._data.tariffScoreRank;
    }
    get csScoreRank() {
        return this._data.csScoreRank;
    }
    get signalScoreRank() {
        return this._data.signalScoreRank;
    }
    get convenienceScoreRank() {
        return this._data.convenienceScoreRank;
    }
    //Normalised
    get pmScoreNormalised() {
        return this._data.pmScoreNormalised;
    }
    get priceScoreNormalised() {
        return this._data.priceScoreNormalised;
    }
    get tariffScoreNormalised() {
        return this._data.tariffScoreNormalised;
    }
    get csScoreNormalised() {
        return this._data.csScoreNormalised;
    }
    get signalScoreNormalised() {
        return this._data.signalScoreNormalised;
    }
    get convenienceScoreNormalised() {
        return this._data.convenienceScoreNormalised;
    }
    //colors
    get pmScoreColor() {
        return this._data.pmScoreColor;
    }
    get priceScoreColor() {
        return this._data.priceScoreColor;
    }
    get tariffScoreColor() {
        return this._data.tariffScoreColor;
    }
    get csScoreColor() {
        return this._data.csScoreColor;
    }
    get signalScoreColor() {
        return this._data.signalScoreColor;
    }
    get convenienceScoreColor() {
        return this._data.convenienceScoreColor;
    }
    get lowMarginWarning() {
        return this._data.lowMarginWarning || false;
    }
}
