import React from "react";
import ButtonLink from "@Components/ButtonLink";
import PredictMascotLeftIcon from "@Components/Icons/PredictMascotLeft";
import buttons from "@Components/Sass/buttons.module.scss";
import { FEEDBACK, TENDERS } from "@Shared/Paths/Supplier";
import DashboardStats from "./DashboardStats";
import styles from "./index.module.scss";
import MyCustomerContracts from "./MyCustomerContracts";
import MyResponses from "./MyResponses";
export default class Dashboard extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.subHeader },
                React.createElement("div", { className: buttons.splitGroups },
                    React.createElement("div", { className: styles.subText }, "Your View"),
                    React.createElement("div", { className: buttons.buttonGroupRight },
                        React.createElement(ButtonLink, { to: TENDERS, className: buttons.buttons }, "SEARCH TENDERS"))),
                React.createElement("div", { className: styles.flexBox },
                    React.createElement(DashboardStats, null),
                    React.createElement("div", { className: styles.feedback },
                        "Feedback provided to help with future Tender responses",
                        React.createElement("div", { className: styles.button },
                            React.createElement(ButtonLink, { to: FEEDBACK, square: true }, "VIEW YOUR FEEDBACK"))))),
            React.createElement("div", { className: styles.actionable },
                React.createElement("div", { className: styles.tenders },
                    React.createElement("span", { className: styles.text }, "My Responses"),
                    React.createElement(MyResponses, null)),
                React.createElement("div", { className: styles.contracts },
                    React.createElement("span", { className: styles.text }, "My Customer Contracts"),
                    React.createElement(MyCustomerContracts, null))),
            React.createElement("div", { className: styles.dashboardImage },
                React.createElement(PredictMascotLeftIcon, null))));
    }
}
