import { collection } from "@Api/helpers";
import Factory from "@Shared/Factory";
import api from "..";
export const find = (supplierId, responseId, editing, inclDel) => {
    const url = `/suppliers/${supplierId}/responses/${responseId}${editing ? "/editing" : ""}`;
    return api
        .get(url, { params: { inclDel } })
        .then((result) => Factory.Response(result.data.item));
};
export const get = (callback, supplierId) => {
    const url = `/suppliers/${supplierId}/responses`;
    return collection((data) => callback(Factory.Response(data)), url);
};
export const post = (response) => {
    return api
        .post(`/suppliers/${response.supplierId}/responses`, response.toSave)
        .then((result) => {
        return Factory.Response(result.data.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
export const put = (response) => {
    return api
        .put(`/suppliers/${response.supplierId}/responses/${response.id}`, response.toSave)
        .then((result) => {
        return Factory.Response(result.data.item);
    });
};
export const unsubmit = (response) => {
    return api
        .put(`/suppliers/${response.supplierId}/responses/${response.id}/unsubmit`)
        .then((result) => {
        return Factory.Response(result.data.item);
    });
};
export const publish = (response) => {
    return api
        .put(`/suppliers/${response.supplierId}/responses/${response.id}/publish`, response.toSave)
        .then((result) => {
        return Factory.Response(result.data.item);
    });
};
export const del = (response) => {
    return api
        .delete(`/suppliers/${response.supplierId}/responses/${response.id}`)
        .then((result) => {
        return result.data.response.item.id;
    });
};
export const stopEditing = (response) => {
    return api.get(`/suppliers/${response.supplierId}/responses/${response.id}/stop-editing`);
};
