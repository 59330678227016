import { CustomerType } from "@Shared/Entities/AbstractEntities/Customer/enums";
const customerVersion1 = (current = {}) => {
    const { customerVersion = 0 } = current;
    if (customerVersion >= 1) {
        return current;
    }
    return { ...current, customerVersion: 1 };
};
const customerVersion2 = (current = {}) => {
    const { customerVersion = 0, type = CustomerType.Predict } = current;
    if (customerVersion >= 2) {
        return current;
    }
    return { ...current, type, customerVersion: 2 };
};
export default (original = {}) => {
    const converted = [customerVersion1, customerVersion2].reduce((converted, f) => f(converted), { ...original });
    const { customerVersion, type, canViewSensabill = false, canCreateTender = false, } = converted;
    return {
        customerVersion,
        type,
        canViewSensabill,
        canCreateTender,
    };
};
