import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import GetPath from "@Helpers/GetPath";
import { fetchTendersForCustomer as fetchTenders } from "@Store/Actions/Customers/tenders";
import { addNotification } from "@Store/Actions/Notifications";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { TENDERS, USAGES } from "@Shared/Paths/Customer";
class Dashboard extends React.Component {
    responsesCard;
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        this.props
            .fetchTenders(this.props.company?.id)
            .then(() => this.setState({ fetching: false }));
    }
    render() {
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        if (this.props.tenders.length > 0 ||
            this.props.company?.canCreateTender) {
            return React.createElement(Redirect, { to: GetPath(TENDERS) });
        }
        if (this.props.company?.canViewSensabill) {
            return React.createElement(Redirect, { to: GetPath(USAGES) });
        }
        return React.createElement(React.Fragment, null);
    }
}
const mapStateToProps = ({ auth: { user }, customers: { companies, tenders }, }) => ({
    user,
    tenders,
    company: companies.find((c) => c?.id === user.customerId),
});
const mapDispatchToProps = (dispatch) => {
    return {
        notify: (data) => addNotification(dispatch, data),
        fetchTenders: (customerId) => fetchTenders(dispatch, customerId),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
