export default {
    customerId: {
        presence: true,
        type: "string",
    },
    userId: {
        type: "string",
    },
    usageId: {
        type: "string",
    },
    publishedAt: {
        type: "Dayjs",
    },
    supplierDeadline: {
        type: "Dayjs",
    },
    customerDeadline: {
        type: "Dayjs",
    },
    // details: {
    //     entity: TenderDetails,
    // },
    awarded: {
        type: "boolean",
    },
    postcode: {
        type: "string",
    },
    supplierInvited: {
        type: "array",
    },
    whitelist: {
        type: "array",
    },
    changedDefaultPublishDate: {
        type: "boolean",
    },
};
