import Card from "@Components/Card";
import HoverTooltip from "@Components/HoverTooltip";
import Close from "@Components/Icons/Close";
import InfoPopup from "@Components/InfoPopup";
import { DataSize } from "@Shared/Entities/Customer/Usage/enums";
import { Duration, UnitType, } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
import { dataConvert } from "@Shared/Helpers/Data";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import React from "react";
import styles from "./index.module.scss";
const displayBundle = (bundle) => {
    const displayOffered = (title, value) => {
        return (React.createElement("div", { className: styles.row },
            React.createElement("div", { className: styles.title }, title),
            React.createElement("div", { className: styles.val }, value)));
    };
    const values = {
        [UnitType.Data]: bundle.dataMB === undefined
            ? bundle.roamLikeHome
                ? "Same as UK"
                : ""
            : bundle.dataMB === "Unlimited"
                ? bundle.dataMB
                : `${bundle.dataMB} MB`,
        [UnitType.SMS]: bundle.sms === undefined
            ? bundle.roamLikeHome
                ? "Same as UK"
                : ""
            : bundle.sms,
        [UnitType.MMS]: bundle.mms === undefined
            ? bundle.roamLikeHome
                ? "Same as UK"
                : ""
            : bundle.mms,
        [UnitType.Mins]: bundle.mins === undefined
            ? bundle.roamLikeHome
                ? "Same as UK"
                : ""
            : bundle.mins === "Unlimited"
                ? bundle.mins
                : `${bundle.mins} mins`,
    };
    const numberText = bundle.duration === Duration.Per_Day
        ? "Number of Days"
        : "Number of Applied Bundles";
    const costText = bundle.duration === Duration.Per_Day
        ? "Cost per Day"
        : "Cost per Bundle";
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.col },
            React.createElement("div", { className: `${styles.title} ${styles.name}` }, bundle.name),
            React.createElement("div", null, bundle.roamLikeHome ? (React.createElement(React.Fragment, null,
                "Roam Like Home",
                React.createElement(HoverTooltip, null, "Allows UK allowances to be used while\u00A0roaming."))) : (""))),
        React.createElement("div", { className: `${styles.col} ${styles.middle}` }, bundle.applyToRows.map((key) => {
            const type = GetUnits(key);
            return (React.createElement("div", { key: key, className: styles.row },
                React.createElement("div", { className: styles.title }, key),
                type === UnitType.Data ? (React.createElement("div", { className: styles.val }, Number.isNaN(Number.parseInt(values[type].split(" ")[0]))
                    ? values[type]
                    : dataConvert(Number.parseInt(values[type].split(" ")[0]), DataSize.MB))) : (React.createElement("div", { className: styles.val }, values[type]))));
        })),
        React.createElement("div", { className: styles.col },
            displayOffered("Duration", displayEnum(Duration, bundle.duration)),
            displayOffered(costText, format(bundle.cost)),
            bundle.used > 0 && (React.createElement(React.Fragment, null,
                displayOffered(numberText, bundle.used),
                displayOffered("Total Cost", format(bundle.cost * bundle.used)))))));
};
const titles = () => {
    return (React.createElement("div", { className: `${styles.wrapper} ${styles.titles}` },
        React.createElement("div", { className: styles.col }, "Name"),
        React.createElement("div", { className: styles.col }, "Covering"),
        React.createElement("div", { className: styles.col }, "Details")));
};
export default (props) => {
    const { bundles = [] } = props;
    const used = bundles.some(({ used = 0 }) => used > 0);
    const totalCost = bundles.reduce((total, bundle) => {
        const bundleTotal = bundle.cost * bundle.used;
        return total + bundleTotal;
    }, 0);
    const popup = () => {
        return (React.createElement(Card, { className: styles.card },
            React.createElement("div", { className: styles.header },
                React.createElement("div", { className: styles.mainTitle },
                    "Bundles",
                    " ",
                    used
                        ? `Applied Based On Current Usage`
                        : `Available For Future Use`),
                React.createElement("div", { className: styles.exit },
                    React.createElement(Close, { onClick: props.onClose, color: "#58585a", size: "0.75rem", className: styles.button }))),
            titles(),
            bundles.map((element) => displayBundle(element)),
            used && (React.createElement("div", { className: styles.totalCost },
                React.createElement("div", null,
                    "Total Estimated Cost, included in",
                    " ",
                    React.createElement("span", { onClick: props.openOOB, className: styles.varLink }, "Variable"),
                    " ",
                    "Cost"),
                React.createElement("div", { className: styles.totalBox }, format(totalCost))))));
    };
    const shownWithSummary = !props.comparison && window.innerWidth > 950;
    return (React.createElement(InfoPopup, { trigger: bundles.length > 0, content: popup, onClose: props.onClose, overlayStyles: shownWithSummary
            ? {
                background: "none",
                pointerEvents: "none",
            }
            : {}, styles: shownWithSummary
            ? {
                marginRight: "470px",
            }
            : {}, closeOnDocumentClick: !shownWithSummary }));
};
