import React from "react";
import UiError from "@Components/Icons/Error";
import Tick from "@Components/Icons/Tick";
import { Status } from "./enums";
import styles from "./index.module.scss";
export default class StateNavigationItem extends React.Component {
    render() {
        return (React.createElement("div", { className: `${styles.link} ${this.props.active ? styles.active : ""}`, onClick: this.props.click },
            React.createElement("div", { className: styles.circle }, this.props.status === Status.Complete ? (React.createElement("div", { className: styles.completeIcon },
                React.createElement(Tick, { success: true, size: "1rem" }))) : this.props.status === Status.Issue ? (React.createElement("div", { className: styles.errorIcon },
                React.createElement(UiError, { error: true, size: "1rem" }))) : (React.createElement(React.Fragment, null))),
            React.createElement("div", { className: styles.text }, this.props.name)));
    }
}
