import React from "react";
import Badge from "@Components/Badge";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import PiggyBank from "@Components/Icons/PiggyBank";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default function Savings(props) {
    const { tender, dateRange = "", costCentres = [] } = props;
    if (tender === undefined) {
        return React.createElement(LoadingFallback, null);
    }
    const newTariff = tender.stats.totalLines - tender.stats.unusedLines;
    const badgeWidth = Math.max(newTariff.toString().length, tender.stats.unusedLines.toString().length);
    return (React.createElement(ColouredBorderBox, { icon: React.createElement(PiggyBank, null), title: React.createElement(React.Fragment, null,
            "Predicted",
            " ",
            React.createElement("b", { className: styles.primaryColor }, " Total Saving"),
            costCentres.length > 0 && (React.createElement("div", null,
                "for ",
                costCentres.join(", ")))), primary: true },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: `${styles.message} ${styles.inverted}` },
                    React.createElement("div", { className: styles.messageTitle }, "Total saving"),
                    React.createElement("div", { className: styles.messageText }, "At contract renewal you will save at least"),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement("div", { className: styles.mainNumber }, Number.isNaN(tender.totalSavings) ? (React.createElement(React.Fragment, null, "\u00A30")) : (format(tender.totalSavings, 0))))),
                React.createElement("div", { className: styles.title }, "Saving consists of two components..."),
                React.createElement("div", { className: `${styles.message}` },
                    React.createElement("div", { className: styles.messageTitle }, "New tariff"),
                    React.createElement("div", { className: styles.messageText },
                        React.createElement(Badge, { width: badgeWidth }, newTariff),
                        " ",
                        React.createElement("span", null,
                            "lines actively used on your account ",
                            dateRange,
                            " ",
                            "at contract renewal saves")),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement("div", { className: styles.mainNumber }, Number.isNaN(tender.benchmarkSavings) ? (React.createElement(React.Fragment, null, "\u00A30")) : (format(tender.benchmarkSavings, 0))))),
                React.createElement("div", { className: `${styles.message}` },
                    React.createElement("div", { className: styles.messageTitle },
                        "Unused lines ",
                        dateRange),
                    React.createElement("div", { className: styles.messageText },
                        React.createElement(Badge, { width: badgeWidth }, tender.stats.unusedLines),
                        " ",
                        React.createElement("span", null, "lines if removed will save")),
                    React.createElement("div", { className: styles.messageContent },
                        React.createElement("div", { className: styles.mainNumber }, Number.isNaN(tender.unusedLinesSavings) ? (React.createElement(React.Fragment, null, "\u00A30")) : (format(tender.unusedLinesSavings, 0))))),
                props.slot))));
}
