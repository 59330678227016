import React from "react";
import styles from "./index.module.scss";
export default class O2PlanIcon extends React.Component {
    render() {
        return (React.createElement("svg", { viewBox: "0 0 150 150", xmlns: "http://www.w3.org/2000/svg", className: `${this.props.size ? "" : styles.o2plan} ${this.props.className || ""}`, style: { width: this.props.size } },
            React.createElement("title", null, "O2 Plan.com Logo"),
            React.createElement("g", null,
                React.createElement("path", { className: styles.cls0, d: "M 60.12 0.84 C 70.69 0.27 81.65 2.86 90.22 9.25 C 98.41 15.24 103.99 24.4 106.28 34.23 A 54 54 90 0 1 106.22 59.23 C 103.27 71.26 95.28 82.1 84.25 87.9 C 76.66 91.98 67.86 93.61 59.3 92.93 A 43.57 43.57 90 0 1 33.49 82.38 C 24.83 74.84 19.51 63.86 18.3 52.52 C 17.28 42.49 18.86 32.07 23.62 23.14 A 41.12 41.12 90 0 1 43.42 4.69 A 47.8 47.8 90 0 1 60.12 0.84 Z M 59.34 14.17 A 25.22 25.22 90 0 0 43.21 23.35 C 37.54 30.3 35.02 39.47 35.34 48.35 C 35.59 56.3 37.94 64.4 42.97 70.66 A 24.43 24.43 90 0 0 58.12 79.52 C 64.25 80.51 70.84 79.69 76.2 76.43 C 81.92 73.04 85.88 67.27 88.05 61.09 C 91.37 51.55 91.28 40.76 87.35 31.41 C 84.84 25.41 80.56 19.99 74.79 16.86 C 70.11 14.29 64.6 13.47 59.34 14.17 Z" }),
                React.createElement("path", { className: styles.cls0, d: "M 106.82 75.89 A 26.2 26.2 90 0 1 119.95 73.17 C 123.04 73.39 126.28 74.46 128.31 76.93 C 130.71 79.89 131.1 84.03 130.31 87.65 C 128.91 93 125.18 97.3 121.46 101.23 C 119.67 103.09 117.83 104.89 115.96 106.66 C 121.27 106.7 126.57 106.66 131.89 106.68 C 131.86 108.7 131.89 110.73 131.89 112.76 Q 118.92 112.76 105.97 112.76 V 106.26 C 110.91 101.63 116.09 97.14 119.98 91.56 C 121.34 89.53 122.65 87.32 122.83 84.82 A 4.8 4.8 90 0 0 120.75 80.49 C 118.82 79.15 116.32 79.15 114.09 79.52 C 111.7 80 109.51 81.12 107.47 82.4 C 107.25 80.23 107.06 78.06 106.82 75.89 Z" })),
            React.createElement("g", null,
                React.createElement("rect", { y: "115", width: "150", height: "35", className: styles.st2 }),
                React.createElement("path", { className: styles.st0, d: "M 71.98 138.12 C 71.98 137.72 72.05 137.31 72.2 136.95 C 72.34 136.58 72.56 136.25 72.82 135.99 C 73.08 135.74 73.41 135.52 73.78 135.33 C 74.14 135.18 74.55 135.07 74.95 135.07 C 75.35 135.07 75.76 135.15 76.12 135.33 C 76.49 135.48 76.82 135.7 77.08 135.99 C 77.34 136.25 77.56 136.58 77.74 136.95 C 77.89 137.31 77.96 137.72 77.96 138.12 C 77.96 138.52 77.89 138.93 77.74 139.3 C 77.59 139.66 77.37 139.99 77.08 140.25 C 76.82 140.51 76.49 140.73 76.12 140.87 C 75.76 141.02 75.35 141.09 74.95 141.09 C 74.55 141.09 74.14 141.02 73.78 140.87 C 73.41 140.73 73.08 140.51 72.82 140.25 C 72.56 139.99 72.34 139.66 72.2 139.3 C 72.05 138.93 71.98 138.52 71.98 138.12" }),
                React.createElement("path", { className: styles.st1, d: "M 96.79 138.71 C 96.02 139.55 95.03 140.25 93.78 140.87 C 92.53 141.46 91.06 141.79 89.37 141.79 C 87.87 141.79 86.55 141.53 85.3 141.06 C 84.09 140.58 83.06 139.88 82.18 139 C 81.34 138.12 80.64 137.09 80.16 135.88 C 79.68 134.67 79.43 133.35 79.43 131.92 C 79.43 130.56 79.68 129.28 80.16 128.1 C 80.64 126.93 81.34 125.86 82.22 124.98 C 83.1 124.1 84.13 123.4 85.34 122.89 C 86.55 122.38 87.87 122.12 89.3 122.12 C 90.95 122.12 92.42 122.38 93.63 122.93 C 94.84 123.48 95.91 124.21 96.71 125.13 L 93.3 128.8 C 92.9 128.32 92.38 127.92 91.76 127.55 C 91.17 127.18 90.36 127 89.37 127 C 88.68 127 88.09 127.15 87.54 127.4 C 86.99 127.7 86.55 128.03 86.18 128.47 C 85.81 128.91 85.52 129.42 85.34 130.01 C 85.15 130.6 85.04 131.22 85.04 131.88 C 85.04 132.47 85.15 133.09 85.34 133.68 C 85.52 134.27 85.81 134.78 86.18 135.26 C 86.55 135.7 87.02 136.1 87.54 136.36 C 88.09 136.65 88.68 136.8 89.37 136.8 C 90.33 136.8 91.14 136.62 91.8 136.25 C 92.46 135.88 92.97 135.44 93.37 135 L 96.79 138.71 Z" }),
                React.createElement("path", { className: styles.st1, d: "M 1 122.74 H 6.39 C 6.43 123.07 6.47 123.4 6.5 123.73 C 6.54 124.06 6.54 124.4 6.58 124.73 C 7.17 123.95 7.94 123.4 8.89 122.96 C 9.84 122.56 10.8 122.38 11.83 122.38 C 13.15 122.38 14.32 122.63 15.35 123.15 C 16.38 123.66 17.22 124.36 17.88 125.24 C 18.54 126.12 19.06 127.11 19.42 128.25 C 19.75 129.39 19.94 130.56 19.94 131.77 C 19.94 133.46 19.64 134.96 19.06 136.21 C 18.47 137.46 17.74 138.49 16.82 139.3 C 15.9 140.1 14.87 140.69 13.73 141.09 C 12.6 141.5 11.46 141.68 10.32 141.68 C 9.44 141.68 8.71 141.61 8.12 141.46 C 7.53 141.31 7.02 141.13 6.54 140.98 V 148.95 H 1 V 122.74 Z M 6.62 136.29 C 7.35 136.8 8.41 137.02 9.88 137.02 C 11.28 137.02 12.34 136.54 13.15 135.63 C 13.96 134.71 14.36 133.46 14.36 131.92 C 14.36 131.33 14.29 130.74 14.14 130.16 C 13.99 129.57 13.77 129.09 13.48 128.65 C 13.18 128.21 12.78 127.88 12.3 127.62 C 11.83 127.37 11.24 127.22 10.51 127.22 C 9.88 127.22 9.33 127.33 8.85 127.59 C 8.38 127.81 7.97 128.14 7.64 128.54 C 7.31 128.95 7.06 129.39 6.91 129.86 C 6.73 130.38 6.65 130.85 6.65 131.4 V 136.29 Z" }),
                React.createElement("rect", { x: "21.8", y: "115", className: styles.st1, width: "5.6", height: "26.1" }),
                React.createElement("path", { className: styles.st1, d: "M 42.98 139.41 C 42.36 140.14 41.55 140.69 40.6 141.09 C 39.65 141.5 38.58 141.68 37.41 141.68 C 36.75 141.68 36.09 141.61 35.35 141.42 C 34.62 141.24 33.96 140.95 33.37 140.51 C 32.75 140.07 32.23 139.48 31.83 138.78 C 31.42 138.05 31.2 137.13 31.2 136.03 C 31.2 134.96 31.42 134.01 31.86 133.17 C 32.31 132.32 32.89 131.63 33.66 131.07 C 34.43 130.52 35.31 130.08 36.34 129.79 C 37.37 129.5 38.51 129.35 39.68 129.35 C 40.34 129.35 41 129.35 41.66 129.39 C 42.32 129.42 42.76 129.46 42.98 129.5 V 129.09 C 42.98 128.62 42.87 128.21 42.69 127.88 C 42.51 127.55 42.25 127.29 41.92 127.07 C 41.59 126.85 41.22 126.71 40.82 126.6 C 40.42 126.52 39.98 126.45 39.5 126.45 C 38.25 126.45 37.22 126.6 36.38 126.93 C 35.57 127.26 34.87 127.59 34.29 127.99 L 31.94 124.54 C 32.34 124.25 32.78 123.95 33.26 123.7 C 33.77 123.4 34.36 123.15 35.02 122.93 C 35.68 122.71 36.45 122.49 37.3 122.38 C 38.14 122.23 39.13 122.16 40.2 122.16 C 42.98 122.16 45.04 122.89 46.4 124.4 C 47.72 125.9 48.38 127.92 48.38 130.45 V 141.13 H 43.09 L 42.98 139.41 Z M 42.98 133.02 C 42.84 132.98 42.62 132.95 42.25 132.91 C 41.88 132.87 41.48 132.84 41 132.84 C 39.42 132.84 38.32 133.06 37.66 133.5 C 37 133.94 36.67 134.56 36.67 135.37 C 36.67 136.87 37.59 137.64 39.42 137.64 C 39.87 137.64 40.31 137.57 40.75 137.42 C 41.19 137.28 41.55 137.09 41.88 136.8 C 42.21 136.54 42.47 136.21 42.69 135.81 C 42.91 135.41 43.02 134.96 43.02 134.45 V 133.02 Z" }),
                React.createElement("path", { className: styles.st1, d: "M 50.28 122.74 H 55.67 L 55.82 124.73 C 56.41 123.99 57.22 123.4 58.21 122.96 C 59.2 122.52 60.22 122.3 61.29 122.3 C 63.42 122.3 65.07 122.93 66.32 124.18 C 67.56 125.42 68.19 127.29 68.19 129.75 V 141.13 H 62.54 V 130.56 C 62.54 129.53 62.28 128.73 61.77 128.14 C 61.25 127.55 60.55 127.22 59.64 127.22 C 59.01 127.22 58.46 127.33 57.99 127.55 C 57.51 127.77 57.14 128.07 56.81 128.43 C 56.48 128.8 56.26 129.24 56.11 129.72 C 55.97 130.19 55.89 130.71 55.89 131.26 V 141.13 H 50.28 V 122.74 Z" }),
                React.createElement("path", { className: styles.st1, d: "M 97.22 131.92 C 97.22 130.56 97.48 129.28 97.95 128.1 C 98.43 126.93 99.13 125.86 99.97 124.98 C 100.85 124.1 101.88 123.4 103.09 122.89 C 104.3 122.38 105.66 122.12 107.09 122.12 C 108.56 122.12 109.88 122.38 111.09 122.89 C 112.3 123.4 113.33 124.1 114.17 124.98 C 115.02 125.86 115.72 126.93 116.19 128.1 C 116.67 129.28 116.93 130.56 116.93 131.92 C 116.93 133.28 116.67 134.56 116.19 135.74 C 115.72 136.91 115.05 137.97 114.17 138.86 C 113.29 139.74 112.27 140.43 111.09 140.98 C 109.88 141.5 108.56 141.75 107.09 141.75 C 105.62 141.75 104.3 141.5 103.09 140.98 C 101.88 140.47 100.85 139.77 99.97 138.86 C 99.13 137.97 98.43 136.95 97.95 135.74 C 97.48 134.56 97.22 133.28 97.22 131.92 M 107.09 136.87 C 107.79 136.87 108.41 136.73 108.96 136.47 C 109.51 136.21 109.99 135.81 110.36 135.37 C 110.72 134.89 111.02 134.38 111.2 133.79 C 111.38 133.2 111.46 132.58 111.46 131.96 C 111.46 131.33 111.38 130.71 111.2 130.12 C 111.02 129.53 110.72 129.02 110.36 128.54 C 109.99 128.1 109.51 127.73 108.96 127.44 C 108.41 127.18 107.79 127.04 107.09 127.04 C 106.39 127.04 105.73 127.18 105.18 127.44 C 104.63 127.73 104.15 128.07 103.79 128.54 C 103.42 128.98 103.13 129.53 102.94 130.12 C 102.76 130.71 102.65 131.33 102.65 131.96 C 102.65 132.58 102.76 133.2 102.94 133.79 C 103.13 134.38 103.42 134.89 103.79 135.37 C 104.15 135.85 104.63 136.21 105.18 136.47 C 105.77 136.73 106.39 136.87 107.09 136.87" }),
                React.createElement("path", { className: styles.st1, d: "M 119.75 122.74 H 125.15 L 125.33 124.8 C 125.92 124.1 126.65 123.51 127.53 123.04 C 128.41 122.56 129.48 122.34 130.65 122.34 C 131.39 122.34 132.05 122.41 132.6 122.56 C 133.18 122.71 133.66 122.93 134.1 123.18 C 134.54 123.44 134.91 123.7 135.24 123.99 C 135.57 124.29 135.83 124.58 136.05 124.87 C 136.82 124.06 137.74 123.44 138.8 123 C 139.9 122.56 141 122.34 142.18 122.34 C 144.52 122.34 146.32 123 147.57 124.32 C 148.82 125.64 149.44 127.48 149.44 129.75 V 141.13 H 143.86 V 130.67 C 143.86 129.57 143.61 128.73 143.13 128.14 C 142.65 127.55 141.92 127.22 140.96 127.22 C 139.86 127.22 138.98 127.59 138.32 128.36 C 137.66 129.13 137.33 130.08 137.33 131.26 V 141.13 H 131.9 V 130.67 C 131.9 129.57 131.64 128.73 131.17 128.14 C 130.69 127.55 129.95 127.22 129 127.22 C 127.94 127.22 127.06 127.59 126.39 128.36 C 125.73 129.13 125.4 130.08 125.4 131.26 V 141.13 H 119.75 V 122.74 Z" }))));
    }
}
