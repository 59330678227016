export const getSubdomain = () => window.location.host.split(".")[0];
export const getDomain = () => window.location.host.split(".").slice(1).join(".");
export const getPath = () => window.location.pathname;
export const isPlatform = () => {
    const subdomain = getSubdomain();
    switch (subdomain) {
        case "platform":
        case "localhost:9000":
        case "127":
            return true;
        default:
            return false;
    }
};
export default getSubdomain;
