import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import constraints from "./constraints";
export default class User extends BaseEntity {
    constructor(data = {}, extraConstraints = {}) {
        super(data, { ...constraints, ...extraConstraints });
    }
    get data() {
        const parent = super.data;
        const { email, password, isPasswordHashed, mobileNumber, lastLogin, type, resetId, singleUsePassword, name, activated, mfaEnabled, assumed, } = this;
        const { userVersion } = this._data;
        return {
            ...parent,
            email,
            password,
            isPasswordHashed,
            mobileNumber,
            lastLogin,
            userVersion,
            type,
            resetId,
            singleUsePassword,
            name,
            activated,
            mfaEnabled,
            assumed,
        };
    }
    get toSave() {
        const { assumed, ...data } = this.data;
        const { mfaSecret } = this;
        return { ...data, mfaSecret };
    }
    get email() {
        return this._data.email;
    }
    get password() {
        return this._data.password;
    }
    get isPasswordHashed() {
        return this._data.isPasswordHashed;
    }
    get name() {
        return this._data.name;
    }
    get mobileNumber() {
        return this._data.mobileNumber;
    }
    get lastLogin() {
        return this._data.lastLogin;
    }
    get singleUsePassword() {
        return this._data.singleUsePassword;
    }
    get resetId() {
        return this._data.resetId;
    }
    get activated() {
        return this._data.activated;
    }
    get mfaEnabled() {
        return this._data.mfaEnabled;
    }
    get mfaSecret() {
        return this._data.mfaSecret;
    }
    get assumed() {
        return this._data.assumed;
    }
}
