// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".group--m3Cll{display:flex;gap:10px;padding:calc(var(--standard-vertical-padding) * 0) calc(var(--standard-horizontal-padding) * 2)}.group--m3Cll .messages--ZLcBN{display:flex;flex:1;flex-direction:column-reverse;gap:10px;margin-bottom:var(--standard-vertical-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/MessageGroup/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,YAAY,CAAC,QAAQ,CAAC,+FAA+F,CAAC,+BAAiB,YAAY,CAAC,MAAM,CAAC,6BAA6B,CAAC,QAAQ,CAAC,8CAA8C","sourcesContent":[".group{display:flex;gap:10px;padding:calc(var(--standard-vertical-padding) * 0) calc(var(--standard-horizontal-padding) * 2)}.group .messages{display:flex;flex:1;flex-direction:column-reverse;gap:10px;margin-bottom:var(--standard-vertical-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "group--m3Cll",
	"messages": "messages--ZLcBN"
};
export default ___CSS_LOADER_EXPORT___;
