import SVG from "@Components/Icons/SVG";
import getBrokerDomain, { BrokerDomain } from "@Helpers/GetBrokerDomain";
export default class Score extends SVG {
    path = [
        "M 81.3307 82.9687 C 81.2512 83.5184 81.1829 84.0634 81.1268 84.6032 C 71.3803 93.4706 58.3662 98.8803 44.1127 98.8803 C 16.0563 98.8803 -1.9155 83.5435 1.6901 63.8009 C 1.9718 62.574 2.3099 61.2913 2.6479 60.0643 C 2.7606 59.7855 2.8169 59.5624 2.8732 59.3393 C 4.6197 54.2643 7.3239 48.9103 10.7042 43.6122 C 21.9718 26.1004 41.1268 8.8674 59.7183 2.2866 C 82.5352 -5.7443 98.8732 14.8348 98.8732 44.7276 C 98.8732 60.5105 92.0563 74.6761 81.1831 84.5474 C 81.2362 84.0222 81.2854 83.4959 81.3307 82.9687 Z",
        "M 17.6307 35.7165 C 17.2738 36.0561 16.918 36.4014 16.5634 36.7525 C 16.9159 36.4044 17.2717 36.059 17.6307 35.7165 Z",
    ];
    getFill() {
        const broker = getBrokerDomain();
        let fill;
        switch (broker) {
            case BrokerDomain.BDO:
                fill = this.props.highScore ? "#e81a3b" : "#d01735";
                break;
            default:
                fill = this.props.highScore ? "#25a85c" : "#79c354";
        }
        return fill;
    }
    fill = this.getFill();
}
