// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progressRail--g3QFg{display:flex;align-items:center;width:100%;height:16px;padding:0 .5em;background-color:#f0f6f6;border-radius:50px}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProgressBar/index.module.scss"],"names":[],"mappings":"AAAA,qBAAc,YAAY,CAAC,kBAAkB,CAAC,UAAU,CAAC,WAAW,CAAC,cAAc,CAAC,wBAAwB,CAAC,kBAAkB","sourcesContent":[".progressRail{display:flex;align-items:center;width:100%;height:16px;padding:0 .5em;background-color:#f0f6f6;border-radius:50px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressRail": "progressRail--g3QFg"
};
export default ___CSS_LOADER_EXPORT___;
