import React from "react";
import styles from "./index.module.scss";
export default class Options extends React.Component {
    select = (option) => {
        const value = option.value;
        const label = option.label;
        this.props.select({ value, label });
    };
    render() {
        const options = this.props.options.map(({ label, value }, index) => {
            const selectedValue = this.props.selected.value || [];
            return (React.createElement("div", { className: `${styles.option} ${(Array.isArray(selectedValue)
                    ? selectedValue.includes(value)
                    : selectedValue === value)
                    ? styles.selected
                    : undefined}`, key: index, onClick: () => {
                    this.select({ label, value });
                } }, label));
        });
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.options }, options)));
    }
}
