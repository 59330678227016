// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--NLIO9{position:sticky;top:0;z-index:100;margin-right:-1.2em;margin-left:-1.2em;padding:1em 2.5em 1em 2.5em;background-color:#fff;border-radius:0}", "",{"version":3,"sources":["webpack://./src/gui/Components/TenderBoxes/TenderFilter/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,eAAe,CAAC,KAAK,CAAC,WAAW,CAAC,mBAAmB,CAAC,kBAAkB,CAAC,2BAA2B,CAAC,qBAAqB,CAAC,eAAe","sourcesContent":[".header{position:sticky;top:0;z-index:100;margin-right:-1.2em;margin-left:-1.2em;padding:1em 2.5em 1em 2.5em;background-color:#fff;border-radius:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--NLIO9"
};
export default ___CSS_LOADER_EXPORT___;
