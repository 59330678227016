import { generate as uuidv4 } from "short-uuid";
import { ToastStatus } from "@Components/Toast";
export const ADD_NOTIFICATION = "addNotification";
export const addNotification = (dispatch, data, ttl = 4000) => dispatch({ type: ADD_NOTIFICATION, data: { ...data, id: uuidv4() }, ttl });
export const DELETE_NOTIFICATION = "deleteNotification";
export const deleteNotification = (dispatch, data) => dispatch({ type: DELETE_NOTIFICATION, data });
export const notify4xx = (dispatch) => async (error) => {
    let data;
    if (error.response.status === 409 || error.response.status === 425) {
        data = {
            status: ToastStatus.Error,
            title: "Outdated Record",
            description: "Can not override newer record in database. Your edits have not been saved",
        };
        await addNotification(dispatch, data);
        return error.response.data.state;
    }
    throw error;
};
export var API_TYPE;
(function (API_TYPE) {
    API_TYPE["GET"] = "get";
    API_TYPE["POST"] = "post";
    API_TYPE["PUT"] = "put";
    API_TYPE["DELETE"] = "delete";
})(API_TYPE || (API_TYPE = {}));
export const notify200 = (dispatch, type, title, description) => async (record) => {
    let data;
    if (type === API_TYPE.PUT) {
        data = {
            status: ToastStatus.Success,
            title: title || "Updated Record",
            description: description || "",
        };
    }
    if (type === API_TYPE.POST) {
        data = {
            status: ToastStatus.Success,
            title: title || "Created Record",
            description: description || "",
        };
    }
    if (type === API_TYPE.DELETE) {
        data = {
            status: ToastStatus.Success,
            title: title || "Deleted Record",
            description: description || "",
        };
    }
    if (data) {
        await addNotification(dispatch, data);
    }
    return record;
};
