import Factory from "@Shared/Factory";
import { ADD_RESPONSE, PROPOSAL_TAB } from "@Store/Actions/Suppliers/responses";
export default (state = [], { type, data = {} }) => {
    switch (type) {
        case ADD_RESPONSE:
            const response = Factory.Response(data);
            const index = state.findIndex(({ id }) => id !== response.id);
            if (index < 0) {
                return [...state, { id: response.id, tab: response.tab }];
            }
            break;
        case PROPOSAL_TAB:
            return [...state.filter(({ id }) => id !== data.id), data];
    }
    return state;
};
