import SVG from "@Components/Icons/SVG";
export default class Usage extends SVG {
    path = [
        "M 68 18",
        "V 4",
        "C 68 2 66 0 64 0",
        "H 4",
        "C 2 0 0 2 0 4",
        "V 86",
        "C 0 88 2 90 4 90",
        "H 10",
        "V 86",
        "H 6",
        "C 5 86 4 85 4 84",
        "V 6",
        "C 4 4 4 4 6 4",
        "H 62",
        "C 64 4 64 5 64 6",
        "V 14",
        "L 68 19",
        "M 76 27",
        "L 60 10",
        "M 77 28",
        "V 96",
        "C 78 99 76 100 74 100",
        "H 14",
        "C 12 100 10 98 10 96",
        "V 14",
        "C 10 12 12 10 14 10",
        "H 60",
        "M 77 28",
        "H 64",
        "C 62 28 60 26 60 24",
        "V 10",
        "M 40 32",
        "H 23",
        "V 36",
        "H 40",
        "Z",
        "M 23 48",
        "H 65",
        "V 44",
        "H 23",
        "Z",
        "M 23 57",
        "H 65",
        "V 61",
        "H 23",
        "Z",
        "M 23 69",
        "H 65",
        "V 73",
        "H 23",
        "Z",
        "M 23 86",
        "C 23 85 24 75 30 79",
        "C 32 81 30 83 30 84",
        "C 30 86 34 81 34 81",
        "C 38 79 36 85 38 85",
        "C 38 87 40 82 44 84 ",
        "V 86",
        "C 40 85 38 88 38 87",
        "C 36 86 36 84 35 83",
        "C 34 84 29 87 28 87",
        "C 27 87 29 82 28 80",
        "C 26 80 24 85 25 86",
        "Z",
        "M 16 14",
        "H 56",
        "V 26",
        "C 56 30 57 31 60 32",
        "H 73",
        "V 93",
        "C 73 96 71 96 72 96",
        "H 17",
        "C 14 96 14 96 14 94",
        "V 16",
        "C 14 14 16 14 16 14",
        "M 60 14",
        "L 73 28",
        "H 77",
        "L 60 10",
        "Z",
    ];
    evenOdd = true;
}
