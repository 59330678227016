import React from "react";
import Eye from "@Components/Icons/Eye";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import styles from "./index.module.scss";
export default class FormInput extends React.Component {
    static defaultProps = {
        value: "",
        type: "text",
        error: false,
        name: "",
        valueChanged: () => { },
        title: "",
        left: false,
    };
    constructor(props) {
        super(props);
        this.state = {
            passwordShown: this.props.showPassword,
            value: "",
        };
    }
    componentDidMount() {
        if (this.props.type === "currency" ||
            this.props.type === "gb" ||
            this.props.type === "number" ||
            this.props.type === "data" ||
            this.props.type === "percentage") {
            this.updateStateToDisplayValue();
        }
    }
    componentDidUpdate(previousProps) {
        if ((this.props.type === "currency" ||
            this.props.type === "gb" ||
            this.props.type === "number" ||
            this.props.type === "data" ||
            this.props.type === "percentage") &&
            this.props.value !== previousProps.value) {
            this.updateStateToDisplayValue();
        }
    }
    updateStateToDisplayValue = () => {
        const value = this.getValue(this.props.value);
        this.setState({
            value,
        });
    };
    changeInput = (event) => {
        const value = event.target.value;
        if (this.props.type === "currency" ||
            this.props.type === "gb" ||
            this.props.type === "number" ||
            this.props.type === "data" ||
            this.props.type === "percentage") {
            this.setState({
                value,
            });
            return;
        }
        return this.update(value);
    };
    processValue = (value_) => {
        if (this.getType() !== "number") {
            return value_;
        }
        let value = value_;
        if (typeof value_ === "string") {
            value = value_.replaceAll(/[^\d.]/g, "");
        }
        if (value.endsWith(".")) {
            return value;
        }
        const numberValue = Number(value);
        if (Number.isNaN(numberValue)) {
            return value_;
        }
        if (numberValue < 0 && !this.props.negative) {
            return 0;
        }
        if (this.props.noDecimal) {
            return Math.floor(numberValue);
        }
        return value;
    };
    update = (value) => {
        if (this.props.valueChanged) {
            this.props.valueChanged(this.props.name, value);
        }
    };
    passwordToggle = () => {
        this.setState((previousState) => ({
            passwordShown: !previousState.passwordShown,
        }));
    };
    handleBlur = () => {
        let displayValue = this.getValue(this.processValue(this.state.value));
        let realValue = Number.parseFloat(displayValue.replaceAll(",", ""));
        if (Number.isNaN(realValue)) {
            realValue = undefined;
            displayValue = "";
        }
        this.setState({
            value: displayValue,
        });
        this.update(realValue);
    };
    handleErrors = () => {
        if (this.props.error && this.props.setErrors !== undefined) {
            this.props.setErrors({});
        }
    };
    getValue = (value) => {
        if (value === "") {
            return "";
        }
        if (String(value).startsWith(".")) {
            //add 0 before decimal point
            value = `0${value}`;
        }
        if (String(value).endsWith(".")) {
            //add 0 after decimal point
            value = `${value}0`;
        }
        if (typeof value === "string") {
            value = Number(value);
        }
        if (this.props.noDecimal) {
            return roundToFormat(value, 0);
        }
        return roundToFormat(value, 2);
    };
    getType = () => {
        if (this.props.type === "currency" ||
            this.props.type === "data" ||
            this.props.type === "percentage" ||
            this.props.type === "lines" ||
            this.props.type === "gb") {
            return "number";
        }
        if (this.props.type === "fakeCurrency") {
            return "text";
        }
        if (this.state.passwordShown) {
            return "text";
        }
        return this.props.type;
    };
    incrementCount = () => {
        let { value } = this.props;
        if (value === undefined || value === "") {
            value = 0;
        }
        if (typeof value === "string") {
            value = Number(value);
        }
        this.update(value + 1);
    };
    decrementCount = () => {
        let { value } = this.props;
        if (value === undefined || value === "") {
            value = 0;
        }
        if (typeof value === "string") {
            value = Number(value);
        }
        this.update(value - 1);
    };
    hideZero = () => {
        if (this.getType() === "number" &&
            (this.props.value === 0 || this.props.value === "0")) {
            this.setState({
                value: "",
            });
        }
    };
    render() {
        const error = this.props.error ? styles.error : "";
        const currency = this.props.type === "currency" && !this.props.plusMinus
            ? styles.currency
            : "";
        const fakeCurrency = this.props.type === "fakeCurrency" ? styles.fakeCurrency : "";
        const percentage = this.props.type === "percentage" ? styles.percentage : "";
        const number = this.props.type === "number" ? styles.number : "";
        const plusMinus = this.props.plusMinus ? styles.plusMinus : "";
        const lines = this.props.type === "lines" ? styles.lines : "";
        const gb = this.props.type === "gb" ? styles.gb : "";
        const data = this.props.type === "data" ? styles.data : "";
        const classes = [
            styles.wrapper,
            error,
            currency,
            fakeCurrency,
            percentage,
            data,
            plusMinus,
            lines,
            gb,
        ].join(` `);
        const type = this.getType();
        const small = this.props.small === true ? styles.small : "";
        const blue = this.props.blue === true ? styles.blue : "";
        const disabled = this.props.disabled === true ? styles.disabled : "";
        const left = this.props.left === true ? styles.left : "";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${classes} ${this.props.className}` },
                this.props.title.length > 0 ? (React.createElement("div", { className: `${styles.title} ${small}` }, this.props.title)) : (React.createElement(React.Fragment, null)),
                this.props.plusMinus && (React.createElement("div", { className: styles.minus, onClick: this.decrementCount },
                    React.createElement("div", { className: styles.minusText }, "-"))),
                React.createElement("input", { className: `${styles.input} ${plusMinus} ${currency} ${fakeCurrency} ${lines} ${gb} ${percentage} ${number} ${left} ${data} ${disabled} ${blue}`, name: this.props.name, type: type === "number" ? "text" : type, onChange: this.changeInput, value: this.props.type === "currency" ||
                        this.props.type === "gb" ||
                        this.props.type === "number" ||
                        this.props.type === "data" ||
                        this.props.type === "percentage"
                        ? this.state.value
                        : this.props.value, disabled: this.props.disabled, maxLength: this.props.maxlength, placeholder: this.props.placeholder, step: type === "number" ? 0.01 : "", autoComplete: "off", onBlur: this.props.type === "currency" ||
                        this.props.type === "gb" ||
                        this.props.type === "number" ||
                        this.props.type === "data" ||
                        this.props.type === "percentage"
                        ? this.handleBlur
                        : () => { }, onFocus: () => {
                        this.handleErrors();
                        this.hideZero();
                    } }),
                this.props.plusMinus && (React.createElement("div", { className: styles.plus, onClick: this.incrementCount },
                    React.createElement("div", null, "+"))),
                this.props.type === "password" && (React.createElement(Eye, { className: styles.icon, primary: this.state.passwordShown, onClick: () => this.passwordToggle() })))));
    }
}
