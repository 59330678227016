import React from "react";
import styles from "./index.module.scss";
export default class StepCircle extends React.Component {
    render() {
        const textBox = (React.createElement("div", { className: styles.textBox }, this.props.children));
        return (React.createElement("div", { className: styles.wrapper },
            textBox,
            React.createElement("svg", { fill: "none", height: "149", viewBox: "0 0 149 149", width: "149", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { clipRule: "evenodd", d: "M74.4 148.8C115.49 148.8 148.8 115.49 148.8 74.4C148.8 33.31 115.49 0 74.4 0C33.31 0 0 33.31 0 74.4C0 115.49 33.31 148.8 74.4 148.8Z", fill: "#F0F6F6", fillRule: "evenodd", stroke: "white", strokeWidth: "1em", strokeLinejoin: "round" }),
                this.props.number === 1 && (React.createElement("g", { clipPath: "url(#clip0)" },
                    React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                    React.createElement("path", { d: "M17.6758 26.2002H15.6563V18.0877C15.6563 17.1195 15.6792 16.3518 15.725 15.7846C15.5932 15.9221 15.4299 16.0739 15.2352 16.24C15.0461 16.4062 14.4044 16.9333 13.3102 17.8213L12.2961 16.5408L15.9914 13.6361H17.6758V26.2002Z", fill: "white" }))),
                this.props.number === 2 && (React.createElement("g", { clipPath: "url(#clip0)" },
                    React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                    React.createElement("path", { d: "M21.0623 26.2002H12.5459V24.6705L15.7857 21.4135C16.7425 20.4338 17.3756 19.7406 17.685 19.3338C18.0001 18.9213 18.2292 18.5346 18.3725 18.1736C18.5157 17.8127 18.5873 17.426 18.5873 17.0135C18.5873 16.4463 18.4154 15.9994 18.0717 15.6729C17.7337 15.3463 17.2639 15.183 16.6623 15.183C16.1811 15.183 15.7141 15.2718 15.2615 15.4494C14.8146 15.627 14.2962 15.9479 13.7061 16.4119L12.6146 15.0799C13.3136 14.4898 13.9925 14.0716 14.6514 13.8252C15.3102 13.5788 16.012 13.4557 16.7568 13.4557C17.9256 13.4557 18.8623 13.7622 19.567 14.3752C20.2717 14.9825 20.624 15.8018 20.624 16.833C20.624 17.4002 20.5209 17.9387 20.3146 18.4486C20.1141 18.9585 19.8019 19.4856 19.3779 20.0299C18.9597 20.5684 18.2607 21.2989 17.2811 22.2213L15.0982 24.3354V24.4213H21.0623V26.2002Z", fill: "white" }))),
                this.props.number === 3 && (React.createElement("g", { clipPath: "url(#clip0)" },
                    React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                    React.createElement("path", { d: "M19.7898 16.5236C19.7898 17.32 19.5578 17.9846 19.0937 18.5174C18.6297 19.0445 17.9766 19.3997 17.1344 19.583V19.6518C18.1427 19.7778 18.899 20.0929 19.4031 20.5971C19.9073 21.0955 20.1594 21.7601 20.1594 22.5908C20.1594 23.7997 19.7326 24.7335 18.8789 25.3924C18.0253 26.0455 16.8107 26.3721 15.2352 26.3721C13.843 26.3721 12.6685 26.1458 11.7117 25.6932V23.8971C12.2445 24.1606 12.8089 24.364 13.4047 24.5072C14.0005 24.6505 14.5734 24.7221 15.1234 24.7221C16.0974 24.7221 16.825 24.5416 17.3062 24.1807C17.7875 23.8197 18.0281 23.2611 18.0281 22.5049C18.0281 21.8346 17.7617 21.3419 17.2289 21.0268C16.6961 20.7117 15.8596 20.5541 14.7195 20.5541H13.6281V18.9127H14.7367C16.7419 18.9127 17.7445 18.2195 17.7445 16.833C17.7445 16.2945 17.5698 15.8791 17.2203 15.5869C16.8708 15.2947 16.3552 15.1486 15.6734 15.1486C15.1979 15.1486 14.7396 15.2174 14.2984 15.3549C13.8573 15.4867 13.3359 15.7473 12.7344 16.1369L11.7461 14.7275C12.8977 13.8796 14.2354 13.4557 15.7594 13.4557C17.0255 13.4557 18.0138 13.7278 18.7242 14.2721C19.4346 14.8163 19.7898 15.5669 19.7898 16.5236Z", fill: "white" }))))));
    }
}
