import React from "react";
import { get as getDevices } from "@Api/Hardware/devices";
import { findCompany, register, upload } from "@Api/Registration";
import AppSwitch from "@Components/AppSwitch";
import getSubdomain from "@Helpers/GetSubdomain";
import Factory from "@Shared/Factory";
import WelcomePopup from "@SmartComponents/TenderEdit/Popups/Welcome";
import { updateTender } from "@Store/Reducers/Customers/tenders";
import CreateAccount from "@Components/CreateAccount";
import Uploading from "@Components/Pages/Fallbacks/Uploading";
import TenderEditDisplay from "../Display";
import AddUsage from "../Popups/AddUsage";
export default class TenderEditLocalStorage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tender: Factory.Tender({ customerId: "dummy" }),
            visibleUpload: false,
            visibleUploading: false,
            visibleAccount: false,
            visibleWelcome: false,
            redirectTo: "",
            completeTenderQuestions: false,
            devices: [],
            middleOfForm: 0,
        };
    }
    componentDidMount() {
        const tenderJson = localStorage.getItem("ls-tender");
        if (tenderJson) {
            const tender = Factory.Tender(JSON.parse(tenderJson));
            this.setState({
                tender,
                completeTenderQuestions: tender.details?.MDM !== undefined,
            });
        }
        ;
        (async () => {
            const devices = [];
            await getDevices((ds) => devices.push(...ds));
            this.setState({
                devices,
            });
        })();
        setTimeout(() => {
            this.setState({
                visibleWelcome: true,
            });
        }, 500);
    }
    update = (input) => {
        const { value, name: effects } = input;
        const data = {
            value,
            effects,
        };
        const tender = updateTender(this.state.tender, data);
        this.setState({
            tender,
            completeTenderQuestions: tender.details?.MDM !== undefined,
        }, () => {
            localStorage.setItem("ls-tender", JSON.stringify(this.state.tender.data));
        });
    };
    submit = () => {
        this.showAccountPopup();
    };
    upload = () => {
        this.showUploadUsagePopup();
    };
    showUploadingPopup = () => {
        this.setState({ visibleUploading: true });
    };
    closeUploadingPopup = () => {
        this.setState({ visibleUploading: false });
    };
    closeWelcomePopup = () => {
        this.setState({ visibleWelcome: false });
    };
    showUploadUsagePopup = () => {
        this.setState({ visibleUpload: true });
    };
    closeUploadUsagePopup = () => {
        this.setState({ visibleUpload: false });
    };
    submitFiles = async (files) => {
        this.setState({
            files,
        });
        this.closeUploadUsagePopup();
        this.showAccountPopup();
    };
    submitPlatform = async (platform) => {
        this.setState({
            platform,
        });
        this.closeUploadUsagePopup();
        this.showAccountPopup();
    };
    showAccountPopup = () => {
        this.setState({ visibleAccount: true });
    };
    closeAccountPopup = () => {
        this.setState({ visibleAccount: false });
    };
    submitAccount = async (account) => {
        this.closeAccountPopup();
        this.showUploadingPopup();
        const { platform, files = [], tender } = this.state;
        const subdomain = getSubdomain();
        const haveUsage = platform || files.length > 0;
        await register({
            network: platform,
            details: account,
            haveUsage,
            numFiles: files.length,
            tender: tender.data,
            subdomain,
        })
            .then(async ({ customer, usage, tender }) => {
            let publish = this.state.completeTenderQuestions;
            if (usage !== null) {
                publish = false;
                await upload(customer.id, usage.id, files);
            }
            localStorage.removeItem("ls-tender");
            return `/customer/tenders/${tender.id}/edit?publish=${publish}`;
        })
            .then((redirectTo) => {
            this.setState({
                redirectTo,
            });
        })
            .catch(({ response }) => {
            const { status } = response;
            if (status === 405) {
                this.closeUploadingPopup();
                this.showAccountPopup();
            }
            throw response;
        });
        this.closeUploadingPopup();
    };
    render() {
        if (this.state.redirectTo !== "") {
            return React.createElement(AppSwitch, { uri: this.state.redirectTo });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(TenderEditDisplay, { tender: this.state.tender, devices: this.state.devices || [], errors: {}, update: this.update, submit: this.submit, upload: this.upload, completeTenderQuestions: this.state.completeTenderQuestions, loaded: this.props.loaded, updatePositions: (positions) => {
                    this.setState({ middleOfForm: positions.middle });
                }, publish: this.submit, welcomePopupOpen: this.state.visibleWelcome, fetchingUsage: false }),
            React.createElement("div", null,
                React.createElement(AddUsage, { middle: this.state.middleOfForm, open: this.state.visibleUpload, onClose: this.closeUploadUsagePopup, submitFiles: this.submitFiles, submitPlatform: this.submitPlatform }),
                React.createElement(CreateAccount, { open: this.state.visibleAccount, onClose: this.closeAccountPopup, notify: () => { }, create: this.submitAccount, findCompany: findCompany }),
                React.createElement(Uploading, { open: this.state.visibleUploading, onClose: this.closeUploadingPopup }),
                React.createElement(WelcomePopup, { middle: this.state.middleOfForm, open: this.state.visibleWelcome &&
                        this.state.middleOfForm > 0, onClose: this.closeWelcomePopup }))));
    }
}
