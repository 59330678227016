import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { BillingRelationship, Effected, Measurement, NetworkRelationship, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export const networkPresenceConstraints = {
    [Effected.MultipleNetworks]: {
        presence: true,
    },
    [Effected.SelectNetworks]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.MultipleNetworks,
                value: true,
            },
        ],
        length: {
            minimum: 1,
        },
    },
    [Effected.BillingRelationship]: {
        presence: true,
    },
    [Effected.BillingRelationshipChoice]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.BillingRelationship,
                value: BillingRelationship.Both,
            },
        ],
    },
    [Effected.NetworkRelationship]: {
        presence: true,
    },
    [Effected.KeepSims]: {
        presence: true,
    },
    [Effected.Increases]: {
        presence: true,
    },
    [Effected.IncreaseMeasurement]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
    },
    [Effected.AdditionalIncrease]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
    },
    [Effected.AdditionalIncreasePercentage]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
            {
                field: Effected.AdditionalIncrease,
                value: true,
            },
        ],
    },
    [Effected.MandatoryRise]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
    },
    [Effected.MandatoryRisePercentage]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
            {
                field: Effected.MandatoryRise,
                value: true,
            },
        ],
    },
    [Effected.BreakClause]: {
        presence: true,
    },
};
export const networkValidConstraints = {
    [Effected.MultipleNetworks]: {
        presence: true,
        type: "boolean",
    },
    [Effected.SelectNetworks]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.MultipleNetworks,
                value: true,
            },
        ],
        length: {
            minimum: 1,
        },
        enumArray: Network,
    },
    [Effected.BillingRelationship]: {
        presence: true,
        enum: BillingRelationship,
    },
    [Effected.BillingRelationshipChoice]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.BillingRelationship,
                value: BillingRelationship.Both,
            },
        ],
        enum: BillingRelationship,
    },
    [Effected.NetworkRelationship]: {
        presence: true,
        enum: NetworkRelationship,
    },
    [Effected.KeepSims]: {
        presence: true,
        type: "boolean",
    },
    [Effected.Increases]: {
        presence: true,
        type: "boolean",
    },
    [Effected.IncreaseMeasurement]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
        enum: Measurement,
    },
    [Effected.AdditionalIncrease]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
        type: "boolean",
    },
    [Effected.AdditionalIncreasePercentage]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
            {
                field: Effected.AdditionalIncrease,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.MandatoryRise]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
        ],
        type: "boolean",
    },
    [Effected.MandatoryRisePercentage]: {
        conditionalPresenceMatchAll: [
            {
                field: Effected.Increases,
                value: true,
            },
            {
                field: Effected.MandatoryRise,
                value: true,
            },
        ],
        type: "number",
    },
    [Effected.BreakClause]: {
        presence: true,
        type: "boolean",
    },
    [Effected.AdditionalNetworkInfo]: {
        type: "string",
    },
};
