export var DetailsFields;
(function (DetailsFields) {
    DetailsFields["VoiceLines"] = "voiceLines";
    DetailsFields["DataLines"] = "dataLines";
    DetailsFields["VoiceOnlyLines"] = "voiceOnlyLines";
    DetailsFields["Length"] = "length";
    DetailsFields["VastMajorityLinesRenewal"] = "vastMajorityLinesRenewal";
    DetailsFields["LinesBeginning"] = "linesBeginning";
    DetailsFields["LinesWithinThreeMonths"] = "linesWithinThreeMonths";
    DetailsFields["LinesWithinSixMonths"] = "linesWithinSixMonths";
    DetailsFields["EstimatedLines"] = "estimatedLines";
    DetailsFields["EstimatedDataLines"] = "estimatedDataLines";
    DetailsFields["ATC"] = "ATC";
    DetailsFields["ATCUnits"] = "ATCUnits";
    DetailsFields["ATCRaw"] = "ATCRaw";
    DetailsFields["TechFund"] = "techFund";
    DetailsFields["RealMonthlyCosts"] = "realMonthlyCosts";
    DetailsFields["OverallData"] = "overallData";
    DetailsFields["IncreaseUsage"] = "increaseUsage";
    DetailsFields["Microsoft365"] = "microsoft365";
    DetailsFields["OnlineTeams"] = "onlineTeams";
    DetailsFields["Zoom"] = "zoom";
    DetailsFields["DataAcquisition"] = "dataAcquisition";
    DetailsFields["OtherDataIncrease"] = "otherDataIncrease";
    DetailsFields["IDDCost"] = "iddCost";
    DetailsFields["IDDCostCountries"] = "iddCostCountries";
    DetailsFields["RoamingCost"] = "roamingCost";
    DetailsFields["RoamingCostCountries"] = "roamingCostCountries";
    DetailsFields["HighCost"] = "highCost";
    DetailsFields["HighCostCountries"] = "highCostCountries";
    DetailsFields["CurrentNetwork"] = "currentNetwork";
    DetailsFields["ChangeNetwork"] = "changeNetwork";
    DetailsFields["MultipleNetwork"] = "multipleNetwork";
    DetailsFields["LeastNetwork"] = "leastNetwork";
    DetailsFields["ChosenNetwork"] = "chosenNetwork";
    DetailsFields["Postcodes"] = "postcodes";
    DetailsFields["ExistingDevices"] = "existingDevices";
    DetailsFields["Locked"] = "locked";
    DetailsFields["Handsets"] = "handsets";
    DetailsFields["SimOnlyFunded"] = "simOnlyFunded";
    DetailsFields["FiveG"] = "fiveG";
    DetailsFields["PickedHandsets"] = "pickedHandsets";
    DetailsFields["NumberHandsets"] = "num";
    DetailsFields["DEP"] = "dep";
    DetailsFields["LikeHardwareFunded"] = "likeHardwareFunded";
    DetailsFields["LeasingType"] = "leasingType";
    DetailsFields["OwnMDM"] = "ownMDM";
    DetailsFields["MDMMSInTune"] = "MDMMSInTune";
    DetailsFields["MDMInLine"] = "MDMInLine";
    DetailsFields["MDMEnd"] = "MDMEnd";
    DetailsFields["MDMFee"] = "MDMFee";
    DetailsFields["MDM"] = "MDM";
    DetailsFields["ManualCurrentCost"] = "manualCurrentCost";
    DetailsFields["VATRegistered"] = "VATRegistered";
    DetailsFields["CharitySocialEnterprise"] = "charitySocialEnterprise";
    DetailsFields["ExtraDetails"] = "extraDetails";
    DetailsFields["HasUsage"] = "hasUsage";
    // TODO Remove if not used
    DetailsFields["None"] = "none";
    DetailsFields["Device"] = "device";
    DetailsFields["SameTime"] = "sameTime";
    DetailsFields["LinesEnd"] = "linesEnd";
    DetailsFields["LinesEndDate"] = "linesEndDate";
    DetailsFields["LinesEndNumber"] = "linesEndNum";
    DetailsFields["CurrentSpend"] = "currentSpend";
    DetailsFields["EuCostCountries"] = "euCostCountries";
    DetailsFields["CostSpread"] = "costSpread";
    DetailsFields["HardwareType"] = "hardwareType";
    DetailsFields["OsSpread"] = "OsSpread";
    DetailsFields["PriceRange"] = "priceRange";
    DetailsFields["OSSpreadHandsets"] = "oSSpread";
    DetailsFields["NewEndDate"] = "newEndDate";
    DetailsFields["Complete"] = "complete";
    DetailsFields["Charity"] = "charity";
    DetailsFields["DEPChoices"] = "depChoices";
})(DetailsFields || (DetailsFields = {}));
export var ManagerPackageTypes;
(function (ManagerPackageTypes) {
    ManagerPackageTypes[ManagerPackageTypes["None"] = 0] = "None";
    ManagerPackageTypes[ManagerPackageTypes["Bronze"] = 1] = "Bronze";
    ManagerPackageTypes[ManagerPackageTypes["Silver"] = 2] = "Silver";
    ManagerPackageTypes[ManagerPackageTypes["Gold"] = 3] = "Gold";
})(ManagerPackageTypes || (ManagerPackageTypes = {}));
export var DeliveryChargeMethod;
(function (DeliveryChargeMethod) {
    DeliveryChargeMethod[DeliveryChargeMethod["PerDevice"] = 0] = "PerDevice";
    DeliveryChargeMethod[DeliveryChargeMethod["PerShipment"] = 1] = "PerShipment";
})(DeliveryChargeMethod || (DeliveryChargeMethod = {}));
export var HighCostCountries;
(function (HighCostCountries) {
    HighCostCountries[HighCostCountries["None"] = 0] = "None";
    HighCostCountries[HighCostCountries["Antarctica"] = 1] = "Antarctica";
    HighCostCountries[HighCostCountries["Bhutan"] = 2] = "Bhutan";
    HighCostCountries[HighCostCountries["Burundi"] = 3] = "Burundi";
    HighCostCountries[HighCostCountries["Central African Republic (the)"] = 4] = "Central African Republic (the)";
    HighCostCountries[HighCostCountries["Comoros (the)"] = 5] = "Comoros (the)";
    HighCostCountries[HighCostCountries["Cook Islands (the)"] = 6] = "Cook Islands (the)";
    HighCostCountries[HighCostCountries["Cura\u00E7ao"] = 7] = "Cura\u00E7ao";
    HighCostCountries[HighCostCountries["Djibouti"] = 8] = "Djibouti";
    HighCostCountries[HighCostCountries["East Timor or Timor-Leste"] = 9] = "East Timor or Timor-Leste";
    HighCostCountries[HighCostCountries["Eritrea"] = 10] = "Eritrea";
    HighCostCountries[HighCostCountries["Ethiopia"] = 11] = "Ethiopia";
    HighCostCountries[HighCostCountries["Faroe Islands (the)"] = 12] = "Faroe Islands (the)";
    HighCostCountries[HighCostCountries["Guam"] = 13] = "Guam";
    HighCostCountries[HighCostCountries["Haiti"] = 14] = "Haiti";
    HighCostCountries[HighCostCountries["Iran (Islamic Republic of)"] = 15] = "Iran (Islamic Republic of)";
    HighCostCountries[HighCostCountries["Kiribati"] = 16] = "Kiribati";
    HighCostCountries[HighCostCountries["Korea (the Democratic People's Republic of)"] = 17] = "Korea (the Democratic People's Republic of)";
    HighCostCountries[HighCostCountries["Kyrgyzstan"] = 18] = "Kyrgyzstan";
    HighCostCountries[HighCostCountries["Lao People's Democratic Republic (the)"] = 19] = "Lao People's Democratic Republic (the)";
    HighCostCountries[HighCostCountries["Lesotho"] = 20] = "Lesotho";
    HighCostCountries[HighCostCountries["Libya"] = 21] = "Libya";
    HighCostCountries[HighCostCountries["Marshall Islands (the)"] = 22] = "Marshall Islands (the)";
    HighCostCountries[HighCostCountries["Mayotte"] = 23] = "Mayotte";
    HighCostCountries[HighCostCountries["Micronesia (Federated States of)"] = 24] = "Micronesia (Federated States of)";
    HighCostCountries[HighCostCountries["Nauru"] = 25] = "Nauru";
    HighCostCountries[HighCostCountries["New_Caledonia"] = 26] = "New_Caledonia";
    HighCostCountries[HighCostCountries["Niue"] = 27] = "Niue";
    HighCostCountries[HighCostCountries["Norfolk_Island"] = 28] = "Norfolk_Island";
    HighCostCountries[HighCostCountries["Northern Mariana Islands (the)"] = 29] = "Northern Mariana Islands (the)";
    HighCostCountries[HighCostCountries["Palau"] = 30] = "Palau";
    HighCostCountries[HighCostCountries["Puerto_Rico"] = 31] = "Puerto_Rico";
    HighCostCountries[HighCostCountries["R\u00E9union"] = 32] = "R\u00E9union";
    HighCostCountries[HighCostCountries["Saint Martin (French part)"] = 33] = "Saint Martin (French part)";
    HighCostCountries[HighCostCountries["Saint_Pierre_and_Miquelon"] = 34] = "Saint_Pierre_and_Miquelon";
    HighCostCountries[HighCostCountries["Samoa"] = 35] = "Samoa";
    HighCostCountries[HighCostCountries["Sao_Tome_and_Principe"] = 36] = "Sao_Tome_and_Principe";
    HighCostCountries[HighCostCountries["Sint Maarten (Dutch part)"] = 37] = "Sint Maarten (Dutch part)";
    HighCostCountries[HighCostCountries["Solomon_Islands"] = 38] = "Solomon_Islands";
    HighCostCountries[HighCostCountries["Somalia"] = 39] = "Somalia";
    HighCostCountries[HighCostCountries["South_Georgia_and_the_South_Sandwich_Islands"] = 40] = "South_Georgia_and_the_South_Sandwich_Islands";
    HighCostCountries[HighCostCountries["South__Sudan"] = 41] = "South__Sudan";
    HighCostCountries[HighCostCountries["Syrian_Arab_Republic"] = 42] = "Syrian_Arab_Republic";
    HighCostCountries[HighCostCountries["Tokelau"] = 43] = "Tokelau";
    HighCostCountries[HighCostCountries["Tuvalu"] = 44] = "Tuvalu";
    HighCostCountries[HighCostCountries["Virgin Islands (U.S.)"] = 45] = "Virgin Islands (U.S.)";
    HighCostCountries[HighCostCountries["Wallis_and_Futuna"] = 46] = "Wallis_and_Futuna";
    HighCostCountries[HighCostCountries["\u00C5land_Islands"] = 47] = "\u00C5land_Islands";
})(HighCostCountries || (HighCostCountries = {}));
export var EuCostCountries;
(function (EuCostCountries) {
    EuCostCountries[EuCostCountries["EU"] = 0] = "EU";
    EuCostCountries[EuCostCountries["Africa"] = 1] = "Africa";
    EuCostCountries[EuCostCountries["Asia"] = 2] = "Asia";
    EuCostCountries[EuCostCountries["South America"] = 3] = "South America";
    EuCostCountries[EuCostCountries["North America"] = 4] = "North America";
    EuCostCountries[EuCostCountries["Asia-Pacific Region (APAC)"] = 5] = "Asia-Pacific Region (APAC)";
})(EuCostCountries || (EuCostCountries = {}));
export var ChangeNetwork;
(function (ChangeNetwork) {
    ChangeNetwork[ChangeNetwork["Yes, I want to change"] = 0] = "Yes, I want to change";
    ChangeNetwork[ChangeNetwork["No, I am happy where I am"] = 1] = "No, I am happy where I am";
    ChangeNetwork[ChangeNetwork["I don't mind"] = 2] = "I don't mind";
})(ChangeNetwork || (ChangeNetwork = {}));
export var DEPChoices;
(function (DEPChoices) {
    DEPChoices[DEPChoices["Apple (DEP)"] = 0] = "Apple (DEP)";
    DEPChoices[DEPChoices["Android (AZT)"] = 1] = "Android (AZT)";
    DEPChoices[DEPChoices["Both"] = 2] = "Both";
    DEPChoices[DEPChoices["Not required"] = 3] = "Not required";
})(DEPChoices || (DEPChoices = {}));
export var DevicesLocked;
(function (DevicesLocked) {
    DevicesLocked[DevicesLocked["Yes"] = 0] = "Yes";
    DevicesLocked[DevicesLocked["No"] = 1] = "No";
    DevicesLocked[DevicesLocked["Don't know/mixed"] = 2] = "Don't know/mixed";
})(DevicesLocked || (DevicesLocked = {}));
export var SimOnlyFunded;
(function (SimOnlyFunded) {
    SimOnlyFunded[SimOnlyFunded["To_lower_my_monthly_payments"] = 0] = "To_lower_my_monthly_payments";
    SimOnlyFunded[SimOnlyFunded["To_receive_a_fund_to_spend_as_I_wish"] = 1] = "To_receive_a_fund_to_spend_as_I_wish";
})(SimOnlyFunded || (SimOnlyFunded = {}));
export var LikeHardwareFunded;
(function (LikeHardwareFunded) {
    LikeHardwareFunded[LikeHardwareFunded["Included_in_tariff"] = 0] = "Included_in_tariff";
    LikeHardwareFunded[LikeHardwareFunded["Outright_Purchase"] = 1] = "Outright_Purchase";
    LikeHardwareFunded[LikeHardwareFunded["Leasing"] = 2] = "Leasing";
})(LikeHardwareFunded || (LikeHardwareFunded = {}));
export var LeasingType;
(function (LeasingType) {
    LeasingType[LeasingType["Residual_value"] = 0] = "Residual_value";
    LeasingType[LeasingType["Lease_purchase"] = 1] = "Lease_purchase";
})(LeasingType || (LeasingType = {}));
export var MDMInLine;
(function (MDMInLine) {
    MDMInLine[MDMInLine["Yes"] = 0] = "Yes";
    MDMInLine[MDMInLine["No"] = 1] = "No";
    MDMInLine[MDMInLine["No_Fixed_Contract"] = 2] = "No_Fixed_Contract";
})(MDMInLine || (MDMInLine = {}));
export var DeviceRange;
(function (DeviceRange) {
    DeviceRange[DeviceRange["Low"] = 0] = "Low";
    DeviceRange[DeviceRange["Mid"] = 1] = "Mid";
    DeviceRange[DeviceRange["High"] = 2] = "High";
})(DeviceRange || (DeviceRange = {}));
export var Months;
(function (Months) {
    Months[Months["January"] = 0] = "January";
    Months[Months["February"] = 1] = "February";
    Months[Months["March"] = 2] = "March";
    Months[Months["April"] = 3] = "April";
    Months[Months["May"] = 4] = "May";
    Months[Months["June"] = 5] = "June";
    Months[Months["July"] = 6] = "July";
    Months[Months["August"] = 7] = "August";
    Months[Months["September"] = 8] = "September";
    Months[Months["October"] = 9] = "October";
    Months[Months["November"] = 10] = "November";
    Months[Months["December"] = 11] = "December";
})(Months || (Months = {}));
export var ContractEnding;
(function (ContractEnding) {
    ContractEnding[ContractEnding["In_the_next_3_months"] = 0] = "In_the_next_3_months";
    ContractEnding[ContractEnding["In_3_to_6_months"] = 1] = "In_3_to_6_months";
    ContractEnding[ContractEnding["Over_6_months"] = 2] = "Over_6_months";
})(ContractEnding || (ContractEnding = {}));
export var ATCUnits;
(function (ATCUnits) {
    ATCUnits[ATCUnits["Pounds"] = 0] = "Pounds";
    ATCUnits[ATCUnits["Percentage"] = 1] = "Percentage";
})(ATCUnits || (ATCUnits = {}));
export var ExistingDevices;
(function (ExistingDevices) {
    ExistingDevices[ExistingDevices["Yes, all of them"] = 0] = "Yes, all of them";
    ExistingDevices[ExistingDevices["Yes, some"] = 1] = "Yes, some";
    ExistingDevices[ExistingDevices["None"] = 2] = "None";
})(ExistingDevices || (ExistingDevices = {}));
export var FiveG;
(function (FiveG) {
    FiveG[FiveG["Yes"] = 0] = "Yes";
    FiveG[FiveG["No"] = 1] = "No";
    FiveG[FiveG["Don't mind"] = 2] = "Don't mind";
})(FiveG || (FiveG = {}));
export var CurrentNetwork;
(function (CurrentNetwork) {
    CurrentNetwork[CurrentNetwork["EE"] = 0] = "EE";
    CurrentNetwork[CurrentNetwork["Vodafone"] = 2] = "Vodafone";
    CurrentNetwork[CurrentNetwork["O2 - Plan.com"] = 4] = "O2 - Plan.com";
    CurrentNetwork[CurrentNetwork["O2"] = 5] = "O2";
})(CurrentNetwork || (CurrentNetwork = {}));
