import React from "react";
import Loading from "@Components/Icons/Loading";
import styles from "./../../index.module.scss";
export default class LoadingFallback extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.loadingWrapper },
            React.createElement("div", { className: styles.loading },
                this.props.text,
                React.createElement(Loading, null))));
    }
}
