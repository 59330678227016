import React from "react";
import styles from "./index.module.scss";
export default class LargeToggle extends React.Component {
    click = (value) => {
        if (!this.props.disabled) {
            this.props.optionSelected(this.props.name, value);
        }
    };
    handleErrors = () => {
        if (this.props.error) {
            this.props.setErrors({});
        }
    };
    render() {
        const smallWidth = this.props.options.length > 3 ? styles.four : "";
        const buttons = this.props.options.map(({ label, icon: Icon, value, size: Size }) => {
            const withIcon = Icon === undefined ? "" : styles.withIcon;
            const selected = this.props.selected === value;
            const long = this.props.long ? styles.long : "";
            return (React.createElement("button", { name: this.props.name, key: `${label} ${selected}`, onClick: () => this.click(value), type: "button", className: `${styles.option} ${long} ${smallWidth} ${this.props.disabled ? styles.disabled : ""} ${selected
                    ? styles.selected
                    : this.props.error
                        ? styles.error
                        : ""} ${this.props.small ? styles.small : ""} ${this.props.green ? styles.green : ""}` },
                Icon !== undefined && (React.createElement("div", { className: styles.icon },
                    Size !== undefined && (React.createElement(Icon, { light: selected, size: "2em", dark: !selected })),
                    Size === undefined && (React.createElement(Icon, { light: selected, size: "2.5em", dark: !selected })))),
                React.createElement("div", { className: `${styles.text} ${long} ${withIcon}` }, label)));
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { onFocus: this.handleErrors, className: `${styles.buttons} ${this.props.small ? styles.small : ""} ${this.props.reducedWidth ? styles.reducedWidth : ""}` }, buttons)));
    }
}
