import React from "react";
import { connect } from "react-redux";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import NotFoundFallback from "@Components/Pages/Fallbacks/NotFound";
import ComparisonPage from "@SmartComponents/Comparison";
import { fetchResponseForComparison } from "@Store/Actions/Customers/responses";
import { fetchTenderForComparison } from "@Store/Actions/Customers/tenders";
class Comparison extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            notFound: false,
            ids: [],
        };
    }
    componentDidMount() {
        const { responseIds = "" } = this.props;
        const regex = /response1=(?<responseId1>.*?)response2=(?<responseId2>.*?)(response3=(?<responseId3>.*?))?$/;
        const match = regex.exec(responseIds);
        if (match === null) {
            return;
        }
        const { groups: { responseId1, responseId2, responseId3 }, } = match;
        const ids = [responseId1, responseId2, responseId3].filter((id) => id !== undefined);
        this.setState({ ids });
        return Promise.all(ids.map((id) => this.props.fetchResponse(this.props.customerId, this.props.tenderId, id)))
            .then(() => this.props.fetchTender(this.props.customerId, this.props.tenderId))
            .finally(() => {
            this.setState({
                fetching: false,
                notFound: this.props.tender === undefined,
            });
        });
    }
    render() {
        if (this.state.notFound) {
            return React.createElement(NotFoundFallback, null);
        }
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        return (React.createElement(ComparisonPage, { isSharedLink: true, responses: this.props.responses, shared: true, tender: this.props.tender, adminUser: false }));
    }
}
const mapStateToProps = ({ suppliers: { responses = [] }, customers: { tenders = [] } }, props) => {
    const tender = tenders.find(({ id }) => id === props.tenderId);
    return {
        tender,
        responses: responses.filter(({ tenderId }) => tenderId === props.tenderId),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        fetchResponse: (customerId, tenderId, responseId) => fetchResponseForComparison(dispatch, customerId, tenderId, responseId),
        fetchTender: (customerId, tenderId) => fetchTenderForComparison(dispatch, customerId, tenderId),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Comparison);
