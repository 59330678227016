import React from "react";
import styles from "./index.module.scss";
export default class Typing extends React.Component {
    render() {
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.typing },
                React.createElement("div", { className: styles.dot }),
                React.createElement("div", { className: styles.dot }),
                React.createElement("div", { className: styles.dot }))));
    }
}
