export default {
    id: {
        type: "string",
    },
    createdAt: {
        type: "Dayjs",
    },
    updatedAt: {
        type: "Dayjs",
    },
    deletedAt: {
        type: "Dayjs",
    },
};
