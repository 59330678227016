import React from "react";
import { connect } from "react-redux";
import ContractBox from "@Components/ContractBoxes";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import getAwardedContractEntities from "@Helpers/GetAwardedContractEntities";
import { fetchAwardedContractsForSupplier } from "@Store/Actions/AwardedContract";
import { addNotification } from "@Store/Actions/Notifications";
class MyCustomerContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        this.props.fetchContracts(this.props.user.supplierId).then(() => {
            this.setState({ fetching: false });
        });
    }
    render() {
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        const awardedContractsWithEntities = this.props.awardedContracts.map(({ awardedContract }) => {
            return getAwardedContractEntities(awardedContract.id);
        });
        return React.createElement(ContractBox, { awardedContracts: awardedContractsWithEntities });
    }
}
const mapStateToProps = ({ auth: { user }, awardedContracts }) => ({
    user,
    awardedContracts: awardedContracts.map(({ id }) => {
        return getAwardedContractEntities(id);
    }),
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetchContracts: (supplierId) => fetchAwardedContractsForSupplier(dispatch, supplierId),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCustomerContracts);
