import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default (key) => {
    switch (AllowanceTypes[key]) {
        case AllowanceTypes["UK Data (GB)"]:
        case AllowanceTypes["EU Data (GB)"]:
        case AllowanceTypes["Non-EU Data (GB)"]:
            return UnitType.Data;
        case AllowanceTypes["Std Calls (mins)"]:
        case AllowanceTypes["Biz rate Calls (mins)"]:
        case AllowanceTypes["Prem Calls"]:
        case AllowanceTypes["Dir Enq"]:
        case AllowanceTypes["Free Calls (mins)"]:
        case AllowanceTypes["UK IDD to EU (mins)"]:
        case AllowanceTypes["UK IDD to USA & Canada (mins)"]:
        case AllowanceTypes["UK IDD to APAC (mins)"]:
        case AllowanceTypes["UK IDD to ROW (mins)"]:
        case AllowanceTypes["EU Calls (mins)"]:
        case AllowanceTypes["Non-EU Calls (mins)"]:
            return UnitType.Mins;
        case AllowanceTypes["Std SMS"]:
        case AllowanceTypes["Prem SMS"]:
        case AllowanceTypes["Int SMS"]:
        case AllowanceTypes["EU SMS"]:
        case AllowanceTypes["Non-EU SMS"]:
            return UnitType.SMS;
        case AllowanceTypes["Std MMS"]:
        case AllowanceTypes["Int MMS"]:
        case AllowanceTypes["EU MMS"]:
        case AllowanceTypes["Non-EU MMS"]:
            return UnitType.MMS;
    }
};
