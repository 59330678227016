import dayjs from "dayjs";
import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import Factory from "@Shared/Factory";
import { Pages, Status } from "./enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const converted = [version1].reduce((converted, f) => f(converted), original);
    let { version, supplierId, tenderId, tender, userId, proposal = {}, costPerItem, progressIndex = 0, submittedAt, notification, status, tab = Pages.Introduction, meta, metaWithLeasing, metaWithLeasingWithVat, metaWithVat, scores, scoresWithLeasingWithVat, scoresWithLeasing, scoresWithVat, awarded, feedback, adminFeedback, favourite, supplierName, limitedGuarantee, assetLock, internalNotes, devices, editing, } = converted;
    if (submittedAt) {
        submittedAt = dayjs.utc(submittedAt);
    }
    if (status === undefined) {
        status = Status.Draft;
    }
    tab = Number(tab);
    scores = Factory.Scores(scores);
    if (scoresWithLeasing !== undefined) {
        scoresWithLeasing = Factory.Scores(scoresWithLeasing);
    }
    if (scoresWithLeasingWithVat !== undefined) {
        scoresWithLeasingWithVat = Factory.Scores(scoresWithLeasingWithVat);
    }
    if (scoresWithVat !== undefined) {
        scoresWithVat = Factory.Scores(scoresWithVat);
    }
    if (tender) {
        tender = Factory.Tender(tender);
    }
    proposal = Factory.Proposal(proposal);
    costPerItem = Factory.CostPerItem(costPerItem);
    return {
        ...baseMigrated,
        version,
        supplierId,
        tenderId,
        userId,
        tender,
        proposal,
        costPerItem,
        progressIndex,
        submittedAt,
        notification,
        status,
        tab,
        meta,
        metaWithLeasing,
        metaWithLeasingWithVat,
        metaWithVat,
        scores,
        scoresWithLeasingWithVat,
        scoresWithLeasing,
        scoresWithVat,
        feedback,
        awarded,
        adminFeedback,
        favourite,
        supplierName,
        limitedGuarantee,
        assetLock,
        internalNotes,
        devices,
        editing,
    };
};
