import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import userMigrate from "@Shared/Entities/AbstractEntities/User/migrate";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version3 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 3) {
        return current;
    }
    let { partnerId } = current;
    if (current.brokerId) {
        partnerId = current.brokerId;
    }
    return { ...current, partnerId, version: 3 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const userMigrated = userMigrate(original);
    const converted = [version1, version3].reduce((converted, f) => f(converted), original);
    const { version, partnerId, role } = converted;
    return {
        ...baseMigrated,
        ...userMigrated,
        version,
        partnerId,
        role,
    };
};
