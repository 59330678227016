// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--NHODH{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);width:35em;background-color:var(--card-color);border-radius:.5em}.popup--NHODH .wrapper--uayAl .title--DhbUQ{margin-bottom:1em;font-weight:bold;font-size:1.1rem}.popup--NHODH .wrapper--uayAl .name--PZDuy{font-weight:bold}.popup--NHODH .wrapper--uayAl .buttons--SpI0h{display:flex;gap:.5em;margin-top:1em}.popup--NHODH .wrapper--uayAl .buttons--SpI0h .fill--fziyJ{flex:1}.popup--NHODH .wrapper--uayAl.loading--xScX_{position:relative;z-index:20;background-color:var(--card-color);background-repeat:no-repeat;opacity:.5;-moz-opacity:.5;filter:alpha(opacity=50)}.popup--NHODH .wrapper--uayAl.loading--xScX_ .spinner--qW15D{position:absolute;top:calc(50% - 2.5rem);left:calc(50% - 2.5rem);display:flex}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderResponses/BeginContracting/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,2EAA2E,CAAC,UAAU,CAAC,kCAAkC,CAAC,kBAAkB,CAAC,4CAAuB,iBAAiB,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,2CAAsB,gBAAgB,CAAC,8CAAyB,YAAY,CAAC,QAAQ,CAAC,cAAc,CAAC,2DAA+B,MAAM,CAAC,6CAAwB,iBAAiB,CAAC,UAAU,CAAC,kCAAkC,CAAC,2BAA2B,CAAC,UAAU,CAAC,eAAe,CAAC,wBAAwB,CAAC,6DAAiC,iBAAiB,CAAC,sBAAsB,CAAC,uBAAuB,CAAC,YAAY","sourcesContent":[".popup{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);width:35em;background-color:var(--card-color);border-radius:.5em}.popup .wrapper .title{margin-bottom:1em;font-weight:bold;font-size:1.1rem}.popup .wrapper .name{font-weight:bold}.popup .wrapper .buttons{display:flex;gap:.5em;margin-top:1em}.popup .wrapper .buttons .fill{flex:1}.popup .wrapper.loading{position:relative;z-index:20;background-color:var(--card-color);background-repeat:no-repeat;opacity:.5;-moz-opacity:.5;filter:alpha(opacity=50)}.popup .wrapper.loading .spinner{position:absolute;top:calc(50% - 2.5rem);left:calc(50% - 2.5rem);display:flex}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup--NHODH",
	"wrapper": "wrapper--uayAl",
	"title": "title--DhbUQ",
	"name": "name--PZDuy",
	"buttons": "buttons--SpI0h",
	"fill": "fill--fziyJ",
	"loading": "loading--xScX_",
	"spinner": "spinner--qW15D"
};
export default ___CSS_LOADER_EXPORT___;
