import fastdom from "fastdom";
import { useLayoutEffect, useRef } from "react";
const store = {};
const add = (key, reference) => {
    const resize = () => handleResize(key);
    if (!store[key]) {
        store[key] = [];
        window.addEventListener("resize", resize);
    }
    console.log("ref", reference, "current", reference.current);
    store[key].push(reference);
    // cleanup function
    return () => {
        const index = store[key].indexOf(reference);
        if (index > -1) {
            store[key].splice(index, 1);
        }
        if (store[key].length === 0) {
            window.removeEventListener("resize", resize);
        }
    };
};
const handleResize = (key) => {
    // get all elements with the same key
    const elements = store[key];
    if (elements) {
        fastdom.measure(() => {
            for (const element of elements) {
                element.current.style.minHeight = `unset`;
            }
            const widths = elements
                .map((element) => element.current?.clientHeight || 0)
                .filter((w) => w > 0);
            const target = Math.max(...widths);
            fastdom.mutate(() => {
                for (const element of elements) {
                    if ((element.current?.clientHeight || 0) === target) {
                        continue;
                    }
                    element.current.style.minHeight = `${target}px`;
                }
            });
        });
    }
};
export const useEqualHeight = (key, deps) => {
    const reference = useRef(null);
    useLayoutEffect(() => {
        const cleanup = add(key, reference);
        return () => {
            cleanup();
        };
    }, []);
    useLayoutEffect(() => {
        handleResize(key);
    }, deps);
    return reference;
};
