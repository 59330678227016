import SVG from "@Components/Icons/SVG";
export default class Send extends SVG {
    path = [
        "M 79.2151 44.6057",
        "L 30.9561 24.6464",
        "C 28.9229 23.8053 26.6261 24.1752 24.962 25.6114",
        "C 23.2978 27.0475 22.6017 29.2604 23.1458 31.3862",
        "L 27.4412 48.176 H 48.4724",
        "C 49.4402 48.176 50.2248 48.9582 50.2248 49.9229",
        "C 50.2248 50.8874 49.4402 51.6695 48.4724 51.6695",
        "H 27.4412 L 23.1458 68.4593 C 22.6017 70.5853 23.2978 72.798 24.962 74.2342",
        "C 26.6296 75.6731 28.9266 76.0384 30.9561 75.1991 L 79.2151 55.2402",
        "C 81.4124 54.3314 82.7772 52.294 82.7772 49.9229",
        "C 82.7772 47.5518 81.4124 45.5141 79.2151 44.6057 Z M 2.1292 50.0364",
        "a 10.646 10.646 90 0 0 95.8144 0 a 10.646 10.646 90 0 0 -95.8144 0",
    ];
    evenOdd = true;
}
