// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--SjLHj{position:relative;display:flex;border:1px solid var(--border-color);border-radius:4px}.wrapper--SjLHj:hover{border-color:var(--primary-button-color)}.wrapper--SjLHj .error--d3vPr{color:var(--error-color);font-size:.75rem}.wrapper--SjLHj .title--vPiKA{display:flex;align-items:center;padding:0 1.03em;color:var(--text-color);background-color:#e9ecef;border-radius:3px 0 0 3px}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Dropdown/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,iBAAiB,CAAC,YAAY,CAAC,oCAAoC,CAAC,iBAAiB,CAAC,sBAAe,wCAAwC,CAAC,8BAAgB,wBAAwB,CAAC,gBAAgB,CAAC,8BAAgB,YAAY,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,uBAAuB,CAAC,wBAAwB,CAAC,yBAAyB","sourcesContent":[".wrapper{position:relative;display:flex;border:1px solid var(--border-color);border-radius:4px}.wrapper:hover{border-color:var(--primary-button-color)}.wrapper .error{color:var(--error-color);font-size:.75rem}.wrapper .title{display:flex;align-items:center;padding:0 1.03em;color:var(--text-color);background-color:#e9ecef;border-radius:3px 0 0 3px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--SjLHj",
	"error": "error--d3vPr",
	"title": "title--vPiKA"
};
export default ___CSS_LOADER_EXPORT___;
