import React from "react";
import { SelectTypes } from "@Components/ChatBot";
import Date from "@Components/ChatBot/TextInput/Date";
import Send from "@Components/Icons/Send";
import styles from "./index.module.scss";
import Multi from "./Multi";
import Single from "./Single";
export default class TextInput extends React.Component {
    displayInput = () => {
        switch (this.props.type) {
            case SelectTypes.MultiText:
                return (React.createElement(Multi, { values: this.props.values, change: this.props.multiChanged, validation: this.props.validation }));
            case SelectTypes.DatePicker:
                return (React.createElement(Date, { value: this.props.value, change: this.props.valueChanged }));
            default:
                const placeholder = this.props.type === SelectTypes.Options
                    ? "Select options above or type here..."
                    : "Type here...";
                return (React.createElement(Single, { value: this.props.value, change: this.props.valueChanged, placeholder: placeholder }));
        }
    };
    render() {
        const { type } = this.props;
        return (React.createElement("div", { className: `
            ${styles.input}
            ${type === SelectTypes.MultiText ? styles.multi : ""}
            ` },
            this.displayInput(),
            React.createElement("div", { className: styles.send, onClick: this.props.submit },
                React.createElement(Send, { size: "3em", primary: true }))));
    }
}
