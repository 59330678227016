import React from "react";
import Arrow from "@Components/Icons/Arrow";
import styles from "@Components/Table/Display/index.module.scss";
import { useEqualWidth } from "@Helpers/EqualWidth";
export function THead(props) {
    const { fields, showSelect, light } = props;
    return (React.createElement("thead", null,
        React.createElement("tr", null,
            showSelect && (React.createElement(React.Fragment, null,
                React.createElement("th", { className: light ? styles.light : "" },
                    React.createElement("input", { type: "checkbox", onChange: props.toggleSelectAll })))),
            fields.map(({ header = "", sortable, field, leftAlign, rightAlign, className = "", widthControl = false, }) => {
                let reference = null;
                if (widthControl) {
                    reference = useEqualWidth(field);
                }
                return (React.createElement("th", { className: ` ${className} ${light ? styles.light : ""} ${props.noPadding ? styles.noPadding : ""}`, key: field, onClick: () => props.toggleSort(field, sortable), ref: reference },
                    React.createElement("div", { className: `${leftAlign
                            ? styles.leftAlign
                            : rightAlign
                                ? styles.rightAlign
                                : styles.center}` },
                        React.createElement("div", { className: `${styles.title}` }, header),
                        sortable && (React.createElement(Arrow, { size: `1em`, light: true, rotate: props.sortBy ===
                                "-".concat(field)
                                ? 270
                                : 90 })))));
            }))));
}
export default THead;
