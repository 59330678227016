// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons--aisXH{margin-right:1em !important}.buttonGroup--vjhu3{display:flex;gap:1rem}.buttonGroupCenter--VOwhQ{display:flex;flex-wrap:wrap;align-items:center;justify-content:center;margin-top:3em}.buttonGroupLeft--DpMya{display:flex;flex-wrap:wrap;align-items:center;justify-content:left}.buttonGroupRight--O6Frn{display:flex;flex-wrap:wrap;align-items:center;justify-content:right}.buttonWithTextAndIcon--HNBUX{display:flex;align-items:center;justify-content:safe center;gap:1rem;height:100%}.splitGroups--W0QEX{display:flex;flex-wrap:wrap;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/gui/Components/Sass/buttons.module.scss"],"names":[],"mappings":"AAAA,gBAAS,2BAA2B,CAAC,oBAAa,YAAY,CAAC,QAAQ,CAAC,0BAAmB,YAAY,CAAC,cAAc,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,cAAc,CAAC,wBAAiB,YAAY,CAAC,cAAc,CAAC,kBAAkB,CAAC,oBAAoB,CAAC,yBAAkB,YAAY,CAAC,cAAc,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,8BAAuB,YAAY,CAAC,kBAAkB,CAAC,2BAA2B,CAAC,QAAQ,CAAC,WAAW,CAAC,oBAAa,YAAY,CAAC,cAAc,CAAC,6BAA6B","sourcesContent":[".buttons{margin-right:1em !important}.buttonGroup{display:flex;gap:1rem}.buttonGroupCenter{display:flex;flex-wrap:wrap;align-items:center;justify-content:center;margin-top:3em}.buttonGroupLeft{display:flex;flex-wrap:wrap;align-items:center;justify-content:left}.buttonGroupRight{display:flex;flex-wrap:wrap;align-items:center;justify-content:right}.buttonWithTextAndIcon{display:flex;align-items:center;justify-content:safe center;gap:1rem;height:100%}.splitGroups{display:flex;flex-wrap:wrap;justify-content:space-between}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "buttons--aisXH",
	"buttonGroup": "buttonGroup--vjhu3",
	"buttonGroupCenter": "buttonGroupCenter--VOwhQ",
	"buttonGroupLeft": "buttonGroupLeft--DpMya",
	"buttonGroupRight": "buttonGroupRight--O6Frn",
	"buttonWithTextAndIcon": "buttonWithTextAndIcon--HNBUX",
	"splitGroups": "splitGroups--W0QEX"
};
export default ___CSS_LOADER_EXPORT___;
