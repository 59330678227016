import React from "react";
import styles from "./index.module.scss";
export default class RadioGroup extends React.Component {
    handleChange = (value) => () => {
        if (this.props.value !== value) {
            this.props.valueChange(this.props.name, value);
        }
    };
    render() {
        const { error } = this.props;
        const items = this.props.options.map(({ value, label }) => {
            const selected = this.props.value === value;
            return (React.createElement("div", { className: styles.item, key: value },
                React.createElement("div", { className: styles.dotWrapper },
                    React.createElement("div", { className: `
                        ${styles.dot}
                        ${selected ? styles.selected : ""}
                        ${error ? styles.error : ""}
                        `, onClick: this.handleChange(value) })),
                React.createElement("div", { className: styles.label, onClick: this.handleChange(value) }, label)));
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${styles.wrapper} ${this.props.className}` }, items)));
    }
}
