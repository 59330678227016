import Factory from "@Shared/Factory";
import { LOGOUT, SET_USER } from "@Store/Actions/Auth";
export default (state = {}, { type, data }) => {
    switch (type) {
        case SET_USER:
            return Factory.User(data);
        case LOGOUT:
            return {};
    }
    return state;
};
