import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class UsageStats extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { version } = this._data;
        const { averageData, usedVoiceLines, totalCost, tariffCosts, dataLines, totalLines, daysTraveller, pictureMessages, iddEurope, iddApac, iddRow, iddUsa, ukToIntTexts, roamingTexts, oobCosts, iddTotalAggregates, iddEuropeAggregates, iddRowAggregates, iddApacAggregates, iddUsaAndCanadaAggregates, percentage, allRecords, categorisedRecords, nonCategorisedRecords, invalidCredentials, unusedLineCost, voiceOnlyLines, } = this;
        return {
            version,
            averageData,
            usedVoiceLines,
            totalCost,
            tariffCosts,
            dataLines,
            totalLines,
            daysTraveller,
            pictureMessages,
            iddEurope,
            iddApac,
            iddRow,
            iddUsa,
            ukToIntTexts,
            roamingTexts,
            oobCosts,
            iddTotalAggregates,
            iddEuropeAggregates,
            iddRowAggregates,
            iddApacAggregates,
            iddUsaAndCanadaAggregates,
            percentage,
            allRecords,
            categorisedRecords,
            nonCategorisedRecords,
            invalidCredentials,
            unusedLineCost,
            voiceOnlyLines,
        };
    }
    get percentage() {
        return this._data.percentage || 0;
    }
    get invalidCredentials() {
        return this._data.invalidCredentials || false;
    }
    get allRecords() {
        return this._data.allRecords || 0;
    }
    get categorisedRecords() {
        return this._data.categorisedRecords || 0;
    }
    get nonCategorisedRecords() {
        return this._data.nonCategorisedRecords || 0;
    }
    get averageData() {
        return this._data.averageData || 0;
    }
    get usedVoiceLines() {
        return this._data.usedVoiceLines || 0;
    }
    get voiceOnlyLines() {
        return this._data.voiceOnlyLines || 0;
    }
    get usedLines() {
        return Math.max(0, this.usedVoiceLines + this.dataLines + this.voiceOnlyLines);
    }
    get unusedLines() {
        const count = this.totalLines - this.usedLines;
        return Math.max(0, count);
    }
    get totalCost() {
        return this._data.totalCost || 0;
    }
    get tariffCosts() {
        return this._data.tariffCosts || 0;
    }
    get unusedLineCost() {
        return this._data.unusedLineCost;
    }
    get dataLines() {
        return this._data.dataLines || 0;
    }
    get totalLines() {
        return this._data.totalLines || 0;
    }
    get daysTraveller() {
        return this._data.daysTraveller || 0;
    }
    get recommendedData() {
        const recommendedData = Number(this.averageData) * 1.35;
        return Math.ceil(recommendedData / 20) * 20;
    }
    get pictureMessages() {
        return this._data.pictureMessages || 0;
    }
    get iddEurope() {
        return this._data.iddEurope || 0;
    }
    get iddApac() {
        return this._data.iddApac || 0;
    }
    get iddRow() {
        return this._data.iddRow || 0;
    }
    get iddUsa() {
        return this._data.iddUsa || 0;
    }
    get ukToIntTexts() {
        return this._data.ukToIntTexts || 0;
    }
    get roamingTexts() {
        return this._data.roamingTexts || 0;
    }
    get oobCosts() {
        return this._data.oobCosts || 0;
    }
    get iddTotalAggregates() {
        return this._data.iddTotalAggregates;
    }
    get iddEuropeAggregates() {
        return this._data.iddEuropeAggregates;
    }
    get iddRowAggregates() {
        return this._data.iddRowAggregates;
    }
    get iddApacAggregates() {
        return this._data.iddApacAggregates;
    }
    get iddUsaAndCanadaAggregates() {
        return this._data.iddUsaAndCanadaAggregates;
    }
}
