import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
export default (value, name) => {
    const unit = GetUnits(name);
    switch (unit) {
        case UnitType.Mins:
            return value / 60;
        case UnitType.Data:
            return value / 1000;
        default:
            return value;
    }
};
