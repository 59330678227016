import "@Shared/Helpers/Bootstrap";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Theme from "@Components/Theme";
import { store } from "@Store/index";
import Pages from "./pages";
if (document.querySelector("#app")) {
    ReactDOM.render(React.createElement(Provider, { store: store },
        React.createElement(Pages, null),
        React.createElement(Theme, null)), document.querySelector("#app"));
}
