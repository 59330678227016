import { API_TYPE, notify200, notify4xx } from "@Store/Actions/Notifications";
import { del, discontinueDevicesPost, find, get, parsePriceListPost, post, priceListPost, put, } from "@Api/Hardware/devices";
import { store } from "@Store/index";
export const DELETE_DEVICE = "deleteDevice";
export const deleteDevice = (dispatch, data) => del(data)
    .then((id) => dispatch({
    type: DELETE_DEVICE,
    data: id,
}))
    .then(notify200(dispatch, API_TYPE.DELETE, "Device Deleted", "The device(s) were deleted successfully"))
    .catch(notify4xx(dispatch));
export const ADD_DEVICE = "updateDevice";
const add = (dispatch) => (data) => {
    return dispatch({
        type: ADD_DEVICE,
        data,
    });
};
export const BULK_ADD_DEVICES = "bulkUpdateDevice";
const bulkAdd = (dispatch) => (data) => {
    return dispatch({
        type: BULK_ADD_DEVICES,
        data,
    });
};
const addUpdated = (dispatch, device) => (data) => {
    if (device.id !== data.id) {
        dispatch({
            type: DELETE_DEVICE,
            data: device.id,
        });
    }
    return dispatch({
        type: ADD_DEVICE,
        data,
    });
};
export const createDevice = (dispatch, data) => post(data)
    .then(add(dispatch))
    .then(notify200(dispatch, API_TYPE.POST, "Device Created", "The device was created successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const updateDevice = (dispatch, data) => put(data)
    .then(addUpdated(dispatch, data))
    .then(notify200(dispatch, API_TYPE.PUT, "Device Updated", "The device was updated successfully"))
    .catch((error) => notify4xx(dispatch)(error).then(add(dispatch)));
export const fetchDevice = (dispatch, deviceId, inclDel = false) => {
    const state = store.getState();
    const devices = state?.hardware?.devices || [];
    if (!devices.some(({ id }) => id === deviceId)) {
        return find(deviceId, inclDel).then(add(dispatch));
    }
    return Promise.resolve();
};
export const fetchDevices = (dispatch, size = 1000) => {
    return get(bulkAdd(dispatch), size);
};
export const createPriceListDevices = (dispatch, devices) => priceListPost(devices).then((devices) => {
    for (const device of devices) {
        dispatch({
            type: ADD_DEVICE,
            data: device,
        });
    }
    return devices;
});
export const parsePriceList = (data) => parsePriceListPost(data);
export const discontinueDevices = (month) => discontinueDevicesPost(month);
