import React from "react";
import styles from "./index.module.scss";
import Tag from "./Tag";
export default class MultiTextAdd extends React.Component {
    static defaultProps = {
        values: [],
        separators: [","],
        format: (text) => text,
    };
    Input = () => {
        // const handleKeyDown = (event: { key: string }) => {
        //     if (event.key === "Enter") {
        //         console.log("do validate")
        //     }
        // }
    };
    handlePaste = (event) => {
        event.stopPropagation();
        const separatedList = event.clipboardData.getData("Text").split(",");
        const newValues = [];
        for (const element of separatedList) {
            if (this.props.values.length + 1 !== this.props.limit) {
                const validatedText = this.props.format(element);
                if (validatedText !== undefined) {
                    newValues.push(validatedText);
                }
            }
        }
        const { values } = this.props;
        this.props.onChange(this.props.name, [...values, ...newValues]);
        event.target.value = "";
        event.preventDefault();
    };
    handleChange = (event) => {
        event.stopPropagation();
        const values = this.props.values || [];
        const text = event.target.value;
        if (event.key === "Backspace" && values.length > 0 && !text) {
            this.props.onChange(this.props.name, values.slice(0, -1));
        }
        if (text && this.Input && values.length !== this.props.limit) {
            const validatedText = this.props.format(text);
            if (validatedText !== undefined) {
                this.props.onChange(this.props.name, [...values, validatedText]);
                event.target.value = "";
                event.preventDefault();
            }
        }
        if (text && values.length !== this.props.limit) {
            const validatedText = this.props.format(text);
            if (validatedText !== undefined) {
                this.props.onChange(this.props.name, [...values, validatedText]);
                event.target.value = "";
                event.preventDefault();
            }
        }
        if (text &&
            this.props.separators.includes(event.key) &&
            values.length !== this.props.limit) {
            const validatedText = this.props.format(text);
            if (validatedText !== undefined) {
                this.props.onChange(this.props.name, [...values, validatedText]);
                event.target.value = "";
                event.preventDefault();
            }
        }
    };
    addToList = (text, event) => {
        const list = this.props.values;
        const validatedText = this.props.format(text);
        if (validatedText !== undefined) {
            this.props.onChange(this.props.name, [...list, validatedText]);
            event.target.value = "";
            event.preventDefault();
        }
    };
    onTagRemove = (text) => {
        this.props.onChange(this.props.name, this.props.values.filter((tag) => tag !== text));
    };
    render() {
        return (React.createElement("div", { className: `${styles.container} 
                ${this.props.disabled ? styles.disabled : ""}
                ${this.props.chat ? styles.chat : ""}
                ${this.props.values.length > 0 ? styles.tagsShown : ""}
                ${this.props.error ? styles.error : ""}
                ` },
            (this.props.values || []).map((tag, index) => (React.createElement(Tag, { key: `${tag}-${index}`, text: tag, remove: this.onTagRemove, chat: this.props.chat }))),
            React.createElement("input", { className: `
                    ${styles.input} 
                    ${this.props.chat ? styles.chat : ""}
                    ${this.props.values.length > 0 ? styles.tagsShown : ""}
                    ${this.props.error ? styles.error : ""}
                    `, type: "text", name: this.props.name, placeholder: this.props.placeHolder, onKeyDown: this.handleChange, disabled: this.props.disabled, onPaste: this.handlePaste, onMouseDown: this.handleChange, onMouseOut: this.handleChange, onBlur: this.handleChange })));
    }
}
