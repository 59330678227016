import BaseEntity from "../../AbstractEntities/BaseEntity";
import { ManagerPackageTypes } from "../../Customer/Tender/Details/enums";
import constraints from "./constraints";
import Meta from "./Meta";
import migrate from "./migrate";
import { Effected, } from "./Proposal/enums";
export default class Response extends BaseEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/suppliers/${this.supplierId}/responses/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { supplierId, tenderId, tender, userId, proposal, progressIndex, submittedAt, notification, status, meta, scores, metaWithLeasingWithVat, scoresWithLeasingWithVat, metaWithLeasing, scoresWithLeasing, metaWithVat, scoresWithVat, feedback, tab, awarded, adminFeedback, favourite, supplierName, limitedGuarantee, assetLock, internalNotes, editing, } = this;
        const { version } = this._data;
        return {
            ...parent,
            version,
            adminFeedback,
            awarded,
            favourite,
            feedback,
            meta,
            metaWithLeasing,
            metaWithLeasingWithVat,
            metaWithVat,
            notification,
            progressIndex,
            proposal: proposal?.data,
            scores: scores?.data,
            scoresWithLeasing: scoresWithLeasing?.data,
            scoresWithLeasingWithVat: scoresWithLeasingWithVat?.data,
            scoresWithVat: scoresWithVat?.data,
            status,
            submittedAt,
            supplierId,
            tab,
            tender,
            tenderId,
            userId,
            supplierName,
            limitedGuarantee,
            assetLock,
            internalNotes,
            editing,
        };
    }
    get toSave() {
        const { seqNo, primaryTerm, tender, meta, metaWithVat, metaWithLeasing, metaWithLeasingWithVat, supplierName, limitedGuarantee, assetLock, editing, ...data } = this.data;
        if (this.isFrozen) {
            return {
                ...data,
                meta,
                metaWithVat,
                metaWithLeasing,
                metaWithLeasingWithVat,
            };
        }
        return data;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get tenderId() {
        return this._data.tenderId;
    }
    get tender() {
        return this._data.tender;
    }
    get awarded() {
        return this._data.awarded;
    }
    get supplierId() {
        return this._data.supplierId;
    }
    get supplierName() {
        return this._data.supplierName;
    }
    get limitedGuarantee() {
        return this._data.limitedGuarantee;
    }
    get assetLock() {
        return this._data.assetLock;
    }
    get userId() {
        return this._data.userId;
    }
    get proposal() {
        return this._data.proposal;
    }
    get CostPerItem() {
        return this._data.costPerItem;
    }
    get progressIndex() {
        if (this.submittedAt !== undefined) {
            return 100;
        }
        return this._data.progressIndex;
    }
    get submittedAt() {
        return this._data.submittedAt;
    }
    get notification() {
        return this._data.notification;
    }
    get status() {
        return this._data.status;
    }
    get scores() {
        return this._data.scores;
    }
    get scoresWithLeasing() {
        return this._data.scoresWithLeasing;
    }
    get scoresWithLeasingWithVat() {
        return this._data.scoresWithLeasingWithVat;
    }
    get scoresWithVat() {
        return this._data.scoresWithVat;
    }
    get feedback() {
        return this._data.feedback;
    }
    get adminFeedback() {
        return this._data.adminFeedback;
    }
    get devices() {
        return this._data.devices;
    }
    get meta() {
        if (!this._data.meta && this.proposal && this.tender) {
            this._data.meta = new Meta({
                proposal: this.proposal,
                tender: this.tender,
                devices: this.devices,
                flags: {},
            });
        }
        return this._data.meta;
    }
    get metaWithLeasing() {
        if (!this._data.metaWithLeasing && this.proposal && this.tender) {
            this._data.metaWithLeasing = new Meta({
                proposal: this.proposal,
                tender: this.tender,
                devices: this.devices,
                flags: { leasing: true },
            });
        }
        return this._data.metaWithLeasing;
    }
    get metaWithVat() {
        if (!this._data.metaWithVat && this.proposal && this.tender) {
            this._data.metaWithVat = new Meta({
                proposal: this.proposal,
                tender: this.tender,
                devices: this.devices,
                flags: { vat: true },
            });
        }
        return this._data.metaWithVat;
    }
    get metaWithLeasingWithVat() {
        if (!this._data.metaWithLeasingWithVat &&
            this.proposal &&
            this.tender) {
            this._data.metaWithLeasingWithVat = new Meta({
                proposal: this.proposal,
                tender: this.tender,
                devices: this.devices,
                flags: { leasing: true, vat: true },
            });
        }
        return this._data.metaWithLeasingWithVat;
    }
    get noMDM() {
        return this.tender.details.MDM === ManagerPackageTypes.None;
    }
    get tab() {
        return this._data.tab;
    }
    get favourite() {
        return this._data.favourite;
    }
    get internalNotes() {
        return this._data.internalNotes;
    }
    get editing() {
        return this._data.editing;
    }
}
