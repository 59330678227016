// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--XNDPG{display:flex;flex-direction:column;width:100%;overflow:hidden}.container--XNDPG .content--OywFv{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container--XNDPG{flex-direction:row}}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Customer/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,YAAY,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,kCAAoB,YAAY,CAAC,SAAS,CAAC,qBAAqB,CAAC,UAAU,CAAC,eAAe,CAAC,yBAAyB,kBAAW,kBAAkB,CAAC","sourcesContent":[".container{display:flex;flex-direction:column;width:100%;overflow:hidden}.container .content{display:flex;flex:auto;flex-direction:column;width:100%;overflow:hidden}@media(min-width: 960px){.container{flex-direction:row}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--XNDPG",
	"content": "content--OywFv"
};
export default ___CSS_LOADER_EXPORT___;
