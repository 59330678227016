import Factory from "@Shared/Factory";
import { ADD_PARTNER_CUSTOMER, DELETE_PARTNER_CUSTOMER, } from "@Store/Actions/Partners/customers";
export default (state = [], { type, data }) => {
    switch (type) {
        case DELETE_PARTNER_CUSTOMER:
            return state.filter((object) => object.id !== data);
        case ADD_PARTNER_CUSTOMER:
            const customer = Factory.PartnerCustomer(data);
            return [...state.filter(({ id }) => id !== customer.id), customer];
    }
    return state;
};
