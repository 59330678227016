import dayjs from "dayjs";
import { displayDate } from "@Shared/Helpers/Date";
export default (tender) => {
    if (tender.publishedAt === undefined) {
        return;
    }
    if (tender.publishedAt.isBefore(dayjs())) {
        return displayDate(tender.publishedAt);
    }
    return `Due ${displayDate(tender.publishedAt)}`;
};
