import React from "react";
import { connect } from "react-redux";
import { addNotification } from "@Store/Actions/Notifications";
import { boot } from "@Store/Actions/Auth";
import Login from "./Login";
import MFA from "./MFA";
class LoginController extends React.Component {
    render() {
        if (this.props.token &&
            this.props.user?.mfaEnabled &&
            !this.props.mfa &&
            !this.props.user?.assumed) {
            return React.createElement(MFA, null);
        }
        return React.createElement(Login, null);
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        boot: () => boot(dispatch),
        notify: (data) => addNotification(dispatch, data),
    };
};
const mapStateToProps = ({ auth: { token, user, mfa } }) => ({
    mfa,
    token,
    user,
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginController);
