export var Status;
(function (Status) {
    Status[Status["Draft"] = 0] = "Draft";
    Status[Status["Ready"] = 1] = "Ready";
    Status[Status["Scored"] = 2] = "Scored";
    Status[Status["FeedbackGiven"] = 3] = "FeedbackGiven";
})(Status || (Status = {}));
export var Feedback;
(function (Feedback) {
    Feedback["Improve_Price"] = "You need to improve your prices";
    Feedback["Good_Price"] = "Your prices were good";
    Feedback["Improve_Signal"] = "You need to improve your signal";
    Feedback["Good_Signal"] = "Your signal ratings are good";
})(Feedback || (Feedback = {}));
export var Pages;
(function (Pages) {
    Pages[Pages["Introduction"] = 0] = "Introduction";
    Pages[Pages["Tariff"] = 1] = "Tariff";
    Pages[Pages["OOB"] = 2] = "OOB";
    Pages[Pages["HardwareFund"] = 3] = "HardwareFund";
    Pages[Pages["Network"] = 4] = "Network";
    Pages[Pages["AdditionalLines"] = 5] = "AdditionalLines";
    Pages[Pages["AdditionalData"] = 6] = "AdditionalData";
    Pages[Pages["MDM"] = 7] = "MDM";
    Pages[Pages["DM"] = 8] = "DM";
    Pages[Pages["CustomerService"] = 9] = "CustomerService";
    Pages[Pages["ReviewAndSubmit"] = 10] = "ReviewAndSubmit";
})(Pages || (Pages = {}));
export var FeedbackBias;
(function (FeedbackBias) {
    FeedbackBias[FeedbackBias["Good"] = 0] = "Good";
    FeedbackBias[FeedbackBias["Bad"] = 1] = "Bad";
    FeedbackBias[FeedbackBias["Neutral"] = 2] = "Neutral";
})(FeedbackBias || (FeedbackBias = {}));
