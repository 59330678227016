import React from "react";
import Popup from "reactjs-popup";
export default class InfoPopup extends React.Component {
    render() {
        return (React.createElement(Popup, { onClose: this.props.onClose, trigger: this.props.children, open: this.props.trigger, modal: true, nested: true, contentStyle: {
                background: "none",
                padding: 0,
                margin: "auto",
                ...this.props.styles,
            }, overlayStyle: {
                ...this.props.overlayStyles,
            }, closeOnDocumentClick: this.props.closeOnDocumentClick }, (close) => this.props.content(close)));
    }
}
