import dayjs from "dayjs";
import { Effected, MDMNames, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import Factory from "@Shared/Factory";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { ADD_RESPONSE, DELETE_RESPONSE, EDIT_BUNDLES, EDIT_PROPOSAL, PROPOSAL_TAB, } from "@Store/Actions/Suppliers/responses";
import { set } from "object-path";
export const cleanAdditionalData = (effects, value) => {
    if (effects !== Effected.AdditionalData) {
        return value;
    }
    const additionalData = Object.keys(value).reduce((object, key) => {
        const keyValue = value[key];
        const additionalDataNoNewLine = keyValue[Effected.AdditionalDataNoNewLine] || false;
        let cleanedKeyValue = {
            [Effected.AdditionalDataNoNewLine]: additionalDataNoNewLine,
        };
        if (!additionalDataNoNewLine) {
            cleanedKeyValue = {
                ...cleanedKeyValue,
                [Effected.AdditionalDataMinQty]: keyValue[Effected.AdditionalDataMinQty],
                [Effected.AdditionalDataType]: keyValue[Effected.AdditionalDataType],
                [Effected.AdditionalDataCost]: keyValue[Effected.AdditionalDataCost],
                [Effected.AdditionalDataAuto]: keyValue[Effected.AdditionalDataAuto] || false,
            };
        }
        object[key] = cleanedKeyValue;
        return object;
    }, {});
    return additionalData;
};
export const cleanAdditional = (proposal = {}, effects, value) => {
    if (effects !== Effected.TariffComponents) {
        return proposal;
    }
    const groups = groupedTariff(value || []);
    const additionalData = groups.reduce((additionalData, { id }) => {
        if (proposal?.additionalData?.[id]) {
            additionalData[id] = proposal?.additionalData?.[id];
        }
        return additionalData;
    }, {});
    const additionalLines = groups.reduce((additionalLines, { id }) => {
        if (proposal?.additionalLines?.[id]) {
            additionalLines[id] = proposal?.additionalLines?.[id];
        }
        return additionalLines;
    }, {});
    return { ...proposal, additionalData, additionalLines };
};
export const cleanMDM = (proposal = {}, effects, value) => {
    if (effects === Effected.OfferMDM && value === false) {
        proposal = [
            Effected.MDMName,
            Effected.MDMDetails,
            Effected.OtherName,
            Effected.MDMOS,
            Effected.MinCommitmentPeriod,
            Effected.MinCommitmentPeriodOther,
            Effected.IsCloudFree,
            Effected.MDMLineCost,
            Effected.MDMRange,
            Effected.EnterpriseVersions,
            Effected.AlternativeSolution,
            Effected.FurtherMDM,
        ].reduce((dt, f) => {
            dt[f] = undefined;
            return dt;
        }, proposal);
    }
    if (effects === Effected.MDMName) {
        if (value === MDMNames.Other) {
            proposal[Effected.MDMDetails] = undefined;
        }
        else {
            proposal[Effected.OtherName] = undefined;
        }
    }
    if (effects === Effected.MinCommitmentPeriod &&
        value !== TimePeriods.Other) {
        proposal[Effected.MinCommitmentPeriodOther] = undefined;
    }
    if (effects === Effected.IsCloudFree && value === true) {
        proposal[Effected.MDMLineCost] = undefined;
    }
    return proposal;
};
export const cleanDM = (proposal = {}, effects, value) => {
    if (effects === Effected.OfferDM && value === false) {
        proposal = [
            Effected.DMTool,
            Effected.DMOS,
            Effected.DMFeatures,
            Effected.DMFree,
            Effected.DMLineCost,
            Effected.DMMinCommitmentPeriod,
            Effected.DMMinCommitmentPeriodOther,
            Effected.DMRange,
            Effected.DMEnterprise,
            Effected.DMAlternative,
        ].reduce((dt, f) => {
            dt[f] = undefined;
            return dt;
        }, proposal);
    }
    if (effects === Effected.DMMinCommitmentPeriod &&
        value === TimePeriods.Other) {
        proposal[Effected.DMMinCommitmentPeriodOther] = undefined;
    }
    if (effects === Effected.DMFree && value === true) {
        proposal[Effected.DMLineCost] = undefined;
    }
    if (effects === Effected.DMRange && value === false) {
        proposal[Effected.DMEnterprise] = undefined;
    }
    return proposal;
};
const updateProposal = (current, data) => {
    let proposal = { ...current.proposal.data };
    let { value, effects } = data;
    if (data.validate) {
        try {
            value = data.validate(value);
        }
        catch { }
    }
    proposal = cleanMDM(proposal, effects, value);
    proposal = cleanDM(proposal, effects, value);
    proposal = cleanAdditional(proposal, effects, value);
    value = cleanAdditionalData(effects, value);
    set(proposal, effects, value);
    return Factory.Response({
        ...current.data,
        proposal,
        updatedAt: dayjs.utc(),
    });
};
const updateWithBundles = (current, data) => {
    const proposal = { ...current.proposal.data };
    const { bundles = [] } = data;
    return Factory.Response({
        ...current.data,
        proposal: { ...proposal, bundles },
    });
};
export default (state = [], { type, data = {} }) => {
    switch (type) {
        case DELETE_RESPONSE:
            return state.filter(({ id }) => id !== data);
        case ADD_RESPONSE:
            const response = Factory.Response(data);
            return [...state.filter(({ id }) => id !== response.id), response];
        case EDIT_PROPOSAL:
            const current = state.find(({ id }) => id === data.id);
            if (!current || data.effects === undefined) {
                return state;
            }
            const updated = updateProposal(current, data);
            return [...state.filter(({ id }) => id !== data.id), updated];
        case EDIT_BUNDLES:
            const currentResponse = state.find(({ id }) => id === data.id);
            if (!currentResponse) {
                return state;
            }
            const updatedWithBundles = updateWithBundles(currentResponse, data);
            return [
                ...state.filter(({ id }) => id !== data.id),
                updatedWithBundles,
            ];
        case PROPOSAL_TAB:
            const currentTab = state.find(({ id }) => id === data.id);
            const editedTab = Factory.Response({
                ...currentTab.data,
                tab: data.tab,
                progressIndex: data.progressIndex,
            });
            return [...state.filter(({ id }) => id !== data.id), editedTab];
    }
    return state;
};
