import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import ChatBot, { ChatWho, } from "@Components/ChatBot";
import { addChat, editAnswer } from "@Store/Actions/Customers/tenders";
import chatLogic from "./Logic";
class TenderChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEndDate: dayjs.utc(),
            typing: false,
            query: null,
            select: undefined,
        };
    }
    componentDidMount() {
        this.initChat();
    }
    initChat = () => {
        new Promise((resolve, reject) => {
            if (this.props.history.length > 0) {
                return resolve(null);
            }
            reject();
        })
            .then(() => this.addMessage({ text: "Welcome back" }))
            .catch(() => this.addMessage({
            text: "You are nearly there, now we ask you a few questions to perfectly tailor your tender, which allows our panel of the UK's biggest and best mobile suppliers to design the very best solution for you",
        }).then(() => this.addMessage({ text: "Let's get started." })))
            .then(() => this.setQuestion());
    };
    setQuestion = () => {
        const query = chatLogic(this.props.tender, this.props.deviceOptions, this.props.months);
        if (!query) {
            return this.addMessage("Thank you, all of my questions have been answered").then(() => this.addMessage("You can now submit your tender"));
        }
        return query.question
            .reduce((p, object) => p.then(() => this.addMessage(object)), Promise.resolve())
            .then(() => this.setState({ query }))
            .then(() => {
            const select = query.select || {};
            this.setState({ select });
        });
    };
    input = ({ message, value }) => {
        const { effects, reply = () => [], validate, } = this.state.query;
        const data = {
            id: this.props.tender.id,
            message,
            who: ChatWho.Customer,
            value,
            effects,
            validate,
        };
        this.setState({ query: null });
        return this.props
            .addChat(data)
            .then(() => {
            return reply(value).reduce((p, object) => p.then(() => this.addMessage(object)), Promise.resolve());
        })
            .then(() => this.setQuestion());
    };
    addMessage = (input) => {
        let text = "", tooltip;
        if (typeof input === "string") {
            text = input;
        }
        else {
            text = input.text;
            tooltip = input.tooltip;
        }
        return new Promise((resolve) => {
            this.setState({ typing: true });
            setTimeout(() => {
                this.setState({ typing: false });
                this.props
                    .addChat({
                    id: this.props.tender.id,
                    who: ChatWho.PredictyBot,
                    message: text,
                    tooltip,
                })
                    .then(() => {
                    resolve(true);
                });
            }, Math.min(text.length * 75 + 150, 1100));
        });
    };
    edit = (index) => {
        this.props
            .addChat({
            id: this.props.tender.id,
            who: ChatWho.PredictyBot,
            message: "You've changed your mind about your answer? No problem!",
        })
            .then(() => this.props.editAnswer({ index, id: this.props.tender.id }))
            .then(() => this.setState({ query: null }))
            .then(() => this.setQuestion());
    };
    render() {
        return (React.createElement(ChatBot, { chat: this.props.history, output: this.input, typing: this.state.typing, select: this.state.select, user: this.props.user }));
    }
}
const mapStateToProps = ({ customers: { chat = [] } }, props) => ({
    history: chat.filter(({ id }) => id === props.tender.id),
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        addChat: (data) => addChat(dispatch, data),
        editAnswer: (data) => editAnswer(dispatch, data),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TenderChat);
