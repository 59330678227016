import SVG from "@Components/Icons/SVG";
export default class Close extends SVG {
    path = ["M 10 10 L 90 90 M 90 10 L 10 90"];
    noFill = true;
    strokeDetails = {
        stroke: this.props.color || "#ffffff",
        strokeWidth: "30",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
}
