import SVG from "@Components/Icons/SVG";
export default class Locked extends SVG {
    path = [
        "M 78.125 99.2308 H 21.875 C 16.7083 99.2308 12.5 95.0994 12.5 90.0179",
        "V 47.0237 C 12.5 41.9423 16.7083 37.8106 21.875 37.8106 H 78.125",
        "C 83.2917 37.8106 87.5 41.9423 87.5 47.0237 V 90.0179",
        "C 87.5 95.0994 83.2917 99.2308 78.125 99.2308 Z M 21.875 43.9527",
        "C 20.1542 43.9527 18.75 45.3285 18.75 47.0237 V 90.0179",
        "C 18.75 91.7131 20.1542 93.089 21.875 93.089 H 78.125",
        "C 79.8458 93.089 81.25 91.7131 81.25 90.0179 V 47.0237",
        "C 81.25 45.3285 79.8458 43.9527 78.125 43.9527 H 21.875 Z M 71.875 43.9523",
        "C 70.15 43.9523 68.75 42.5765 68.75 40.8815 V 25.5262",
        "C 68.75 15.3673 60.3375 7.1002 50 7.1002 C 39.6625 7.1002 31.25 15.3673 31.25 25.5262",
        "V 40.8815 C 31.25 42.5765 29.85 43.9523 28.125 43.9523",
        "C 26.4 43.9523 25 42.5765 25 40.8815 V 25.5262 C 25 11.9771 36.2125 0.9581 50 0.9581",
        "C 63.7875 0.9581 75 11.9771 75 25.5262 V 40.8815",
        "C 75 42.5765 73.6 43.9523 71.875 43.9523 Z M 50 70.5683",
        "C 45.4042 70.5683 41.6667 66.8952 41.6667 62.3783",
        "C 41.6667 57.8617 45.4042 54.1885 50 54.1885",
        "C 54.5958 54.1885 58.3333 57.8617 58.3333 62.3783",
        "C 58.3333 66.8952 54.5958 70.5683 50 70.5683 Z M 50 60.3308",
        "C 48.8542 60.3308 47.9167 61.2481 47.9167 62.3783",
        "C 47.9167 63.5085 48.8542 64.4258 50 64.4258",
        "C 51.1458 64.4258 52.0833 63.5085 52.0833 62.3783",
        "C 52.0833 61.2481 51.1458 60.3308 50 60.3308 Z M 50 82.8521",
        "C 48.275 82.8521 46.875 81.4762 46.875 79.781 V 68.52",
        "C 46.875 66.8248 48.275 65.449 50 65.449 C 51.725 65.449 53.125 66.8248 53.125 68.52",
        "V 79.781 C 53.125 81.4762 51.725 82.8521 50 82.8521 Z",
    ];
}
