import React from "react";
import styles from "./index.module.scss";
export default class Single extends React.Component {
    change = (event) => {
        this.props.change("text", event.target.value);
    };
    render() {
        return (React.createElement("input", { className: styles.type, value: this.props.value, onChange: this.change, placeholder: this.props.placeholder }));
    }
}
