import { ADD_COMPARISON, DELETE_COMPARISON, } from "@Store/Actions/Customers/comparisons";
export default (state = [], { type, data }) => {
    let comparisons = [...state];
    if (comparisons.length === 0 && localStorage.comparisons) {
        const parsed = JSON.parse(localStorage.comparisons);
        if (parsed.length > 0) {
            comparisons = parsed;
        }
    }
    switch (type) {
        case DELETE_COMPARISON:
            comparisons = state.filter((object) => object !== data);
            break;
        case ADD_COMPARISON:
            comparisons = [...state.filter((id) => id !== data), data];
            break;
    }
    localStorage.setItem("comparisons", JSON.stringify(comparisons));
    return comparisons;
};
