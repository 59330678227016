import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class CompanyDetails extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { name, addressLine1, addressLine2, city, region, country, postcode, status, sicCodes, updatedAt, } = this;
        const { version } = this._data;
        return {
            version,
            name,
            addressLine1,
            addressLine2,
            city,
            region,
            country,
            postcode,
            status,
            sicCodes,
            updatedAt,
        };
    }
    get name() {
        return this._data.name;
    }
    get addressLine1() {
        return this._data.addressLine1;
    }
    get addressLine2() {
        return this._data.addressLine2;
    }
    get city() {
        return this._data.city;
    }
    get region() {
        return this._data.region;
    }
    get country() {
        return this._data.country;
    }
    get postcode() {
        return this._data.postcode;
    }
    get address() {
        return [
            this.addressLine1,
            this.addressLine2,
            this.city,
            this.region,
            this.country,
            this.postcode,
        ]
            .filter(Boolean)
            .join(", ");
    }
    get status() {
        return this._data.status;
    }
    get sicCodes() {
        return this._data.sicCodes;
    }
    get updatedAt() {
        return this._data.updatedAt;
    }
}
