import Factory from "@Shared/Factory";
const companyVersion1 = (current = {}) => {
    const { companyVersion = 0 } = current;
    if (companyVersion >= 1) {
        return current;
    }
    return { ...current, companyVersion: 1 };
};
export default (original = {}) => {
    const converted = [companyVersion1].reduce((converted, f) => f(converted), original);
    let { companyVersion, companyNo, details, tradingAs, viewAll } = converted;
    details = Factory.CompanyDetails(details);
    if (companyNo !== undefined) {
        companyNo = companyNo.toUpperCase();
    }
    return {
        companyVersion,
        companyNo,
        details,
        tradingAs,
        viewAll,
    };
};
