import React from "react";
import InfoPopup from "@Components/InfoPopup";
import UsageUpload from "@Components/UsageUploadSensabill";
import { upload } from "@Api/Registration";
import Uploading from "@Components/Pages/Fallbacks/Uploading";
import Factory from "@Shared/Factory";
export default class AddUsage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleUploading: false,
        };
    }
    getUsageId = async () => {
        // Need to create usage
        return await this.props.createUsage(Factory.Usage({
            customerId: this.props.customerId,
            status: 0,
        }));
    };
    openUploadingPopup = () => {
        this.setState({ visibleUploading: true });
    };
    closeUploadingPopup = () => {
        this.setState({ visibleUploading: false });
    };
    submitFiles = async (files) => {
        const id = await this.getUsageId();
        this.openUploadingPopup();
        await upload(this.props.customerId, id, files);
        this.closeUploadingPopup();
        this.props.onClose();
    };
    submitPlatform = async (data) => {
        const id = await this.getUsageId();
        await this.props.updateUsage(Factory.Usage({
            id,
            customerId: this.props.customerId,
            status: 0,
            ...data,
        }));
        this.props.onClose();
    };
    render() {
        const popup = (close) => {
            return (React.createElement(UsageUpload, { submitPlatform: this.submitPlatform, submitFiles: this.submitFiles, inModal: true, close: close, title: (s) => (React.createElement("div", { className: s.title },
                    React.createElement("b", { className: s.primaryColor }, "Bill Report"),
                    " ",
                    "upload, two easy ways to start Sensabill")) }));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(InfoPopup, { trigger: this.props.open === true, content: popup, onClose: this.props.onClose }),
            React.createElement(Uploading, { open: this.state.visibleUploading, onClose: this.closeUploadingPopup })));
    }
}
