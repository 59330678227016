import React from "react";
import styles from "./index.module.scss";
export default class SupportTel extends React.Component {
    render() {
        if (this.props.gavin) {
            return (React.createElement("a", { className: styles.blue, href: "tel:07306271165" }, "07306 271165"));
        }
        return (React.createElement("a", { className: styles.blue, href: "tel:07500900700" }, "07500 900 700"));
    }
}
