import Company from "@Shared/Entities/AbstractEntities/Company";
import constraints from "./constraints";
export default class Customer extends Company {
    constructor(data = {}, extraConstraints = {}) {
        super(data, { ...constraints, ...extraConstraints });
    }
    get canViewSensabill() {
        return this._data.canViewSensabill;
    }
    get canCreateTender() {
        return this._data.canCreateTender;
    }
    get data() {
        const parent = super.data;
        const { type, canViewSensabill, canCreateTender } = this;
        const { customerVersion } = this._data;
        return {
            ...parent,
            customerVersion,
            type,
            canViewSensabill,
            canCreateTender,
        };
    }
}
