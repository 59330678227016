import api from "@Api/index";
import Factory from "@Shared/Factory";
export const invite = (partnerId, customerId, tenderId, email) => {
    return api
        .put(`partners/${partnerId}/customers/${customerId}/tenders/${tenderId}/invite`, { email })
        .then((result) => {
        return Factory.Tender(result.data.tender.item);
    })
        .catch((error) => {
        throw error.response.data;
    });
};
