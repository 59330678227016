import React from "react";
import { useEqualHeight } from "@Helpers/EqualHeight";
import { useEqualWidth } from "@Helpers/EqualWidth";
import styles from "./index.module.scss";
export default function ColouredBorderBox(props) {
    const colour = props.primary
        ? styles.primary
        : props.none
            ? styles.none
            : styles.secondary;
    let reference = null;
    if (props.matchWidth) {
        reference = useEqualWidth(props.matchWidth, []);
    }
    if (props.matchHeight) {
        reference = useEqualHeight(props.matchHeight, []);
    }
    return (React.createElement("div", { className: `${styles.card} ${colour} ${props.className}`, ref: reference },
        React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.title },
                React.createElement("div", { className: `${styles.titleWrapper} ${styles.titleGap}` },
                    props.icon,
                    React.createElement("div", { className: styles.textWrapper }, props.title)))),
        props.children));
}
