import React from "react";
import Exit from "@Components/Icons/Exit";
import styles from "./index.module.scss";
export default class ExpiredPopup extends React.Component {
    delete = (response) => {
        this.props.delete(response);
    };
    render() {
        return (React.createElement("div", null, this.props.responses.map((response) => {
            return (React.createElement("div", { className: styles.popup, key: response.id },
                React.createElement("div", { className: styles.exit },
                    React.createElement(Exit, { error: true, size: "1.4rem", onClick: () => {
                            this.delete(response);
                        } })),
                React.createElement("div", { className: styles.expired },
                    React.createElement("div", { className: styles.expiredHeader }, "Expired"),
                    React.createElement("div", { className: styles.expiredContent }, "Please complete tenders before end date!"))));
        })));
    }
}
