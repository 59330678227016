import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { initMessageListener } from "redux-state-sync";
import middleware from "./Middleware";
import reducers from "./Reducers";
const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 50,
    serialize: true,
});
export const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));
initMessageListener(store);
export { addChat, addFormData, ADD_FORM_DATA, ADD_TENDER_CHAT, editAnswer, EDIT_ANSWER, } from "@Store/Actions/Customers/tenders";
