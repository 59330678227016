import SVG from "@Components/Icons/SVG";
export default class Cross extends SVG {
    path = [
        "m 49.9002 2 c -26.4529 0 -47.9002 21.4473 -47.9002 47.9002 s 21.4473 47.9002 47.9002 47.9002 s 47.9002 -21.4473 47.9002 -47.9002 s -21.4473 -47.9002 -47.9002 -47.9002 M 50 85 L 50 76 M 50 52 L 50 15",
    ];
    strokeDetails = {
        stroke: this.props.color,
        strokeWidth: "6",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
