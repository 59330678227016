import React from "react";
import { Redirect } from "react-router-dom";
import Button from "@Components/Button";
import Loading from "@Components/Icons/Loading";
import InfoPopup from "@Components/InfoPopup";
import { ToastStatus } from "@Components/Toast";
import GetPath from "@Helpers/GetPath";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import { AWARDED_CONTRACTS } from "@Shared/Paths/Admin";
import { TENDERS } from "@Shared/Paths/Customer";
import { CONTRACT } from "@Shared/Paths/Partner";
import styles from "./index.module.scss";
export default class BeginContracting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            contractId: undefined,
            loading: false,
        };
    }
    beginContracting = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });
        this.props
            .beginContracting(this.props.response.id)
            .then((contract) => {
            this.props.notify({
                title: "Supplier chosen",
                description: "The supplier has been notified",
                status: ToastStatus.Success,
            });
            this.setState({
                redirect: true,
                contractId: contract.id,
                loading: false,
            });
        })
            .catch(() => {
            this.setState({
                loading: false,
            });
        });
    };
    render() {
        if (this.state.redirect) {
            switch (this.props.userType) {
                case UserType.Customer:
                    return React.createElement(Redirect, { to: GetPath(TENDERS) });
                case UserType.Partner:
                    return (React.createElement(Redirect, { to: GetPath(CONTRACT, this.state.contractId) }));
                case UserType.Admin:
                    return React.createElement(Redirect, { to: GetPath(AWARDED_CONTRACTS) });
                default:
                    return React.createElement(Redirect, { to: "/" });
            }
        }
        const popup = (close) => {
            const { loading = false } = this.state;
            return (React.createElement("div", { className: styles.popup },
                React.createElement("div", { className: `${styles.wrapper} ${loading ? styles.loading : ""}` },
                    React.createElement("div", { className: styles.title }, "Congratulations!"),
                    React.createElement("div", null, "You're one step away from meeting your chosen supplier. After confirming you will be unable to return to any other supplier solutions."),
                    (this.props.userType === UserType.Admin ||
                        this.props.tender.showSupplierNames) && (React.createElement("div", null,
                        "Confirm you want to select:",
                        " ",
                        React.createElement("span", { className: styles.name }, this.props.response?.supplierName))),
                    React.createElement("div", { className: styles.buttons },
                        React.createElement("div", { className: styles.fill }),
                        React.createElement(Button, { click: close, dark: true }, "BACK"),
                        React.createElement(Button, { click: this.beginContracting, disabled: this.state.loading }, "CONFIRM")),
                    loading && (React.createElement(Loading, { size: "5rem", className: styles.spinner })))));
        };
        return (React.createElement(InfoPopup, { trigger: this.props.response !== undefined, content: popup, onClose: this.props.onClose }));
    }
}
