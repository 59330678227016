// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subHeader--qe2hG{position:sticky;top:0;padding:.7rem 1rem;background-color:#fff}.subHeader--qe2hG.marginIncrease--ihato{margin-right:-1rem;margin-left:-1rem}.subHeader--qe2hG.flex--wgmQc{display:flex;align-items:center}.subHeader--qe2hG.seperated--fwvAi{display:flex;align-items:center;justify-content:space-between;padding:0 1rem}.subHeader--qe2hG.noPadding--aT2NZ{padding:0}", "",{"version":3,"sources":["webpack://./src/gui/Components/SubHeader/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,eAAe,CAAC,KAAK,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,wCAA0B,kBAAkB,CAAC,iBAAiB,CAAC,8BAAgB,YAAY,CAAC,kBAAkB,CAAC,mCAAqB,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,cAAc,CAAC,mCAAqB,SAAS","sourcesContent":[".subHeader{position:sticky;top:0;padding:.7rem 1rem;background-color:#fff}.subHeader.marginIncrease{margin-right:-1rem;margin-left:-1rem}.subHeader.flex{display:flex;align-items:center}.subHeader.seperated{display:flex;align-items:center;justify-content:space-between;padding:0 1rem}.subHeader.noPadding{padding:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeader": "subHeader--qe2hG",
	"marginIncrease": "marginIncrease--ihato",
	"flex": "flex--wgmQc",
	"seperated": "seperated--fwvAi",
	"noPadding": "noPadding--aT2NZ"
};
export default ___CSS_LOADER_EXPORT___;
