import { findCompany, sensabillRegister, upload } from "@Api/Registration";
import AppSwitch from "@Components/AppSwitch";
import CreateAccount from "@Components/CreateAccount";
import Header from "@Components/Header";
import Uploading from "@Components/Pages/Fallbacks/Uploading";
import SubHeader from "@Components/SubHeader";
import UsageUploadSensabill from "@Components/UsageUploadSensabill";
import getSubdomain from "@Helpers/GetSubdomain";
import React from "react";
import styles from "./index.module.scss";
export default class Sensabill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAccount: false,
            visibleUploading: false,
        };
    }
    submitFiles = async (files) => {
        this.setState({
            files,
        });
        this.showAccountPopup();
    };
    submitPlatform = async (platform) => {
        this.setState({
            platform,
        });
        this.showAccountPopup();
    };
    showAccountPopup = () => {
        this.setState({ visibleAccount: true });
    };
    closeAccountPopup = () => {
        this.setState({
            visibleAccount: false,
        });
    };
    showUploadingPopup = () => {
        this.setState({ visibleUploading: true });
    };
    closeUploadingPopup = () => {
        this.setState({
            visibleUploading: false,
        });
    };
    submitAccount = async (account) => {
        this.closeAccountPopup();
        this.showUploadingPopup();
        const { platform, files = [] } = this.state;
        const subdomain = getSubdomain();
        // const haveUsage = platform || files.length > 0
        await sensabillRegister({
            network: platform,
            details: account,
            numFiles: files.length,
            subdomain,
        })
            .then(async ({ customer, usage }) => {
            await upload(customer.id, usage.id, files);
            return `/customer/sensabill/${usage.id}`;
        })
            .then((redirectTo) => {
            this.setState({
                redirectTo,
            });
        })
            .catch(({ response }) => {
            const { status } = response;
            if (status === 405) {
                this.closeUploadingPopup();
                this.showAccountPopup();
            }
            throw response;
        });
        this.closeUploadingPopup();
    };
    render() {
        if (this.state.redirectTo !== undefined) {
            return React.createElement(AppSwitch, { uri: this.state.redirectTo });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, null, "Welcome to Sensabill from Predict"),
            React.createElement(SubHeader, null,
                React.createElement("div", { className: styles.subHeaderText },
                    "Control your mobile account with",
                    " ",
                    React.createElement("span", { className: styles.highlighted }, "Sensabill's"),
                    " ",
                    "easy-to-use Smart-Meter.")),
            React.createElement("div", { className: styles.content },
                React.createElement(UsageUploadSensabill, { submitFiles: this.submitFiles, submitPlatform: this.submitPlatform, title: (s) => (React.createElement("div", { className: s.title },
                        React.createElement("b", { className: s.primaryColor }, "Bill Report"),
                        " ",
                        "upload, two easy ways to start Predict Sensabill")) }),
                React.createElement(CreateAccount, { open: this.state.visibleAccount, onClose: this.closeAccountPopup, notify: () => { }, create: this.submitAccount, findCompany: findCompany, sensabill: true }),
                React.createElement(Uploading, { open: this.state.visibleUploading, onClose: this.closeUploadingPopup }))));
    }
}
