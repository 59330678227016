import SVG from "@Components/Icons/SVG";
export default class Add extends SVG {
    path = [
        "M 5 50 a 45 45 90 1 0 90 0 a 45 45 90 1 0 -90 0",
        "M 29.75 50 L 70.25 50 M 50 70.25 L 50 29.75",
    ];
    strokeDetails = {
        stroke: "white",
        strokeWidth: "6",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    evenOdd = true;
}
