import React from "react";
import styles from "./index.module.scss";
import logo from "./Ofcom.png";
export default class OfcomLogo extends React.Component {
    static defaultProps = {
        className: "",
    };
    render() {
        return (React.createElement("img", { src: logo, alt: "Ofcom", className: `${styles.logo} ${this.props.className}` }));
    }
}
